import React from 'react'
import './loader.scss'

function index (props) {
  return (
        <>
        {
            props.show === true
              ? <div className="loader-container" >
                    <div className="lds-ripple"><div></div><div></div></div>
                </div>
              : ''
        }
        </>
  )
}

export default index
