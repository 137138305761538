/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import back from '../../assets/images/back2.svg'
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormFeedback,
  Form,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { GetData, PostData } from 'apiHandler'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toastr from 'toastr'
import Loader from '../../components/Loader'
import { globalVariables } from 'globalVariables'
import NumberFormat from 'react-number-format'
function AddWarehouse () {
  const userObj = JSON.parse(localStorage.authUser)
  const history = useHistory()

  // eslint-disable-next-line no-unused-vars
  const [currentRole, setCurrentRole] = useState(1)
  // eslint-disable-next-line no-unused-vars
  const [permissionsDataLeft, setPermissionsDataLeft] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [permissionsDataRight, setPermissionsDataRight] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [selectedPermission, setselectedPermission] = useState([])
  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])
  const [selectedState, setSelectedState] = useState({})
  const [selectedCity, setSelectedCity] = useState({})
  const [stateBtn, setStateBtn] = useState(false)
  const [cityBtn, setCityBtn] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [needValidation, setNeedValidation] = useState(false)
  const [loader, setLoader] = useState(false)

  const getState = async () => {
    const response = await GetData(`states?countryId=${globalVariables.countryId}`)
    setStates(response?.result)
  }

  const getCities = async (stateSelected) => {
    const response = await GetData(`cities?countryId=${globalVariables.countryId}&stateId=` + stateSelected?.id)
    setCities(response?.result)
  }

  const clearData = () => {
    setSelectedState({})
    setSelectedCity({})
    setNeedValidation(false)
  }

  useEffect(() => {
    if (localStorage.authUser) {
      const userObj1 = JSON.parse(localStorage.authUser)
      setCurrentRole(userObj1.roleId)
      getState()
      clearData()
    }
  }, [])

  useEffect(() => {
    let roleId = 2
    if (userObj.roleId === 3 || userObj.roleId === 4) {
      roleId = 4
    }
    getPermissions(roleId)
  }, [])

  const getPermissions = (roleId) => {
    GetData('admin/modules/get?areaCode=' + roleId).then((response) => {
      if (response.status === true) {
        setPermissionsDataLeft(
          response.result.slice(0, response.result.length / 2)
        )
        setPermissionsDataRight(
          response.result.slice(response.result.length / 2)
        )
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      locationName: '',
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      title: '',
      street: '',
      zipcode: '',
      state: '',
      city: '',
      primary: false
    },
    validationSchema: Yup.object().shape({
      locationName: Yup.string().trim().required('Please enter Location Name'),
      firstName: Yup.string().trim().required('Please enter Name'),
      lastName: Yup.string().trim().required('Please enter Name'),
      email: Yup.string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
      mobile: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required('Please enter Phone Number')
        .test(
          'maxDigits',
          'Must be 10 digits',
          (number) => String(number).length === 10
        ),
      title: Yup.string().trim().required('Please enter title'),
      street: Yup.string().trim().required('Please enter street'),
      zipcode: Yup.string().trim().required('Please enter zipcode'),
      state: Yup.string().trim().test('check-state', 'Please Select State', () => selectedState?.id),
      city: Yup.string().trim().test('check-city', 'Please Select City', () => selectedCity?.id)
    }),
    onSubmit: (values) => {
      setLoader(true)
      const url = 'vendor/addWarehouseLocation'

      const SubmitData = {
        locationName: values?.locationName,
        shippingStreet: values?.street,
        shippingStateId: selectedState?.id,
        shippingCityId: selectedCity?.id,
        shippingCountryId: globalVariables.countryId,
        shippingZipCode: values?.zipcode,
        contactFirstName: values?.firstName,
        contactLastName: values?.lastName,
        contactEmail: values?.email,
        contactPhone: values?.mobile,
        contactTitle: values?.title,
        shippingCityName: selectedCity?.name,
        isPrimaryLocation: values?.primary ? 1 : 0
      }
      setNeedValidation(true)

      //  if(isEmpty(selectedState) || isEmpty(selectedCity)){
      //    return
      //  }

      PostData(url, SubmitData).then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Success')
          setLoader(false)
          clearData()
          history.push('/warehouse-management')
        } else {
          setLoader(false)
          toastr.error(response.message, 'Error')
        }
      })
    }
  })
  return (
    <>
      <div className="page-content">
        <Loader show={loader}></Loader>
        <Container fluid={true} className="customer-container">
          <div className="staff-management-heading Add-staff">
            <Link to="/warehouse-management">
              {' '}
              <img src={back} style={{ marginRight: '8px', marginBottom: '4px' }} />
            </Link>{' '}
            Add New Warehouse
          </div>
          <Form
                  onSubmit={(e) => {
                    e.preventDefault()
                    validation.handleSubmit()
                  }}
                >
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                  <CardBody className="card-main-body">

                    <Row>
                      <Col lg={12}>
                      <div className="street--main--div add--newlocation">
                      <div className="edit-full-wt">
                        <div className="staff-manafement-light ">
                        Location Name*
                        </div>
                        <Input
                          type="text"
                          className="staff-first-input"
                          name="locationName"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.locationName || ''}
                          invalid={
                            !!(validation.touched.locationName &&
                            validation.errors.locationName)
                          }
                        />
                        {validation.touched.locationName &&
                        validation.errors.locationName
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.locationName}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </div>
                      </Col>
                      <Col lg={6}>
                      <div className='warehouse-contact-text'>Shipping Address</div>

                      <div className="street--main--div">
                      <div className="edit-full-wt">
                        <div className="staff-manafement-light">
                          Street*
                        </div>
                        <Input
                          type="text"
                          className="staff-first-input"
                          name="street"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.street || ''}
                          invalid={
                            !!(validation.touched.street &&
                            validation.errors.street)
                          }
                        />
                        {validation.touched.street &&
                        validation.errors.street
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.street}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </div>

                    <div className="street--main--div">
                      <Label htmlFor="formrow-firstname-Input">State*</Label>
                      <Dropdown
                        isOpen={stateBtn}
                        toggle={() => setStateBtn(!stateBtn)}
                        name="state"
                        onChange={validation.handleChange}
                        className={'location-modal-select-container' + (!selectedState?.name ? ' is-invalid' : '')}
                      >
                        <DropdownToggle className="location-modal-select text-dark">
                          <span>{selectedState?.name ?? 'selected'}</span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                        {states?.map(d =>
                          <DropdownItem onClick={() => {
                            setSelectedState(d)
                            getCities(d)
                            setSelectedCity({})
                          }} key={d?.id}>
                            {d?.name}
                          </DropdownItem>
                        )}
                        </DropdownMenu>
                      </Dropdown>
                      {validation.touched.state &&
                      validation.errors.state
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.state}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                    <div className="street--main--div">
                      <Label htmlFor="formrow-firstname-Input">City*</Label>
                      <Dropdown
                        isOpen={cityBtn}
                        toggle={() => setCityBtn(!cityBtn)}
                        name="city"
                        onChange={validation.handleChange}
                        className={'location-modal-select-container' + (!selectedCity?.name ? ' is-invalid' : '')}
                      >
                        <DropdownToggle className="location-modal-select text-dark">
                          <span>{selectedCity?.name ?? 'selected'}</span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                        {cities?.map(d =>
                          <DropdownItem onClick={() => {
                            setSelectedCity(d)
                          }} key={d?.id}>
                            {d?.name}
                          </DropdownItem>
                        )}
                        </DropdownMenu>
                      </Dropdown>
                      {validation.touched.city &&
                      validation.errors.city
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.city}
                        </FormFeedback>
                          )
                        : null}
                    </div>

                    <div className="street--main--div">
                    <div className="edit-full-wt">
                      <div className="staff-manafement-light">Zipcode*</div>
                      <Input
                        type="text"
                        className="staff-first-input"
                        name="zipcode"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.zipcode || ''}
                        invalid={
                          !!(validation.touched.zipcode &&
                          validation.errors.zipcode)
                        }
                      />
                      {validation.touched.zipcode &&
                      validation.errors.zipcode
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.zipcode}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                    </div>

                    <div className="street--main--div">
                    <div className="edit-full-wt">
                      <Input
                        type="checkbox"
                        name="primary"
                        id="primary"
                        className="pointer-cursor"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.primary || ''}
                      />&nbsp;&nbsp;<label htmlFor="primary" className="pointer-cursor">Set as Primary Location</label>
                    </div>
                    </div>

                      </Col>
                      <Col lg={6}>
                      <div className='warehouse-contact-text'>Contact Information</div>
                      <div className="street--main--div">
                      <div className="street--main--div warehouse--first-name">
                      <div className="name--first">
                        <div className="staff-manafement-light">
                          First Name*
                        </div>
                        <Input
                          type="text"
                          className="staff-first-input"
                          name="firstName"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstName || ''}
                          invalid={
                            !!(validation.touched.firstName &&
                            validation.errors.firstName)
                          }
                        />
                        {validation.touched.firstName &&
                        validation.errors.firstName
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstName}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                      <div className="name--first">
                        <div className="staff-manafement-light">Last Name*</div>
                        <Input
                          type="text"
                          className="staff-first-input"
                          name="lastName"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastName || ''}
                          invalid={
                            !!(validation.touched.lastName &&
                            validation.errors.lastName)
                          }
                        />
                        {validation.touched.lastName &&
                        validation.errors.lastName
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastName}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                      </div>
                    </div>
                    <div className="street--main--div">
                      <div className="edit-full-wt">
                        <div className="staff-manafement-light">
                          Email Address*
                        </div>
                        <Input
                          type="email"
                          className="staff-first-input"
                          name="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            !!(validation.touched.email && validation.errors.email)
                          }
                        />
                        {validation.touched.email && validation.errors.email
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </div>

                    <div className="street--main--div">
                      <div className="edit-full-wt">
                        <div className="staff-manafement-light">
                          Mobile Number*
                        </div>
                        <NumberFormat
                        className="staff-first-input"
                        name="mobile" displayType="input" format="(###) ###-####"
                        value={validation.values.mobile || ''}
                        onValueChange={(values) => {
                          if (values.value.length < 11) {
                            validation.setFieldValue(
                              'mobile',
                              values.value
                            )
                          }
                        }}
                        />
                        {/* <Input
                          type="text"
                          className="staff-first-input"
                          name="mobile"
                          onChange={(e) => {
                            if (e.target.value.length < 11) {
                              validation.setFieldValue(
                                'mobile',
                                e.target.value
                              )
                            }
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.mobile || ''}
                          invalid={
                            !!(validation.touched.mobile &&
                            validation.errors.mobile)
                          }
                        /> */}
                        {validation.touched.mobile &&
                        validation.errors.mobile
                          ? (
                          <div className='error' type="invalid">
                            {validation.errors.mobile}
                          </div>
                            )
                          : null}
                      </div>
                    </div>

                    <div className="street--main--div">
                      <div className="edit-full-wt">
                        <div className="staff-manafement-light">
                          Title*
                        </div>
                        <Input
                          type="text"
                          className="staff-first-input"
                          name="title"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.title || ''}
                          invalid={
                            !!(validation.touched.title &&
                            validation.errors.title)
                          }
                        />
                        {validation.touched.title &&
                        validation.errors.title
                          ? (
                          <FormFeedback>
                            {validation.errors.title}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </div>
                      </Col>
                    </Row>
                    <br/>
                    <button className="staff-save-changes staff-btn-clr" type='submit'>
                      Save
                    </button>
                    <button className="staff-cancel-btn staff-btn-clr" onClick={() => history.goBack()}>
                      Cancel
                    </button>
                  </CardBody>
              </Card>
            </Col>
          </Row>
          </Form>
        </Container>
      </div>
    </>
  )
}
export default AddWarehouse
