/* eslint-disable no-unused-vars */
/* eslint-disable multiline-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef
} from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Label,
  Input,
  Form,
  FormFeedback,
  Button,
  Modal
} from 'reactstrap'
import Breadcrumbs from 'components/Common/Breadcrumb'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import deleteLogo from '../../assets/images/ic_delete.svg'
import WDEditModal from 'components/Common/WDEditModal'
import { useParams, useHistory, Link } from 'react-router-dom'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { GetData, PutData, DeleteData, PostData } from '../../apiHandler'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import StaffList from './StaffList'
import CustomerList from './CustomerList'
import moment from 'moment'
import { globalVariables } from 'globalVariables'
import InfiniteScroll from 'react-infinite-scroll-component'
import NumberFormat from 'react-number-format'
import ProductList from './ProductList'
import OrderList from './OrderList'
import DisableModal from 'components/Common/DisableModal/DisableModal'

export const WDEditModalContext = createContext()
export const WDEditContext = createContext()

let searchTimer
let searchKeyword = ''

export default function ActiveWD () {
  const history = useHistory()
  const params = useParams()
  const [loader, setLoader] = useState(false)
  const [userData, setUserData] = useState({})
  const [profileData, setProfileData] = useState({})
  const [locations, setLocations] = useState([])
  const [tab, setTab] = useState('1')

  const [editModal, setEditModal] = useState(false)
  const [editProfile, setEditProfile] = useState(false)
  const [categoriesData, setCategoryData] = useState([])
  const [locationData, setLocationData] = useState({})
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [disableStatus, setDisableStatus] = useState()
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const [selectedData, setSelectedData] = useState({})
  const [openLocationIndex, setOpenLocationIndex] = useState(null)
  const [primaryLocation, setPrimaryLocation] = useState(null)
  const [disableModal, setDisableModal] = useState(false)
  const wdNotesRef = useRef()

  const commonData = JSON.parse(localStorage.getItem('commonData'))
  const data = commonData.programAffiliation

  const getStates = () => {
    const url = `states?countryId=${globalVariables.countryId}`
    GetData(url).then((response) => {
      if (response.status === true) {
        if (response.result.length) {
          setStates(response.result)
        } else {
          setStates([])
        }
      } else {
        setStates([])
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getCities = (id) => {
    const url = `cities?countryId=${globalVariables.countryId}&stateId=` + id
    GetData(url).then((response) => {
      if (response.status === true) {
        if (response.result.length) {
          setCities(response.result)
        } else {
          setCities([])
        }
      } else {
        setCities([])
        toastr.error(response.message, 'Error')
      }
    })
  }

  useEffect(() => {
    getWDData(params.id)
    getLocationsData(params.id)
    getCategories()
    getWDProfileData(params.id)
  }, [])

  const getWDData = (id, t = false) => {
    setLoader(true)
    GetData('admin/getWDDetails/few/' + id).then((response) => {
      if (response.status === true) {
        setUserData(response.result)
        if (t === false) {
          // getWDProfileData(params.id)
        } else {
          setLoader(false)
        }
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getLocationsData = (id, t = false) => {
    setLoader(true)
    GetData('admin/vendor/getLocations/' + id).then((response) => {
      if (response.status === true) {
        const temp = []
        response.result.map((item, index) => {
          temp.push({ ...item, open: index == 0 })
        })
        temp.sort((a, b) => (a.isPrimaryLocation < b.isPrimaryLocation ? 1 : -1))
        temp.map((loc) => {
          loc.open = true
        })
        setLocations(temp)
        if (t === false) {
          getWDProfileData(params.id)
        } else {
          setLoader(false)
        }

        // get primary location index and id
        const primaryIndex = response.result.findIndex((item) => item.isPrimaryLocation === 1)
        setPrimaryLocation(response.result[primaryIndex].id)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getWDProfileData = (id) => {
    GetData('admin/getWDDetails/' + id).then((response) => {
      setLoader(false)
      if (response.status === true) {
        setProfileData(response.result)
        setDisableStatus(response.result.status == 1)
        getStates()
        getCities(response.result.primaryBillingStateId)
        setLoader(false)
        if (wdNotesRef.current) {
          wdNotesRef.current.style.height = `${wdNotesRef.current.scrollHeight}px`
        }
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const perChunk = 5
  const brandResult = profileData?.brands?.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  function handleLocationTab (i, e) {
    e.preventDefault()
    const temp = [...locations]
    if (locations[i].open == true) {
      temp[i].open = false
      setLocations(temp)
    } else {
      temp[i].open = true
      setLocations(temp)
    }
  }

  // eslint-disable-next-line no-unused-vars
  function handleAdd (e) {
    e.preventDefault()
    const updatedlocation = [...locations]
    updatedlocation.push({})
    setLocations(updatedlocation)
  }

  function handleDelete (i, e, id) {
    e.preventDefault()
    setLoader(true)
    setIsDisableOpen(false)
    const updatedlocation = [...locations]
    if (id) {
      const url = 'admin/vendor/removeLocation/' + id
      DeleteData(url).then((response) => {
        setLoader(false)
        if (response.status === true) {
          setLoader(false)
          toastr.success(response.message, 'Success')
          updatedlocation.splice(i, 1)
          setLocations(updatedlocation)
        } else {
          toastr.error(response.message, 'Error')
        }
      })
    }
  }

  const getCategories = () => {
    GetData('admin/getCategoriesForAdmin').then((response) => {
      if (response.status === true) {
        const tableData = response.result.map((data, index) => {
          const tableData = data.open = index == 0
          return data
        })
        setCategoryData(tableData)
      }
    })
  }

  const handleDisable = () => {
    const url = `admin/changeWDstatus/${params.id}`
    GetData(url).then((response) => {
      if (response.status === true) {
        toastr.success(response.message, 'Success')
        getWDData(params.id)
        setDisableStatus(false)
        setDisableModal(false)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const onChangeHandler = function (e) {
    const target = e.target
    wdNotesRef.current.style.height = '30px'
    wdNotesRef.current.style.height = `${target.scrollHeight}px`
  }

  function handleUpdateWDNotes (e) {
    e.preventDefault()
    setLoader(true)
    PostData('admin/updateVendorNote', {
      vendorId: params.id,
      vendorNotes: wdNotesRef.current.value
    }).then((response) => {
      if (response.status == true) {
        toastr.success(response.message, 'Success')
        setLoader(false)
      } else {
        toastr.error(response.message, 'Error')
        setLoader(false)
      }
    })
  }

  const handleModal = () => {
    setDisableModal((prevState) => !prevState)
    setDisableStatus(true)
  }

  const handleDisableCheck = (e) => {
    if (e.target.value === 'on') {
      setDisableModal(true)
      setDisableStatus(false)
    }
  }

  return (
    <WDEditModalContext.Provider
      value={{
        locationData,
        setLocationData,
        editModal,
        setEditModal,
        getLocationsData,
        primaryLocation
      }}
    >
      <DisableModal show={disableModal} onCloseClick={handleModal} onDeleteClick={handleDisable}/>
      <div className="page-content">
        <Loader show={loader} />
        <Container fluid={true}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/wd-management')}
                className="dripicons-arrow-thin-left me-3"
              ></i>
              <Breadcrumbs
                title="UI Elements"
                breadcrumbItem={profileData.companyName}
              />
            </div>
            {profileData.status === 1 || profileData.status === 4
              ? (
              <div className="d-flex align-items-center">
                <div className="pt-1">
                  <span>Status :</span>
                  <span>
                    {' '}
                    {profileData.status === 1 ? 'Active' : 'Disable'}
                  </span>
                </div>

                <div className="form-check form-switch form-switch-md mb-0 ms-2">
                  <input
                    type="checkbox"
                    className="form-check-input status-check-input "
                    id="customSwitchsizemd"
                    disabled={!disableStatus}
                    checked={disableStatus}
                    onClick={handleDisableCheck}
                  />
                </div>
              </div>
                )
              : null}
            {/* <div >
              <button className="reject-btn">Reject</button>
              <button className="approve-btn">Approve</button>
          </div> */}
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <div className="bottom-customer-card-container">
                <Card>
                  <CardBody className="px-0">
                    <div className="active-customer-upper-card d-flex justify-content-around">
                      <div>
                        <p className="flex-div mb-0">Registration Date</p>
                        <span>
                          {userData.registrationDate
                            ? userData.registrationDate
                            : '--'}
                        </span>
                      </div>
                      <div>
                        <p className="flex-div mb-0">Approval Date</p>
                        <span>
                          {userData.approvalDate ? userData.approvalDate : '--'}
                        </span>
                      </div>
                      <div>
                        <p className="flex-div mb-0">Last Updated</p>
                        <span>
                          {userData.lastUpdated ? userData.lastUpdated : '--'}
                        </span>
                      </div>
                      {/* <div className="d-flex align-items-center justify-content-center" style={{ flex: "2" }}>
                                            <button className="assign-wd-button">Assign WD Location</button>
                                        </div> */}
                      <div>
                        <p className="flex-div mb-0">Total Orders</p>
                        <span className="span-blue">
                          {userData.totalOrders ? userData.totalOrders : '0'}
                        </span>
                        <Link to='/orders'>
                          <p className="para-blue">View All Orders</p>
                        </Link>
                      </div>
                      <div>
                        <p className="mb-0">Total Orders Value</p>
                        <span className="span-blue">
                          {userData.totalOrdersValue
                            ? userData.totalOrdersValue
                            : '0'}
                        </span>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="d-flex customer-notes-div">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2  col-form-label customer-notes-label"
                        id="customer-notes-text"
                      >
                        WD Notes :
                      </label>
                      <div className="col-md-9">
                        <textarea
                          className="form-control"
                          type="text"
                          ref={wdNotesRef}
                          onChange={(e) => onChangeHandler(e)}
                          defaultValue={profileData.vendorNotes}
                        />
                      </div>
                      <div className="col-md-1 text-center">
                        <button
                          className="customer-notes-save-btn"
                          onClick={(e) => handleUpdateWDNotes(e)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="bottom-customer-card-container">
                <Card>
                  <CardBody className="px-0">
                    <Nav pills className="ms-5 ps-3">
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classNames({
                            active: tab === '1'
                          })}
                          onClick={() => setTab('1')}
                        >
                          Profile
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classNames({
                            active: tab === '2'
                          })}
                          onClick={() => {
                            setTab('2')
                          }}
                        >
                          Location
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursoir: 'pointer' }}
                          className={classNames({
                            active: tab === '6'
                          })}
                          onClick={() => {
                            setTab('6')
                          }}
                        >
                          Orders
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                        style={{ cursoir: 'pointer' }}
                        className={classNames({
                          active: tab === '3'
                        })}
                        onClick={() => {
                          setTab('3')
                        }}
                        >
                         Products
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                        style={{ cursoir: 'pointer' }}
                        className={classNames({
                          active: tab === '4'
                        })}
                        onClick={() => {
                          setTab('4')
                        }}
                        >
                         Customers
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                        style={{ cursoir: 'pointer' }}
                        className={classNames({
                          active: tab === '4'
                        })}
                        onClick={() => {
                          setTab('4')
                        }}
                        >
                         Customers
                        </NavLink>
                      </NavItem> */}
                      <NavItem>
                        <NavLink
                          style={{ cursoir: 'pointer' }}
                          className={classNames({
                            active: tab === '5'
                          })}
                          onClick={() => {
                            setTab('5')
                          }}
                        >
                          Staff
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <hr className=" mt-0 mb-4"></hr>
                    <TabContent activeTab={tab}>
                      <TabPane tabId="1">
                        {editProfile ? (
                          <WDEditContext.Provider value={{
                            profileData,
                            setEditProfile,
                            getWDData,
                            setLoader,
                            getWDProfileData
                          }}>
                            <EditProfile />
                          </WDEditContext.Provider>
                        ) : (
                          <>
                            <div className="bottom-customer-card-inner-location">
                              <div className="d-flex justify-content-between">
                                <h5 className="section-main-heading-blue">
                                  Business Information
                                </h5>
                                <div
                                  className="profile-edit-btn d-flex align-items-center cursor-pointer"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setEditProfile(true)
                                  }}
                                >
                                  <button className='assign-wd-button px-2 py-1' style={{ height: 'auto' }}>
                                    <i
                                    className="bx bx-edit-alt ms-0"
                                    style={{ fontSize: '18px' }}
                                  ></i>
                                  <span style={{ fontSize: '14px' }}>Edit</span>
                                </button>
                                </div>
                              </div>
                              <div className="bottom-customer-card-inner-profile">
                                <div className="d-flex justify-content-between profile-common">
                                  <div style={{ flex: '1' }}>
                                    <Label>Company Name</Label>
                                    <p>
                                      {profileData.companyName
                                        ? profileData.companyName
                                        : '--'}
                                    </p>
                                  </div>
                                  <div style={{ flex: '1' }}>
                                    <Label>Email Address</Label>
                                    <p>
                                      {profileData.email
                                        ? profileData.email
                                        : '--'}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between profile-common">
                                  <div style={{ flex: '1' }}>
                                    <Label>Phone Number</Label>
                                    <p>
                                      <NumberFormat value={profileData.mobile ? profileData.mobile : '--'} displayType={'text'} format="+1 (###) ###-####"/>
                                      {/* {profileData.mobile
                                        ? profileData.mobile
                                        : '--'} */}
                                    </p>
                                  </div>
                                  <div style={{ flex: '1' }}>
                                    <Label>Group Affiliation</Label>
                                    <p>
                                    {profileData.programAffiliation
                                      ? profileData.programAffiliation
                                      : '--'}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between ">
                                  <div style={{ flex: '1' }}>
                                    <Label>Date Company Started</Label>
                                    <p>
                                      {profileData.dateCompanyStarted
                                        ? moment(
                                          profileData.dateCompanyStarted
                                        ).format('MM-DD-YYYY')
                                        : '--'}
                                    </p>
                                  </div>
                                  <div style={{ flex: '1' }}>
                                    <Label>Is EDI WD</Label>
                                    <p>
                                    {profileData.isEdiEnabledVendor === 1
                                      ? "Yes"
                                      : 'No'}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div style={{ flex: '1' }}>
                                    <h5 className="section-inner-heading mt-3">
                                      Primary Billing Address
                                    </h5>
                                    <div className="d-flex justify-content-between profile-common">
                                      <div style={{ flex: '1' }}>
                                        <Label>Street</Label>
                                        <p>
                                          {profileData.primaryBillingStreet
                                            ? profileData.primaryBillingStreet
                                            : '--'}
                                        </p>
                                      </div>
                                      <div style={{ flex: '1' }}>
                                        <Label>State</Label>
                                        <p>
                                          {profileData.primaryBillingStateId
                                            ? states.map((itm) => {
                                              if (
                                                itm.id ==
                                                  profileData.primaryBillingStateId
                                              ) {
                                                return itm.name
                                              }
                                            })
                                            : '--'}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between profile-common">
                                      <div style={{ flex: '1' }}>
                                        <Label>City</Label>
                                        <p>
                                          {profileData.primaryBillingCityId
                                            ? cities.map((itm) => {
                                              if (
                                                itm.id ==
                                                  profileData.primaryBillingCityId
                                              ) {
                                                return itm.name
                                              }
                                            })
                                            : '--'}
                                        </p>
                                      </div>
                                      <div style={{ flex: '1' }}>
                                        <Label>Zip Code</Label>
                                        <p>
                                          {profileData.primaryBillingZipCode
                                            ? profileData.primaryBillingZipCode
                                            : '--'}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div style={{ flex: '1' }}>
                                    <h5 className="section-inner-heading mt-3">
                                      Assigned Categories and SubCategories
                                    </h5>
                                    <div className="d-flex flex-wrap">
                                      {profileData.categories
                                        ? categoriesData.map((item, index) => {
                                          const catSelected = profileData.categories.map((item) => {
                                            return item.categoryID
                                          })
                                          if (
                                            catSelected.includes(
                                              item.categoryID
                                            )
                                          ) {
                                            return (
                                                <div
                                                  key={index}
                                                  className="me-5"
                                                >
                                                  <Label>{item.categoryName}</Label>
                                                  <ul className="ps-3">
                                                    {item.children.map(
                                                      (innerData, iIndex) => {
                                                        const selectedSubCat = profileData.categories.map((item) => {
                                                          return item.children
                                                        })
                                                        const updateSelectedSubCat = [].concat(...selectedSubCat)
                                                        if (
                                                          updateSelectedSubCat.includes(
                                                            innerData.subcategoryID
                                                          )
                                                        ) {
                                                          return (
                                                            <li key={iIndex}>
                                                              {innerData.subCategoryName}
                                                            </li>
                                                          )
                                                        }
                                                      }
                                                    )}
                                                  </ul>
                                                </div>
                                            )
                                          }
                                        })
                                        : '--'}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div style={{ flex: '1' }}>
                                    <h5 className="section-inner-heading mt-3">
                                      Assigned Brands
                                    </h5>
                                      <div className='row'>
                                      {brandResult?.map((item, i) => {
                                        return <div key={i} className='col-6'> <ul>
                                            {item?.map((brand, index) => {
                                              return <li key={index} className='mb-2'>{brand?.BrandName}</li>
                                            })}
                                        </ul>
                                        </div>
                                      })}
                                      </div>
                                  </div>
                                </div>
                                {/* <div className="d-flex justify-content-between">
                                  <div style={{ flex: "1" }}>
                                    <h5 className="section-inner-heading mt-3">
                                      Tax Information
                                    </h5>
                                    <div className="d-flex justify-content-between profile-common">
                                      <div style={{ flex: "1" }}>
                                        <Label>Resale Tax Number*</Label>
                                        <p>
                                          {profileData.reSaleTaxNumber
                                            ? profileData.reSaleTaxNumber
                                            : "--"}
                                        </p>
                                      </div>
                                      <div style={{ flex: "1" }}>
                                        <Label>Upload Document</Label>
                                        <p>
                                          {profileData.documentUrl ? (
                                            <a
                                              href={profileData.documentUrl}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Document
                                            </a>
                                          ) : (
                                            "--"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                              <hr></hr>
                              <h5 className="section-main-heading-blue">
                                Contact Information
                              </h5>
                              <div className="bottom-customer-card-inner-profile">
                                <h5 className="section-inner-heading">
                                  Primary / Owner
                                </h5>
                                <div className="d-flex justify-content-between profile-common">
                                  <div style={{ flex: '1' }}>
                                    <Label>First Name</Label>
                                    <p>
                                      {profileData.primaryFirstName
                                        ? profileData.primaryFirstName
                                        : '--'}
                                    </p>
                                  </div>
                                  <div style={{ flex: '1' }}>
                                    <Label>Last Name</Label>
                                    <p>
                                      {profileData.primaryLastName
                                        ? profileData.primaryLastName
                                        : '--'}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between profile-common">
                                  <div style={{ flex: '1' }}>
                                    <Label>Email Address</Label>
                                    <p>
                                      {profileData.primaryEmail
                                        ? profileData.primaryEmail
                                        : '--'}
                                    </p>
                                  </div>
                                  <div style={{ flex: '1' }}>
                                    <Label>Phone Number</Label>
                                    <p>
                                      <NumberFormat value={profileData.primaryPhone ? profileData.primaryPhone : '--'} displayType={'text'} format="+1 (###) ###-####"/>
                                      {/* {profileData.primaryPhone
                                        ? profileData.primaryPhone
                                        : '--'} */}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div style={{ flex: '1' }}>
                                    <Label>Title</Label>
                                    <p>
                                      {profileData.primaryTitle
                                        ? profileData.primaryTitle
                                        : '--'}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="bottom-customer-card-inner-profile">
                                <h5 className="section-inner-heading">
                                  Alternate
                                </h5>
                                <div className="d-flex justify-content-between profile-common">
                                  <div style={{ flex: '1' }}>
                                    <Label>First Name</Label>
                                    <p>
                                      {profileData.alternateFirstName
                                        ? profileData.alternateFirstName
                                        : '--'}
                                    </p>
                                  </div>
                                  <div style={{ flex: '1' }}>
                                    <Label>Last Name</Label>
                                    <p>
                                      {profileData.alternateLastName
                                        ? profileData.alternateLastName
                                        : '--'}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between profile-common">
                                  <div style={{ flex: '1' }}>
                                    <Label>Email Address</Label>
                                    <p>
                                      {profileData.alternateEmail
                                        ? profileData.alternateEmail
                                        : '--'}
                                    </p>
                                  </div>
                                  <div style={{ flex: '1' }}>
                                    <Label>Phone Number</Label>
                                    <p>
                                      <NumberFormat value={profileData.alternatePhone ? profileData.alternatePhone : '--'} displayType={'text'} format="+1 (###) ###-####"/>
                                      {/* {profileData.alternatePhone
                                        ? profileData.alternatePhone
                                        : '--'} */}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div style={{ flex: '1' }}>
                                    <Label>Title</Label>
                                    <p>
                                      {profileData.alternateTitle
                                        ? profileData.alternateTitle
                                        : '--'}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="bottom-customer-card-inner-profile">
                                <h5 className="section-inner-heading">
                                  Accounting
                                </h5>
                                <div className="d-flex justify-content-between profile-common">
                                  <div style={{ flex: '1' }}>
                                    <Label>First Name</Label>
                                    <p>
                                      {profileData.accountingFirstName
                                        ? profileData.accountingFirstName
                                        : '--'}
                                    </p>
                                  </div>
                                  <div style={{ flex: '1' }}>
                                    <Label>Last Name</Label>
                                    <p>
                                      {profileData.accountingLastName
                                        ? profileData.accountingLastName
                                        : '--'}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between profile-common">
                                  <div style={{ flex: '1' }}>
                                    <Label>Email Address</Label>
                                    <p>
                                      {profileData.accountingEmail
                                        ? profileData.accountingEmail
                                        : '--'}
                                    </p>
                                  </div>
                                  <div style={{ flex: '1' }}>
                                    <Label>Phone Number</Label>
                                    <p>
                                      <NumberFormat value={profileData.accountingPhone ? profileData.accountingPhone : '--'} displayType={'text'} format="+1 (###) ###-####"/>
                                      {/* {profileData.accountingPhone
                                        ? profileData.accountingPhone
                                        : '--'} */}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div style={{ flex: '1' }}>
                                    <Label>Title</Label>
                                    <p>
                                      {profileData.accountingTitle
                                        ? profileData.accountingTitle
                                        : '--'}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="bottom-customer-card-inner-profile">
                                <h5 className="section-inner-heading">
                                  IT
                                </h5>
                                <div className="d-flex justify-content-between profile-common">
                                  <div style={{ flex: '1' }}>
                                    <Label>First Name</Label>
                                    <p>
                                      {profileData.itFirstName
                                        ? profileData.itFirstName
                                        : '--'}
                                    </p>
                                  </div>
                                  <div style={{ flex: '1' }}>
                                    <Label>Last Name</Label>
                                    <p>
                                      {profileData.itLastName
                                        ? profileData.itLastName
                                        : '--'}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between profile-common">
                                  <div style={{ flex: '1' }}>
                                    <Label>Email Address</Label>
                                    <p>
                                      {profileData.itEmail
                                        ? profileData.itEmail
                                        : '--'}
                                    </p>
                                  </div>
                                  <div style={{ flex: '1' }}>
                                    <Label>Phone Number</Label>
                                    <p>
                                      <NumberFormat value={profileData.itPhone ? profileData.itPhone : '--'} displayType={'text'} format="+1 (###) ###-####"/>
                                      {/* {profileData.primaryPhone
                                        ? profileData.primaryPhone
                                        : '--'} */}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div style={{ flex: '1' }}>
                                    <Label>Title</Label>
                                    <p>
                                      {profileData.itTitle
                                        ? profileData.itTitle
                                        : '--'}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </TabPane>
                      <TabPane tabId="2">
                        <div>
                          <div className="bottom-customer-card-inner-location">
                            <hr></hr>
                            {locations.map((location, index) => {
                              return (
                                <div key={index}>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                      <span
                                        className="location-number"
                                        onClick={(e) => {
                                          handleLocationTab(index, e)
                                        }}
                                      >
                                        Location {index + 1}
                                      </span>
                                      {location.isPrimaryLocation == 1 && (
                                        <span className="primary-location">
                                          Primary Location
                                        </span>
                                      )}
                                    </div>
                                    <div className="d-flex align-items-center">
                                      {location.isPrimaryLocation != 1
                                        ? (
                                        <span
                                          style={{ cursor: 'pointer' }}
                                          onClick={(e) => {
                                            setSelectedData({
                                              ...location,
                                              index,
                                              e
                                            })
                                            setIsDisableOpen(true)
                                          }}
                                        >
                                          <img
                                            src={deleteLogo}
                                            className="mx-4"
                                          ></img>
                                        </span>
                                          )
                                        : null}

                                      {location.open
                                        ? (
                                        <i
                                          className="bx bx-minus"
                                          style={{ cursor: 'pointer' }}
                                          onClick={(e) => {
                                            handleLocationTab(index, e)
                                            setOpenLocationIndex(null)
                                          }}
                                        ></i>
                                          )
                                        : (
                                        <i
                                          className="bx bx-plus"
                                          style={{ cursor: 'pointer' }}
                                          onClick={(e) => {
                                            handleLocationTab(index, e)
                                            setOpenLocationIndex(index)
                                          }}
                                        ></i>
                                          )}
                                    </div>
                                  </div>
                                  {(location.open || openLocationIndex === index) && (
                                    <LocationInfo location={location} />
                                  )}
                                  <hr></hr>
                                </div>
                              )
                            })}
                            <WDEditModal />
                            <button
                              className="add-new-btn"
                              onClick={(e) => {
                                // handleAdd(e);
                                setLocationData({})
                                setEditModal(true)
                              }}
                            >
                              + Add New Location
                            </button>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId={'4'}>
                        <CustomerList />
                      </TabPane>
                      <TabPane tabId={'5'}>
                        <StaffList locations={locations}/>
                      </TabPane>
                      <TabPane tabId={'3'}>
                        <ProductList />
                      </TabPane>
                      <TabPane tabId={'6'}>
                        <OrderList />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
                <Modal
                  className="modal-main-body"
                  isOpen={isDisableOpen}
                  toggle={() => setIsDisableOpen(false)}
                  centered
                >
                  <div className="modal-header">
                    <button
                      type="button"
                      onClick={() => {
                        setIsDisableOpen(false)
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p className="disable-warehouse">
                      Are you sure you want to delete this location?
                    </p>
                    <div className="modal-detail-div">
                      <p className="modal-details">Details</p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading">
                          {' '}
                          Location Name:{' '}
                        </span>{' '}
                        {selectedData.locationName}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading">
                          {' '}
                          Contact Person:{' '}
                        </span>{' '}
                        {selectedData.contactFirstName}{' '}
                        {selectedData.contactLastName}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Email: </span>{' '}
                        {selectedData.contactEmail}
                      </p>
                    </div>
                    <div className="modal-btn-div">
                      <button
                        className="warehouse-btn-yes"
                        onClick={(e) =>
                          handleDelete(selectedData.index, e, selectedData.id)
                        }
                      >
                        Yes
                      </button>
                      <button
                        className="warehouse-btn-yes warehouse-btn-no"
                        onClick={() => setIsDisableOpen(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </Modal>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </WDEditModalContext.Provider>
  )
}

function LocationInfo ({ location }) {
  const {
    setEditModal,
    locationData,
    setLocationData,
    getLocationsData
  } = useContext(WDEditModalContext)

  const [locationStatus, setLocationStatus] = useState(null)
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const [loader, setLoader] = useState(false)
  const params = useParams()

  const handleModalOpen = (e, status) => {
    setIsDisableOpen(true)
    setLocationStatus(status)
  }

  const handleLocationStatus = (e, locationId, locationStatus) => {
    const url = `admin/changeWdLocationStatus/${locationId}`
    setLoader(true)
    let body = {}
    if (locationStatus == 'approve') {
      body = {
        action: 1,
        reason: 'Approved'
      }
    } else {
      body = {
        action: 2,
        reason: 'Rejected'
      }
    }
    PostData(url, body).then((response) => {
      if (response.status === true) {
        setLoader(false)
        setIsDisableOpen(false)
        getLocationsData(params.id)
        toastr.success(response.message, 'Success')
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  return (
    <div className="location-details-container">
      <Loader show={loader} />
      <div className="d-flex align-items-center justify-content-between">
        <span className="location-details-big-heading">
          {location.locationName}&nbsp;
          {location.status == 1
            ? (
            <span className="active-div">Active</span>
              )
            : location.status == 2
              ? (
            <span className="approved-div">Approved</span>
                )
              : location.status == 3
                ? (
            <span className="rejected-div">Rejected</span>
                  )
                : location.status == 4
                  ? (
            <span className="disabled-div">Disabled</span>
                    )
                  : (
            <span className="pending-div">Pending</span>
                    )}
        </span>
        <div
          className="edit-btn profile-edit-btn"
          onClick={(e) => {
            e.preventDefault()
            setLocationData(location)
            setEditModal(true)
          }}
        >
          <a>
            <i className="bx bxs-edit-alt" style={{ marginRight: '5px' }}></i>
            <span>Edit</span>
          </a>
        </div>
      </div>
      <div className="d-flex justify-content-between location-container-inner  location-border mt-3">
        <div className="location-container-inner">
          <p className="location-details-heading">Shipping Address</p>
          <div>
            <div>
              {location.shippingCityId
                ? (
                <div>
                  <p>{location.shippingAddress}</p>
                  <p>{location.shippingStreet}</p>
                  <p>{location.shippingZipCode}</p>
                </div>
                  )
                : (
                    '--'
                  )}
            </div>
          </div>
        </div>
        <div className="location-container-inner ps-4">
          <p className="location-details-heading">Contact Information</p>
          <div>
            {location.contactTitle
              ? (
              <>
                <p>{location.contactTitle ? location.contactTitle : ''}</p>
                <p>
                  {location.contactFirstName
                    ? location.contactFirstName + ' ' + location.contactLastName
                    : ''}
                </p>
                <p>{location.contactEmail ? location.contactEmail : ''}</p>
                <p>
                  <NumberFormat value={location.contactPhone ? location.contactPhone : '' } displayType={'text'} format="+1 (###) ###-####"/>
                  {/* {location.contactPhone ? location.contactPhone : ''} */}
                  </p>
              </>
                )
              : (
                  '--'
                )}
          </div>
        </div>
      </div>
      {location.status === 0 && <div
        className="d-flex align-items-center mt-3"
        style={{ justifyContent: 'flex-end' }}
      >
        <button
          className="reject-btn"
          disabled={location.status == 3 || location.status == 1}
          onClick={(e) => handleModalOpen(e, 'reject')}
        >
          Reject
        </button>
        <button
          className="approve-btn"
          disabled={location.status == 1}
          onClick={(e) => handleModalOpen(e, 'approve')}
        >
          Approve
        </button>
      </div>}
      <Modal
        className="modal-main-body"
        isOpen={isDisableOpen}
        toggle={() => setIsDisableOpen(false)}
        centered
      >
        <div className="modal-header">
          <button
            type="button"
            onClick={() => {
              setIsDisableOpen(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <p className="disable-warehouse">
            Are you sure you want to {locationStatus} Warehouse?
          </p>
          <div className="modal-detail-div">
            <Row>
              <Col lg={12}>
                <div className="location-container-inner">
                  <p className="location-details-heading">
                    <span style={{ fontWeight: '700' }}>Details</span>
                  </p>
                  <div>
                    <div>
                      <p>
                        Warehouse Name:{' '}
                        <span style={{ fontWeight: '700' }}>
                          {location ? location.locationName : '--'}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Contact Person:{' '}
                        <span style={{ fontWeight: '700' }}>
                          {location?.contactFirstName
                            ? location.contactFirstName +
                              ' ' +
                              location.contactLastName
                            : ''}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Address:{' '}
                        <span style={{ fontWeight: '700' }}>
                          {location.shippingCityId
                            ? `${location?.shippingStreet}, ${location.shippingCityName || '--'}, ${location.shippingZipCode}`
                            : null}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-btn-div">
            <button
              className="warehouse-btn-yes"
              onClick={(e) =>
                handleLocationStatus(e, location?.id, locationStatus)
              }
            >
              Yes
            </button>
            <button
              className="warehouse-btn-yes warehouse-btn-no"
              onClick={() => setIsDisableOpen(false)}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
LocationInfo.propTypes = {
  location: PropTypes.object
}

// eslint-disable-next-line react/prop-types
function EditProfile () {
  const { profileData, setEditProfile, getWDData, setLoader, getWDProfileData } = useContext(WDEditContext)
  const params = useParams()
  const [programAffiliationData, setProgramAffiliationData] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  // const categories = [
  //   { Category: 'Filters', open: false, SubCategory: ['Any', 'Any', 'Any'] },
  //   {
  //     Category: 'WiperBlades',
  //     open: false,
  //     SubCategory: ['Any', 'Any', 'Any']
  //   },
  //   { Category: 'Lighting', open: false, SubCategory: ['Any', 'Any', 'Any'] }
  // ]
  const [categoryData, setCategoryData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])
  const [searchText, setSearchText] = useState('')
  const [allBrands, setAllBrands] = useState([])
  const [brandPage, setBrandPage] = useState(1)
  const [selectedBrands, setSelectedBrands] = useState([])
  const [selectedBrandIds, setSelectedBrandsIds] = useState([])
  const scrollRef = useRef()

  const [isEDIWD, setIsEDIWD] = useState(false)

  // ----------------function handle EDI WD checkbox-------------
  const handleEdiWdCheckbox = () => {
    setIsEDIWD(!isEDIWD)
  }
  useEffect(() => {
    getCategories()
    getProgramAffiliation()
    getStates()
    getCities(profileData.primaryBillingStateId)
    setSelectedCategory(profileData.categories)
    setIsEDIWD(!!profileData?.isEdiEnabledVendor)
  }, [])

  useEffect(() => {
    if (profileData.brands.length > 0) {
      setSelectedBrandsIds(profileData?.brands?.map((brand) => {
        return brand?.BrandID
      }))
      setSelectedBrands(profileData?.brands?.map((brand) => {
        return { label: brand?.BrandName, value: brand?.BrandID }
      }))
      validation.setFieldValue('brands', profileData?.brands?.map((brand) => {
        return brand?.BrandID
      }))
    }
  }, [profileData])

  // useEffect(() => {
  //   getBrands(brandPage)
  // }, [])

  useEffect(() => {
    if (selectedCategory.length > 0) {
      setAllBrands([])
      getBrands(1)
    } else {
      setAllBrands([])
    }
  }, [selectedCategory])

  const getBrands = (p) => {
    setBrandPage(p)
    setLoader(true)
    const url = 'admin/getAllVcdbBrandsByCategory'
    const body = {
      page: p,
      pageSize: 20,
      categories: selectedCategory
    }
    if (searchKeyword !== '') {
      body.search = searchKeyword
    }
    PostData(url, body).then((response) => {
      if (response.status === true) {
        setLoader(false)
        if (response.result.length > 0) {
          const newBrands = response.result.map((item) => {
            return { label: item.BrandName, value: item.BrandID }
          })
          if (searchKeyword && p === 1) {
            scrollRef.current.scrollTo(0, 0)
            setAllBrands(newBrands)
          } else setAllBrands(prevState => [...prevState, ...newBrands])
        }
      } else {
        toastr.error(response.message, 'Error')
        setLoader(false)
      }
    })
  }

  const handleBrandCheckbox = (brand, e) => {
    if (!e.target.checked) {
      const updatedCheck = [...selectedBrands]
      const brandData = { ...brand }
      brandData.checked = true
      updatedCheck.push(brandData)
      setSelectedBrands(updatedCheck)
      setSelectedBrandsIds(updatedCheck.map((brand) => {
        return brand.value
      }))
      validation.setFieldValue('brands', updatedCheck.map((brand) => {
        return brand.value
      }))
    } else if (e.target.checked) {
      const data = selectedBrands.filter((item, i) => {
        return item.value !== brand.value
      })
      setSelectedBrands(data)
      setSelectedBrandsIds(data.map((brand) => {
        return brand.value
      }))
      validation.setFieldValue('brands', data.map((brand) => {
        return brand.value
      }))
    }
  }

  const fetchNextBrands = () => {
    getBrands(parseInt(brandPage) + 1)
  }

  const handleDeleteBrand = (e, brand) => {
    const data = selectedBrands.filter((item, i) => {
      return item.value !== brand.value
    })
    setSelectedBrands(data)
    setSelectedBrandsIds(data.map((brand) => {
      return brand.value
    }))
    validation.setFieldValue('brands', data.map((brand) => {
      return brand.value
    }))
  }

  const getCategories = () => {
    GetData('admin/getCategoriesForAdmin').then((response) => {
      if (response.status === true) {
        const tableData = response.result.map((data, index) => {
          const tableData = data.open = index == 0
          return data
        })
        setCategoryData(tableData)
      }
    })
  }
  const filterBrand = (e) => {
    setSearchText(e.target.value)
    if (e.target.value === '') {
      setAllBrands([])
    }
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      searchKeyword = e.target.value
      getBrands(1)
    }, 1000)
  }

  const getStates = () => {
    const url = `states?countryId=${globalVariables.countryId}`
    GetData(url).then((response) => {
      if (response.status === true) {
        if (response.result.length) {
          setStates(response.result)
        } else {
          setStates([])
        }
      } else {
        setStates([])
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getCities = (id) => {
    const url = `cities?countryId=${globalVariables.countryId}&stateId=` + id
    GetData(url).then((response) => {
      if (response.status === true) {
        if (response.result.length) {
          setCities(response.result)
        } else {
          setCities([])
        }
      } else {
        setCities([])
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getProgramAffiliation = () => {
    try {
      const commonData = JSON.parse(localStorage.getItem('commonData'))
      const caarr = []
      const data = commonData.programAffiliation
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key)) {
          caarr.push({ id: key, name: data[key] })
        }
      }
      setProgramAffiliationData(caarr)
    } catch (e) {
      console.log('e: ', e)
    }
  }

  function handleOpen (i, e) {
    e.preventDefault()
    let temp
    // eslint-disable-next-line prefer-const
    temp = [...categoryData]

    if (temp[i].open == true) {
      temp[i].open = false
      setCategoryData(temp)
    } else {
      temp[i].open = true
      setCategoryData(temp)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      companyName: profileData.companyName,
      email: profileData.email,
      mobile: profileData.mobile,
      programAffiliation: profileData.programAffiliation,
      dateCompanyStarted: profileData.dateCompanyStarted,
      primaryBillingStreet: profileData.primaryBillingStreet,
      primaryBillingCountryId: profileData.primaryBillingCountryId,
      primaryBillingStateId: profileData.primaryBillingStateId,
      primaryBillingCityId: profileData.primaryBillingCityId,
      primaryBillingZipCode: profileData.primaryBillingZipCode,
      primaryFirstName: profileData.primaryFirstName,
      primaryLastName: profileData.primaryLastName,
      primaryEmail: profileData.primaryEmail,
      primaryPhone: profileData.primaryPhone,
      primaryTitle: profileData.primaryTitle,
      alternateFirstName: profileData.alternateFirstName,
      alternateLastName: profileData.alternateLastName,
      alternateEmail: profileData.alternateEmail,
      alternatePhone: profileData.alternatePhone,
      alternateTitle: profileData.alternateTitle,
      accountingFirstName: profileData.accountingFirstName,
      accountingLastName: profileData.accountingLastName,
      accountingEmail: profileData.accountingEmail,
      accountingPhone: profileData.accountingPhone,
      accountingTitle: profileData.accountingTitle,
      categories: profileData.categories,
      itFirstName: profileData.itFirstName,
      itLastName: profileData.itLastName,
      itEmail: profileData.itEmail,
      itPhone: profileData.itPhone,
      itTitle: profileData.itTitle,
      documentFile: profileData.documentUrl ? profileData.documentUrl : ''
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required('Please enter Company Name'),
      email: Yup.string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
      mobile: Yup.string().required('Please enter Mobile Number'),
      brands: Yup.array().required('Please select brands').min(1, 'Please select at least one brand'),
      programAffiliation: Yup.string().required(
        'Please enter Group Affiliation'
      ),
      dateCompanyStarted: Yup.string().required(
        'Please enter Company Start Date'
      ),
      primaryBillingStreet: Yup.string().required(
        'Please enter Primary Billing Street'
      ),
      primaryBillingStateId: Yup.string().required(
        'Please enter Primary Billing State'
      ),
      primaryBillingCityId: Yup.string().required(
        'Please enter Primary Billing City'
      ),
      primaryBillingZipCode: Yup.number().required(
        'Please enter Primary Billing Zip Code'
      ),
      primaryFirstName: Yup.string().required(
        'Please enter Primary First Name'
      ),
      primaryLastName: Yup.string().required('Please enter Primary Last Name'),
      primaryEmail: Yup.string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
      primaryPhone: Yup.string().required('Please enter Primary Phone Number'),
      primaryTitle: Yup.string().required('Please enter Primary Title'),
      accountingPhone: Yup.string()
        .notRequired()
        .nullable(true)
        .min(10, 'Must be equal to 10 numbers')
        .max(10, 'Must be equal to 10 numbers')
        .typeError("That doesn't look like a phone number"),
      itPhone: Yup.string()
        .notRequired()
        .nullable(true)
        .min(10, 'Must be equal to 10 numbers')
        .max(10, 'Must be equal to 10 numbers')
        .typeError("That doesn't look like a phone number"),
      // accountingFirstName: Yup.string().required(
      //   'Please enter Accounting First Name'
      // ),
      // accountingLastName: Yup.string().required(
      //   'Please enter Accounting Last Name'
      // ),
      // accountingEmail: Yup.string('Enter your email')
      //   .email('Enter a valid email')
      //   .required('Email is required'),
      // accountingPhone: Yup.string().required(
      //   'Please enter Accounting Phone Number'
      // ),
      // accountingTitle: Yup.string().required('Please enter Accounting Title'),
      // itFirstName: Yup.string()
      //   .trim()
      //   .required('Please Enter First Name'),
      // itLastName: Yup.string()
      //   .trim()
      //   .required('Please Enter Last Name'),
      // itEmail: Yup.string('Enter your email')
      //   .trim()
      //   .email('Enter a valid email')
      //   .required('Email is required'),
      // itPhone: Yup.number()
      //   .typeError("That doesn't look like a phone number")
      //   .positive("A phone number can't start with a minus")
      //   .integer("A phone number can't include a decimal point")
      //   .required('Please enter IT Phone Number')
      //   .test(
      //     'maxDigits',
      //     'Must be 10 digits',
      //     (number) => String(number).length === 10
      //   ),
      // itTitle: Yup.string().trim().required('Please Enter Title'),
      categories: Yup.array().required('Please select categories').min(1, 'Please select at least one category')
    }),
    onSubmit: (values) => {
      const finalObj = { ...values, isEdiEnabledVendor: isEDIWD ? 1 : 0 }
      const url = 'admin/updateWDProfile/' + params.id
      setLoader(true)
      PutData(url, finalObj).then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Success')
          setEditProfile(false)
          window.scrollTo(0, 0)
          getWDData(params.id)
          setLoader(false)
          getWDProfileData(params.id)
        } else {
          toastr.error(response.message, 'Error')
          setLoader(false)
        }
      })
    }
  })

  const handleErrors = () => {
    if (Object.keys(validation.errors).length > 0) {
      document.getElementsByName(Object.keys(validation.errors)[0])[0]?.scrollIntoView({ block: 'center' })
    }
  }

  const handleCheckCategory = (id, children, e) => {
    let temp = [...selectedCategory]
    const subCategoryIds = children.map((subCategory) => {
      return subCategory.subcategoryID
    })
    if (!e.target.checked) {
      temp.push({ categoryID: id, children: [...subCategoryIds] })
      setSelectedCategory(temp)
    } else if (e.target.checked) {
      temp = temp.filter((item) => {
        return item.categoryID !== id
      })
      if (temp.length === 0) {
        setSelectedBrands([])
        setSelectedBrandsIds([])
        validation.setFieldValue('brands', [])
      }
      setSelectedCategory(temp)
    }
    validation.setFieldValue('categories', temp)
  }

  const handleCheckSubCategory = (id, subId, e) => {
    const temp = selectedCategory.filter((category) => {
      return category.categoryID !== id
    })
    const filteredTemp = selectedCategory.filter((category) => {
      return category.categoryID === id
    })
    let categoryObj
    filteredTemp.length === 0
      ? categoryObj = { categoryID: id, children: [] }
      : categoryObj = filteredTemp[0]
    const childArr = [...categoryObj.children]
    if (!e.target.checked) {
      childArr.push(subId)
      categoryObj.children = childArr
      setSelectedCategory([categoryObj, ...temp])
      validation.setFieldValue('categories', [categoryObj, ...temp])
    } else if (e.target.checked) {
      const updatedChildArr = childArr.filter((item) => {
        return item !== subId
      })
      if (updatedChildArr.length === 0) {
        const categories = selectedCategory.filter((item) => {
          return item.categoryID !== id
        })
        setSelectedCategory(categories)
        validation.setFieldValue('categories', categories)
      } else {
        categoryObj.children = updatedChildArr
        setSelectedCategory([categoryObj, ...temp])
        validation.setFieldValue('categories', [categoryObj, ...temp])
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleDocument = (e) => {
    const url = 'admin/uploadDocument'
    const formData = new FormData()
    formData.append('document', e.target.files[0])
    PostData(url, formData, true).then((response) => {
      if (response.status === true) {
        validation.setFieldValue('documentFile', response.result.newFile)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  return (
    <div>
      <Row>
        <Col lg={12}>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              handleErrors()
              validation.handleSubmit()
              return false
            }}
          >
            <div className="new-registration-container ">
              <div className="d-flex justify-content-between align-items-center wd-business-nav">
                <h2 className="new-registration-heading-blue">
                  Business Information
                </h2>
                <span className="all-marked-wd">
                  * All marked fields are mandatory
                </span>
              </div>
              <Row>
                <Col lg={6}>
                  <div className="mb-4">
                    <Label htmlFor="formrow-firstname-Input">
                      Company Name*
                    </Label>
                    <Input
                      name="companyName"
                      type="text"
                      className="form-control"
                      id="formrow-firstname-Input"
                      placeholder="Enter Company Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.companyName || ''}
                      invalid={
                        !!(validation.touched.companyName &&
                        validation.errors.companyName)
                      }
                    />
                    {validation.touched.companyName &&
                    validation.errors.companyName
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.companyName}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-4">
                    <Label htmlFor="formrow-firstname-Input">
                      Email Address*
                    </Label>
                    <Input
                      name="email"
                      type="text"
                      className="form-control"
                      id="formrow-firstname-Input"
                      placeholder="Enter Email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ''}
                      invalid={
                        !!(validation.touched.email && validation.errors.email)
                      }
                    />
                    {validation.touched.email && validation.errors.email
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <div className="mb-4">
                    <Label htmlFor="formrow-firstname-Input">
                      Mobile Number*
                    </Label>
                    <NumberFormat
                     name="mobile"
                     className={
                      validation.touched.mobile && validation.errors.mobile
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                     id="formrow-firstname-Input"
                     placeholder="Enter Mobile Number"
                     value={validation.values.mobile || ''}
                     onChange={validation.handleChange}
                     displayType="input" format="(###) ###-####"
                    />
                    {/* <Input
                      name="mobile"
                      type="tel"
                      className="form-control"
                      id="formrow-firstname-Input"
                      placeholder="Enter Mobile Number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.mobile || ''}
                      invalid={
                        !!(validation.touched.mobile && validation.errors.mobile)
                      }
                    /> */}
                    {validation.touched.mobile && validation.errors.mobile
                      ? (
                      <div className='error' type="invalid">
                        {validation.errors.mobile}
                      </div>
                        )
                      : null}
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-4">
                    <Label htmlFor="formrow-firstname-Input">
                      Group Affiliation*
                    </Label>
                    <Input
                      name="programAffiliation"
                      type="input"
                      className="form-control"
                      id="formrow-firstname-Input"
                      placeholder="Enter Group Affiliation"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.programAffiliation || ''}
                      invalid={
                        !!(validation.touched.programAffiliation &&
                        validation.errors.programAffiliation)
                      }
                     />
                    {validation.touched.programAffiliation &&
                    validation.errors.programAffiliation
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.programAffiliation}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-4">
                    <Label htmlFor="formrow-firstname-Input">
                      Date Company Started
                    </Label>
                    <Input
                      name="dateCompanyStarted"
                      type="date"
                      className="form-control"
                      id="formrow-firstname-Input"
                      placeholder="Enter Date Company Started"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.dateCompanyStarted || ''}
                      invalid={
                        !!(validation.touched.dateCompanyStarted &&
                        validation.errors.dateCompanyStarted)
                      }
                    />
                    {validation.touched.dateCompanyStarted &&
                    validation.errors.dateCompanyStarted
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.dateCompanyStarted}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                </Col>
              </Row>
              <Row>
                  <Col lg={12}>
                    <div className="mb-4 d-flex">
                      <input
                              type="checkbox"
                              id="edi-wd"
                              name="ediWD"
                              checked={isEDIWD}
                              onClick={handleEdiWdCheckbox}
                            />
                        <label htmlFor="edi-wd" style={{ marginLeft: '10px', marginBottom: '0px' }}>
                              Is EDI WD
                        </label>
                      </div>
                    </Col>
                </Row>
              <Row>
                <Col lg={6}>
                  <h3 className="new-registration-heading-black ">
                    Primary Billing Address
                  </h3>
                  <div className="mb-4">
                    <Label htmlFor="formrow-firstname-Input">Street*</Label>
                    <Input
                      name="primaryBillingStreet"
                      type="text"
                      className="form-control"
                      id="formrow-firstname-Input"
                      placeholder="Enter Street Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.primaryBillingStreet || ''}
                      invalid={
                        !!(validation.touched.primaryBillingStreet &&
                        validation.errors.primaryBillingStreet)
                      }
                    />
                    {validation.touched.primaryBillingStreet &&
                    validation.errors.primaryBillingStreet
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.primaryBillingStreet}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                  <div className="mb-4">
                    <Label htmlFor="formrow-firstname-Input">State*</Label>
                    <Input
                      type="select"
                      name="primaryBillingStateId"
                      id="stateSelect"
                      className="cursor--p"
                      onChange={(e) => {
                        validation.setFieldValue(
                          'primaryBillingStateId',
                          e.target.value
                        )
                        getCities(e.target.value)
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.primaryBillingStateId || ''}
                      invalid={
                        !!(validation.touched.primaryBillingStateId &&
                        validation.errors.primaryBillingStateId)
                      }
                    >
                      <option value="">-Select State-</option>
                      {states.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                    {validation.touched.primaryBillingStateId &&
                    validation.errors.primaryBillingStateId
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.primaryBillingStateId}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                  <Row>
                    <Col lg={6}>
                      {' '}
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">City*</Label>
                        <Input
                          name="primaryBillingCityId"
                          type="select"
                          className="form-control cursor--p"
                          id="citySelect"
                          placeholder="Enter City"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.primaryBillingCityId || ''}
                          invalid={
                            !!(validation.touched.primaryBillingCityId &&
                            validation.errors.primaryBillingCityId)
                          }
                        >
                          <option value="">-Select City-</option>
                          {cities.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Input>
                        {validation.touched.primaryBillingCityId &&
                        validation.errors.primaryBillingCityId
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.primaryBillingCityId}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Zip Code*
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Zipcode"
                          name="primaryBillingZipCode"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.primaryBillingZipCode || ''}
                          invalid={
                            !!(validation.touched.primaryBillingZipCode &&
                            validation.errors.primaryBillingZipCode)
                          }
                        />
                        {validation.touched.primaryBillingZipCode &&
                        validation.errors.primaryBillingZipCode
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.primaryBillingZipCode}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </Col>
                  </Row>

                  {/* <h3 className="new-registration-heading-black ">
                    Tax Information
                  </h3> */}
                </Col>
                <Col lg={6}>
                  <h3 className="new-registration-heading-black">
                    Assign Category and Subcategory
                  </h3>
                  <div className="mb-4">
                    <Label htmlFor="formrow-firstname-Input">
                      Select Category and Sub Category*
                    </Label>
                    <div className="category-subcategory-container">
                      {categoryData.map((data, i) => {
                        const selectedCat = selectedCategory.map((cat) => {
                          return cat.categoryID
                        })
                        return (
                          <div key={i}>
                            <div className="d-flex align-items-center">
                              {data.open
                                ? (
                                <i
                                  className="bx bx-minus cursor--p"
                                  onClick={(e) => handleOpen(i, e)}
                                ></i>
                                  )
                                : (
                                <i
                                  className="bx bx-plus cursor--p"
                                  onClick={(e) => handleOpen(i, e)}
                                ></i>
                                  )}

                              <input
                                className=" m-0 ms-2"
                                type="checkbox"
                                value={data.categoryID}
                                onClick={(e) =>
                                  handleCheckCategory(
                                    data.categoryID,
                                    data.children,
                                    e
                                  )
                                }
                                id={'defaultCheck' + data.categoryName}
                                checked={selectedCat.includes(data.categoryID)}
                                name="categories"
                              />
                              <label
                                className="form-check-label ms-1"
                                htmlFor={'defaultCheck' + data.categoryName}
                              >
                                {data.categoryName}
                              </label>
                            </div>
                            {data.open && (
                              <div className="d-block">
                                {data.children.map(
                                  (innerData, innerindex) => {
                                    const selectedSubCat = selectedCategory.map((subCat) => {
                                      return subCat.children
                                    })
                                    const updateSelectedSubCat = [].concat(...selectedSubCat)
                                    return (
                                      <div
                                        key={innerindex}
                                        className="subcategory-container"
                                      >
                                        <input
                                          className=" m-0 ms-2"
                                          type="checkbox"
                                          value={innerData.subcategoryID}
                                          onClick={(e) =>
                                            handleCheckSubCategory(
                                              data.categoryID,
                                              innerData.subcategoryID,
                                              e
                                            )
                                          }
                                          id={'defaultChecki' + innerData.subCategoryName}
                                          checked={updateSelectedSubCat.includes(innerData.subcategoryID)}
                                        />
                                        <label
                                          className="form-check-label ms-1"
                                          htmlFor={
                                            'defaultChecki' + innerData.subCategoryName
                                          }
                                        >
                                          {innerData.subCategoryName}
                                        </label>
                                      </div>
                                    )
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        )
                      })}
                      <div></div>
                    </div>
                    {validation.errors.categories
                      ? (
                          <FormFeedback
                            type="invalid"
                            style={{ display: 'block' }}
                          >
                            {validation.errors.categories}
                          </FormFeedback>
                        )
                      : null}
                  </div>
                </Col>
                {/* <Row>
                  <Col lg={4}>
                    <div className="mb-4">
                      <Label htmlFor="formrow-firstname-Input">
                        Resale Tax Number*{" "}
                        <span className="info-icon">
                            <img src={info} className="info-icon cursor--p" alt="Info" />
                            <span className="tooltiptextt">
                              Lorem Ipsum is simply dummy text of the printing
                              and type setting industry.Lorem Ipsum
                            </span>
                          </span>
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Enter Resale Tax Number"
                        name="reSaleTaxNumber"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.reSaleTaxNumber || ""}
                        invalid={
                          validation.touched.reSaleTaxNumber &&
                          validation.errors.reSaleTaxNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.reSaleTaxNumber &&
                      validation.errors.reSaleTaxNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.reSaleTaxNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className="mb-4">
                      <Label htmlFor="formrow-firstname-Input">
                        Upload Document(Optional)
                        <span className="info-icon">
                            <img src={info} className="info-icon cursor--p" alt="Info" />
                            <span className="tooltiptextt">
                              Lorem Ipsum is simply dummy text of the printing
                              and type setting industry.Lorem Ipsum
                            </span>
                          </span>
                      </Label>
                      {validation.documentFile || ""}
                      <Input
                        type="file"
                        className="form-control d-none"
                        id="UploadTaxDocument"
                        placeholder="Upload Document"
                        onChange={handleDocument}
                      />
                      <div
                        className="upload-document-container choosefile--hover"
                        onClick={(e) => {
                          e.preventDefault();
                          document.getElementById("UploadTaxDocument").click();
                        }}
                      >
                        <i className="bx bx-cloud-upload me-2"></i>
                        Choose File
                      </div>
                    </div>
                  </Col>
                </Row> */}
                <Row>
                      <h3 className="new-registration-heading-black">
                        Assign Brands
                      </h3>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Assign Brands Here
                        </Label>
                        <div className="wd-brands-container">
                        <div className="position-relative search-featured mb-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            onChange={filterBrand}
                            value={searchText}
                          />
                          <span className="bx bx-search search-icon" />
                        </div>
                        <div className="check-listing-container" id='scrollableDiv' ref={scrollRef}>
                          <div className="check-listing">
                         {allBrands.length > 0 ? <InfiniteScroll
                            dataLength={allBrands.length}
                            next={fetchNextBrands}
                            hasMore={true}
                            endMessage={
                              <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                              </p>
                            }
                            scrollableTarget="scrollableDiv"
                          >
                             {allBrands.map((brand, i) => {
                               return <div className="checkbox-line" key={i}>
                               <div className="d-flex align-items-center">
                                 <input
                                   className="me-2"
                                   type="checkbox"
                                   value={brand.value}
                                   id={`brand-${brand.value}`}
                                   onClick={(e) => handleBrandCheckbox(brand, e)}
                                   checked={
                                    selectedBrandIds.includes(brand.value)
                                  }
                                  readOnly
                                  name="brands"
                                 />
                                 <label
                                   className="form-check-label"
                                   htmlFor={`brand-${brand.value}`}
                                 >
                                   {brand.label}
                                 </label>
                               </div>
                             </div>
                             })}
                          </InfiniteScroll>
                           : <div>Please select at least 1 category and sub-category to choose brands</div>}
                          </div>
                        </div>
                        </div>
                        {validation.errors.brands
                          ? (
                          <FormFeedback
                            type="invalid"
                            style={{ display: 'block' }}
                          >
                            {validation.errors.brands}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                    <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Selected Brands
                        </Label>
                        <div className="category-subcategory-container">
                            {selectedBrands.map((brand, i) => {
                              return <div key={i} className="mb-2"> <img
                              src={deleteLogo}
                              className="mx-2 cursor--p"
                              onClick={(e) => { handleDeleteBrand(e, brand) }}
                              />
                              <span>{brand.label}</span></div>
                            })}
                        </div>
                      </div>
                    </Col>
                  </Row>
                <hr></hr>
                <h2 className="new-registration-heading-blue">
                  Contact Information
                </h2>
                <Row>
                  <Col lg={6}>
                    <h3 className="new-registration-normal-heading">
                      {' '}
                      Primary / Owner
                    </h3>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            First Name*
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter First Name"
                            name="primaryFirstName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.primaryFirstName || ''}
                            invalid={
                              !!(validation.touched.primaryFirstName &&
                              validation.errors.primaryFirstName)
                            }
                          />
                          {validation.touched.primaryFirstName &&
                          validation.errors.primaryFirstName
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.primaryFirstName}
                            </FormFeedback>
                              )
                            : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Last Name*
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Last Name"
                            name="primaryLastName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.primaryLastName || ''}
                            invalid={
                              !!(validation.touched.primaryLastName &&
                              validation.errors.primaryLastName)
                            }
                          />
                          {validation.touched.primaryLastName &&
                          validation.errors.primaryLastName
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.primaryLastName}
                            </FormFeedback>
                              )
                            : null}
                        </div>
                      </Col>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Email Address*
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Email Address"
                          name="primaryEmail"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.primaryEmail || ''}
                          invalid={
                            !!(validation.touched.primaryEmail &&
                            validation.errors.primaryEmail)
                          }
                        />
                        {validation.touched.primaryEmail &&
                        validation.errors.primaryEmail
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.primaryEmail}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Mobile Number*
                        </Label>
                        <NumberFormat
                        className={
                          validation.touched.primaryPhone && validation.errors.primaryPhone
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        id="formrow-firstname-Input"
                        placeholder="Enter Mobile Number"
                        name="primaryPhone"
                        displayType="input" format="(###) ###-####"
                        value={validation.values.primaryPhone || ''}
                        onChange={ validation.handleChange}
                        />
                        {/* <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Mobile Number"
                          name="primaryPhone"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.primaryPhone || ''}
                          invalid={
                            !!(validation.touched.primaryPhone &&
                            validation.errors.primaryPhone)
                          }
                        /> */}
                        {validation.touched.primaryPhone &&
                        validation.errors.primaryPhone
                          ? (
                          <div className='error' type="invalid">
                            {validation.errors.primaryPhone}
                          </div>
                            )
                          : null}
                      </div>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">Title*</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Title"
                          name="primaryTitle"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.primaryTitle || ''}
                          invalid={
                            !!(validation.touched.primaryTitle &&
                            validation.errors.primaryTitle)
                          }
                        />
                        {validation.touched.primaryTitle &&
                        validation.errors.primaryTitle
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.primaryTitle}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <h3 className="new-registration-normal-heading">
                      Alternate
                    </h3>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            First Name
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter First Name"
                            name="alternateFirstName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.alternateFirstName || ''}
                          />
                          {/* {validation.touched.alternateFirstName &&
                          validation.errors.alternateFirstName
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.alternateFirstName}
                            </FormFeedback>
                              )
                            : null} */}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Last Name
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Last Name"
                            name="alternateLastName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.alternateLastName || ''}
                          />
                          {/* {validation.touched.alternateLastName &&
                          validation.errors.alternateLastName
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.alternateLastName}
                            </FormFeedback>
                              )
                            : null} */}
                        </div>
                      </Col>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Email Address
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Email Address"
                          name="alternateEmail"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.alternateEmail || ''}
                        />
                        {/* {validation.touched.alternateEmail &&
                        validation.errors.alternateEmail
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.alternateEmail}
                          </FormFeedback>
                            )
                          : null} */}
                      </div>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Mobile Number
                        </Label>
                        <NumberFormat
                        className={
                          validation.touched.alternatePhone && validation.errors.alternatePhone
                            ? 'form-control form-coDate Company Startedntrol is-invalid'
                            : 'form-control form-coDate Company Startedntrol'
                        }
                        id="formrow-firstname-Input"
                        placeholder="Enter Mobile Number"
                        name="alternatePhone"
                        onChange={validation.handleChange}
                        displayType="input" format="(###) ###-####"
                        value={validation.values.alternatePhone || ''}
                        />
                        {/* <Input
                          type="text"
                          className="form-coDate Company Startedntrol"
                          id="formrow-firstname-Input"
                          placeholder="Enter Mobile Number"
                          name="alternatePhone"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.alternatePhone || ''}
                        /> */}
                        {/* {validation.touched.alternatePhone &&
                        validation.errors.alternatePhone
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.alternatePhone}
                          </FormFeedback>
                            )
                          : null} */}
                      </div>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">Title</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Title"
                          name="alternateTitle"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.alternateTitle || ''}
                        />
                        {/* {validation.touched.alternateTitle &&
                        validation.errors.alternateTitle
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.alternateTitle}
                          </FormFeedback>
                            )
                          : null} */}
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <h3 className="new-registration-normal-heading">
                      Accounting
                    </h3>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            First Name
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter First Name"
                            name="accountingFirstName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.accountingFirstName || ''}
                            // invalid={
                            //   !!(validation.touched.accountingFirstName &&
                            //   validation.errors.accountingFirstName)
                            // }
                          />
                          {/* {validation.touched.accountingFirstName &&
                          validation.errors.accountingFirstName
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.accountingFirstName}
                            </FormFeedback>
                              )
                            : null} */}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Last Name
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Last Name"
                            name="accountingLastName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.accountingLastName || ''}
                            // invalid={
                            //   !!(validation.touched.accountingLastName &&
                            //   validation.errors.accountingLastName)
                            // }
                          />
                          {/* {validation.touched.accountingLastName &&
                          validation.errors.accountingLastName
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.accountingLastName}
                            </FormFeedback>
                              )
                            : null} */}
                        </div>
                      </Col>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Email Address
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Email Address"
                          name="accountingEmail"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.accountingEmail || ''}
                          // invalid={
                          //   !!(validation.touched.accountingEmail &&
                          //   validation.errors.accountingEmail)
                          // }
                        />
                        {/* {validation.touched.accountingEmail &&
                        validation.errors.accountingEmail
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.accountingEmail}
                          </FormFeedback>
                            )
                          : null} */}
                      </div>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Mobile Number
                        </Label>
                        <NumberFormat
                       className={
                        validation.touched.accountingPhone && validation.errors.accountingPhone
                          ? 'form-control form-coDate Company Startedntrol is-invalid'
                          : 'form-control form-coDate Company Startedntrol'
                      }
                        id="formrow-firstname-Input"
                        placeholder="Enter Mobile Number"
                        name="accountingPhone"
                        onChange={validation.handleChange}
                        displayType="input" format="(###) ###-####"
                        value={validation.values.accountingPhone || ''}
                        />
                        {/* <Input
                          type="text"
                          className="form-coDate Company Startedntrol"
                          id="formrow-firstname-Input"
                          placeholder="Enter Mobile Number"
                          name="accountingPhone"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.accountingPhone || ''}
                          invalid={
                            !!(validation.touched.accountingPhone &&
                            validation.errors.accountingPhone)
                          }
                        /> */}
                        {validation.touched.accountingPhone &&
                        validation.errors.accountingPhone
                          ? (
                            <FormFeedback type="invalid">
                            {validation.errors.accountingPhone}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">Title</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Title"
                          name="accountingTitle"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.accountingTitle || ''}
                          // invalid={
                          //   !!(validation.touched.accountingTitle &&
                          //   validation.errors.accountingTitle)
                          // }
                        />
                        {/* {validation.touched.accountingTitle &&
                        validation.errors.accountingTitle
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.accountingTitle}
                          </FormFeedback>
                            )
                          : null} */}
                      </div>
                    </Row>
                  </Col>
                  <Col lg={6}>
                      <h3 className="new-registration-normal-heading">
                        IT Contact
                      </h3>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">
                              First Name
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter First Name"
                              name="itFirstName"
                              value={validation.values.itFirstName || ''}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              // invalid={
                              //   !!(validation.touched.itFirstName &&
                              //   validation.errors.itFirstName)
                              // }
                            />
                            {/* {validation.touched.itFirstName &&
                            validation.errors.itFirstName
                              ? (
                        <FormFeedback type="invalid">
                          {validation.errors.itFirstName}
                        </FormFeedback>
                                )
                              : null} */}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">
                              Last Name
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Last Name"
                              name="itLastName"
                              value={validation.values.itLastName || ''}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              // invalid={
                              //   !!(validation.touched.itLastName &&
                              //   validation.errors.itLastName)
                              // }
                            />
                            {/* {validation.touched.itLastName &&
                      validation.errors.itLastName
                              ? (
                        <FormFeedback type="invalid">
                          {validation.errors.itLastName}
                        </FormFeedback>
                                )
                              : null} */}
                          </div>
                        </Col>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Email Address
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Email Address"
                            name="itEmail"
                            value={validation.values.itEmail || ''}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            // invalid={
                            //   !!(validation.touched.itLastName &&
                            //   validation.errors.itLastName)
                            // }
                          />
                          {/* {validation.touched.itEmail &&
                          validation.errors.itEmail
                            ? (
                      <FormFeedback type="invalid">
                        {validation.errors.itEmail}
                      </FormFeedback>
                              )
                            : null} */}
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Mobile Number
                          </Label>
                          <NumberFormat
                          className={
                            validation.touched.itPhone && validation.errors.itPhone
                              ? 'form-control form-coDate Company Startedntrol is-invalid'
                              : 'form-control form-coDate Company Startedntrol'
                          }
                          id="formrow-firstname-Input"
                            placeholder="Enter Mobile Number"
                            name="itPhone"
                            value={validation.values.itPhone || ''}
                            displayType="input" format="(###) ###-####"
                            onValueChange={(values) => {
                              if (values.value.length < 11) {
                                validation.setFieldValue(
                                  'itPhone',
                                  values.value
                                )
                              }
                            }}
                          />
                          {/* <Input
                            type="text"
                            className="form-coDate Company Startedntrol"
                            id="formrow-firstname-Input"
                            placeholder="Enter Mobile Number"
                            name="itPhone"
                      onChange={(e) => {
                        if (e.target.value.length < 11) {
                          validation.setFieldValue(
                            'itPhone',
                            e.target.value
                          )
                        }
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.itPhone || ''}
                      invalid={
                        !!(validation.touched.itPhone &&
                        validation.errors.itPhone)
                      }
                          /> */}
                          {validation.touched.itPhone &&
                          validation.errors.itPhone
                            ? (
                              <FormFeedback type="invalid">
                        {validation.errors.itPhone}
                      </FormFeedback>
                              )
                            : null}
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Title
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Title"
                            name="itTitle"
                      value={validation.values.itTitle || ''}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      // invalid={
                      //   !!(validation.touched.itTitle &&
                      //   validation.errors.itTitle)
                      // }
                          />
                          {/* {validation.touched.itTitle &&
                    validation.errors.itTitle
                            ? (
                      <FormFeedback type="invalid">
                        {validation.errors.itTitle}
                      </FormFeedback>
                              )
                            : null} */}
                        </div>
                      </Row>
                    </Col>
                </Row>
                <Row>
                  <Col lg={12} className="mt-2 save-cancel-div">
                    <Button
                      color="primary"
                      className="new-wd-approval-btn"
                      type="submit"
                      // onClick={validation.handleSubmit()}
                    >
                      Save and Approve
                    </Button>
                    <button
                      onClick={() => setEditProfile(false)}
                      className="new-wd-reset-btn btn"
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  )
}
