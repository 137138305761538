import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import React, { useEffect, useState } from 'react'

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label
} from 'reactstrap'

// redux
import { useSelector, useDispatch } from 'react-redux'

import { withRouter, Link } from 'react-router-dom'

// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

// actions
import { loginUser } from '../../store/actions'

// import images
import logo from 'assets/images/QLlogo.svg'
import eye from 'assets/images/view.svg'
import hide from 'assets/images/hide.svg'

// Import config
import Loader from '../../components/Loader'

const Login = (props) => {
  const dispatch = useDispatch()
  const loginSuccess = useSelector((state) => state.Login)
  const [passwordFieldType, setPasswordFieldType] = useState('password')

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      props.history.push('/dashboard')
    }
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      password: Yup.string().required('Please Enter Your Password')
    }),
    onSubmit: (values) => {
      values.step = 1
      dispatch(loginUser(values, props.history))
    }
  })

  const { error } = useSelector((state) => ({
    error: state.Login.error
  }))

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | QLS</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages d-flex justify-content-between flex-column" style={{ height: '100vh' }}>
      <div className="text-center bg-white p-4">
          <h1 className='m-0' style={{ fontWeight: '700', color: '#655b5c' }}>ADMIN PORTAL</h1>
        </div>
        <Container className='mt-5'>
          <Loader show={loginSuccess.loading} />
          <Row className="justify-content-center">
            <Col md={7} lg={5} xl={5}>
              <Card className="overflow-hidden">
                {/* <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to QLS.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div> */}
                <CardBody className="p-4">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div
                        className="avatar-md profile-user-wid mb-2 mt-1"
                        style={{ width: '100%', marginTop: '0px' }}
                      >
                        <span
                          className="avatar-title"
                          style={{ backgroundColor: 'transparent' }}
                        >
                          <img src={logo} alt="" height="42" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  {/* <div className="d-flex justify-content-center mb-2 set-password">
                    Login To Your Dashboard
                  </div> */}
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault()
                        validation.handleSubmit()
                        // return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            !!(validation.touched.email && validation.errors.email)
                          }
                        />
                        {validation.touched.email && validation.errors.email
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                            )
                          : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className='password-main'>
                        <input
                          name="password"
                          className='new-password'
                          value={validation.values.password || ''}
                          type={passwordFieldType}
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            !!(validation.touched.password &&
                            validation.errors.password)
                          }
                        />
                        {
                          passwordFieldType === 'password'
                            ? <img src={eye} className='eye-password' onClick={() => setPasswordFieldType('text')}/>
                            : <img src={hide} className='eye-password' onClick={() => setPasswordFieldType('password')}/>
                        }
                        </div>
                        {validation.touched.password &&
                        validation.errors.password
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                            )
                          : null}
                      </div>

                      {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input "
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div> */}

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted forgot-pass-hover">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div className="mt-4 text-center bg-white p-4">
          <p className="lower-privacy">
            <a
              href={process.env.REACT_APP_WEB_URL + '/page/privacy-policy'}
              rel="noreferrer"
              target="_blank"
            >
              <span className="me-4">Privacy & Policy</span>
            </a>{' '}
            <a href= {process.env.REACT_APP_WEB_URL + '/page/terms-and-conditions'}
            rel="noreferrer"
            target="_blank">
            <span>Terms of Use </span></a>
          </p>
          <p className="lower-copyright mt-4">
            Copyright © {new Date().getFullYear()} QL SUPPLY. All rights
            reserved.
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object
}
