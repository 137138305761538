import React, { useState, useEffect } from 'react'
import { Card, CardBody, Col, Container, Row, Label, Input } from 'reactstrap'
import './ProductDetail.scss'
import Breadcrumb from 'components/Common/Breadcrumb'
import { GetData, PostData } from 'apiHandler'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Loader from '../../../components/Loader'
import { useHistory, useParams } from 'react-router-dom'
import bitmap from '../../../assets/images/Bitmap.png'
import oilFilterImg from '../../../assets/images/placeholderImages/OilFilter.png'
import airFilterImg from '../../../assets/images/placeholderImages/AirFilter.png'
import cabinAirFilterImg from '../../../assets/images/placeholderImages/CabinAirFilter.png'
import fuelFilterImg from '../../../assets/images/placeholderImages/FuelFilter.png'
import transmissionFilterImg from '../../../assets/images/placeholderImages/TransmissionFilter.png'
import drainPlugImg from '../../../assets/images/placeholderImages/drainPlug.png'
import oilCapImg from '../../../assets/images/placeholderImages/oilCap.png'
import wiperBladeImg from '../../../assets/images/placeholderImages/wiperBlade.png'

export default function ProductDetail() {
  const history = useHistory()
  const { id } = useParams()
  // eslint-disable-next-line no-unused-vars
  const [product, setProduct] = useState(null)
  const [price, setPrice] = useState(null)
  const [save, setSave] = useState(false)
  const [loader, setLoader] = useState(false)
  const userObj = JSON.parse(localStorage.authUser)

  const handlePrice = (e) => {
    setPrice(e.target.value)
  }

  // function to update the price
  const handleSave = (e) => {
    e.preventDefault()
    setSave(true)
    setLoader(true)
    const url = 'admin/updateSellPrice'
    const body = {
      part_id: id,
      sell_price: price,
      BrandID: product?.BrandID
    }
    PostData(url, body)
      .then((response) => {
        toastr.success(response.message, 'Success')
        setLoader(false)
      }).catch((error) => {
        toastr.error('Error', error)
        setLoader(false)
      })
  }

  // function to go to products table on back button
  const handlePush = (e) => {
    e.preventDefault()
    history.push('/products')
  }

  useEffect(() => {
    if (id) {
      fetchProductDetails(id)
    }
  }, [id])

  const fetchProductDetails = (partId) => {
    let url = `admin/getProduct/${partId}`

    if (userObj.roleId === 3 || userObj.roleId === 4) {
      url = `vendor/getProductDetails/${partId}`
    }

    setLoader(true)
    GetData(url).then((response) => {
      if (response.status) {
        setLoader(false)

        if (userObj.roleId === 3 || userObj.roleId === 4) {
          setProduct({ ...response.result.vendorProduct, quantity: response.result.quantity })
          response.result.vendorProduct.price.cost_price && setPrice(response.result.vendorProduct.price?.cost_price)
        } else {
          setProduct(response.result)
          response.result.price.sell_price && setPrice(response.result.price?.sell_price)
        }
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
      .catch((err) => {
        toastr.error('Error', err)
      })
  }

  // ----------------------------------Conditional placeholder function---------------------------------

  const conditionalProductImg = (productData) => {

    if (productData) {
      const brandName = productData?.brands[0].BrandName
      const categoryName = productData?.partCategoryMapping?.partCategory?.categoryName
      const subCategoryName = productData?.partCategoryMapping?.partSubCategory?.subCategoryName

      if (categoryName === 'Filters' && (brandName === 'Premium Guard' || brandName === 'ECOGARD')) {
        if (subCategoryName === 'Oil Filter') {
          return oilFilterImg
        } else if (subCategoryName === 'Air Filter') {
          return airFilterImg
        } else if (subCategoryName === 'Cabin Air Filter') {
          return cabinAirFilterImg
        } else if (subCategoryName === 'Fuel Filter') {
          return fuelFilterImg
        } else if (subCategoryName === 'Transmission') {
          return transmissionFilterImg
        } else if (subCategoryName === 'Drain Plugs and Gaskets') {
          return drainPlugImg
        } else if (subCategoryName === 'Oil Filter Housing Caps') {
          return oilCapImg
        }
      } else if (categoryName === 'Wiper Blades' && (subCategoryName === 'Wiper Blades' || subCategoryName === 'Rear Wiper Blade') && (brandName === 'Premium Guard' || brandName === 'ECOGARD')) {
        return wiperBladeImg
      }
    }

    return bitmap
  }

  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <Container fluid={true}>
          <div className="d-flex justify-content-between align-items-center">
            <Breadcrumb
              breadcrumbItem={product?.partNumber || '--'}
              icon={
                <i
                  className="bx bx-arrow-back me-3"
                  onClick={handlePush}
                ></i>
              }
            />
          </div>
          <Row className="mt-3">
            <Col lg={9}>
              <Card>
                <CardBody>
                  <Label className='m-0'>Part Number</Label>
                  <p><b>{product?.partNumber || '--'}</b></p>
                  <Label className='m-0'>Category</Label>
                  <p style={{ textTransform: 'capitalize' }}><b>{product?.partCategoryMapping?.partCategory?.slug || '--'}</b></p>
                  <Label className='m-0'>Sub Category</Label>
                  <p style={{ textTransform: 'capitalize' }}><b>{product?.partCategoryMapping?.partSubCategory?.slug || '--'}</b></p>
                  <Label className='m-0'>Brand</Label>
                  <p><b>{product?.brands[0]?.BrandName || '--'}</b></p>
                  <Label className='m-0'>Product Type</Label>
                  <p><b>{product?.PartTerminology?.PartTerminologyName || '--'}</b></p>
                  <Label className='m-0'>Description</Label>
                  <p>{product?.descriptions[0]?.Description || '--'}</p>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h6><b>Media</b></h6>
                  <div className='card-images'>
                    {
                      product?.imageMapper.length > 0 ? (product?.imageMapper?.map((image, key) => {
                        return <div className='card-image-container' key={key}>
                          <img src={image.imageUrl} />
                        </div>
                      })) : <div className='card-image-container'>
                        <img src={conditionalProductImg(product)} alt='product image' />
                      </div>
                    }
                  </div>
                </CardBody>
              </Card>

            </Col>
            <Col lg={3}>
              <Card>
                <CardBody>
                  <h6><b>Pricing</b></h6>
                  <Label>{(userObj.roleId === 3 || userObj.roleId === 4) ? 'Cost' : 'Sell price'} per item</Label>
                  <div className='d-flex'>
                    <Input type="text" className="input-border" placeholder='$ 254,00' defaultValue={price} onChange={handlePrice} disabled={(userObj.roleId === 3 || userObj.roleId === 2)} />
                    {
                      (userObj.roleId === 3 || userObj.roleId === 4) ? null : <button className='pricing-save-btn ms-2 px-3' onClick={handleSave}>Save</button>
                    }
                  </div>
                  {(userObj.roleId === 3 || userObj.roleId === 4) && save && <p className='m-0 mt-2'>Price change sent for approval</p>}
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h6><b>Inventory</b></h6>
                  <Label>Quantity</Label>
                  <Input type="text" className="input-border" disabled defaultValue={product?.quantity} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
