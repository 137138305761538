import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import PdfReports from 'components/PdfReports/PdfReports'
function ReportsNewCustomerAdmin () {
  const [data, setData] = useState(null)
  const [pdfData, setPdfData] = useState(null)
  const location = useLocation()
  React.useEffect(() => {
    setData(location.state)
  }, [location])
  const columns = [
    { key: 'companyName', label: 'companyName' },
    { key: 'mobile', label: 'mobile' },
    { key: 'primaryFirstName', label: 'Name' }
  ]
  React.useEffect(() => {
    if (data) {
      setPdfData(data.data.map((item) => {
        return {
          companyName: item.companyName,
          mobile: item.mobile,
          primaryFirstName: item.primaryFirstName
        }
      }))
    }
  }, [data])
  return (
        <>
        {
          pdfData && <PdfReports
          columns={columns}
          content={pdfData}
          />
        }
        </>
  )
}
export default ReportsNewCustomerAdmin
