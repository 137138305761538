import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Document, Page, View, Text, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer'
import { GetData } from 'apiHandler'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Loader from '../../../components/Loader'
import logo from '../../../assets/images/pdf.png'
import './PdfViewer.scss'

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: '30px'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowTop: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowTopText: {
    border: '1px solid #000'
  },
  // So Declarative and unDRY 👌
  rowTop1: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop2: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop3: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop4: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop5: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  row1: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row2: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    wordBreak: 'break-word',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row3: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    wordBreak: 'break-word',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row4: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row5: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: '300',
    border: '1px solid #000'
  },
  uom: {
    fontSize: '8px',
    color: '#202020',
    width: '100%',
    display: 'block'
  },
  qty: {
    width: '100%',
    display: 'block'
  },
  image: {
    width: '150px',
    marginBottom: '25px',
    marginTop: '10px'
  }
})

// Create Document Component
const MyDocument = () => {
  const { id, slip } = useParams()
  const [loader, setLoader] = useState(false)
  const [productsData, setProductsData] = useState(null)

  useEffect(() => {
    if (id && slip) {
      if (slip === 'pickTicket') {
        fetchOrderData(id)
      } else {
        fetchPackingSlip()
      }
    }
  }, [id, slip])

  const fetchPackingSlip = () => {
    setLoader(true)
    const url = `vendor/getPackingSlip?shipmentId=${id}`
    GetData(url)
      .then((res) => {
        if (res.status) {
          setProductsData(res.result.shipmentProducts)
          setLoader(false)
        } else {
          setLoader(false)
          toastr.error(res.message, 'Error')
        }
      }).catch((err) => {
        setLoader(false)
        toastr.error(err, 'Error')
      })
  }

  const fetchOrderData = (id) => {
    setLoader(true)
    const url = `vendor/getPickTicket?shipmentId=${id}`
    GetData(url)
      .then((res) => {
        if (res.status) {
          setProductsData(res.result.shipmentProducts)
          setLoader(false)
        } else {
          setLoader(false)
          toastr.error(res.message, 'Error')
        }
      }).catch((err) => {
        setLoader(false)
        toastr.error(err, 'Error')
      })
  }

  return (
    <>
        <Loader show={loader} />
        {
            productsData && <PDFViewer>
            <Document>
      <Page size="A4" style={styles.page}>
      <Image src={logo} style={styles.image}/>
      <View style={styles.rowTop}>
        <Text style={styles.rowTop1}>Part Number</Text>
        <Text style={styles.rowTop2}>Brand</Text>
        <Text style={styles.rowTop3}>Type</Text>
        <Text style={styles.rowTop4}>Qty to Ship</Text>
        <Text style={styles.rowTop5}>{
            slip === 'pickTicket' ? 'Qty Ordered' : 'Uom'
        }</Text>
      </View>
      {productsData.map((product, i) => (
        <View key={i} style={styles.row} wrap={false}>
          <Text style={styles.row1}>
          {product.shipmentProductData.partNumber}
          </Text>
          <Text style={styles.row2}>{product.shipmentProductData.brands.BrandName}</Text>
          <Text style={styles.row3}>
          {product.shipmentProductData.PartTerminology.PartTerminologyName}</Text>
          <Text style={styles.row4}>
          {product.shipmentQuantity}
          </Text>
          <Text style={styles.row5}>
            {
              product.orderedQuantity !== undefined && <Text style={styles.qty}>{product.orderedQuantity}{'\n'}</Text>
            }
                <Text>{product.uom}</Text>
          </Text>
        </View>
      ))}
      </Page>
    </Document>
        </PDFViewer>
        }
    </>
  )
}

export default MyDocument
