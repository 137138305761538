/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Input, Label, Row, Form, Button } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { useHistory, Link, useParams } from 'react-router-dom'
// import { Editor } from 'react-draft-wysiwyg'
import Dropzone from 'react-dropzone'
import { GetData, PutData, PostData } from 'apiHandler'
import Loader from '../../components/Loader'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { Editor } from '@tinymce/tinymce-react'

export function slugify (text) {
  return text
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}

function AddNewServices () {
  const history = useHistory()
  const { id } = useParams()
  const [loader, setLoader] = useState(false)
  const [editorContent, setEditorContent] = useState()
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [serviceData, setServiceData] = useState({})
  const [status, setStatus] = useState(null)
  const [imageError, setImageError] = useState({
    titleImage: '',
    bannerImage1: '',
    bannerImage2: ''
  })
  const [serviceImages, setServiceImages] = useState({
    titleImage: null,
    bannerImage1: null,
    bannerImage2: null
  })

  function handleChange (type, e) {
    e.preventDefault()
    if (type === 'tags') {
      // do something
    } else if (type === 'image') {
      if (e.target.files[0].size >= 500000) {
        setImageError(prevState => ({ ...prevState, [e.target.name]: 'Please Upload Small Size File' }))
      } else {
        setImageError(prevState => ({ ...prevState, [e.target.name]: '' }))
        setLoader(true)
        const formImage = new FormData()
        formImage.append('image', e.target.files[0])
        PostData('admin/uploadServiceImage', formImage).then((response) => {
          if (response.status === true) {
            setLoader(false)
            setServiceData({
              ...serviceData,
              [e.target.name]: response.result.newFile
            })
            setServiceImages(prevState => ({ ...prevState, [e.target.name]: response.result.url }))
          }
        })
      }
    } else if (e.target.name === 'title') {
      setServiceData({
        ...serviceData,
        [e.target.name]: e.target.value,
        slug: slugify(e.target.value)
      })
    } else setServiceData({ ...serviceData, [e.target.name]: e.target.value })
  }

  function resetUploadBanner (name, e) {
    e.preventDefault()
    setServiceData({
      ...serviceData,
      [name]: null
    })
    setServiceImages(prevState => ({
      ...prevState,
      [name]: null
    }))
  }

  function handleAcceptedFiles (name, files) {
    setLoader(true)
    if (files[0].size >= 500000) {
      setImageError('Please Upload Small Size File')
      setLoader(false)
    } else {
      setImageError('')
      const formImage = new FormData()
      formImage.append('image', files[0])
      PostData('admin/uploadServiceImage', formImage).then((response) => {
        if (response.status === true) {
          setLoader(false)
          setServiceData({
            ...serviceData,
            [name]: response.result.newFile
          })
          setServiceImages(prevState => ({ ...prevState, [name]: response.result.url }))
        }
      })
    }
  }

  function handleSubmit (e) {
    e.preventDefault()
    setLoader(true)
    const formData = {
      ...serviceData,
      description: editorContent
    }
    id
      ? PutData(`admin/updateService/${id}`, formData).then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Sent')
          history.push('/services')
          setLoader(false)
        } else {
          toastr.error(response.message, 'Error')
          setLoader(false)
        }
      })
      : PostData('admin/addService', formData).then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Sent')
          history.push('/services')
          setLoader(false)
        } else {
          toastr.error(response.message, 'Error')
          setLoader(false)
        }
      })
  }

  const getServiceById = (id) => {
    setLoader(true)
    GetData(`admin/getService/${id}`).then((response) => {
      if (response.status === true) {
        setStatus(response.result.status)
        setServiceData(response.result)
        setLoader(false)
        // const descriptionDataState = ContentState.createFromBlockArray(
        //   htmlToDraft(`${response.result.description}`)
        // )
        // setEditorState(EditorState.createWithContent(descriptionDataState))
        setEditorContent(response.result.description)
      }
    })
  }

  useEffect(() => {
    if (id) {
      getServiceById(id)
    }
  }, [id])

  const handleUpload = async (file) => {
    setLoader(true)
    const formImage = new FormData()
    formImage.append('image', file)
    const response = await PostData('admin/uploadPostImage', formImage)
    if (response) {
      setLoader(false)
    }
    return response.status ? response.result.url : toastr.error(response.message, 'Error')
  }

  return (
        <>
        <Loader show={loader}></Loader>
        <div className="page-content">
            <Container fluid={true} className="homepage-wrap" >
                <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <Breadcrumbs
              title="UI Elements"
              breadcrumbItem={id ? 'Edit Service' : 'Add New Service'}
              icon={
                <i
                  className="bx bx-arrow-back me-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push('/services')}
                ></i>
              }
            />
             <a
              href={`${process.env.REACT_APP_WEB_URL}/service-opportunities/${serviceData.slug}`}
              target={'_blank'} rel="noreferrer"
            >
              {status === 1 && 'View on Website'}
            </a>
            </div>
            <Row className='mt-3'>
                <Col lg={9}>
                <Card>
                    <CardBody>
                        <div className="mb-3">
                            <Label className="form-label">
                            <b>Title</b>
                            </Label>
                            <Input
                            placeholder="Enter Title"
                            type="  "
                            id="title"
                            name="title"
                            className="input-border"
                            value={serviceData.title}
                            onChange={(e) => handleChange('', e)}
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">
                            <b>SubTitle</b>
                            </Label>
                            <Input
                            placeholder="Enter subtitle"
                            type="  "
                            id="subTitle"
                            name="subTitle"
                            className="input-border"
                            value={serviceData.subTitle}
                            onChange={(e) => handleChange('', e)}
                            />
                        </div>
                        <div className="mb-3">
                      <Label className="form-label" htmlFor="slug">
                        <b>Slug</b>
                      </Label>
                      <Input
                        id="slug"
                        placeholder="Enter Slug"
                        type="text"
                        name="slug"
                        className="input-border"
                        value={serviceData.slug}
                        onChange={(e) => handleChange('', e)}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="slug">
                        <b>Heading</b>
                      </Label>
                      <Input
                        id="heading"
                        placeholder="Enter heading"
                        type="text"
                        name="heading"
                        className="input-border"
                        value={serviceData.heading}
                        onChange={(e) => handleChange('', e)}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="slug">
                        <b>Heading Bold</b>
                      </Label>
                      <Input
                        id="headingBold"
                        placeholder="Enter heading bold"
                        type="text"
                        name="headingBold"
                        className="input-border"
                        value={serviceData.headingBold}
                        onChange={(e) => handleChange('', e)}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="slug">
                        <b>Excerpt</b>
                      </Label>
                      <textarea
                        id="paragraph"
                        placeholder="Enter paragraph"
                        type="text"
                        name="paragraph"
                        className="input-border w-100 rounded p-2"
                        value={serviceData.paragraph}
                        onChange={(e) => handleChange('', e)}
                        rows={6}
                      />
                    </div>
                        <div className="mb-3">
                            <Label className="form-label">
                            <b>Body</b>
                            </Label>
                            <Form method="post">
                            <Editor
                        value={editorContent}
                        onEditorChange={e => setEditorContent(e)}
                        apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                        init={{
                          height: 350,
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                            'table', 'image', 'link'
                          ],
                          toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist | ' +
                            'table |link | image',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          default_link_target: '_blank',
                          automatic_uploads: true,
                          file_picker_types: 'image',
                          image_uploadtab: true,
                          file_picker_callback: function (callback, value, meta) {
                            if (meta.filetype === 'image') {
                              const input = document.createElement('input')
                              input.setAttribute('type', 'file')
                              input.setAttribute('accept', 'image/*')
                              input.click()
                              input.onchange = async function () {
                                const file = input.files[0]
                                const reader = new FileReader()
                                const fileUrl = await handleUpload(file)
                                callback(fileUrl, {
                                  alt: file.name
                                })
                                reader.readAsDataURL(file)
                              }
                            }
                          }
                        }}
                      />

                        {/* <Editor
                          editorState={editorState}
                          toolbarClassName="articleToolbar"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) => {
                            setEditorState(e)
                          }}
                        /> */}
                      </Form>
                        </div>
                        <div className="mb-3">
                      <Label className="form-label" htmlFor="sortDescription">
                        <b>Block Quote</b>
                      </Label>
                      <textarea
                        id="sortDescription"
                        className="form-control description-height input-border"
                        name="sortDescription"
                        value={serviceData.sortDescription}
                        onChange={(e) => handleChange('', e)}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="banner1Link">
                        <b>CTA 1 Link</b>
                      </Label>
                      <Input
                        id="banner1Link"
                        type="url"
                        name="banner1Link"
                        className="input-border"
                         onChange={(e) => handleChange('', e)}
                         value={serviceData.banner1Link}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="banner2Link">
                        <b>CTA 2 Link</b>
                      </Label>
                      <Input
                        id="banner2Link"
                        type="url"
                        name="banner2Link"
                        className="input-border"
                         onChange={(e) => handleChange('', e)}
                         value={serviceData.banner2Link}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="videoLink">
                        <b>Video Link</b>
                      </Label>
                      <Input
                        id="videoLink"
                        type="text"
                        name="videoLink"
                        className="input-border"
                         onChange={(e) => handleChange('', e)}
                         value={serviceData.videoLink}
                      />
                    </div>
                    </CardBody>
                </Card>
                    <Link to="/services">
                <Button className="btn btn-cancel mb-4 ms-0">Cancel</Button>
              </Link>
              <Button
                className="btn btn-add-new mb-4 ms-0"
                onClick={handleSubmit}
              >
                Save
              </Button>
                </Col>
                <Col lg={3}>
                    <Card>
                        <CardBody>
                        <Label className="font-weight-bold">
                        <b>Title Image</b>
                        </Label>
                        <div className="form-outer-dropzone services-dropZone-container">
                        <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles('titleImage', acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone drop-zone-container-left ">
                          <div
                            className="dz-message needsclick mt-2 p-0"
                            {...getRootProps()}
                          >
                            <input
                              {...getInputProps()}
                              onChange={(e) => handleChange('image', e)}
                              name="titleImage"
                            />
                            <div className="">
                              <i className="display-4 text-muted bx bxs-cloud-upload drop-zone-icon" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {serviceData?.titleImage && (
                      <div className="preview-container">
                        <div className="file-preview-container">
                          <div className="file-box">
                            <img
                              data-dz-thumbnail=""
                              className="avatar-sm"
                              src={serviceImages?.titleImage || serviceData?.titleImageUrl}
                              name="postImage"
                            />
                          </div>
                          <div
                            className="cross-btn-upload"
                            onClick={(e) => {
                              resetUploadBanner('titleImage', e)
                            }}
                          >
                            X
                          </div>
                        </div>
                      </div>
                    )}
                        </div>
                        <div className="error mt-2">{imageError.titleImage}</div>
                        <div className="image-supported-text align-self-end mt-2">
                    <div>Format Supported: Jpeg, JPG, PNG, GIF</div>
                    <div>Preferred Dimensions: 400x466 (pixels) </div>
                    <div>Maximum Size: 500kb </div>
                  </div>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                        <Label className="font-weight-bold">
                        <b>CTA Image 1</b>
                        </Label>
                        <div className="form-outer-dropzone services-dropZone-container">
                        <Dropzone
                     onDrop={(acceptedFiles) => {
                       handleAcceptedFiles('bannerImage1', acceptedFiles)
                     }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone drop-zone-container-left ">
                          <div
                            className="dz-message needsclick mt-2 p-0"
                            {...getRootProps()}
                          >
                            <input
                              {...getInputProps()}
                              onChange={(e) => handleChange('image', e)}
                              name="bannerImage1"
                            />
                            <div className="">
                              <i className="display-4 text-muted bx bxs-cloud-upload drop-zone-icon" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {serviceData?.bannerImage1 && (
                      <div className="preview-container">
                        <div className="file-preview-container">
                          <div className="file-box">
                            <img
                              data-dz-thumbnail=""
                              className="avatar-sm"
                              src={serviceImages?.bannerImage1 || serviceData?.bannerImage1Url}
                              name="postImage"
                            />
                          </div>
                          <div
                            className="cross-btn-upload"
                            onClick={(e) => {
                              resetUploadBanner('bannerImage1', e)
                            }}
                          >
                            X
                          </div>
                        </div>
                      </div>
                    )}
                        </div>
                        <div className="error mt-2">{imageError.bannerImage1}</div>
                        <div className="image-supported-text align-self-end mt-2">
                    <div>Format Supported: Jpeg, JPG, PNG, GIF</div>
                    <div>Preferred Dimensions: 320x320 (pixels) </div>
                    <div>Maximum Size: 500kb </div>
                  </div>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                        <Label className="font-weight-bold">
                        <b>CTA Image 2</b>
                        </Label>
                        <div className="form-outer-dropzone services-dropZone-container">
                        <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles('bannerImage2', acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone drop-zone-container-left ">
                          <div
                            className="dz-message needsclick mt-2 p-0"
                            {...getRootProps()}
                          >
                            <input
                              {...getInputProps()}
                              onChange={(e) => handleChange('image', e)}
                              name="bannerImage2"
                            />
                            <div className="">
                              <i className="display-4 text-muted bx bxs-cloud-upload drop-zone-icon" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {serviceData?.bannerImage2 && (
                      <div className="preview-container">
                        <div className="file-preview-container">
                          <div className="file-box">
                            <img
                              data-dz-thumbnail=""
                              className="avatar-sm"
                              src={serviceImages?.bannerImage2 || serviceData?.bannerImage2Url}
                              name="postImage"
                            />
                          </div>
                          <div
                            className="cross-btn-upload"
                            onClick={(e) => {
                              resetUploadBanner('bannerImage2', e)
                            }}
                          >
                            X
                          </div>
                        </div>
                      </div>
                    )}
                        </div>
                        <div className="error mt-2">{imageError.bannerImage2}</div>
                        <div className="image-supported-text align-self-end mt-2">
                    <div>Format Supported: Jpeg, JPG, PNG, GIF</div>
                    <div>Preferred Dimensions: 320x320 (pixels) </div>
                    <div>Maximum Size: 500kb </div>
                  </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            </Container>
        </div>
        </>
  )
}
export default AddNewServices
