/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Container, Button, Row, Col, Table, Card, CardBody, Modal } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Link, useHistory } from 'react-router-dom'
import { GetData, DeleteData } from 'apiHandler'
import Loader from '../../components/Loader'
import delIcon from '../../assets/images/ic_delete.svg'
import toastr from 'toastr'
import moment from 'moment'
import 'toastr/build/toastr.min.css'

function Industry () {
  const history = useHistory()
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)
  const [totalPages, setTotalPages] = useState(null)
  const [page, setPage] = useState(1)
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const [indexId, setIndexId] = useState({ id: null, index: null })

  function formatText (str) {
    str = str?.replace(/(<([^>]+)>)/ig, '')
    return str.length > 250 ? str.substr(0, 250) + '...' : str
  }

  const getAllIndustry = () => {
    const pageSize = 20
    setLoader(true)
    GetData(
      `admin/industryLinks?page=${page}&pageSize=${pageSize}`
    ).then((response) => {
      if (response.status === true) {
        setLoader(false)
        response.result.industryLinks.forEach((item) => {
          item.description = formatText(item.description)
        })
        setData(response.result.industryLinks)
        setTotalPages(Math.ceil(response.result.total / pageSize))
      } else {
        setLoader(false)
      }
    })
  }

  useEffect(() => {
    getAllIndustry()
  }, [page])

  function handleSearch (e) {
    e.preventDefault()
    const pageSize = 20
    GetData(
      `admin/industryLinks?page=${page}&pageSize=${pageSize}&serach=${e.target.value}`
    ).then((response) => {
      if (response.status === true) {
        setData(response.result.industryLinks)
      } else {
        // toastr.warning("Post Deleted", "Deleted");
      }
    })
  }

  function handlePage (pageNo, e) {
    e.preventDefault()
    setPage(pageNo)
  }

  function handleDelete (id, e) {
    e.preventDefault()
    const pageSize = 20
    setLoader(true)
    DeleteData(`admin/industryLink/${id}`).then((response) => {
      if (response.status === true) {
        GetData(
          `admin/industryLinks?page=${page}&pageSize=${pageSize}`
        ).then((response) => {
          if (response.status === true) {
            setData(response.result.industryLinks)
            setLoader(false)
            setIsDisableOpen(false)
            toastr.success('Industry Link Deleted', 'Deleted')
          } else {
            setLoader(false)
            toastr.error(response.message)
            setIsDisableOpen(false)
          }
        })
      } else {
        setLoader(false)
      }
    })
  }

  return (
        <>
        <Loader show={loader}></Loader>
        <div className="page-content">
        <Container fluid={true} className="homepage-wrap">
        <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <Breadcrumbs
              title="UI Elements"
              breadcrumbItem="Industry"
            />
            <div>
              <div className="d-flex align-items-center knowledge-base-button-container">
                <Link to="/add-industry-link">
                  <Button color="primary" className="btn btn-add-new">
                    Add New
                  </Button>
                </Link>
              </div>
            </div>
          </div>

          <Row>
            <Col lg={12} style={{ textAlign: '-webkit-right' }}>
              <Card>
                <CardBody>
                  <Col lg={12} className="pb-2 text-right">
                    <div className="search-form">
                      <input
                        className="form-control input-border"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => handleSearch(e)}
                      />
                      <i className="bx bx-search-alt-2 search-icon"></i>
                    </div>
                  </Col>

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="table-light">
                        <tr>
                          <th>Title</th>
                          <th>Short Description</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                            data?.length > 0
                              ? <>
                                 {data?.map((details, index) => {
                                   return (
                            <tr key={index} style={{ verticalAlign: 'middle' }}>
                              <td
                                onClick={(e) => {
                                  e.preventDefault()
                                  history.push('/industry-links/' + details.id)
                                }}
                              >
                                {details.title}
                              </td>
                              <td
                                onClick={(e) => {
                                  e.preventDefault()
                                  history.push('/industry-links/' + details.id)
                                }}
                              >
                                {details.description}
                              </td>
                              <td
                                onClick={(e) => {
                                  e.preventDefault()
                                  history.push('/industry-links/' + details.id)
                                }}
                              >
                                {moment(details.createdAt).format('MMMM Do YYYY')}
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                <i
                                  className="bx bxs-edit-alt icons me-2"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    history.push('/industry-links/' + details.id)
                                  }}
                                ></i>
                                <img
                                  src={delIcon}
                                  className="del--icon"
                                  onClick={() => {
                                    // eslint-disable-next-line no-unused-expressions
                                    setIsDisableOpen(true)
                                    setIndexId({
                                      id: details.id,
                                      index
                                    })
                                  }}
                                />
                                </div>
                              </td>
                            </tr>
                                   )
                                 })}
                            </>
                              : <tr><td colSpan={4}>No record found</td></tr>
                        }
                      </tbody>
                    </Table>
                    {data?.total > 20 && (
                      <div className="d-flex align-items-center justify-content-center mt-3 mb-1">
                        <div
                          className="pagination-box cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault()
                            page !== 1 && setPage(page - 1)
                          }}
                        >
                          <i
                            className="fas fa-angle-left "
                            style={{ fontSize: '18px' }}
                          ></i>{' '}
                        </div>
                        {Array.from(Array(totalPages), (e, index) => {
                          return (
                            <div
                              className={`pagination-box cursor-pointer ${
                                page === index + 1 && 'pagination-box-active'
                              }`}
                              onClick={(event) => handlePage(index + 1, event)}
                              key={index}
                            >
                              {index + 1}
                            </div>
                          )
                        })}
                        <div
                          className="pagination-box cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault()
                            page !== totalPages && setPage(page + 1)
                          }}
                        >
                          <i
                            className="fas fa-angle-right cursor-pointer"
                            style={{ fontSize: '18px' }}
                          ></i>{' '}
                        </div>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            className="modal-main-body"
            isOpen={isDisableOpen}
            toggle={() => setIsDisableOpen(false)}
            centered
          >
            <div className="modal-header">
              <button
                type="button"
                onClick={() => {
                  setIsDisableOpen(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="disable-warehouse">
                Are you sure you want to Delete this Industry Link?
              </p>
              <div className="modal-detail-div">
                <p className="modal-details">Details</p>
                <p className="modal-warehouse-name">
                  <span className="warehouse-heading"> Title: </span>{' '}
                  {data?.[indexId.index]?.title}
                </p>
                <p className="modal-warehouse-name">
                  <span className="warehouse-heading"> Date </span>{' '}
                  {moment(data?.[indexId.index]?.createdAt).format('MMMM Do YYYY')}
                </p>
              </div>
              <div className="modal-btn-div">
                <button
                  className="warehouse-btn-yes"
                  onClick={(e) => handleDelete(indexId.id, e)}
                >
                  Yes
                </button>
                <button
                  className=" warehouse-btn-no"
                  onClick={() => setIsDisableOpen(false)}
                >
                  No
                </button>
              </div>
            </div>
          </Modal>
        </Container>
        </div>
        </>
  )
}
export default Industry
