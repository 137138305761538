/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  TabContent,
  TabPane,
  Modal
} from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { GetData } from '../../apiHandler'
import TableComponent from 'components/Table'
import Loader from '../../components/Loader'
import toastr from 'toastr'

const WarehouseTable = () => {
  // eslint-disable-next-line no-unused-vars
  const [currentRole, setCurrentRole] = useState(1)
  const [singlebtn1, setSinglebtn1] = useState(false)
  const [activeTab, setActiveTab] = useState('0')
  const [loader, setLoader] = useState(false)
  const [tableData, setTableData] = useState([])
  const [filterValue, setFilterValue] = useState('All')
  const [countValues, setCountValues] = useState(null)
  const [searchValue, setSearchValue] = useState(null)
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const [selectedData, setSelectedData] = useState(null)
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })

  const filterValueArray = [
    'All',
    'Warehouse Name',
    'Contact Person',
    'Location',
    'Status'
  ]
  const history = useHistory()
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })

  const paginationHandler = (page) => {
    fetchDataByStatus(activeTab, page)
  }

  const columns = [
    { key: 'locationName', label: 'Warehouse Name', sorting: true },
    { key: 'contactPerson', label: 'Contact Person', sorting: true },
    { key: 'address', label: 'Address', sorting: false },
    { key: 'customercount', label: 'Customer Assigned', sorting: false },
    { key: 'status', label: 'Status', sorting: false },
    { key: 'action', label: 'View/Active', sorting: false }
  ]

  useEffect(() => {
    if (localStorage.authUser) {
      const userObj = JSON.parse(localStorage.authUser)
      setCurrentRole(userObj.roleId)
    }
    countData()
  }, [])

  // wait for count values to generate
  useEffect(() => {
    if (countValues) {
      fetchTableData(null, 1)
    }
  }, [countValues])

  // run use effect to filter data by search values
  useEffect(() => {
    if (searchValue) {
      const filterForApi = filterValue.toLowerCase().replaceAll(/\s/g, '')
      filterData(searchValue, filterForApi, 1)
    }
  }, [searchValue, filterValue])

  const handleActions = (key, data) => {
    if (key === 'disable' || key === 'enable') {
      setIsDisableOpen(true)
      setSelectedData(data)
    }
    if (key === 'view') {
      history.push(`/warehouse-profile/${data?.id}`)
    }
  }

  const handleRow = (data) => {
    history.push(`/warehouse-profile/${data?.id}`)
  }

  const manipulateData = (data) => {
    const dataArray = []
    data?.map((row) => {
      // set actions for row
      const temp = ['view']
      if (row?.status === 1) {
        temp.push('disable')
      } else if (row?.status === 4) {
        temp.push('enable')
      }

      // set status with class names
      let statusString = <div className="pending-div">Pending</div>
      if (row.status === 1) {
        statusString = <div className="active-div">Active</div>
      }
      if (row.status === 2) {
        statusString = <div className="approved-div">Approved</div>
      }
      if (row.status === 3) {
        statusString = <div className="rejected-div">Rejected</div>
      }
      if (row.status === 4) {
        statusString = <div className="disabled-div">Disabled</div>
      }

      const rowData = {
        id: row?.id,
        locationName: row?.locationName,
        contactPerson: `${row?.contactFirstName} ${row?.contactLastName}`,
        address: `${row?.shippingStreet}, ${row?.cities?.name}, ${row?.states?.name}`,
        customercount: row?.customersCount,
        status: statusString,
        action: temp
      }
      dataArray.push(rowData)
    })
    setTableData(dataArray)
  }

  // filter table data based on search, filter type and tab selected
  const filterData = (search, filter, p) => {
    const pageSize = 20
    const url = `vendor/getWarehouseLocations?filterBy=${filter}&search=${search}&page=${p}&pageSize=${pageSize}`

    setLoader(true)
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status) {
        // manipulate and set data for table
        manipulateData(response?.result?.data)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  // get count of warhouse location with respect to status
  const countData = () => {
    const url = 'vendor/getLocationsCount'
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status) {
        setCountValues(response?.result?.data)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  // fetching data for different tables
  const fetchTableData = (status, p) => {
    const pageSize = 20
    let url = `vendor/getWarehouseLocations?${
      status ? 'status=' + status + '&' : ''
    }page=${p}&pageSize=${pageSize}`

    if (sortingState.sortColumn != null) {
      url +=
          '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order
    }

    setLoader(true)
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status) {
        // manipulate and set data for table
        manipulateData(response?.result?.data)
        if (status) {
          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: countValues[status]
          })
        } else {
          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: countValues?.all
          })
        }
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const fetchDataByStatus = (tab, p) => {
    if (tab == '0') {
      fetchTableData(null, p)
    } else if (tab == '1') {
      fetchTableData('active', p)
    } else if (tab == '3') {
      fetchTableData('rejected', p)
    } else if (tab == '4') {
      fetchTableData('disabled', p)
    } else if (tab == '5') {
      fetchTableData('pending', p)
    }
  }

  const toggleTab = (tab) => {
    if (activeTab != tab) {
      setActiveTab(tab)
      fetchDataByStatus(tab, 1)
    }
  }

  // change status of warehouse location
  const handleLocationStatus = (e, locationId) => {
    e.preventDefault()
    setLoader(true)
    const newUrl = `vendor/changeWarehouseLocationStatus/${locationId}`
    GetData(newUrl).then((response) => {
      if (response.status === true) {
        setLoader(false)
        setIsDisableOpen(false)
        fetchDataByStatus(activeTab, 1)
        toastr.success(response.message, 'Success')
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  useEffect(() => {
    sortingState.sortColumn && sortingState.order && fetchDataByStatus(activeTab, 1)
  }, [sortingState])

  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn == column) {
      if (sortingState.order == 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }

  //   const handleRemoveSearchWord = () => {
  //     setSearchText("");
  //     filterByKeyObject.string = "";
  //     getWds(1);
  //   };

  return (
    <>
      <Loader show={loader}></Loader>
      <div className="page-content">
        <Container fluid={true} className="customer-container">
          <div className="staff-management-main">
            <span className="staff-management-heading">
              Warehouse Management
            </span>
            <div>
              <button className="import--btn">Import</button>
              <button className="import--btn">Export</button>
              <Link to="/Add-warehouse">
                <button className="add-btn add-btn-warehouse">
                  + Add New Warehouse
                </button>
              </Link>
            </div>
          </div>

          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="warehouse-navbar">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            toggleTab('0')
                          }}
                          className={`tab-border ${classnames({
                            active: activeTab === '0'
                          })}`}
                        >
                          All {countValues && `(${countValues.all})`}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            toggleTab('4')
                          }}
                          className={`tab-border ${classnames({
                            active: activeTab === '4'
                          })}`}
                        >
                          Disabled {countValues && `(${countValues.disabled})`}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            toggleTab('5')
                          }}
                          className={`tab-border ${classnames({
                            active: activeTab === '5'
                          })}`}
                        >
                          Pending {countValues && `(${countValues.pending})`}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            toggleTab('1')
                          }}
                          className={`tab-border ${classnames({
                            active: activeTab === '1'
                          })}`}
                        >
                          Active {countValues && `(${countValues.active})`}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            toggleTab('3')
                          }}
                          className={`tab-border ${classnames({
                            active: activeTab === '3'
                          })}`}
                        >
                          Rejected {countValues && `(${countValues.rejected})`}
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div className="d-flex drop-search">
                      <Dropdown
                        isOpen={singlebtn1}
                        toggle={() => setSinglebtn1(!singlebtn1)}
                      >
                        <DropdownToggle className="customer-select text-dark">
                          Filter By {filterValue}{' '}
                          <span className="font-weight-bold"></span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {filterValueArray.map((value, i) => {
                            return (
                              <DropdownItem
                                key={i}
                                onClick={() => setFilterValue(value)}
                              >
                                {value}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      <div className="customer-search-form">
                        <input
                          className="form-control input-border customer-input-border"
                          type="text"
                          placeholder="Search"
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <i className="bx bx-search-alt-2 search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <hr className=" mt-0 mb-4 tab-hr"></hr>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={activeTab}>
                      <div className="table-responsive">
                        <TableComponent
                          columns={columns}
                          content={tableData}
                          handleActions={handleActions}
                          paging={paging}
                          paginationHandler={paginationHandler}
                          onClick={handleRow}
                          sorting={handleSort}
                          arrowState={sortingState}
                        />
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            className="modal-main-body"
            isOpen={isDisableOpen}
            toggle={() => setIsDisableOpen(false)}
            centered
          >
            <div className="modal-header">
              <button
                type="button"
                onClick={() => {
                  setIsDisableOpen(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="disable-warehouse">
                Are you sure you want to{' '}
                {selectedData?.status?.props?.children === 'Active'
                  ? ' Disable'
                  : ' Enable'}{' '}
                Warehouse?
              </p>
              <div className="modal-detail-div">
                <Row>
                  <Col lg={12}>
                    <div className="location-container-inner">
                      <p className="location-details-heading">
                        <span style={{ fontWeight: '700' }}>Details</span>
                      </p>
                      <div>
                        <div>
                          <p>
                            Warehouse Name:{' '}
                            <span style={{ fontWeight: '700' }}>
                              {selectedData ? selectedData.locationName : '--'}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p>
                            Contact Person:{' '}
                            <span style={{ fontWeight: '700' }}>
                              {selectedData?.contactPerson
                                ? selectedData?.contactPerson
                                : ''}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p>
                            Address:{' '}
                            <span style={{ fontWeight: '700' }}>
                              {selectedData?.address}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="modal-btn-div">
                <button
                  className="warehouse-btn-yes"
                  onClick={(e) => handleLocationStatus(e, selectedData?.id)}
                >
                  Yes
                </button>
                <button
                  className="warehouse-btn-yes warehouse-btn-no"
                  onClick={() => setIsDisableOpen(false)}
                >
                  No
                </button>
              </div>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  )
}
export default WarehouseTable
