import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import PdfReports from 'components/PdfReports/PdfReports'

function ReportsBillingHistoryPdfVendor () {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(null)
  const [pdfData, setPdfData] = useState(null)
  const location = useLocation()
  React.useEffect(() => {
    setData(location.state)
  }, [location])

  const columns = [
    { key: 'po_number', label: 'P.O. No' },
    { key: 'orderNumber', label: 'Order No' },
    { key: 'costPriceTotal', label: 'Order Amount' },
    { key: 'customerName', label: 'Customer Name' }
  ]

  React.useEffect(() => {
    if (data) {
      setPdfData(data.data.orders.map((item) => {
        return {
          orderNumber: item.parentOrder.orderNumber,
          costPriceTotal: item.costPriceTotal,
          customerName: item.parentOrder.customer.primaryFirstName + ' ' + item.parentOrder.customer.primaryLastName,
          po_number: item.parentOrder.po_number,
        }
      }))
    }
  }, [data])

  return (
        <>
        {
          pdfData && <PdfReports
          columns={columns}
          content={pdfData}
          />
        }
        </>
  )
}
export default ReportsBillingHistoryPdfVendor
