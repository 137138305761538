import axios from 'axios'
import accessToken from './jwt-token-access/accessToken'

// pass new generated access token here
const token = accessToken

// apply base url for axios
const API_URL = process.env.REACT_APP_API_URL

const axiosApi = axios.create({
  baseURL: API_URL
})

axiosApi.defaults.headers.common.Authorization = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get (url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post (url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response)
    .catch(function (error) {
      console.log('error', JSON.parse(JSON.stringify(error)))
      if (error.code === 'ECONNABORTED') {
        return { code: 505, status: false, message: 'Api request timed out. Please try again!' }
      } else {
        return { code: 200, status: false, message: "Sorry, we didn't recognize those details, please try again" }
      }
    })
}

export async function put (url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del (url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
