/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import { Label, Input } from 'reactstrap'
import CrossIcon from '../../assets/images/cross2.svg'
import { GetData, PutData, ToesterSettings } from 'apiHandler'
import PropTypes from 'prop-types'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Loader from '../../components/Loader'

const NewArrivals = (props) => {
  toastr.options = ToesterSettings
  const [checklist, setChecklist] = useState(null)
  const [newArrival, setNewArrival] = useState(null)
  const [accordian, setAccordian] = useState([])
  const [items, setItems] = useState({ categories: [], subCategories: [] })
  const [allCategory, setAllCategory] = useState({ categories: [], subCategories: [] })
  const [selectedSubCategories, setSelectedSubCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState({})
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    getCategories()
  }, [])

  const getCategories = () => {
    GetData('admin/getCategoriesForAdmin').then((response) => {
      if (response.status === true) {
        setChecklist(response.result)
        const allCategory = { categories: [], subCategories: [] }
        response.result.map((data, index) => {
          allCategory.categories.push(data)
          if (data.children.length) {
            data.children.map((child) => {
              allCategory.subCategories.push(child)
            })
          }
          setAccordian([...accordian, (accordian[index] = true)])
        })

        setAllCategory(allCategory)
      }
    })
  }

  function handleCategory (id, index, e) {
    if ((items.categories.length + items.subCategories.length) < 5) {
      if (!e.target.checked) {
        setSelectedCategories(prevState => ({
          ...prevState,
          [id]: {
            isParentCategorySelected: true,
            subCategories: [...checklist[index].children.map((item) => { return item.subcategoryID })]
          }
        }))
        setSelectedSubCategories(prevState => ([...prevState, ...checklist[index].children.map((item) => { return item.subcategoryID })]))
      } else {
        const categoryTemp = { ...selectedCategories }
        delete categoryTemp[id]
        setSelectedCategories(categoryTemp)
        const tempSubcategories = Object.keys(categoryTemp).map((item) => { return categoryTemp[item].subCategories })
        setSelectedSubCategories(tempSubcategories.flat())
      }
    } else if ((items.categories.length + items.subCategories.length) == 5) {
      if (e.target.checked) {
        const categoryTemp = { ...selectedCategories }
        delete categoryTemp[id]
        setSelectedCategories(categoryTemp)
        const tempSubcategories = Object.keys(categoryTemp).map((item) => { return categoryTemp[item].subCategories })
        setSelectedSubCategories(tempSubcategories.flat())
      } else {
        e.preventDefault()
      }
    } else {
      e.preventDefault()
    }
  }

  function handleDeleteCategory (id) {
    const categoryTemp = { ...selectedCategories }
    delete categoryTemp[id]
    setSelectedCategories(categoryTemp)
    const tempSubcategories = Object.keys(categoryTemp).map((item) => { return categoryTemp[item].subCategories })
    setSelectedSubCategories(tempSubcategories.flat())
  }
  function handleDeleteSubCategory (id) {
    const tempSelectedCategories = { ...selectedCategories }
    for (const categoryId in tempSelectedCategories) {
      const indexOfSubCategory = tempSelectedCategories[categoryId].subCategories.indexOf(id)
      if (indexOfSubCategory !== -1) {
        tempSelectedCategories[categoryId].subCategories.splice(indexOfSubCategory, 1)
        break
      }
    }
    setSelectedCategories(tempSelectedCategories)
    const tempSubcategories = Object.keys(tempSelectedCategories).map((item) => { return tempSelectedCategories[item].subCategories })
    setSelectedSubCategories(tempSubcategories.flat())
  }

  function handleChange (e) {
    e.preventDefault()
    setNewArrival({ ...newArrival, [e.target.name]: e.target.value })
  }

  function handleOpen (index, e) {
    e.preventDefault()
    const tempArr = [...accordian]
    if (tempArr[index]) {
      tempArr[index] = false
    } else tempArr[index] = true

    setAccordian(tempArr)
  }

  useEffect(() => {
    if (props.data) {
      setSelectedCategories(props.data.categories)
      const tempSubcategories = Object.keys(props.data.categories).map((item) => { return props.data.categories[item].subCategories })
      setSelectedSubCategories(tempSubcategories.flat())
    setNewArrival({ ...newArrival, title: props?.data?.title })

    }
  }, [props.data])

  function handleSubmit (e) {
    e.preventDefault()
    setLoader(true)
    PutData('admin/updateHomeSection7', { ...newArrival, categories: { ...selectedCategories } }).then((response) => {
      setLoader(false)
      if (response.status === true) {
        toastr.success(response.message, 'Success')
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  useEffect(() => {
    const catId = Object.keys(selectedCategories).filter((item) => {
      return selectedCategories[item].isParentCategorySelected === true
    })
    const subCatId = Object.keys(selectedCategories).filter((item) => {
      return selectedCategories[item].isParentCategorySelected === false
    })
    setItems({
      categories: [...catId],
      subCategories: [...subCatId.map((item) => {
        return selectedCategories[item].subCategories
      }).flat()]
    })
  }, [selectedSubCategories])

  const handleSubCategory = (id, categoryId, e) => {
    const tempSelectedCategories = { ...selectedCategories }
    if ((items.categories.length + items.subCategories.length) < 5) {
      if (!selectedCategories[categoryId]?.isParentCategorySelected) {
        if (!e.target.checked) {
          let subCate = []
          if (tempSelectedCategories[categoryId]?.subCategories.length > 0) {
            subCate = tempSelectedCategories[categoryId].subCategories
          }
          subCate.push(id)
          tempSelectedCategories[categoryId] = {
            isParentCategorySelected: false,
            subCategories: [...subCate]
          }
          setSelectedCategories(tempSelectedCategories)
          setSelectedSubCategories(prevState => ([...prevState, id]))
        } else {
          let subCate = []
          if (tempSelectedCategories[categoryId]?.subCategories.length > 0) {
            subCate = tempSelectedCategories[categoryId].subCategories
            subCate = subCate.filter((item) => {
              return item !== id
            })
          }
          if (subCate.length === 0) {
            delete tempSelectedCategories[categoryId]
          } else {
            tempSelectedCategories[categoryId] = {
              isParentCategorySelected: false,
              subCategories: [...subCate]
            }
          }
          setSelectedCategories(tempSelectedCategories)
          const tempSubcategories = Object.keys(tempSelectedCategories).map((item) => { return tempSelectedCategories[item].subCategories })
          setSelectedSubCategories(tempSubcategories.flat())
        }
      } else {
        if (e.target.checked) {
          let subCate = []
          if (tempSelectedCategories[categoryId]?.subCategories.length > 0) {
            subCate = tempSelectedCategories[categoryId].subCategories
            subCate = subCate.filter((item) => {
              return item !== id
            })
          }
          if (subCate.length === 0) {
            delete tempSelectedCategories[categoryId]
          } else {
            tempSelectedCategories[categoryId] = {
              isParentCategorySelected: false,
              subCategories: [...subCate]
            }
          }
          setSelectedCategories(tempSelectedCategories)
          const tempSubcategories = Object.keys(tempSelectedCategories).map((item) => { return tempSelectedCategories[item].subCategories })
          setSelectedSubCategories(tempSubcategories.flat())
        } else {
          let subCate = []
          if (tempSelectedCategories[categoryId]?.subCategories.length > 0) {
            subCate = tempSelectedCategories[categoryId].subCategories
          }
          subCate.push(id)
          tempSelectedCategories[categoryId] = {
            ...tempSelectedCategories[categoryId],
            subCategories: [...subCate]
          }
          setSelectedCategories(tempSelectedCategories)
          setSelectedSubCategories(prevState => ([...prevState, id]))
        }
      }
    } else if ((items.categories.length + items.subCategories.length) === 5) {
      if (!selectedCategories[categoryId]?.isParentCategorySelected) {
        if (e.target.checked) {
          let subCate = []
          if (tempSelectedCategories[categoryId]?.subCategories.length > 0) {
            subCate = tempSelectedCategories[categoryId].subCategories
            subCate = subCate.filter((item) => {
              return item !== id
            })
          }
          if (subCate.length === 0) {
            delete tempSelectedCategories[categoryId]
          } else {
            tempSelectedCategories[categoryId] = {
              isParentCategorySelected: false,
              subCategories: [...subCate]
            }
          }
          setSelectedCategories(tempSelectedCategories)
          const tempSubcategories = Object.keys(tempSelectedCategories).map((item) => { return tempSelectedCategories[item].subCategories })
          setSelectedSubCategories(tempSubcategories.flat())
        } else {
          e.preventDefault()
        }
      } else {
        if (e.target.checked) {
          let subCate = []
          if (tempSelectedCategories[categoryId]?.subCategories.length > 0) {
            subCate = tempSelectedCategories[categoryId].subCategories
            subCate = subCate.filter((item) => {
              return item !== id
            })
          }
          if (subCate.length === 0) {
            delete tempSelectedCategories[categoryId]
          } else {
            tempSelectedCategories[categoryId] = {
              isParentCategorySelected: false,
              subCategories: [...subCate]
            }
          }
          setSelectedCategories(tempSelectedCategories)
          const tempSubcategories = Object.keys(tempSelectedCategories).map((item) => { return tempSelectedCategories[item].subCategories })
          setSelectedSubCategories(tempSubcategories.flat())
        } else {
          e.preventDefault()
        }
      }
    } else {
      e.preventDefault()
    }
  }

  return (
    <React.Fragment>
      <Loader show={loader}></Loader>
      <div className="home-banner-right-wrap">
        <div className="supply-resource-wrap">
          <div className="banner-fields-box">
            <div className="sr-title-top">
              <Label htmlFor="formrow-firstname-Input">Section Title</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter section title"
                defaultValue={newArrival?.title}
                name="title"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
        {/* banner1 */}
        <div className="home-banner-outer featured-product-outer">
          <div className="featured-product-wrap">
            <h4>Select Category / Sub Category (maximum of 5)</h4>
            <div className="selected-value">
              {items?.categories?.map((data, i) => {
                return (
                  <span key={i} className="mt-2">
                    {allCategory?.categories?.find((x) => x.categoryID === parseInt(data))?.categoryName}
                    <div className="cross-btn">
                      <img
                        src={CrossIcon}
                        alt="cross"
                        id={data}
                        onClick={(e) => handleDeleteCategory(data)}
                      />
                    </div>
                  </span>
                )
              })}
              {items?.subCategories?.map((data, i) => {
                return (
                  <span key={i} className="mt-2">
                    {allCategory?.subCategories?.find((x) => x.subcategoryID === parseInt(data))?.subCategoryName}
                    <div className="cross-btn">
                      <img
                        src={CrossIcon}
                        alt="cross"
                        id={data}
                        onClick={(e) => handleDeleteSubCategory(data)}
                      />
                    </div>
                  </span>
                )
              })}
              {/* innerCheckNames?.map((name, index) => {
                return (
                  <span key={index} className="mt-2">
                    {name}
                    <div className="cross-btn">
                      <img
                        src={CrossIcon}
                        alt="cross"
                        id={index}
                        onClick={(e) => handleDelete(e)}
                      />
                    </div>
                  </span>
                );
              }) */}
            </div>
            <div className="featured-inner-box">
              {/* <div className="position-relative search-featured">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  // onChange={filter}
                />
                <span className="bx bx-search search-icon" />
              </div> */}
              <div className="check-listing-container" style={{ height: '100%' }}>
                <div className="check-listing">
                  {checklist && checklist.length > 0
                    ? (
                        checklist?.map((data, index) => {
                          return (
                        <div className="checkbox-line" key={index}>
                          <div className="d-flex align-items-center">
                            {accordian[index]
                              ? (
                              <i
                                className="bx bx-minus me-2 cursor-pointer"
                                onClick={(e) => {
                                  handleOpen(index, e)
                                }}
                              ></i>
                                )
                              : (
                              <i
                                className="bx bx-plus me-2 cursor-pointer"
                                onClick={(e) => handleOpen(index, e)}
                              ></i>
                                )}
                            <input
                              className="me-2"
                              type="checkbox"
                              id={'category-' + data.categoryID}
                              value={data.categoryID}
                              name={data.categoryID}
                              onClick={(e) => handleCategory(data.categoryID, index, e)}
                              disabled={(!Object.keys(selectedCategories).filter((item) => {
                                return selectedCategories[item].isParentCategorySelected === true
                              }).includes(data.categoryID.toString()) && (items.categories.length + items.subCategories.length) === 5)}
                              checked={Object.keys(selectedCategories).filter((item) => {
                                return selectedCategories[item].isParentCategorySelected === true
                              }).includes(data.categoryID.toString())}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={'category-' + data.categoryID}
                            >
                              {data.categoryName}
                            </label>
                          </div>
                          {accordian[index] &&
                            data.children.map((innerData, innerIndex) => {
                              return (
                                <div
                                  className="checkbox-line ms-5"
                                  key={innerIndex}
                                >
                                  <div className="d-flex align-items-center">
                                    <input
                                      className="me-2"
                                      type="checkbox"
                                      id={'subCategory-' + innerData.subcategoryID}
                                      value={innerData.subcategoryID}
                                      name={innerData.subcategoryID}
                                      disabled={(!selectedSubCategories.includes(innerData.subcategoryID) && (items.categories.length + items.subCategories.length) === 5)}
                                      onClick={(e) =>
                                        handleSubCategory(innerData.subcategoryID, data.categoryID, e)
                                      }
                                      checked={selectedSubCategories.includes(innerData.subcategoryID)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={'subCategory-' + innerData.subcategoryID}
                                    >
                                      {innerData.subCategoryName}
                                    </label>
                                  </div>
                                </div>
                              )
                            })}
                        </div>
                          )
                        })
                      )
                    : (
                    <div className="no-result">No results found!</div>
                      )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="save-btn">
          <button type="submit" onClick={(e) => handleSubmit(e)}>
            Save
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NewArrivals

NewArrivals.propTypes = {
  data: PropTypes.object
}
