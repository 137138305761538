import React from 'react'
import { Document, Page, View, Text, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import logo from '../../../src/assets/images/pdf.png'
// Create styles
const styles = StyleSheet.create({
  page: {
    padding: '30px'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowTop: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowTopText: {
    border: '1px solid #000'
  },
  // So Declarative and unDRY 👌
  rowTop1: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop2: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop3: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop4: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop5: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  row1: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row2: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    wordBreak: 'break-word',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row3: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    wordBreak: 'break-word',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row4: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row5: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: '300',
    border: '1px solid #000',
    display: 'flex',
    flexDirection: 'column'
  },
  uom: {
    fontSize: '8px',
    color: '#202020',
    display: 'block'
  },
  image: {
    width: '150px',
    marginBottom: '25px'
  }
})
function PdfReports ({ columns, content }) {
  return (
        <><PDFViewer>
            <Document>
      <Page size="A4" style={styles.page}>
        <Image src={logo} style={styles.image}/>
      <View style={styles.rowTop}>
        {
            columns.length > 0 && columns.map((item, index) => {
              return (
                    <Text style={{
                      width: `${100 / columns.length}%`,
                      border: '1px solid #000',
                      padding: '5px 10px',
                      fontSize: '14px',
                      fontWeight: '700'
                    }} key={index}>{item.label}</Text>
              )
            })
        }
      </View>
      {
        content.length > 0 && content.map((item, index) => {
          return (
            <View key={index} style={{
              display: 'flex',
              flexDirection: 'row'
            }}>
                {
                    columns.length > 0 && columns.map((col, k) => {
                      return (
                            <Text key={k} style={{
                              width: `${100 / columns.length}%`,
                              border: '1px solid #000',
                              padding: '5px 10px',
                              fontSize: '14px',
                              fontWeight: '700'
                            }}>
                            {item[col.key] ? item[col.key] : '--'}
                            </Text>
                      )
                    })
                }
        </View>)
        })}
      </Page>
    </Document>
        </PDFViewer>
        </>
  )
}

PdfReports.propTypes = {
  columns: PropTypes.any,
  content: PropTypes.any
}

export default PdfReports
