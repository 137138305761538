import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap'
import classnames from 'classnames'
import BulkUploadModal from 'components/Common/BulkUploadModal/BulkUploadModal'
import { GetData, PutData, PostData } from 'apiHandler'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import TableComponent from 'components/Table'
import 'toastr/build/toastr.min.css'
import MetaTags from 'react-meta-tags'
import SweetAlert from 'react-bootstrap-sweetalert'

let filterByStatus = 'active'
let locationId = ''
let searchTimer
let filterByKeyObject = { key: 'all' }
let confirmUpload = false
let formData = new FormData()
const InventoryTable = () => {
  const [singlebtn, setSinglebtn] = useState(false)
  const [drop, setDrop] = useState(false)
  const [tab, setTab] = useState('1')
  const [loader, setLoader] = useState(false)
  const [warehouses, setWarehouses] = useState([])
  const [totalCounts, setTotalCounts] = useState({ active: 0, all: 0 })
  const [selectedWarehouse, setSelectedWarehouse] = useState('Select Warehouse')
  const [data, setData] = useState([])
  const [bulkError, setBulkError] = useState('')
  // const sheetUrl = 'https://docs.google.com/spreadsheets/d/1l17egJHN6p9KF9CAZfGw7s1GchhbFVpOVoPnC2QsqUU/edit?usp=sharing'
  const [searchText, setSearchText] = useState('')
  const [showConfirm, setShowConfirm] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState('')
  const [bulkErrorSheet, setBulkErrorSheet] = useState('')
  // const [uploadFile, setUploadFile] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState({
    label: 'All',
    key: 'all'
  })
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const [columns, setColumns] = useState([
    { key: 'partnumber', label: 'Part Number' },
    { key: 'product', label: 'Product' },
    { key: 'brand', label: 'Brand' },
    { key: 'category', label: 'Category', sorting: true },
    { key: 'status', label: 'Status' },
    { key: 'subcategory', label: 'Sub Category', sorting: true },
    { key: 'price', label: 'WD Price', sorting: true },
    { key: 'quantity', label: 'Qty', sorting: true, input: true },
    { key: 'uom', label: 'UoM' }
  ])
  const filterTypes = [
    { label: 'All', key: 'all' },
    { label: 'Part Number', key: 'partnumber' },
    { label: 'Brand', key: 'brand' },
    { label: 'Category', key: 'category' },
    { label: 'Sub Category', key: 'subcategory' }
  ]

  useEffect(() => {
    getWarehouses()
    return () => {
      setTab('1')
      filterByStatus = 'active'
      locationId = ''
      filterByKeyObject = { key: 'all' }
      formData = new FormData()
      confirmUpload = false
    }
  }, [])

  const getWarehouses = () => {
    setLoader(true)
    GetData('vendor/getVendorWarehouses').then((response) => {
      if (response.status === true) {
        setLoader(false)
        const primaryLoc = response?.result.filter((loc) => parseInt(loc.isPrimaryLocation) === 1)
        setSelectedWarehouse(primaryLoc[0]?.locationName)
        locationId = response?.result[0]?.location_id
        getLocations(1)
        setWarehouses(response.result)
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getLocations = (p) => {
    setLoader(true)
    const pageSize = 20
    let url = 'vendor/getInventory?page=' + p + '&pageSize=' + pageSize + '&vendorWarehouseLocationId=' + locationId + '&selectedTab=all'
    if (filterByKeyObject.string) {
      url +=
        '&filterBy=' +
        filterByKeyObject.key +
        '&search=' +
        filterByKeyObject.string
    }
    if (sortingState.sortColumn != null) {
      url +=
        '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order.toUpperCase()
    }

    GetData(url).then((response) => {
      if (response.status === true || response.status === 200) {
        setLoader(false)
        const parsedParts = []
        response.result.products.map((part) => {
          let statusString = <div className="disabled-div">Not Listed</div>
          if (part?.status === 1) {
            statusString = <div className="active-div">Active</div>
          }
          parsedParts.push({
            part_id: part?.part_id,
            partnumber: part?.partNumber,
            brand: part?.brand,
            product: part?.PartTerminology?.PartTerminologyName,
            price: part?.price?.cost_price ? '$' + part?.price?.cost_price : 'Not Set',
            category: part?.partCategoryMapping?.partCategory?.categoryName,
            subcategory: part?.partCategoryMapping?.partSubCategory?.subCategoryName,
            quantity: part?.quantity,
            status: statusString,
            uom: part?.uom
          })
          return null
        })
        setData(parsedParts)
        setTotalCounts({ all: response.result.allCount, active: response.result.activeCount })
        setPaging({
          currentPage: p,
          recordPerPage: pageSize,
          totalRecord: filterByStatus === 'inactive' ? response.result.allCount : response.result.activeCount
        })
      } else {
        setLoader(false)
        setData([])
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
        toastr.error(response.message, 'Error')
      }
    })
  }

  const paginationHandler = (page) => {
    getLocations(page)
  }

  const onChangeHandler = (e) => {
    setSelectedWarehouse(e.target.value.locationName)
    locationId = e.target.value.location_id
    filterByStatus = 'active'
    setTab('1')
    getLocations(1)
  }

  const navHandle = key => e => {
    e.preventDefault()
    filterByStatus = key === '2' ? 'inactive' : 'active'
    if (key === '2') {
      setColumns([
        { key: 'partnumber', label: 'Part Number' },
        { key: 'product', label: 'Product' },
        { key: 'brand', label: 'Brand' },
        { key: 'category', label: 'Category', sorting: true },
        { key: 'status', label: 'Status' },
        { key: 'subcategory', label: 'Sub Category', sorting: true },
        { key: 'price', label: 'WD Price', sorting: true },
        { key: 'quantity', label: 'Qty', sorting: true, input: true },
        { key: 'uom', label: 'UoM' }
      ])
    } else {
      setColumns([
        { key: 'partnumber', label: 'Part Number' },
        { key: 'product', label: 'Product' },
        { key: 'category', label: 'Category', sorting: true },
        { key: 'subcategory', label: 'Sub Category', sorting: true },
        { key: 'price', label: 'WD Price', sorting: true },
        { key: 'quantity', label: 'Qty', sorting: true, input: true },
        { key: 'uom', label: 'UoM' }
      ])
    }
    filterData(key, 'all')
  }

  const filterData = (key) => {
    setTab(key)
    getLocations(1)
  }

  const dropToggle = () => {
    setSinglebtn(!singlebtn)
  }
  const dropHandel = () => {
    setDrop(!drop)
  }

  const handleActions = (key, data) => {
    if (key === 'view') {
      history.push('/products/' + data.id)
    }
  }

  const filterByKey = (obj) => {
    filterByKeyObject.key = obj.key
    setSelectedFilter(obj)
    if (filterByKeyObject.string) {
      getLocations(1)
    }
  }
  const searchByText = (t) => {
    setSearchText(t)
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      filterByKeyObject.string = t
      getLocations(1)
    }, 2000)
  }

  const handleRow = (data) => {
    // history.push('/products/' + data.id)
  }
  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn === column) {
      if (sortingState.order === 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }
  const updateQuantity = (e, data, qty, page) => {
    e.preventDefault()
    if (qty === '') {
      toastr.error('Quantity is required', 'Error')
      return
    }
    const body = {
      part_id: data.part_id,
      vendorWarehouseLocationId: locationId,
      quantity: parseInt(qty)
    }
    setLoader(true)
    PutData('vendor/updateInventoryQuantity', body).then((response) => {
      if (response.status === true) {
        setLoader(false)
        getLocations(1)
        toastr.success(response.message)
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  const inventoryUpload = (e, file) => {
    if (confirmUpload) {
      setLoader(true)
      PostData('vendor/bulkInventoryUpload/' + locationId, formData).then((response) => {
        setShowConfirm(false)
        if (response.status === true) {
          confirmUpload = false
          getLocations(1)
          setBulkError(false)
          setTimeout(() => { setBulkError('') }, 200)
          toastr.success(response.message)
        } else {
          confirmUpload = false
          getLocations(1)
          setBulkError(response?.message)
          setBulkErrorSheet(response?.result?.url)
          toastr.error(response?.message, 'Error')
          setLoader(false)
        }
      })
    } else {
      if (file) {
        formData = new FormData()
        formData.append('vendorWarehouseLocationId', locationId)
        formData.append('document', file)
      }
      setLoader(true)
      PostData('vendor/getBulkInventoryChanges/' + locationId, formData).then((response) => {
        if (response.status === true) {
          setLoader(false)
          setShowConfirm(true)
          setConfirmMessage(response.message + ' : ' + response.result.count)
        } else {
          confirmUpload = false
          setLoader(false)
          toastr.error(response.message, 'Error')
        }
      })
    }
  }


  const downloadFile = (link) => {
    const element = document.createElement('a')
    element.setAttribute('href', link)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  const exportInventory = () => {
    setLoader(true)
    GetData('vendor/exportInventory?vendorWarehouseLocationId=' + locationId).then((response) => {
      setLoader(false)
      if (response.status === true) {
        downloadFile('https://' + response.result)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getLocations(1)
  }, [sortingState])

  return (
    <>
    <MetaTags>
      <title>Inventory :: QLS Admin Panel</title>
    </MetaTags>
    <Loader show={loader} />
    {
      showConfirm
        ? <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setShowConfirm(false)
            confirmUpload = true
            inventoryUpload()
          }}
          onCancel={() => {
            confirmUpload = false
            setShowConfirm(false)
            setBulkError(false)
            setTimeout(() => { setBulkError('') }, 200)
          }}
        >
          {confirmMessage}
        </SweetAlert>
        : null
    }
      <div className="page-content">
        <Container fluid={true} className="customer-container">
          <div className="products-nav">
            <div className='inventory-dropdown-div'>
              <span className="products-heading">Inventory</span>
              <div className='warehouse-dropdown'>
                  <span className='select-warehouse'>Select Warehouse: </span>
                  <Dropdown
                  isOpen={drop}
                  toggle={dropHandel}
                  >
                      <DropdownToggle className="customer-select text-dark drop--toggle">
                        {selectedWarehouse}
                        <i className=" ms-2 bx bx-caret-down" />
                      </DropdownToggle>
                      <DropdownMenu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                      {
                        warehouses.length
                          ? warehouses.map((warehouse, k) => {
                            return (
                              <DropdownItem onClick={() => {
                                onChangeHandler({
                                  target: {
                                    name: 'warehouse',
                                    value: warehouse
                                  }
                                })
                              }} key={k}>{warehouse.locationName}</DropdownItem>
                            )
                          })
                          : <DropdownItem>Select Warehouse</DropdownItem>
                      }
                      </DropdownMenu>
                  </Dropdown>
              </div>
            </div>
            <div className='d-flex'>
              <BulkUploadModal modalType={'Inventory Upload to ' + selectedWarehouse} productsBulkUpload={inventoryUpload} bulkError={bulkError} bulkErrorSheet={bulkErrorSheet} sheet={'BulkInventory'}/>
              <button className="products-nav-btn products-export-btn" onClick={exportInventory}>
                Export
              </button>
            </div>
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="warehouse-navbar">
                    <Nav>
                      <NavItem className='navItems'>
                        <NavLink
                          className={classnames({
                            active: tab === '1'
                          })}
                          onClick={navHandle('1')}
                        >
                          All ({totalCounts.all})
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div className="d-flex">
                      <Dropdown
                        isOpen={singlebtn}
                        toggle={dropToggle}
                      >
                        <DropdownToggle className="customer-select text-dark">
                          Filter By{' '}
                          <span className="font-weight-bold">
                            {selectedFilter.label}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {filterTypes.map((filterObj, key) => {
                            return (
                              <DropdownItem
                                key={key}
                                onClick={() => filterByKey(filterObj)}
                              >
                                {filterObj.label}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      <div className="customer-search-form">
                        <input
                          className="form-control input-border customer-input-border"
                          type="text"
                          placeholder="Search"
                          onChange={(e) => searchByText(e.target.value)}
                          value={searchText}
                        />
                        <i className="bx bx-search-alt-2 search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <hr className=" mt-0 mb-4 tab-hr"></hr>
                  <TableComponent
                    columns={columns}
                    content={data}
                    handleActions={handleActions}
                    paging={paging}
                    paginationHandler={paginationHandler}
                    onClick={handleRow}
                    sorting={handleSort}
                    inputField="quantity"
                    updateSellingPrice={updateQuantity}
                    arrowState={sortingState}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default InventoryTable
