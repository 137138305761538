/* eslint-disable eqeqeq */
import React, { useState, useContext, useEffect } from 'react'
import {
  Col,
  Container,
  Row,
  Modal,
  Label,
  Input,
  FormFeedback,
  Form
} from 'reactstrap'
import { WDEditModalContext } from 'pages/WD Management/ActiveWD'
import { useFormik } from 'formik'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { GetData, PutData, PostData } from '../../apiHandler'
import NumberFormat from 'react-number-format'
import { globalVariables } from 'globalVariables'

export default function WDEditModal () {
  const params = useParams()
  const { editModal, setEditModal, locationData, setLocationData, getLocationsData } =
    useContext(WDEditModalContext)
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  useEffect(() => {
    getStates()
    console.log('loc data is ', locationData)
    if (locationData.shippingStateId) getCities(locationData.shippingStateId)
  }, [locationData])

  const getStates = () => {
    const url = `states?countryId=${globalVariables.countryId}`
    GetData(url).then((response) => {
      if (response.status === true) {
        if (response.result.length) {
          setStates(response.result)
        } else {
          setStates([])
        }
      } else {
        setStates([])
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getCities = (id) => {
    const url = `cities?countryId=${globalVariables.countryId}&stateId=` + id
    GetData(url).then((response) => {
      if (response.status === true) {
        if (response.result.length) {
          setCities(response.result)
        } else {
          setCities([])
        }
      } else {
        setCities([])
        toastr.error(response.message, 'Error')
      }
    })
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      locationName: '',
      shippingStreet: '',
      shippingZipCode: '',
      contactFirstName: '',
      contactLastName: '',
      contactEmail: '',
      contactPhone: '',
      contactTitle: '',
      shippingCountryId: `${globalVariables.countryId}`,
      shippingStateId: '',
      shippingCityId: '',
      ...locationData,
      isPrimaryLocation:
        locationData &&
        locationData.isPrimaryLocation &&
        (locationData.isPrimaryLocation == 1)
    },
    validationSchema: Yup.object({
      locationName: Yup.string().required('Please Enter Location Name'),
      shippingStreet: Yup.string().required('Please Enter Street'),
      shippingZipCode: Yup.string().required('Please Enter Zip Code'),
      contactFirstName: Yup.string().required('Please Enter First Name'),
      contactLastName: Yup.string().required('Please Enter Last Name'),
      contactEmail: Yup.string()
        .email('Please Enter Valid Email Address')
        .required('Please Enter Email Address'),
      contactPhone: Yup.string().required('Please Enter Phone Number'),
      contactTitle: Yup.string().required('Please Enter Title'),
      shippingStateId: Yup.string().required('Please enter Shipping State'),
      shippingCityId: Yup.string().required('Please enter Shipping City')
    }),
    onSubmit: (values) => {
      if (values.id) {
        const body = {
          id: values.id,
          vendorId: values.vendorId,
          locationName: values.locationName,
          shippingStreet: values.shippingStreet,
          shippingStateId: values.shippingStateId,
          shippingCityId: values.shippingCityId,
          shippingZipCode: values.shippingZipCode,
          contactFirstName: values.contactFirstName,
          contactLastName: values.contactLastName,
          contactEmail: values.contactEmail,
          contactPhone: values.contactPhone,
          contactTitle: values.contactTitle,
          isPrimaryLocation: values.isPrimaryLocation == true ? 1 : 0
        }
        const url = 'admin/vendor/editLocation'
        PutData(url, body).then((response) => {
          if (response.status === true) {
            setEditModal(false)
            getLocationsData(params.id)
          } else {
            toastr.error(response.message, 'Error')
          }
        })
      } else {
        const body = {
          vendorId: params.id,
          locationName: values.locationName,
          shippingStreet: values.shippingStreet,
          shippingCountryId: values.shippingCountryId,
          shippingStateId: values.shippingStateId,
          shippingCityId: values.shippingCityId,
          shippingZipCode: values.shippingZipCode,
          contactFirstName: values.contactFirstName,
          contactLastName: values.contactLastName,
          contactEmail: values.contactEmail,
          contactPhone: values.contactPhone,
          contactTitle: values.contactTitle,
          isPrimaryLocation: values.isPrimaryLocation == true ? 1 : 0
        }
        const url = 'admin/vendor/addLocation'
        PostData(url, body).then((response) => {
          if (response.status === true) {
            setEditModal(false)
            getLocationsData(params.id)
          } else {
            toastr.error(response.message, 'Error')
          }
        })
      }
    }
  })

  return (
    <div className="wd-edit-modal-container">
      <Container fluid={true}>
        <Row>
          <Col lg={12}>
            <Modal
              size="lg"
              isOpen={editModal}
              toggle={() => {
                // eslint-disable-next-line no-undef
                tog_large()
              }}
              centered
            >
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="modal-header border-0">
                  <button
                    onClick={() => {
                      setEditModal(false)
                      setLocationData({})
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="wd-edit-modal-body">
                  <h2 className="edit-location-heading">
                    {Object.keys(locationData).length > 1
                      ? 'Edit Location'
                      : 'Add Location'}
                  </h2>
                  <Row>
                    <Col lg={12}>
                      <div className="my-3">
                        <Label htmlFor="formrow-firstname-Input">
                          Location Name/Title*
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Location Name/Title"
                          name={'locationName'}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.locationName || ''}
                          invalid={
                            !!(validation.touched.locationName &&
                            validation.errors.locationName)
                          }
                        />
                        {validation.touched.locationName &&
                        validation.errors.locationName
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.locationName}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <h3 className="section-heading">Shipping Address</h3>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">Street*</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Street Name"
                          name={'shippingStreet'}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.shippingStreet || ''}
                          invalid={
                            !!(validation.touched.shippingStreet &&
                            validation.errors.shippingStreet)
                          }
                        />
                        {validation.touched.shippingStreet &&
                        validation.errors.shippingStreet
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.shippingStreet}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">State*</Label>
                        <Input
                          type="select"
                          id={'stateSelect'}
                          name={'shippingStateId'}
                          onChange={(e) => {
                            validation.setFieldValue(
                              'shippingStateId',
                              e.target.value
                            )
                            getCities(e.target.value)
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.shippingStateId || ''}
                          invalid={
                            !!(validation.touched.shippingStateId &&
                            validation.errors.shippingStateId)
                          }
                        >
                          <option value="">-Select State-</option>
                          {states.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Input>

                        {validation.touched.shippingStateId &&
                        validation.errors.shippingStateId
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.shippingStateId}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">
                              City*
                            </Label>
                            <Input
                              type="select"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter City Name"
                              name={'shippingCityId'}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.shippingCityId || ''}
                              invalid={
                                !!(validation.touched.shippingCityId &&
                                validation.errors.shippingCityId)
                              }
                            >
                              <option value="">-Select City-</option>
                              {cities.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Input>
                            {validation.touched.shippingCityId &&
                            validation.errors.shippingCityId
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.shippingCityId}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">
                              Zip Code*
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Zipcode"
                              name={'shippingZipCode'}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.shippingZipCode || ''}
                              invalid={
                                !!(validation.touched.shippingZipCode &&
                                validation.errors.shippingZipCode)
                              }
                            />
                            {validation.touched.shippingZipCode &&
                            validation.errors.shippingZipCode
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.shippingZipCode}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                      </Row>
                      {
                        locationData.isPrimaryLocation !== 1 && <div className="d-flex align-items-center">
                        <input
                          className="form-check-input m-0 ms-2"
                          type="checkbox"
                          checked={
                            validation.values.isPrimaryLocation || false
                          }
                          id={'defaultCheck'}
                          name={'isPrimaryLocation'}
                          onClick={(e) =>
                            validation.setFieldValue(
                              'isPrimaryLocation',
                              !e.target.checked
                            )
                          }
                          // onClick={(e)=>alert(e.target.checked)}
                          onBlur={validation.handleBlur}
                        />
                        <label
                          className="form-check-label ms-2"
                          htmlFor={'defaultCheck'}
                        >
                          Set as Primary Warehouse
                        </label>
                      </div>
                      }
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <h3 className="section-heading">Contact Information</h3>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">
                              First Name*
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter First Name"
                              name={'contactFirstName'}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.contactFirstName || ''
                              }
                              invalid={
                                !!(validation.touched.contactFirstName &&
                                validation.errors.contactFirstName)
                              }
                            />
                            {validation.touched.contactFirstName &&
                            validation.errors.contactFirstName
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.contactFirstName}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">
                              Last Name*
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Last Name"
                              name={'contactLastName'}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.contactLastName || ''}
                              invalid={
                                !!(validation.touched.contactLastName &&
                                validation.errors.contactLastName)
                              }
                            />
                            {validation.touched.contactLastName &&
                            validation.errors.contactLastName
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.contactLastName}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Email Address*
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Email Adress"
                            name={'contactEmail'}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contactEmail || ''}
                            invalid={
                              !!(validation.touched.contactEmail &&
                              validation.errors.contactEmail)
                            }
                          />
                          {validation.touched.contactEmail &&
                          validation.errors.contactEmail
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contactEmail}
                            </FormFeedback>
                              )
                            : null}
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Phone Numberr*
                          </Label>
                          <NumberFormat
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Phone Number"
                          displayType="input" format="(###) ###-####"
                          name={'contactPhone'}
                          value={validation.values.contactPhone || ''}
                          onChange={validation.handleChange}
                          />
                          {/* <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Phone Number"
                            name={'contactPhone'}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contactPhone || ''}
                            invalid={
                              !!(validation.touched.contactPhone &&
                              validation.errors.contactPhone)
                            }
                          /> */}
                          {validation.touched.contactPhone &&
                          validation.errors.contactPhone
                            ? (
                            <div className='error' type="invalid">
                              {validation.errors.contactPhone}
                            </div>
                              )
                            : null}
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Title*
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Title"
                            name={'contactTitle'}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contactTitle || ''}
                            invalid={
                              !!(validation.touched.contactTitle &&
                              validation.errors.contactTitle)
                            }
                          />
                          {validation.touched.contactTitle &&
                          validation.errors.contactTitle
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contactTitle}
                            </FormFeedback>
                              )
                            : null}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end">
                    <button
                      onClick={() => {
                        setEditModal(false)
                        setLocationData({})
                      }}
                      className="wd-location-modal-cancel-btn"
                    >
                      Cancel
                    </button>
                    <button className="wd-location-modal-save-btn">Save</button>
                  </div>
                </div>
              </Form>
            </Modal>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

WDEditModal.propTypes = {
  locationData: PropTypes.object
}
