/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, CardBody, Label, Input } from 'reactstrap'
import Breadcrumbs from 'components/Common/Breadcrumb'
import { useHistory, useParams, Link } from 'react-router-dom'
import { GetData, PostData, PutData } from 'apiHandler'
import toastr from 'toastr'
import Loader from '../../components/Loader'
import SelectionModalTable from '../../components/SelectionModalTable/SelectionModalTable'
import CategoryModalTable from '../../components/SelectionModalTable/CategoryModalTable'
import TimePicker from 'rc-time-picker'
import moment from 'moment'
import 'rc-time-picker/assets/index.css'
import './discount.scss'

export default function CreateDiscount1 () {
  const history = useHistory()
  const { id, auto } = useParams()

  const [productsData, setProductsData] = useState(null)
  const [categoriesData, setCategoriesData] = useState(null)
  const [customersData, setCustomersData] = useState(null)

  // input show states
  const [endDateInput, setEndDateInput] = useState(false)
  const [discountData, setDisountData] = useState(null)

  const [typesRadio, setTypesRadio] = useState(null)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedCustomers, setSelectedCustomers] = useState([])
  const [tempSelectedProducts, setTempSelectedProducts] = useState([])
  const [tempSelectedCategories, setTempSelectedCategories] = useState([])
  const [tempSelectedCustomers, setTempSelectedCustomers] = useState([])
  const [tempSearchProduct, setTempSearchProduct] = useState(null)
  const [tempSearchCategories, setTempSearchCategories] = useState(null)
  const [tempSearchCustomer, setTempSearchCustomer] = useState(null)
  const [customerPaging, setCustomerPaging] = useState({
    totalRecord: 0,
    recordPerPage: 2,
    currentPage: 1
  })
  const [productsPaging, setProductsPaging] = useState({
    totalRecord: 0,
    recordPerPage: 2,
    currentPage: 1
  })
  // eslint-disable-next-line no-unused-vars
  const [categoryPaging, setCategoryPaging] = useState({
    totalRecord: 40,
    recordPerPage: 40,
    currentPage: 1
  })
  const [loader, setLoader] = useState(false)

  // form based upton type selection
  const [formOptions, setFormOptions] = useState(
    {
      percentage: [
        {
          label: 'Value',
          fields: [
            {
              label: 'Discount Value',
              inputType: 'text',
              inputIcon: 'percent',
              name: 'discountValue',
              validate: true,
              dbName: 'discountValue',
              error: 'Please enter discount value',
              value: ''
            }
          ]
        },
        {
          label: 'Applies To',
          validate: true,
          error: 'Please select atleast one option for applies to',
          dbName: 'appliesTo',
          value: '',
          fields: [
            {
              label: 'All Parts/Categories',
              inputType: 'radio',
              name: 'appliesTo',
              radioValue: 0,
              checked: false,
              inputBar: false
            },
            {
              label: 'Specific Categories',
              inputType: 'radio',
              name: 'appliesTo',
              radioValue: 1,
              inputBar: true,
              barType: 'search',
              barModalType: 'categories',
              dbName: 'categoryIds',
              showBar: false,
              checked: false,
              validate: true,
              error: 'Please select products'
            },
            {
              label: 'Specific Part Numbers',
              inputType: 'radio',
              name: 'appliesTo',
              dbName: 'productSKU',
              radioValue: 2,
              inputBar: true,
              barType: 'search',
              barModalType: 'products',
              showBar: false,
              checked: false,
              validate: true,
              error: 'Please select products'
            }
          ]
        },
        {
          label: 'Minimum Requirements',
          validate: true,
          dbName: 'minimumRequirementType',
          error: 'Please select atleast one minimum requirement',
          value: '',
          fields: [
            {
              label: 'None',
              inputType: 'radio',
              name: 'minRequire',
              radioValue: 0,
              inputBar: false
            },
            {
              label: 'Minimum Purchase Amount ($)',
              inputType: 'radio',
              radioValue: 1,
              name: 'minRequire',
              dbName: 'minimumRequirementValue',
              inputBar: true,
              barType: 'text',
              inputIcon: 'dollar',
              showBar: false,
              extraLabel: 'Applies only to selected products',
              validate: true,
              value: '',
              error: 'Please enter minimum purchase amount'
            },
            {
              label: 'Minimum Quantity Of Items',
              inputType: 'radio',
              name: 'minRequire',
              dbName: 'minimumRequirementValue',
              radioValue: 2,
              inputBar: true,
              barType: 'text',
              inputIcon: null,
              showBar: false,
              extraLabel: 'Applies only to selected products',
              validate: true,
              value: '',
              error: 'Please enter minimum Quantity'
            }
          ]
        },
        {
          label: 'Active Dates',
          fields: [
            {
              label: 'Start Date',
              inputType: 'date',
              name: 'startDate',
              dbName: 'startdate',
              validate: true,
              value: '',
              error: 'Please select start date'
            },
            {
              label: 'Start Time',
              inputType: 'time',
              name: 'startTime',
              dbName: 'startTime',
              validate: true,
              value: '',
              error: 'Please select start time'
            },
            {
              label: 'Set End Date',
              inputType: 'checkbox',
              name: 'setEndDate'
            },
            {
              label: 'End Date',
              inputType: 'date',
              name: 'endDate',
              dbName: 'enddate',
              validate: true,
              value: '',
              error: 'Please select end date'
            },
            {
              label: 'End Time',
              inputType: 'time',
              name: 'endTime',
              dbName: 'endTime',
              validate: true,
              value: '',
              error: 'Please select end time'
            }
          ]
        },
        {
          label: 'Usage Limits',
          dbName: 'customerMultipleUsage',
          validate: true,
          value: '',
          error: 'Please select atleast one usage limit',
          fields: [
            {
              label: 'Limit number of times this discount can be used in total',
              inputType: 'radio',
              name: 'usageLimit',
              dbName: 'maxUsage',
              radioValue: 0,
              inputBar: true,
              barType: 'text',
              inputIcon: null,
              showBar: false,
              validate: true,
              value: '',
              error: 'Please enter some value'
            },
            {
              label: 'Limit to one use per customer',
              name: 'usageLimit',
              radioValue: 1,
              inputType: 'radio',
              inputBar: false
            }
          ]
        },
        {
          label: 'Customer Eligibility',
          dbName: 'eligibleCustomer',
          validate: true,
          value: '',
          error: 'Please atleast one customer eligibility',
          fields: [
            {
              label: 'Everyone',
              name: 'customer',
              inputType: 'radio',
              radioValue: 0,
              inputBar: false
            },
            {
              label: 'Specific Customers',
              radioValue: 1,
              inputType: 'radio',
              name: 'customer',
              inputBar: true,
              barType: 'search',
              dbName: 'targatedCustomer',
              barModalType: 'customers',
              showBar: false,
              validate: true,
              error: 'Please select customers'
            }
          ]
        }
      ],
      fixedAmount: [
        {
          label: 'Value',
          fields: [
            {
              label: 'Discount Value',
              inputType: 'text',
              inputIcon: 'dollar',
              name: 'discountValue',
              dbName: 'discountValue',
              validate: true,
              value: '',
              error: 'Please select end time'
            }
          ]
        },
        {
          label: 'Applies To',
          dbName: 'appliesTo',
          validate: true,
          value: '',
          error: 'Please select atleast one applies to',
          fields: [
            {
              label: 'All Parts/Categories',
              inputType: 'radio',
              radioValue: 0,
              name: 'appliesToFixed',
              checked: false,
              inputBar: false
            },
            {
              label: 'Specific Categories',
              inputType: 'radio',
              name: 'appliesToFixed',
              dbName: 'categoryIds',
              radioValue: 1,
              inputBar: true,
              barType: 'search',
              barModalType: 'categories',
              showBar: false,
              validate: true,
              checked: false,
              error: 'Please select list of products'
            },
            {
              label: 'Specific Part Numbers',
              inputType: 'radio',
              radioValue: 2,
              name: 'appliesToFixed',
              dbName: 'productSKU',
              inputBar: true,
              barType: 'search',
              barModalType: 'products',
              showBar: false,
              validate: true,
              checked: false,
              error: 'Please select list of products'
            }
          ]
        },
        {
          label: 'Minimum Requirements',
          dbName: 'minimumRequirementType',
          validate: true,
          value: '',
          error: 'Please select atleast one minimum requirement',
          fields: [
            {
              label: 'None',
              inputType: 'radio',
              name: 'minRequire',
              radioValue: 0,
              inputBar: false
            },
            {
              label: 'Minimum Purchase Amount ($)',
              inputType: 'radio',
              name: 'minRequire',
              dbName: 'minimumRequirementValue',
              radioValue: 1,
              inputBar: true,
              barType: 'text',
              inputIcon: 'dollar',
              showBar: false,
              extraLabel: 'Applies only to selected products',
              validate: true,
              value: '',
              error: 'Please enter minimum purchase amount'
            },
            {
              label: 'Minimum Quantity Of Items',
              inputType: 'radio',
              name: 'minRequire',
              dbName: 'minimumRequirementValue',
              radioValue: 2,
              inputBar: true,
              barType: 'text',
              inputIcon: null,
              showBar: false,
              extraLabel: 'Applies only to selected products',
              validate: true,
              value: '',
              error: 'Please enter minimum quantity'
            }
          ]
        },
        {
          label: 'Active Dates',
          fields: [
            {
              label: 'Start Date',
              inputType: 'date',
              name: 'startDate',
              dbName: 'startdate',
              validate: true,
              value: '',
              error: 'Please enter start date'
            },
            {
              label: 'Start Time',
              inputType: 'time',
              name: 'startTime',
              dbName: 'startTime',
              validate: true,
              value: '',
              error: 'Please enter start time'
            },
            {
              label: 'Set End Date',
              inputType: 'checkbox',
              name: 'setEndDate'
            },
            {
              label: 'End Date',
              inputType: 'date',
              name: 'endDate',
              dbName: 'enddate',
              validate: true,
              value: '',
              error: 'Please enter end date'
            },
            {
              label: 'End Time',
              inputType: 'time',
              name: 'endTime',
              dbName: 'endTime',
              validate: true,
              value: '',
              error: 'Please enter end time'
            }
          ]
        },
        {
          label: 'Usage Limits',
          dbName: 'customerMultipleUsage',
          validate: true,
          value: '',
          error: 'Please select atleast one usage limit',
          fields: [
            {
              label: 'Limit number of times this discount can be used in total',
              inputType: 'radio',
              radioValue: 0,
              name: 'usageLimit',
              dbName: 'maxUsage',
              inputBar: true,
              barType: 'text',
              inputIcon: null,
              showBar: false,
              validate: true,
              value: '',
              error: 'Please enter usage limit'
            },
            {
              label: 'Limit to one use per customer',
              radioValue: 1,
              name: 'usageLimit',
              inputType: 'radio',
              inputBar: false
            }
          ]
        },
        {
          label: 'Customer Eligibility',
          validate: true,
          value: '',
          dbName: 'eligibleCustomer',
          error: 'Please select atleast one customer eligibility',
          fields: [
            {
              label: 'Everyone',
              name: 'customer',
              inputType: 'radio',
              radioValue: 0,
              inputBar: false
            },
            {
              label: 'Specific Customers',
              inputType: 'radio',
              radioValue: 1,
              name: 'customer',
              dbName: 'targatedCustomer',
              inputBar: true,
              barType: 'search',
              barModalType: 'customers',
              showBar: false,
              validate: true,
              error: 'Please select customers from the list'
            }
          ]
        }
      ],
      bulkDiscount: [
        {
          label: 'Minimum Requirements',
          fields: [
            {
              label: 'Minimum Quantity Of Items',
              inputType: 'text',
              name: 'minimumRequirementValue',
              dbName: 'minimumRequirementValue',
              validate: true,
              value: '',
              error: 'Please enter minimum quantity'
            }
          ]
        },
        {
          label: 'Value',
          dbName: 'discountTypeValue',
          validate: true,
          value: '',
          error: 'Please select atleast one discount type',
          fields: [
            {
              label: 'Fixed Discount Value',
              inputType: 'radio',
              radioValue: 3,
              name: 'discountValue',
              dbName: 'discountValue',
              inputBar: true,
              barType: 'text',
              inputIcon: 'dollar',
              showBar: false,
              validate: true,
              value: '',
              error: 'Please enter discount value'
            },
            {
              label: 'Percentage Discount Value',
              inputType: 'radio',
              radioValue: 2,
              name: 'discountValue',
              dbName: 'discountValue',
              inputBar: true,
              barType: 'text',
              inputIcon: 'percent',
              showBar: false,
              validate: true,
              value: '',
              error: 'Please enter discount value'
            }
          ]
        },
        {
          label: 'Applies To',
          validate: true,
          error: 'Please select atleast one option for applies to',
          dbName: 'appliesTo',
          value: '',
          fields: [
            {
              label: 'All Parts/Categories',
              inputType: 'radio',
              name: 'appliesTo',
              radioValue: 0,
              checked: false,
              inputBar: false
            },
            {
              label: 'Specific Categories',
              inputType: 'radio',
              name: 'appliesTo',
              radioValue: 1,
              inputBar: true,
              barType: 'search',
              barModalType: 'categories',
              dbName: 'categoryIds',
              showBar: false,
              checked: false,
              validate: true,
              error: 'Please select products'
            },
            {
              label: 'Specific Part Numbers',
              inputType: 'radio',
              name: 'appliesTo',
              dbName: 'productSKU',
              radioValue: 2,
              inputBar: true,
              barType: 'search',
              barModalType: 'products',
              showBar: false,
              checked: false,
              validate: true,
              error: 'Please select products'
            }
          ]
        },
        {
          label: 'Active Dates',
          fields: [
            {
              label: 'Start Date',
              inputType: 'date',
              name: 'startDate',
              dbName: 'startdate',
              validate: true,
              value: '',
              error: 'Please enter start date'
            },
            {
              label: 'Start Time',
              inputType: 'time',
              name: 'startTime',
              dbName: 'startTime',
              validate: true,
              value: '',
              error: 'Please enter start time'
            },
            {
              label: 'Set End Date',
              inputType: 'checkbox',
              name: 'setEndDate'
            },
            {
              label: 'End Date',
              inputType: 'date',
              name: 'endDate',
              dbName: 'enddate',
              validate: true,
              value: '',
              error: 'Please enter end date'
            },
            {
              label: 'End Time',
              inputType: 'time',
              name: 'endTime',
              dbName: 'endTime',
              validate: true,
              value: '',
              error: 'Please enter end time'
            }
          ]
        },
        {
          label: 'Usage Limits',
          dbName: 'customerMultipleUsage',
          validate: true,
          value: '',
          error: 'Please select atleast usage limit',
          fields: [
            {
              label: 'Limit number of times this discount can be used in total',
              inputType: 'radio',
              name: 'usageLimit',
              dbName: 'maxUsage',
              radioValue: 0,
              inputBar: true,
              barType: 'text',
              inputIcon: null,
              showBar: false,
              validate: true,
              value: '',
              error: 'Please enter limit number value'
            },
            {
              label: 'Limit to one use per customer',
              name: 'usageLimit',
              radioValue: 1,
              inputType: 'radio',
              inputBar: false
            }
          ]
        },
        {
          label: 'Customer Eligibility',
          dbName: 'eligibleCustomer',
          validate: true,
          value: '',
          error: 'Please select atleast one customer eligibility',
          fields: [
            {
              label: 'Everyone',
              name: 'customer',
              inputType: 'radio',
              radioValue: 0,
              inputBar: false
            },
            {
              label: 'Specific Customers',
              inputType: 'radio',
              radioValue: 1,
              name: 'customer',
              dbName: 'targatedCustomer',
              inputBar: true,
              barType: 'search',
              barModalType: 'customers',
              showBar: false,
              validate: true,
              error: 'Please select customers from the list'
            }
          ]
        }
      ],
      totalOrder: [
        {
          label: 'Value',
          fields: [
            {
              label: 'Fixed Discount Value',
              inputType: 'radio',
              radioValue: 1,
              name: 'discountValue',
              dbName: 'discountValue',
              inputBar: true,
              barType: 'text',
              inputIcon: 'dollar',
              showBar: false,
              validate: true,
              value: '',
              error: 'Please enter discount value'
            },
            {
              label: 'Percentage Discount Value',
              inputType: 'radio',
              radioValue: 2,
              name: 'discountValue',
              dbName: 'discountValue',
              inputBar: true,
              barType: 'text',
              inputIcon: 'percent',
              showBar: false,
              validate: true,
              value: '',
              error: 'Please enter discount value'
            }
          ]
        },
        {
          label: 'Minimum Cart Value',
          fields: [
            {
              label: 'Minimum Cart Value',
              inputType: 'text',
              name: 'minCartValue',
              dbName: 'minCartValue',
              validate: true,
              value: '',
              error: 'Please enter minimum cart value'
            }
          ]
        },
        {
          label: 'Customer Eligibility',
          dbName: 'eligibleCustomer',
          validate: true,
          value: '',
          error: 'Please select atleast one customer eligibility',
          fields: [
            {
              label: 'Everyone',
              name: 'customer',
              inputType: 'radio',
              radioValue: 0,
              inputBar: false
            },
            {
              label: 'Specific Customers',
              inputType: 'radio',
              radioValue: 1,
              name: 'customer',
              dbName: 'targatedCustomer',
              inputBar: true,
              barType: 'search',
              barModalType: 'customers',
              showBar: false,
              validate: true,
              error: 'Please select customers from the list'
            }
          ]
        }
      ]
    })

  const [searchPopup, setSearchPopup] = useState({
    modalOpen: false,
    modalType: ''
  })

  useEffect(() => {
    if (id) {
      fetchDiscountData()
      fetchProducts(1, '')
      fetchCustomers(1, '')
    }
  }, [id])

  // run when data is fetched for discount
  useEffect(() => {
    if (discountData) {
      let typeSelected = ''

      if (discountData?.type == 0) {
        typeSelected = 'fixedAmount'
      } else if (discountData?.type == 1) {
        typeSelected = 'percentage'
      } else if (discountData?.type == 2 || discountData?.type === 3) {
        typeSelected = 'bulkDiscount'
      }

      setMainOptions({
        discountCode: {
          value: discountData?.code,
          validate: true,
          error: 'Please enter discount code'
        },
        formType: {
          value: typeSelected,
          validate: true,
          error: 'Please select atleast one type'
        }
      })

      setTypesRadio(typeSelected)
      formOptionsValues(discountData)
    }
  }, [discountData])

  const formOptionsValues = (data) => {
    let typeSelected = ''

    if (data?.type == 0) {
      typeSelected = 'fixedAmount'
    } else if (data?.type == 1) {
      typeSelected = 'percentage'
    } else if (data?.type == 2) {
      typeSelected = 'bulkDiscount'
    } else if (data?.type == 3) {
      typeSelected = 'bulkDiscount'
    }

    // enter values in product and customers selected list
    if (data?.appliesTo == 2) {
      if (data?.productSKU && data?.productSKU.length > 0) {
        setSelectedProducts(data?.productSKU)
      }
    }
    if (data?.eligibleCustomer == 1) {
      if (data?.targatedCustomerDetails.length > 0) {
        const myNewArr = data?.targatedCustomerDetails.map((customer) => {
          customer.customerId = customer.id
          return customer
        })
        setSelectedCustomers(myNewArr)
      }
    }

    // enter values in form options
    const formOptionsArray = formOptions[typeSelected]
    formOptionsArray.map((formOption, i) => {
      if (formOption.value == undefined) {
        formOption.fields.map((field, j) => {
          if (field.dbName) {
            if (field.dbName === 'discountValue') {
              field.value = data?.discountValue
            } else if (field.dbName === 'minimumRequirementValue') {
              field.value = data?.minimumRequirementValue
            } else if (field.dbName === 'minCartValue') {
              field.value = data?.minCartValue
            } else if (field.dbName === 'startdate') {
              const newDate = new Date(data?.startdate)
              const dateData = newDate.toISOString().substr(0, 10)
              field.value = dateData
            } else if (field.dbName === 'startTime') {
              field.value = moment().hour(data?.startTime.split(':')[0]).minute(data?.startTime.split(':')[1])
            } else if (field.dbName === 'enddate') {
              if (data?.enddate !== null && data?.enddate !== '') {
                setEndDateInput(true)
                const newDate = new Date(data?.enddate)
                const dateData = newDate.toISOString().substr(0, 10)
                field.value = dateData
              }
            } else if (field.dbName === 'endTime') {
              field.value = moment().hour(data?.endTime.split(':')[0]).minute(data?.endTime.split(':')[1])
            }
          }
        })
      } else {
        if (formOption.dbName == 'allowMultipleUsage') {
          formOption.value = data?.allowMultipleUsage.toString()
        } else if (formOption.dbName == 'appliesTo') {
          formOption.value = data?.appliesTo.toString()
        } else if (formOption.dbName == 'minimumRequirementType') {
          formOption.value = data?.minimumRequirementType.toString()
        } else if (formOption.dbName == 'discountType') {
          formOption.value = data?.discountType.toString()
        } else if (formOption.dbName == 'eligibleCustomer') {
          formOption.value = data?.eligibleCustomer.toString()
        } else if (formOption.dbName == 'customerMultipleUsage') {
          formOption.value = data?.customerMultipleUsage.toString()
        } else if (formOption.dbName == 'discountValue') {
          formOption.value = data?.discountValue.toString()
        } else if (formOption.dbName == 'discountTypeValue') {
          formOption.value = data?.type.toString()
        }

        formOption.fields.map((field, i) => {
          if (field.dbName) {
            if (field.dbName == 'maxUsage' && formOption.value == field.radioValue) {
              field.value = data?.maxUsage
              field.showBar = true
            } else if (field.dbName == 'minimumRequirementValue' && formOption.value == field.radioValue) {
              field.value = data?.minimumRequirementValue
              field.showBar = true
            } else if (field.dbName == 'categoryIds' && formOption.value == field.radioValue) {
              field.value = data?.categoryIds
              const categoriesArr = []
              data?.categoryIds.map((cat) => {
                const checkCategory = categoriesArr.findIndex((item) => item.categoryId === cat.categoryID)
                if (checkCategory > -1) {
                  categoriesArr[checkCategory].subCategory.push(cat.SubCategory)
                } else {
                  const categoryObj = {
                    categoryId: cat.categoryID,
                    categoryName: cat.Category.categoryName,
                    subCategory: [cat.SubCategory]
                  }
                  categoriesArr.push(categoryObj)
                }
              })
              setSelectedCategories(categoriesArr)
              field.showBar = true
            } else if (field.dbName == 'productSKU' && formOption.value == field.radioValue) {
              field.value = data?.productSKU
              const productsArr = []
              data?.productSKU.map((product) => {
                productsArr.push(product.productData[0])
              })
              setSelectedProducts(productsArr)
              field.showBar = true
            } else if (field.dbName == 'targatedCustomer' && formOption.value == field.radioValue) {
              field.value = data?.targatedCustomer
              field.showBar = true
            } else if (field.dbName == 'discountValue' && formOption.value == field.radioValue) {
              field.value = data?.discountValue
              field.showBar = true
            }
          }
        })
      }
    })

    setFormOptions({ ...formOptions, [typeSelected]: formOptionsArray })
  }

  // fetch data
  const fetchDiscountData = () => {
    const url = `admin/getDiscount/${id}`

    setLoader(true)

    GetData(url).then((response) => {
      if (response.status) {
        setLoader(false)
        setDisountData(response?.result?.data)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  // fetch customers
  const fetchCustomers = (p, searchQuery) => {
    const pageSize = 20
    const url = `admin/getAllCustomers?page=${p}&pageSize=${pageSize}&status=active${searchQuery !== '' ? '&search=' + searchQuery + '' : ''}`
    GetData(url).then((response) => {
      if (response.status) {
        // manipulate and set data for table

        const customersFromDb = response?.result?.data
        selectedCustomers.map((customerSelected, i) => {
          const findCustomerIndex = customersFromDb.findIndex((customer) => customer.customerId == customerSelected.id)

          if (findCustomerIndex > -1) {
            customersFromDb.splice(findCustomerIndex, 1)
          }
        })

        setCustomerPaging({
          currentPage: p,
          recordPerPage: pageSize,
          totalRecord: response?.result?.total
        })
        setCustomersData(customersFromDb)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  // create discount code
  const fetchDiscountCode = () => {
    const url = 'admin/createDiscountCode'
    setLoader(true)
    GetData(url).then((response) => {
      if (response.status && response.code === 200) {
        setLoader(false)
        toastr.success(response.message, 'Success')
        setMainOptions({ ...mainOptions, discountCode: { ...mainOptions.discountCode, value: response.result.code, validate: true } })
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  // fetch products
  const fetchProducts = (p, searchQuery) => {
    // eslint-disable-next-line no-unused-vars
    const pageSize = 20
    const url = `admin/getProductList?page=${p}&pageSize=${pageSize}${searchQuery !== '' ? '&search=' + searchQuery + '&filterBy=partnumber' : ''}`

    GetData(url).then((response) => {
      if (response.status) {
        // manipulate and set data for table

        const productsFromDb = response?.result?.products

        selectedProducts.map((productSelected, i) => {
          const findProductIndex = productsFromDb.findIndex((product) => product.id == productSelected.id)

          if (findProductIndex > -1) {
            productsFromDb.splice(findProductIndex, 1)
          }
        })
        setProductsPaging({
          currentPage: p,
          recordPerPage: pageSize,
          totalRecord: response?.result?.total
        })
        setProductsData(productsFromDb)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  // fetch categories
  const fetchCategories = (p, searchQuery) => {
    // eslint-disable-next-line no-unused-vars
    const url = `admin/getCategoriesForDiscounts${searchQuery !== '' ? '?search=' + searchQuery + '' : ''}`

    GetData(url).then((response) => {
      if (response.status && response.code === 200) {
        // manipulate and set data for table
        const categoriesFromDb = response?.result?.category
        setCategoriesData(categoriesFromDb)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const customerPaginationHandler = (page) => {
    fetchCustomers(page, '')
  }

  const productPaginationHandler = (page) => {
    fetchProducts(page, '')
  }

  // store products temporarily until it's decided whether user has clicked cancel or add button
  const handleSelectProduct = (e, product) => {
    if (e.target.checked) {
      setTempSelectedProducts([...tempSelectedProducts, product])
    } else {
      const filterArray = tempSelectedProducts.filter((prod) => {
        return prod != product
      })
      setTempSelectedProducts(filterArray)
    }
  }

  const handleSelectCategory = (e, category, subCategory) => {
    if (category && !subCategory) {
      if (!e.target.checked) {
        const subCategoryArr = [...category.children]
        setSelectedCategories([...selectedCategories, { categoryId: category?.categoryID, categoryName: category?.categoryName, subCategory: subCategoryArr }])
      } else {
        const catArray = [...selectedCategories]
        const index = catArray.findIndex((item) => {
          return item.categoryId == category.categoryID
        })
        if (index > -1) {
          catArray.splice(index, 1)
          setSelectedCategories(catArray)
        }
      }
    } else if (category && subCategory) {
      if (!e.target.checked) {
        const catArray = [...selectedCategories]
        const index = catArray.findIndex((item) => {
          return item.categoryId == category.categoryID
        })
        if (index > -1) {
          catArray[index].subCategory.push(subCategory)
          setSelectedCategories(catArray)
        } else {
          setSelectedCategories([...selectedCategories, { categoryId: category?.categoryID, categoryName: category?.categoryName, subCategory: [subCategory] }])
        }
      } else {
        const catArray = [...selectedCategories]
        const catIndex = catArray.findIndex((item) => {
          return item.categoryId == category.categoryID
        })
        if (catIndex > -1) {
          const subCatIndex = catArray[catIndex].subCategory.findIndex((item) => {
            return item.subcategoryID === subCategory.subcategoryID
          })
          if (subCatIndex > -1) {
            if (catArray[catIndex].subCategory.length === 1) {
              catArray.splice(catIndex, 1)
              setSelectedCategories(catArray)
            } else {
              catArray[catIndex].subCategory.splice(subCatIndex, 1)
              setSelectedCategories(catArray)
            }
          }
        }
      }
    }
  }

  const handleSelectCustomers = (e, customer) => {
    if (e.target.checked) {
      setTempSelectedCustomers([...tempSelectedCustomers, customer])
    } else {
      const filterArray = tempSelectedCustomers.filter((cust) => {
        return cust != customer
      })
      setTempSelectedCustomers(filterArray)
    }
  }

  // store in main selected product list as add button is clicked
  const handleProductListDisplay = (e) => {
    e.preventDefault()
    const productsArr = [...productsData]

    // add temp product list to already present prdocuts list and show the product list
    tempSelectedProducts.map((product, i) => {
      const index = productsArr.indexOf(product)
      if (index > -1) {
        productsArr.splice(index, 1)
      }

      setSelectedProducts((prevState) => ([
        ...prevState,
        product
      ]))
    })

    setTempSearchProduct(null)
    setProductsData(productsArr)
    setSearchPopup({ modalOpen: false, modalType: '' })

    // empty the temp product array once it's added to selected product list
    setTempSelectedProducts([])
  }

  // store in main selected product list as add button is clicked
  const handleCategoryListDisplay = (e) => {
    e.preventDefault()
    const categoryArr = [...categoriesData]

    // add temp product list to already present prdocuts list and show the product list
    tempSelectedCategories.map((category, i) => {
      const index = categoryArr.indexOf(category)
      if (index > -1) {
        categoryArr.splice(index, 1)
      }

      setSelectedCategories((prevState) => ([
        ...prevState,
        category
      ]))
    })

    setTempSearchCategories(null)
    setCategoriesData(categoryArr)
    setSearchPopup({ modalOpen: false, modalType: '' })

    // empty the temp product array once it's added to selected product list
    setTempSelectedCategories([])
  }

  const handleCustomerListDisplay = (e) => {
    e.preventDefault()

    const customersArr = [...customersData]

    // add temp product list to already present prdocuts list and show the product list
    tempSelectedCustomers.map((customer, i) => {
      const index = customersArr.indexOf(customer)
      if (index > -1) {
        customersArr.splice(index, 1)
      }

      setSelectedCustomers((prevState) => ([
        ...prevState,
        customer
      ]))
    })

    setTempSearchCustomer(null)
    setCustomersData(customersArr)
    setSearchPopup({ modalOpen: false, modalType: '' })

    // empty the temp product array once it's added to selected product list
    setTempSelectedCustomers([])
  }

  // remove all the products from temp array as cancel button is clicked
  const handleCancelProductListDisplay = (e) => {
    e.preventDefault()
    setSearchPopup({ modalOpen: false, modalType: '' })
    setTempSelectedProducts([])
  }

  const handleCancelCategoryListDisplay = (e) => {
    e.preventDefault()
    setSearchPopup({ modalOpen: false, modalType: '' })
    setTempSelectedCategories([])
  }

  const handleCancelCustomerListDisplay = (e) => {
    e.preventDefault()
    setSearchPopup({ modalOpen: false, modalType: '' })
    setTempSelectedCustomers([])
  }

  const removeProduct = (e, k) => {
    const productArr = [...selectedProducts]
    const productRemove = productArr[k]

    setSelectedProducts(selectedProducts.filter((prod) => {
      return prod != productRemove
    }))

    setProductsData([...productsData, productRemove])
  }

  // eslint-disable-next-line no-unused-vars
  const removeCategory = (e, catId, subId) => {
    const catArray = [...selectedCategories]
    const catIndex = catArray.findIndex((item) => {
      return item.categoryId == catId
    })
    if (catIndex > -1) {
      const subCatIndex = catArray[catIndex].subCategory.findIndex((item) => {
        return item.subcategoryID === subId
      })
      if (subCatIndex > -1) {
        if (catArray[catIndex].subCategory.length === 1) {
          catArray.splice(catIndex, 1)
          setSelectedCategories(catArray)
        } else {
          catArray[catIndex].subCategory.splice(subCatIndex, 1)
          setSelectedCategories(catArray)
        }
      }
    }
  }

  const removeCustomer = (e, k) => {
    const customerArr = [...selectedCustomers]
    const customerRemove = customerArr[k]

    setSelectedCustomers(selectedCustomers.filter((cust) => {
      return cust != customerRemove
    }))

    setCustomersData([...customersData, customerRemove])
  }

  // function to call fetch data for customers and product
  const fetchModalData = (e, modalType) => {
    e.preventDefault()
    if (modalType == 'customers') {
      fetchCustomers(1, '')
    } else if (modalType === 'categories') {
      fetchCategories(1, '')
    } else {
      fetchProducts(1, '')
    }
    setSearchPopup({ modalOpen: true, modalType })
  }

  // validate main options
  const [mainOptions, setMainOptions] = useState({
    discountCode: {
      value: '',
      validate: true,
      error: 'Please enter discount code'
    },
    formType: {
      value: '',
      validate: true,
      error: 'Please select atleast one type'
    }
  })

  // function to show and hide input text for radio buttons
  const showRadioInput = (e, i, j, radioOption) => {
    const radioArray = [...formOptions[radioOption]]

    if (!e.target.checked) {
      // make show input false for every filed
      for (let k = 0; k < radioArray[i].fields.length; k++) {
        if (radioArray[i].fields[k].showBar != undefined) {
          radioArray[i].fields[k].showBar = false
        }
      }

      // make show input true only for the radio button that is selected from field
      if (radioArray[i].fields[j].showBar != undefined) {
        radioArray[i].fields[j].showBar = true
      }
      setFormOptions({ ...formOptions, percentage: radioArray })
    }
  }

  // validate main form options
  const handleMainOptions = (e) => {
    if (e.target.name == 'formType') {
      !e.target.checked && setTypesRadio(e.target.value)
      setMainOptions({ ...mainOptions, [e.target.name]: { ...mainOptions[e.target.name], value: e.target.value, validate: true } })
    } else {
      setMainOptions({ ...mainOptions, [e.target.name]: { ...mainOptions[e.target.name], value: e.target.value, validate: true } })
    }
  }

  // validate form options and put values for them
  const handleFormOptions = (e, i, j) => {
    if (e.target.type == 'text' || e.target.type == 'date' || e.target.type == 'time') {
      const arr = formOptions[typesRadio]
      arr[i].fields[j].value = e.target.value
      arr[i].fields[j].validate = true
      setFormOptions({ ...formOptions, [typesRadio]: arr })
    } else if (e.target.type == 'radio') {
      const arr = formOptions[typesRadio]
      arr[i].value = e.target.value
      arr[i].validate = true
      arr[i].fields[j].checked = true
      setFormOptions({ ...formOptions, [typesRadio]: arr })
    }
  }

  // validate text inputs generated from radio buttons
  const handleTextFromRadio = (e, i, j) => {
    const arr = formOptions[typesRadio]
    arr[i].fields[j].value = e.target.value
    arr[i].fields[j].validate = true
    setFormOptions({ ...formOptions, [typesRadio]: arr })
  }

  const saveData = () => {
    const discountId = id
    const code = mainOptions.discountCode.value
    let type = ''
    let discountValue = ''
    const maxCapValue = ''
    let startdate = ''
    let enddate = ''
    let startTime = ''
    let endTime = ''
    let appliesTo = ''
    let minimumRequirementType = ''
    const allowMultipleUsage = 0
    let minimumRequirementValue = 0
    let customerMultipleUsage = 0
    const categoryIds = []
    const productSKU = []
    const targatedCustomer = []
    const discountType = auto ? 1 : 0
    let maxUsage = 0
    let eligibleCustomer = 0
    let minCartValue = 0

    if (typesRadio == 'percentage') {
      type = 1
    } else if (typesRadio == 'fixedAmount') {
      type = 0
    } else if (typesRadio == 'bulkDiscount') {
      type = 2
    }

    formOptions[typesRadio].map((formOption, i) => {
      if (formOption.value) {
        if (formOption.dbName == 'appliesTo') {
          appliesTo = parseInt(formOption.value)
          if (formOption.value == '1') {
            selectedCategories.map((category, j) => {
              const subcategoryArr = []
              category.subCategory.map((sub) => {
                subcategoryArr.push(sub.subcategoryID)
              })
              const catObj = {
                categoryId: category.categoryId,
                subCategories: subcategoryArr
              }
              categoryIds.push(catObj)
            })
          } else if (formOption.value == '2') {
            selectedProducts.map((product, j) => {
              productSKU.push(product.partNumber)
            })
          }
        } else if (formOption.dbName == 'minimumRequirementType') {
          minimumRequirementType = parseInt(formOption.value)
        } else if (formOption.dbName == 'discountTypeValue') {
          if (parseInt(formOption.value) === 2) {
            type = 2
          } else {
            type = 3
          }
        } else if (formOption.dbName == 'customerMultipleUsage') {
          customerMultipleUsage = parseInt(formOption.value)
        } else if (formOption.dbName == 'eligibleCustomer') {
          if (formOption.value == '1') {
            if (id) {
              selectedCustomers.map((customer, j) => {
                targatedCustomer.push(customer.customerId)
              })
            } else {
              selectedCustomers.map((customer, j) => {
                targatedCustomer.push(customer.customerId)
              })
            }
          }
          eligibleCustomer = parseInt(formOption.value)
        }

        formOption.fields.map((field, j) => {
          if (field.inputBar) {
            if (field.dbName == 'minimumRequirementValue') {
              if (field.value != '') {
                minimumRequirementValue = parseInt(field.value)
              }
            } else if (field.dbName == 'maxUsage') {
              if (field.value != '') {
                maxUsage = parseInt(field.value)
              }
            } else if (field.dbName === 'discountValue') {
              if (field.value !== '') {
                discountValue = parseInt(field.value)
              }
            }
          }
        })
      } else {
        formOption.fields.map((field, j) => {
          if (field.dbName == 'discountValue') {
            discountValue = parseInt(field.value)
          } if (field.dbName === 'minimumRequirementValue') {
            minimumRequirementValue = parseInt(field.value)
          } if (field.dbName == 'minCartValue') {
            minCartValue = parseInt(field.value)
          } if (field.dbName == 'startdate') {
            startdate = field.value
          } if (field.dbName == 'startTime') {
            startTime = typeof field.value === 'object' ? field.value.format('HH:mm:ss') : field.value
          } if (field.dbName == 'enddate') {
            if (endDateInput) {
              enddate = field.value
            }
          } if (field.dbName == 'endTime') {
            if (endDateInput) {
              endTime = typeof field.value === 'object' ? field.value.format('HH:mm:ss') : field.value
            }
          }
        })
      }
    })

    const formData = {
      id: discountId,
      code,
      type,
      discountValue,
      maxCapValue,
      startdate,
      startTime,
      enddate,
      endTime,
      appliesTo,
      minimumRequirementType,
      allowMultipleUsage,
      minimumRequirementValue,
      customerMultipleUsage,
      categoryIds,
      productSKU,
      targatedCustomer,
      discountType,
      maxUsage,
      eligibleCustomer,
      minCartValue
    }

    setLoader(true)

    if (id) {
      const url = 'admin/editDiscount'
      PutData(url, formData).then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Success')
          setLoader(false)
          history.push('/discount')
        } else {
          setLoader(false)
          toastr.error(response.message, 'Error')
        }
      })
    } else {
      const url = 'admin/createDiscount'
      PostData(url, formData).then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Success')
          setLoader(false)
          history.push('/discount')
        } else {
          setLoader(false)
          toastr.error(response.message, 'Error')
        }
      })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let error = false

    // check if any of the main form option is empty
    Object.keys(mainOptions).map((option, index) => {
      if (mainOptions[option].value == '') {
        error = true
        setMainOptions((prevState) => ({
          ...prevState,
          [option]: { ...mainOptions[option], validate: false }
        }))
      }
    })

    formOptions[typesRadio].map((formOption, i) => {
      // check for text box values
      if (formOption.value == undefined) {
        formOption.fields.map((field, j) => {
          if (!endDateInput) {
            if ((field.name !== 'endDate' && field.name !== 'endTime')) {
              if (field.name == 'discountValue' && field.value == '') {
                error = true
                const formArray = formOptions[typesRadio]
                formArray[i].fields[j].validate = false

                setFormOptions((prevState) => ({
                  ...prevState,
                  [typesRadio]: formArray
                }))
              } else if (field.name == 'minCartValue' && field.value == '') {
                error = true
                const formArray = formOptions[typesRadio]
                formArray[i].fields[j].validate = false

                setFormOptions((prevState) => ({
                  ...prevState,
                  [typesRadio]: formArray
                }))
              } else if (field.value == '') {
                error = true
                const formArray = formOptions[typesRadio]
                formArray[i].fields[j].validate = false

                setFormOptions((prevState) => ({
                  ...prevState,
                  [typesRadio]: formArray
                }))
              }
            }
          } else {
            if (field.name == 'discountValue' && field.value == '') {
              error = true
              const formArray = formOptions[typesRadio]
              formArray[i].fields[j].validate = false

              setFormOptions((prevState) => ({
                ...prevState,
                [typesRadio]: formArray
              }))
            } else if (field.name == 'minCartValue' && field.value == '') {
              error = true
              const formArray = formOptions[typesRadio]
              formArray[i].fields[j].validate = false

              setFormOptions((prevState) => ({
                ...prevState,
                [typesRadio]: formArray
              }))
            } else if (field.value == '') {
              error = true
              const formArray = formOptions[typesRadio]
              formArray[i].fields[j].validate = false

              setFormOptions((prevState) => ({
                ...prevState,
                [typesRadio]: formArray
              }))
            }
          }
        })
      } else {
        if (formOption.value == '') {
          // validate radio button values
          error = true
          const formArray = formOptions[typesRadio]
          formArray[i].validate = false

          setFormOptions((prevState) => ({
            ...prevState,
            [typesRadio]: formArray
          }))
        } else {
          // validate radio button input text
          formOption.fields.map((field, j) => {
            if (field.radioValue == parseInt(formOption.value)) {
              if (field.dbName == 'categoryIds') {
                if (formOption.value == '1') {
                  if (selectedCategories.length == 0) {
                    error = true

                    const formArray = formOptions[typesRadio]
                    formArray[i].fields[j].validate = false

                    setFormOptions((prevState) => ({
                      ...prevState,
                      [typesRadio]: formArray
                    }))
                  }
                }
              } else if (field.dbName == 'productSKU') {
                if (formOption.value == '2') {
                  if (selectedProducts.length == 0) {
                    error = true

                    const formArray = formOptions[typesRadio]
                    formArray[i].fields[j].validate = false

                    setFormOptions((prevState) => ({
                      ...prevState,
                      [typesRadio]: formArray
                    }))
                  }
                }
              } else if (field.dbName == 'targatedCustomer') {
                if (formOption.value == '1') {
                  if (selectedCustomers.length == 0) {
                    error = true

                    const formArray = formOptions[typesRadio]
                    formArray[i].fields[j].validate = false

                    setFormOptions((prevState) => ({
                      ...prevState,
                      [typesRadio]: formArray
                    }))
                  }
                }
              } else {
                if (field.value == '') {
                  error = true
                  const formArray = formOptions[typesRadio]
                  formArray[i].fields[j].validate = false

                  setFormOptions((prevState) => ({
                    ...prevState,
                    [typesRadio]: formArray
                  }))
                }
              }
            }
          })
        }
      }
    })

    if (!error) {
      saveData()
    } else {
      toastr.error('Please fix all the errors', 'Error')
    }
  }

  const modalPopup = (modalStatus, modalType) => {
    setSearchPopup({ modalOpen: modalStatus, modalType })
  }
  // const now = moment().hour(0).minute(0)

  const format = 'h:mm a'

  const handleTime = (e, i, j) => {
    const arr = formOptions[typesRadio]
    arr[i].fields[j].value = e.format('HH:mm:ss')
    arr[i].fields[j].validate = true
    setFormOptions({ ...formOptions, [typesRadio]: arr })
    // console.log(e && e.format('HH:mm:ss'))
    // console.log(e)
  }

  return (
    <>
            <Loader show={loader}></Loader>
      <div className="page-content">
        <Container fluid={true} className="customer-container">
          <div className="discount-table-head">
            <div className="d-flex align-items-center">
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/discount')}
                className="dripicons-arrow-thin-left me-3"
              ></i>
              <Breadcrumbs
                title="UI Elements"
                breadcrumbItem="Create Discount Code"
              />
            </div>
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="create-discount-inner-container">
                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <Label className="form-label" htmlFor="discount-code">
                          <b>Discount Code</b>
                        </Label>
                        <span className="code-link" onClick={() => fetchDiscountCode()}>
                          <b>Generate Code</b>
                        </span>
                      </div>
                      <Input
                        placeholder="eg. QLS123"
                        type="text"
                        id="discount-code"
                        name="discountCode"
                        className="input-border"
                        onChange={(e) => handleMainOptions(e)}
                        value={ mainOptions.discountCode.value != '' ? mainOptions.discountCode.value : ''}
                      />
                      <p className="m-0 mt-2">
                        Customers will enter this discount code at checkout.
                      </p>
                      { !mainOptions.discountCode.validate
                        ? (
                          <span className="validation-check">
                            {mainOptions.discountCode.error}
                          </span>
                          )
                        : null}
                    </div>
                    <hr className=" mt-0 mb-4"></hr>
                    <div className="mb-3">
                      <p>
                        <b>Types</b>
                      </p>

                        {
                          ['fixedAmount', 'percentage', 'bulkDiscount'].map((option, i) => {
                            return (
                              <div className="mb-1" key={i}>
                              <Input
                                placeholder="eg. QLS123"
                                type="radio"
                                id={`discount-types-${i + 1}`}
                                name="formType"
                                className="input-border cursor-pointer"
                                value={option}
                                checked={typesRadio == option}
                                onClick={(e) => handleMainOptions(e)}
                                readOnly
                              />
                              <Label
                                  className="form-label ms-2 cursor-pointer"
                                  htmlFor={`discount-types-${i + 1}`}
                              >
                                {
                                  option == 'percentage'
                                    ? 'Percentage'
                                    : option == 'fixedAmount'
                                      ? 'Fixed Amount'
                                      : option == 'bulkDiscount' ? 'Bulk Buying Discount' : 'Total Order/Cart Value Discount'
                                }
                              </Label>
                              </div>
                            )
                          })
                        }

                      { !mainOptions.formType.validate
                        ? (
                          <span className="validation-check">
                            {mainOptions.formType.error}
                          </span>
                          )
                        : null}
                    </div>
                    <hr className=" mt-0 mb-4"></hr>

                    {
                        typesRadio && <>
                            {
                                formOptions[typesRadio].map((formOption, key) => {
                                  return (
                                        <div key={key}>
                                            <div className="mb-3">
                                            <p>
                                                <b>{formOption.label}</b>
                                            </p>
                                            {
                                                formOption?.fields.map((field, j) => {
                                                  if (field.inputType == 'text') {
                                                    return (
                                                            <Row className="mt-3" key={j}>
                                                                <Col lg={6}>
                                                                    <Label
                                                                        htmlFor={field.name}
                                                                        className="form-label cursor-pointer"
                                                                    >
                                                                        {field.label}
                                                                    </Label>
                                                                {
                                                                    field.inputIcon != undefined
                                                                      ? (
                                                                        <div className={field.inputIcon}>
                                                                            <Input
                                                                                type="text"
                                                                                id={field.name}
                                                                                name={field.name}
                                                                                className="input-border w-40"
                                                                                value={field?.value}
                                                                                onChange={(e) => handleFormOptions(e, key, j)}
                                                                            ></Input>
                                                                            { !formOptions[typesRadio][key].fields[j].validate
                                                                              ? (
                                                                                <span className="validation-check">
                                                                                  {formOptions[typesRadio][key].fields[j].error}
                                                                                </span>
                                                                                )
                                                                              : null}
                                                                        </div>
                                                                        )
                                                                      : (
                                                                        <>
                                                                          <Input
                                                                            type="text"
                                                                            id="discount-value"
                                                                            name="discount-value"
                                                                            className="input-border w-40"
                                                                            value={field?.value}
                                                                            onChange={(e) => handleFormOptions(e, key, j)}
                                                                        ></Input>
                                                                        { !formOptions[typesRadio][key].fields[j].validate
                                                                          ? (
                                                                          <span className="validation-check">
                                                                            {formOptions[typesRadio][key].fields[j].error}
                                                                          </span>
                                                                            )
                                                                          : null}
                                                                        </>
                                                                        )
                                                                }
                                                                </Col>
                                                            </Row>)
                                                  } else if (field.inputType == 'radio') {
                                                    return (
                                                            <div className="mb-1" key={j}>
                                                                {
                                                                        <>
                                                                            {
                                                                                discountData
                                                                                  ? (
                                                                                    <>
                                                                                    <Input
                                                                                        placeholder="eg. QLS123"
                                                                                        type="radio"
                                                                                        id={`${field?.name}${j}`}
                                                                                        name={field?.name}
                                                                                        className={'input-border cursor-pointer radio-select'}
                                                                                        value={field?.radioValue}
                                                                                        checked={parseInt(field?.radioValue) == formOption?.value}
                                                                                        onClick={(e) => {
                                                                                          handleFormOptions(e, key, j)
                                                                                          showRadioInput(e, key, j, typesRadio)
                                                                                        }}
                                                                                        readOnly
                                                                                    />
                                                                                    <Label
                                                                                        className="form-label ms-2 cursor-pointer"
                                                                                        htmlFor={`${field.name}${j}`}
                                                                                    >
                                                                                        {field.label}
                                                                                    </Label>
                                                                                    </>
                                                                                    )
                                                                                  : (
                                                                                    <>
                                                                                        <Input
                                                                                        placeholder="eg. QLS123"
                                                                                        type="radio"
                                                                                        id={`${field.name}${j}`}
                                                                                        name={field.name}
                                                                                        className={'input-border cursor-pointer radio-select'}
                                                                                        value={field.radioValue}
                                                                                        checked={!!((formOption.value != '' && field.radioValue == formOption.value))}
                                                                                        onClick={(e) => {
                                                                                          handleFormOptions(e, key, j)
                                                                                          showRadioInput(e, key, j, typesRadio)
                                                                                        }}
                                                                                        readOnly
                                                                                        />
                                                                                        <Label
                                                                                            className="form-label ms-2 cursor-pointer"
                                                                                            htmlFor={`${field.name}${j}`}
                                                                                        >
                                                                                            {field.label}
                                                                                        </Label>
                                                                                    </>
                                                                                    )
                                                                            }
                                                                            {
                                                                                field.showBar && <>
                                                                                {
                                                                                    field.barType == 'text'
                                                                                      ? (
                                                                                        <Row className="ms-2">
                                                                                            <Col lg={3}>
                                                                                                <div className={field.inputIcon}>
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        value={field.value}
                                                                                                        className={'input-border'}
                                                                                                        onChange={(e) => handleTextFromRadio(e, key, j)}
                                                                                                    ></Input>
                                                                                                </div>
                                                                                            </Col>
                                                                                            {
                                                                                                field.extraLabel ? <p className="m-0 mt-1">Applies only to selected products</p> : null
                                                                                            }
                                                                                            { !formOptions[typesRadio][key].fields[j].validate
                                                                                              ? (
                                                                                                <span className="validation-check">
                                                                                                    {formOptions[typesRadio][key].fields[j].error}
                                                                                                </span>
                                                                                                )
                                                                                              : null}
                                                                                            </Row>
                                                                                        )
                                                                                      : (
                                                                                        <form className="mb-3">
                                                                                            <div className="d-flex">
                                                                                            <Input
                                                                                                placeholder={`${field.barModalType == 'products' ? 'Search products' : field.barModalType === 'categories' ? 'Search Categories' : 'Search customers'}`}
                                                                                                type="text"
                                                                                                id="search-products"
                                                                                                name="search-products"
                                                                                                className="input-border"
                                                                                                onClick={(e) => fetchModalData(e, field.barModalType)}
                                                                                            />
                                                                                            <button
                                                                                                type="submit"
                                                                                                className="btn border border-secondary ms-2 bg-transparent text-dark"
                                                                                                onClick={(e) => fetchModalData(e, field.barModalType)}
                                                                                            >
                                                                                                Search
                                                                                            </button>
                                                                                            </div>
                                                                                            {
                                                                                                !formOptions[typesRadio][key].fields[j].validate
                                                                                                  ? (
                                                                                                <span className="validation-check">
                                                                                                    {formOptions[typesRadio][key].fields[j].error}
                                                                                                </span>
                                                                                                    )
                                                                                                  : null}
                                                                                            {
                                                                                              field.barModalType == 'products'
                                                                                                ? (
                                                                                                    selectedProducts.length > 0 && <>
                                                                                                  <div className="search-list">
                                                                                                    <h4>Selected Products</h4>
                                                                                                    <table>
                                                                                                      <tbody>
                                                                                                        {
                                                                                                          selectedProducts.map((product, k) => {
                                                                                                            return (
                                                                                                              <tr key={k}>
                                                                                                                <td className="text-center">{product?.imageMapper?.length > 0 ? <img src={product.imageMapper[0].imageUrl} style={{ width: '60px', height: '60px', objectFit: 'contain' }} /> : <i className="bx bx-image-alt image-icon"></i>}</td>
                                                                                                                <td>
                                                                                                                  {
                                                                                                                    product?.partNumber
                                                                                                                  }
                                                                                                                </td>
                                                                                                                <td onClick={(e) => removeProduct(e, k)}>
                                                                                                                  <i className="mdi mdi-close image-icon"></i>
                                                                                                                </td>
                                                                                                              </tr>
                                                                                                            )
                                                                                                          })
                                                                                                        }
                                                                                                    </tbody>
                                                                                                    </table>
                                                                                                    </div>
                                                                                                </>
                                                                                                  )
                                                                                                : field.barModalType === 'categories'
                                                                                                  ? (
                                                                                                      selectedCategories.length > 0 && <>
                                                                                                  <div className="search-list">
                                                                                                    <h4>Selected Categories</h4>
                                                                                                    <table>
                                                                                                      <tbody>
                                                                                                      {
                                                                                                        selectedCategories.map((category, k) => {
                                                                                                          return (
                                                                                                          <>
                                                                                                          {
                                                                                                            category?.subCategory.map((sub) => {
                                                                                                              return (
                                                                                                                <tr key={k}>
<td>{category?.categoryName}</td>
<td>{sub?.subCategoryName}</td>
<td onClick={(e) => removeCategory(e, category?.categoryId, sub?.subcategoryID)}><i className="mdi mdi-close image-icon"></i> </td>
</tr>
                                                                                                              )
                                                                                                            })
                                                                                                          }
                                                                                                          </>
                                                                                                          )
                                                                                                        })}
                                                                                                    </tbody>
                                                                                                    </table>
                                                                                                    </div>
                                                                                                </>
                                                                                                    )
                                                                                                  : (
                                                                                                      selectedCustomers.length > 0 && <>
                                                                                                <div className="search-list">
                                                                                                    <h4>Selected Customers</h4>
                                                                                                      <table>
                                                                                                      <tbody>
                                                                                                      {
                                                                                                        selectedCustomers.map((customer, k) => {
                                                                                                          return (
                                                                                                            <tr key={k}>
                                                                                                              <td>{
                                                                                                                      customer?.companyName
                                                                                                                }</td>
                                                                                                              <td>
                                                                                                                {
                                                                                                                    customer?.email
                                                                                                                }
                                                                                                              </td>
                                                                                                              <td onClick={(e) => removeCustomer(e, k)}>
                                                                                                                <i className="mdi mdi-close image-icon"></i>
                                                                                                              </td>
                                                                                                            </tr>
                                                                                                          )
                                                                                                        })
                                                                                                  }
                                                                                                  </tbody>
                                                                                                </table>
                                                                                                  </div>
                                                                                                </>
                                                                                                    )
                                                                                            }
                                                                                        </form>
                                                                                        )
                                                                                }
                                                                                </>
                                                                            }
                                                                        </>
                                                                }
                                                            </div>
                                                    )
                                                  } else if (field.inputType == 'date') {
                                                    return (
                                                        <>
                                                            {
                                                                field.name == 'endDate'
                                                                  ? (
                                                                <>
                                                                    {
                                                                        endDateInput && <div className="mb-1 set-float" key={j}>
                                                                            <Label
                                                                                className="form-label cursor-pointer"
                                                                                htmlFor={field.name}
                                                                            >
                                                                                <b>{field.label}</b>
                                                                            </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={field.name}
                                                                                name={field.name}
                                                                                className="input-border cursor-pointer"
                                                                                value={field.value}
                                                                                onChange={(e) => handleFormOptions(e, key, j)}
                                                                            />
                                                                            { !formOptions[typesRadio][key].fields[j].validate
                                                                              ? (
                                                                                <span className="validation-check">
                                                                                  {formOptions[typesRadio][key].fields[j].error}
                                                                                </span>
                                                                                )
                                                                              : null}
                                                                        </div>
                                                                    }
                                                                </>
                                                                    )
                                                                  : (
                                                                <div className="mb-1 set-float" key={j}>
                                                                    <Label
                                                                        className="form-label cursor-pointer"
                                                                        htmlFor={field.name}
                                                                    >
                                                                        <b>{field.label}</b>
                                                                    </Label>
                                                                    <Input
                                                                        type="date"
                                                                        id="start-date"
                                                                        name={field.name}
                                                                        value={field.value}
                                                                        className="input-border cursor-pointer"
                                                                        onChange={(e) => handleFormOptions(e, key, j)}
                                                                    />
                                                                    { !formOptions[typesRadio][key].fields[j].validate
                                                                      ? (
                                                                                <span className="validation-check">
                                                                                  {formOptions[typesRadio][key].fields[j].error}
                                                                                </span>
                                                                        )
                                                                      : null}
                                                                </div>
                                                                    )
                                                            }
                                                        </>
                                                    )
                                                  } else if (field.inputType == 'time') {
                                                    return (
                                                            <>
                                                            {
                                                                field.name == 'endTime'
                                                                  ? (
                                                                    <>
                                                                    {
                                                                        endDateInput && <div className="mb-1 set-float mg-l" key={j}>
                                                                        <Label
                                                                            className="form-label cursor-pointer"
                                                                            htmlFor={field.name}
                                                                        >
                                                                            <b>{field.label} (UTC)</b>
                                                                        </Label>
                                                                        <TimePicker
                                                                          id={field.name}
                                                                          name={field.name}
                                                                          showSecond={false}
                                                                          defaultValue={field.value}
                                                                          className="time-picker form-control input-border cursor-pointer"
                                                                          onChange={(e) => handleTime(e, key, j)}
                                                                          format={format}
                                                                          popupClassName="time-picker-popup"
                                                                          inputReadOnly
                                                                          use12Hours
                                                                          clearIcon={<></>}
                                                                        />
                                                                        {/* <Input
                                                                            type="time"
                                                                            id={field.name}
                                                                            name={field.name}
                                                                            value={field.value}
                                                                            className="input-border cursor-pointer"
                                                                            onChange={(e) => handleFormOptions(e, key, j)}
                                                                        /> */}
                                                                        { !formOptions[typesRadio][key].fields[j].validate
                                                                          ? (
                                                                                <span className="validation-check">
                                                                                  {formOptions[typesRadio][key].fields[j].error}
                                                                                </span>
                                                                            )
                                                                          : null}
                                                                        </div>
                                                                    }
                                                                    </>
                                                                    )
                                                                  : (
                                                                    <div className="mb-1 set-float mg-l" key={j}>
                                                                        <Label
                                                                            className="form-label cursor-pointer"
                                                                            htmlFor={field.name}
                                                                        >
                                                                            <b>{field.label} (UTC)</b>
                                                                        </Label>
                                                                        <TimePicker
                                                                          id={field.name}
                                                                          name={field.name}
                                                                          showSecond={false}
                                                                          defaultValue={field.value}
                                                                          className="time-picker form-control input-border cursor-pointer"
                                                                          onChange={(e) => handleTime(e, key, j)}
                                                                          format={format}
                                                                          use12Hours
                                                                          popupClassName="time-picker-popup"
                                                                          clearIcon={<></>}
                                                                          inputReadOnly
                                                                        />
                                                                        {/* <Input
                                                                            type="time"
                                                                            id="start-time"
                                                                            name={field.name}
                                                                            className="input-border cursor-pointer"
                                                                            value={field.value}
                                                                            onChange={(e) => handleFormOptions(e, key, j)}
                                                                        /> */}
                                                                        { !formOptions[typesRadio][key].fields[j].validate
                                                                          ? (
                                                                                <span className="validation-check">
                                                                                  {formOptions[typesRadio][key].fields[j].error}
                                                                                </span>
                                                                            )
                                                                          : null}
                                                                    </div>
                                                                    )
                                                            }
                                                            </>
                                                    )
                                                  } else if (field.inputType == 'checkbox') {
                                                    return (
                                                            <div className="mt-1 mb-3 " key={j}>
                                                                <Input
                                                                    type="checkbox"
                                                                    id="set-end-time"
                                                                    name={field.name}
                                                                    className="input-border  cursor-pointer"
                                                                    checked={endDateInput}
                                                                    onClick={(e) => !e.target.checked ? setEndDateInput(true) : setEndDateInput(false) }
                                                                    readOnly
                                                                />
                                                                <Label
                                                                    className="form-label ms-2 cursor-pointer"
                                                                    htmlFor={field.name}
                                                                >
                                                                    {field.label}
                                                                </Label>
                                                            </div>
                                                    )
                                                  }
                                                })
                                            }
                                            </div>
                                            { !formOption.validate
                                              ? (
                                                  <div className="validation-check mb-1">
                                                        {formOption.error}
                                                  </div>
                                                )
                                              : null}
                                            <hr className=" mt-0 mb-4"></hr>
                                        </div>
                                  )
                                })
                            }
                        </>
                    }
                    <div>
                      <button className="btn btn-danger" onClick={(e) => handleSubmit(e)}>Save</button>
                      <Link to="/discount">
                        <button className="btn ms-3 btn-light">Discard</button>
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
                {
                  searchPopup.modalType === 'products' &&
                  <SelectionModalTable
                   modalPopup={modalPopup}
                   modalState={searchPopup}
                   fetchData={fetchProducts}
                   tempData={tempSearchProduct}
                   mainData={productsData}
                   tempSelected={tempSelectedProducts}
                   selectedData={selectedProducts}
                   handleSelect={handleSelectProduct}
                   handleCancel={handleCancelProductListDisplay}
                   handleAdd={handleProductListDisplay}
                   paging={productsPaging}
                   paginationHandler={productPaginationHandler}
                    />
                }
                {
                  searchPopup.modalType === 'categories' &&
                  <CategoryModalTable
                   modalPopup={modalPopup}
                   modalState={searchPopup}
                   fetchData={fetchCategories}
                   tempData={tempSearchCategories}
                   mainData={categoriesData}
                   tempSelected={tempSelectedCategories}
                   selectedData={selectedCategories}
                   handleSelect={handleSelectCategory}
                   paging={categoryPaging}
                   handleCancel={handleCancelCategoryListDisplay}
                   handleAdd={handleCategoryListDisplay}
                    />
                }
                {
                  searchPopup.modalType === 'customers' &&
                  <SelectionModalTable
                  modalPopup={modalPopup}
                  modalState={searchPopup}
                  fetchData={fetchCustomers}
                  tempData={tempSearchCustomer}
                  mainData={customersData}
                  tempSelected={tempSelectedCustomers}
                  selectedData={selectedCustomers}
                  handleSelect={handleSelectCustomers}
                  handleCancel={handleCancelCustomerListDisplay}
                  handleAdd={handleCustomerListDisplay}
                  paging={customerPaging}
                  paginationHandler={customerPaginationHandler} />
                }
        </>
  )
}
