import React, { useState } from 'react'
import { Document, Page, View, Text, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import { useLocation } from 'react-router-dom'
// Create styles
const styles = StyleSheet.create({
  page: {
    padding: '30px'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowTop: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowTopText: {
    border: '1px solid #000'
  },
  // So Declarative and unDRY 👌
  rowTop1: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop2: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop3: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop4: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop5: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  row1: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row2: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    wordBreak: 'break-word',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row3: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    wordBreak: 'break-word',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row4: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row5: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: '300',
    border: '1px solid #000',
    display: 'flex',
    flexDirection: 'column'
  },
  uom: {
    fontSize: '8px',
    color: '#202020',
    display: 'block'
  }
})
function InvoicingPdf () {
  const [data, setData] = useState()
  const location = useLocation()
  React.useEffect(() => {
    setData(location.state)
  }, [location])
  //   console.log(data)
  return (
        <>
        <PDFViewer>
            <Document>
      <Page size="A4" style={styles.page}>
      <View style={styles.rowTop}>
        <Text style={styles.rowTop1}>Month</Text>
        <Text style={styles.rowTop2}>Total Order</Text>
        <Text style={styles.rowTop3}>Order Value</Text>
        <Text style={styles.rowTop4}>Delivered Date</Text>
        <Text style={styles.rowTop5}>
          {/* {
            slip === 'pickTicket' ? 'Qty Ordered' : 'Uom'
        } */} Invoiced Data
        </Text>
      </View>
      {
        data?.data.map((item, i) => {
          return (
                <View style={styles.row} wrap={false} key={i}>
                    <Text style={styles.row1}>
          {item.Month}
          </Text>
          <Text style={styles.row2}>{item.OrderCount}</Text>
          <Text style={styles.row3}>
          {item.OrderTotal}</Text>
          <Text style={styles.row4}>
          {item.DeliveredOrder}
          </Text>
          <Text style={styles.row5}>
            {item.InvoiceTotal}
          </Text>
                </View>
          )
        })
      }
      </Page>
    </Document>
        </PDFViewer>
        </>
  )
}
export default InvoicingPdf
