/* eslint-disable no-dupe-keys */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Form,
  Input,
  Label,
  FormGroup,
  Button
} from 'reactstrap'

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Dropzone from 'react-dropzone'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import Loader from '../../components/Loader'
import { PutData, GetData, ToesterSettings } from '../../apiHandler'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function GlobalSettings (props) {
  toastr.options = ToesterSettings
  const [settings, setSettings] = useState({})
  const [loader, setLoader] = useState(false)
  const [headerLogo, setHeaderLogo] = useState([])
  const [footerLogo, setFooterLogo] = useState([])
  const [imageChange, setImageChange] = useState(false)
  const [imageError, setImageError] = useState({
    logo: '',
    footerLogo: ''
  })
  const [selectedFile, setSelectedFile] = useState({
    logo: null,
    footerLogo: null
  })

  function handleAcceptedFiles (files, dropName) {
    if (files[0].size >= 150000) {
      const tempError = { ...imageError }
      tempError[dropName] = 'Please Upload Small Size File'
      setImageError(tempError)
    } else {
      setImageError('')
      setSettings({ ...settings, [dropName]: files[0] })
      setImageChange(true)
      setSelectedFile({
        ...selectedFile,
        [dropName]: URL.createObjectURL(files[0])
      })
    }
  }

  function formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  useEffect(() => {
    getSettings()
  }, [])

  const URLValidation =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      metaTitle: settings.metaTitle,
      metaKeywords: settings.metaKeywords,
      metaDescription: settings.metaDescription,
      facebookLink: settings.facebookLink,
      twitterLink: settings.twitterLink,
      instagramLink: settings.instagramLink,
      youtubeLink: settings.youtubeLink,
      linkedinLink: settings.linkedinLink,
      threadsLink: settings.threadsLink
    },
    validationSchema: Yup.object({
      metaTitle: Yup.string().required('Please Enter Meta Title'),
      metaKeywords: Yup.string().required('Please Enter Meta Keywords'),
      metaDescription: Yup.string().required('Please Enter Meta Description'),
      facebookLink: Yup.string().required('Please Enter Facebook Link'),
      twitterLink: Yup.string().required('Please Enter Twitter Link'),
      instagramLink: Yup.string().required('Please Enter Instagram Link'),
      youtubeLink: Yup.string().required('Please Enter Youtube Link'),
      linkedinLink: Yup.string().required('Please Enter Linkedin Link'),
      threadsLink: Yup.string().required('Please Enter Threads Link'),
      facebookLink: Yup.string().matches(URLValidation, 'Enter a valid url'),
      twitterLink: Yup.string().matches(URLValidation, 'Enter a valid url'),
      instagramLink: Yup.string().matches(URLValidation, 'Enter a valid url'),
      youtubeLink: Yup.string().matches(URLValidation, 'Enter a valid url'),
      threadsLink: Yup.string().matches(URLValidation, 'Enter a valid url')
    }),
    onSubmit: (values) => {
      saveSettings(values)
    }
  })

  const getSettings = () => {
    setLoader(true)
    GetData('admin/getGlobalSettings').then((response) => {
      setLoader(false)
      if (response.status === true) {
        setHeaderLogo({ preview: response.result.logo })
        setFooterLogo({ preview: response.result.footerLogo })
        setSettings(response.result)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  function handleChange (e) {
    if (e.target.files[0].size >= 150000) {
      const tempError = { ...imageError }
      tempError[e.target.name] = 'Please Upload Small Size File'
      setImageError(tempError)
    } else {
      setImageError('')
      previewImage(e)
      setSettings({ ...settings, [e.target.name]: e.target.files[0] })
      setImageChange(true)
    }
  }

  function previewImage (e) {
    setSelectedFile({
      ...selectedFile,
      [e.target.name]: URL.createObjectURL(e.target.files[0])
    })
  }

  function removeImage (logoName, e) {
    e.preventDefault()
    const newData = { ...settings }
    newData[logoName] = null
    setSettings(newData)
  }

  const saveSettings = (data) => {
    const formData = new FormData()
    for (const k in data) {
      formData.append(k, data[k])
    }
    if (imageChange) {
      if (selectedFile.logo && selectedFile.footerLogo) {
        formData.append('websiteLogo', settings.logo)
        formData.append('footerLogo', settings.footerLogo)
      } else if (selectedFile.logo) {
        formData.append('websiteLogo', settings.logo)
      } else {
        formData.append('footerLogo', settings.footerLogo)
      }
    }
    setLoader(true)
    PutData('admin/globalSettings', formData).then((response) => {
      setLoader(false)
      if (response.status === true) {
        toastr.success(response.message)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  return (
    <React.Fragment>
      <Loader show={loader} />
      <div className="page-content">
        <MetaTags>
          <title>QLS Admin | Home Page</title>
        </MetaTags>
        <Container fluid={true} className="homepage-wrap">
          <Breadcrumbs title="UI Elements" breadcrumbItem="Global Settings" />
          <Form className="mt-3">
            <Row>
              <Col lg={12}>
                <Card className="py-2 px-4">
                  <CardTitle className="p-3 pb-0 ps-2">WEBSITE LOGO</CardTitle>
                  <CardBody className="d-flex global-settings-card-body">
                    <div className="global-settings-form-outer-dropzone">
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles, 'logo')
                        }}
                        accept="image/jpeg, image/png, image/gif, image/bmp"
                        minSize={0}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone drop-zone-container">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input
                                {...getInputProps()}
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                                name="logo"
                              />
                              <div className="upload-icon">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h5>Drop files here or click to upload.</h5>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      {settings?.logo && (
                        <div className="preview-container ">
                          <div className="file-preview-container">
                            <div className="file-box ">
                              <img
                                data-dz-thumbnail=""
                                className="avatar-sm"
                                src={selectedFile.logo || settings.logo}
                                style={{ objectFit: 'contain' }}
                              />
                            </div>
                            <div
                              className="cross-btn-upload"
                              onClick={(e) => {
                                removeImage('logo', e)
                              }}
                            >
                              X
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="image-supported-text ms-3 align-self-end">
                      <div className="error mt-2">{imageError.logo}</div>
                      <div>Format Supported: Jpeg, JPG, PNG, GIF</div>
                      <div>Preferred Dimensions: 212x40 (pixels) </div>
                      <div>Maximum Size: 150 kb </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card className="py-2 px-4">
                  <CardTitle className="p-3 pb-0 ps-2">
                    META INFORMATION
                  </CardTitle>
                  <CardBody className="d-flex">
                    <div style={{ width: '-webkit-fill-available' }}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="homepage-title">
                          <b>Homepage Title</b>
                        </Label>
                        <Input
                          placeholder="Enter Homepage Title"
                          type="text"
                          id="homepage-title"
                          name="metaTitle"
                          className={
                            validation.touched.metaTitle &&
                            validation.errors.metaTitle
                              ? 'is-invalid'
                              : ''
                          }
                          defaultValue={validation.values.metaTitle || ''}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                        {validation.touched.metaTitle &&
                        validation.errors.metaTitle
                          ? (
                          <div className="invalid-feedback">
                            {validation.errors.metaTitle}
                          </div>
                            )
                          : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="keywords">
                          <b>Keywords</b>
                        </Label>
                        <Input
                          id="keywords"
                          placeholder="Enter Keywords"
                          type="text"
                          name="metaKeywords"
                          className={
                            validation.touched.metaKeywords &&
                            validation.errors.metaKeywords
                              ? 'is-invalid'
                              : ''
                          }
                          value={validation.values.metaKeywords || ''}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                        {validation.touched.metaKeywords &&
                        validation.errors.metaKeywords
                          ? (
                          <div className="invalid-feedback">
                            {validation.errors.metaKeywords}
                          </div>
                            )
                          : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="description">
                          <b>Description</b>
                        </Label>
                        <textarea
                          id="description"
                          name="metaDescription"
                          className={
                            validation.touched.metaDescription &&
                            validation.errors.metaDescription
                              ? 'is-invalid form-control description-height'
                              : 'form-control description-height'
                          }
                          value={validation.values.metaDescription || ''}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        ></textarea>
                        {validation.touched.metaDescription &&
                        validation.errors.metaDescription
                          ? (
                          <div className="invalid-feedback">
                            {validation.errors.metaDescription}
                          </div>
                            )
                          : null}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card className="py-2 px-4">
                  <CardTitle className="p-3 pb-0 ps-2">FOOTER LOGO</CardTitle>
                  <CardBody className="d-flex global-settings-card-body">
                    <div className="global-settings-form-outer-dropzone">
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles, 'footerLogo')
                        }}
                        accept="image/jpeg, image/png, image/gif, image/bmp"
                        minSize={0}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone drop-zone-container">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input
                                {...getInputProps()}
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                                name="footerLogo"
                              />
                              <div className="upload-icon">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h5>Drop files here or click to upload.</h5>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      {settings?.footerLogo && (
                        <div className="preview-container">
                          <div className="file-preview-container">
                            <div className="file-box ">
                              <img
                                data-dz-thumbnail=""
                                className="avatar-sm"
                                src={
                                  selectedFile.footerLogo || settings.footerLogo
                                }
                                style={{ objectFit: 'contain' }}
                              />
                            </div>
                            <div
                              className="cross-btn-upload"
                              onClick={(e) => {
                                removeImage('footerLogo', e)
                              }}
                            >
                              X
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="image-supported-text ms-3 align-self-end">
                      <div className="error mt-2">{imageError.footerLogo}</div>
                      <div>Format Supported: Jpeg, JPG, PNG, GIF</div>
                      <div>Preferred Dimensions: 212x40 (pixels) </div>
                      <div>Maximum Size: 150 kb </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardTitle className="p-3 pb-0 ps-2">SOCIAL LINKS</CardTitle>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            <b>Link 1 (Facebook URL)</b>
                          </Label>
                          <Input
                            placeholder="Enter Link 1"
                            type="url"
                            id="validationCustom01"
                            name="facebookLink"
                            className={
                              validation.touched.facebookLink &&
                              validation.errors.facebookLink
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            value={validation.values.facebookLink || ''}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                          {validation.touched.facebookLink &&
                          validation.errors.facebookLink
                            ? (
                            <div className="invalid-feedback">
                              {validation.errors.facebookLink}
                            </div>
                              )
                            : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">
                            <b>Link 2 (Instagram URL)</b>
                          </Label>
                          <Input
                            placeholder="Enter Link 2"
                            type="url"
                            id="validationCustom02"
                            name="instagramLink"
                            className={
                              validation.touched.instagramLink &&
                              validation.errors.instagramLink
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            value={validation.values.instagramLink || ''}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                          {validation.touched.instagramLink &&
                          validation.errors.instagramLink
                            ? (
                            <div className="invalid-feedback">
                              {validation.errors.instagramLink}
                            </div>
                              )
                            : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom03">
                            <b>Link 3 (Twitter URL)</b>
                          </Label>
                          <Input
                            placeholder="Enter Link 3"
                            type="url"
                            id="validationCustom03"
                            name="twitterLink"
                            className={
                              validation.touched.twitterLink &&
                              validation.errors.twitterLink
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            value={validation.values.twitterLink || ''}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                          {validation.touched.twitterLink &&
                          validation.errors.twitterLink
                            ? (
                            <div className="invalid-feedback">
                              {validation.errors.twitterLink}
                            </div>
                              )
                            : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label
                            htmlFor="validationCustom04"
                            className="font-weight-bold"
                          >
                            <b>Link 4 (Youtube URL)</b>
                          </Label>
                          <Input
                            placeholder="Enter Link 4"
                            type="url"
                            id="validationCustom04"
                            name="youtubeLink"
                            className={
                              validation.touched.youtubeLink &&
                              validation.errors.youtubeLink
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            value={validation.values.youtubeLink || ''}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                          {validation.touched.youtubeLink &&
                          validation.errors.youtubeLink
                            ? (
                            <div className="invalid-feedback">
                              {validation.errors.youtubeLink}
                            </div>
                              )
                            : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom05">
                            <b>Link 5 (Linkedin URL)</b>
                          </Label>
                          <Input
                            placeholder="Enter Link 5"
                            type="url"
                            id="validationCustom05"
                            name="linkedinLink"
                            className={
                              validation.touched.linkedinLink &&
                              validation.errors.linkedinLink
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            value={validation.values.linkedinLink || ''}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                          {validation.touched.linkedinLink &&
                          validation.errors.linkedinLink
                            ? (
                            <div className="invalid-feedback">
                              {validation.errors.linkedinLink}
                            </div>
                              )
                            : null}
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom06">
                            <b>Link 6 (Threads URL)</b>
                          </Label>
                          <Input
                            placeholder="Enter Link 6"
                            type="url"
                            id="validationCustom06"
                            name="threadsLink"
                            className={
                              validation.touched.threadsLink &&
                              validation.errors.threadsLink
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                            value={validation.values.threadsLink || ''}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                          {validation.touched.threadsLink &&
                          validation.errors.threadsLink
                            ? (
                            <div className="invalid-feedback">
                              {validation.errors.threadsLink}
                            </div>
                              )
                            : null}
                        </FormGroup>
                      </Col>
                    </Row>
                      
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Button
              onClick={() => validation.handleSubmit()}
              color="primary"
              className="btn btn-add-new mb-4 ms-0"
            >
              Save
            </Button>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GlobalSettings
