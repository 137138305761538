/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from 'react'
import { Label, Input } from 'reactstrap'
import CrossIcon from '../../assets/images/cross2.svg'
import PropTypes from 'prop-types'
import { GetData, PutData } from 'apiHandler'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Loader from '../Loader'
import InfiniteScroll from 'react-infinite-scroll-component'

let searchTimer
let searchKeyword = ''

const FeaturedProduct = (props) => {
  const [featuredData, setFeaturedData] = useState({
    title: '',
    productIds: []
  })
  // const [checklist, setChecklist] = useState(null)
  const [loader, setLoader] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [productPage, setProductPage] = useState(1)
  const [allProducts, setAllProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedProductIds, setSelectedProductIds] = useState([])
  const [error, setError] = useState(false)
  const scrollRef = useRef()

  useEffect(() => {
    getProducts(productPage)
  }, [])

  const getProducts = (p) => {
    setProductPage(p)
    setLoader(true)
    let url
    if (searchKeyword !== '') {
      url = `admin/getProductsForFeatured?page=${p}&pageSize=20&search=${searchKeyword}`
    } else {
      url = `admin/getProductsForFeatured?page=${p}&pageSize=20`
    }
    GetData(url).then((response) => {
      if (response.status === true) {
        setLoader(false)
        const newProducts = response.result.data.map((item) => {
          return { value: item.part_id, label: `${item.partNumber} - ${item.PartTerminologyName}` }
        })
        if (searchKeyword !== '') {
          scrollRef.current.scrollTo(0, 0)
          setAllProducts(newProducts)
        } else setAllProducts(prevState => [...prevState, ...newProducts])
      } else {
        toastr.error(response.message, 'Error')
        setLoader(false)
      }
    })
  }

  const fetchNextProducts = () => {
    getProducts(parseInt(productPage) + 1)
  }

  const filterProduct = (e) => {
    setSearchText(e.target.value)
    if (e.target.value === '') {
      setAllProducts([])
    }
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      searchKeyword = e.target.value
      getProducts(1)
    }, 1000)
  }

  const handleProductCheckbox = (product, e) => {
    if (!e.target.checked) {
      const updatedCheck = [...selectedProducts]
      const brandData = { ...product }
      brandData.checked = true
      updatedCheck.push(brandData)
      setSelectedProducts(updatedCheck)
      setSelectedProductIds(updatedCheck.map((product) => {
        return product.value
      }))
    } else if (e.target.checked) {
      const data = selectedProducts.filter((item, i) => {
        return item.value !== product.value
      })
      setSelectedProducts(data)
      setSelectedProductIds(data.map((brand) => {
        return brand.value
      }))
    }
  }

  useEffect(() => {
    if (selectedProductIds.length >= 20) {
      setError(false)
    }
  }, [selectedProductIds])

  useEffect(() => {
    if (props.data) {
      setFeaturedData(props.data)
      setSelectedProducts(props.data.productIds.map((product) => {
        return { value: product.part_id, label: `${product.partNumber} - ${product.PartTerminologyName}` }
      }))
      setSelectedProductIds(props.data.productIds.map((product) => {
        return product.part_id
      }))
    }
  }, [props.data])

  function handleChange (e) {
    e.preventDefault()
    setFeaturedData({ ...featuredData, [e.target.name]: e.target.value })
  }

  const handleDeleteProduct = (e, product) => {
    const data = selectedProducts.filter((item, i) => {
      return item.value !== product.value
    })
    setSelectedProducts(data)
    setSelectedProductIds(data.map((brand) => {
      return brand.value
    }))
  }

  function handleSubmit (e) {
    e.preventDefault()
    if (selectedProductIds.length < 20) {
      setError(true)
    } else {
      setLoader(true)
      const body = { ...featuredData, productIds: [...selectedProductIds] }
      PutData('admin/updateHomeSection4', body)
        .then((response) => {
          if (response.status === true) {
            toastr.success(response.message, 'Sent')
            setLoader(false)
          } else {
            toastr.error(response.message, 'Error')
            setLoader(false)
          }
        })
    }
  }

  return (
    <React.Fragment>
       <Loader show={loader}></Loader>
      <div className="home-banner-right-wrap">
        <div className="supply-resource-wrap">
          <div className="banner-fields-box">
            <div className="sr-title-top">
              <Label htmlFor="formrow-firstname-Input">Section Title</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter section title"
                name="title"
                defaultValue={featuredData?.title}
                onChange={(e) => {
                  handleChange(e)
                }}
              />
            </div>
          </div>
        </div>

        {/* banner1 */}

        <div className="home-banner-outer featured-product-outer">
          <div className="featured-product-wrap">
            <h4>Products Selection ( minimum of 20 )</h4>
            {error && <div className="error">Please select minimum of 20 products</div>}
            <div className="selected-value">
                {selectedProducts.map((product, i) => {
                  return <>
                  <span key={i} className="mt-2">
                  {product.label}
                  <div className="cross-btn">
                    <img
                      src={CrossIcon}
                      alt="cross"
                      onClick={(e) => { handleDeleteProduct(e, product) }}
                    />
                  </div>
                </span>
                </>
                })}
            </div>
            <div className="featured-inner-box">
            <div className="position-relative search-featured mb-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            onChange={filterProduct}
                            value={searchText}
                          />
                          <span className="bx bx-search search-icon" />
                        </div>
              <div className="check-listing-container" id='scrollable' ref={scrollRef}>
              <div className="check-listing">
              <InfiniteScroll
                            dataLength={allProducts.length}
                            next={fetchNextProducts}
                            hasMore={true}
                            endMessage={
                              <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                              </p>
                            }
                            scrollableTarget="scrollable"
                          >
                             {allProducts.map((product, i) => {
                               return <div className="checkbox-line" key={i}>
                               <div className="d-flex align-items-center">
                                 <input
                                   className="me-2"
                                   type="checkbox"
                                   value={product.value}
                                   id={`product-${product.value}`}
                                   onClick={(e) => handleProductCheckbox(product, e)}
                                   checked={
                                    selectedProductIds.includes(product.value)
                                  }
                                  readOnly
                                 />
                                 <label
                                   className="form-check-label"
                                   htmlFor={`product-${product.value}`}
                                 >
                                   {product.label}
                                 </label>
                               </div>
                             </div>
                             })}
                          </InfiniteScroll>
                {/* {foundUsers && foundUsers.length > 0
                  ? foundUsers?.map((data, index) => {
                    return <div className="checkbox-line" key={index}>
                    <div className="d-flex align-items-center">
                      <input
                        className="me-2"
                        type="checkbox"
                        value={data.id}
                        name={data.id}
                        id={`product-${data.id}`}
                        onClick={(e) => handleCheckbox(index, e)}
                        defaultChecked={props.data && featuredData.productIds.includes(data.id)}

                      />
                      <label className="form-check-label" htmlFor={`product-${data.id}`}>
                        {data.title}
                      </label>
                    </div>
                  </div>
                  })
                  : <div className="no-result">No results found!</div>} */}
              </div>
              </div>
            </div>
          </div>
        </div>

        <div className="save-btn">
          <button type="submit" onClick={(e) => handleSubmit(e)}>
            Save
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FeaturedProduct

FeaturedProduct.propTypes = {
  data: PropTypes.object
}
