import React, { useState, useEffect } from 'react'
import { GetData } from '../../apiHandler'
import Loader from '../../components/Loader'
import { useParams } from 'react-router-dom'
import TableComponent from 'components/Table'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

function ProductList() {
  const { id } = useParams()
  const [loader, setLoader] = useState(false)
  const columns = [
    { key: 'partNumber', label: 'Part Number', sorting: true },
    { key: 'PartTerminologyName', label: 'Product', sorting: false },
    { key: 'brandName', label: 'brand', sorting: true },
    { key: 'categoryName', label: 'category', sorting: true },
    { key: 'subCategoryName', label: 'subCategory', sorting: true },
    { key: 'costPrice', label: 'WD price', sorting: true },
    { key: 'sellPrice', label: 'selling Price', sorting: true },
    { key: 'quantity', label: 'Qty', sorting: true },
    { key: 'uom', label: 'UoM', sorting: false }
  ]
  const filterTypes = [
    { label: 'All', key: 'all' },
    { label: 'Brand', key: 'brand' },
    { label: 'partnumber', key: 'partnumber' },
    { label: 'Category', key: 'category' },
    { label: 'subCategory', key: 'subcategory' }
  ]
  const [selectedFilter, setSelectedFilter] = useState({
    label: 'All',
    key: 'all'
  })
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  let filterByStatus = 'all'
  let searchTimer
  let filterByKeyObject = { key: 'all' }
  const [searchText, setSearchText] = useState('')
  const [singlebtn, setSinglebtn] = useState(false)
  const [locationdata, setLocationData] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [loctionID, setLocationID] = useState()
  const [productData, setProductData] = useState([])
  const [locId, setlocId] = useState(null)
  //   const [wdProduct, setWdproduct] = useState()
  // let locationId = ''
  useEffect(() => {
    GetData(`admin/getVendorActiveWarehouses?vendorId=${id}`).then((response) => {
      if (response.status === true) {
        setLoader(false)
        setLocationData(response.result)
        setlocId(response?.result[0]?.location_id)
      }
    })
  }, [])
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  useEffect(() => {
    locId && getWDProduct()
  }, [locId])

  useEffect(() => {
    filterByStatus = 'all'
    filterByKeyObject = { key: 'all' }
  }, [])

  const getWDProduct = (p = 1) => {
    const pageSize = 20
    let url = 'admin/getProductsForWDMang?vendorWarehouseLocationId=' +
      locId +
      '&page=' +
      p +
      '&pageSize=' +
      pageSize
    if (sortingState.sortColumn != null) {
      url +=
        '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order.toUpperCase()
    }
    if (filterByStatus !== 'all') {
      url += '&status=' + filterByStatus
    }
    if (filterByKeyObject.string) {
      url +=
        '&filterBy=' +
        filterByKeyObject.key +
        '&search=' +
        filterByKeyObject.string
    }
    setLoader(true)
    GetData(url).then((response) => {
      if (response.status === true || response.status === 200) {
        setLoader(false)
        if (response.result.products) {
          // eslint-disable-next-line no-unused-vars
          const tableData = []
          response.result.products?.map((item, i) => {
            tableData.push({
              partNumber: item.partNumber,
              PartTerminologyName: item.PartTerminologyName,
              brandName: item.brandName,
              categoryName: item.categoryName,
              subCategoryName: item.subCategoryName,
              costPrice: item.costPrice,
              sellPrice: item.sellPrice,
              quantity: item.quantity,
              uom: item.uom
            })
            setProductData(tableData)
            return null
          })
        }
        setPaging({
          currentPage: p,
          recordPerPage: pageSize,
          totalRecord: response.result.total
        })
      } else {
        setLoader(false)
        setProductData([])
        setPaging({
          currentPage: 1,
          recordPerPage: pageSize,
          totalRecord: 0
        })
      }
    })
  }
  // ------------------------handle pagination---------------------------
  const paginationHandler = (page) => {
    getWDProduct(page)
  }

  function newLocationID(e) {
    setlocId(e.target.value)
  }
  const filterByKey = (obj) => {
    filterByKeyObject.key = obj.key
    setSelectedFilter(obj)
    if (filterByKeyObject.string) {
      getWDProduct(1)
    }
  }
  const searchByText = (t) => {
    setSearchText(t)
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      filterByKeyObject.string = t
      getWDProduct(1)
    }, 2000)
  }
  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn === column) {
      if (sortingState.order === 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }
  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getWDProduct(1)
  }, [sortingState])
  const handleRemoveSearchWord = () => {
    setSearchText('')
    filterByKeyObject.string = ''
    getWDProduct(1)
  }
  return (
    <div>
      <div className="warehouse--staff warehouse--staff-new">
        <div className='loc-dropdown'>
          <div className="input-label">Select Location</div>

          <div className="dropdown__staff main-drop-staff">
            <select className="drop-staf" onChange={(e) => newLocationID(e)}>
              {/* <option selected>Select Location</option> */}
              {locationdata.length > 0 &&
                locationdata?.map((item, i) => {
                  return (
                    <>
                      <option value={item?.location_id} >{item?.locationName}, {item?.cities?.name}, {item?.states?.name}</option>
                    </>
                  )
                })
              }
            </select>
          </div>
        </div>
        <div className='filter--main--div'>
          <Dropdown
            isOpen={singlebtn}
            toggle={() => setSinglebtn(!singlebtn)}>
            <DropdownToggle className="customer-select text-dark">
              Filter By {' '}
              <span>
                {selectedFilter.label}
              </span>
              <i className=" ms-2 bx bx-caret-down" />
            </DropdownToggle>
            <DropdownMenu>
              {filterTypes.map((filterObj, key) => {
                return (
                  <DropdownItem
                    key={key}
                    onClick={() => filterByKey(filterObj)}
                  >
                    {filterObj.label}
                  </DropdownItem>
                )
              })}
            </DropdownMenu>

          </Dropdown>
          <div className="customer-search-form">
            <input
              className="form-control input-border customer-input-border"
              type="text"
              placeholder="Search"
              onChange={(e) => searchByText(e.target.value)}
              value={searchText}
            />
            {searchText && (
              <i
                className="mdi mdi-close empty-search-icon"
                onClick={() => handleRemoveSearchWord()}
              ></i>
            )}
            <i className="bx bx-search-alt-2 search-icon"></i>
          </div>
        </div>
      </div>
      <div className="staff-main-table">
        <Loader show={loader} />
        <TableComponent
          columns={columns}
          content={productData}
          paging={paging}
          sorting={handleSort}
          paginationHandler={paginationHandler}
          arrowState={sortingState}
        />
      </div>
    </div>
  )
}
export default ProductList
