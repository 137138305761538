/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Modal,
  UncontrolledTooltip
} from 'reactstrap'
import classnames from 'classnames'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import TableComponent from '../../components/Table'

import { GetData, ToesterSettings } from '../../apiHandler'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { Link, useHistory } from 'react-router-dom'

let filterByStatus = 'all'
let searchTimer
let filterByKeyObject = { key: 'all' }

export default function WDManagement () {
  toastr.options = ToesterSettings
  const history = useHistory()
  const [loader, setLoader] = useState(false)
  const [tab, setTab] = useState('1')
  const [singlebtn, setSinglebtn] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState({
    label: 'All',
    key: 'all'
  })
  const [wds, setWds] = useState([])
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  const [searchText, setSearchText] = useState('')
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const [selectedData, setSelectedData] = useState({})
  const filterTypes = [
    { label: 'All', key: 'all' },
    { label: 'Company Name', key: 'companyName' },
    { label: 'Email Address', key: 'email' },
    { label: 'City', key: 'city' },
    { label: 'State', key: 'state' }
  ]

  useEffect(() => {
    getWds()
    return () => {
      setTab('1')
      filterByKeyObject = { key: 'all' }
    }
  }, [])

  const getWds = (p = 1) => {
    const pageSize = 20
    let url = 'admin/getAllWDs?page=' + p + '&pageSize=' + pageSize
    if (filterByStatus !== 'all') {
      url += '&status=' + filterByStatus
    }
    if (filterByKeyObject.string) {
      url +=
        '&filterBy=' +
        filterByKeyObject.key +
        '&search=' +
        filterByKeyObject.string
    }
    if (sortingState.sortColumn != null) {
      url +=
        '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order
    }
    setLoader(true)
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status === true) {
        if (response.result.data.length) {
          const tableData = []
          response.result.data.map((data, index) => {
            let locations = ''
            if (data.locations.length > 1) {
              data.locations.slice(1).map((location, indx) => {
                locations +=
                  '<b>' +
                  location.locationName +
                  ', ' +
                  location.city +
                  '</b><div>' +
                  location.state +
                  '</div>'
                locations += data.locations.length - 2 != indx ? '<hr/>' : ''
                return null
              })
            }

            let statusString = <div className="pending-div">Pending</div>
            if (data.status === 1) {
              statusString = <div className="active-div">Active</div>
            }
            if (data.status === 2) {
              statusString = <div className="approved-div">Approved</div>
            }
            if (data.status === 3) {
              statusString = <div className="rejected-div">Rejected</div>
            }
            if (data.status === 4) {
              statusString = <div className="disabled-div">Disabled</div>
            }
            data.tempStatus = data.status
            data.customerAssigned = data.customerAssigned.toString()
            data.categories = data.categories.toString()
            data.status = statusString
            data.locationLength = (
              <>
                <b>
                  {data.locations[0].locationName},{data.locations[0].city}
                </b>
                <div>
                  {data.locations[0].state}&nbsp;
                  {data.locations.length > 1
                    ? (
                    <a
                      className="tooltip-main"
                      id={`UncontrolledTooltipExample${index}`}
                    >
                      +{data.locations.length - 1}
                    </a>
                      )
                    : null}
                </div>
                {data.locations.length > 1
                  ? (
                  <UncontrolledTooltip
                    className="wd-tool"
                    placement="left"
                    target={`UncontrolledTooltipExample${index}`}
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      boxShadow:
                        '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
                      borderRadius: '4px',
                      border: 'red'
                    }}
                  >
                    <p dangerouslySetInnerHTML={{ __html: locations }}></p>
                  </UncontrolledTooltip>
                    )
                  : null}
              </>
            )
            data.locations = locations
            const temp = ['view']
            if (data.tempStatus === 1) {
              temp.push('disable')
            }
            // else if (data.tempStatus === 4) {
            //   temp.push('enable')
            // }
            data.action = temp
            tableData.push(data)
            return null
          })

          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: response.result.total
          })
          setWds(tableData)
        } else {
          setWds([])
          setPaging({
            currentPage: 1,
            recordPerPage: pageSize,
            totalRecord: 0
          })
        }
      } else {
        setWds([])
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
        toastr.error(response.message, 'Error')
      }
    })
  }

  const filterData = (key, type) => {
    setTab(key)
    filterByStatus = type
    getWds(1)
  }

  const getStates = (countryid, stateid) => {
    const url = 'states?countryId=' + countryid
    GetData(url).then((response) => {
      if (response.status === true) {
        if (response.result.length) {
          const state = response.result.filter((item) => item.id == stateid)
          setSelectedData((prev) => ({ ...prev, statename: state[0].name }))
        }
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getCities = (countryid, stateid, cityid) => {
    const url = 'cities?countryId=' + countryid + '&stateId=' + stateid
    GetData(url).then((response) => {
      if (response.status === true) {
        if (response.result.length) {
          const city = response.result.filter((item) => item.id == cityid)
          setSelectedData((prev) => ({ ...prev, cityname: city[0].name }))
        }
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleActions = (key, data) => {
    if (key === 'disable' || key === 'enable') {
      setIsDisableOpen(true)
      setSelectedData(data)
      getStates(data.primaryBillingCountryId, data.primaryBillingStateId)
      getCities(
        data.primaryBillingCountryId,
        data.primaryBillingStateId,
        data.primaryBillingCityId
      )
    }
    if (key === 'view') {
      history.push('/wd-management/' + data.vendorId)
    }
  }

  const handleRow = (data) => {
    history.push('/wd-management/' + data.vendorId)
  }

  const paginationHandler = (page) => {
    getWds(page)
  }

  const filterByKey = (obj) => {
    filterByKeyObject.key = obj.key
    setSelectedFilter(obj)
    if (filterByKeyObject.string) {
      getWds(1)
    }
  }

  const searchByText = (t) => {
    setSearchText(t)
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      filterByKeyObject.string = t
      getWds(1)
    }, 500)
  }

  const handleDisable = (id) => (e) => {
    e.preventDefault()
    const url = `admin/changeWDstatus/${id}`
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status === true) {
        toastr.success(response.message, 'Success')
        getWds()
      } else {
        setWds([])
        // eslint-disable-next-line no-undef
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
        toastr.error(response.message, 'Error')
      }
    })
    setIsDisableOpen(false)
  }

  const columns = [
    { key: 'companyName', label: 'Company Name', sorting: true },
    { key: 'phone', label: 'Phone Number', sorting: false },
    { key: 'email', label: 'Email Address', sorting: true },
    { key: 'customerAssigned', label: 'Customer Assigned', sorting: false },
    { key: 'categories', label: 'Product Categories', sorting: false },
    // { key: 'avgPartCostPrice', label: 'Cost', sorting: false },
    { key: 'locationLength', label: 'Warehouse', html: true, sorting: false },
    { key: 'status', label: 'Status', html: true, sorting: false },
    { key: 'action', label: 'View/Disable', sorting: false }
  ]

  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getWds(1)
  }, [sortingState])

  const [modal_center, setmodal_center] = useState(false)

  function removeBodyCss () {
    document.body.classList.add('no_padding')
  }

  // eslint-disable-next-line no-unused-vars
  function tog_center () {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn == column) {
      if (sortingState.order == 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }

  const handleRemoveSearchWord = () => {
    setSearchText('')
    filterByKeyObject.string = ''
    getWds(1)
  }

  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <MetaTags>
          <title>QLS Admin | WD Management</title>
        </MetaTags>
        <Container fluid={true} className="customer-container">
          <div className="breadcrumb-main-nav">
            <Breadcrumbs title="UI Elements" breadcrumbItem="WD Management" />
            <div className="imp-exp-div">
              {/* <button className="bg-white export-btn me-2">Import</button> */}
              <button className="bg-white export-btn me-2">Export</button>
              <Link to="/wd-management/add-new" className="">
                <button className="add-new-customer-btn">+ Add New WD</button>
              </Link>
            </div>
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="warehouse-navbar">
                    <Nav pills className="wd-bottom">
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '1'
                          })}
                          onClick={() => filterData('1', 'all')}
                        >
                          All
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '2'
                          })}
                          onClick={() => filterData('2', 'pending')}
                          id="pending"
                        >
                          New/Pending
                          <UncontrolledTooltip
                          placement="top"
                          target={'pending'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            <div>New: WD application received.</div>
                            <div>Pending: QLS Admin to approve account.</div>
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '4'
                          })}
                          onClick={() => filterData('4', 'approved')}
                          id="approved"
                        >
                          Approved
                          <UncontrolledTooltip
                          placement="top"
                          target={'approved'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            WD account has been approved. Email confirmation sent to customer to create password.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '3'
                          })}
                          onClick={() => filterData('3', 'active')}
                          id="active"
                        >
                          Active
                          <UncontrolledTooltip
                          placement="top"
                          target={'active'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            WD has created their password and logged into their account.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '5'
                          })}
                          onClick={() => filterData('5', 'rejected')}
                          id="rejected"
                        >
                          Rejected
                          <UncontrolledTooltip
                          placement="top"
                          target={'rejected'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                              WD account not approved.
                            </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '6'
                          })}
                          onClick={() => filterData('6', 'disabled')}
                          id="disabled"
                        >
                          Disabled
                          <UncontrolledTooltip
                          placement="top"
                          target={'disabled'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            WD account has been permanently disabled. Account cannot be reinstated.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div className="d-flex drop-search">
                      <Dropdown
                        isOpen={singlebtn}
                        toggle={() => setSinglebtn(!singlebtn)}
                      >
                        <DropdownToggle className="customer-select text-dark">
                          Filter By{' '}
                          <span className="font-weight-bold">
                            {selectedFilter.label}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {filterTypes.map((filterObj, key) => {
                            return (
                              <DropdownItem
                                key={key}
                                onClick={() => filterByKey(filterObj)}
                              >
                                {filterObj.label}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      <div className="customer-search-form">
                        <input
                          className="form-control input-border customer-input-border"
                          type="text"
                          placeholder="Search"
                          onChange={(e) => searchByText(e.target.value)}
                          value={searchText}
                        />
                        {searchText && (
                          <i
                            className="mdi mdi-close empty-search-icon"
                            onClick={() => handleRemoveSearchWord()}
                          ></i>
                        )}
                        <i className="bx bx-search-alt-2 search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <hr className=" mt-0 mb-4 tab-hr"></hr>
                  <TableComponent
                    columns={columns}
                    content={wds}
                    handleActions={handleActions}
                    paging={paging}
                    paginationHandler={paginationHandler}
                    onClick={handleRow}
                    sorting={handleSort}
                    arrowState={sortingState}
                  />
                </CardBody>
                <Modal
                  className="modal-main-body"
                  isOpen={isDisableOpen}
                  toggle={() => setIsDisableOpen(false)}
                  centered
                >
                  <div className="modal-header">
                    <button
                      type="button"
                      onClick={() => {
                        setIsDisableOpen(false)
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p className="disable-warehouse">
                      Are you sure you want to{' '}
                      {selectedData.tempStatus === 4 ? ' enable' : ' disable'}{' '}this{' '}
                      WD?
                    </p>
                    <div className="modal-detail-div">
                      <p className="modal-details">Details</p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading">
                          {' '}
                          Company Name:{' '}
                        </span>{' '}
                        {selectedData.companyName}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading">
                          {' '}
                          Contact Person:{' '}
                        </span>{' '}
                        {selectedData.phone}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Email: </span>{' '}
                        {selectedData.email}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Location: </span>{' '}
                        {selectedData.primaryBillingStreet},{' '}
                        {selectedData.cityname}, {selectedData.statename},{' '}
                        {selectedData.primaryBillingZipCode}
                      </p>
                    </div>
                    <div className="modal-btn-div">
                      <button
                        className="warehouse-btn-yes"
                        onClick={handleDisable(selectedData.vendorId)}
                      >
                        Yes
                      </button>
                      <button
                        className="warehouse-btn-yes warehouse-btn-no"
                        onClick={() => setIsDisableOpen(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </Modal>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
