import React from 'react'

function FooterStatic () {
  return (
        <>
        <div className='main-footer'>
            <div className='privacy-security'>
                <div className='privacy-div'>
                <a href='/#' className='privacy-span'>  Privacy & Security </a>
                <a href='/#' className='privacy-span'>Terms of Use</a>
                </div>
            </div>
            <div className='copyright'>
            Copyright © 2021 QL SUPPLY. All rights reserved.
            </div>

        </div>
        </>
  )
}

export default FooterStatic
