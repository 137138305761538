/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import { Form, Label, Input } from 'reactstrap'
import Dropzone from 'react-dropzone'
import { PutData, PostData } from '../../apiHandler'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import PropTypes from 'prop-types'
import Loader from '../../components/Loader'

export const INPUTTYPE = {
  TEXT: 'text',
  IMAGE: 'image'
}

const HomeBanner = (props) => {
  const mainBanner = [
    {
      bannerImage: '',
      bannerText1: '',
      bannerText2: '',
      bannerUrl: ''
    }
  ]
  const [bannerData, setBannerData] = useState(mainBanner)
  const [image1, setImage1] = useState([])
  const [loader, setLoader] = useState(false)
  const [imageError, setImageError] = useState([])

  useEffect(() => {
    props.data && setBannerData(props.data)
  }, [props.data])

  function handleAcceptedFiles (files, i) {
    setLoader(true)
    const bannerDataTemp = bannerData[i]
    if (files[0].type === 'video/mp4') {
      if (files[0].size >= (5 * 1024 * 1024)) {
        const tempError = [...imageError]
        tempError[i] = 'Please Upload Small Size File '
        setImageError(tempError)
        setLoader(false)
      } else {
        const tempError = [...imageError]
        tempError[i] = null
        setImageError(tempError)
        const formVideo = new FormData()
        formVideo.append('video', files[0])
        PostData('admin/uploadSectionMedia', formVideo).then((response) => {
          if (response.status == true) {
            setLoader(false)
            bannerDataTemp.bannerImage = response.result.url
            setBannerData([...bannerData], bannerDataTemp)
          }
        })
      }
    } else {
      if (files[0].size >= (0.5 * 1024 * 1024)) {
        const tempError = [...imageError]
        tempError[i] = 'Please Upload Small Size File'
        setImageError(tempError)
        setLoader(false)
      } else {
        const tempError = [...imageError]
        tempError[i] = null
        setImageError(tempError)
        const formImage = new FormData()
        formImage.append('image', files[0])
        PostData('admin/uploadSectionImage', formImage).then((response) => {
          if (response.status == true) {
            setLoader(false)
            bannerDataTemp.bannerImage = response.result.url
            setBannerData([...bannerData], bannerDataTemp)
          }
        })
      }
    }

    // files.map((file) =>
    //   Object.assign(file, {
    //     preview: URL.createObjectURL(file),
    //     formattedSize: formatBytes(file.size),
    //   })
    // );
  }

  function imagePreview (i, e) {
    const tempImage = [...image1]
    tempImage[i] = URL.createObjectURL(e.target.files[0])
    setImage1(tempImage)
  }
  const maxSize = 5242880
  /**
   * Formats the size
   */
  // function formatBytes (bytes, decimals = 2) {
  //   if (bytes === 0) return '0 Bytes'
  //   const k = 1024
  //   const dm = decimals < 0 ? 0 : decimals
  //   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  //   const i = Math.floor(Math.log(bytes) / Math.log(k))
  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  // }

  // function resetUpload (i, e) {
  //   e.preventDefault()
  //   const tempImage = { ...image1 }
  //   tempImage[`previewImage${i}`] = null
  //   setImage1(tempImage)
  // }

  function resetUploadBanner (i, e) {
    e.preventDefault()
    const tempData = bannerData[i]
    tempData.bannerImage = null
    setBannerData([...bannerData], tempData)
  }

  const addNewBanner = (e) => {
    e.preventDefault()
    setBannerData([...bannerData, {}])
  }

  const removeBanner = (i, e) => {
    e.preventDefault()
    document.getElementById(`form${i}`).reset()
    const updatedData = [...bannerData]
    const tempImage = [...image1]
    updatedData.splice(i, 1)
    tempImage.splice(i, 1)
    setBannerData(updatedData)
    setImage1(tempImage)
  }

  const handleChange = (key, input, e) => {
    e.preventDefault()
    setLoader(true)
    const bannerDataTemp = bannerData[key]
    if (input == INPUTTYPE.IMAGE) {
      if (e.target.files[0].type === 'video/mp4') {
        if (e.target.files[0].size > (5 * 1024 * 1024)) {
          const tempError = [...imageError]
          tempError[key] = 'Please Upload Small Size File '
          setImageError(tempError)
          setLoader(false)
        } else {
          const tempError = [...imageError]
          tempError[key] = null
          setImageError(tempError)
          const formVideo = new FormData()
          formVideo.append('video', e.target.files[0])
          PostData('admin/uploadSectionMedia', formVideo).then((response) => {
            if (response.status == true) {
              setLoader(false)
              bannerDataTemp[e.target.name] = response.result.url
              setBannerData([...bannerData], bannerDataTemp)
            }
          })
        }
      } else {
        if (e.target.files[0].size >= (0.5 * 1024 * 1024)) {
          const tempError = [...imageError]
          tempError[key] = 'Please Upload Small Size File '
          setImageError(tempError)
          setLoader(false)
        } else {
          const tempError = [...imageError]
          tempError[key] = null
          setImageError(tempError)
          const formImage = new FormData()
          formImage.append('image', e.target.files[0])
          PostData('admin/uploadSectionImage', formImage).then((response) => {
            if (response.status == true) {
              setLoader(false)
              bannerDataTemp[e.target.name] = response.result.url
              setBannerData([...bannerData], bannerDataTemp)
            }
          })
        }
      }
    } else {
      setLoader(false)
      bannerDataTemp[e.target.name] = e.target.value
      setBannerData([...bannerData], bannerDataTemp)
    }
  }

  function handleSubmit (e) {
    setLoader(true)
    e.preventDefault()
    PutData('admin/updateHomeSection1', { banners: bannerData })
      .then((response) => {
        if (response.status === true) {
          setLoader(false)
          toastr.success(response.message, 'Sent')
        } else {
          setLoader(false)
          toastr.error(response.message, 'Error')
        }
      })
      .catch((response) => {
        setLoader(false)
        toastr.error(response.message, 'Error')
      })
  }

  return (
    <React.Fragment>
      <Loader show={loader}></Loader>
      <div className="home-banner-right-wrap">
        {bannerData?.map((item, i) => {
          return (
            <Form key={i} id={`form${i}`}>
              <div key={i}>
                <div className="home-banner-outer">
                  <h3>Banner {i + 1}</h3>
                  <div className="home-banner-inner">
                    <div className="home-banner-left-box">
                      <h4>Banner {i + 1} Image</h4>
                      <div className="form-outer-dropzone">
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles(acceptedFiles, i)
                          }}
                          accept="image/jpeg, image/png, image/gif, image/webp, video/mp4"
                          minSize={0}
                          maxSize={maxSize}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input
                                  {...getInputProps()}
                                  onChange={(e) => {
                                    handleChange(i, INPUTTYPE.IMAGE, e)
                                    imagePreview(i, e)
                                  }}
                                  name="bannerImage"
                                />
                                <div className="upload-icon">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h5>Drop files here or click to upload.</h5>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        {item.bannerImage &&
                          (item?.bannerImage?.split('.')?.pop() === 'mp4'
                            ? <div className="preview-container">
                          <div
                            className="file-preview-container"
                            key={i + '-file'}
                          >
                            <div className="file-box">
                            <video loop playsInline webkit-playsInline muted controls height="100%" width='100%'>
                            <source src={item?.bannerImage} type="video/mp4" />
                          </video>
                            </div>
                            <div
                              className="cross-btn-upload"
                              onClick={(e) => {
                                resetUploadBanner(i, e)
                              }}
                            >
                              X
                            </div>
                          </div>
                        </div>
                            : (
                          <div className="preview-container">
                            <div
                              className="file-preview-container"
                              key={i + '-file'}
                            >
                              <div className="file-box">
                                <img
                                  data-dz-thumbnail=""
                                  className="avatar-sm"
                                  src={item.bannerImage}
                                />
                              </div>
                              <div
                                className="cross-btn-upload"
                                onClick={(e) => {
                                  resetUploadBanner(i, e)
                                }}
                              >
                                X
                              </div>
                            </div>
                          </div>
                              ))}
                      </div>
                      <div className="error mt-2">{imageError[i]}</div>
                      <div className="upload-img-lines">
                        <ul>
                          <li>Format Supported: Jpeg, JPG, PNG, GIF, MP4</li>
                          <li>Preferred Dimensions: 1440x350 (pixels) </li>
                          <li>Maximum Size: Image 500kb, Video 5MB  </li>
                        </ul>
                      </div>
                    </div>
                    <div className="home-banner-right-box">
                      <div className="banner-fields-box">
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Banner {i + 1} Text 1
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder={`Enter banner ${i + 1} Text 1`}
                            name={'bannerText1'}
                            defaultValue={item.bannerText1}
                            onChange={(e) => {
                              handleChange(i, INPUTTYPE.TEXT, e)
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Banner {i + 1} Text 2
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder={`Enter banner ${i + 1} Text 2`}
                            name={'bannerText2'}
                            onChange={(e) => {
                              handleChange(i, INPUTTYPE.TEXT, e)
                            }}
                            defaultValue={item.bannerText2}
                          />
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Banner {i + 1} Text 3
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder={`Enter banner ${i + 1} Text 3`}
                            name={'bannerText3'}
                            onChange={(e) => {
                              handleChange(i, INPUTTYPE.TEXT, e)
                            }}
                            defaultValue={item.bannerText3}
                          />
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Banner {i + 1} Link (URL)
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter banner link"
                            name={'bannerUrl'}
                            defaultValue={item.bannerUrl}
                            onChange={(e) => {
                              handleChange(i, INPUTTYPE.TEXT, e)
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Banner {i + 1} Button Text
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter banner button text"
                            name={'bannerButton'}
                            defaultValue={item.bannerButton}
                            onChange={(e) => {
                              handleChange(i, INPUTTYPE.TEXT, e)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {bannerData.length - 1 == i
                  ? (
                  <div className="remove-add-banner">
                    <span className="max-upload">
                      You can upload max 5 banner
                    </span>

                    <div className="add-rem-btns">
                      {bannerData.length < 5
                        ? (
                        <span
                          className="add-btn"
                          onClick={(e) => {
                            addNewBanner(e)
                          }}
                        >
                          Add New
                        </span>
                          )
                        : (
                        <></>
                          )}
                      {bannerData.length != 1
                        ? (
                        <span
                          className="remove-btn"
                          onClick={(e) => {
                            removeBanner(i, e)
                          }}
                        >
                          Remove
                        </span>
                          )
                        : (
                        <></>
                          )}
                    </div>
                  </div>
                    )
                  : (
                  <div className="remove-add-banner">
                    <div className="add-rem-btns">
                      <span
                        className="remove-btn"
                        onClick={(e) => {
                          removeBanner(i, e)
                        }}
                      >
                        Remove
                      </span>
                    </div>
                  </div>
                    )}
              </div>
            </Form>
          )
        })}
        <div className="save-btn">
          <button
            type="submit"
            onClick={(e) => {
              handleSubmit(e)
            }}
          >
            Save
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default HomeBanner

HomeBanner.propTypes = {
  data: PropTypes.array
}
