import React from 'react'

import { Row, Col, Card, CardBody } from 'reactstrap'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// import avatar1 from '../../assets/images/users/avatar-1.jpg'
import profileImg from '../../assets/images/profile-img.png'

const WelcomeComp = (props) => {
  const [username, setusername] = React.useState({
    firstName: 'Admin',
    lastName: ''
  })

  React.useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'))
      setusername({
        firstName: obj.firstName,
        lastName: obj.lastName
      })
    }
  }, [props.success])
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>Skote Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <div className="img-thumbnail rounded-circle h2 d-flex align-items-center justify-content-center border-secondary" style={{ height: '70px', width: '70px' }}>
                {username?.firstName?.charAt(0) || 'A'} {username?.lastName?.charAt(0)?.toUpperCase() || 'D'}
                </div>
                {/* <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                /> */}
              </div>
              <h5 className="font-size-15 text-truncate">{username.firstName} {username.lastName}</h5>
              <p className="text-muted mb-0 text-truncate">UI/UX Designer</p>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">125</h5>
                    <p className="text-muted mb-0">Projects</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">$1245</h5>
                    <p className="text-muted mb-0">Revenue</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link
                    to=""
                    className="btn btn-primary  btn-sm"
                  >
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

WelcomeComp.propTypes = {
  success: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})((WelcomeComp))
)
