import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

// i18n
import SidebarContent from './SidebarContent'

import logo from '../../assets/images/logo.svg'
import logoLightPng from '../../assets/images/logo.svg'
import QLogo from '../../assets/images/q-logo.svg'
import logoDark from '../../assets/images/logo-dark.png'
import WdLogo from '../../assets/images/WD-Logo.svg'

const Sidebar = (props) => {
  const userObj = JSON.parse(localStorage.authUser)
  // const [currentRole, setCurrentRole] = useState(1)

  // useEffect(() => {
  //   if (localStorage.authUser) {
  //     const userObj = JSON.parse(localStorage.authUser)
  //     setCurrentRole(userObj.roleId)
  //   }
  // }, [])

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box" >
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="17" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light" >
          <span className="logo-sm" >
                <img src={QLogo} alt="" height="25" />
              </span>

            {(userObj.roleId == 1 || userObj.roleId == 2)
              ? (
               <span className="logo-lg">
               <img src={logoLightPng} alt="" height="44" />
             </span>
                )
              : (
              <span className="logo-lg">
                <img src={WdLogo} alt="" height="44" />
              </span>
                )}

          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout
  }
}
export default connect(mapStatetoProps, {})(withRouter(Sidebar))
