/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import back from '../../assets/images/back2.svg'
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormFeedback,
  Form
} from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { GetData, PostData } from 'apiHandler'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toastr from 'toastr'
import NumberFormat from 'react-number-format'
import Select from 'react-select'

function AddStaff () {
  const userObj = JSON.parse(localStorage.authUser)
  const history = useHistory()
  const [radio, setRadio] = useState(true)
  const [currentRole, setCurrentRole] = useState(1)
  const [permissionsDataLeft, setPermissionsDataLeft] = useState([])
  const [permissionsDataRight, setPermissionsDataRight] = useState([])
  const [selectedPermission, setselectedPermission] = useState([])
  const [privilegesErrors, setprivilegesErrors] = useState('')
  const [warehouses, setWarehouses] = useState([])

  useEffect(() => {
    if (localStorage.authUser) {
      const userObj1 = JSON.parse(localStorage.authUser)
      setCurrentRole(userObj1.roleId)
    }
  }, [])

  const handleCheckPermission = (status, id) => {
    const temp = [...selectedPermission]
    if (id != 'all') {
      if (!status) {
        for (let i = 0; i < temp.length; i++) {
          if (temp[i] == id) {
            temp.splice(i, 1)
          }
        }
      } else {
        temp.push(id)
      }
      setselectedPermission(temp)
      if (temp.length === 0) setprivilegesErrors('Please select privileges')
      else setprivilegesErrors('')
      validation.setFieldValue('permissions', temp)
    } else {
      const tempp = []
      if (status) {
        [...permissionsDataLeft, ...permissionsDataRight].forEach((item) => {
          tempp.push(item.id)
        })
      }
      setselectedPermission(tempp)
      if (tempp.length === 0) setprivilegesErrors('Please select privileges')
      else setprivilegesErrors('')
      validation.setFieldValue('permissions', tempp)
    }
  }

  useEffect(() => {
    let roleId = 2
    if (userObj.roleId == 3) {
      roleId = 4
    }
    getPermissions(roleId)
  }, [])

  const getPermissions = (roleId) => {
    GetData('admin/modules/get?areaCode=' + roleId).then((response) => {
      if (response.status === true) {
        setPermissionsDataLeft(
          response.result.slice(0, response.result.length / 2)
        )
        setPermissionsDataRight(
          response.result.slice(response.result.length / 2)
        )
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      // phoneCode: '',
      phone: '',
      // phoneLast: '',
      designation: '',
      permissions: [],
      roleId: 4,
      type: 4,
      vehicleNumber: '',
      driverCode: '',
      vendorWarehouseLocationId: ''
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('Please enter Name'),
      lastName: Yup.string().required('Please enter Name'),
      email: Yup.string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
      designation: Yup.string().required('Please enter designation'),
      roleId: Yup.string().required('Please select Role ID'),
      type: Yup.string().required('Please select type'),
      mobile: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required('Please enter Phone Number')
        .test(
          'maxDigits',
          'Must be 10 digits',
          (number) => String(number).length === 10
        ),
      phone: Yup.string()
        .notRequired()
        .nullable(true)
        .min(10, 'Must be equal to 10 numbers')
        .max(10, 'Must be equal to 10 numbers')
        .typeError("That doesn't look like a phone number"),
      vendorWarehouseLocationId: userObj.roleId == 3 && Yup.number().required('Please select warehouse')
    }),
    onSubmit: (values) => {
      setprivilegesErrors('')
      if (values.roleId == 4) {
        if (values.permissions.length === 0) {
          validation.setFieldError('permissions', 'Please select privileges')
          setprivilegesErrors('')
          return
        }
      }
      let url = 'admin/createStaff'
      if (userObj.roleId == 3) {
        url = 'vendor/createStaff'
      }
      const body = { ...values }
      // body.phone = body.phoneCode + body.phone + body.phoneLast
      // delete body.phoneCode
      // delete body.phoneLast
      if (userObj.roleId == 1) {
        delete body.roleId
        delete body.type
        delete body.vehicleNumber
        delete body.driverCode
      }
      PostData(url, body).then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Success')
          history.push('/staff-management')
        } else {
          toastr.error(response.message, 'Error')
        }
      })
    }
  })

  const handleCancel = () => {
    history.push('/staff-management')
  }

  useEffect(() => {
    if (userObj.roleId == 3) {
      fetchWarehouse()
    }
  }, [])

  const fetchWarehouse = () => {
    const url = 'vendor/getWarehouseLocationsWithoutPagination'

    GetData(url).then((response) => {
      if (response.status) {
        if (response.result.data.length > 0) {
          const warehouses = response.result.data.map((warehouse) => {
            return { value: warehouse.id, label: warehouse.locationName }
          })
          setWarehouses(prevState => ([...prevState, ...warehouses]))
        }
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true} className="customer-container">
          <div className="staff-management-heading Add-staff">
            <Link to="/staff-management">
              {' '}
              <img src={back} style={{ marginRight: '8px' }} />
            </Link>{' '}
            Add New Staff
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault()
                    if (validation.values.roleId == 4) {
                      if (validation.values.permissions.length === 0) {
                        setprivilegesErrors('Please select privileges')
                      }
                    }
                    validation.handleSubmit()
                  }}
                >
                  <CardBody className="card-main-body">
                    <div className="staff-edit-main">
                      <div className="edit-first-name">
                        <div className="staff-manafement-light">
                          First Name*
                        </div>
                        <input
                          type="text"
                          className="staff-first-input"
                          name="firstName"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstName || ''}
                          invalid={
                            !!(validation.touched.firstName &&
                            validation.errors.firstName)
                          }
                        />
                        {validation.touched.firstName &&
                        validation.errors.firstName
                          ? (
                            <div className='error' type="invalid">
                            {validation.errors.firstName}
                          </div>
                            )
                          : null}
                      </div>
                      <div className="edit-first-name">
                        <div className="staff-manafement-light">Last Name*</div>
                        <input
                          type="text"
                          className="staff-first-input"
                          name="lastName"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastName || ''}
                          invalid={
                            !!(validation.touched.lastName &&
                            validation.errors.lastName)
                          }
                        />
                        {validation.touched.lastName &&
                        validation.errors.lastName
                          ? (
                            <div className='error' type="invalid">
                            {validation.errors.lastName}
                          </div>
                            )
                          : null}
                      </div>
                    </div>
                    <div className="staff-edit-main">
                      <div className="edit-first-name">
                        <div className="staff-manafement-light">
                          Email Address*
                        </div>
                        <input
                          type="email"
                          className="staff-first-input"
                          name="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            !!(validation.touched.email && validation.errors.email)
                          }
                        />
                        {validation.touched.email && validation.errors.email
                          ? (
                            <div className='error' type="invalid">
                            {validation.errors.email}
                          </div>
                            )
                          : null}
                      </div>
                      <div className="edit-first-name">
                        <div className="staff-manafement-light">
                          Mobile Number*
                        </div>
                        <NumberFormat displayType="input" format="(###) ###-####"
                        className="staff-first-input"
                        name="mobile"
                        value={validation.values.mobile}
                        onValueChange={(values) => {
                          // setMobile(values.value)
                          validation.setFieldValue('mobile', values.value)
                        }}
                        />
                        {validation.touched.mobile &&
                        validation.errors.mobile
                          ? (
                          <div className='error' type="invalid">
                            {validation.errors.mobile}
                          </div>
                            )
                          : null}
                      </div>
                    </div>
                    <div className="staff-edit-main">
                    <div className="edit-first-name">
                        <div className="staff-manafement-light">
                          Phone Number
                        </div>
                        <NumberFormat displayType="input" format="(###) ###-####"
                        className="staff-first-input"
                        name="phone"
                        value={validation.values.phone}
                        onValueChange={(values) => {
                          validation.setFieldValue('phone', values.value)
                        }}
                        />
                        {validation.touched.phone &&
                        validation.errors.phone
                          ? (
                          <div className='error' type="invalid">
                            {validation.errors.phone}
                          </div>
                            )
                          : null}
                      </div>
                      {/* <div className="edit-first-name">
                        <div className="staff-manafement-light">
                          {' '}
                          Phone Number
                        </div>
                        <input
                          type="text"
                          className="staff-phn-no"
                          name="phoneCode"
                          onChange={(e) => {
                            if (e.target.value.length < 4) {
                              validation.setFieldValue(
                                'phoneCode',
                                e.target.value
                              )
                            }
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.phoneCode || ''}
                        />
                        <i className="mdi mdi-minus staff-minus"></i>{' '}
                        <input
                          type="text"
                          className="staff-phn-no staff-phn-landline"
                          name="phone"
                          onChange={(e) => {
                            if (e.target.value.length < 4) {
                              validation.setFieldValue('phone', e.target.value)
                            }
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone || ''}
                        />
                        <i className="mdi mdi-minus staff-minus"></i>{' '}
                         <input
                          type="text"
                          className="staff-phn-no staff-phn-landline"
                          style={{ width: '190px' }}
                          name="phoneLast"
                          onChange={(e) => {
                            if (e.target.value.length < 5) {
                              validation.setFieldValue('phoneLast', e.target.value)
                            }
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.phoneLast || ''}
                        />
                        </div> */}
                        {/* {
                        validation.touched.phoneCode &&
                        validation.errors.phoneCode ? (
                          <FormFeedback type="invalid" style={{display:"block"}}>
                            {validation.errors.phoneCode}
                          </FormFeedback>
                        ): validation.touched.phone &&
                        validation.errors.phone && (
                          <FormFeedback type="invalid" style={{display:"block"}}>
                            {validation.errors.phone}
                          </FormFeedback>
                        )
                      } */}
                      <div className="edit-first-name">
                        <div className="staff-manafement-light">
                          Title*
                        </div>
                        <input
                          type="text"
                          className="staff-first-input"
                          name="designation"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.designation || ''}
                          invalid={
                            !!(validation.touched.designation &&
                            validation.errors.designation)
                          }
                        />
                        {validation.touched.designation &&
                        validation.errors.designation
                          ? (
                            <div className='error' type="invalid">
                            {validation.errors.designation}
                          </div>
                            )
                          : null}
                      </div>
                    </div>
                   {(userObj.roleId === 3 || userObj.roleId === 4) &&
                    <div className="edit-first-name mb-3">
                        <div className="staff-manafement-light">
                          Select Warehouse*
                        </div>
                        <Select options={warehouses} onChange={(e) => {
                          validation.setFieldValue(
                            'vendorWarehouseLocationId',
                            e.value
                          )
                        }} placeholder="Select Warehouse" />
                         {validation.touched.vendorWarehouseLocationId &&
                        validation.errors.vendorWarehouseLocationId
                           ? (
                          <div className='error' type="invalid">
                            {validation.errors.vendorWarehouseLocationId}
                          </div>
                             )
                           : null}
                      </div>}
                    {currentRole === 1
                      ? null
                      : (
                      <div>
                        <div className="staff-manafement-light mb-2">
                          {' '}
                          Type*
                        </div>
                        <div className="mb-4">
                          <input
                            type="radio"
                            name="html"
                            onClick={(e) => {
                              setRadio(true)
                              validation.setFieldValue('roleId', 4)
                              validation.setFieldValue('type', 4)
                              validation.setFieldValue('permissions', [])
                              setselectedPermission([])
                            }}
                            checked={validation.values.roleId == 4}
                          />{' '}
                          <span>Staff</span>
                          <input
                            type="radio"
                            name="html"
                            style={{ marginLeft: '22px' }}
                            onClick={(e) => {
                              setRadio(false)
                              validation.setFieldValue('roleId', 5)
                              validation.setFieldValue('type', 5)
                              validation.setFieldValue('permissions', [])
                              setselectedPermission([])
                            }}
                            checked={validation.values.roleId == 5}
                          />{' '}
                          <span>Driver</span>
                        </div>
                        {validation.touched.type && validation.errors.type
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.type}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                        )}
                    <div>
                      {radio
                        ? (
                        <div>
                          <div className="staff-manafement-dark mb-3">
                            Privileges*
                          </div>
                          <div className="staff-edit-main">
                            <div className="edit-first-name">
                              <div className="mb-1">
                                <input
                                  type="checkbox"
                                  id={'defaultCheckall'}
                                  value={'all'}
                                  onClick={(e) =>
                                    handleCheckPermission(
                                      !e.target.checked,
                                      'all'
                                    )
                                  }
                                  checked={
                                    selectedPermission.length ===
                                    permissionsDataLeft.length +
                                      permissionsDataRight.length
                                  }
                                  style={{ cursor: 'pointer' }}
                                  className="staff-check-box"
                                />
                                <label
                                  className="staff-manafement-light"
                                  htmlFor={'defaultCheckall'}
                                  style={{ cursor: 'pointer' }}
                                >
                                  All
                                </label>
                              </div>
                              {permissionsDataLeft.map((data) => {
                                const checked = selectedPermission.includes(
                                  data.id
                                )
                                return (
                                  <div key={data.id} className="mb-1">
                                    <input
                                      type="checkbox"
                                      id={'defaultCheck' + data.name}
                                      value={data.id}
                                      onClick={(e) =>
                                        handleCheckPermission(
                                          !e.target.checked,
                                          data.id
                                        )
                                      }
                                      style={{ cursor: 'pointer' }}
                                      checked={checked}
                                      className="staff-check-box"
                                    />
                                    <label
                                      htmlFor={'defaultCheck' + data.name}
                                      style={{ cursor: 'pointer' }}
                                      className="staff-manafement-light"
                                    >
                                      {data.name}
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                            <div className="edit-first-name">
                              {permissionsDataRight.map((data) => {
                                const checked = selectedPermission.includes(
                                  data.id
                                )
                                return (
                                  <div key={data.id} className="mb-1">
                                    <input
                                      type="checkbox"
                                      id={'defaultCheck' + data.name}
                                      value={data.id}
                                      onClick={(e) =>
                                        handleCheckPermission(
                                          !e.target.checked,
                                          data.id
                                        )
                                      }
                                      style={{ cursor: 'pointer' }}
                                      checked={checked}
                                      className="staff-check-box"
                                    />
                                    <label
                                      htmlFor={'defaultCheck' + data.name}
                                      style={{ cursor: 'pointer' }}
                                      className="staff-manafement-light"
                                    >
                                      {data.name}
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                          {validation.errors.permissions
                            ? (
                            <div className='error'
                              type="invalid"
                              style={{ display: 'block', marginTop: '-15px', marginBottom: '30px' }}
                            >
                              {validation.errors.permissions}
                            </div>
                              )
                            : null}
                          {privilegesErrors
                            ? (
                            <div className='error'
                              type="invalid"
                              style={{ display: 'block', marginTop: '-15px', marginBottom: '30px' }}
                            >
                              {privilegesErrors}
                            </div>
                              )
                            : null}
                        </div>
                          )
                        : (
                        <div className="staff-edit-main">
                          <div className="edit-first-name">
                            <div className="staff-manafement-light">
                              {' '}
                              Driver Code (optional)
                            </div>
                            <input
                              type="text"
                              className="staff-first-input"
                              name="driverCode"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.driverCode || ''}
                              invalid={
                                !!(validation.touched.driverCode &&
                                validation.errors.driverCode)
                              }
                            />
                            {validation.touched.driverCode &&
                            validation.errors.driverCode
                              ? (
                                <div className='error' type="invalid">
                                {validation.errors.driverCode}
                              </div>
                                )
                              : null}
                          </div>
                          <div className="edit-first-name">
                            <div className="staff-manafement-light">
                              Vehical Number (optional)
                            </div>
                            <input
                              type="text"
                              className="staff-first-input"
                              name="vehicleNumber"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.vehicleNumber || ''}
                              invalid={
                                !!(validation.touched.vehicleNumber &&
                                validation.errors.vehicleNumber)
                              }
                            />
                            {validation.touched.vehicleNumber &&
                            validation.errors.vehicleNumber
                              ? (
                                <div className='error' type="invalid">
                                {validation.errors.vehicleNumber}
                              </div>
                                )
                              : null}
                          </div>
                        </div>
                          )}
                    </div>
                    <button type="submit" className="staff-save-changes staff-btn-clr">
                      Save Changes
                    </button>
                    <button onClick={handleCancel} className="staff-cancel-btn staff-btn-clr">
                      Cancel
                    </button>
                  </CardBody>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default AddStaff
