/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { GetData, PostData } from "apiHandler";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import "./notificationsDropdown.scss";
import { io } from "socket.io-client";
import moment from "moment";
import noNotificationImg from "../../../assets/images/no-notification.jpg";

// i18n

const NotificationDropdown = (props) => {
  const [menu, setMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);
  // const [allNotification, setAllNotification] = useState({});
  const [newNotificationCount, setNewNotificationCount] = useState(null);
  const userObj = JSON.parse(localStorage.authUser);

  const connectSocket = () => {
    const socket = io(process.env.REACT_APP_API_BASE_URL);

    socket.on(`globalNotification_${userObj?.userId}`, (notificationCount) => {
      console.log("Check notification count", notificationCount)
      setNewNotificationCount(notificationCount?.isNewCount);
    });

    return () => {
      socket.disconnect();
    };
  };

  useEffect(() => {
    if (userObj.roleId === 1 || userObj.roleId === 2) {
      getNotifications(1);
      connectSocket()
    }
  }, []);

  const toggleMenu = () => {
    if (!menu) {
      updateNotification("newStatusUpdate", notifications);
    }
    setMenu(!menu);
  };

  // --------------Function to get notifications listing data-----------
  const getNotifications = (p = 1) => {
    const pageSize = 50;
    let url =
      "admin/getGlobalNotifications?page=" + p + "&pageSize=" + pageSize;
    GetData(url)
      .then((response) => {
        if (response.status && response.code === 200) {
          const notificationsData =
            response?.result?.notifications?.parsedNotifications;
          setNotifications(
            notificationsData.filter((data) => data?.is_read === false)
            // []
          );
          setNewNotificationCount(response?.result?.notifications?.isNewCount)
          // setAllNotification(response?.result?.notifications);
        } else {
          toastr.error(response.message, "Error");
        }
      })
      .catch((err) => {
        toastr.error(err, "Error");
      });
  };

  // --------------------Function to update notifications-------------
  const updateNotification = (action, notificationData) => {
    let apiUrl = "admin/updateNofication";
    let payload = {
      action: action,
    };

    if (action === "newStatusUpdate") {
      payload.is_new = 0;
    } else if (action === "readStatusUpdate") {
      payload.is_read = 1;
      payload.notification_id = notificationData?.notification_id;
    }

    PostData(apiUrl, payload).then((response) => {
      if (response.status === true) {
        getNotifications();
      } else {
        toastr.error(response.message, "Error");
      }
    });
  };

  // --------------------Function to get dynamic icons based on action------------------
  const getIconName = (action) => {
    const iconMap = {
      ORDER_PLACED: "bx bx-cart",
      CUSTOMER_CHANGE_REQUEST: "bx bxs-user-detail",
      ORDER_NOTE: "mdi mdi-file-chart",
      CUSTOMER_REGISTERED: "bx bxs-user-plus",
      WD_PRODUCT_UPLOAD: "mdi mdi-file-excel",
      WD_PRICE_UPLOAD: "mdi mdi-file-excel",
      default: "bx bxs-info-circle",
    };

    return iconMap[action] || iconMap["default"];
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggleMenu}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i
            className={`bx bx-bell ${
              newNotificationCount > 0 ? "bx-tada" : ""
            } `}
          />
          {/* {allNotification?.isNewCount > 0 && (
            <span className="badge bg-danger rounded-pill">
              {allNotification?.isNewCount}
            </span>
          )} */}
          {newNotificationCount > 0 && (
            <span className="badge bg-danger rounded-pill">
              {newNotificationCount}
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="notification-header">
            <h4>
              Notifications <span>({notifications?.length})</span>
            </h4>
            {notifications?.length > 0 && (
              <button onClick={() => updateNotification("readStatusUpdate")}>
                Mark all as read
              </button>
            )}
          </div>
          <ul className="wrapper-notification">
            {notifications?.length > 0 ? (
              notifications?.map((notification, index) => (
                <Link
                  to={notification?.additional_data?.LINK}
                  onClick={() =>
                    updateNotification("readStatusUpdate", notification)
                  }
                >
                  <li className="inner-notification" key={index}>
                    <div className="notification-type">
                      <i className={getIconName(notification?.action)} />
                    </div>
                    <div className="notification-details">
                      <h6 className="title">{notification?.title}</h6>
                      <p className="action-time">
                        {moment(notification?.createdAt).fromNow()}
                        <span className="tag">
                          {notification?.generated_by?.replace(/_/g, " ")}
                        </span>
                      </p>
                    </div>
                  </li>
                </Link>
              ))
            ) : (
              <img
                className="empty-notification"
                src={noNotificationImg}
                alt="no notification"
              />
            )}
          </ul>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="/notifications"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span key="t-view-more">{"View All Notification..."}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
