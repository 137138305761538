/* eslint-disable no-dupe-keys */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useContext, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Modal,
  Label,
  Input,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  FormFeedback
} from 'reactstrap'
import { ProfileModalContext } from 'pages/Customer/View'
import { PostData } from '../../apiHandler'
import PropTypes from 'prop-types'
import toastr from 'toastr'

// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import NumberFormat from 'react-number-format'

const billingOptions = [{
  label: '30 Days',
  value: 30
}, {
  label: '60 Days',
  value: 60
}, {
  label: '90 Days',
  value: 90
}]

export default function ProfileModal (props) {
  const [stateBtn, setStateBtn] = useState(false)
  const [stateBtn1, setStateBtn1] = useState(false)
  const [businessType, setBusinessType] = useState({})
  const [profileData, setProfileData] = useState({})
  const [ownershipType, setOwnershipType] = useState({})
  const [chainAffilation, setChainAffilation] = useState({})
  const [priceLevels, setPriceLevels] = useState([])
  const [billingCycle, setBillingCycle] = useState()
  const [needValidation, setNeedValidation] = useState(false)

  const [documentDetails, setDocumentDetails] = useState([])

  const { profileModal, setProfileModal, setLoader } =
    useContext(ProfileModalContext)

  useEffect(() => {
    const commonData = JSON.parse(localStorage.commonData)
    if (commonData.businessType) {
      setBusinessType(commonData.businessType)
    }
    if (commonData.ownershipType) {
      setOwnershipType(commonData.ownershipType)
    }
    if (commonData.chainAffilation) {
      setChainAffilation(commonData.chainAffilation)
    }
    if (commonData.priceLevels) {
      setPriceLevels(commonData.priceLevels)
    }
  }, [])

  useEffect(() => {
    if (props.profileData && Object.keys(profileData).length === 0) {
      const newObj = { ...props.profileData }
      setProfileData(newObj)
      setNeedValidation(false)
      props.profileData.billingCycle ? setBillingCycle(props.profileData.billingCycle?.duration) : setBillingCycle(30)
    }
  }, [props, profileData])

  const save = async () => {
    validation.submitForm()
    const { isValid, errors } = validation
    console.log(isValid, errors)
    setNeedValidation(true)
    if (isValid && profileData?.priceLevels?.name) { props.saveProfile(profileData) }
  }

  const onChangeHandler = (e) => {
    if (e.target.name === 'billingCycleDuration') {
      setBillingCycle(e.target.value)
      setProfileData((value) => ({
        ...value,
        billingCycleDuration: e.target.value
      }))
    } else {
      setProfileData((value) => ({
        ...value,
        [e?.target?.name]: e?.target?.value
      }))
    }
  }

  const handleOpenAccount = (e) => {
    const myObj = { ...profileData }
    if (e.target.checked) {
      myObj[e.target.name] = false
      delete myObj.billingCycleDuration
    } else {
      myObj[e.target.name] = true
      myObj.billingCycleDuration = 30
    }
    setProfileData(myObj)
  }

  const handleDropship = (e) => {
    const myObj = { ...profileData }
    if (e.target.checked) {
      myObj[e.target.name] = false
    } else {
      myObj[e.target.name] = true
    }
    setProfileData(myObj)
  }

  function removeBodyCss () {
    document.body.classList.add('no_padding')
  }
  // eslint-disable-next-line camelcase
  function tog_large () {
    // setmodal_large(!modal_large);
    setProfileModal(!profileModal)
    removeBodyCss()
  }

  const NumberValidation = Yup.string()
    .max(10, 'Please Enter Valid Mobile Number')
    .min(10, 'Please Enter Valid Mobile Number')
    .required('Please Enter Mobile Number')
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Please Enter Valid Mobile Number'
    )

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      companyName: '',
      email: '',
      mobile: '',
      chainAffiliation: '',
      dateCompanyStarted: '',
      ownershipType: '',
      businessType: '',
      primaryFirstName: '',
      primaryLastName: '',
      primaryEmail: '',
      primaryPhone: '',
      primaryTitle: '',
      alternateFirstName: '',
      alternateLastName: '',
      alternateEmail: '',
      alternateTitle: '',
      reSaleTaxNumber: '',
      alternatePhone: '',
      alternateEmail: '',
      alternateTitle: '',
      isOpenAccount: false,
      reSaleTaxNumber: '',
      ...profileData
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required('Please Enter Company Name'),
      email: Yup.string()
        .email('Please Enter Valid Email Address')
        .required('Please Enter Email Address'),
      mobile: NumberValidation,
      chainAffiliation: Yup.string().required('Please Enter Chain Affiliation'),
      dateCompanyStarted: Yup.string().required(
        'Please Enter Date Company Started'
      ),
      ownershipType: Yup.string().required('Please Enter ownershipType'),
      businessType: Yup.string().required('Please Enter businessType'),
      primaryFirstName: Yup.string().required('Please Enter First Name'),
      primaryLastName: Yup.string().required('Please Enter Last Name'),
      primaryEmail: Yup.string()
        .email('Please Enter Valid Email Address')
        .required('Please Enter Email Address'),
      primaryPhone: NumberValidation,
      primaryTitle: Yup.string().required('Please Enter Title')
    }),
    onSubmit: (values, { resetForm }) => {
    }
  })

  const handleAcceptedFiles = (event) => {
    setLoader(true)
    const formData = new FormData()
    formData.append('document', event.target.files[0])
    PostData('customer/uploadDocument', formData, true).then((response) => {
      setLoader(false)
      if (response.status === true) {
        toastr.success(response.message, 'Success')
        setDocumentDetails([
          {
            documentfile: response?.result?.newFile,
            documentFileUrl: response?.result?.url,
            documentFileName: response?.result?.originalFile
          }
        ])
        onChangeHandler({
          target: { name: 'documentFile', value: response?.result?.newFile }
        })
        onChangeHandler({
          target: { name: 'documentFileUrl', value: response?.result?.url }
        })
        onChangeHandler({
          target: {
            name: 'documentFileName',
            value: response?.result?.originalFile
          }
        })
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col lg={12}>
            <Modal
              size="lg"
              isOpen={profileModal}
              toggle={() => {
                tog_large()
              }}
            >
              <div className="modal-header border-0 modal-new-title ">
                <h5 className="modal-title mt-0" id="myLargeModalLabel">
                  <b> Edit Profile</b>
                </h5>
                <button
                  onClick={() => {
                    setProfileModal(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body location-modal-body new-modal-body">
                <section>
                  <h5 className="profile-modal-heading-blue">
                    Business Information
                  </h5>

                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          Company Name*
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Company Name"
                          name="companyName"
                          onChange={(e) => onChangeHandler(e)}
                          onBlur={validation.handleBlur}
                          value={profileData.companyName}
                          invalid={
                            validation.touched.companyName &&
                            !!validation.errors.companyName
                          }
                        />
                        {validation.touched.companyName &&
                        validation.errors.companyName
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.companyName}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          Email Address*
                        </Label>
                        <Input
                          name="email"
                          onChange={(e) => onChangeHandler(e)}
                          value={profileData.email}
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Email Address"
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.email &&
                            !!validation.errors.email
                          }
                        />
                        {validation.touched.email && validation.errors.email
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          Phone Number*
                        </Label>
                        <NumberFormat value={profileData.mobile} displayType={'input'} format="(###) ###-####"
                        name="mobile"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Enter Phone Number"
                        onChange={(e) => onChangeHandler(e)}
                        />
                        {/* <Input
                          name="mobile"
                          onChange={(e) => onChangeHandler(e)}
                          value={profileData.mobile}
                          type="tel"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Phone Number"
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.mobile &&
                            !!validation.errors.mobile
                          }
                        /> */}
                        {validation.touched.mobile &&
                        validation.errors.mobile
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.mobile}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          Chain Affiliation*
                        </Label>
                        <Dropdown
                          isOpen={stateBtn}
                          toggle={() => setStateBtn(!stateBtn)}
                          className="location-modal-select-container"
                        >
                          <DropdownToggle className="location-modal-select text-dark">
                            <span>
                              {chainAffilation[profileData.chainAffiliation]}
                            </span>
                            <i className=" ms-2 bx bx-caret-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            {Object.keys(chainAffilation).length
                              ? (
                                  Object.keys(chainAffilation).map((k, i) => {
                                    return (
                                  <DropdownItem
                                    onClick={() =>
                                      onChangeHandler({
                                        target: {
                                          name: 'chainAffiliation',
                                          value: k
                                        }
                                      })
                                    }
                                    key={i}
                                  >
                                    {chainAffilation[k]}
                                  </DropdownItem>
                                    )
                                  })
                                )
                              : (
                              <DropdownItem>Any</DropdownItem>
                                )}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          Date Company Started*
                        </Label>
                        <Input
                          name="dateCompanyStarted"
                          onChange={(e) => onChangeHandler(e)}
                          type="date"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Date"
                          value={profileData.dateCompanyStarted}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.dateCompanyStarted &&
                            !!validation.errors.dateCompanyStarted
                          }
                        />
                        {validation.touched.dateCompanyStarted &&
                        validation.errors.dateCompanyStarted
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.dateCompanyStarted}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Label className="profile-modal-content-heading">
                        Ownership Type* (Check One)
                      </Label>
                      {Object.keys(ownershipType).length
                        ? Object.keys(ownershipType).map((k, i) => {
                          return (
                              <div key={i} className="form-check mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={k}
                                  name="ownershipType"
                                  onClick={(e) => {
                                    onChangeHandler(e)
                                  }}
                                  checked={
                                    profileData.ownershipType === k
                                  }
                                  id={'ownership-view-' + k}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={'ownership-view-' + k}
                                >
                                  {ownershipType[k]}
                                </label>
                              </div>
                          )
                        })
                        : '--'}
                    </Col>
                    <Col lg={6}>
                      <Label className="profile-modal-content-heading">
                        Type of Business* (Check One)
                      </Label>
                      {Object.keys(businessType).length
                        ? Object.keys(businessType).map((k, i) => {
                          return (
                              <div key={i} className="form-check mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="businessType"
                                  onClick={(e) => {
                                    onChangeHandler(e)
                                  }}
                                  value={k}
                                  checked={
                                    profileData.businessType === k
                                  }
                                  id={'businessType-view-' + k}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={'businessType-view-' + k}
                                >
                                  {businessType[k]}
                                </label>
                              </div>
                          )
                        })
                        : ''}
                    </Col>
                  </Row>
                </section>
                <hr></hr>
                <section>
                  <h5 className="profile-modal-heading-blue">
                    Contact Information
                  </h5>
                  <Row>
                    <Col lg={6}>
                      <Row>
                        <h6 className="profile-modal-content-heading">
                          Primary / Owner
                        </h6>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              First Name*
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter First Name"
                              name="primaryFirstName"
                              onChange={(e) => onChangeHandler(e)}
                              value={profileData.primaryFirstName}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.primaryFirstName &&
                                !!validation.errors.primaryFirstName
                              }
                            />
                            {validation.touched.primaryFirstName &&
                            validation.errors.primaryFirstName
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.primaryFirstName}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Last Name*
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Last Name"
                              name="primaryLastName"
                              onChange={(e) => onChangeHandler(e)}
                              value={profileData.primaryLastName}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.primaryLastName &&
                                !!validation.errors.primaryLastName
                              }
                            />
                            {validation.touched.primaryLastName &&
                            validation.errors.primaryLastName
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.primaryLastName}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Email Address*
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Email Address"
                              name="primaryEmail"
                              onChange={(e) => onChangeHandler(e)}
                              value={profileData.primaryEmail}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.primaryEmail &&
                                !!validation.errors.primaryEmail
                              }
                            />
                            {validation.touched.primaryEmail &&
                            validation.errors.primaryEmail
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.primaryEmail}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Mobile Number*
                            </Label>
                            <NumberFormat
                            className="form-control" placeholder="Enter Mobile Number"
                            name="primaryPhone" displayType="input" format="(###) ###-####"
                            value={profileData.primaryPhone}
                            onChange={(e) => onChangeHandler(e)}
                            />
                            {/* <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Mobile Number"
                              name="primaryPhone"
                              onChange={(e) => onChangeHandler(e)}
                              value={profileData.primaryPhone}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.primaryPhone &&
                                !!validation.errors.primaryPhone
                              }
                            /> */}
                            {validation.touched.primaryPhone &&
                            validation.errors.primaryPhone
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.primaryPhone}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Title*
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Title  "
                              name="primaryTitle"
                              onChange={(e) => onChangeHandler(e)}
                              value={profileData.primaryTitle}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.primaryTitle &&
                                !!validation.errors.primaryTitle
                              }
                            />
                            {validation.touched.primaryTitle &&
                            validation.errors.primaryTitle
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.primaryTitle}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col lg={6}>
                      <Row>
                        <h6 className="profile-modal-content-heading">
                          Alternate
                        </h6>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              First Name
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter First Name"
                              name="alternateFirstName"
                              onChange={(e) => onChangeHandler(e)}
                              value={profileData.alternateFirstName}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Last Name
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Last Name"
                              name="alternateLastName"
                              onChange={(e) => onChangeHandler(e)}
                              value={profileData.alternateLastName}
                            />
                            {validation.touched.alternateLastName &&
                            validation.errors.alternateLastName
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.alternateLastName}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Email Address
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Email Address"
                              name="alternateEmail"
                              onChange={(e) => onChangeHandler(e)}
                              value={profileData.alternateEmail}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.alternateEmail &&
                                !!validation.errors.alternateEmail
                              }
                            />
                            {validation.touched.alternateEmail &&
                            validation.errors.alternateEmail
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.alternateEmail}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Mobile Number
                            </Label>
                            <NumberFormat
                            className="form-control" placeholder="Enter Mobile Number"
                            name="alternatePhone" onChange={(e) => onChangeHandler(e)}
                            displayType="input" format="(###) ###-####"
                            value={profileData.alternatePhone}
                            />
                            {validation.touched.alternatePhone &&
                            validation.errors.alternatePhone
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.alternatePhone}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Title
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Title  "
                              name="alternateTitle"
                              onChange={(e) => onChangeHandler(e)}
                              value={profileData.alternateTitle}
                            />
                            {validation.touched.alternateTitle &&
                            validation.errors.alternateTitle
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.alternateTitle}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                      </Row>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col lg={12}>
                    <div className="mb-3 d-flex">
                    <Input
                              type="checkbox"
                              id="open-account"
                              name="isOpenAccount"
                              onClick={(e) => handleOpenAccount(e)}
                              checked={Boolean(profileData.isOpenAccount)}
                            />
                            <Label htmlFor="open-account" style={{ marginLeft: '5px' }}>
                              Is Open Account Customer
                            </Label>
                          </div>
                    </Col>
                  </Row>
                  {/* {
                    Boolean(profileData.isOpenAccount) && <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Select Billing Cycle*
                          </Label>
                           {
                                profileData.billingCycle.duration
                              }
                          <Dropdown
                            isOpen={stateBtn}
                            toggle={() => setStateBtn(!stateBtn)}
                            className="location-modal-select-container"
                          >
                            <DropdownToggle className="location-modal-select text-dark">
                              <span>
                                {billingOptions.find((item) => item.value === profileData.billingCycle.duration).label}
                              </span>
                              <i className=" ms-2 bx bx-caret-down" />
                            </DropdownToggle>
                            <DropdownMenu>
                              {billingOptions.length
                                ? (
                                    billingOptions.map((k, i) => {
                                      return (
                                    <DropdownItem
                                      onClick={() =>
                                        onChangeHandler({
                                          target: {
                                            name: 'billingCycle',
                                            value: k.value
                                          }
                                        })
                                      }
                                      key={i}
                                    >
                                      {k.label}
                                    </DropdownItem>
                                      )
                                    })
                                  )
                                : (
                                <DropdownItem>Any</DropdownItem>
                                  )}
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </Col>
                    </Row>
                  } */}
                  {
                    profileData.isOpenAccount && <Row>
                    <Col lg={6}>
                    <div className="number--location phn-email">
                              Billing Cycle Duration*
                            </div>
                    <select
                          id="billingCycleDuration"
                          name="billingCycleDuration"
                          value={billingCycle}
                          onChange={(e) => onChangeHandler(e)}
                          className="register-input pointer"
                        >
                          {billingOptions.map((ch, c) => {
                            return (
                              <option key={c} value={ch.value}>
                                {ch.label}
                              </option>
                            )
                          })}
                        </select>
                    </Col>
                  </Row>
                  }
                  <Row>
                    <Col lg={12}>
                    <div className="mt-3 d-flex">
                    <Input
                              type="checkbox"
                              id="allow_dropship"
                              name="allow_dropship"
                              onClick={(e) => handleDropship(e)}
                              checked={Boolean(profileData?.allow_dropship)}
                            />
                            <Label htmlFor="allow_dropship" style={{ marginLeft: '5px' }}>
                              Allow Dropship
                            </Label>
                          </div>
                    </Col>
                  </Row>
                </section>
                <hr></hr>
                <section>
                  <h5 className="profile-modal-heading-blue">
                    Tax Information
                  </h5>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          Resale Tax Number
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Resale Tax Number"
                          name="reSaleTaxNumber"
                          onChange={(e) => onChangeHandler(e)}
                          value={profileData.reSaleTaxNumber}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.reSaleTaxNumber &&
                            !!validation.errors.reSaleTaxNumber
                          }
                        />
                        {validation.touched.reSaleTaxNumber &&
                        validation.errors.reSaleTaxNumber
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.reSaleTaxNumber}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          Upload Document (Optional)
                        </Label>

                        {/* {documentDetails.length > 0 ? (
                          documentDetails?.map((d, index) => (
                            <p key={index}>
                              <a href={d?.documentFileUrl} target="_blank">
                                {d.documentfile}
                              </a>{" "}&nbsp;
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setDocumentDetails([]);
                                  onChangeHandler({
                                    target: {
                                      name: "customerDocuments",
                                      value: [],
                                    },
                                  });
                                  validation.setFieldValue(
                                    "customerDocuments",
                                    []
                                  );
                                }}
                              >
                                <i className="fas fa-window-close" style={{color:'red'}}/>
                              </span>
                            </p>
                          ))
                        ) : (validation.values.customerDocuments || []).length >
                          0 ? (
                          (validation.values.customerDocuments || []).map(
                            (d, index) => (
                              <p key={index}>
                                <a href={d?.documentUrl} target="_blank">
                                  {d.name}
                                </a>{" "}&nbsp;
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setDocumentDetails([]);
                                    onChangeHandler({
                                      target: {
                                        name: "customerDocuments",
                                        value: [],
                                      },
                                    });
                                    validation.setFieldValue(
                                      "customerDocuments",
                                      []
                                    );
                                  }}
                                >
                                  <i className="fas fa-window-close" style={{color:'red'}}/>
                                </span>
                              </p>
                            )
                          )
                        ) : (
                          <p>--</p>
                        )} */}
                        <div className="d-flex">
                        <div style={{ cursor: 'pointer' }}>
                        <Input
                          type="file"
                          className="form-control d-none"
                          id="UploadTaxDocument"
                          onChange={(e) => handleAcceptedFiles(e)}
                          accept=".docx,.doc,.png,.pdf"
                        />
                        <div
                          className="upload-document-container"
                          onClick={(e) => {
                            e.preventDefault()
                            document
                              .getElementById('UploadTaxDocument')
                              .click()
                          }}
                        >
                          <i className="bx bx-cloud-upload me-2"></i>
                          Choose File
                        </div>
                        </div>
                        <div style={{ marginLeft: '1.5em' }}>
                        {documentDetails.length > 0
                          ? (
                              documentDetails?.map((d, index) => (
                            <p key={index}>
                              <a href={d?.documentFileUrl} target="_blank">
                                {d.documentfile}
                              </a>{' '}&nbsp;
                              <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setDocumentDetails([])
                                  onChangeHandler({
                                    target: {
                                      name: 'customerDocuments',
                                      value: []
                                    }
                                  })
                                  validation.setFieldValue(
                                    'customerDocuments',
                                    []
                                  )
                                }}
                              >
                                <i className="fas fa-window-close" style={{ color: 'red' }}/>
                              </span>
                            </p>
                              ))
                            )
                          : (validation.values.customerDocuments || []).length >
                          0 && validation.values.customerDocuments[0].documentUrl != null
                              ? (
                                  (validation.values.customerDocuments || []).map(
                                    (d, index) => (
                              <p key={index}>
                                <a href={d?.documentUrl} target="_blank">
                                  {d.name}
                                </a>{' '}&nbsp;
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setDocumentDetails([])
                                    onChangeHandler({
                                      target: {
                                        name: 'customerDocuments',
                                        value: []
                                      }
                                    })
                                    validation.setFieldValue(
                                      'customerDocuments',
                                      []
                                    )
                                  }}
                                >
                                  <i className="fas fa-window-close" style={{ color: 'red' }}/>
                                </span>
                              </p>
                                    )
                                  )
                                )
                              : null}
                        </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <h6 className="profile-modal-content-heading">
                        Price Group
                      </h6>
                      <Dropdown
                        isOpen={stateBtn1}
                        toggle={() => {
                          setStateBtn1(!stateBtn1)
                          setNeedValidation(true)
                        }}
                        className={
                          'location-modal-select-container' +
                          (!profileData?.priceLevels?.name ? ' is-invalid' : '')
                        }
                      >
                        <DropdownToggle style={{ height: 'fit-content' }} className="location-modal-select text-dark">
                          <span>
                            {profileData?.priceLevels
                              ? `${profileData?.priceLevels?.name} ${profileData?.priceLevels?.name !== 'National Account' ? `- ${profileData?.priceLevels?.value}` : ''}`
                              : 'Select Price Level'}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {priceLevels?.length
                            ? (
                                priceLevels?.map?.((price, i) => {
                                  return (
                                <DropdownItem
                                  onClick={() => {
                                    onChangeHandler({
                                      target: {
                                        name: 'priceLevels',
                                        value: price
                                      }
                                    })
                                    onChangeHandler({
                                      target: {
                                        name: 'priceLevelId',
                                        value: price.id
                                      }
                                    })
                                  }}
                                  key={i}
                                >
                                  {price.name} {price?.value && '-'} {price?.value}
                                </DropdownItem>
                                  )
                                })
                              )
                            : (
                            <DropdownItem key={1}>Any</DropdownItem>
                              )}
                        </DropdownMenu>
                      </Dropdown>
                      {needValidation && !profileData?.priceLevels?.name && (
                        <div type="invalid" className="invalid-feedback">
                          Please Select Price Level
                        </div>
                      )}
                    </Col>
                  </Row>
                </section>
                <hr></hr>
                <Row className="mb-3">
                  <Col lg={12}>
                    <div className="d-flex justify-content-end">
                      <button
                        onClick={() => setProfileModal(false)}
                        className="location-modal-cancel-btn"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => save()}
                        className="location-modal-save-btn"
                      >
                        {' '}
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  )
}
ProfileModal.propTypes = {
  profileData: PropTypes.object,
  saveProfile: PropTypes.func
}
