/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable multiline-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
import React, { useEffect, useRef, useState } from 'react'
import { Label, Input, Col, Form, FormFeedback } from 'reactstrap'
import back from '../../assets/images/back2.svg'
import information from '../../assets/images/info2.svg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { PostWithoutToken, GetWithoutToken } from 'apiHandler'
import Loader from '../../components/Loader'
import StaticHeader from './StaticHeader'
import FooterStatic from './StaticFooter'
import { globalVariables } from 'globalVariables'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const NewRegister = () => {
  const stateRef = useRef()
  const [show, setShow] = useState(true)
  const [loader, setLoader] = useState(false)
  const [formData, setFormData] = useState({})
  const [chainAffiliation, setChainAffiliation] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [termsError, setTermsError] = useState(false)
  const [uploadFile, setUploadFile] = useState(null)
  console.log(formData)

  const locationObj = {
    open: true,
    completed: false,
    locationName: '',
    shippingStreet: '',
    shippingCountryId: globalVariables.countryId,
    shippingStateId: '',
    shippingCityId: '',
    shippingZipCode: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactPhone: '',
    contactTitle: '',
    isPrimaryLocation: 1
  }

  const [locationsArray, setLocationsArray] = useState([locationObj])
  const [locationsErrorArray, setLocationsErrorArray] = useState([locationObj])

  const locationErrorStrings = {
    locationName: 'Please enter location name',
    shippingStreet: 'Please Enter Shipping Street',
    shippingStateId: 'Please Select State',
    shippingCityId: 'Please Select City',
    shippingZipCode: 'Please Enter Zip Code',
    contactFirstName: 'Please enter first name',
    contactLastName: 'Please enter last name',
    contactEmail: 'Please enter email',
    contactPhone: 'Please enter phone',
    contactTitle: 'Please enter title'
  }

  function handleOpen (i, e) {
    e.preventDefault()
    let temp
    temp = [...locationsArray]

    if (temp[i].open == true) {
      temp[i].open = false
      setLocationsArray(temp)
    } else {
      temp[i].open = true
      setLocationsArray(temp)
    }
  }

  useEffect(() => {
    setLoader(true)
    GetWithoutToken('commonData').then((response) => {
      if (response.status === true) {
        setChainAffiliation(response.result.chainAffilation)
        setLoader(false)
      } else {
        toastr.error(response.message, 'Error')
        setLoader(false)
      }
    })
    GetWithoutToken(`states/?countryId=${globalVariables.countryId}`).then((response) => {
      if (response.status === true) {
        setStates(response.result)
        setLoader(false)
      } else {
        toastr.error(response.message, 'Error')
        setLoader(false)
      }
    })
  }, [])

  useEffect(() => {
    const alertUser = (e) => {
      e.preventDefault()
      e.returnValue = ''
    }
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: '',
      email: '',
      mobile: '',
      chainAffiliation: '',
      dateCompanyStarted: '',
      primaryBillingStreet: '',
      primaryBillingCountryId: globalVariables.countryId,
      primaryBillingStateId: '',
      primaryBillingCityId: '',
      primaryBillingZipCode: '',
      reSaleTaxNumber: '',
      primaryFirstName: '',
      primaryLastName: '',
      primaryEmail: '',
      primaryPhone: '',
      primaryTitle: '',
      alternateFirstName: '',
      alternateLastName: '',
      alternateEmail: '',
      alternatePhone: '',
      alternateTitle: '',
      accountingFirstName: '',
      accountingLastName: '',
      accountingEmail: '',
      accountingPhone: '',
      accountingTitle: '',
      documentFile: '',
      noOfLocations: '1',
      userName: 'Dev',
      categories: [1]
    },
    validationSchema: Yup.object({
      companyName: Yup.string().trim().required('Please Enter Company Name'),
      email: Yup.string('Enter your email').trim()
        .email('Enter a valid email')
        .required('Email is required'),
      mobile: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required('Please enter Phone Number')
        .test(
          'maxDigits',
          'Must be 10 digits',
          (number) => String(number).length === 10
        ),
      chainAffiliation: Yup.string().required(
        'Please Select Chain Affiliation'
      ),
      dateCompanyStarted: Yup.string().required(
        'Please Enter Date of Company Started'
      ),
      primaryBillingStreet: Yup.string().trim().required('Please Enter Street Name'),
      primaryBillingStateId: Yup.number().required(
        'Please Select Billing State'
      ),
      primaryBillingCityId: Yup.number().required('Please Select Billing City'),
      primaryBillingZipCode: Yup.string().trim().required(
        'Please Enter Billing ZipCode'
      ),
      primaryFirstName: Yup.string().trim().required('Please Enter First Name'),
      primaryLastName: Yup.string().trim().required('Please Enter Second Name'),
      primaryEmail: Yup.string('Enter your email').trim()
        .email('Enter a valid email')
        .required('Email is required'),
      primaryPhone: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required('Please enter Phone Number')
        .test(
          'maxDigits',
          'Must be 10 digits',
          (number) => String(number).length === 10
        ),
      primaryTitle: Yup.string().trim().required('Please Enter Title'),
      alternateFirstName: Yup.string().trim().required('Please Enter First Name'),
      alternateLastName: Yup.string().trim().required('Please Enter Second Name'),
      alternateEmail: Yup.string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
      alternatePhone: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required('Please enter Phone Number')
        .test(
          'maxDigits',
          'Must be 10 digits',
          (number) => String(number).length === 10
        ),
      alternateTitle: Yup.string().trim().required('Please Enter Title'),
      accountingFirstName: Yup.string().trim().required('Please Enter First Name'),
      accountingLastName: Yup.string().trim().required('Please Enter Second Name'),
      accountingEmail: Yup.string('Enter your email').trim()
        .email('Enter a valid email')
        .required('Email is required'),
      accountingPhone: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required('Please enter Phone Number')
        .test(
          'maxDigits',
          'Must be 10 digits',
          (number) => String(number).length === 10
        ),
      accountingTitle: Yup.string().trim().required('Please Enter Title'),
      documentFile: Yup.string()
    }),
    onSubmit: (values) => {
      setShow(false)
      const tempData = values
      tempData.primaryBillingStateId = parseInt(tempData.primaryBillingStateId)
      tempData.primaryBillingCityId = parseInt(tempData.primaryBillingCityId)
      if (uploadFile) {
        tempData.documentFile = `${uploadFile.newFile}`
      }
      setFormData(values)
    }
  })

  function UploadFile (e) {
    e.preventDefault()
    const formImg = new FormData()
    formImg.append('document', e.target.files[0])
    PostWithoutToken('admin/uploadWDDocumentPublic', formImg, true).then(
      (response) => {
        if (response.status === true) {
          setUploadFile(response.result)
        } else {
          toastr.error(response.message, 'Error')
        }
      }
    )
  }

  function getCities (id, e) {
    e.preventDefault()
    setLoader(true)
    GetWithoutToken(`cities/?countryId=${globalVariables.countryId}&&stateId=${id}`).then((response) => {
      if (response.status === true) {
        setCities(response.result)
        setLoader(false)
      } else {
        toastr.error(response.message, 'Error')
        setLoader(false)
      }
    })
  }

  function handleAdd (e) {
    e.preventDefault()
    const locationsTemp = [...locationsArray]
    const locationsErrorTemp = JSON.parse(JSON.stringify(locationsErrorArray))
    locationsErrorTemp.push(locationObj)
    setLocationsErrorArray(locationsErrorTemp)
    locationObj.isPrimaryLocation = 0
    locationsTemp.push(locationObj)
    const locationsTempObj = []
    locationsTemp.map((l) => {
      l.open = false
      locationsTempObj.push(l)
      return null
    })
    locationsTempObj[locationsTempObj.length - 1].open = true
    setLocationsArray(locationsTempObj)
  }

  const handleLocations = (e, index) => {
    const locationsTemp = [...locationsArray]
    const locationErrorsTemp = JSON.parse(JSON.stringify(locationsErrorArray))
    locationErrorsTemp[index][e.target.name] = ''
    locationsTemp[index][e.target.name] = e.target.value
    setLocationsArray(locationsTemp)
    setLocationsErrorArray(locationErrorsTemp)
  }

  const handleCheck = (index, e) => {
    e.preventDefault()
    const locationsTemp = JSON.parse(JSON.stringify(locationsArray))
    const locationsErrorTemp = JSON.parse(JSON.stringify(locationsErrorArray))
    let error = 0
    const errorTitles = []
    for (const k in locationsTemp[index]) {
      if (locationsTemp[index][k] === '') {
        error = 1
        locationsErrorTemp[index][k] = locationErrorStrings[k]
        errorTitles.push(k)
      }
    }
    setLocationsErrorArray(locationsErrorTemp)
    if (!error) {
      locationsTemp[index].open = false
      locationsTemp[index].completed = true
      locationsTemp[index].shippingStateId = parseInt(
        locationsTemp[index].shippingStateId
      )
      locationsTemp[index].shippingCityId = parseInt(
        locationsTemp[index].shippingCityId
      )
      setLocationsArray(locationsTemp)
      const formDataTemp = { ...formData }
      formDataTemp.locations = locationsTemp
      setFormData(formDataTemp)
    }
  }

  function getAllLocations (e) {
    e.preventDefault()
    let error = 0
    const checkError = checkLocationError()
    if (checkError.length) {
      error = 1
      setTimeout(() => {
        if (document.getElementById(checkError[0])) {
          document.getElementById(checkError[0]).focus()
        }
      }, 400)
    }
    //  else {
    //   for (let k in formErrorsStep2) {
    //     if (formData[k] === "") {
    //       error = 1;
    //       setFormErrorsStep2((prevState) => ({
    //         ...prevState,
    //         [k]: errorStrings2[k],
    //       }));
    //     }
    //   }
    // }
    if (!error) {
      !acceptTerms && setTermsError(true)
      if (acceptTerms) {
        setLoader(true)
        PostWithoutToken('admin/registerWDPublic', formData).then(
          (response) => {
            if (response.status === true) {
              setLoader(false)
              toastr.success(response.message, 'Sent')
            } else {
              toastr.error(response.message, 'Error')
              setLoader(false)
            }
          }
        )
      }
    }
  }
  const checkLocationError = () => {
    const locationsTemp = JSON.parse(JSON.stringify(locationsArray))
    const locationsErrorTemp = JSON.parse(JSON.stringify(locationsErrorArray))
    let error = 0
    const errorTitles = []
    const errorsIndexFocus = []
    const errorsIndex = []
    locationsTemp.map((location, index) => {
      for (const k in locationsTemp[index]) {
        if (locationsTemp[index][k] === '') {
          error = 1
          locationsErrorTemp[index][k] = locationErrorStrings[k]
          errorTitles.push(k)
        }
      }
      setLocationsErrorArray(locationsErrorTemp)

      const formDataTemp = JSON.parse(JSON.stringify(formData))
      formDataTemp.locations = locationsArray

      if (!error) {
        locationsTemp[index].open = false
        locationsTemp[index].completed = true
        setLocationsArray(locationsTemp)
        setFormData(formDataTemp)
      } else {
        errorsIndexFocus.push(errorTitles[0] + '-' + index)
        errorsIndex.push(index)
      }
    })
    if (errorsIndex.length) {
      locationsTemp[errorsIndex[0]].open = true
      locationsTemp[errorsIndex[0]].completed = false
      setLocationsArray(locationsTemp)
    }
    return errorsIndexFocus
  }

  function handleSetPrimary (index, e) {
    const locationObj = [...locationsArray]
    locationObj.map((location, i) => {
      location.isPrimaryLocation = 0
    })
    locationObj[index].isPrimaryLocation = 1
    setLocationsArray(locationObj)
  }

  return (
    <React.Fragment>
      <StaticHeader />
      <Loader show={loader} />
      <div className="main-register-box">
        <div className="need---help">
          Need Help?
          <a className="contact--link" to="/contact-us">
            {' '}
            Contact Us{' '}
          </a>
        </div>
        {/* {
                    step 1  */}
        {show ? (
          <Form>
            <div className="mainn">
              <div className="mainn-div">
                <div className="ql">
                  <div className="create-account">Create Account</div>
                  <div className="apply_div">Apply for WD Account</div>
                  <button className="step__btn">
                    Step 1<span className="second"> / 2</span>
                  </button>
                </div>
                <div className="business__row">
                  <div className="busines__div">Business Information</div>
                  <div className="all__marked">
                    * All marked fields are mandatory
                  </div>
                </div>
                <div className="company--row">
                  <div className="company-name-div">
                    <div className="label input-label">Company Name*</div>
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className={
                        validation.touched.companyName &&
                        validation.errors.companyName
                          ? 'company_input is-invalid'
                          : 'company_input'
                      }
                      placeholder="Enter company name"
                    />
                    {validation.touched.companyName &&
                    validation.errors.companyName
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.companyName}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                  <div className="company-name-div">
                    <div className="label">
                      Primary Email Address* <img src={information} />
                    </div>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className={
                        validation.touched.email && validation.errors.email
                          ? 'company_input is-invalid'
                          : 'company_input'
                      }
                      placeholder="Enter email address"
                    />
                    {validation.touched.email && validation.errors.email
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                </div>
                <div className="mobile--row mobile--row-wd">
                  <div className="mobile-main-div">
                    <div className="label">Mobile Number*</div>
                    <input
                      type="text"
                      id="mobile"
                      name="mobile"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className={
                        validation.touched.mobile && validation.errors.mobile
                          ? 'mobile--input is-invalid'
                          : 'mobile--input'
                      }
                      placeholder="Enter mobile number"
                    />
                    {validation.touched.mobile && validation.errors.mobile
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.mobile}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                  <div className="mobile-main-div chain__affiliation main-affilication">
                    <div className="label">Chain Affiliation*</div>
                    <select
                      id="chainAffiliation"
                      name="chainAffiliation"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className={
                        validation.touched.chainAffiliation &&
                        validation.errors.chainAffiliation
                          ? 'mobile--input is-invalid'
                          : 'mobile--input'
                      }
                    >
                      <option value="">Select</option>
                      {Object.keys(chainAffiliation).map((ch, c) => {
                        return (
                          <option key={c} value={ch}>
                            {chainAffiliation[ch]}
                          </option>
                        )
                      })}
                    </select>

                    {validation.touched.chainAffiliation &&
                    validation.errors.chainAffiliation
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.chainAffiliation}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                  <div className="mobile-main-div main-affilication">
                    <div className="label">Date Company Started*</div>
                    <input
                      type="date"
                      name="dateCompanyStarted"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className={
                        validation.touched.dateCompanyStarted &&
                        validation.errors.dateCompanyStarted
                          ? 'mobile--input is-invalid'
                          : 'mobile--input'
                      }
                    />
                    {/* <i className="bx bx-calendar-minus new-down-arrow"></i> */}
                    {validation.touched.dateCompanyStarted &&
                    validation.errors.dateCompanyStarted
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.dateCompanyStarted}
                      </FormFeedback>
                        )
                      : null}
                    {/* <DatePicker
                                                placeholderText="dd/mm/yyyy"
                                                className='mobile--input'
                                                id="dateCompanyStartedTemp"
                                                name="dateCompanyStartedTemp"

                                                dateFormat={'MM/dd/yyyy'}

                                            /> */}
                  </div>
                </div>
                <div className="tax--div">Primary Billing Address</div>
                <div className="company--row">
                  <div className="company-name-div">
                    <div className="label input-label">Street*</div>
                    <input
                      type="text"
                      id="primaryBillingStreet"
                      name="primaryBillingStreet"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className={
                        validation.touched.primaryBillingStreet &&
                        validation.errors.primaryBillingStreet
                          ? 'company_input is-invalid'
                          : 'company_input'
                      }
                      placeholder="Enter Street Name "
                    />
                    {validation.touched.primaryBillingStreet &&
                    validation.errors.primaryBillingStreet
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.primaryBillingStreet}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                  <div className="company-name-div main-affilication">
                    <div className="label">State*</div>
                    <select
                      name="primaryBillingStateId"
                      id="primaryBillingStateId"
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      onClick={(e) => getCities(stateRef.current.value, e)}
                      ref={stateRef}
                      className={
                        validation.touched.primaryBillingStateId &&
                        validation.errors.primaryBillingStateId
                          ? 'company_input is-invalid'
                          : 'company_input'
                      }
                    >
                      <option value="null">Select</option>
                      {states.map((data, i) => {
                        return (
                          <option key={i} value={data.id}>
                            {data.name}
                          </option>
                        )
                      })}
                    </select>
                    {validation.touched.primaryBillingStateId &&
                    validation.errors.primaryBillingStateId
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.primaryBillingStateId}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                </div>
                <div className="company--row">
                  <div className="company-name-div main-affilication">
                    <div className="label input-label"> City*</div>
                    <select
                      id="primaryBillingCityId"
                      name="primaryBillingCityId"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className={
                        validation.touched.primaryBillingCityId &&
                        validation.errors.primaryBillingCityId
                          ? 'company_input is-invalid'
                          : 'company_input'
                      }
                      placeholder="Enter city"
                    >
                      <option value="">Select</option>
                      {cities.map((data, i) => {
                        return (
                          <option key={i} value={data.id}>
                            {data.name}
                          </option>
                        )
                      })}
                    </select>

                    {validation.touched.primaryBillingCityId &&
                    validation.errors.primaryBillingCityId
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.primaryBillingCityId}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                  <div className="company-name-div">
                    <div className="label">Zip Code*</div>
                    <input
                      type="text"
                      id="primaryBillingZipCode"
                      name="primaryBillingZipCode"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className={
                        validation.touched.primaryBillingZipCode &&
                        validation.errors.primaryBillingZipCode
                          ? 'company_input is-invalid'
                          : 'company_input'
                      }
                      placeholder="Enter zip code "
                    />
                    {validation.touched.primaryBillingZipCode &&
                    validation.errors.primaryBillingZipCode
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.primaryBillingZipCode}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                </div>

                {/* {
                                        formData.businessType === 'other' ?
                                            <div className='other--input--row'>
                                                <input type='text'
                                                id="businessTypeOther"
                                                name="businessTypeOther"
                                                value={formData.businessTypeOther}
                                                onChange={(e) => inputFieldsHandler(e)}
                                                className='other--input' placeholder='Please Specify'/>
                                            </div>
                                        :
                                            <div className='other--input--row'>
                                            </div>
                                    } */}
                <div className="tax--div">Tax Information</div>
                <div className="resale--row">
                  <div className="upload--div resale-tax-div">
                    <div className="resale--div">
                      Resale Tax Number
                      <span className="info-icon">
                        <img
                          src={information}
                          className="info-icon"
                          alt="Info"
                        />
                        <span className="tooltiptext">
                          Lorem Ipsum is simply dummy text of the printing and
                          type setting industry.Lorem Ipsum
                        </span>
                      </span>
                    </div>
                    <input
                      type="text"
                      id="reSaleTaxNumber"
                      name="reSaleTaxNumber"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className={
                        validation.touched.reSaleTaxNumber &&
                        validation.errors.reSaleTaxNumber
                          ? 'resale--input is-invalid'
                          : 'resale--input'
                      }
                      placeholder="Enter resale tax number"
                    />
                    {validation.touched.reSaleTaxNumber &&
                    validation.errors.reSaleTaxNumber
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.reSaleTaxNumber}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                  <div className="upload--div upload__div">
                    {/* <div className='resale--div'>Upload Resale Tax Certificate <span className='optional'>(Optional)</span>
                                        <span className='info-icon'>
                                            <img src='./image/info (1).svg' className='info-icon' alt="Info" />
                                            <span className="tooltiptext">Lorem Ipsum is simply dummy text of the printing and type setting industry.Lorem Ipsum</span>
                                        </span>
                                    </div> */}
                    <Col>
                      <div className="mb-3">
                        <Label
                          htmlFor="formrow-firstname-Input upload--option "
                          className="upload-tax"
                        >
                          Upload Resale Tax Certificate{' '}
                          <span> (Optional) </span> <img src={information} />
                        </Label>
                        <Input
                          type="file"
                          name="documentFile"
                          id="documentFile"
                          className="form-control d-none"
                          onChange={(e) => {
                            validation.handleChange, UploadFile(e)
                          }}
                          onBlur={validation.handleBlur}
                        />
                        <div className="d-flex align-items-center">
                          <div
                            className="upload-document-container upload-wd"
                            onClick={(e) => {
                              e.preventDefault()
                              document.getElementById('documentFile').click()
                            }}
                          >
                            <i className="bx bx-cloud-upload me-2"></i>
                            Choose File
                          </div>
                          <p className="m-0 p-0 ms-3">
                            {uploadFile?.originalFile}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <div className="error"></div>
                  </div>
                </div>
                <div
                  className="location contact-heading"
                  id="contact-information"
                >
                  Contact Information
                </div>
                <div className="contact--div">
                  <div className="owner--primary--div owner--first--div">
                    <div className="owner-primary">Owner / Primary </div>
                    <div className="city--div">
                      <div>
                        <div className="number--location">First Name*</div>
                        <input
                          type="text"
                          id="primaryFirstName"
                          name="primaryFirstName"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          className={
                            validation.touched.primaryFirstName &&
                            validation.errors.primaryFirstName
                              ? 'city--row first--input--div is-invalid'
                              : 'city--row first--input--div'
                          }
                        />
                        {validation.touched.primaryFirstName &&
                        validation.errors.primaryFirstName
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.primaryFirstName}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                      <div>
                        <div className="number--location">Last Name*</div>
                        <input
                          type="text"
                          id="primaryLastName"
                          name="primaryLastName"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          className={
                            validation.touched.primaryLastName &&
                            validation.errors.primaryLastName
                              ? 'city--row first--input--div is-invalid'
                              : 'city--row first--input--div'
                          }
                        />
                        {validation.touched.primaryLastName &&
                        validation.errors.primaryLastName
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.primaryLastName}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </div>

                    <div className="main--title">
                      <div className="number--location">Title*</div>
                      <input
                        type="text"
                        id="primaryTitle"
                        name="primaryTitle"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        className={
                          validation.touched.primaryTitle &&
                          validation.errors.primaryTitle
                            ? 'company_input is-invalid'
                            : 'company_input'
                        }
                      />
                      {validation.touched.primaryTitle &&
                      validation.errors.primaryTitle
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.primaryTitle}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                    <div className="main-email">
                      <div className="number--location">Email Address*</div>
                      <input
                        type="email"
                        id="primaryEmail"
                        name="primaryEmail"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        className={
                          validation.touched.primaryEmail &&
                          validation.errors.primaryEmail
                            ? 'company_input is-invalid'
                            : 'company_input'
                        }
                      />
                      {validation.touched.primaryEmail &&
                      validation.errors.primaryEmail
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.primaryEmail}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                    <div className="main-phone">
                      <div className="number--location">Phone Number*</div>
                      <input
                        type="text"
                        name="primaryPhone"
                        id="primaryPhone"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        className={
                          validation.touched.primaryPhone &&
                          validation.errors.primaryPhone
                            ? 'company_input is-invalid'
                            : 'company_input'
                        }
                      />
                      {validation.touched.primaryPhone &&
                      validation.errors.primaryPhone
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.primaryPhone}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                  </div>
                  <div className="owner-primary-div owner--first--div ">
                    <div className="owner-primary">Alternate</div>
                    <div className="city--div">
                      <div>
                        <div className="number--location">First Name*</div>
                        <input
                          type="text"
                          id="alternateFirstName"
                          name="alternateFirstName"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          className={
                            validation.touched.alternateFirstName &&
                            validation.errors.alternateFirstName
                              ? 'city--row first--input--div is-invalid'
                              : 'city--row first--input--div'
                          }
                        />
                        {validation.touched.alternateFirstName &&
                        validation.errors.alternateFirstName
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.alternateFirstName}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                      <div>
                        <div className="number--location">Last Name*</div>
                        <input
                          type="text"
                          id="alternateLastName"
                          name="alternateLastName"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          className={
                            validation.touched.alternateLastName &&
                            validation.errors.alternateLastName
                              ? 'city--row first--input--div is-invalid'
                              : 'city--row first--input--div'
                          }
                        />
                        {validation.touched.alternateLastName &&
                        validation.errors.alternateLastName
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.alternateLastName}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </div>

                    <div className="main--title">
                      <div className="number--location">Title*</div>
                      <input
                        type="text"
                        id="alternateTitle"
                        name="alternateTitle"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        className={
                          validation.touched.alternateTitle &&
                          validation.errors.alternateTitle
                            ? 'company_input is-invalid'
                            : 'company_input'
                        }
                      />
                      {validation.touched.alternateTitle &&
                      validation.errors.alternateTitle
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.alternateTitle}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                    <div className="main-email">
                      <div className="number--location">Email Address*</div>
                      <input
                        type="text"
                        id="alternateEmail"
                        name="alternateEmail"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        className={
                          validation.touched.alternateEmail &&
                          validation.errors.alternateEmail
                            ? 'company_input is-invalid'
                            : 'company_input'
                        }
                      />
                      {validation.touched.alternateEmail &&
                      validation.errors.alternateEmail
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.alternateEmail}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                    <div className="main-phone">
                      <div className="number--location">Phone Number*</div>
                      <input
                        type="text"
                        id="alternatePhone"
                        name="alternatePhone"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        // value={formData.alternatePhone}
                        // onChange={(e) => {
                        //     if (isNaN(e.target.value)) { return; }
                        //     if (e.target.value.length > 10) { return; }
                        //     inputFieldsHandler(e)
                        // }}
                        className={
                          validation.touched.alternatePhone &&
                          validation.errors.alternatePhone
                            ? 'company_input is-invalid'
                            : 'company_input'
                        }
                      />
                      {validation.touched.alternatePhone &&
                      validation.errors.alternatePhone
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.alternatePhone}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                  </div>
                </div>
                <div className="contact--div">
                  <div className="owner-primary-div owner--first--div account-div">
                    <div className="owner-primary">Accounting</div>
                    <div className="city--div">
                      <div>
                        <div className="number--location">First Name*</div>
                        <input
                          type="text"
                          id="accountingFirstName"
                          name="accountingFirstName"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          className={
                            validation.touched.accountingFirstName &&
                            validation.errors.accountingFirstName
                              ? 'city--row first--input--div is-invalid'
                              : 'city--row first--input--div'
                          }
                        />
                        {validation.touched.accountingFirstName &&
                        validation.errors.accountingFirstName
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.accountingFirstName}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                      <div>
                        <div className="number--location">Last Name*</div>
                        <input
                          type="text"
                          id="accountingLastName"
                          name="accountingLastName"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          className={
                            validation.touched.accountingLastName &&
                            validation.errors.accountingLastName
                              ? 'city--row first--input--div is-invalid'
                              : 'city--row first--input--div'
                          }
                        />
                        {validation.touched.accountingLastName &&
                        validation.errors.accountingLastName
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.accountingLastName}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </div>

                    <div className="main--title">
                      <div className="number--location">Title*</div>
                      <input
                        type="text"
                        id="accountingTitle"
                        name="accountingTitle"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        className={
                          validation.touched.accountingTitle &&
                          validation.errors.accountingTitle
                            ? 'company_input is-invalid'
                            : 'company_input'
                        }
                      />
                      {validation.touched.accountingTitle &&
                      validation.errors.accountingTitle
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.accountingTitle}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                    <div className="main-email">
                      <div className="number--location">Email Address*</div>
                      <input
                        type="text"
                        id="accountingEmail"
                        name="accountingEmail"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        className={
                          validation.touched.accountingEmail &&
                          validation.errors.accountingEmail
                            ? 'company_input is-invalid'
                            : 'company_input'
                        }
                      />
                      {validation.touched.accountingEmail &&
                      validation.errors.accountingEmail
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.accountingEmail}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                    <div className="main-phone">
                      <div className="number--location">Phone Number*</div>
                      <input
                        type="text"
                        id="accountingPhone"
                        name="accountingPhone"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        // value={formData.alternatePhone}
                        // onChange={(e) => {
                        //     if (isNaN(e.target.value)) { return; }
                        //     if (e.target.value.length > 10) { return; }
                        //     inputFieldsHandler(e)
                        // }}
                        className={
                          validation.touched.accountingPhone &&
                          validation.errors.accountingPhone
                            ? 'company_input is-invalid'
                            : 'company_input'
                        }
                      />
                      {validation.touched.accountingPhone &&
                      validation.errors.accountingPhone
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.accountingPhone}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                  </div>
                </div>

                <div className="continue--div">
                  <button
                    type="submit"
                    className="continue--btn"
                    onClick={validation.handleSubmit}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Form>
        ) : (
          <div>
            <div className="mainn">
              <div className="mainn-div">
                <div className="ql">
                  <div style={{ display: 'flex' }}>
                    <div className="apply_div create-ql-supply">
                      <div className="create-your-heading">
                        {' '}
                        <img src={back} /> Apply for WD Account
                      </div>
                    </div>
                    <div className="create-account">Create Account</div>
                  </div>
                  <button className="step__btn">
                    Step 2<span className="second"> / 2</span>
                  </button>
                </div>
                <Form>
                  <hr className="main--wd-hr" />
                  {locationsArray.map((item, i) => {
                    return (
                      <div key={i}>
                        <div className="wd-location-div">
                          <div className="wd-warehouse-location">
                            Warehouse Location - 1
                          </div>
                          {item.open
                            ? (
                            <i
                              className="fa fa-minus"
                              onClick={(e) => handleOpen(i, e)}
                            ></i>
                              )
                            : (
                            <i
                              className="fa fa-plus"
                              onClick={(e) => handleOpen(i, e)}
                            ></i>
                              )}
                        </div>
                        {item.open && (
                          <>
                            <div className="location-title-div">
                              <div className="number--location">
                                Location Name / Title*
                                <span className="info-icon">
                                  <img
                                    src={information}
                                    className="info-icon"
                                  />
                                  <span className="tooltiptext">
                                    Lorem Ipsum is simply dummy text of the
                                    printing and type setting industry.Lorem
                                    Ipsum
                                  </span>
                                </span>
                              </div>
                              <input
                                type="text"
                                name="locationName"
                                id={'locationName-' + i}
                                onChange={(e) => {
                                  handleLocations(e, i)
                                }}
                                defaultValue={locationsArray[i].locationName}
                                className="location-name-input"
                              />
                              <div className="error">
                                {locationsErrorArray[i].locationName}
                              </div>
                            </div>
                            <div className="contact--div">
                              <div className="owner--primary--div owner--first--div">
                                <div className="owner-primary">
                                  Shipping Address
                                </div>
                                <div className="">
                                  <div className="number--location">
                                    Street*
                                  </div>
                                  <input
                                    type="text"
                                    id={'shippingStreet-' + i}
                                    name="shippingStreet"
                                    onChange={(e) => {
                                      handleLocations(e, i)
                                    }}
                                    defaultValue={
                                      locationsArray[i].shippingStreet
                                    }
                                    className="company_input"
                                  />
                                  <div className="error">
                                    {locationsErrorArray[i].shippingStreet}
                                  </div>
                                </div>

                                <div className="main--title">
                                  <div className="number--location">State*</div>
                                  <select
                                    id={'shippingStateId-' + i}
                                    name="shippingStateId"
                                    onChange={(e) => {
                                      handleLocations(e, i)
                                    }}
                                    ref={stateRef}
                                    onClick={(e) =>
                                      getCities(stateRef.current.value, e)
                                    }
                                    defaultValue={
                                      locationsArray[i].shippingStateId
                                    }
                                    className="company_input"
                                  >
                                    <option value="null">Select</option>
                                    {states.map((data, i) => {
                                      return (
                                        <option
                                          key={i}
                                          value={parseInt(data.id)}
                                        >
                                          {data.name}
                                        </option>
                                      )
                                    })}
                                  </select>
                                  <div className="error">
                                    {locationsErrorArray[i].shippingStateId}
                                  </div>
                                </div>
                                <div className="main-email">
                                  <div className="number--location">City*</div>
                                  <select
                                    id={'shippingCityId-' + i}
                                    name="shippingCityId"
                                    onChange={(e) => {
                                      handleLocations(e, i)
                                    }}
                                    defaultValue={
                                      locationsArray[i].shippingCityId
                                    }
                                    className="company_input"
                                  >
                                    <option value="null">Select</option>
                                    {cities.map((data, i) => {
                                      return (
                                        <option key={i} value={data.id}>
                                          {data.name}
                                        </option>
                                      )
                                    })}
                                  </select>
                                  <div className="error">
                                    {locationsErrorArray[i].shippingCityId}
                                  </div>
                                </div>
                                <div className="main-phone">
                                  <div className="number--location">
                                    Zip Code*
                                  </div>
                                  <input
                                    type="text"
                                    name="shippingZipCode"
                                    id={'shippingZipCode-' + i}
                                    onChange={(e) => {
                                      handleLocations(e, i)
                                    }}
                                    defaultValue={
                                      locationsArray[i].shippingZipCode
                                    }
                                    className="company_input"
                                  />
                                  <div className="error">
                                    {locationsErrorArray[i].shippingZipCode}
                                  </div>
                                </div>
                              </div>
                              <div className="owner-primary-div owner--first--div ">
                                <div className="owner-primary">
                                  Contact Information
                                </div>
                                <div className="city--div">
                                  <div>
                                    <div className="number--location">
                                      First Name*
                                    </div>
                                    <input
                                      type="text"
                                      id={'contactFirstName-' + i}
                                      name="contactFirstName"
                                      onChange={(e) => {
                                        handleLocations(e, i)
                                      }}
                                      defaultValue={
                                        locationsArray[i].contactFirstName
                                      }
                                      className="city--row first--input--div"
                                    />
                                    <div className="error">
                                      {locationsErrorArray[i].contactFirstName}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="number--location">
                                      Last Name*
                                    </div>
                                    <input
                                      type="text"
                                      id={'contactLastName-' + i}
                                      name="contactLastName"
                                      onChange={(e) => {
                                        handleLocations(e, i)
                                      }}
                                      defaultValue={
                                        locationsArray[i].contactLastName
                                      }
                                      className="city--row first--input--div"
                                    />
                                    <div className="error">
                                      {locationsErrorArray[i].contactLastName}
                                    </div>
                                  </div>
                                </div>
                                <div className="main-email">
                                  <div className="number--location">
                                    Email Address*
                                  </div>
                                  <input
                                    type="text"
                                    id={'contactEmail-' + i}
                                    name="contactEmail"
                                    onChange={(e) => {
                                      handleLocations(e, i)
                                    }}
                                    defaultValue={
                                      locationsArray[i].contactEmail
                                    }
                                    className="company_input"
                                  />
                                  <div className="error">
                                    {locationsErrorArray[i].contactEmail}
                                  </div>
                                </div>
                                <div className="main-phone">
                                  <div className="number--location">
                                    Mobile Number*
                                  </div>
                                  <input
                                    type="text"
                                    id={'contactPhone-' + i}
                                    name="contactPhone"
                                    // value={formData.alternatePhone}
                                    // onChange={(e) => {
                                    //     if (isNaN(e.target.value)) { return; }
                                    //     if (e.target.value.length > 10) { return; }
                                    //     inputFieldsHandler(e)
                                    // }}
                                    defaultValue={
                                      locationsArray[i].contactPhone
                                    }
                                    onChange={(e) => {
                                      handleLocations(e, i)
                                    }}
                                    className="company_input"
                                  />
                                  <div className="error">
                                    {locationsErrorArray[i].contactPhone}
                                  </div>
                                </div>

                                <div className="main--title">
                                  <div className="number--location">Title*</div>
                                  <input
                                    type="text"
                                    id={'contactTitle-' + i}
                                    name="contactTitle"
                                    onChange={(e) => {
                                      handleLocations(e, i)
                                    }}
                                    defaultValue={
                                      locationsArray[i].contactTitle
                                    }
                                    className="company_input"
                                  />
                                  <div className="error">
                                    {locationsErrorArray[i].contactTitle}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="wd-done-div">
                              <div className="set-primart-location">
                                <input
                                  type="checkbox"
                                  name="isPrimaryLocation"
                                  id="isPrimaryLocation"
                                  className="check"
                                  defaultChecked={
                                    locationsArray[i].isPrimaryLocation
                                  }
                                  onClick={(e) => handleSetPrimary(i, e)}
                                />
                                <label className="set-primary mb-0">
                                  Set as Primary Warehouse
                                </label>
                              </div>
                              <button
                                className="wd-done-btn"
                                onClick={(e) => handleCheck(i, e)}
                              >
                                Done
                              </button>
                            </div>
                          </>
                        )}
                        <hr />
                      </div>
                    )
                  })}

                  <div className="please-contact-qls">
                    <span className="font-weight-bold">Note:</span>{' '}
                    {termsError
                      ? 'Please Accept Terms and Conditions'
                      : 'If you need to add a significant number of allLocations, please contact QLS customer support for assistance'}
                  </div>
                  <div
                    className="wd-add-location"
                    onClick={(e) => handleAdd(e)}
                  >
                    <i className="fas fa-plus wd-add-icon"></i>
                    Add More Location
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      id="acceptTerms"
                      type="checkbox"
                      className="check"
                      defaultChecked={acceptTerms}
                      onChange={(e) => {
                        setAcceptTerms(e.target.checked)
                        setTermsError(false)
                      }}
                    />
                    <label
                      htmlFor="acceptTerms"
                      className="term-condition mb-0"
                    >
                      I agree to the{' '}
                      <span className="term-span">terms and conditions.</span>
                    </label>
                  </div>
                  <div className="register--row">
                    <button
                      type="submit"
                      className="register--btn"
                      onClick={(e) => getAllLocations(e)}
                    >
                      Register
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
      </div>
      <FooterStatic />
    </React.Fragment>
  )
}

export default NewRegister
