/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Input, Label, Row, Form, Button } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { useHistory, Link, useParams } from 'react-router-dom'
// import { Editor } from 'react-draft-wysiwyg'
import Dropzone from 'react-dropzone'
import { GetData, PutData, PostData, PatchData } from 'apiHandler'
import Loader from '../../components/Loader'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { Editor } from '@tinymce/tinymce-react'

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}

function ContactUs() {
  const history = useHistory()
  const { id } = useParams()
  const [loader, setLoader] = useState(false)
  const [editorContent, setEditorContent] = useState()
  const [validation, setValidation] = useState({
    heading1: '',
    heading2: '',
    subHeading: '',
    description: ''
  })
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [contactData, setContactData] = useState({})


  useEffect(() => {
    getContact()
  }, [])

  function handleChange(type, e) {
    e.preventDefault()
    setContactData({ ...contactData, [e.target.name]: e.target.value })
  }

  function handleSubmit(e) {
    e.preventDefault()
    conatctDataSend()
  }

  const conatctDataSend = () => {
    setLoader(true)
    const formData = {
      ...contactData
    }
    PutData('admin/contactUsSetting', formData).then((response) => {
      if (response.status === true) {
        toastr.success(response.message, 'Sent')
        setLoader(false)
        getContact()
      } else {
        toastr.error(response.message, 'Error')
        setLoader(false)
      }
    })
  }

  const getContact = () => {
    setLoader(true)
    GetData('admin/contactUsSetting').then((response) => {
      if (response.status === true) {
        setContactData(response.result)
        setLoader(false)
      }
    })
  }

  return (
    <>
      <Loader show={loader}></Loader>
      <div className="page-content">
        <Container fluid={true} className="homepage-wrap" >
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <Breadcrumbs
              title="UI Elements"
              breadcrumbItem={'Contact Us'}
              icon={
                <></>
              }
            />
            {/* <a
              href={`${process.env.REACT_APP_WEB_URL}/service-opportunities/${serviceData.slug}`}
              target={'_blank'} rel="noreferrer"
            >
              {status === 1 && 'View on Website'}
            </a> */}
          </div>
          <Row className='mt-3'>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label">
                      <b>Heading 1</b>
                    </Label>
                    <Input
                      placeholder="Enter Heading 1"
                      type="text"
                      id="heading1"
                      name="heading1"
                      className="input-border"
                      value={contactData.heading1}
                      maxLength={19}
                      onChange={(e) => handleChange('', e)}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">
                      <b>Heading 2</b>
                    </Label>
                    <Input
                      placeholder="Enter Heading 2"
                      type="text"
                      id="heading2"
                      name="heading2"
                      className="input-border"
                      value={contactData.heading2}
                      maxLength={33}
                      onChange={(e) => handleChange('', e)}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">
                      <b>Sub Heading</b>
                    </Label>
                    <Input
                      placeholder="Enter Sub Heading"
                      type="text"
                      id="subHeading"
                      name="subHeading"
                      className="input-border"
                      value={contactData.subHeading}
                      maxLength={150}
                      onChange={(e) => handleChange('', e)}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">
                      <b>Description</b>
                    </Label>
                    <Input
                      placeholder="Enter Description"
                      type="text"
                      id="description"
                      name="description"
                      className="input-border"
                      value={contactData.description}
                      maxLength={70}
                      onChange={(e) => handleChange('', e)}
                    />
                  </div>
                </CardBody>
              </Card>
              <Button
                className="btn btn-add-new mb-4 ms-0"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default ContactUs
