import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import PropTypes from "prop-types";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { GetData, PostData } from "apiHandler";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";

const AssignBrandsCategories = ({ display }) => {
  const params = useParams();

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [categorySubcategory, setCategorySubcategory] = useState([]);
  const [isBrandModalOpen, setIsBrandModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [assignedListData, setAssignedListData] = useState({});
  const [loading, setLoading] = useState(false);

  const getBrandsList = () => {
    PostData("admin/getFilteredVcdbBrands").then((response) => {
      if (response.status === true) {
        setBrandList(response?.result);
      } else {
        toastr.error(response?.message, "Error");
      }
    });
  };

  //------------------------Function to get the category & subcategory based on the brand---------------------
  const getFilteredCategorySubCat = (brandId) => {
    setLoading(true);
    GetData(`admin/getCategoriesByBrand?BrandID=${brandId}`).then(
      (response) => {
        setLoading(false);
        if (response.status === true) {
          setCategorySubcategory(response?.result);
        } else {
          toastr.error(response?.message, "Error");
        }
      }
    );
  };

  const getAssignedList = () => {
    setLoading(true);
    GetData(`admin/customerCategories/${params.id}`).then((response) => {
      setLoading(false);
      if (response.status === true) {
        setAssignedListData(response?.result);
      } else {
        toastr.error(response?.message, "Error");
      }
    });
  };

  useEffect(() => {
    if (display) {
      getBrandsList();
      getAssignedList();
    }
  }, [display]);

  const handleAddBrand = () => {
    setIsBrandModalOpen(true);
  };

  const handleBrandSelect = (brand) => {
    const newBrandEntry = {
      brandId: brand.BrandID,
      brandName: brand.BrandName,
      categoryID: null,
      children: [],
    };
    setAssignedListData((prevData) => ({
      ...prevData,
      [brand.BrandID]: [newBrandEntry],
    }));
    setIsBrandModalOpen(false);
  };

  const handleAssignCategory = (brand) => {
    getFilteredCategorySubCat(brand?.brandId);
    setSelectedBrand(brand);
    const selectedBrandData = assignedListData[brand.brandId];
    if (selectedBrandData?.[0]?.categoryID) {
      setSelectedCategories(
        selectedBrandData.map((data) => ({
          categoryID: data.categoryID,
          categoryName: data.categoryName,
          children: data.children,
        }))
      );
      setSelectedSubCategories(
        selectedBrandData.reduce((acc, data) => acc.concat(data.children), [])
      );
    } else {
      setSelectedCategories([]);
      setSelectedSubCategories([]);
    }
    setIsCategoryModalOpen(true);
  };

  // ----------------function to handle category and subcategory change-------------
  const handleCategoryChange = (e, category) => {
    const isChecked = e.target.checked;
    const updatedCategories = isChecked
      ? [...selectedCategories, category]
      : selectedCategories.filter(
          (cat) => cat.categoryID !== category.categoryID
        );
    setSelectedCategories(updatedCategories);

    if (!isChecked) {
      setSelectedSubCategories(
        selectedSubCategories.filter(
          (sub) =>
            !category.children.some(
              (child) => child.subcategoryID === sub.subcategoryID
            )
        )
      );
    } else {
      const updatedSubCategories = [
        ...selectedSubCategories,
        ...category.children.filter(
          (child) =>
            !selectedSubCategories.some(
              (sub) => sub.subcategoryID === child.subcategoryID
            )
        ),
      ];
      setSelectedSubCategories(updatedSubCategories);
    }
  };

  const handleSubCategoryChange = (e, subcategory) => {
    const isChecked = e.target.checked;
    const updatedSubCategories = isChecked
      ? [...selectedSubCategories, subcategory]
      : selectedSubCategories.filter(
          (sub) => sub.subcategoryID !== subcategory.subcategoryID
        );
    setSelectedSubCategories(updatedSubCategories);

    if (isChecked) {
      const category = categorySubcategory.find((cat) =>
        cat.children.some(
          (child) => child.subcategoryID === subcategory.subcategoryID
        )
      );
      // if (
      //   category &&
      //   !selectedCategories.some(
      //     (cat) => cat.categoryID === category.categoryID
      //   )
      // ) {
      //   setSelectedCategories([...selectedCategories, category]);
      // }

      if (
        category &&
        !selectedCategories.some(
          (cat) => cat.categoryID === category.categoryID
        )
      ) {
        setSelectedCategories([...selectedCategories, category]);
      } else {
        setSelectedCategories((prevSelectedCategories) =>
          prevSelectedCategories.map((cat) => {
            if (cat.categoryID === category.categoryID) {
              if (cat.children.length !== category.children.length) {
                return {
                  ...cat,
                  children: category.children,
                };
              }
            }
            return cat;
          })
        );
      }
    }
  };

  // ----------------------Function to Add update category and subcategory-----------------
  const handleSaveCategory = () => {
    const formattedCategories = selectedCategories.map((category) => ({
      categoryID: category.categoryID,
      children: selectedSubCategories
        .filter((sub) =>
          category.children.some(
            (child) => child.subcategoryID === sub.subcategoryID
          )
        )
        .map((sub) => sub.subcategoryID),
    }));

    const payload = {
      BrandID: selectedBrand.brandId,
      categories: formattedCategories,
    };
    setLoading(true);
    PostData(`admin/updateCustomerCategory/${params.id}`, payload).then(
      (response) => {
        setLoading(false);
        if (response.status === true) {
          toastr.success(response?.message, "Success");
          // getAssignedList()
          setAssignedListData((prevData) => ({
            ...prevData,
            [selectedBrand.brandId]: formattedCategories.map((category) => ({
              brandId: selectedBrand.brandId,
              brandName: selectedBrand.brandName,
              categoryID: category.categoryID,
              categoryName: categorySubcategory.find(
                (cat) => cat.categoryID === category.categoryID
              )?.categoryName,
              children: category.children.map((subCategoryId) => ({
                subcategoryID: subCategoryId,
                subCategoryName: categorySubcategory
                  .find((cat) => cat.categoryID === category.categoryID)
                  .children.find((sub) => sub.subcategoryID === subCategoryId)
                  .subCategoryName,
              })),
            })),
          }));
          setIsCategoryModalOpen(false);
        } else {
          toastr.error(response?.message, "Error");
        }
      }
    );
  };

  const handleDeleteBrand = (brand) => {
    let payload = {
      BrandID: brand?.brandId,
    };
    setLoading(true);
    PostData(`admin/deleteCustomerCategory/${params.id}`, payload).then(
      (response) => {
        setLoading(false);

        if (response.status === true) {
          setAssignedListData((prevData) => {
            const updatedData = { ...prevData };
            delete updatedData[brand.brandId];
            return updatedData;
          });
          toastr.success(response?.message, "Success");
        } else {
          toastr.error(response?.message, "Error");
        }
      }
    );
  };

  const renderRow = (brandId, listings) => (
    <tr key={brandId}>
      <td>{listings[0].brandName}</td>
      <td>
        {listings.map((listing) => (
          <div key={`${listing.categoryID}-${listing.brandId}`}>
            <strong>{listing.categoryName}</strong>
            <ul>
              {listing.children.map((sub) => (
                <li key={sub.subcategoryID}>{sub.subCategoryName}</li>
              ))}
            </ul>
          </div>
        ))}
      </td>
      <td>
        <Button
          color="primary"
          size="sm"
          onClick={() => handleAssignCategory(listings[0])}
        >
          {listings[0].categoryID ? "Edit" : "Assign"}
        </Button>{" "}
        <Button
          color="danger"
          size="sm"
          onClick={() => handleDeleteBrand(listings[0])}
        >
          Delete
        </Button>
      </td>
    </tr>
  );

  useEffect(() => {
    categorySubcategory.forEach((category) => {
      const categoryCheckbox = document.getElementById(
        `category-${category.categoryID}`
      );
      if (categoryCheckbox) {
        categoryCheckbox.checked = selectedCategories.some(
          (cat) => cat.categoryID === category.categoryID
        );
      }
      category.children.forEach((subcategory) => {
        const subcategoryCheckbox = document.getElementById(
          `subCategory-${subcategory.subcategoryID}`
        );
        if (subcategoryCheckbox) {
          subcategoryCheckbox.checked = selectedSubCategories.some(
            (sub) => sub.subcategoryID === subcategory.subcategoryID
          );
        }
      });
    });
  }, [categorySubcategory, selectedCategories, selectedSubCategories]);

  return (
    <>
      <div className="container">
        <Loader show={loading} />
        <Button color="primary" onClick={handleAddBrand} className="mt-3">
          Add Brand
        </Button>
        <Table className="mt-3">
          <thead>
            <tr>
              <th>Brand</th>
              <th>Assigned Categories/Subcategories</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(assignedListData).length ? (
              Object.entries(assignedListData).map(([brandId, listings]) =>
                renderRow(brandId, listings)
              )
            ) : (
              <tr>
                <td colSpan={12}>Data not found...</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Modal
          isOpen={isBrandModalOpen}
          toggle={() => setIsBrandModalOpen(false)}
          fade
          centered
        >
          <ModalHeader toggle={() => setIsBrandModalOpen(false)}>
            Select a Brand
          </ModalHeader>
          <ModalBody>
            {brandList
              .filter(
                (brand) =>
                  !Object.keys(assignedListData).includes(brand.BrandID)
              )
              .map((brand) => (
                <Button
                  key={brand?.BrandID}
                  color="secondary"
                  onClick={() => handleBrandSelect(brand)}
                  className="mb-2 me-2"
                  outline
                >
                  {brand?.BrandName}
                </Button>
              ))}
          </ModalBody>
        </Modal>
      </div>
      <Modal
        isOpen={isCategoryModalOpen}
        toggle={() => setIsCategoryModalOpen(false)}
      >
        <ModalHeader toggle={() => setIsCategoryModalOpen(false)}>
          Assign Category and Subcategory
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>Select Category and Subcategory</Label>
              {categorySubcategory.map((category) => (
                <div key={category.categoryID}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        id={`category-${category.categoryID}`}
                        type="checkbox"
                        onChange={(e) => handleCategoryChange(e, category)}
                        // checked={selectedCategories.some(
                        //   (cat) => cat.categoryID === category.categoryID
                        // )}
                      />
                      {category.categoryName}
                    </Label>
                  </FormGroup>
                  {category.children.map((subcategory) => (
                    <FormGroup
                      check
                      key={subcategory.subcategoryID}
                      className="ms-4"
                    >
                      <Label check>
                        <Input
                          id={`subCategory-${subcategory.subcategoryID}`}
                          type="checkbox"
                          onChange={(e) =>
                            handleSubCategoryChange(e, subcategory)
                          }
                          // checked={selectedSubCategories.some(
                          //   (sub) =>
                          //     sub.subcategoryID === subcategory.subcategoryID
                          // )}
                        />
                        {subcategory.subCategoryName}
                      </Label>
                    </FormGroup>
                  ))}
                </div>
              ))}
            </FormGroup>
          </Form>
          <Button
            color="primary"
            onClick={handleSaveCategory}
            disabled={!selectedCategories.length}
          >
            Save
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AssignBrandsCategories;

AssignBrandsCategories.propTypes = {
  display: PropTypes.any,
};
