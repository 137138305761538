/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Label, Input } from 'reactstrap'
import PropTypes from 'prop-types'
import { PutData } from 'apiHandler'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Loader from '../Loader'

const CallToAction = (props) => {
  const [sectionData, setSectionData] = useState({
    buttonText1: '',
    buttonText2: '',
    buttonText3: '',
    buttonUrl1: '',
    buttonUrl2: '',
    buttonUrl3: ''
  })
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    props.data && setSectionData(props.data)
    console.log(props.data)
  }, [props.data])

  function handleChange (e) {
    e.preventDefault()
    setSectionData({ ...sectionData, [e.target.name]: e.target.value })
  }

  function handleSubmit (e) {
    e.preventDefault()
    setLoader(true)
    PutData('admin/updateHomeSection10', sectionData)
      .then((response) => {
        if (response.status === true) {
          setLoader(false)
          toastr.success(response.message, 'Sent')
        }
      })
      .catch((response) => {
        setLoader(false)
        toastr.error(response.message, 'Error')
      })
  }

  return (
    <React.Fragment>
      <Loader show={loader}></Loader>
      <div className="home-banner-right-wrap">
      <h3 className='mb-3'>Section 1</h3>
        <div className="supply-resource-wrap">
          <div className="banner-fields-box">
            <div className="sr-title-top">
                <div className="mb-2">
              <Label htmlFor="formrow-firstname-Input">Section Text</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter section text"
                defaultValue={sectionData.buttonText1}
                onChange={(e) => handleChange(e)}
                name="buttonText1"
              />
</div>
            <div className="mb-2">
                        <Label htmlFor="formrow-firstname-Input">Section Link (URL)</Label>
                        <Input
                            type="url"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter section link"
                            defaultValue={sectionData.buttonUrl1}
                            onChange={(e) => handleChange(e)}
                            name="buttonUrl1"
                        />
            </div>
            </div>
          </div>
        </div>
        <h3 className='mb-3'>Section 2</h3>
        <div className="supply-resource-wrap">
          <div className="banner-fields-box">
            <div className="sr-title-top">
                <div className="mb-2">
              <Label htmlFor="formrow-firstname-Input">Section Text</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter section text"
                defaultValue={sectionData.buttonText2}
                onChange={(e) => handleChange(e)}
                name="buttonText2"
              />
</div>
            {/* <div className="mb-2">
                        <Label htmlFor="formrow-firstname-Input">Section Link (URL)</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter section link"
                            defaultValue={sectionData.buttonUrl2}
                            onChange={(e) => handleChange(e)}
                            name="buttonUrl2"
                        />
            </div> */}
            </div>
          </div>
        </div>
        <h3 className='mb-3'>Section 3</h3>
        <div className="supply-resource-wrap">
          <div className="banner-fields-box">
            <div className="sr-title-top">
                <div className="mb-2">
              <Label htmlFor="formrow-firstname-Input">Section Text</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter section text"
                defaultValue={sectionData.buttonText3}
                onChange={(e) => handleChange(e)}
                name="buttonText3"
              />
</div>
            <div className="mb-2">
                        <Label htmlFor="formrow-firstname-Input">Section Link (URL)</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter section link"
                            defaultValue={sectionData.buttonUrl3}
                            onChange={(e) => handleChange(e)}
                            name="buttonUrl3"
                        />
            </div>
            </div>
          </div>
        </div>
        <div className="save-btn">
          <button type="submit" onClick={(e) => handleSubmit(e)}>
            Save
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CallToAction

CallToAction.propTypes = {
  data: PropTypes.object
}
