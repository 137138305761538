import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Container, Row, Col, Table, Button, Card } from 'reactstrap'
import Breadcrumb from 'components/Common/Breadcrumb'
import { GetData, PostData } from 'apiHandler'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Loader from '../../../components/Loader'
import moment from 'moment'
import './Process.scss'

const Fulfillment = () => {
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(false)
  const [orderData, setOrderData] = useState(null)
  const [productsData, setProductsData] = useState(null)
  const [shippingAddress, setShippingAddress] = useState(null)
  const [shipProducts, setShipProducts] = useState(null)
  const [step, setStep] = useState(1)
  const [shipmentId, setShipmentId] = useState(null)
  const [vendorOrderId, setVendorOrderId] = useState(null)
  const [pickTicket, setPickTicket] = useState(false)
  const [generateSlip, setGenerateSlip] = useState(false)
  const [shipmentStatus, setShipmentStatus] = useState(null)
  const [drivers, setDrivers] = useState(null)
  const [shipmentHistory, setShipmentHistory] = useState(null)
  const [driverSearch, setDriverSearch] = useState('')
  const [selectedDrivers, setSelectedDrivers] = useState([])
  const [timeStamps, setTimeStamps] = useState({
    step1: '',
    step2: '',
    step3: ''
  })
  const userObj = JSON.parse(localStorage.authUser)
  const history = useHistory()
  const { id, shipId } = useParams()
  const [totalQty, setTotalQty] = useState(0)

  // function to go to products table on back button
  const handlePush = (e) => {
    e.preventDefault()
    history.push('/orders/' + id)
  }

  useEffect(() => {
    if (userObj.roleId !== 3 && userObj.roleId !== 4) {
      history.push('/login')
    }
  }, [])

  useEffect(() => {
    if (productsData) {
      const findQty = productsData.reduce((acc, item) => {
        acc += item.qtyToShip
        return acc
      }, 0)
      setTotalQty(findQty)
    }
  }, [productsData])

  useEffect(() => {
    if (id) {
      if (step === 1) {
        if (productsData === null) {
          fetchOrderData(id)
        }
      }
      if (step === 2) {
        if (shipProducts === null) {
          fetchPackingSlip()
        }
      }
      if (step === 3) {
        if (drivers === null) {
          fetchDrivers()
        }
      }
      if (step === 4) {
        fetchDetails()
      }
    }
  }, [step, id])

  useEffect(() => {
    fetchDrivers()
  }, [driverSearch])

  useEffect(() => {
    if (shipId) {
      fetchShipmentDetails(shipId)
      setShipmentId(shipId)
    }
  }, [shipId])

  useEffect(() => {
    if (shipmentHistory) {
      setStep(parseInt(shipmentHistory[shipmentHistory.length - 1].shipmentStatus) + 1)
      setShipmentStatus(parseInt(shipmentHistory[shipmentHistory.length - 1].shipmentStatus))
      // eslint-disable-next-line array-callback-return
      shipmentHistory.map((ship) => {
        if (ship.shipmentStatus === 0) {
          setPickTicket(true)
          setTimeStamps((prevState) => ({
            ...prevState,
            step1: ship.createdAt
          }))
        }
        if (ship.shipmentStatus === 1) {
          setGenerateSlip(true)
          setTimeStamps((prevState) => ({
            ...prevState,
            step2: ship.createdAt
          }))
        }
        if (ship.shipmentStatus === 2) {
          setTimeStamps((prevState) => ({
            ...prevState,
            step3: ship.createdAt
          }))
        }
      })
    }
  }, [shipmentHistory])

  useEffect(() => {
    if (shipProducts && productsData) {
      const productsArr = [...productsData]
      // eslint-disable-next-line array-callback-return
      shipProducts.map((product, i) => {
        const findIndex = productsArr.findIndex((item) => item.part_id === product.orderProductId)
        productsArr[findIndex].qtyToShip = product.shipmentQuantity
      })
      setProductsData(productsArr)
    }
  }, [shipProducts])

  useEffect(() => {
    if (pickTicket) {
      fetchPackingSlip()
    }
  }, [pickTicket])

  const fetchShipmentDetails = (shipId) => {
    const url = `vendor/getShipmentDetails?shipmentId=${shipId}`
    setLoader(true)
    GetData(url)
      .then((res) => {
        if (res.status) {
          setLoader(false)
          setShipmentHistory(res.result.shipmentHistory)
        } else {
          setLoader(false)
          toastr.error(res.message, 'Error')
        }
      }).catch((err) => {
        setLoader(false)
        toastr.error(err, 'Error')
      })
  }

  const fetchDetails = () => {
    const url = `vendor/getShipmentDetails?shipmentId=${shipmentId}`
    GetData(url)
      .then((res) => {
        if (res.status) {
          setSelectedDrivers([res.result.driverDetails])
          setLoader(false)
        } else {
          setLoader(false)
          toastr.error(res.message, 'Error')
        }
      }).catch((err) => {
        setLoader(false)
        toastr.error(err, 'Error')
      })
  }

  const fetchDrivers = () => {
    const pageSize = 20
    const url = `vendor/getWdDriverList?pageSize=${pageSize}${driverSearch !== '' ? '&search=' + driverSearch : ''}`
    GetData(url)
      .then((res) => {
        if (res.status) {
          setDrivers(res.result.data)
          setLoader(false)
        } else {
          setLoader(false)
          toastr.error(res.message, 'Error')
        }
      }).catch((err) => {
        setLoader(false)
        toastr.error(err, 'Error')
      })
  }

  const fetchPackingSlip = () => {
    setLoader(true)
    const url = `vendor/getPackingSlip?shipmentId=${shipmentId}`
    GetData(url)
      .then((res) => {
        if (res.status) {
          setShippingAddress(res.result.vendorOrder.parentOrder)
          setShipProducts(res.result.shipmentProducts)
          setVendorOrderId(res.result.vendorOrderId)
          setLoader(false)
        } else {
          setLoader(false)
          toastr.error(res.message, 'Error')
        }
      }).catch((err) => {
        setLoader(false)
        toastr.error(err, 'Error')
      })
  }

  const fetchOrderData = (id) => {
    setLoader(true)
    const url = `vendor/vendorOrderProductlisting?orderId=${id}`
    GetData(url)
      .then((res) => {
        if (res.status) {
          setOrderData({
            orderDate: res.result.orderDate,
            orderId: res.result.orderId,
            orderStatus: res.result.orderStatus,
            parentOrder: res.result.parentOrder
          })
          const productsArr = res.result.vendorOrderProducts
          // eslint-disable-next-line array-callback-return
          productsArr.map((product) => {
            product.qtyToShip = product.orderedQuantity
          })
          setProductsData(productsArr)
          setLoader(false)
        } else {
          setLoader(false)
          toastr.error(res.message, 'Error')
        }
      }).catch((err) => {
        setLoader(false)
        toastr.error(err, 'Error')
      })
  }

  const handlePrintTicket = (e) => {
    e.preventDefault()
    setLoader(true)
    const url = 'vendor/createShipment'

    const products = []
    // eslint-disable-next-line array-callback-return
    productsData.map((product) => {
      const productObj = { productId: product.part_id, quantity: product.qtyToShip }
      products.push(productObj)
    })

    const body = {
      vendorOrderId: orderData.orderId,
      products
    }

    PostData(url, body)
      .then((res) => {
        if (res.status) {
          setLoader(false)
          if (res.result.shipmentStatus === 'Pending') {
            history.push(`/orders/${id}`)
          } else {
            setShipmentId(res.result.id)
            toastr.success(res.message, 'Success')
            setPickTicket(true)
            handleShipmentStatus(res.result.id, 0)
          }
        } else {
          setLoader(false)
          toastr.error(res.message, 'Error')
        }
      })
      .catch((err) => {
        setLoader(false)
        toastr.error(err, 'Error')
      })
  }

  const handlePackingSlip = () => {
    setGenerateSlip(true)
    handleShipmentStatus(shipmentId, 1)
  }

  const handleShipmentStatus = (shipId, status) => {
    const url = 'vendor/updateShipmentStatus'

    const body = {
      shipmentId: shipId,
      shipmentStatus: status
    }
    setLoader(true)
    PostData(url, body)
      .then((res) => {
        if (res.status) {
          setLoader(false)
          toastr.success(res.message, 'Success')
          setShipmentStatus(1)
          if (step === 1) {
            setTimeStamps({ ...timeStamps, step1: res.result.createdAt })
          }
          if (step === 2) {
            setTimeStamps({ ...timeStamps, step2: res.result.createdAt })
          }
          if (step === 3) {
            setTimeStamps({ ...timeStamps, step3: res.result.createdAt })
          }
        } else {
          setLoader(false)
          toastr.error(res.message, 'Error')
        }
      })
      .catch((err) => {
        setLoader(false)
        toastr.error(err, 'Error')
      })
  }

  const handleStep = (e) => {
    e.preventDefault()
    if (step === 1) {
      setStep(2)
    } else if (step === 2) {
      setStep(3)
    } else if (step === 3) {
      setStep(4)
    } else if (step === 4) {
      history.push(`/orders/${id}`)
    }
  }

  const handleBack = (e) => {
    e.preventDefault()
    if (step === 2) {
      setStep(1)
    } else if (step === 3) {
      setStep(2)
    } else if (step === 4) {
      setStep(3)
    }
  }

  const handleSelectDriver = (e, driver) => {
    e.preventDefault()
    const url = 'vendor/assignDriver'

    const body = {
      vendorOrderId,
      driverId: driver,
      shipmentId
    }
    setLoader(true)
    PostData(url, body)
      .then((res) => {
        if (res.status) {
          setLoader(false)
          toastr.success(res.message, 'Success')
          setSelectedDrivers([res.result])
          handleShipmentStatus(shipmentId, 2)
        } else {
          setLoader(false)
          toastr.error(res.message, 'Error')
        }
      })
      .catch((err) => {
        setLoader(false)
        toastr.error(err, 'Error')
      })
  }

  const handleRemoveDriver = (e, driver) => {
    const url = 'vendor/removeDriver'

    const body = {
      vendorOrderId,
      driverId: driver
    }
    setLoader(true)
    PostData(url, body)
      .then((res) => {
        if (res.status) {
          setLoader(false)
          toastr.success(res.message, 'Success')
          const filteredDriver = selectedDrivers.filter((item) => {
            return item.id !== driver
          })
          setSelectedDrivers(filteredDriver)
        } else {
          setLoader(false)
          toastr.error(res.message, 'Error')
        }
      })
      .catch((err) => {
        setLoader(false)
        toastr.error(err, 'Error')
      })
  }

  const handleMinusQuantity = (e, index) => {
    console.log('quantity is ', productsData[index].qtyToShip - 1)
    if (productsData[index].qtyToShip === 0) {
      toastr.error('Quantity to ship can\'t be less than 0', 'Error')
    } else {
      const productsArr = [...productsData]
      productsArr[index].qtyToShip -= 1
      setProductsData(productsArr)
    }
  }

  const handleAddQuantity = (e, index) => {
    if (productsData[index].qtyToShip < productsData[index].orderedQuantity) {
      const productsArr = [...productsData]
      productsArr[index].qtyToShip += 1
      setProductsData(productsArr)
    } else {
      toastr.error('Quantity to ship can\'t be greater than Quantity Ordered', 'Error')
    }
  }

  const handleQuantity = (e, index) => {
    if (e.target.value < 0) {
      toastr.error('Quantity to ship can\'t be less than 0', 'Error')
    } else if (e.target.value > productsData[index].orderedQuantity) {
      toastr.error('Quantity to ship can\'t be greater than Quantity Ordered', 'Error')
    } else {
      const productsArr = [...productsData]
      productsArr[index].qtyToShip = e.target.value
      setProductsData(productsArr)
    }
  }

  const handlePdf = (e, page) => {
    if (page === 'pickTicket') {
      window.open(`/document/${shipmentId}/${page}`, '_blank')
    } else {
      window.open(`/document/${shipmentId}/${page}`, '_blank')
    }
  }

  const handleSearch = (e) => {
    setDriverSearch(e.target.value)
  }

  return (
      <>
      <Loader show={loader} />
      <div className="page-content">
      <Container fluid={true}>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb
            breadcrumbItem={`${orderData?.parentOrder?.orderNumber} - Fulfillment Process` || '--'}
            icon={
              <i
                className="bx bx-arrow-back me-3"
                onClick={handlePush}
              ></i>
            }
          />
          <span>
            Shipment Number: <b>{orderData?.shipmentId}</b>
          </span>
        </div>
      </Container>
      <Container fluid={true} className='fulfillment-container'>
        <Row className='fulfillment-row'>
          <Col lg={12} style={ { padding: 0 } }>
          <Card>
           <Row className='steps'>
             <Col lg={3} className={`step ${step === 1 && 'active'}`}>
              <div className='d-flex align-items-center'>
                {
                  step === 1 ? <div className='number'>1</div> : <div className='tick'><i className='bx bx-comment-check'></i></div>
                }
                <div className='desc ms-3'>Generate Pick Ticket{
                  timeStamps.step1 !== '' && <p className='pick-time'>{moment(timeStamps.step1).format('Do MMMM YYYY, h:mm A')}</p>
                }</div>
              </div>
             </Col>
             <Col lg={3} className={`step ${step === 2 && 'active'}`}>
              <div className='d-flex align-items-center'>
              {
                  (step !== 3 && step !== 4) ? <div className='number'>2</div> : <div className='tick'><i className='bx bx-comment-check'></i></div>
                }
                <div className='desc ms-3'>Create Packing Slip{
                  timeStamps.step2 !== '' && <p className='pick-time'>{moment(timeStamps.step2).format('Do MMMM YYYY, h:mm A')}</p>
                }</div>
              </div>
             </Col>
             <Col lg={3} className={`step ${step === 3 && 'active'}`}>
              <div className='d-flex align-items-center'>
              {
                  (step !== 4) ? <div className='number'>3</div> : <div className='tick'><i className='bx bx-comment-check'></i></div>
                }
                <div className='desc ms-3'>Assigning Driver{
                  timeStamps.step3 !== '' && <p className='pick-time'>{moment(timeStamps.step3).format('Do MMMM YYYY, h:mm A')}</p>
                }</div>
              </div>
             </Col>
             <Col lg={3} className={`step ${step === 4 && 'active'}`}>
              <div className='d-flex align-items-center'>
                <div className='number'>4</div>
                <div className='desc ms-3'>Ship the Order</div>
              </div>
             </Col>
           </Row>
           </Card>
          </Col>
          {
            step === 1 && <Col lg={12} className="details-container">
            <Table bordered>
              <thead>
                <tr>
                  <th colSpan={1}>Part Number</th>
                  <th colSpan={1}>Brand</th>
                  <th colSpan={1}>Type</th>
                  <th colSpan={1} style={{ textAlign: 'center' }}>Qty to Ship</th>
                  <th colSpan={1} style={{ textAlign: 'center' }}>Qty Ordered</th>
                  <th colSpan={3} style={{ textAlign: 'center' }}></th>
                </tr>
              </thead>
              <tbody>
                  {
                    productsData && productsData.map((product, key) => {
                      return (
                        <tr key={key}>
                          <td className='part-no' colSpan={1}>{product.vendorOrderProductData.partNumber}</td>
                          <td className='brand' colSpan={1}>{product?.vendorProduct?.brand}</td>
                          <td colSpan={1}>{product.vendorOrderProductData.PartTerminology.PartTerminologyName}</td>
                          <td colSpan={1} className="quantity-td"><div className="quantity__input__container">
                            {
                              !pickTicket
                                ? <>
                              <i className='bx bx-message-alt-minus products__minus '
                                            onClick={(e, index = key) =>
                                              handleMinusQuantity(e, index)
                                              }
                                              ></i>
                                            <input
                                              min="1"
                                              type="number"
                                              className="quantity__input all-product-quantity-input"
                                              value={product?.qtyToShip}
                                              onChange={(e, index = key) =>
                                                handleQuantity(e, index)
                                              }
                                            ></input>
                                            <i className='bx bx-plus products__plus'
                                            onClick={(e, index = key) =>
                                              handleAddQuantity(e, index)
                                              }
                                              ></i>
                              </>
                                : <p style={{ textAlign: 'center', marginBottom: '0px' }}>{product?.qtyToShip}</p>
                            }

                                          </div></td>
                          <td colSpan={1} style={{ textAlign: 'center' }}><p className='qty'>{product.orderedQuantity}</p>
                            <p className='case'>{product.uom}</p>
                          </td>
                          <td colSpan={3} className="blank"></td>
                        </tr>
                      )
                    })
                  }
              </tbody>
            </Table>
            {
              (step === 1 && !pickTicket) && <div className='disclamer'>
              <p><b>Disclaimer</b> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
            }
          </Col>
          }
          {
            step === 2 && <>
              <Row className="details-container step-2">
                <div className='address-container'>
                  <div className='address-label'>
                    <b>Shipping Address:</b>
                  </div>
                  {
                    shippingAddress && <div className='address-desc'>
                    <p>
                      {shippingAddress?.shippingFirstName} {shippingAddress?.shippingLastName}<br></br>
                      {shippingAddress?.shippingAddress1 || '--'}<br></br>
                      {shippingAddress?.shippingAddress2 || '--'} {shippingAddress?.shippingCity || '--'}, {shippingAddress?.shippingState || '--'} {shippingAddress?.shippingZipCode || '--'}<br></br>
                      {shippingAddress?.shippingCountry || '--'}<br></br>
                    </p>
                  </div>
                  }
                </div>
              </Row>
              <Col className='details-container' lg={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th colSpan={1}>Part Number</th>
                      <th colSpan={2}>Brand</th>
                      <th colSpan={2}>Type</th>
                      <th colSpan={2} style={{ textAlign: 'center' }}>Qty to Ship</th>
                      <th colSpan={1} style={{ textAlign: 'center' }}>Uom</th>
                      <th colSpan={3} style={{ textAlign: 'center' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      shipProducts && shipProducts.map((product, key) => {
                        return (
                          <tr key={key}>
                      <td className='part-no' colSpan={1}>{product.shipmentProductData.partNumber}</td>
                      <td className='brand' colSpan={2}>{product.shipmentProductData.brands.BrandName}</td>
                      <td colSpan={2}>{product.shipmentProductData.PartTerminology.PartTerminologyName}</td>
                      <td colSpan={2} style={{ textAlign: 'center' }}>
                        {
                          product.shipmentQuantity
                        }
                      </td>
                      <td colSpan={1} style={{ textAlign: 'center' }}>
                        {
                          product.uom
                        }
                      </td>
                      <td colSpan={3} className="blank"></td>
                    </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
            </Col>
            </>
          }
          {
            step === 3 && <>
              <Col lg={12} className="details-container">
                {
                  selectedDrivers.length > 0 && <>
                    <p className='assignText'>Assigned Driver</p>
                    <Table className='driver-table'><tbody>
                    {
                      selectedDrivers.map((driver, key) => {
                        return (
                          <tr key={key}>
                            <td>{driver.firstName || '--'} {driver.lastName || '--'}</td>
                      <td>{driver.phone || driver.mobile || '--'}</td>
                      <td>{driver.code || '--'}</td>
                            <td style={{ textAlign: 'right', color: '#d54242', fontWeight: '600' }} className='d-flex align-items-center justify-content-end'><span onClick={(e) => handleRemoveDriver(e, driver.id)}><i className='bx bx-x'></i>Remove</span></td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </Table>
                  </>
                }
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Driver Code</th>
                      <th style={{ textAlign: 'right', position: 'relative' }}><input placeholder='Search driver name or code' className='search' onChange={handleSearch} />
                      <i className='bx bx-search'></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      drivers && drivers.map((driver, key) => {
                        return (
                          <tr key={key}>
                      <td>{driver.firstName || '--'} {driver.lastName || '--'}</td>
                      <td>{driver.phone || driver.mobile || '--'}</td>
                      <td>{driver.code || '--'}</td>
                      <td style={{ textAlign: 'right' }}>
                        {
                          selectedDrivers.findIndex((item) => item.id === driver.id) > -1 ? <p className='assign-text'><i className='mdi mdi-check-circle'></i>&nbsp;&nbsp;Assigned</p> : <Button className='me-2' id='link-driver' onClick={(e, driverDetails = driver.id) => handleSelectDriver(e, driverDetails)}>Assign Driver</Button>
                        }
                      </td>
                    </tr>
                        )
                      })
                    }
                  </tbody>
              </Table>
            </Col>
            </>
          }
          {
            step === 4 && <Col lg={12} className='step4'>
              <Row className='step4-row'>
                <Col lg={8} className="d-flex align-items-center set-border">
                  <div className='step4-number'>
                    <span>1</span>
                  </div>
                  <div className='step4-detail'>
                    <p className='heading'>Pick Ticket{
                  timeStamps.step1 !== '' && <p className='pick-time'>{moment(timeStamps.step1).format('Do MMMM YYYY, h:mm A')}</p>
                }</p>
                    <p className='sub-heading'>{orderData?.pickTicketTime}</p>
                  </div>
                </Col>
                <Col lg={4} className="action d-flex justify-content-end set-border">
                  <Button className='me-2' id='link-btn' onClick={(e) => handlePdf(e, 'pickTicket')}>Print  Pick Ticket</Button>
                </Col>
              </Row>
              <Row className='step4-row'>
                <Col lg={8} className="d-flex align-items-center set-border">
                  <div className='step4-number'>
                    <span>2</span>
                  </div>
                  <div className='step4-detail'>
                    <p className='heading'>Packing Slip{
                  timeStamps.step2 !== '' && <p className='pick-time'>{moment(timeStamps.step2).format('Do MMMM YYYY, h:mm A')}</p>
                }</p>
                    <p className='sub-heading'>{orderData?.packingSlipTime}</p>
                  </div>
                </Col>
                <Col lg={4} className="action d-flex justify-content-end set-border">
                  <Button className='me-2' id='link-btn' onClick={(e) => handlePdf(e, 'packing')}>View Packing Slip</Button>
                </Col>
              </Row>
              <Row className='step4-row'>
                <Col lg={12} className="d-flex align-items-center">
                  <div className='step4-number'>
                    <span>3</span>
                  </div>
                  <div className='step4-detail'>
                    <p className='heading'>Assigned Driver{
                  timeStamps.step3 !== '' && <p className='pick-time'>{moment(timeStamps.step3).format('Do MMMM YYYY, h:mm A')}</p>
                }</p>
                    <p className='sub-heading'>{orderData?.assignTime}</p>
                  </div>
                </Col>
                <Col lg={12} className="d-flex align-items-center">
                  <div className='step4-number'>
                    <span style={{ visibility: 'hidden' }}>0</span>
                  </div>
                  <div className='step4-detail'>
                    {
                      selectedDrivers && selectedDrivers.map((driver, i) => {
                        return (
                          <p key={i}><b>Name: {driver.firstName} {driver.lastName}</b><br />
                    Phone Number: {driver.phone || '--'} <br />
                    Driver Code: {driver.code || '--'}
                    </p>
                        )
                      })
                    }
                  </div>
                </Col>
              </Row>
            </Col>
          }
        </Row>
        {
          shipmentHistory
            ? <Row className='btn-row'>
          <Col lg={6}>
            {
              step !== 1 && <Button variant="secondary" onClick={handleBack} className="back-btn d-flex justify-content-center align-items-center"><i
              className="bx bx-arrow-back me-1"
            ></i>{' '}Back</Button>
            }
          </Col>
          <Col lg={6} className="d-flex justify-content-end">
            {
              step === 1 && <>
              <Button variant="link" id="link-btn" onClick={(e) => handlePdf(e, 'pickTicket')} className='next-btn d-flex justify-content-center align-items-center'>Print  Pick Ticket
              </Button>
            <Button variant="primary" disabled={totalQty === 0} onClick={handleStep} className='next-btn d-flex justify-content-center align-items-center'>NEXT - Create packing slip<i
              className="bx bx-arrow-back ms-1"
              style={{ transform: 'rotate(180deg)' }}
            ></i></Button>
              </>
            }
            {
              (step === 2 && shipmentStatus === 0) && <Button variant="primary" onClick={handlePackingSlip} className='next-btn d-flex justify-content-center align-items-center'>Generate Packing Slip</Button>
            }
          {
            (step === 2 && shipmentStatus === 1) && <>
            <Button variant="link" id="link-btn" onClick={(e) => handlePdf(e, 'packing')} className='next-btn d-flex justify-content-center align-items-center'>Print  Packing  Slip
              </Button>
              <Button variant="primary" onClick={handleStep} className='next-btn d-flex justify-content-center align-items-center'>NEXT - Assign Driver<i
            className="bx bx-arrow-back ms-1"
            style={{ transform: 'rotate(180deg)' }}
          ></i></Button>
            </>
          }
          {
            step === 3 && <Button variant="primary" disabled={selectedDrivers.length === 0} onClick={handleStep} className='next-btn d-flex justify-content-center align-items-center'>NEXT - Ship the Order<i
            className="bx bx-arrow-back ms-1"
            style={{ transform: 'rotate(180deg)' }}
          ></i></Button>
          }
          {
            step === 4 && <Button variant="primary" onClick={handleStep} className='next-btn d-flex justify-content-center align-items-center'>View Order Detail<i
            className="bx bx-arrow-back ms-1"
            style={{ transform: 'rotate(180deg)' }}
          ></i></Button>
          }
          </Col>
        </Row>
            : <Row className='btn-row'>
          <Col lg={6}>
            {
              (step !== 1) && <Button variant="secondary" onClick={handleBack} className="back-btn d-flex justify-content-center align-items-center"><i
              className="bx bx-arrow-back me-1"
            ></i>{' '}Back</Button>
            }
          </Col>
          <Col lg={6} className="d-flex justify-content-end">
            {
              (step === 1 && !pickTicket) && <Button variant="primary" onClick={handlePrintTicket} className='next-btn d-flex justify-content-center align-items-center'>Confirm Pick Ticket</Button>
            }
            {
              (step === 1 && pickTicket) && <>
              <Button variant="link" id="link-btn" onClick={(e) => handlePdf(e, 'pickTicket')} className='next-btn d-flex justify-content-center align-items-center'>Print  Pick Ticket
              </Button>
            <Button variant="primary" onClick={handleStep} className='next-btn d-flex justify-content-center align-items-center'>NEXT - Create packing slip<i
              className="bx bx-arrow-back ms-1"
              style={{ transform: 'rotate(180deg)' }}
            ></i></Button>
              </>
            }
            {
              (step === 2 && !generateSlip) && <Button variant="primary" onClick={handlePackingSlip} className='next-btn d-flex justify-content-center align-items-center'>Generate Packing Slip</Button>
            }
          {
            (step === 2 && generateSlip) && <>
            <Button variant="link" id="link-btn" onClick={(e) => handlePdf(e, 'packing')} className='next-btn d-flex justify-content-center align-items-center'>Print  Packing  Slip
              </Button>
              <Button variant="primary" onClick={handleStep} className='next-btn d-flex justify-content-center align-items-center'>NEXT - Assign Driver<i
            className="bx bx-arrow-back ms-1"
            style={{ transform: 'rotate(180deg)' }}
          ></i></Button>
            </>
          }
          {
            step === 3 && <Button variant="primary" disabled={selectedDrivers.length === 0} onClick={handleStep} className='next-btn d-flex justify-content-center align-items-center'>NEXT - Ship the Order<i
            className="bx bx-arrow-back ms-1"
            style={{ transform: 'rotate(180deg)' }}
          ></i></Button>
          }
          {
            step === 4 && <Button variant="primary" onClick={handleStep} className='next-btn d-flex justify-content-center align-items-center'>View Order Detail<i
            className="bx bx-arrow-back ms-1"
            style={{ transform: 'rotate(180deg)' }}
          ></i></Button>
          }
          </Col>
        </Row>
        }
      </Container>
    </div>
      </>
  )
}

export default Fulfillment
