/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Modal
} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { useHistory, Link, useParams } from 'react-router-dom'
// import { Editor } from 'react-draft-wysiwyg'
import Dropzone from 'react-dropzone'
import { GetData, PutData, PostData, DeleteData } from 'apiHandler'
import Loader from '../../components/Loader'
import delIcon from '../../assets/images/ic_delete.svg'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

export function slugify (text) {
  return text
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}

function AddTechnicalLinks () {
  const history = useHistory()
  const { id } = useParams()
  const [loader, setLoader] = useState(false)
  const [links, setLinks] = useState([{ title: '', linkFile: '' }])
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [technicalData, setTechnicalData] = useState({})
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const [modalData, setModalData] = useState(null)

  const handleRequestCall = (formData) => {
    return new Promise((resolve, reject) => {
      if (formData.get('id')) {
        PutData(`admin/technicalBulletinMedia/${formData.get('id')}`, formData).then((response) => {
          if (response.status === true) {
            resolve(true)
          } else {
            toastr.error(response.message, 'Error')
            setLoader(false)
            reject(new Error(false))
          }
        })
      } else {
        PostData('admin/technicalBulletinMedia', formData).then((response) => {
          if (response.status === true) {
            resolve(true)
          } else {
            toastr.error(response.message, 'Error')
            setLoader(false)
            reject(new Error(false))
          }
        })
      }
    })
  }

  function handleSubmit (e) {
    e.preventDefault()
    setLoader(true)
    let doneCount = 0
    let notDoneCount = 0

    for (const link of links) {
      const formData = new FormData()
      formData.append('title', link.title)
      link.linkFile && formData.append('document', link.linkFile)
      link.id ? formData.append('id', Number(link.id)) : formData.append('technicalBulletinId', Number(id))
      handleRequestCall(formData).then((res) => {
        doneCount++
        if (doneCount === links.length) {
          setLoader(false)
          toastr.success('Links Updated', 'Success')
        }
        if ((doneCount + notDoneCount) === links.length) {
          getTechnicalById(id)
        }
      }).catch((err) => {
        if (err) {
          notDoneCount++
        }
        if ((doneCount + notDoneCount) === links.length) {
          getTechnicalById(id)
        }
      })
    }
  }

  const handleAddLink = () => {
    setLinks((prevState) => {
      return [...prevState, { title: '', linkFile: '' }]
    })
  }

  const getTechnicalById = (id) => {
    setLoader(true)
    GetData(`admin/technicalBulletin/${id}`).then((response) => {
      if (response.status === true) {
        response.result.technicalBulletinMedia.length && setLinks(response.result.technicalBulletinMedia)
        setTechnicalData(response.result)
        setLoader(false)
      }
    })
  }

  useEffect(() => {
    if (id) {
      getTechnicalById(id)
    }
  }, [id])

  const handleDeleteClick = (link, index) => {
    setModalData({ data: link, index })
    setIsDisableOpen(true)
  }

  const handleDeleteConfirm = (e) => {
    e.preventDefault()
    if (modalData.data.id) {
      setLoader(true)
      DeleteData(`admin/technicalBulletinMedia/${modalData.data.id}`).then((response) => {
        if (response.status === true) {
          setLoader(false)
          getTechnicalById(id)
          setIsDisableOpen(false)
          setModalData(null)
          toastr.success(response.message, 'Success')
        } else {
          setLoader(false)
        }
      })
    } else {
      setModalData(null)
      setIsDisableOpen(false)
      setLinks((prevState) => {
        const arr = [...prevState]
        arr.splice(modalData.index, 1)
        return arr
      })
    }
  }

  const handleLink = (e, index) => {
    if (e.target.name === 'linkFile') {
      setLinks((prevState) => {
        const arr = [...prevState]
        arr[index][e.target.name] = e.target.files[0]
        return arr
      })
    } else {
      setLinks((prevState) => {
        const arr = [...prevState]
        arr[index][e.target.name] = e.target.value
        return arr
      })
    }
  }

  return (
    <>
      <Loader show={loader}></Loader>
      <div className="page-content">
        <Container fluid={true} className="homepage-wrap">
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <Breadcrumbs
              title="UI Elements"
              breadcrumbItem={id ? 'Edit Technical Bulletin Links' : 'Add Technical Bulletin Links'}
              icon={
                <i
                  className="bx bx-arrow-back me-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push(`/technical-bulletin/${id}`)}
                ></i>
              }
            />
            {/* <a
              href={`${process.env.REACT_APP_WEB_URL}/service-opportunities/${serviceData.slug}`}
              target={'_blank'} rel="noreferrer"
            >
              {status === 1 && 'View on Website'}
            </a> */}
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label">
                      <b>Title</b>
                    </Label>
                    <p>{technicalData.title}</p>
                  </div>
                  <hr />
                  {links.map((link, i) => {
                    return (
                      <div key={i}>
                        <div className="mb-3 relative">
                          <Label className="form-label">
                            <b>Link {i + 1}</b>
                          </Label>
                          <Input
                            placeholder="Enter Link Title"
                            type="text"
                            id={`link${i + 1}`}
                            name={'title'}
                            className="input-border"
                            value={link.title}
                            onChange={(e) => handleLink(e, i)}
                          />
                          {
                              i === 0 || <img
                              src={delIcon}
                              className="del--icon absolute"
                              style={{ top: '0px', right: '0px' }}
                              onClick={ () => handleDeleteClick(link, i) }
                            />
                          }
                        </div>
                        {
                                link.link && <div className="mb-3 d-flex" style={{ flexDirection: 'column' }}>
                                    <Label
                                        className="form-label"
                                        htmlFor={`linkFile${i + 1}`}
                                    >
                                        <b>Uploaded Link File {i + 1}</b>
                                    </Label>
                                    <a href={link.link} target="_blank" rel="noreferrer">{link.link}</a>
                                </div>
                            }
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor={`linkFile${i + 1}`}
                          >
                            <b>Upload Link File {i + 1}</b>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id={`linkFile${i + 1}`}
                            onChange={(e) => handleLink(e, i)}
                            name="linkFile"
                            accept=".docx,.doc,.png,.pdf"
                          />
                        </div>
                        <hr />
                      </div>
                    )
                  })}
                  <div className="mb-3">
                    <p style={{ width: '100%' }} className="text-center">
                      <button
                        style={{
                          color: '#4646ea',
                          background: 'transparent',
                          border: 'unset'
                        }}
                        onClick={handleAddLink}
                      >
                        <b>
                          <u>Add More Links</u>
                        </b>
                      </button>
                    </p>
                  </div>
                </CardBody>
              </Card>
              <Link to="/technical-bulletin">
                <Button className="btn btn-cancel mb-4 ms-0">Cancel</Button>
              </Link>
              <Button
                className="btn btn-add-new mb-4 ms-0"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
            className="modal-main-body"
            isOpen={isDisableOpen}
            toggle={() => setIsDisableOpen(false)}
            centered
          >
            <div className="modal-header">
              <button
                type="button"
                onClick={() => {
                  setIsDisableOpen(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="disable-warehouse">
                Are you sure you want to Delete this Technical Bulletin?
              </p>
              {
                modalData && <div className="modal-detail-div">
                <p className="modal-details">Details</p>
                <p className="modal-warehouse-name">
                  <span className="warehouse-heading"> Title: </span>{' '}
                  {modalData?.data?.title}
                </p>
                {
                    modalData?.data?.link && <p className="modal-warehouse-name">
                    <span className="warehouse-heading"> Uploaded File: </span>{' '}
                    { modalData?.data?.link }
                  </p>
                }
              </div>
              }
              <div className="modal-btn-div">
                <button
                  className="warehouse-btn-yes"
                  onClick={(e) => handleDeleteConfirm(e)}
                >
                  Yes
                </button>
                <button
                  className=" warehouse-btn-no"
                  onClick={() => setIsDisableOpen(false)}
                >
                  No
                </button>
              </div>
            </div>
          </Modal>
    </>
  )
}
export default AddTechnicalLinks
