/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
  Button,
  Modal
} from 'reactstrap'
import { DeleteData, GetData, PostData } from 'apiHandler'
import Dropzone from 'react-dropzone'
import TableComponent from 'components/Table'
import { useHistory, Link } from 'react-router-dom'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import PropTypes from 'prop-types'
import './SafetyDataSheet.scss'
import DeleteModal from 'components/Common/DeleteModal'
import moment from 'moment'

let searchTimer
let searchKeyword = ''

const SafetyDataSheetUploadModal = (props) => {
  const [file, setFile] = useState(null)
  const [error, setError] = useState(false)
  const [bulkError, setBulkError] = useState(false)

  // handles the file uploading error and setting file to a file variable
  const handleChange = (e) => {
    const dropFile = e?.target?.files[0] || e[0]
    if (dropFile.name.split('.').pop() === 'pdf') {
      setFile(dropFile)
      setError(false)
    } else {
      setError(true)
    }
  }

  // handle the modal opening and closing
  const handleModal = (e) => {
    e.preventDefault()
    setFile(null)
    setBulkError(false)
    props.setModalShow(prevState => (!prevState))
  }

  // removes the file from file variable on replace button
  const handleRemove = (e) => {
    e.preventDefault()
    setFile(null)
  }

  const handleUploadFile = () => {
    props.setModalStep(2)
  }

  useEffect(() => {
    if (!props.modalShow) {
      setFile(null)
    }
  }, [props.modalShow])

  useEffect(() => {
    if (props.bulkError) {
      setBulkError(props.bulkError)
      props.setModalShow(true)
    } else {
      props.setModalShow(false)
      setFile(null)
    }
  }, [props.bulkError])

  const handlePartCheckbox = (part, e) => {
    if (!e.target.checked) {
      const updatedCheck = [...props.selectedParts]
      updatedCheck.push({ partNumber: part.value, BrandID: part.brandId })
      props.setSelectedParts(updatedCheck)
    } else if (e.target.checked) {
      const data = props.selectedParts.filter((item, i) => {
        return item.partNumber !== part.value
      })
      props.setSelectedParts(data)
    }
  }

  return (
      <>
        <div>
          <Row>
            <Col lg={12}>
              <Modal
                size="md"
                isOpen={props.modalShow}
                toggle={handleModal}
                centered
              >
                <div className="modal-header ">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                    {props.modalStep === 1 ? <b>Upload Safety Data Sheet</b> : <b>Assign Part Number</b>}
                  </h5>
                  <button
                   onClick={handleModal}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <hr className="m-0"></hr>
                {props.modalStep === 1
                  ? <div className="modal-body bulk-upload-modal-container">
                  {error && (
                    <>
                      <div className="d-flex error-warning-container">
                        <i className="bx bxs-info-circle me-2"></i>
                        <div>
                          <p className="m-0">
                            There was an error importing your PDF file. After you
                            fix the error, try importing the PDF file again.
                          </p>
                          <p className="m-0">
                            - Invalid PDF Header: Missing headers: Title
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="my-2">
                    {file
                      ? (
                      <>
                        <p>Added File :</p>
                        <hr className="m-0"></hr>{' '}
                        <p className="my-2 d-flex justify-content-between align-items-center">
                          <span className="d-flex align-items-center">
                            <i className="bx bx-file me-2"></i>
                            {file.name}
                          </span>
                          <button
                            className="replace-btn"
                            onClick={handleRemove}
                          >
                            Replace File
                          </button>
                        </p>
                        <hr className="m-0"></hr>
                      </>
                        )
                      : (
                      <Dropzone
                        onDrop={handleChange}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone drop-zone-container-left ">
                            <div
                              className="dz-message needsclick mt-2 p-0"
                              {...getRootProps()}
                            >
                              <input
                                {...getInputProps()}
                                onChange={handleChange}
                                name="postImage"
                              />
                              <div className="">
                                <i className="display-4 text-muted bx bxs-cloud-upload drop-zone-icon" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                        )}
                  </div>
                </div>
                  : <div className='modal-body bulk-upload-modal-container p-4'>
                    <form className="position-relative my-3 mx-auto">
                      <input
                        className="form-control input-border"
                        type="text"
                        placeholder="Search"
                        onChange={props.handlePart}
                      />
                      <i className="bx bx-search-alt-2 search-icon"></i>
                    </form>
                    <div ref={props.scrollRef} id='scrollable' className="parts-check-container">
                    <div className="checkbox-line">
                               <div className="d-flex align-items-center my-2">
                                 <input
                                   className="me-2"
                                   type="checkbox"
                                   disabled
                                  readOnly
                                  name="parts"
                                  style={{ width: '10%', visibility: 'hidden' }}
                                 />
                                 <label
                                   className="form-check-label"
                                   style={{ width: '45%' }}
                                 >
                                   Part Number
                                 </label>
                                 <label
                                   className="form-check-label"
                                   style={{ width: '45%' }}
                                 >
                                   Brand Name
                                 </label>
                               </div>
                             </div>
                    <InfiniteScroll
                            dataLength={props.partsData.length}
                            next={props.fetchNextParts}
                            hasMore={true}
                            endMessage={
                              <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                              </p>
                            }
                            scrollableTarget="scrollable"
                          >
                             {props.partsData.map((part, i) => {
                               return <div className="checkbox-line" key={i}>
                               <div className="d-flex align-items-center my-2">
                                 <input
                                   className="me-2"
                                   type="checkbox"
                                   value={part.value}
                                   id={`part-${part.value}`}
                                   onClick={(e) => handlePartCheckbox(part, e)}
                                   checked={
                                    props.selectedParts.findIndex((item) => item.partNumber === part.value) !== -1
                                  }
                                  readOnly
                                  name="parts"
                                  style={{ width: '10%' }}
                                 />
                                 <label
                                   className="form-check-label"
                                   htmlFor={`part-${part.value}`}
                                   style={{ width: '45%' }}
                                 >
                                   {part.label}
                                 </label>
                                 <label
                                   className="form-check-label"
                                   htmlFor={`part-${part.value}`}
                                   style={{ width: '45%' }}
                                 >
                                   {part.brandName}
                                 </label>
                               </div>
                             </div>
                             })}
                          </InfiniteScroll>
                          </div>
                    </div>}
                <div className="modal-body bulk-upload-modal-container">
                  <div className="text-end">
                    <button
                      className="cancel-btn me-3"
                      onClick={handleModal}
                    >
                      Cancel
                    </button>
                    {props.modalStep === 1 && <button className={`upload-btn ${file && 'upload-btn-active'} `} disabled={!file} onClick={handleUploadFile}>Next</button>}
                    {props.modalStep === 2 && <button className={`upload-btn ${file && 'upload-btn-active'} `} disabled={!file} onClick={() => props.handleSave(file)}>Submit</button>}
                  </div>
                </div>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
  )
}

const SafetyDataSheet = () => {
  const history = useHistory()
  const [modalShow, setModalShow] = useState(false)
  const [parts, setParts] = useState([])
  const [loader, setLoader] = useState(false)
  const [modalStep, setModalStep] = useState(1)
  const [partsData, setPartsData] = useState([])
  const [partsPage, setPartsPage] = useState()
  const scrollRef = React.useRef()
  const [deleteModal, setDeleteModal] = useState(false)
  const [rowData, setRowData] = useState()
  const [searchText, setSearchText] = useState('')
  const [selectedParts, setSelectedParts] = useState([])
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  const columns = [
    { key: 'title', label: 'Safety Data Sheet' },
    { key: 'parts', label: 'Assigned Part Number' },
    { key: 'createdAt', label: 'Date Added', sorting: true },
    { key: 'action', label: 'Action' }
  ]

  const handleActions = (key, data) => {
    if (key === 'delete') {
      setRowData(data)
      setDeleteModal(true)
    }
  }

  const handleRow = (data) => {
    // history.push('/products/' + data.id)
  }

  const paginationHandler = (page) => {
    getProducts(page)
  }

  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn === column) {
      if (sortingState.order === 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }

  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getProducts(paging.currentPage)
  }, [sortingState])

  const getProducts = (p = 1) => {
    setLoader(true)
    const pageSize = 20
    let url = 'admin/getAllSDS?page=' + p + '&pageSize=' + pageSize
    if (sortingState.sortColumn != null) {
      url +=
        '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order
    }
    GetData(url).then((response) => {
      if (response.status === true || response.status === 200) {
        setLoader(false)
        setParts(response.result.sdsDoc.map((part, index) => {
          return {
            id: part.id,
            title: <a href={part.url} target="_blank" rel="noreferrer" className='sds-text'>{part.title}</a>,
            createdAt: moment(part?.createdAt).format('DD MMM, YYYY'),
            action: ['delete'],
            parts: part.parts.length > 0
              ? <><span id={`tooltip-${index}`} className="d-flex align-items-center sds-text p-1" style={{ width: 'fit-content' }}>{part.parts.length}</span>
                <UncontrolledTooltip
                  placement="right"
                  target={`tooltip-${index}`}
                  autohide={false}
                  style={{
                    backgroundColor: 'white',
                    color: 'black',
                    boxShadow:
                      '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
                    minWidth: '150px',
                    fontSize: '13px'
                  }}>
                  <div className="tooltiptext1 arrow">
                    <div className='tooltip-innerdata' id="tooltip-scrollBar" style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                      {
                        part.parts.map((data, key) => {
                          return (
                            <div className='partid-qty-div d-flex align-items-center justify-content-between mb-1 border-bottom py-1' key={key} style={{ width: '200px' }}>
                               <span>{data.partNumber}</span>
                                <span className="pe-1">{data?.BrandName}</span>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </UncontrolledTooltip></>
              : '--'
          }
        }))
        setPaging({
          currentPage: p,
          recordPerPage: pageSize,
          totalRecord: response.result.total
        })
      } else {
        setLoader(false)
        setParts([])
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
        toastr.error(response.message, 'Error')
      }
    })
  }

  useEffect(() => {
    getProducts()
  }, [])

  const getParts = (p) => {
    setPartsPage(p)
    setLoader(true)
    let url = `admin/getAllPartNumber?page=${p}&pageSize=20`
    if (searchKeyword) {
      url += '&search=' + searchKeyword
    }
    GetData(url).then((response) => {
      if (response.status === true) {
        setLoader(false)
        if (response.result.data.length > 0) {
          const newParts = response.result.data.map((part) => {
            return { label: part.partNumber, value: part.partNumber, brandName: part.BrandName, brandId: part.BrandID }
          })
          if (searchKeyword && p === 1) {
            scrollRef.current.scrollTo(0, 0)
            setPartsData(newParts)
          } else setPartsData(prevState => [...prevState, ...newParts])
        }
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  useEffect(() => {
    modalStep === 2 && getParts(1)
  }, [modalStep])

  const handlePart = (e) => {
    setSearchText(e.target.value)
    if (e.target.value === '') {
      setPartsData([])
    }
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      searchKeyword = e.target.value
      getParts(1)
    }, 1000)
  }

  const fetchNextParts = () => {
    getParts(parseInt(partsPage) + 1)
  }

  useEffect(() => {
    if (!modalShow) {
      searchKeyword = ''
      setPartsData([])
      setModalStep(1)
      setSearchText('')
      setSelectedParts([])
    }
  }, [modalShow])

  const handleAddParts = (id) => {
    const body = {
      partsInfo: [...selectedParts]
    }
    PostData(`admin/mapPartNumberToSDS/${id}`, body).then((response) => {
      if (response.status === true) {
        setLoader(false)
        setModalShow(false)
        getProducts(1)
        toastr.success(response.message, 'Success')
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleSave = (file) => {
    setLoader(true)
    const formData = new FormData()
    formData.append('document', file)
    PostData('admin/uploadSDS', formData).then((response) => {
      if (response.status === true) {
        handleAddParts(response.result.id)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleDeleteEvent = () => {
    setLoader(true)
    DeleteData(`admin/deleteSDS/${rowData.id}`).then((response) => {
      if (response.status === true) {
        setLoader(false)
        setDeleteModal(false)
        getProducts(1)
        toastr.success(response.message, 'Success')
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  return (
    <>
    <SafetyDataSheetUploadModal
     modalShow={modalShow}
      setModalShow={setModalShow} modalStep={modalStep} setLoader={setLoader}
      fetchNextParts={fetchNextParts}
      partsData={partsData}
      scrollRef={scrollRef}
      handlePart={handlePart}
      handleSave={handleSave}
      selectedParts={selectedParts}
      setSelectedParts={setSelectedParts}
      setModalStep={setModalStep}
      />
       <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Loader show={loader} />
      <div className="page-content">
        <Container fluid={true} className="customer-container">
          <div className="products-nav">
            <span className="products-heading">Safety Data Sheet</span>
            <div className='d-flex'>
                <Button color="primary" className="btn btn-add-new" onClick={() => setModalShow(true)}>
                    Add
                </Button>
            </div>
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <TableComponent
                    columns={columns}
                    content={parts}
                    handleActions={handleActions}
                    paging={paging}
                    paginationHandler={paginationHandler}
                    onClick={handleRow}
                    sorting={handleSort}
                    arrowState={sortingState}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

SafetyDataSheetUploadModal.propTypes = {
  modalShow: PropTypes.bool,
  modalStep: PropTypes.number,
  bulkError: PropTypes.any,
  setModalShow: PropTypes.func,
  setLoader: PropTypes.func,
  fetchNextParts: PropTypes.func,
  partsData: PropTypes.array,
  scrollRef: PropTypes.any,
  handlePart: PropTypes.func,
  handleSave: PropTypes.func,
  setSelectedParts: PropTypes.func,
  selectedParts: PropTypes.array,
  setModalStep: PropTypes.func
}

export default SafetyDataSheet
