import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Label,
  Input,
} from "reactstrap";
import "../AccountPricing/AccountPricing.scss";
import Select from "react-select";
import { PostData, GetData } from "apiHandler";
import toastr from "toastr";
import Loader from "../../components/Loader";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import delIcon from "../../assets/images/ic_delete.svg";

const ShippingManagement = () => {
  const [shippingList, setShippingList] = useState([]);
  const [shippingPopup, setShippingPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState("");
  const [tableMessage, setTableMessage] = useState("Please wait loading...");
  const [carrierList, setCarrierList] = useState([]);
  const [createCarrierName, setCreateCarrierName] = useState(null);
  const [createShippingNote, setCreateShippingNote] = useState("");
  const [createCarrierValue, setCreateCarrierValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState({
    carrierName: "",
    value: "",
    shippingNote: "",
  });

  useEffect(() => {
    const commonData = JSON.parse(localStorage.getItem("commonData"));
    getShippingList();
    const tempCarrierList = [];
    commonData?.carrierTypes?.map((shipping) => {
      tempCarrierList.push({
        ...shipping,
        label: shipping?.name,
        value: shipping?.name,
      });
      return null;
    });
    setCarrierList(tempCarrierList);
  }, []);

  const getShippingList = () => {
    setLoader(true);
    let url = "admin/getShippings";

    GetData(url).then((response) => {
      setLoader(false);
      if (response.status === true) {
        setShippingList(response?.result);
      } else {
        toastr.error(response?.message, "Error");
      }
    });
  };

  //   -------------------------Function to update shipping-----------------------------
  const updateShipping = (key, check) => {
    const shippingListTemp = [...shippingList];
    if (check === true) {
      const payload = {
        carrierName: shippingListTemp[key].carrierName,
        value: shippingListTemp[key].value,
        shippingNote: shippingListTemp[key].shippingNote,
      };
      setLoader(true);
      PostData("admin/updateShipping", payload).then((response) => {
        setLoader(false);
        if (response.status === true) {
          shippingListTemp[key].update = false;
          setShippingList(shippingListTemp);
          getShippingList();
          toastr.success(response.message, "Success");
        } else {
          toastr.error(response.message, "Error");
        }
      });
    } else {
      getShippingList();
    }
  };

  const handleCarrierName = (data) => {
    setSelectedCarrier(data?.carrierName);
    setDeletePopup(true);
  };
  const shippingMethodDelete = () => {
    let payload = {
      carrierName: selectedCarrier,
    };
    PostData("admin/deleteShipping", payload).then((response) => {
      setLoader(true);
      if (response.status === true) {
        setLoader(false);
        setDeletePopup(false);
        getShippingList();
        toastr.success(response?.message, "Success");
      } else {
        setLoader(false);
        toastr.error(response?.message, "Error");
      }
    });
  };

  // --------------------function to handle shipping price------------------
  const handleInputPrice = (e, key) => {
    if (isNaN(e.target.value)) {
      return;
    }

    const shippingListTemp = [...shippingList];
    shippingListTemp[key].value = e.target.value;
    shippingListTemp[key].update = true;
    setShippingList(shippingListTemp);
  };

  // --------------------function to handle shipping note------------------
  const handleInputNote = (e, key) => {
    const shippingListTemp = [...shippingList];
    shippingListTemp[key].shippingNote = e.target.value;
    shippingListTemp[key].update = true;
    setShippingList(shippingListTemp);
  };

  // -----------------------function to add shipping method-----------------------
  const ClearAddShipping = () => {
    setCreateCarrierName(null);
    setCreateCarrierValue(null);
    setCreateShippingNote("");
  };

  const AddShippingMethod = () => {
    const newErrorMessage = { ...errorMessage };

    if (!createCarrierName?.label) {
      newErrorMessage.carrierName = "Carrier name cannot be blank.";
    } else {
      newErrorMessage.carrierName = "";
    }

    if (!createCarrierValue) {
      newErrorMessage.value = "Carrier price cannot be blank.";
    } else {
      newErrorMessage.value = "";
    }

    setErrorMessage(newErrorMessage);
    if (!createCarrierName?.label || !createCarrierValue) {
      return;
    }
    const payload = {
      carrierName: createCarrierName?.label,
      value: createCarrierValue,
      shippingNote: createShippingNote,
      carrierCode: createCarrierName?.code,
      transportationMethod: createCarrierName?.transportationMethod,
    };
    setLoader(true);
    PostData("admin/updateShipping", payload).then((response) => {
      setLoader(false);
      if (response.status === true) {
        ClearAddShipping();
        getShippingList();
        setShippingPopup(false);
        toastr.success(response.message, "Success");
      } else {
        toastr.error(response.message, "Error");
      }
    });
  };

  return (
    <div className="page-content">
      <Loader show={loader} />
      <Container fluid={true} className="customer-container">
        <div className="breadcrumb-main-nav">
          <Breadcrumbs
            title="UI Elements"
            breadcrumbItem="Shipping Management"
          />
          <div className="imp-exp-div d-flex gap-2">
            <button
              className="add-new-customer-btn"
              onClick={() => setShippingPopup(true)}
            >
              + Add New
            </button>
          </div>
        </div>
        <Row className="mt-3">
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={3} className="heading-tab">
                    <p>Carrier Name</p>
                  </Col>
                  <Col lg={3} className="heading-tab">
                    <p>Shipping Note</p>
                  </Col>
                  <Col lg={2} className="heading-tab">
                    <p>Shipping Price</p>
                  </Col>
                  <Col lg={2} className="heading-tab">
                    <p>Delete</p>
                  </Col>
                  <Col lg={2} className="heading-tab">
                    <p></p>
                  </Col>
                </Row>
                {shippingList?.length > 0 ? (
                  shippingList?.map((list, key) => {
                    return (
                      <Row key={key}>
                        <Col lg={3} className="input-tab">
                          <div>{list?.carrierName}</div>
                        </Col>
                        <Col lg={3} className="input-tab">
                          <input
                            placeholder="Enter shipping note"
                            type="text"
                            className="input-sell-price w-100 ps-2"
                            value={list?.shippingNote}
                            onChange={(e) => handleInputNote(e, key)}
                          />
                        </Col>
                        <Col lg={2} className="input-tab">
                          <input
                            type="text"
                            className="text-center input-sell-price"
                            value={list?.value}
                            onChange={(e) => handleInputPrice(e, key)}
                          />
                        </Col>
                        <Col lg={2} className="input-tab">
                          <img
                            src={delIcon}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleCarrierName(list)}
                          />
                        </Col>
                        <Col
                          lg={2}
                          className="input-tab d-flex justify-content-center align-items-center"
                        >
                          {list.update && (
                            <div className="mx-1">
                              <i
                                onClick={() => updateShipping(key, true)}
                                className="fas fa-check-circle input-icon-tick mr-1"
                              ></i>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <i
                                onClick={() => updateShipping(key, false)}
                                className="fas fa-times-circle input-icon-cross"
                              ></i>
                            </div>
                          )}
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <Row>
                    {" "}
                    <Col lg={12} sm={12} className="input-tab">
                      {" "}
                      {tableMessage}{" "}
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {shippingPopup && (
        <Modal
          className="modal-main-body"
          isOpen={shippingPopup}
          toggle={() => {
            ClearAddShipping();
            setShippingPopup(false);
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              <b>Add Shipping Method</b>
            </h5>
            <button
              type="button"
              onClick={() => {
                ClearAddShipping();
                setShippingPopup(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div>
              <Row>
                <Col lg="12">
                  <div className="mb-3">
                    <Label style={{ fontWeight: "bold" }}>Carrier Name</Label>
                    <Select
                      value={createCarrierName || null}
                      onChange={(selected) => {
                        setCreateCarrierName(selected ? selected : null);
                        setErrorMessage((prevError) => ({
                          ...prevError,
                          carrierName: "",
                        }));
                      }}
                      placeholder="Select Carrier Name"
                      options={carrierList}
                      classNamePrefix="select2-selection"
                    />
                    {errorMessage?.carrierName && (
                      <span className="error">{errorMessage?.carrierName}</span>
                    )}
                  </div>
                </Col>
                <Col lg="12">
                  <div className="mb-3">
                    <Label style={{ fontWeight: "bold" }}>Price</Label>
                    <Input
                      name="price"
                      value={createCarrierValue}
                      type="text"
                      className="colorpicker-default"
                      onChange={(e) => {
                        if (isNaN(e.target.value)) {
                          return;
                        }
                        setCreateCarrierValue(e.target.value);
                        setErrorMessage((prevError) => ({
                          ...prevError,
                          value: "",
                        }));
                      }}
                    />
                    {errorMessage?.value && (
                      <span className="error">{errorMessage?.value}</span>
                    )}
                  </div>
                </Col>
                <Col lg="12">
                  <div className="mb-3">
                    <Label style={{ fontWeight: "bold" }}>Shipping Note</Label>
                    <Input
                      name="shippingNote"
                      value={createShippingNote}
                      type="text"
                      className="colorpicker-default"
                      onChange={(e) => {
                        setCreateShippingNote(e.target.value);
                        setErrorMessage((prevError) => ({
                          ...prevError,
                          shippingNote: "",
                        }));
                      }}
                    />
                    {errorMessage?.shippingNote && (
                      <span className="error">
                        {errorMessage?.shippingNote}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="modal-btn-div">
              <button
                className="warehouse-btn-yes"
                onClick={() => AddShippingMethod()}
              >
                Yes
              </button>
              <button
                className="warehouse-btn-no"
                onClick={() => {
                  ClearAddShipping();
                  setShippingPopup(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      )}
      <Modal
        className="modal-main-body"
        isOpen={deletePopup}
        toggle={() => setDeletePopup(false)}
        centered
      >
        <div className="modal-header">
          <button
            type="button"
            onClick={() => setDeletePopup(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div>
            <Row>
              <Col lg="12">
                <div className="mb-3">
                  <h5>
                    Are you sure you want to Delete this shipping method ?{" "}
                  </h5>
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-btn-div">
            <button
              className="warehouse-btn-yes"
              onClick={() => shippingMethodDelete()}
            >
              Yes
            </button>
            <button
              className="warehouse-btn-no"
              onClick={() => setDeletePopup(false)}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ShippingManagement;
