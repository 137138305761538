import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap'

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Testimonials from 'components/HomeComponents/Testimonials'
import classnames from 'classnames'
import HomeBanner from 'components/HomeComponents/HomeBanner'
// import SupplyResources from 'components/HomeComponents/SupplyResources'
import SupplyCustomer from 'components/HomeComponents/SupplyCustomer'
import FeaturedProduct from 'components/HomeComponents/FeaturedProduct'
import FeaturedBrands from 'components/HomeComponents/FeaturedBrands'
import CallToAction from 'components/HomeComponents/CallToAction'
// import TopSellingCategories from 'components/HomeComponents/TopSellingCategories'
// import WhyQls from 'components/HomeComponents/WhyQls'
import NewArrivals from 'components/HomeComponents/NewArrivals'
import { GetData } from 'apiHandler'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Loader from '../../components/Loader'

function HomePage () {
  const [verticalActiveTab, setverticalActiveTab] = useState('1')
  const [loader, setLoader] = useState(false)
  const [data, setData] = useState(null)

  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  useEffect(() => {
    setLoader(true)
    GetData('admin/getHomeSettings').then((response) => {
      if (response.status === true) {
        setData(response.result)
        setLoader(false)
      } else {
        toastr.error(response.message, 'Error')
        setLoader(false)
      }
    })
  }, [])

  return (
    <React.Fragment>
      <Loader show={loader}></Loader>
      <div className="page-content">
        <MetaTags>
          <title>QLS Admin | Home Page</title>
        </MetaTags>
        <Container fluid={true} className="homepage-wrap">
          <Breadcrumbs title="UI Elements" breadcrumbItem="Homepage" />
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="4" className="pe-xl-0 d-xl-flex">
                      <div className="outer-home-menu">
                        <Nav pills className="">
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: verticalActiveTab === '1'
                              })}
                              onClick={() => {
                                toggleVertical('1')
                              }}
                            >
                              1. Banner
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: verticalActiveTab === '2'
                              })}
                              onClick={() => {
                                toggleVertical('2')
                              }}
                            >
                              2. QL Supply Resources
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: verticalActiveTab === '3'
                              })}
                              onClick={() => {
                                toggleVertical('3')
                              }}
                            >
                              3. Register as QL Supply Customer
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: verticalActiveTab === '4'
                              })}
                              onClick={() => {
                                toggleVertical('4')
                              }}
                            >
                              4. Featured Products
                            </NavLink>
                          </NavItem>
                          {/* <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: verticalActiveTab === '5'
                              })}
                              onClick={() => {
                                toggleVertical('5')
                              }}
                            >
                              5. Top Selling Categories
                            </NavLink>
                          </NavItem> */}
                          {/* <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: verticalActiveTab === '6'
                              })}
                              onClick={() => {
                                toggleVertical('6')
                              }}
                            >
                              6. Why QLS
                            </NavLink>
                          </NavItem> */}
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: verticalActiveTab === '5'
                              })}
                              onClick={() => {
                                toggleVertical('5')
                              }}
                            >
                              5. New Arrivals
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: verticalActiveTab === '6'
                              })}
                              onClick={() => {
                                toggleVertical('6')
                              }}
                            >
                              6. Featured Brands
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: verticalActiveTab === '7'
                              })}
                              onClick={() => {
                                toggleVertical('7')
                              }}
                            >
                              7. Testimonials
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </Col>
                    <Col xl="8">
                      <TabContent
                        activeTab={verticalActiveTab}
                        className="text-muted mt-4 mt-md-0"
                      >
                        <TabPane tabId="1">
                          <HomeBanner data={data?.section1.banners} />
                        </TabPane>
                        <TabPane tabId="2">
                          <CallToAction data={data?.section10}/>
                        </TabPane>
                        {/* <TabPane tabId="2">
                          <SupplyResources data={data?.section2}/>
                        </TabPane> */}
                        <TabPane tabId="3" >
                          <SupplyCustomer data={data?.section3}/>
                        </TabPane>
                        <TabPane tabId="4" >
                          <FeaturedProduct data={data?.section4}/>
                        </TabPane>
                        {/* <TabPane tabId="5">
                          <TopSellingCategories data={data?.section5}/>
                        </TabPane> */}
                        {/* <TabPane tabId="6" >
                          <WhyQls data={data?.section6}/>
                        </TabPane> */}
                        <TabPane tabId="5" >
                          <NewArrivals data={data?.section7}/>
                        </TabPane>
                        <TabPane tabId="6">
                          <FeaturedBrands data={data?.section9}/>
                        </TabPane>
                        <TabPane tabId="7" >
                          <Testimonials data={data?.section8} />
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default HomePage
