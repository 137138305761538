/* eslint-disable no-unused-expressions */
import React, { useContext } from 'react'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import delIcon from '../../assets/images/ic_delete.svg'
import { DeleteModalContext } from './AddNewCategory'

export default function CustomTable (props) {
  const history = useHistory()
  function handlePush (id, e) {
    e.preventDefault()
    history.push(`/category/${id}`)
  }

  const { setIsDisableOpen } = useContext(DeleteModalContext)

  return (
    <div className="table-responsive" style={{ height: '410px' }}>
      <Table className="table mb-0">
        <thead className="table-light">
          <tr>
            <th>{props.title1}</th>
            <th>{props.title2}</th>
            <th>{props.title3}</th>
            <th style={{ width: '200px' }}>{props.title4}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.content?.map((item, i) => {
            return (
              <tr key={i}>
                <td onClick={e => handlePush(item.id, e)}>{item.title}</td>
                <td onClick={e => handlePush(item.id, e)}>{item.metaTitle}</td>
                <td onClick={e => handlePush(item.id, e)}>{item.slug}</td>
                <td onClick={e => handlePush(item.id, e)}>{item.metaDescription}</td>
                <td onClick={(e) => {
                  setIsDisableOpen(true)
                  props.handleModal(e, item)
                }}> <img src={delIcon} /></td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

CustomTable.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  title3: PropTypes.string,
  title4: PropTypes.string,
  content: PropTypes.array,
  handleModal: PropTypes.func
}
