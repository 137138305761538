/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-keys */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  FormFeedback,
  Form
} from 'reactstrap'
import { useParams } from 'react-router-dom'
import { GetData, PutData, PostData } from '../../apiHandler'
import toastr from 'toastr'
import * as Yup from 'yup'
import { useFormik, yupToFormErrors } from 'formik'
import NumberFormat from 'react-number-format'
import Select from 'react-select'

function EditStaff ({ details, setEdit, getStaffDetails, warehouses }) {
  const userObj = JSON.parse(localStorage.authUser)
  const [radio, setRadio] = useState(true)
  const [currentRole, setCurrentRole] = useState(1)
  const params = useParams()
  const [loader, setLoader] = useState(false)
  const [permissionsDataLeft, setPermissionsDataLeft] = useState([])
  const [permissionsDataRight, setPermissionsDataRight] = useState([])
  const [selectedPermission, setselectedPermission] = useState([])
  const [privilegesErrors, setprivilegesErrors] = useState('')
  const [mobile, setMobile] = useState()

  useEffect(() => {
    if (localStorage.authUser) {
      const userObj = JSON.parse(localStorage.authUser)
      setCurrentRole(userObj.roleId)
    }
  }, [])
  useEffect(() => {
    setMobile(details.mobile)
  }, [])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      phoneCode: '',
      phone: details?.phone,
      designation: '',
      permissions: [],
      roleId: 4,
      type: 4,
      vehicleNumber: '',
      driverCode: '',
      vendorWarehouseLocationId: details.staffWareHouseLocation?.vendorWarehouseLocationId,
      ...details,
      phone: details?.phone,
      type: details?.roleId || 4
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('Please enter Name'),
      lastName: Yup.string().required('Please enter Name'),
      email: Yup.string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
      designation: Yup.string().required('Please enter designation'),
      mobile: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required('Please enter Phone Number')
        .test(
          'maxDigits',
          'Must be 10 digits',
          (number) => String(number).length === 10
        ),
      vendorWarehouseLocationId: (userObj.roleId == 3 || userObj.roleId == 2) && Yup.number().required('Please select warehouse')
      // permissions: Yup.array()
      //   .required("Please select permissions")
      //   .min(1, "Please select atleast one permission"),
      // roleId: Yup.string().required("Please select Role ID"),
      // type: Yup.string().required("Please select type"),
    }),
    onSubmit: (values) => {
      setprivilegesErrors('')
      if (values.roleId == 4) {
        if (values.permissions.length === 0) {
          validation.setFieldError('permissions', 'Please select permissions')
          setprivilegesErrors('')
          return
        }
      }
      const body = { ...values }
      // body.phone = body.phoneCode + body.phone
      // delete body.phoneCode
      let url = 'admin/updateStaffDetails/' + params.id
      if (userObj.roleId == 3 || userObj.roleId == 2) {
        url = 'vendor/editStaff'
        if (body.roleId == 5) {
          body.permissions = []
        }
        body.staffId = params.id
        PutData(url, body).then((response) => {
          if (response.status === true) {
            toastr.success(response.message, 'Success')
            setEdit(true)
            getStaffDetails()
          } else {
            toastr.error(response.message, 'Error')
          }
        })
      } else {
        delete body.driverCode
        delete body.vehicleNumber
        delete body.roleId
        delete body.status
        delete body.type
        PostData(url, body).then((response) => {
          if (response.status === true) {
            toastr.success(response.message, 'Success')
            setEdit(true)
            getStaffDetails()
          } else {
            toastr.error(response.message, 'Error')
          }
        })
      }
    }
  })

  useEffect(() => {
    setRadio(details?.roleId != 5)
    filterPermissions()
    let roleId = 2
    if (userObj.roleId == 3 || userObj.roleId == 2) {
      roleId = 4
    }
    getPermissions(roleId)
  }, [details])

  const filterPermissions = () => {
    const arr = []
    details?.userPermissions?.map((item) => {
      arr.push(item.permissions.id)
    })
    setselectedPermission(arr)
    validation.setFieldValue('permissions', arr)
  }

  const getPermissions = (roleId) => {
    GetData('admin/modules/get?areaCode=' + roleId).then((response) => {
      if (response.status === true) {
        setPermissionsDataLeft(
          response.result.slice(0, response.result.length / 2)
        )
        setPermissionsDataRight(
          response.result.slice(response.result.length / 2)
        )
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleCheckPermission = (status, id) => {
    const temp = [...selectedPermission]
    if (id != 'all') {
      if (!status) {
        for (let i = 0; i < temp.length; i++) {
          if (temp[i] == id) {
            temp.splice(i, 1)
          }
        }
      } else {
        temp.push(id)
      }
      setselectedPermission(temp)
      if (temp.length === 0) setprivilegesErrors('Please select privileges')
      else setprivilegesErrors('')
      validation.setFieldValue('permissions', temp)
    } else {
      const tempp = []
      if (status) {
        [...permissionsDataLeft, ...permissionsDataRight].forEach((item) => {
          tempp.push(item.id)
        })
      }
      setselectedPermission(tempp)
      if (tempp.length === 0) setprivilegesErrors('Please select privileges')
      else setprivilegesErrors('')
      validation.setFieldValue('permissions', tempp)
    }
  }

  return (
    <>
      <Row className="mt-3">
        <Col lg={12}>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              if (validation.values.roleId == 4) {
                if (validation.values.permissions.length === 0) {
                  setprivilegesErrors('Please select privileges')
                }
              }
              validation.handleSubmit()
            }}
          >
            <Card>
              <CardBody className="card-main-body">
                <div className="staff-edit-main">
                  <div className="edit-first-name">
                    <div className="staff-manafement-light">First Name*</div>
                    <Input
                      type="text"
                      className="staff-first-input"
                      name="firstName"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstName || ''}
                      invalid={
                        !!(validation.touched.firstName &&
                        validation.errors.firstName)
                      }
                    />
                    {validation.touched.firstName &&
                    validation.errors.firstName
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.firstName}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                  <div className="edit-first-name">
                    <div className="staff-manafement-light">Last Name*</div>
                    <Input
                      type="text"
                      className="staff-first-input"
                      name="lastName"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastName || ''}
                      invalid={
                        !!(validation.touched.lastName &&
                        validation.errors.lastName)
                      }
                    />
                    {validation.touched.lastName &&
                    validation.errors.lastName
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lastName}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                </div>
                <div className="staff-edit-main">
                  <div className="edit-first-name">
                    <div className="staff-manafement-light">Email Address*</div>
                    <Input
                      type="email"
                      className="staff-first-input"
                      name="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ''}
                      invalid={
                        !!(validation.touched.email && validation.errors.email)
                      }
                    />
                    {validation.touched.email && validation.errors.email
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                  <div className="edit-first-name">
                    <div className="staff-manafement-light">Mobile Number</div>
                    <NumberFormat value={mobile} className="staff-first-input"
                    displayType="input" format="(###) ###-####"
                    name="mobile"
                    onValueChange={(values) => {
                      setMobile(values.value)
                      validation.setFieldValue('mobile', values.value)
                    }}
                    // onValueChange={(values) => {
                    //   if (values.value.length < 11) {
                    //     validation.setFieldValue('mobile', values.value)
                    //   }
                    // }}
                    //   onBlur={validation.handleBlur}
                      />
                    {/* <Input
                      type="text"
                      className="staff-first-input"
                      name="mobile"
                      onChange={(e) => {
                        if (e.target.value.length < 11) {
                          validation.setFieldValue('mobile', e.target.value)
                          console.log('sonu', validation.setFieldValue('mobile', e.target.value))
                        }
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.mobile || ''}
                    /> */}
                    {validation.touched.mobile && validation.errors.mobile
                      ? (
                      <div className='error' type="invalid">
                        {validation.errors.mobile}
                        </div>
                        )
                      : null}
                  </div>
                </div>
                <div className="staff-edit-main">
                <div className="edit-first-name">
                        <div className="staff-manafement-light">
                          Phone Number
                        </div>
                        <NumberFormat displayType="input" format="(###) ###-####"
                        className="staff-first-input"
                        name="phone"
                        value={validation.values.phone}
                        onValueChange={(values) => {
                          validation.setFieldValue('phone', values.value)
                        }}
                        />
                        {validation.touched.phone &&
                        validation.errors.phone
                          ? (
                          <div className='error' type="invalid">
                            {validation.errors.phone}
                          </div>
                            )
                          : null}
                      </div>
                  {/* <div className="edit-first-name">
                    <div className="staff-manafement-light"> Phone Number</div>
                    <input
                      type="text"
                      className="staff-phn-no"
                      name="phoneCode"
                      onChange={(e) => {
                        if (e.target.value.length < 4) {
                          validation.setFieldValue('phoneCode', e.target.value)
                        }
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.phoneCode || ''}
                    />
                    <i className="mdi mdi-minus staff-minus"></i>{' '}
                    <input
                      type="text"
                      className="staff-phn-no staff-phn-landline"
                      style={{ width: '190px' }}
                      name="phone"
                      onChange={(e) => {
                        if (e.target.value.length < 8) {
                          validation.setFieldValue('phone', e.target.value)
                        }
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone || ''}
                    />
                    </div> */}
                    {/* {validation.touched.phoneCode &&
                    validation.errors.phoneCode ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.phoneCode}
                      </FormFeedback>
                    ) : (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.phone}
                      </FormFeedback>
                    )} */}
                  <div className="edit-first-name">
                    <div className="staff-manafement-light">Title*</div>
                    <Input
                      type="text"
                      className="staff-first-input"
                      name="designation"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.designation || ''}
                      invalid={
                        !!(validation.touched.designation &&
                        validation.errors.designation)
                      }
                    />
                    {validation.touched.designation &&
                    validation.errors.designation
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.designation}
                      </FormFeedback>
                        )
                      : null}
                  </div>
                </div>
                {(userObj.roleId == 3 || userObj.roleId == 2) &&
                <div className="edit-first-name mb-3">
                        <div className="staff-manafement-light">
                          Select Warehouse*
                        </div>
                        <Select options={warehouses} onChange={(e) => {
                          validation.setFieldValue(
                            'vendorWarehouseLocationId',
                            e.value
                          )
                        }}
                        defaultValue={warehouses.filter((warehouse) => {
                          return warehouse.value === details?.staffWareHouseLocation?.vendorWarehouseLocationId
                        })}
                        placeholder="Select Warehouse" />
                         {validation.touched.vendorWarehouseLocationId &&
                        validation.errors.vendorWarehouseLocationId
                           ? (
                          <div className='error' type="invalid">
                            {validation.errors.vendorWarehouseLocationId}
                          </div>
                             )
                           : null}
                      </div>}
                {currentRole === 1
                  ? null
                  : (
                  <div>
                    <div className="staff-manafement-light mb-2"> Type*</div>
                    <div className="mb-4">
                      <input
                        type="radio"
                        name="html"
                        id="radio1"
                        onClick={(e) => {
                          setRadio(true)
                          validation.setFieldValue('roleId', 4)
                          validation.setFieldValue('type', 4)
                          validation.setFieldValue('permissions', [])
                        }}
                        checked={validation.values.roleId == 4}
                      />{' '}
                      <span htmlFor="radio1">Staff</span>
                      <input
                        type="radio"
                        name="html"
                        style={{ marginLeft: '22px' }}
                        onClick={(e) => {
                          setRadio(false)
                          validation.setFieldValue('roleId', 5)
                          validation.setFieldValue('type', 5)
                          validation.setFieldValue('permissions', [])
                        }}
                        checked={validation.values.roleId == 5}
                      />{' '}
                      <span>Driver</span>
                    </div>
                  </div>
                    )}
                <div>
                  {radio
                    ? (
                    <div>
                      <div className="staff-manafement-dark mb-3">
                        Privileges*
                      </div>
                      <div className="staff-edit-main">
                        <div className="edit-first-name">
                          <div className="mb-1">
                            <input
                              type="checkbox"
                              id={'defaultCheckall'}
                              value={'all'}
                              onClick={(e) =>
                                handleCheckPermission(!e.target.checked, 'all')
                              }
                              checked={
                                selectedPermission.length ===
                                permissionsDataLeft.length +
                                  permissionsDataRight.length
                              }
                              style={{ cursor: 'pointer' }}
                              className="staff-check-box"
                            />
                            <label
                              className="staff-manafement-light"
                              htmlFor={'defaultCheckall'}
                              style={{ cursor: 'pointer' }}
                            >
                              All
                            </label>
                          </div>
                          {permissionsDataLeft.map((data) => {
                            const checked = selectedPermission.includes(
                              data.id
                            )
                            return (
                              <div key={data.id} className="mb-1">
                                <input
                                  type="checkbox"
                                  id={'defaultCheck' + data.name}
                                  value={data.id}
                                  onClick={(e) =>
                                    handleCheckPermission(
                                      !e.target.checked,
                                      data.id
                                    )
                                  }
                                  style={{ cursor: 'pointer' }}
                                  checked={checked}
                                  className="staff-check-box"
                                />
                                <label
                                  htmlFor={'defaultCheck' + data.name}
                                  style={{ cursor: 'pointer' }}
                                  className="staff-manafement-light"
                                >
                                  {data.name}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                        <div className="edit-first-name">
                          {permissionsDataRight.map((data) => {
                            const checked = selectedPermission.includes(
                              data.id
                            )
                            return (
                              <div key={data.id} className="mb-1">
                                <input
                                  type="checkbox"
                                  id={'defaultCheck' + data.name}
                                  value={data.id}
                                  onClick={(e) =>
                                    handleCheckPermission(
                                      !e.target.checked,
                                      data.id
                                    )
                                  }
                                  style={{ cursor: 'pointer' }}
                                  checked={checked}
                                  className="staff-check-box"
                                />
                                <label
                                  htmlFor={'defaultCheck' + data.name}
                                  style={{ cursor: 'pointer' }}
                                  className="staff-manafement-light"
                                >
                                  {data.name}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      {validation.errors.permissions
                        ? (
                            <FormFeedback
                              type="invalid"
                              style={{ display: 'block', marginTop: '-15px', marginBottom: '30px' }}
                            >
                              {validation.errors.permissions}
                            </FormFeedback>
                          )
                        : null}
                      {privilegesErrors
                        ? (
                            <FormFeedback
                              type="invalid"
                              style={{ display: 'block', marginTop: '-15px', marginBottom: '30px' }}
                            >
                              {privilegesErrors}
                            </FormFeedback>
                          )
                        : null}
                    </div>
                      )
                    : (
                    <div className="staff-edit-main">
                      <div className="edit-first-name">
                        <div className="staff-manafement-light">
                          {' '}
                          Driver Code (optional)
                        </div>
                        <Input
                          type="text"
                          className="staff-first-input"
                          name="driverCode"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.driverCode || ''}
                          invalid={
                            !!(validation.touched.driverCode &&
                            validation.errors.driverCode)
                          }
                        />
                        {validation.touched.driverCode &&
                        validation.errors.driverCode
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.driverCode}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                      <div className="edit-first-name">
                        <div className="staff-manafement-light">
                          Vehical Number (optional)
                        </div>
                        <Input
                          type="text"
                          className="staff-first-input"
                          name="vehicleNumber"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.vehicleNumber || ''}
                          invalid={
                            !!(validation.touched.vehicleNumber &&
                            validation.errors.vehicleNumber)
                          }
                        />
                        {validation.touched.vehicleNumber &&
                        validation.errors.vehicleNumber
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.vehicleNumber}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </div>
                      )}
                </div>

                <button className="staff-save-changes staff-btn-clr">
                  Save Changes
                </button>
                <button
                  className="staff-cancel-btn staff-btn-clr"
                  onClick={() => setEdit(true)}
                >
                  Cancel
                </button>
              </CardBody>
            </Card>
          </Form>
        </Col>
      </Row>
    </>
  )
}
export default EditStaff
