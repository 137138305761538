/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'
import disableIcon from '../../assets/images/disable.svg'
import delIcon from '../../assets/images/ic_delete.svg'
import moment from 'moment'

export default function CheckBoxTable (props) {
  const [content, setContent] = useState(null)
  const [allCheck, setAllCheck] = useState(false)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    if (props.content) {
      setContent(props.content)
    }
  }, [props.content])

  // handles the main checkbox which checks and unchecks in one go
  function handleAllCheck (e) {
    if (!allCheck) {
      setAllCheck(true)
      setContent(
        content.map((item, i) => {
          return { ...item, checked: true }
        })
      )
    } else {
      setAllCheck(false)
      setContent(
        content.map((item, i) => {
          return { ...item, checked: false }
        })
      )
    }
  }

  // handles the checkbox check and uncheck functionality
  function handleChangeDiscount (e, index) {
    const updatedArr = [...content]
    if (updatedArr[index].checked) {
      updatedArr[index].checked = false
      setContent(updatedArr)
      setAllCheck(false)
    } else {
      updatedArr[index].checked = true
      setContent(updatedArr)
    }
  }
  return (
    <div className="table-responsive">
      <Table className="text-center">
        <thead className="table--head">
          <tr>
            <th>
              <input
                type="checkbox"
                className="input-check-box"
                checked={allCheck}
                onClick={(e) => {
                  handleAllCheck(e)
                }}
              />
            </th>
            {props?.columns?.map((col, index) => {
              return <th key={index}>{col.label}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {content?.length > 0 &&
            content.map((item, i) => {
              return (
                <tr key={i}>
                  <td>
                    <input
                      type="checkbox"
                      checked={item.checked}
                      className="input-check-box"
                      onClick={(e) => handleChangeDiscount(e, i)}
                    />
                  </td>
                  {props.columns.map((col, index) => {
                    if (col.key == 'action') {
                      return (
                        <td key={index}>
                          <img
                            src={disableIcon}
                            onClick={(e) => {
                              props.action(e, 'disable', item)
                            }}
                          />
                          <img
                            className="ms-4"
                            src={delIcon}
                            onClick={(e) => {
                              props.action(e, 'delete', item)
                            }}
                          />
                        </td>
                      )
                    } else if (col.key == 'status') {
                      if (item.status == 1) {
                        return (
                          <td key={index} onClick={(e) => props.handleRow(e, item)}>
                            <div className="active-div mx-auto">Active</div>
                          </td>
                        )
                      } else {
                        return (
                          <td key={index} onClick={(e) => props.handleRow(e, item)}>
                            <div className="disabled-div mx-auto">Inactive</div>
                          </td>
                        )
                      }
                    } else if (col.key == 'startdate') {
                      return (
                        <td key={index} onClick={(e) => props.handleRow(e, item)}>
                          {moment(item[col.key]).format('MM-DD-YYYY')}
                        </td>
                      )
                    } else if (col.key === 'type') {
                      return (
                        <td key={index}>
                          {
                            parseInt(item[col.key]) === 0 ? 'Fixed Amount' : parseInt(item[col.key]) === 1 ? 'Percentage' : 'Bulk Buying Discount'
                          }
                        </td>
                      )
                    } else {
                      return <td key={index} onClick={(e) => props.handleRow(e, item)}>{item[col.key]}</td>
                    }
                  })}
                </tr>
              )
            })}
        </tbody>
      </Table>
      {Math.ceil(props.paging.totalRecord / props.paging.recordPerPage) > 1
        ? (
        <div className="d-flex align-items-center justify-content-center mt-3 mb-1">
          <div
            style={{ cursor: 'pointer' }}
            onClick={() =>
              props.paging.currentPage === 1
                ? ''
                : props.paginationHandler(props.paging.currentPage - 1)
            }
            className="pagination-box"
          >
            <i className="fas fa-angle-left" style={{ fontSize: '18px' }}></i>{' '}
          </div>
          {[
            ...Array(
              Math.ceil(props.paging.totalRecord / props.paging.recordPerPage)
            )
          ].map((x, i) => (
            <div
              style={{ cursor: 'pointer' }}
              key={i}
              onClick={() => props.paginationHandler(i + 1)}
              className={
                props.paging.currentPage === i + 1
                  ? 'pagination-box pagination-box-active'
                  : 'pagination-box'
              }
            >
              {i + 1}
            </div>
          ))}
          <div
            style={{ cursor: 'pointer' }}
            onClick={() =>
              props.paging.currentPage ===
              Math.ceil(props.paging.totalRecord / props.paging.recordPerPage)
                ? ''
                : props.paginationHandler(props.paging.currentPage + 1)
            }
            className="pagination-box"
          >
            <i className="fas fa-angle-right" style={{ fontSize: '18px' }}></i>{' '}
          </div>
        </div>
          )
        : (
        <></>
          )}
    </div>
  )
}

CheckBoxTable.propTypes = {
  columns: PropTypes.array,
  content: PropTypes.array,
  action: PropTypes.func,
  paging: PropTypes.object,
  paginationHandler: PropTypes.func,
  handleRow: PropTypes.func
}
