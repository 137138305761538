import React, { useState, useCallback } from 'react'
import TableRow from './TableRow'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from './arrayMove'
import PropTypes from 'prop-types'

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>
})

const SortableItem = SortableElement(props => <TableRow {...props} />)

const MyTable = (props) => {
  const [items, setItems] = useState(props.data)

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setItems(oldItems => arrayMove(oldItems, oldIndex, newIndex))
  }, [])

  return (
      <table className="table mb-0">
        <thead className="table-light">
          <tr>
            {
              props.columns.map((item) => {
                return <th key={item.id}>{item.label}</th>
              })
            }
          </tr>
        </thead>
        <SortableCont
          onSortEnd={onSortEnd}
          axis="y"
          lockAxis="y"
          lockToContainerEdges={true}
          lockOffset={['30%', '50%']}
          helperClass="helperContainerClass"
          useDragHandle={true}
        >
          {
            props.actions
              ? <>
              {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              values={value}
              columns={props.columns}
              deleteAction={props.deleteAction}
              firstCol={props.firstCol}
            />
              ))}
            </>
              : <>
            {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              values={value}
            />
            ))}
            </>
          }
        </SortableCont>
      </table>
  )
}

export default MyTable

MyTable.propTypes = {
  data: PropTypes.any,
  columns: PropTypes.any,
  actions: PropTypes.bool,
  deleteAction: PropTypes.any,
  firstCol: PropTypes.string
}
