import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledTooltip
} from 'reactstrap'
import './Category.scss'
import Select from 'react-select'
import { PostData, GetData, PutData } from 'apiHandler'
import toastr from 'toastr'
import Loader from '../../components/Loader'
import { Pagination } from '@mui/material'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

let tableData = []
const recordPerPage = 10
let searchTimer

let filterByKeyObject = { key: 'partNumber' }
const Mapping = () => {
  const [mappingList, setMappingList] = useState([])
  const [singlebtn, setSinglebtn] = useState(false)
  const filterTypes = [{
    value: 'partNumber',
    key: 'Part Number'
  },
  {
    value: 'PartTerminology',
    key: 'Part Terminology Name'
  },
  {
    value: 'brand',
    key: 'Brand'
  }
  ]
  const [categoryList, setCategoryList] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])
  const userObj = JSON.parse(localStorage.authUser)
  const history = useHistory()
  const [tableMessage, setTableMessage] = useState('Please wait loading...')
  const [loader, setLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const [firstRender, setFirstRender] = useState(true)
  const [dumpDate, setDumpDate] = useState('')
  const [paging, setPaging] = useState({ totalRecord: 0, recordPerPage: 10 })
  const [selectedFilter, setSelectedFilter] = useState({
    value: 'partNumber',
    key: 'Part Number'
  })
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })

  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getCategoryMapping(currentPage)
  }, [sortingState])

  useEffect(() => {
    getCategoryList()
    setFirstRender(false)
  }, [])

  useEffect(() => {
    getCategoryMapping(currentPage)
  }, [currentPage])

  useEffect(() => {
    return () => {
      filterByKeyObject = { key: 'partNumber' }
    }
  }, [])

  useEffect(() => {
    // if (userObj.roleId === 3 || userObj.roleId === 2) {
    //   history.push('/dashboard')
    // }
    getLatestDumpDate()
  }, [])

  const getCategoryList = () => {
    GetData('admin/getCategoriesForCategoryMapping').then((response) => {
      if (response.status === true) {
        const categoryData = []
        const subCategoryData = {}
        response.result.map((category) => {
          categoryData.push({ label: category.categoryName, value: category.categoryID })
          if (category.children.length) {
            const subCategoryDataParsed = []
            category.children.map((subCategory) => {
              subCategoryDataParsed.push({ label: subCategory.subCategoryName, value: subCategory.subcategoryID })
              return null
            })
            subCategoryData[category.categoryID] = subCategoryDataParsed
          }
          return null
        })
        setSubCategoryList(subCategoryData)
        setCategoryList(categoryData)
        // getCategoryMapping()
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const searchByText = (t) => {
    setSearchValue(t)
  }

  useEffect(() => {
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    if (!firstRender) {
      searchTimer = setTimeout(() => {
        filterByKeyObject.string = searchValue
        getCategoryMapping(1)
      }, 2000)
    }
  }, [searchValue])

  const getCategoryMapping = (p) => {
    const data = {
      page: p,
      pageSize: recordPerPage
    }
    if (searchValue !== '') {
      data.filterBy = filterByKeyObject.key
      data.search = searchValue
    }
    if (sortingState.sortColumn != null) {
      data.sortBy = sortingState.sortColumn
      data.orderBy = sortingState.order.toUpperCase()
    }
    setTableMessage('Please wait loading...')
    setLoader(true)
    PostData('admin/getProductsForCategoryMapping', data).then((response) => {
      setLoader(false)
      if (response.status === true) {
        if (response?.result?.products?.length === 0) {
          setTableMessage('No search results...')
        }
        tableData = JSON.parse(JSON.stringify(response.result.products))
        setPaging({ totalRecord: response.result.total, recordPerPage })
        setMappingList(response.result.products)
      } else {
        setTableMessage('Data not available')
        toastr.error(response.message, 'Error')
      }
    })
  }

  const dropToggle = () => {
    setSinglebtn(!singlebtn)
  }

  const handleCategoryChange = (e, key) => {
    const mappingListTemp = [...mappingList]
    mappingListTemp[key].categoryID = e.value
    mappingListTemp[key].partCategory = e.label

    mappingListTemp[key].subcategoryID = subCategoryList[e.value][0].value
    mappingListTemp[key].partSubCategory = subCategoryList[e.value][0].label
    mappingListTemp[key].update = true

    setMappingList(mappingListTemp)
  }

  const handleSubCategoryChange = (e, key) => {
    const mappingListTemp = [...mappingList]
    mappingListTemp[key].subcategoryID = e.value
    mappingListTemp[key].partSubCategory = e.label
    mappingListTemp[key].update = true

    setMappingList(mappingListTemp)
  }

  const handleExport = (e) => {
    e.preventDefault()
    let url = 'admin/exportProductsForCategoryMapping'

    if (selectedFilter?.value && searchValue) {
      url += `?filterBy=${selectedFilter?.value}&search=${searchValue}`
    }
    setLoader(true)
    GetData(url).then((response) => {
      if (response.status === true || response.status === 200) {
        setLoader(false)
        const a = document.createElement('a')
        a.href = 'https://' + response.result
        a.download = 'download'
        a.click()
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  const filterByKey = (obj) => {
    filterByKeyObject.key = obj.value
    setSelectedFilter(obj)
    if (filterByKeyObject.string) {
      getCategoryMapping(1)
    }
  }

  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn === column) {
      if (sortingState.order === 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }

  const getLatestDumpDate = () => {
    const url = 'admin/getLatestDatabaseDumpInfo'
    GetData(url).then((response) => {
      if (response.status === true || response.status === 200) {
        setLoader(false)
        setDumpDate(response.result.lastUpdatedAt)
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  const updateMapping = (key, check) => {
    const mappingListTemp = [...mappingList]
    if (check === true) {
      const data = {
        partTerminogyId: mappingListTemp[key].PartTerminologyID,
        categoryId: mappingListTemp[key].categoryID,
        subcategoryId: mappingListTemp[key].subcategoryID
      }
      setLoader(true)
      PutData('admin/updateTerminologyCategoryMapping', data).then((response) => {
        setLoader(false)
        if (response.status === true) {
          mappingListTemp[key].update = false
          setMappingList(mappingListTemp)
          getCategoryMapping(1)
          toastr.success(response.message, 'Success')
        } else {
          toastr.error(response.message, 'Error')
        }
      })
    } else {
      mappingListTemp[key].categoryID = tableData[key].categoryID
      mappingListTemp[key].partCategory = tableData[key].partCategory

      mappingListTemp[key].subcategoryID = tableData[key].subcategoryID
      mappingListTemp[key].partSubCategory = tableData[key].partSubCategory
      mappingListTemp[key].update = false

      setMappingList(mappingListTemp)
    }
  }

  function handlePage (e, value) {
    e.preventDefault()
    setCurrentPage(value)
  }

  return (
    <div className="page-content">
    <Loader show={loader}/>
    <Container fluid={true} className="customer-container">
      <div className="products-nav">
        <span className="products-heading">Category Mapping</span>
        <div className="customer-search-form new-form-search d-flex" style={{ width: '700px' }}>
        <button className="products-nav-btn products-export-btn p"
                onClick={handleExport}
                >
                  Export
                </button>
                <Dropdown
                        isOpen={singlebtn}
                        toggle={dropToggle}
                         style={{ width: '300px' }}
                      >
                        <DropdownToggle className="customer-select text-dark" style={{ width: '300px' }}>
                          Filter By{' '}<span style={{ width: '200px' }} className="font-weight-bold">
                            {selectedFilter.key}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                        {filterTypes.length > 0 && filterTypes.map((filterObj, key) => {
                          return (
                              <DropdownItem
                                key={key}
                                onClick={() => filterByKey(filterObj)}
                              >
                                {filterObj.key}
                              </DropdownItem>
                          )
                        })}
                        </DropdownMenu>
                      </Dropdown>
                        <input
                          className="form-control input-border customer-input-border"
                          type="text"
                          placeholder="Search"
                          onChange={(e) => searchByText(e.target.value)}
                          value={searchValue}
                        />
                        <i className="bx bx-search-alt-2 search-icon"></i>
                      </div>
      </div>
      <Row className="mt-3">
        <Col lg={12}>
          <Card className='table-card'>
            <CardBody>
            <p style={{ textAlign: 'right' }}>Latest Data Dump Date: <b>{ dumpDate ? moment(dumpDate).format('MMMM DD, YYYY') : '--' }</b></p>
              <Row className='table-row'>
                <Col lg={2} xs={2} className='heading-tab d-flex flex-column justify-content-center'>
                    <p>Part Number</p>
                </Col>
                <Col lg={2} xs={2} className='heading-tab d-flex flex-column justify-content-center'>
                  <p>Brand</p>
                </Col>
                <Col lg={2} xs={2} className='heading-tab d-flex flex-column justify-content-center'>
                    <p>Part Terminology Name {
                      (sortingState.order && sortingState.sortColumn === 'PartTerminologyName')
                        ? <>
                        {
                          (sortingState.order === 'asc' && sortingState.sortColumn === 'PartTerminologyName')
                            ? <i
                        className="bx bx-down-arrow-alt sorting-arrow"
                        onClick={(e) => handleSort(e, 'PartTerminologyName')}
                      ></i>
                            : <i
                      className="bx bx-up-arrow-alt sorting-arrow"
                      onClick={(e) => handleSort(e, 'PartTerminologyName')}
                    ></i>
                        }
                      </>
                        : <i
                          className="bx bx-sort sorting-arrow"
                          onClick={(e) => handleSort(e, 'PartTerminologyName')}
                        ></i>
                      }
                          </p>
                </Col>
                <Col lg={2} xs={2} className='heading-tab light d-flex flex-column justify-content-center'>
                    <p>QLS Category {
                      (sortingState.order && sortingState.sortColumn === 'categoryName')
                        ? <>
                        {
                          (sortingState.order === 'asc' && sortingState.sortColumn === 'categoryName')
                            ? <i
                        className="bx bx-down-arrow-alt sorting-arrow"
                        onClick={(e) => handleSort(e, 'categoryName')}
                      ></i>
                            : <i
                      className="bx bx-up-arrow-alt sorting-arrow"
                      onClick={(e) => handleSort(e, 'categoryName')}
                    ></i>
                        }
                      </>
                        : <i
                          className="bx bx-sort sorting-arrow"
                          onClick={(e) => handleSort(e, 'categoryName')}
                        ></i>
                      }</p>
                </Col>
                <Col lg={2} xs={2} className='heading-tab light d-flex flex-column justify-content-center'>
                    <p>QLS Subcategory</p>
                </Col>
                <Col lg={2} xs={2} className='heading-tab light d-flex flex-column justify-content-center'>
                    &nbsp;
                </Col>
              </Row>
                {
                  mappingList.length > 0
                    ? mappingList.map((list, key) => {
                      return (
                                <Row key={key} className='table-row'>
                                <Col lg={2} xs={2} className='input-tab'>
                                    <Select
                                        isDisabled={true}
                                        value={{ value: list.part_id, label: list.partNumber }}
                                        options={[{ value: list.part_id, label: list.partNumber }]}
                                        classNamePrefix="select2-selection"
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    />
                                </Col>
                                <Col lg={2} xs={2} className='input-tab'>
                                    <Select
                                        isDisabled={true}
                                        value={{ value: list.brandName, label: list.brandName }}
                                        options={[{ value: list.brandName, label: list.brandName }]}
                                        classNamePrefix="select2-selection"
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    />
                                </Col>
                                <UncontrolledTooltip
                                      placement="top"
                                      target={'message' + key}
                                    >
                                      { list.PartTerminologyName }
                                    </UncontrolledTooltip>
                                <Col lg={2} xs={2} id={'message' + key} className='input-tab'>
                                    <Select
                                        isDisabled={true}
                                        value={{ value: list.PartTerminologyID, label: list.PartTerminologyName }}
                                        options={[{ value: list.PartTerminologyID, label: list.PartTerminologyName }]}
                                        classNamePrefix="select2-selection"
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    />
                                </Col>
                                <Col lg={2} xs={2} className='input-tab light'>
                                    <Select
                                            value={{ value: list.categoryID, label: list.partCategory }}
                                            onChange={(e) => {
                                              handleCategoryChange(e, key)
                                            }}
                                            options={categoryList}
                                            classNamePrefix="select2-selection"
                                        />
                                </Col>
                                <Col lg={2} xs={2} className='input-tab light'>
                                    <Select
                                            value={{ value: list.subcategoryID, label: list.partSubCategory }}
                                            onChange={(e) => {
                                              handleSubCategoryChange(e, key)
                                            }}
                                            options={subCategoryList[list.categoryID]}
                                            classNamePrefix="select2-selection"
                                        />
                                </Col>
                                <Col lg={2} xs={2} className='input-tab light d-flex justify-content-center align-items-center'>
                                    {
                                        list.update && (
                                            <div className='mx-1'>
                                                <i onClick={() => updateMapping(key, true)} className='fas fa-check-circle input-icon-tick mr-1'></i>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <i onClick={() => updateMapping(key, false)} className='fas fa-times-circle input-icon-cross'></i>
                                            </div>
                                        )
                                    }
                                </Col>
                                </Row>
                      )
                    })
                    : <Row> <Col lg={12} sm={12} className='input-tab'> {tableMessage} </Col></Row>
                }
                {
                    Math.ceil(paging.totalRecord / paging.recordPerPage) > 1 && <div className='d-flex justify-content-center mt-4'>
                        <Pagination count={Math.ceil(paging.totalRecord / paging.recordPerPage)} page={currentPage} onChange={handlePage} variant="outlined" color="primary" shape="rounded" />
                    </div>
                }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
  )
}

export default Mapping
