import MetaTags from 'react-meta-tags'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  FormFeedback
} from 'reactstrap'
import { Link, useParams, useHistory } from 'react-router-dom'
import logo from 'assets/images/QLlogo.svg'
import * as Yup from 'yup'
import { PostWithoutToken } from 'apiHandler'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Loader from '../../components/Loader'
import eye from 'assets/images/view.svg'
import hide from 'assets/images/hide.svg'

const SetPassword = (props) => {
  const params = useParams()
  const history = useHistory()
  const [serverResponse, setServerResponse] = useState()
  const [showLoader, setShowLoader] = useState(true)
  const [passwordFieldType, setPasswordFieldType] = useState({
    newPassword: 'password',
    confirmNewPassword: 'password'
  })

  const verifyToken = () => {
    setShowLoader(true)
    const url = 'admin/validateEmailToken'
    PostWithoutToken(url, { verificationToken: params.token }).then((response) => {
      if (response.status === true) {
        setServerResponse(response.status)
        setShowLoader(false)
      } else {
        setServerResponse(response.status)
        setShowLoader(false)
      }
    })
  }

  useEffect(() => {
    verifyToken()
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
      confirmpassword: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Password is required')
        // .matches(
        //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        // )
        .nullable(),
      confirmpassword: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password'), null], 'Confirm password should be same')
        .nullable()
    }),
    onSubmit: (values) => {
      const body = {
        password: values.password,
        verificationToken: params.token
      }
      const url = 'admin/createAccountPassword'
      PostWithoutToken(url, body).then((response) => {
        if (response.status === true) {
          history.replace({ pathname: '/password-successful' })
        } else {
          toastr.error(response.message, 'Error')
        }
      })
    }
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Reset Password | QLS</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
      <Loader show={showLoader} />
        <Container>
          <Row className="justify-content-center">
            <Col md={7} lg={5} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="p-4">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div
                        className="avatar-md profile-user-wid mb-2 mt-3"
                        style={{ width: '100%', marginTop: '0px' }}
                      >
                        <span
                          className="avatar-title"
                          style={{ backgroundColor: 'transparent' }}
                        >
                          <img src={logo} alt="" height="42" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  {
                    serverResponse
                      ? <>
                      <div className="d-flex justify-content-center mb-2 set-password">
                        Set Your Password
                      </div>
                      <div className="d-flex justify-content-center mb-2 please-set-password">
                        Please set a new password for your account
                      </div>
                      <div className="p-2">
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3 input-div-set-password">
                            <Input
                              name="password"
                              className="new-password "
                              placeholder="New Password"
                              type={passwordFieldType.newPassword}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ''}
                              invalid={
                                !!(validation.touched.password &&
                                validation.errors.password)
                              }
                            />
                          {
                          passwordFieldType.newPassword === 'password'
                            ? <img src={eye} className='eye-icons-password position-absolute' onClick={() => setPasswordFieldType(prevState => ({ ...prevState, newPassword: 'text' }))}/>
                            : <img src={hide} className='eye-icons-password position-absolute' onClick={() => setPasswordFieldType(prevState => ({ ...prevState, newPassword: 'password' }))}/>
                        }
                            {validation.touched.password &&
                            validation.errors.password
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                                )
                              : null}
                          </div>

                          <div className="mb-3 input-div-set-password">
                            <Input
                              className="new-password"
                              name="confirmpassword"
                              type={passwordFieldType.confirmNewPassword}
                              placeholder="Confirm New Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirmpassword || ''}
                              invalid={
                                !!(validation.touched.confirmpassword &&
                                validation.errors.confirmpassword)
                              }
                            />
{
                          passwordFieldType.confirmNewPassword === 'password'
                            ? <img src={eye} className='eye-icon-confirm-password position-absolute' onClick={() => setPasswordFieldType(prevState => ({ ...prevState, confirmNewPassword: 'text' }))}/>
                            : <img src={hide} className='eye-icon-confirm-password position-absolute' onClick={() => setPasswordFieldType(prevState => ({ ...prevState, confirmNewPassword: 'password' }))}/>
                        }
                            {validation.touched.confirmpassword &&
                            validation.errors.confirmpassword
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.confirmpassword}
                              </FormFeedback>
                                )
                              : null}
                          </div>

                          <div className="mt-3 mb-3 d-grid">
                            <button
                              className="btn btn-block set-password-btn"
                              type="submit"
                            >
                              Save
                            </button>
                          </div>
                        </Form>
                  </div>
                  </>
                      : showLoader
                        ? <></>
                        : <>
                        <div className="d-flex justify-content-center mt-2 mb-1 set-password">
                          Invalid Token!
                        </div>
                        <div className="d-flex justify-content-center mb-4">
                          <Link to="/login">
                            <button className="login-here">Login Here</button>
                          </Link>
                        </div>
                      </>
                  }
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="lower-privacy">Privacy & Policy | Terms of Use</p>
                <p className="lower-copyright">
                  Copyright © 2021 QL SUPPLY. All rights reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SetPassword
