import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import PdfReports from 'components/PdfReports/PdfReports'
import NumberFormat from 'react-number-format'
function ReportsOrderFillVendor () {
  const [data, setData] = useState(null)
  const [pdfData, setPdfData] = useState(null)
  const location = useLocation()
  React.useEffect(() => {
    setData(location.state)
  }, [location])
  const columns = [
    { key: 'orderNumber', label: 'Order No' },
    { key: 'totalOrder', label: 'Ordered Quantity', sorting: true },
    { key: 'totalfulfilledQuantity', label: 'Fulfilled Quantity', sorting: true },
    { key: 'avgtotal', label: 'Avg. Total', sorting: true }
  ]
  React.useEffect(() => {
    if (data) {
      setPdfData(data?.data.orders.map((item) => {
        return {
          orderNumber: item.productorder.parentOrder.orderNumber,
          totalOrder: item.totalOrder,
          totalfulfilledQuantity: item.totalfulfilledQuantity,
          avgtotal: <NumberFormat
            value={item.avgtotal}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        }
      }))
    }
  }, [data])
  return (
        <>
        {
          pdfData && <PdfReports
          columns={columns}
          content={pdfData}
          />
        }
        </>
  )
}
export default ReportsOrderFillVendor
