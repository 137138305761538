/* eslint-disable multiline-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Label,
  Form,
  Button,
  FormFeedback
} from 'reactstrap'
import deleteLogo from '../../assets/images/ic_delete.svg'
import info from '../../assets/images/info.svg'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { GetData, PostData } from '../../apiHandler'
import * as Yup from 'yup'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { useHistory } from 'react-router-dom'
import Loader from '../../components/Loader'
import { globalVariables } from 'globalVariables'
import InfiniteScroll from 'react-infinite-scroll-component'
import NumberFormat from 'react-number-format'

let searchTimer
let searchKeyword = ''

// eslint-disable-next-line react/display-name
export default React.memo((props) => {
  const history = useHistory()
  const DROPDOWN = { LOCATION: 'locations', CATEGORY: 'categories' }
  const [loader, setLoader] = useState(false)
  const [locations, setLocations] = useState([])
  const [searchText, setSearchText] = useState('')
  const [categoryData, setCategoryData] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [citiesLocations, setCitiesLocations] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])
  const [allBrands, setAllBrands] = useState([])
  const [brandPage, setBrandPage] = useState()
  const [selectedBrands, setSelectedBrands] = useState([])
  const [selectedBrandIds, setSelectedBrandsIds] = useState([])
  const [primaryAccounting, setPrimaryAccounting] = useState(false)
  const [primaryIt, setPrimaryIt] = useState(false)
  const scrollRef = useRef()
  const [mobile, setMobile] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [fileObj, setFileObj] = useState({})
  const [dupLocationError, setDupLocationError] = useState(false)
  const [emailandmobileerror, setemailandmobileerror] = useState({
    email: '',
    mobile: ''
  })
  const [newPhone, setNewPhone] = useState({
    BusinessNumber: '',
    ContactNumber: ''
  })

  const [isEDIWD, setIsEDIWD] = useState(false)

  // ----------------function handle EDI WD checkbox-------------
  const handleEdiWdCheckbox = () => {
    setIsEDIWD(!isEDIWD);
  };


  useEffect(() => {
    getCategories()
    getProgramAffiliation()
    getStates()
  }, [])

  useEffect(() => {
    if (selectedCategory.length > 0) {
      setAllBrands([])
      getBrands(1)
    } else setAllBrands([])
  }, [selectedCategory])

  const getBrands = (p) => {
    setBrandPage(p)
    setLoader(true)
    const url = 'admin/getAllVcdbBrandsByCategory'
    const body = {
      page: p,
      pageSize: 20,
      categories: selectedCategory
    }
    if (searchKeyword !== '') {
      body.search = searchKeyword
    }
    // if (searchKeyword !== '') {
    //   url = `admin/getAllVcdbBrands?page=${p}&pageSize=20&search=${searchKeyword}`
    // } else {
    //   url = `admin/getAllVcdbBrands?page=${p}&pageSize=20`
    // }
    PostData(url, body).then((response) => {
      if (response.status === true) {
        setLoader(false)
        if (response.result.length > 0) {
          const newBrands = response.result.map((item) => {
            return { label: item.BrandName, value: item.BrandID }
          })
          // setAllBrands(newBrands)
          if (searchKeyword && p === 1) {
            scrollRef.current.scrollTo(0, 0)
            setAllBrands(newBrands)
          } else setAllBrands(prevState => [...prevState, ...newBrands])
        }
      } else {
        toastr.error(response.message, 'Error')
        setLoader(false)
      }
    })
  }

  const fetchNextBrands = () => {
    getBrands(parseInt(brandPage) + 1)
  }

  const filterBrand = (e) => {
    setSearchText(e.target.value)
    if (e.target.value === '') {
      setAllBrands([])
    }
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      searchKeyword = e.target.value
      getBrands(1)
    }, 1000)
  }

  const handleBrandCheckbox = (brand, e) => {
    if (!e.target.checked) {
      const updatedCheck = [...selectedBrands]
      const brandData = { ...brand }
      brandData.checked = true
      updatedCheck.push(brandData)
      setSelectedBrands(updatedCheck)
      setSelectedBrandsIds(updatedCheck.map((brand) => {
        return brand.value
      }))
      validation.setFieldValue('brands', updatedCheck.map((brand) => {
        return brand.value
      }))
    } else if (e.target.checked) {
      const data = selectedBrands.filter((item, i) => {
        return item.value !== brand.value
      })
      setSelectedBrands(data)
      setSelectedBrandsIds(data.map((brand) => {
        return brand.value
      }))
      validation.setFieldValue('brands', data.map((brand) => {
        return brand.value
      }))
    }
  }

  const handleDeleteBrand = (e, brand) => {
    const data = selectedBrands.filter((item, i) => {
      return item.value !== brand.value
    })
    setSelectedBrands(data)
    setSelectedBrandsIds(data.map((brand) => {
      return brand.value
    }))
    validation.setFieldValue('brands', data.map((brand) => {
      return brand.value
    }))
  }

  const getStates = () => {
    const url = `states?countryId=${globalVariables.countryId}`
    GetData(url).then((response) => {
      if (response.status === true) {
        if (response.result.length) {
          setStates(response.result)
        } else {
          setStates([])
        }
      } else {
        setStates([])
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getCities = (id) => {
    const url = `cities?countryId=${globalVariables.countryId}&stateId=` + id
    GetData(url).then((response) => {
      if (response.status === true) {
        if (response.result.length) {
          setCities(response.result)
        } else {
          setCities([])
        }
      } else {
        setCities([])
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getCitiesLocations = (id, index) => {
    const url = `cities?countryId=${globalVariables.countryId}&stateId=` + id
    GetData(url).then((response) => {
      if (response.status === true) {
        if (response.result.length) {
          setCitiesLocations((prev) => ({ ...prev, [index]: response.result }))
        }
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getCategories = () => {
    GetData('admin/getCategoriesForAdmin').then((response) => {
      if (response.status === true) {
        const tableData = response.result.map((data, index) => {
          const tableData = data.open = index == 0
          return data
        })
        setCategoryData(tableData)
      }
    })
  }

  const getProgramAffiliation = () => {
    try {
      const commonData = JSON.parse(localStorage.getItem('commonData'))
      const caarr = []
      const data = commonData.programAffiliation
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key)) {
          caarr.push({ id: key, name: data[key] })
        }
      }
    } catch (e) {
      console.log('e: ', e)
    }
  }

  function handleOpen (dropdown, i, e) {
    e.preventDefault()
    let temp
    dropdown == DROPDOWN.LOCATION
      ? (temp = [...locations])
      : (temp = [...categoryData])
    if (temp[i].open == true) {
      temp[i].open = false
      dropdown == DROPDOWN.LOCATION
        ? setLocations(temp)
        : setCategoryData(temp)
    } else {
      temp[i].open = true
      dropdown == DROPDOWN.LOCATION
        ? setLocations(temp)
        : setCategoryData(temp)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userName: 'wd1',
      primaryBillingCountryId: globalVariables.countryId,
      companyName: '',
      email: '',
      mobile: '',
      programAffiliation: '',
      dateCompanyStarted: '',
      primaryBillingStreet: '',
      primaryBillingStateId: '',
      primaryBillingCityId: '',
      primaryBillingZipCode: '',
      reSaleTaxNumber: '',
      primaryFirstName: '',
      primaryLastName: '',
      primaryEmail: '',
      primaryPhone: '',
      primaryTitle: '',
      alternateFirstName: '',
      alternateLastName: '',
      alternateEmail: '',
      alternatePhone: '',
      alternateTitle: '',
      itFirstName: '',
      itLastName: '',
      itEmail: '',
      itPhone: '',
      itTitle: '',
      accountingFirstName: '',
      accountingLastName: '',
      accountingEmail: '',
      accountingPhone: '',
      accountingTitle: '',
      locations: [
        {
          locationName: '',
          shippingStreet: '',
          shippingCountryId: globalVariables.countryId,
          shippingStateId: '',
          shippingCityId: '',
          shippingZipCode: '',
          contactFirstName: '',
          contactLastName: '',
          contactEmail: '',
          contactPhone: '',
          contactTitle: '',
          isPrimaryLocation: true,
          open: true
        }
      ]
    },
    validationSchema: Yup.object().shape({
      companyName: Yup.string().required('Please enter Company Name'),
      email: Yup.string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
      mobile: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required('Please enter Mobile Number')
        .test(
          'maxDigits',
          'Must be 10 digits',
          (number) => String(number).length === 10
        ),
      // programAffiliation: Yup.string().required(
      //   'Please enter Group Affiliation'
      // ),
      // dateCompanyStarted: Yup.string().required(
      //   'Please enter Company Start Date'
      // ),
      primaryBillingStreet: Yup.string().required(
        'Please enter Primary Billing Street'
      ),
      primaryBillingStateId: Yup.string().required(
        'Please enter Primary Billing State'
      ),
      primaryBillingCityId: Yup.string().required(
        'Please enter Primary Billing City'
      ),
      primaryBillingZipCode: Yup.number().required(
        'Please enter Primary Billing Zip Code'
      ),
      // reSaleTaxNumber: Yup.string().required("Please enter Resale Tax Number"),
      primaryFirstName: Yup.string().required(
        'Please enter Primary First Name'
      ),
      primaryLastName: Yup.string().required('Please enter Primary Last Name'),
      primaryEmail: Yup.string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
      primaryPhone: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required('Please enter Primary Phone Number')
        .test(
          'maxDigits',
          'Must be 10 digits',
          (number) => String(number).length === 10
        ),
      primaryTitle: Yup.string().required('Please enter Primary Title'),
      // accountingFirstName: Yup.string().required(
      //   'Please enter Accounting First Name'
      // ),
      // accountingLastName: Yup.string().required(
      //   'Please enter Accounting Last Name'
      // ),
      // accountingEmail: Yup.string('Enter your email')
      //   .email('Enter a valid email')
      //   .required('Email is required'),
      accountingPhone: Yup.string()
        .notRequired()
        .nullable(true)
        .min(10, 'Must be equal to 10 numbers')
        .max(10, 'Must be equal to 10 numbers')
        .typeError("That doesn't look like a phone number"),
      // accountingTitle: Yup.string().required('Please enter Accounting Title'),
      // itFirstName: Yup.string()
      //   .trim()
      //   .required('Please Enter First Name'),
      // itLastName: Yup.string()
      //   .trim()
      //   .required('Please Enter Last Name'),
      // itEmail: Yup.string('Enter your email')
      //   .trim()
      //   .email('Enter a valid email')
      //   .required('Email is required'),
      itPhone: Yup.string()
        .notRequired()
        .nullable(true)
        .min(10, 'Must be equal to 10 numbers')
        .max(10, 'Must be equal to 10 numbers')
        .typeError("That doesn't look like a phone number"),
      // itTitle: Yup.string().trim().required('Please Enter Title'),
      categories: Yup.array().required('Please select categories').min(1, 'Please select at least one category'),
      brands: Yup.array().required('Please select brands').min(1, 'Please elect at least one brand'),
      locations: Yup.array().of(
        Yup.object().shape({
          locationName: Yup.string().required('Please enter Location'),
          shippingStreet: Yup.string().required('Please enter Shipping street'),
          shippingStateId: Yup.string().required('Please enter Shipping State'),
          shippingCityId: Yup.string().required('Please enter Shipping City'),
          shippingZipCode: Yup.number().required(
            'Please enter Shipping Zip Code'
          ),
          contactFirstName: Yup.string().required('Please enter first name'),
          contactLastName: Yup.string().required('Please enter last name'),
          contactEmail: Yup.string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
          contactPhone: Yup.number()
            .typeError("That doesn't look like a phone number")
            .positive("A phone number can't start with a minus")
            .integer("A phone number can't include a decimal point")
            .required('Please enter Phone Number')
            .test(
              'maxDigits',
              'Must be 10 digits',
              (number) => String(number).length === 10
            ),
          contactTitle: Yup.string().required('Please enter title'),
          isPrimaryLocation: false
        })
      )
    }),
    onSubmit: (values) => {
      if (
        !dupLocationError &&
        emailandmobileerror.email == '' &&
        emailandmobileerror.mobile == ''
      ) {
        const finalObj = { ...values, isEdiEnabledVendor: isEDIWD ? 1 : 0 }
        const locationData = values.locations
        finalObj.locations = []
        locationData.map((item, index) => {
          const { open, isPrimaryLocation, ...rest } = item

          if (!finalObj.locations) {
            finalObj.locations = []
          }
          finalObj.locations.push({
            ...rest,
            isPrimaryLocation: isPrimaryLocation ? 1 : 0
          })
        })
        delete finalObj.locationArray
        finalObj.noOfLocations = values.locations.length
        finalObj.primaryBillingCityId = parseInt(
          finalObj.primaryBillingCityId
        )
        finalObj.primaryBillingStateId = parseInt(
          finalObj.primaryBillingStateId
        )
        const url = 'admin/registerWD'
        PostData(url, finalObj).then((response) => {
          if (response.status === true) {
            props.history.push('/wd-management')
          } else {
            toastr.error(response.message, 'Error')
          }
        })
      }
    }
  })

  const handleCheckCategory = (id, children, e) => {
    let temp = [...selectedCategory]
    const subCategoryIds = children.map((subCategory) => {
      return subCategory.subcategoryID
    })
    if (!e.target.checked) {
      temp.push({ categoryID: id, children: [...subCategoryIds] })
      setSelectedCategory(temp)
    } else if (e.target.checked) {
      temp = temp.filter((item) => {
        return item.categoryID !== id
      })
      if (temp.length === 0) {
        setSelectedBrands([])
        setSelectedBrandsIds([])
        validation.setFieldValue('brands', [])
      }
      setSelectedCategory(temp)
    }
    validation.setFieldValue('categories', temp)
  }

  const handleCheckSubCategory = (id, subId, e) => {
    const temp = selectedCategory.filter((category) => {
      return category.categoryID !== id
    })
    const filteredTemp = selectedCategory.filter((category) => {
      return category.categoryID === id
    })
    let categoryObj
    if (filteredTemp.length === 0) {
      categoryObj = { categoryID: id, children: [] }
    } else {
      categoryObj = filteredTemp[0]
    }
    const childArr = [...categoryObj.children]
    if (!e.target.checked) {
      childArr.push(subId)
      categoryObj.children = childArr
      setSelectedCategory([categoryObj, ...temp])
      validation.setFieldValue('categories', [categoryObj, ...temp])
    } else if (e.target.checked) {
      const updatedChildArr = childArr.filter((item) => {
        return item !== subId
      })
      if (updatedChildArr.length === 0) {
        const categories = selectedCategory.filter((item) => {
          return item.categoryID !== id
        })
        setSelectedCategory(categories)
        validation.setFieldValue('categories', categories)
      } else {
        categoryObj.children = updatedChildArr
        setSelectedCategory([categoryObj, ...temp])
        validation.setFieldValue('categories', [categoryObj, ...temp])
      }
    }
  }

  const handleDocument = (e) => {
    const url = 'admin/uploadDocument'
    const formData = new FormData()
    formData.append('document', e.target.files[0])
    const file_size = event.target.files[0].size / 1024

    if (file_size > 1024 * 5) {
      // setDocumentUploadedError("File should be less than 5MB");
      validation.setFieldError('documentFile', 'File should be less than 5MB')
    } else {
      PostData(url, formData, true).then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Success')
          setFileObj(response.result)
          validation.setFieldValue('documentFile', response.result.newFile)
        } else {
          toastr.error(response.message, 'Error')
        }
      })
    }
  }

  const handlePrimaryLocation = (key, i, e) => {
    if (validation.values.locations.length > 1) {
      validation.values.locations.map((item, index) => {
        if (index == i) {
          validation.setFieldValue(
            `locations[${index}].isPrimaryLocation`,
            !e.target.checked
          )
        } else {
          validation.setFieldValue(
            `locations[${index}].isPrimaryLocation`,
            false
          )
        }
      })
    } else {
      // validation.setFieldValue(key, !e.target.checked);
    }
  }

  const handleLocationData = (key, i, name, e) => {
    // console.log(key, i, name, e)
    validation.setFieldValue(key, e.target.value)
    let status = false
    if (validation.values.locations.length > 1) {
      validation.values.locations.map((item, index) => {
        if (index != i) {
          if (
            validation.values.locations[i].shippingStateId != '' &&
            validation.values.locations[i].shippingCityId != '' &&
            validation.values.locations[i].shippingZipCode != ''
          ) {
            const obj1 = {
              shippingStateId: item.shippingStateId,
              shippingCityId: item.shippingCityId,
              shippingZipCode: item.shippingZipCode
            }
            const obj2 = {
              shippingStateId: validation.values.locations[i].shippingStateId,
              shippingCityId: validation.values.locations[i].shippingCityId,
              shippingZipCode: validation.values.locations[i].shippingZipCode,
              [name]: e.target.value
            }
            if (obj1.shippingStateId == obj2.shippingStateId) {
              if (obj1.shippingCityId == obj2.shippingCityId) {
                if (obj1.shippingZipCode == obj2.shippingZipCode) {
                  // setDupLocationError(true);
                  status = true
                } else {
                  // setDupLocationError(false);
                }
              } else {
                // setDupLocationError(false);
              }
            } else {
              // setDupLocationError(false);
            }
          }
        }
      })
    }
    setDupLocationError(status)
  }

  const validateEmail = (email) => {
    if (email != '') {
      setLoader(true)
      const url = 'vendor/validateWdEmail'
      const body = {
        email
      }
      PostData(url, body).then((response) => {
        setLoader(false)
        if (response.status === true) {
          setemailandmobileerror((prev) => ({
            ...prev,
            email: ''
          }))
        } else {
          // validation.setFieldError("email", response.message);
          setemailandmobileerror((prev) => ({
            ...prev,
            email: response.message
          }))
        }
      })
    } else {
      setemailandmobileerror((prev) => ({
        ...prev,
        email: ''
      }))
    }
  }

  // const validateMobile = (mobile) => {
  //   if (mobile != '') {
  //     setLoader(true)
  //     const url = 'vendor/validateWdMobile'
  //     const body = {
  //       mobile
  //     }
  //     PostData(url, body).then((response) => {
  //       setLoader(false)
  //       if (response.status === true) {
  //         setemailandmobileerror((prev) => ({
  //           ...prev,
  //           mobile: ''
  //         }))
  //       } else {
  //         // validation.setFieldError("mobile", response.message);
  //         setemailandmobileerror((prev) => ({
  //           ...prev,
  //           mobile: response.message
  //         }))
  //       }
  //     })
  //   } else {
  //     setemailandmobileerror((prev) => ({
  //       ...prev,
  //       mobile: ''
  //     }))
  //   }
  // }

  const handleErrors = () => {
    if (Object.keys(validation.errors).length > 0) {
      document.getElementsByName(Object.keys(validation.errors)[0])[0]?.scrollIntoView({ block: 'center' })
    }
  }

  const handleAccountingPrimary = (e, name) => {
    if (e.target.checked) {
      name === 'accounting' && setPrimaryAccounting(true)
      name === 'it' && setPrimaryIt(true)
      validation.setFieldValue(`${name}FirstName`, validation.values.primaryFirstName)
      validation.setFieldValue(`${name}LastName`, validation.values.primaryLastName)
      validation.setFieldValue(`${name}Title`, validation.values.primaryTitle)
      validation.setFieldValue(`${name}Email`, validation.values.primaryEmail)
      validation.setFieldValue(`${name}Phone`, validation.values.primaryPhone)
    } else {
      name === 'accounting' && setPrimaryAccounting(false)
      name === 'it' && setPrimaryIt(false)
      validation.setFieldValue(`${name}FirstName`, '')
      validation.setFieldValue(`${name}LastName`, '')
      validation.setFieldValue(`${name}Title`, '')
      validation.setFieldValue(`${name}Email`, '')
      validation.setFieldValue(`${name}Phone`, '')
    }
  }

  const handleLocationsFill = (e, index) => {
    if (e.target.checked) {
      validation.setFieldValue(`locations[${index}].contactFirstName`, validation.values.primaryFirstName)
      validation.setFieldValue(`locations[${index}].contactLastName`, validation.values.primaryLastName)
      validation.setFieldValue(`locations[${index}].contactEmail`, validation.values.primaryEmail)
      validation.setFieldValue(`locations[${index}].contactTitle`, validation.values.primaryTitle)
      validation.setFieldValue(`locations[${index}].contactPhone`, validation.values.primaryPhone)
    } else {
      validation.setFieldValue(`locations[${index}].contactFirstName`, '')
      validation.setFieldValue(`locations[${index}].contactLastName`, '')
      validation.setFieldValue(`locations[${index}].contactEmail`, '')
      validation.setFieldValue(`locations[${index}].contactTitle`, '')
      validation.setFieldValue(`locations[${index}].contactPhone`, '')
    }
  }

  useEffect(() => {
    validation.setFieldValue('mobile', mobile)
 
  }, [mobile])

  // useEffect(() => {
  //   let name = ''
  //   if (primaryAccounting || primaryIt) {
  //     if (primaryAccounting) {
  //       name = 'accounting'
  //     }
  //     if (primaryIt) {
  //       name = 'it'
  //     }
  //     validation.setFieldValue(`${name}FirstName`, validation.values.primaryFirstName)
  //     validation.setFieldValue(`${name}LastName`, validation.values.primaryLastName)
  //     validation.setFieldValue(`${name}Title`, validation.values.primaryTitle)
  //     validation.setFieldValue(`${name}Email`, validation.values.primaryEmail)
  //     validation.setFieldValue(`${name}Phone`, validation.values.primaryPhone)
  //   }
  // }, [validation.values])

  const handleInputField = (e, name) => {
    if (name === 'primaryPhone') {
      primaryAccounting && validation.setFieldValue('accountingPhone', e)
      primaryIt && validation.setFieldValue('itPhone', e)
    } else if (e.target.name === 'primaryFirstName') {
      primaryAccounting && validation.setFieldValue('accountingFirstName', e.target.value)
      primaryIt && validation.setFieldValue('itFirstName', e.target.value)
    } else if (e.target.name === 'primaryLastName') {
      primaryAccounting && validation.setFieldValue('accountingLastName', e.target.value)
      primaryIt && validation.setFieldValue('itLastName', e.target.value)
    } else if (e.target.name === 'primaryEmail') {
      primaryAccounting && validation.setFieldValue('accountingEmail', e.target.value)
      primaryIt && validation.setFieldValue('itEmail', e.target.value)
    } else if (e.target.name === 'primaryTitle') {
      primaryAccounting && validation.setFieldValue('accountingTitle', e.target.value)
      primaryIt && validation.setFieldValue('itTitle', e.target.value)
    }
  }

  return (
    <div className="page-content">
      <Loader show={loader} />
      <div className="d-flex align-items-center">
        <i
          onClick={() => history.push('/wd-management')}
          style={{ cursor: 'pointer' }}
          className="dripicons-arrow-thin-left me-3"
        ></i>
        <Breadcrumbs title="UI Elements" breadcrumbItem="New WD Registration" />
      </div>

      <Row className="mt-3">
        <Col lg={12}>
          <Card>
            <FormikProvider value={validation}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  handleErrors()
                  validation.handleSubmit()
                  return false
                }}
              >
                <CardBody className="new-registration-container">
                  <div className="d-flex justify-content-between align-items-center wd-business-nav">
                    <h2 className="new-registration-heading-blue">
                      Business Information
                    </h2>
                    <span className="all-marked-wd">
                      * All marked fields are mandatory
                    </span>
                  </div>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Company Name*
                        </Label>
                        <Input
                          name="companyName"
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Company Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.companyName || ''}
                          invalid={
                            !!(validation.touched.companyName &&
                            validation.errors.companyName)
                          }
                        />
                        {validation.touched.companyName &&
                        validation.errors.companyName
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.companyName}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Email Address*
                        </Label>
                        <Input
                          name="email"
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Email"
                          onChange={validation.handleChange}
                          onBlur={(e) => {
                            validation.setFieldTouched('email', true)
                            validateEmail(validation.values.email)
                          }}
                          value={validation.values.email || ''}
                          invalid={
                            !!(validation.touched.email && validation.errors.email)
                          }
                        />
                        {validation.touched.email && validation.errors.email
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                            )
                          : null}
                        {emailandmobileerror?.email != '' && (
                          <FormFeedback
                            type="invalid"
                            style={{ display: 'block' }}
                          >
                            {emailandmobileerror?.email}
                          </FormFeedback>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Mobile Number*
                        </Label>
                        <NumberFormat displayType="input" format="(###) ###-####"
                         className={
                          validation.touched.mobile && validation.errors.mobile
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                          name="mobile"
                          id="formrow-firstname-Input"
                          placeholder="Enter Mobile Number"
                          defaultValue={mobile}
                          onValueChange={(value) => { setMobile(value.value) }}
                          />
                        {/* <Input
                          name="mobile"
                          type="tel"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Mobile Number"
                          onChange={(e) => {
                            if (e.target.value.length < 11) {
                              validation.setFieldValue(
                                'mobile',
                                e.target.value
                              )
                            }
                          }}
                          onBlur={() => {
                            validation.setFieldTouched('mobile', true)
                            validateMobile(validation.values.mobile)
                          }}
                          value={validation.values.mobile || ''}
                          invalid={
                            !!(validation.touched.mobile &&
                            validation.errors.mobile)
                          }
                        /> */}
                        {validation.touched.mobile && validation.errors.mobile
                          ? (
                    <FormFeedback type="invalid">
                      {validation.errors.mobile}
                    </FormFeedback>
                            )
                          : null}
                        {emailandmobileerror?.mobile != '' && (
                          <FormFeedback type="invalid" style={{ display: 'block' }}>
                            {emailandmobileerror?.mobile}
                          </FormFeedback>
                        )}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Group Affiliation
                        </Label>
                        <Input
                          name="programAffiliation"
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Group Affiliation"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.programAffiliation || ''}
                          // invalid={
                          //   !!(validation.touched.programAffiliation &&
                          //   validation.errors.programAffiliation)
                          // }
                        />
                        {/* {validation.touched.programAffiliation &&
                        validation.errors.programAffiliation
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.programAffiliation}
                          </FormFeedback>
                            )
                          : null} */}
                    </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-4">
                        <Label htmlFor="dateCompanyStarted">
                          Date Company Started
                        </Label>
                        <input
                          name="dateCompanyStarted"
                          type="date"
                          className="form-control cursor--p"
                          style={{ cursor: 'pointer' }}
                          id="dateCompanyStarted"
                          placeholder="Enter Date Company Started"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.dateCompanyStarted || ''}
                          invalid={
                            !!(validation.touched.dateCompanyStarted &&
                            validation.errors.dateCompanyStarted)
                          }
                          data-date-inline-picker="true"
                        />
                        {validation.touched.dateCompanyStarted &&
                        validation.errors.dateCompanyStarted
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.dateCompanyStarted}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                    <div className="mb-4 d-flex">
                    <input
                              type="checkbox"
                              id="edi-wd"
                              name="ediWD"
                              checked={isEDIWD}
                              onClick={handleEdiWdCheckbox}
                            />
                            <label htmlFor="edi-wd" style={{ marginLeft: '10px', marginBottom: '0px' }}>
                              Is EDI WD
                            </label>
                          </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <h3 className="new-registration-heading-black ">
                        Primary Billing Address
                      </h3>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">Street*</Label>
                        <Input
                          name="primaryBillingStreet"
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Street Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.primaryBillingStreet || ''}
                          invalid={
                            !!(validation.touched.primaryBillingStreet &&
                            validation.errors.primaryBillingStreet)
                          }
                        />
                        {validation.touched.primaryBillingStreet &&
                        validation.errors.primaryBillingStreet
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.primaryBillingStreet}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                      <div className="mb-4">
                        <Label htmlFor="stateSelect">State/Province*</Label>
                        <Input
                          type="select"
                          name="primaryBillingStateId"
                          className="cursor--p"
                          id="stateSelect"
                          onChange={(e) => {
                            validation.setFieldValue(
                              'primaryBillingStateId',
                              e.target.value
                            )
                            getCities(e.target.value)
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.primaryBillingStateId || ''}
                          invalid={
                            !!(validation.touched.primaryBillingStateId &&
                            validation.errors.primaryBillingStateId)
                          }
                        >
                          <option value="">-Select State-</option>
                          {states.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Input>
                        {validation.touched.primaryBillingStateId &&
                        validation.errors.primaryBillingStateId
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.primaryBillingStateId}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="citySelect">City*</Label>
                            <Input
                              name="primaryBillingCityId"
                              type="select"
                              className="form-control cursor--p"
                              id="citySelect"
                              placeholder="Enter City"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.primaryBillingCityId || ''
                              }
                              invalid={
                                !!(validation.touched.primaryBillingCityId &&
                                validation.errors.primaryBillingCityId)
                              }
                            >
                              <option value="">-Select City-</option>
                              {cities.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Input>
                            {validation.touched.primaryBillingCityId &&
                            validation.errors.primaryBillingCityId
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.primaryBillingCityId}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">
                              Zip Code/Postal Code*
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Zipcode"
                              name="primaryBillingZipCode"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.primaryBillingZipCode || ''
                              }
                              invalid={
                                !!(validation.touched.primaryBillingZipCode &&
                                validation.errors.primaryBillingZipCode)
                              }
                            />
                            {validation.touched.primaryBillingZipCode &&
                            validation.errors.primaryBillingZipCode
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.primaryBillingZipCode}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <h3 className="new-registration-heading-black">
                        Assign Category and Subcategory
                      </h3>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Select Category and Sub Category*
                        </Label>
                        <div className="category-subcategory-container">
                          {categoryData.map((data, i) => {
                            const selectedCat = selectedCategory.map((cat) => {
                              return cat.categoryID
                            })
                            return (
                              <div key={i}>
                                <div className="d-flex align-items-center">
                                  {data.open
                                    ? (
                                    <i
                                      className="bx bx-minus cursor--p"
                                      onClick={(e) =>
                                        handleOpen(DROPDOWN.CATEGORY, i, e)
                                      }
                                    ></i>
                                      )
                                    : (
                                    <i
                                      className="bx bx-plus cursor--p"
                                      onClick={(e) =>
                                        handleOpen(DROPDOWN.CATEGORY, i, e)
                                      }
                                    ></i>
                                      )}

                                  <input
                                    className=" m-0 ms-2"
                                    type="checkbox"
                                    value={data.categoryID}
                                    onClick={(e) =>
                                      handleCheckCategory(
                                        data.categoryID,
                                        data.children,
                                        e
                                      )
                                    }
                                    id={'defaultCheck' + data.categoryName}
                                    checked={selectedCat.includes(data.categoryID)}
                                    name="categories"
                                  />
                                  <label
                                    className="form-check-label ms-1"
                                    htmlFor={'defaultCheck' + data.categoryName}
                                  >
                                    {data.categoryName}
                                  </label>
                                </div>
                                {data.open && (
                                  <div className="d-block">
                                    {data.children.map(
                                      (innerData, innerindex) => {
                                        const selectedSubCat = selectedCategory.map((subCat) => {
                                          return subCat.children
                                        })
                                        const updateSelectedSubCat = [].concat(...selectedSubCat)
                                        return (
                                          <div
                                            key={innerindex}
                                            className="subcategory-container"
                                          >
                                            {' '}
                                            <input
                                              className=" m-0 ms-2"
                                              type="checkbox"
                                              value={innerData.subcategoryID}
                                              onClick={(e) =>
                                                handleCheckSubCategory(
                                                  data.categoryID,
                                                  innerData.subcategoryID,
                                                  e
                                                )
                                              }
                                              id={
                                                'defaultChecki' + innerData.subCategoryName
                                              }
                                              checked={updateSelectedSubCat.includes(innerData.subcategoryID)}
                                            />
                                            <label
                                              className="form-check-label ms-1"
                                              htmlFor={
                                                'defaultChecki' + innerData.subCategoryName
                                              }
                                            >
                                              {innerData.subCategoryName}
                                            </label>
                                          </div>
                                        )
                                      }
                                    )}
                                  </div>
                                )}
                              </div>
                            )
                          })}
                        </div>
                        {validation.errors.categories
                          ? (
                          <FormFeedback
                            type="invalid"
                            style={{ display: 'block' }}
                          >
                            {validation.errors.categories}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </Col>
                    {/* <Row>
                      <Col lg={4}>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Resale Tax Number*{" "}
                            <span className="info-icon">
                              <img src={info} className="cursor--p" />
                              <span className="tooltiptextt">
                                Lorem Ipsum es simplemente el texto de relleno
                                de las imprentas y archivos de texto
                              </span>
                            </span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Resale Tax Number"
                            name="reSaleTaxNumber"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.reSaleTaxNumber || ""}
                            invalid={
                              validation.touched.reSaleTaxNumber &&
                              validation.errors.reSaleTaxNumber
                                ? true
                                : false
                            }
                          />
                          {validation.touched.reSaleTaxNumber &&
                          validation.errors.reSaleTaxNumber ? (
                            <FormFeedback type="invalid">
                              {validation.errors.reSaleTaxNumber}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={8}>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Upload Document(Optional)
                            <span className="info-icon">
                              <img src={info} className="cursor--p" />
                              <span className="tooltiptextt">
                                Lorem Ipsum es simplemente el texto de relleno
                                de las imprentas y archivos de texto
                              </span>
                            </span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control d-none"
                            id="UploadTaxDocument"
                            placeholder="Upload Document"
                            onChange={handleDocument}
                          />
                          <div>
                            {Object.keys(fileObj).length > 0 ? (
                              <div className="pdf-upload phn-email">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    document
                                      .getElementById("UploadTaxDocument")
                                      .click();
                                  }}
                                  className="choose--btn"
                                >
                                  <i className="bx bx-cloud-upload"></i> Update
                                  File
                                </button>
                                <div
                                  style={{
                                    marginLeft: "10px",
                                    position: "relative",
                                  }}
                                  className="pdf-row"
                                >
                                  <img
                                    src={"/cross.svg"}
                                    onClick={() => {
                                      setFileObj({});
                                      validation.setFieldValue(
                                        "documentFile",
                                        ""
                                      );
                                    }}
                                    alt="close"
                                    style={{
                                      position: "absolute",
                                      right: "-7px",
                                      top: "-7px",
                                      cursor: "pointer",
                                    }}
                                  />
                                  <a
                                    href={fileObj.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img src="/pdf2.svg" alt="pdf" />
                                  </a>
                                </div>
                                <span className="pdf-input">
                                  {fileObj.newFile}
                                </span>
                              </div>
                            ) : (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  document
                                    .getElementById("UploadTaxDocument")
                                    .click();
                                }}
                                className="choose--btn choose-cloud-btn"
                              >
                                <i className="bx bx-cloud-upload cloud-icon"></i>{" "}
                                Choose File
                              </button>
                            )}
                          </div>
                          {validation.errors.documentFile ? (
                            <FormFeedback
                              type="invalid"
                              style={{ display: "block" }}
                            >
                              {validation.errors.documentFile}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row> */}
                  </Row>
                  <Row>
                      <h3 className="new-registration-heading-black">
                        Assign Brands
                      </h3>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Assign Brands Here
                        </Label>
                        <div className="wd-brands-container">
                        <div className="position-relative search-featured mb-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            onChange={filterBrand}
                            value={searchText}
                          />
                          <span className="bx bx-search search-icon" />
                        </div>
                        <div className="check-listing-container" id='scrollable' ref={scrollRef}>
                          <div className="check-listing">
                          {allBrands.length > 0
                            ? <InfiniteScroll
                            dataLength={allBrands.length}
                            next={fetchNextBrands}
                            hasMore={true}
                            endMessage={
                              <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                              </p>
                            }
                            scrollableTarget="scrollable"
                          >
                             {allBrands.map((brand, i) => {
                               return <div className="checkbox-line" key={i}>
                               <div className="d-flex align-items-center">
                                 <input
                                   className="me-2"
                                   type="checkbox"
                                   value={brand.value}
                                   id={`brand-${brand.value}`}
                                   onClick={(e) => handleBrandCheckbox(brand, e)}
                                   checked={
                                    selectedBrandIds.includes(brand.value)
                                  }
                                  readOnly
                                  name="brands"
                                 />
                                 <label
                                   className="form-check-label"
                                   htmlFor={`brand-${brand.value}`}
                                 >
                                   {brand.label}
                                 </label>
                               </div>
                             </div>
                             })}
                          </InfiniteScroll>
                            : <div>Please select at least 1 category and sub-category to choose brands</div>}
                          </div>
                        </div>
                        </div>
                        {validation.errors.brands
                          ? (
                          <FormFeedback
                            type="invalid"
                            style={{ display: 'block' }}
                          >
                            {validation.errors.brands}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                    <div className="mb-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Selected Brands
                        </Label>
                        <div className="category-subcategory-container">
                            {selectedBrands.map((brand, i) => {
                              return <div key={i} className="mb-2"> <img
                              src={deleteLogo}
                              className="mx-2 cursor--p"
                              onClick={(e) => { handleDeleteBrand(e, brand) }}
                              />
                              <span>{brand.label}</span></div>
                            })}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr></hr>
                  <h2 className="new-registration-heading-blue">
                    Contact Information
                  </h2>
                  <Row>
                    <Col lg={6}>
                      <h3 className="new-registration-normal-heading">
                        {' '}
                        Primary / Owner
                      </h3>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">
                              First Name*
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter First Name"
                              name="primaryFirstName"
                              onChange={(e) => {
                                handleInputField(e)
                                validation.handleChange(e)
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.primaryFirstName || ''}
                              invalid={
                                !!(validation.touched.primaryFirstName &&
                                validation.errors.primaryFirstName)
                              }
                            />
                            {validation.touched.primaryFirstName &&
                            validation.errors.primaryFirstName
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.primaryFirstName}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">
                              Last Name*
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Last Name"
                              name="primaryLastName"
                              onChange={(e) => {
                                handleInputField(e)
                                validation.handleChange(e)
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.primaryLastName || ''}
                              invalid={
                                !!(validation.touched.primaryLastName &&
                                validation.errors.primaryLastName)
                              }
                            />
                            {validation.touched.primaryLastName &&
                            validation.errors.primaryLastName
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.primaryLastName}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </Col>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Email Address*
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Email Address"
                            name="primaryEmail"
                            onChange={(e) => {
                              handleInputField(e)
                              validation.handleChange(e)
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.primaryEmail || ''}
                            invalid={
                              !!(validation.touched.primaryEmail &&
                              validation.errors.primaryEmail)
                            }
                          />
                          {validation.touched.primaryEmail &&
                          validation.errors.primaryEmail
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.primaryEmail}
                            </FormFeedback>
                              )
                            : null}
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Mobile Number*
                          </Label>
                          <NumberFormat
                          className={
                            validation.touched.primaryPhone && validation.errors.primaryPhone
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                          id="formrow-firstname-Input"
                            placeholder="Enter Mobile Number"
                            name="primaryPhone"
                            displayType="input" format="(###) ###-####"
                            value={validation.values.primaryPhone || ''}
                            onValueChange={(values) => {
                              handleInputField(values.value, 'primaryPhone')
                              if (values.value.length < 11) {
                                validation.setFieldValue(
                                  'primaryPhone',
                                  values.value
                                )
                              }
                            }}
                          />
                          {/* <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Mobile Number"
                            name="primaryPhone"
                            onChange={(e) => {
                              if (e.target.value.length < 11) {
                                validation.setFieldValue(
                                  'primaryPhone',
                                  e.target.value
                                )
                              }
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.primaryPhone || ''}
                            invalid={
                              !!(validation.touched.primaryPhone &&
                              validation.errors.primaryPhone)
                            }
                          /> */}
                          {validation.touched.primaryPhone &&
                          validation.errors.primaryPhone
                            ? (
                              <FormFeedback type="invalid">
                              {validation.errors.primaryPhone}
                            </FormFeedback>
                              )
                            : null}
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Title*
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Title"
                            name="primaryTitle"
                            onChange={(e) => {
                              handleInputField(e)
                              validation.handleChange(e)
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.primaryTitle || ''}
                            invalid={
                              !!(validation.touched.primaryTitle &&
                              validation.errors.primaryTitle)
                            }
                          />
                          {validation.touched.primaryTitle &&
                          validation.errors.primaryTitle
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.primaryTitle}
                            </FormFeedback>
                              )
                            : null}
                        </div>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <h3 className="new-registration-normal-heading">
                        Alternate
                      </h3>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">
                              First Name
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter First Name"
                              name="alternateFirstName"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.alternateFirstName || ''}
                              // invalid={
                              //   !!(validation.touched.alternateFirstName &&
                              //   validation.errors.alternateFirstName)
                              // }
                            />
                            {/* {validation.touched.alternateFirstName &&
                            validation.errors.alternateFirstName
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.alternateFirstName}
                              </FormFeedback>
                                )
                              : null} */}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">
                              Last Name
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Last Name"
                              name="alternateLastName"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.alternateLastName || ''}
                              // invalid={
                              //   !!(validation.touched.alternateLastName &&
                              //   validation.errors.alternateLastName)
                              // }
                            />
                            {/* {validation.touched.alternateLastName &&
                            validation.errors.alternateLastName
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.alternateLastName}
                              </FormFeedback>
                                )
                              : null} */}
                          </div>
                        </Col>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Email Address
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Email Address"
                            name="alternateEmail"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.alternateEmail || ''}
                            // invalid={
                            //   !!(validation.touched.alternateEmail &&
                            //   validation.errors.alternateEmail)
                            // }
                          />
                          {/* {validation.touched.alternateEmail &&
                          validation.errors.alternateEmail
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.alternateEmail}
                            </FormFeedback>
                              )
                            : null} */}
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Mobile Number
                          </Label>
                          <NumberFormat
                          // className="form-coDate Company Startedntrol"
                          className={
                            validation.touched.alternatePhone && validation.errors.alternatePhone
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                          id="formrow-firstname-Input"
                          placeholder="Enter Mobile Number"
                          name="alternatePhone"
                          value={validation.values.alternatePhone || ''}
                          displayType="input" format="(###) ###-####"
                            onValueChange={(values) => {
                              if (values.value.length < 11) {
                                validation.setFieldValue(
                                  'alternatePhone',
                                  values.value
                                )
                              }
                            }}
                          />
                          {/* <Input
                            type="text"
                            className="form-coDate Company Startedntrol"
                            id="formrow-firstname-Input"
                            placeholder="Enter Mobile Number"
                            name="alternatePhone"
                            onChange={(e) => {
                              if (e.target.value.length < 11) {
                                validation.setFieldValue(
                                  'alternatePhone',
                                  e.target.value
                                )
                              }
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.alternatePhone || ''}
                          /> */}
                          {/* {validation.touched.alternatePhone &&
                          validation.errors.alternatePhone
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.alternatePhone}
                            </FormFeedback>
                              )
                            : null} */}
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Title
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Title"
                            name="alternateTitle"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.alternateTitle || ''}
                            // invalid={
                            //   !!(validation.touched.alternateTitle &&
                            //   validation.errors.alternateTitle)
                            // }
                          />
                          {/* {validation.touched.alternateTitle &&
                          validation.errors.alternateTitle
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.alternateTitle}
                            </FormFeedback>
                              )
                            : null} */}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                     <div className="d-flex align-items-center justify-content-between" >
                      <h3 className="new-registration-normal-heading">
                          Accounting
                        </h3>
                          <div className='d-flex align-items-center'>
                          <input
                              type="checkbox"
                              name="accountingPrimary"
                              id="accountingPrimary"
                              className="me-2"
                              onChange={(e) => { handleAccountingPrimary(e, 'accounting') }}
                            />
                        <label htmlFor="accountingPrimary" className="cursor-pointer m-0">
                          Same as Primary Details
                        </label>
                          </div>
                     </div>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">
                              First Name
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter First Name"
                              name="accountingFirstName"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.accountingFirstName || ''
                              }
                              // invalid={
                              //   !!(validation.touched.accountingFirstName &&
                              //   validation.errors.accountingFirstName)
                              // }
                            />
                            {/* {validation.touched.accountingFirstName &&
                            validation.errors.accountingFirstName
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.accountingFirstName}
                              </FormFeedback>
                                )
                              : null} */}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">
                              Last Name
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Last Name"
                              name="accountingLastName"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.accountingLastName || ''}
                              // invalid={
                              //   !!(validation.touched.accountingLastName &&
                              //   validation.errors.accountingLastName)
                              // }
                            />
                            {/* {validation.touched.accountingLastName &&
                            validation.errors.accountingLastName
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.accountingLastName}
                              </FormFeedback>
                                )
                              : null} */}
                          </div>
                        </Col>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Email Address
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Email Address"
                            name="accountingEmail"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.accountingEmail || ''}
                            // invalid={
                            //   !!(validation.touched.accountingEmail &&
                            //   validation.errors.accountingEmail)
                            // }
                          />
                          {/* {validation.touched.accountingEmail &&
                          validation.errors.accountingEmail
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.accountingEmail}
                            </FormFeedback>
                              )
                            : null} */}
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Mobile Number
                          </Label>
                          <NumberFormat
                         className={
                          validation.touched.accountingPhone && validation.errors.accountingPhone
                            ? 'form-control form-coDate Company Startedntrol is-invalid'
                            : 'form-control form-coDate Company Startedntrol'
                        }
                          id="formrow-firstname-Input"
                          placeholder="Enter Mobile Number"
                          name="accountingPhone"
                          displayType="input" format="(###) ###-####"
                          value={validation.values.accountingPhone || ''}
                          onValueChange={(values) => {
                            if (values.value.length < 11) {
                              validation.setFieldValue(
                                'accountingPhone',
                                values.value
                              )
                            }
                          }}

                          />
                          {/* <Input
                            type="text"
                            className="form-coDate Company Startedntrol"
                            id="formrow-firstname-Input"
                            placeholder="Enter Mobile Number"
                            name="accountingPhone"
                            onChange={(e) => {
                              if (e.target.value.length < 11) {
                                validation.setFieldValue(
                                  'accountingPhone',
                                  e.target.value
                                )
                              }
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.accountingPhone || ''}
                            invalid={
                              !!(validation.touched.accountingPhone &&
                              validation.errors.accountingPhone)
                            }
                          /> */}
                          {validation.touched.accountingPhone &&
                          validation.errors.accountingPhone
                            ? (
                            <div className='error' type="invalid">
                              {validation.errors.accountingPhone}
                            </div>
                              )
                            : null}
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Title
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Title"
                            name="accountingTitle"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.accountingTitle || ''}
                            // invalid={
                            //   !!(validation.touched.accountingTitle &&
                            //   validation.errors.accountingTitle)
                            // }
                          />
                          {/* {validation.touched.accountingTitle &&
                          validation.errors.accountingTitle
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.accountingTitle}
                            </FormFeedback>
                              )
                            : null} */}
                        </div>
                      </Row>
                    </Col>
                    <Col lg={6}>
                    <div className="d-flex align-items-center justify-content-between" >
                      <h3 className="new-registration-normal-heading">
                          IT Contact
                        </h3>
                          <div className='d-flex align-items-center'>
                          <input
                              type="checkbox"
                              name="ITPrimary"
                              id="ITPrimary"
                              className="me-2"
                              onChange={(e) => { handleAccountingPrimary(e, 'it') }}
                            />
                        <label htmlFor="ITPrimary" className="cursor-pointer m-0">
                          Same as Primary Details
                        </label>
                          </div>
                     </div>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">
                              First Name
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter First Name"
                              name="itFirstName"
                              value={validation.values.itFirstName || ''}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              // invalid={
                              //   !!(validation.touched.itFirstName &&
                              //   validation.errors.itFirstName)
                              // }
                            />
                            {/* {validation.touched.itFirstName &&
                            validation.errors.itFirstName
                              ? (
                        <FormFeedback type="invalid">
                          {validation.errors.itFirstName}
                        </FormFeedback>
                                )
                              : null} */}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">
                              Last Name
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Last Name"
                              name="itLastName"
                              value={validation.values.itLastName || ''}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              // invalid={
                              //   !!(validation.touched.itLastName &&
                              //   validation.errors.itLastName)
                              // }
                            />
                            {/* {validation.touched.itLastName &&
                      validation.errors.itLastName
                              ? (
                        <FormFeedback type="invalid">
                          {validation.errors.itLastName}
                        </FormFeedback>
                                )
                              : null} */}
                          </div>
                        </Col>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Email Address
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Email Address"
                            name="itEmail"
                            value={validation.values.itEmail || ''}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            // invalid={
                            //   !!(validation.touched.itLastName &&
                            //   validation.errors.itLastName)
                            // }
                          />
                          {/* {validation.touched.itEmail &&
                          validation.errors.itEmail
                            ? (
                      <FormFeedback type="invalid">
                        {validation.errors.itEmail}
                      </FormFeedback>
                              )
                            : null} */}
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Mobile Number
                          </Label>
                          <NumberFormat
                          className={
                            validation.touched.itPhone && validation.errors.itPhone
                              ? 'form-control form-coDate Company Startedntrol is-invalid'
                              : 'form-control form-coDate Company Startedntrol'
                          }
                          id="formrow-firstname-Input"
                            placeholder="Enter Mobile Number"
                            name="itPhone"
                            displayType="input" format="(###) ###-####"
                            value={validation.values.itPhone || ''}
                            onValueChange={(values) => {
                              if (values.value.length < 11) {
                                validation.setFieldValue(
                                  'itPhone',
                                  values.value
                                )
                              }
                            }}
                          />
                          {/* <Input
                            type="text"
                            className="form-coDate Company Startedntrol"
                            id="formrow-firstname-Input"
                            placeholder="Enter Mobile Number"
                            name="itPhone"
                      onChange={(e) => {
                        if (e.target.value.length < 11) {
                          validation.setFieldValue(
                            'itPhone',
                            e.target.value
                          )
                        }
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.itPhone || ''}
                      invalid={
                        !!(validation.touched.itPhone &&
                        validation.errors.itPhone)
                      }
                          /> */}
                          {validation.touched.itPhone &&
                          validation.errors.itPhone
                            ? (
                      <div className='error' type="invalid">
                        {validation.errors.itPhone}
                      </div>
                              )
                            : null}
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="formrow-firstname-Input">
                            Title
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Title"
                            name="itTitle"
                      value={validation.values.itTitle || ''}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      // invalid={
                      //   !!(validation.touched.itTitle &&
                      //   validation.errors.itTitle)
                      // }
                          />
                          {/* {validation.touched.itTitle &&
                    validation.errors.itTitle
                            ? (
                      <FormFeedback type="invalid">
                        {validation.errors.itTitle}
                      </FormFeedback>
                              )
                            : null} */}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <hr></hr>
                  <h2 className="new-registration-heading-blue">
                    Warehouse Shipping Locations (
                    {validation.values.locations &&
                      validation.values.locations.length}
                    )
                  </h2>
                  <hr></hr>
                  <FieldArray
                    name="locations"
                    render={(arrayHelpers) => (
                      <div>
                        {validation.values.locations.map((_item, index) => (
                          <div key={index}>
                            <div key={index}>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <span
                                    className="warehouse-location-heading"
                                    onClick={(e) => {
                                      // handleOpen(DROPDOWN.LOCATION, index, e);
                                      validation.setFieldValue(
                                        `locations[${index}].open`,
                                        !validation.values.locations[index]
                                          .open || ''
                                      )
                                    }}
                                  >
                                    <a>Warehouse Location {index + 1}</a>
                                  </span>
                                  {validation.values.locations[index]
                                    .isPrimaryLocation ? (
                                    <span className="primary-location">
                                      {/* Primary Location */}
                                    </span>
                                      ) : null}
                                </div>
                                <div className="d-flex align-items-center">
                                  <span>
                                    <img
                                      src={deleteLogo}
                                      className="mx-4 cursor--p table-img-hover"
                                      onClick={(e) =>
                                        arrayHelpers.remove(index)
                                      }
                                    />
                                  </span>

                                  {_item.open
                                    ? (
                                    <span>
                                      <i
                                        className="bx bx-minus cursor--p table-img-hover"
                                        onClick={(e) => {
                                          validation.setFieldValue(
                                            `locations[${index}].open`,
                                            !validation.values.locations[index]
                                              .open || ''
                                          )
                                        }}
                                      ></i>
                                    </span>
                                      )
                                    : (
                                    <i
                                      className="bx bx-plus cursor--p table-img-hover"
                                      onClick={(e) => {
                                        validation.setFieldValue(
                                          `locations[${index}].open`,
                                          !validation.values.locations[index]
                                            .open || ''
                                        )
                                      }}
                                    ></i>
                                      )}
                                </div>
                              </div>
                              {_item.open && (
                                <div>
                                  <Row>
                                    <Col lg={12}>
                                      <div className="my-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Location Name/Title*{' '}
                                          {/* <span className="info-icon">
                                            <img
                                              src={info}
                                              className="cursor--p"
                                            />
                                            <span className="tooltiptextt">
                                              Lorem Ipsum es simplemente el
                                              texto de relleno de las imprentas
                                              y archivos de texto
                                            </span>
                                          </span> */}
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Enter Location Name/Title"
                                          name={`locations[${index}].locationName`}
                                          value={
                                            validation.values.locations[index]
                                              .locationName || ''
                                          }
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          invalid={
                                            !!(validation.touched &&
                                            validation.touched.locations &&
                                            validation.touched.locations[
                                              index
                                            ] &&
                                            validation.touched.locations[index]
                                              .locationName &&
                                            validation.errors &&
                                            validation.errors.locations &&
                                            validation.errors.locations[
                                              index
                                            ] &&
                                            validation.errors.locations[index]
                                              .locationName)
                                          }
                                        />
                                        {validation.touched &&
                                        validation.touched.locations &&
                                        validation.touched.locations[index] &&
                                        validation.touched.locations[index]
                                          .locationName &&
                                        validation.errors &&
                                        validation.errors.locations &&
                                        validation.errors.locations[index] &&
                                        validation.errors.locations[index]
                                          .locationName
                                          ? (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.locations[index]
                                                .locationName
                                            }
                                          </FormFeedback>
                                            )
                                          : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={6}>
                                      <h3 className="new-registration-heading-black">
                                        Shipping Address
                                      </h3>
                                      <div className="mb-4">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Street*
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Enter Street Name"
                                          name={`locations[${index}].shippingStreet`}
                                          value={
                                            validation.values.locations[index]
                                              .shippingStreet || ''
                                          }
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          invalid={
                                            !!(validation.touched &&
                                            validation.touched.locations &&
                                            validation.touched.locations[
                                              index
                                            ] &&
                                            validation.touched.locations[index]
                                              .shippingStreet &&
                                            validation.errors &&
                                            validation.errors.locations &&
                                            validation.errors.locations[
                                              index
                                            ] &&
                                            validation.errors.locations[index]
                                              .shippingStreet)
                                          }
                                        />
                                        {validation.touched &&
                                        validation.touched.locations &&
                                        validation.touched.locations[index] &&
                                        validation.touched.locations[index]
                                          .shippingStreet &&
                                        validation.errors &&
                                        validation.errors.locations &&
                                        validation.errors.locations[index] &&
                                        validation.errors.locations[index]
                                          .shippingStreet
                                          ? (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.locations[index]
                                                .shippingStreet
                                            }
                                          </FormFeedback>
                                            )
                                          : null}
                                      </div>
                                      <div className="mb-4">
                                        <Label htmlFor={`stateSelect${index}`}>
                                          State/Province*
                                        </Label>
                                        <Input
                                          type="select"
                                          id={`stateSelect${index}`}
                                          className="cursor--p"
                                          name={`locations[${index}].shippingStateId`}
                                          onChange={(e) => {
                                            // validation.setFieldValue(
                                            //   `locations[${index}].shippingStateId`,
                                            //   e.target.value
                                            // );
                                            handleLocationData(
                                              `locations[${index}].shippingStateId`,
                                              index,
                                              'shippingStateId',
                                              e
                                            )
                                            getCitiesLocations(
                                              e.target.value,
                                              index
                                            )
                                          }}
                                          value={
                                            validation.values.locations[index]
                                              .shippingStateId || ''
                                          }
                                          onBlur={validation.handleBlur}
                                          invalid={
                                            !!(validation.touched &&
                                            validation.touched.locations &&
                                            validation.touched.locations[
                                              index
                                            ] &&
                                            validation.touched.locations[index]
                                              .shippingStateId &&
                                            validation.errors &&
                                            validation.errors.locations &&
                                            validation.errors.locations[
                                              index
                                            ] &&
                                            validation.errors.locations[index]
                                              .shippingStateId)
                                          }
                                        >
                                          <option value="">
                                            -Select State-
                                          </option>
                                          {states.map((item) => (
                                            <option
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.name}
                                            </option>
                                          ))}
                                        </Input>

                                        {validation.touched &&
                                        validation.touched.locations &&
                                        validation.touched.locations[index] &&
                                        validation.touched.locations[index]
                                          .shippingStateId &&
                                        validation.errors &&
                                        validation.errors.locations &&
                                        validation.errors.locations[index] &&
                                        validation.errors.locations[index]
                                          .shippingStateId
                                          ? (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.locations[index]
                                                .shippingStateId
                                            }
                                          </FormFeedback>
                                            )
                                          : null}
                                      </div>
                                      <div className="mb-4">
                                        <Label htmlFor="formrow-firstname-Input">
                                          City*
                                        </Label>
                                        <Input
                                          type="select"
                                          className="form-control cursor--p"
                                          id="formrow-firstname-Input"
                                          placeholder="Enter City Name"
                                          name={`locations[${index}].shippingCityId`}
                                          value={
                                            validation.values.locations[index]
                                              .shippingCityId || ''
                                          }
                                          // onChange={validation.handleChange}
                                          onChange={(e) => {
                                            handleLocationData(
                                              `locations[${index}].shippingCityId`,
                                              index,
                                              'shippingCityId',
                                              e
                                            )
                                          }}
                                          onBlur={validation.handleBlur}
                                          invalid={
                                            !!(validation.touched &&
                                            validation.touched.locations &&
                                            validation.touched.locations[
                                              index
                                            ] &&
                                            validation.touched.locations[index]
                                              .shippingCityId &&
                                            validation.errors &&
                                            validation.errors.locations &&
                                            validation.errors.locations[
                                              index
                                            ] &&
                                            validation.errors.locations[index]
                                              .shippingCityId)
                                          }
                                        >
                                          <option value="">
                                            -Select City-
                                          </option>
                                          {citiesLocations &&
                                            citiesLocations[index] &&
                                            citiesLocations[index].map(
                                              (item) => (
                                                <option
                                                  key={item.id}
                                                  value={item.id}
                                                >
                                                  {item.name}
                                                </option>
                                              )
                                            )}
                                        </Input>
                                        {validation.touched &&
                                        validation.touched.locations &&
                                        validation.touched.locations[index] &&
                                        validation.touched.locations[index]
                                          .shippingCityId &&
                                        validation.errors &&
                                        validation.errors.locations &&
                                        validation.errors.locations[index] &&
                                        validation.errors.locations[index]
                                          .shippingCityId
                                          ? (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.locations[index]
                                                .shippingCityId
                                            }
                                          </FormFeedback>
                                            )
                                          : null}
                                      </div>
                                      <div className="mb-4">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Zip Code/Postal Code*
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Enter Zipcode"
                                          name={`locations[${index}].shippingZipCode`}
                                          value={
                                            validation.values.locations[index]
                                              .shippingZipCode || ''
                                          }
                                          // onChange={validation.handleChange}
                                          onChange={(e) => {
                                            handleLocationData(
                                              `locations[${index}].shippingZipCode`,
                                              index,
                                              'shippingZipCode',
                                              e
                                            )
                                          }}
                                          onBlur={validation.handleBlur}
                                          invalid={
                                            !!(validation.touched &&
                                            validation.touched.locations &&
                                            validation.touched.locations[
                                              index
                                            ] &&
                                            validation.touched.locations[index]
                                              .shippingZipCode &&
                                            validation.errors &&
                                            validation.errors.locations &&
                                            validation.errors.locations[
                                              index
                                            ] &&
                                            validation.errors.locations[index]
                                              .shippingZipCode)
                                          }
                                        />
                                        {validation.touched &&
                                        validation.touched.locations &&
                                        validation.touched.locations[index] &&
                                        validation.touched.locations[index]
                                          .shippingZipCode &&
                                        validation.errors &&
                                        validation.errors.locations &&
                                        validation.errors.locations[index] &&
                                        validation.errors.locations[index]
                                          .shippingZipCode
                                          ? (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.locations[index]
                                                .shippingZipCode
                                            }
                                          </FormFeedback>
                                            )
                                          : null}
                                        {dupLocationError
                                          ? (
                                          <FormFeedback
                                            type="invalid"
                                            style={{ display: 'block' }}
                                          >
                                            State/City/Zipcode cannot be same as
                                            above.
                                          </FormFeedback>
                                            )
                                          : null}
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <input
                                          className=" m-0 ms-2"
                                          type="checkbox"
                                          value=""
                                          id={'defaultCheck' + index}
                                          name={`locations[${index}].isPrimaryLocation`}
                                          onChange={validation.handleChange}
                                          onClick={(e) =>
                                            handlePrimaryLocation(
                                              `locations[${index}].isPrimaryLocation`,
                                              index,
                                              e
                                            )
                                          }
                                          onBlur={validation.handleBlur}
                                          checked={
                                            validation.values.locations[index]
                                              .isPrimaryLocation
                                          }
                                        />
                                        <label
                                          className="form-check-label ms-2"
                                          htmlFor={'defaultCheck' + index}
                                        >
                                          <span className="info-icon">
                                            Set as Primary Warehouse
                                            {validation.values.locations
                                              .length == 1 && (
                                              <span className="tooltiptextt">
                                                To change primary warehouse
                                                please add more than 1 locations
                                              </span>
                                            )}
                                          </span>
                                          {/* Set as Primary Warehouse */}
                                        </label>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <Row>
                                        <div className="d-flex align-items-center justify-content-between">
                                        <h3 className="new-registration-heading-black">
                                          Contact Information
                                        </h3>
                                        <div className='d-flex align-items-center'>
                          <input
                              type="checkbox"
                              name={`locations-${index}`}
                              id={`locations-${index}`}
                              className="me-2"
                              onChange={(e) => { handleLocationsFill(e, index) }}
                            />
                        <label htmlFor={`locations-${index}`} className="cursor-pointer m-0">
                          Same as Primary Details
                        </label>
                          </div>
                          </div>
                                        <Col lg={6}>
                                          <div className="mb-4">
                                            <Label htmlFor="formrow-firstname-Input">
                                              First Name*
                                            </Label>
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id="formrow-firstname-Input"
                                              placeholder="Enter First Name"
                                              name={`locations[${index}].contactFirstName`}
                                              value={
                                                validation.values.locations[
                                                  index
                                                ].contactFirstName || ''
                                              }
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              invalid={
                                                !!(validation.touched &&
                                                validation.touched.locations &&
                                                validation.touched.locations[
                                                  index
                                                ] &&
                                                validation.touched.locations[
                                                  index
                                                ].contactFirstName &&
                                                validation.errors &&
                                                validation.errors.locations &&
                                                validation.errors.locations[
                                                  index
                                                ] &&
                                                validation.errors.locations[
                                                  index
                                                ].contactFirstName)
                                              }
                                            />
                                            {validation.touched &&
                                            validation.touched.locations &&
                                            validation.touched.locations[
                                              index
                                            ] &&
                                            validation.touched.locations[index]
                                              .contactFirstName &&
                                            validation.errors &&
                                            validation.errors.locations &&
                                            validation.errors.locations[
                                              index
                                            ] &&
                                            validation.errors.locations[index]
                                              .contactFirstName
                                              ? (
                                              <FormFeedback type="invalid">
                                                {
                                                  validation.errors.locations[
                                                    index
                                                  ].contactFirstName
                                                }
                                              </FormFeedback>
                                                )
                                              : null}
                                          </div>
                                        </Col>
                                        <Col lg={6}>
                                          <div className="mb-4">
                                            <Label htmlFor="formrow-firstname-Input">
                                              Last Name*
                                            </Label>
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id="formrow-firstname-Input"
                                              placeholder="Enter Last Name"
                                              name={`locations[${index}].contactLastName`}
                                              value={
                                                validation.values.locations[
                                                  index
                                                ].contactLastName || ''
                                              }
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              invalid={
                                                !!(validation.touched &&
                                                validation.touched.locations &&
                                                validation.touched.locations[
                                                  index
                                                ] &&
                                                validation.touched.locations[
                                                  index
                                                ].contactLastName &&
                                                validation.errors &&
                                                validation.errors.locations &&
                                                validation.errors.locations[
                                                  index
                                                ] &&
                                                validation.errors.locations[
                                                  index
                                                ].contactLastName)
                                              }
                                            />
                                            {validation.touched &&
                                            validation.touched.locations &&
                                            validation.touched.locations[
                                              index
                                            ] &&
                                            validation.touched.locations[index]
                                              .contactLastName &&
                                            validation.errors &&
                                            validation.errors.locations &&
                                            validation.errors.locations[
                                              index
                                            ] &&
                                            validation.errors.locations[index]
                                              .contactLastName
                                              ? (
                                              <FormFeedback type="invalid">
                                                {
                                                  validation.errors.locations[
                                                    index
                                                  ].contactLastName
                                                }
                                              </FormFeedback>
                                                )
                                              : null}
                                          </div>
                                        </Col>
                                        <div className="mb-4">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Email Address*
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control "
                                            id="formrow-firstname-Input"
                                            placeholder="Enter Email Address"
                                            name={`locations[${index}].contactEmail`}
                                            value={
                                              validation.values.locations[index]
                                                .contactEmail || ''
                                            }
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                              !!(validation.touched &&
                                              validation.touched.locations &&
                                              validation.touched.locations[
                                                index
                                              ] &&
                                              validation.touched.locations[
                                                index
                                              ].contactEmail &&
                                              validation.errors &&
                                              validation.errors.locations &&
                                              validation.errors.locations[
                                                index
                                              ] &&
                                              validation.errors.locations[index]
                                                .contactEmail)
                                            }
                                          />
                                          {validation.touched &&
                                          validation.touched.locations &&
                                          validation.touched.locations[index] &&
                                          validation.touched.locations[index]
                                            .contactEmail &&
                                          validation.errors &&
                                          validation.errors.locations &&
                                          validation.errors.locations[index] &&
                                          validation.errors.locations[index]
                                            .contactEmail
                                            ? (
                                            <FormFeedback type="invalid">
                                              {
                                                validation.errors.locations[
                                                  index
                                                ].contactEmail
                                              }
                                            </FormFeedback>
                                              )
                                            : null}
                                        </div>
                                        <div className="mb-4">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Phone Number*
                                          </Label>
                                          <NumberFormat
                                          className={
                                            validation.touched && validation.touched.locations && validation.touched.locations[index].contactPhone && validation.errors &&
                                            validation.errors.locations && validation.errors.locations[index]?.contactPhone
                                              ? 'form-control is-invalid'
                                              : 'form-control'
                                          }
                                          id="formrow-firstname-Input"
                                          placeholder="Enter Phone Number"
                                          name={`locations[${index}].contactPhone`}
                                          displayType="input" format="(###) ###-####"
                                          value={validation.values.locations[index].contactPhone || ''}
                                          onValueChange={(values) => {
                                            if (values.value.length < 11) {
                                              validation.setFieldValue(
                                                `locations[${index}].contactPhone`,
                                                values.value
                                              )
                                            }
                                          }}
                                          />
                                          {/* <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Enter Phone Number"
                                            name={`locations[${index}].contactPhone`}
                                            value={
                                              validation.values.locations[index]
                                                .contactPhone || ''
                                            }
                                            onChange={(e) => {
                                              if (e.target.value.length < 11) {
                                                validation.setFieldValue(
                                                  `locations[${index}].contactPhone`,
                                                  e.target.value
                                                )
                                              }
                                            }}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                              !!(validation.touched &&
                                              validation.touched.locations &&
                                              validation.touched.locations[
                                                index
                                              ] &&
                                              validation.touched.locations[
                                                index
                                              ].contactPhone &&
                                              validation.errors &&
                                              validation.errors.locations &&
                                              validation.errors.locations[
                                                index
                                              ] &&
                                              validation.errors.locations[index]
                                                .contactPhone)
                                            }
                                          /> */}
                                          {validation.touched &&
                                          validation.touched.locations &&
                                          validation.touched.locations[index] &&
                                          validation.touched.locations[index]
                                            .contactPhone &&
                                          validation.errors &&
                                          validation.errors.locations &&
                                          validation.errors.locations[index] &&
                                          validation.errors.locations[index]
                                            .contactPhone
                                            ? (
                                              <FormFeedback type="invalid">
                                              {
                                                validation.errors.locations[
                                                  index
                                                ].contactPhone
                                              }
                                            </FormFeedback>
                                              )
                                            : null}
                                        </div>
                                        <div className="mb-4">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Title*
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Enter Title"
                                            name={`locations[${index}].contactTitle`}
                                            value={
                                              validation.values.locations[index]
                                                .contactTitle || ''
                                            }
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                              !!(validation.touched &&
                                              validation.touched.locations &&
                                              validation.touched.locations[
                                                index
                                              ] &&
                                              validation.touched.locations[
                                                index
                                              ].contactTitle &&
                                              validation.errors &&
                                              validation.errors.locations &&
                                              validation.errors.locations[
                                                index
                                              ] &&
                                              validation.errors.locations[index]
                                                .contactTitle)
                                            }
                                          />
                                          {validation.touched &&
                                          validation.touched.locations &&
                                          validation.touched.locations[index] &&
                                          validation.touched.locations[index]
                                            .contactTitle &&
                                          validation.errors &&
                                          validation.errors.locations &&
                                          validation.errors.locations[index] &&
                                          validation.errors.locations[index]
                                            .contactTitle
                                            ? (
                                            <FormFeedback type="invalid">
                                              {
                                                validation.errors.locations[
                                                  index
                                                ].contactTitle
                                              }
                                            </FormFeedback>
                                              )
                                            : null}
                                        </div>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                              <hr></hr>
                            </div>
                          </div>
                        ))}
                        <button
                          type="button"
                          className="new-wd-add-new-btn add-newbtn-hover"
                          onClick={() =>
                            arrayHelpers.push({
                              locationName: '',
                              shippingStreet: '',
                              shippingCountryId: globalVariables.countryId,
                              shippingStateId: '',
                              shippingCityId: '',
                              shippingZipCode: '',
                              contactFirstName: '',
                              contactLastName: '',
                              contactEmail: '',
                              contactPhone: '',
                              contactTitle: '',
                              isPrimaryLocation: false,
                              open: false
                            })
                          }
                        >
                          <i className="bx bx-plus"></i>Add New Location
                        </button>
                      </div>
                    )}
                  />

                  <Row>
                    <Col
                      lg={12}
                      className="mt-2 save-and-approv-div  d-flex justify-content-center"
                    >
                      <Button
                        color="primary"
                        className="new-wd-approval-btn "
                        type="submit"
                      >
                        Save and Approve
                      </Button>
                      <button
                        className="new-wd-reset-btn"
                        type="reset"
                        onClick={(e) => {
                          validation.handleReset()
                          setCitiesLocations([])
                          setSelectedCategory([])
                          setFileObj({})
                          setDupLocationError(false)
                          setemailandmobileerror({ email: '', mobile: '' })
                        }}
                      >
                        Reset
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            </FormikProvider>
          </Card>
        </Col>
      </Row>
    </div>
  )
})
