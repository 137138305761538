import React from 'react'
import { Link } from 'react-router-dom'
import logo from 'assets/images/QLlogo.svg'

function StaticHeader () {
  return (
        <div className='main-static-header'>
            <div className='static-header'>
                <div>
                    <Link to="/"><img src={logo} className='qls-img' alt='QLsupply' /></Link>
                </div>
                <div className='already-account'>

                            <span className='new-qls'>New to QLS ? <Link to='/register' className='header-register' > Contact Us</Link></span>

                </div>
            </div>
        </div>
  )
}

export default StaticHeader
