import PropTypes from 'prop-types'
import React from 'react'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import './DisableModal'

const DisableModal = ({ show, onDeleteClick, onCloseClick }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3">
        <Row>
          <Col lg={12}>
        <div className="modal-body">
                    <p className="disable-warehouse">
                    </p>
                    <div className="modal-detail-div">
                    <div className="text-center">
                    <i
                      className="mdi mdi-alert-circle-outline"
                      style={{ fontSize: '6em', color: 'orange' }}
                    />
                    <h2>Are you sure?</h2>
                    <h4>{"You won't be able to revert this!"}</h4>
                  </div>
                    </div>
                    <div className="modal-btn-div">
                      <button
                        className="warehouse-btn-yes"
                        onClick={onDeleteClick}
                      >
                        Yes
                      </button>
                      <button
                        className="warehouse-btn-yes warehouse-btn-no"
                        onClick={onCloseClick}
                      >
                        No
                      </button>
                    </div>
                  </div>
                  </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

DisableModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default DisableModal
