/* eslint-disable multiline-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'
import './table.scss'
import disable from '../../assets/images/disable.svg'
import deleteIcon from '../../assets/images/ic_delete.svg'
import { Pagination } from '@mui/material'
import NumberFormat from 'react-number-format'
export default function TableComponent (props) {
  const [totalPages, setTotalPages] = useState(1)
  const [inputIcon, setInputIcon] = useState([])
  const [inputText, setInputText] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    if (props.content.length) {
      const updatedInputIcon = props.content.map((item) => {
        return false
      })
      setInputIcon([...updatedInputIcon])
      const updatedInputText = props.content.map((item) => {
        if (props.inputField) {
          return item[props.inputField]
        } else {
          return item.sellingPrice || item.price || 0
        }
      })
      setInputText([...updatedInputText])
    }
  }, [props.content])

  useEffect(() => {
    setCurrentPage(props.paging.currentPage)
  }, [props.paging.currentPage])

  function handlePage (e, value) {
    e.preventDefault()
    setCurrentPage(value)
    props.paginationHandler(value)
  }

  function handleInputUpdate (e, data, index) {
    e.preventDefault()
    props.updateSellingPrice(e, data, inputText[index], currentPage)
    const inputArr = [...inputIcon]
    inputArr[index] = false
    setInputIcon(inputArr)
  }

  function handleInput (e, index) {
    e.preventDefault()
    if (props?.inputField === 'quantity') {
      if (isNaN(e.target.value)) { return }
      e.target.value = e.target.value.replace(/\D/g, '')
    }
    const inputArr = [...inputIcon]
    inputArr[index] = true
    setInputIcon(inputArr)
    const inputTextArr = [...inputText]
    inputTextArr[index] = e.target.value
    setInputText(inputTextArr)
  }

  function handleInputCross (e, index) {
    e.preventDefault()
    const inputArr = [...inputIcon]
    inputArr[index] = false
    setInputIcon(inputArr)
    const inputTextArr = [...inputText]
    inputTextArr[index] = props.content[index].sellingPrice
    setInputText(inputTextArr)
  }

  return (
    <div className="table-responsive">
      <Table className="table mb-0" hover>
        <thead className="table-light">
          <tr>
            {props.columns.map((col, key) => {
              return (
                <th key={key}>
                  <div className="d-flex align-items-center">
                    <span>{col.label}</span>{' '}
                    {col.sorting &&
                      (props.arrowState.order &&
                      props.arrowState.sortColumn == col.key
                        ? (
                            props.arrowState.order == 'asc' &&
                        props.arrowState.sortColumn == col.key
                              ? (
                          <i
                            className="bx bx-down-arrow-alt sorting-arrow"
                            onClick={(e) => props.sorting(e, col.key)}
                          ></i>
                                )
                              : (
                          <i
                            className="bx bx-up-arrow-alt sorting-arrow"
                            onClick={(e) => props.sorting(e, col.key)}
                          ></i>
                                )
                          )
                        : (
                        <i
                          className="bx bx-sort sorting-arrow"
                          onClick={(e) => props.sorting(e, col.key)}
                        ></i>
                          ))}
                  </div>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody style={{ verticalAlign: 'middle' }}>
          {props.content.length ? (
            props.content?.map((item, i) => {
              return (
                <tr key={i}>
                  {props.columns.map((col, key) => {
                    return (
                      col.key === 'phone' || col.key === 'mobile'
                        ? <td key={key} className="staff-table-tbody"><NumberFormat value={item[col.key]}
                      displayType={'text'}
                      className="input-style-detail d-flex align-items-center"
                      format="(###) ###-####"/></td>
                        : col.input ? <td
                      key={key}
                    >
                      <div className='d-flex align-items-center'>
                      {inputText.length && <input type='text' className='text-center input-sell-price' value={inputText[i]} onChange={e => handleInput(e, i)} onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleInputUpdate(e, item, i)
                        }
                      }}/>}
                      {inputIcon[i] && <div className='mx-1'><i className='fas fa-check-circle input-icon-tick mr-1' onClick={e => handleInputUpdate(e, item, i)}></i> <i className='fas fa-times-circle input-icon-cross' onClick={e => handleInputCross(e, i)}></i></div>}
                      </div>
                    </td> : col.html ? (
                      <td
                        key={key}
                        onClick={() =>
                          props.onClick(item)
                        } /* dangerouslySetInnerHTML={{__html: item[col.key] ? item[col.key] : '--'}} */
                      >
                        {item[col.key] ? item[col.key] : '--'}
                      </td>
                        ) : col.key === 'action' ? (
                          item[col.key] ? (
                        <td key={key} className="">
                          <div className="d-flex align-items-center" style={ props.verticalCol ? { flexDirection: 'column' } : {} }>
                            {item[col.key].map((action, aKey) => {
                              return action === 'view' ? (
                                <i
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    props.handleActions(action, item)
                                  }
                                  key={aKey}
                                  className="mdi mdi-eye-outline eye-icon table-icon-hover"
                                ></i>
                              ) : action === 'disable' ? (
                                <img
                                  src={disable}
                                  style={props.verticalCol ? {
                                    cursor: 'pointer',
                                    paddingLeft: '0px'
                                  } : {
                                    marginLeft: '15px',
                                    cursor: 'pointer'
                                  }}
                                  className="table-img-hover"
                                  onClick={() =>
                                    props.handleActions(action, item)
                                  }
                                  key={aKey}
                                />
                              ) // <i style={{cursor:'pointer'}} onClick={()=>props.handleActions(action,item)} key={aKey} className="bx bx-stop-circle stop-icon"></i>
                                : action === 'delete' ? (
                                <img
                                  src={deleteIcon}
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: '15px'
                                  }}
                                  className="table-img-hover"
                                  onClick={() =>
                                    props.handleActions(action, item)
                                  }
                                  key={aKey}
                                />
                                ) // <i style={{cursor:'pointer',size:25}} onClick={()=>props.handleActions(action,item)} key={aKey} className="mdi mdi-delete"></i>
                                  : action === 'enable'
                                    ? (
                                <i
                                  className="bx bx-comment-error table-enable-hover"
                                  style={{
                                    marginLeft: '15px',
                                    cursor: 'pointer',

                                    fontSize: '20px',
                                    color: 'gray'
                                  }}
                                  onClick={() =>
                                    props.handleActions(action, item)
                                  }
                                  key={aKey}
                                />
                                      )
                                    : action === 'paybtn' ? (
                                      <button className='btn btn-secondary' onClick={() =>
                                        props.handleActions(action, item)
                                      }>Notify</button>
                                    ) : action === 'disablePaybtn' ? (
                                      <button className='btn btn-secondary' disabled>Notify</button>
                                    ) : (
                                      '--'
                                    )
                            })}
                          </div>
                        </td>
                          ) : (
                        <td onClick={() => props.onClick(item)} key={key}>
                          --
                        </td>
                          )
                        ) : (
                      <td onClick={() => props.onClick(item)} key={key}>
                        {item[col.key] ? item[col.key] : '--'}
                      </td>
                        ))
                  })}
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={props.columns.length} style={ { position: 'unset' } }>Data not found...</td>
            </tr>
          )}
        </tbody>
      </Table>
      {/* {Math.ceil(props.paging.totalRecord / props.paging.recordPerPage) > 1
        ? (
        <div className="d-flex align-items-center justify-content-center mt-3 mb-1">
          <div
            style={{ cursor: 'pointer' }}
            onClick={() =>
              props.paging.currentPage === 1
                ? ''
                : props.paginationHandler(props.paging.currentPage - 1)
            }
            className="pagination-box pagination-arrow-hover"
          >
            <i className="fas fa-angle-left" style={{ fontSize: '18px' }}></i>{' '}
          </div>
          {[
            ...Array(
              Math.ceil(props.paging.totalRecord / props.paging.recordPerPage)
            )
          ].map((x, i) => (
            <div
              style={{ cursor: 'pointer' }}
              key={i}
              onClick={() => props.paginationHandler(i + 1)}
              className={

                props.paging.currentPage === i + 1
                  ? 'pagination-box pagination-box-active'
                  : 'pagination-box'
              }
              id="pagination-hover"
            >
              {i + 1}
            </div>
          ))}
          <div
            style={{ cursor: 'pointer' }}
            onClick={() =>
              props.paging.currentPage ===
              Math.ceil(props.paging.totalRecord / props.paging.recordPerPage)
                ? ''
                : props.paginationHandler(props.paging.currentPage + 1)
            }
            className="pagination-box  pagination-arrow-hover"
          >
            <i className="fas fa-angle-right" style={{ fontSize: '18px' }}></i>{' '}
          </div>
        </div>
          )
        : (
        <></>
          )} */}
          {
            Math.ceil(props.paging.totalRecord / props.paging.recordPerPage) > 1 && <div className='d-flex justify-content-center mt-2'>
            <Pagination count={Math.ceil(props.paging.totalRecord / props.paging.recordPerPage)} page={currentPage} onChange={handlePage} variant="outlined" color="primary" shape="rounded" />
            </div>
          }
    </div>
  )
}

TableComponent.propTypes = {
  columns: PropTypes.array,
  content: PropTypes.array,
  handleActions: PropTypes.func,
  paging: PropTypes.object,
  paginationHandler: PropTypes.func,
  onClick: PropTypes.func,
  sorting: PropTypes.func,
  arrowState: PropTypes.object,
  updateSellingPrice: PropTypes.func,
  inputField: PropTypes.any,
  verticalCol: PropTypes.any
}

TableComponent.defaultProps = {
  onClick: function () {}
}
