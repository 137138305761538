/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import TableComponent from "../../components/Table";

import { GetData } from "../../apiHandler";
import Loader from "../../components/Loader";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
} from "reactstrap";
import { useParams } from "react-router-dom";

const filterByKeyObject = { key: "all" };
let searchTimer;

function CustomerList() {
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [wdCustomer, setWDCustomer] = useState([]);
  const [singlebtn, setSinglebtn] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [filterTypes, setFilterTypes] = useState([
    { value: "all", key: "All" },
    { value: "name", key: "Name" },
    { value: "phone", key: "Phone" },
    { value: "email", key: "Email" },
  ]);
  const [selectedFilter, setSelectedFilter] = useState({
    value: "all",
    key: "All",
  });

  const filterByKey = (obj) => {
    filterByKeyObject.key = obj.value;
    setSelectedFilter(obj);
    if (filterByKeyObject.string) {
      getWDCustomer(1);
    }
  };

  const dropToggle = () => {
    setSinglebtn(!singlebtn);
  };

  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0,
  });
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null,
  });
  const columns = [
    { key: "companyName", label: "Company Name", sorting: true },
    { key: "name", label: "Customer Name", sorting: true },
    { key: "phone", label: "Mobile Number" },
    { key: "email", label: "Email Address", sorting: true },
    { key: "location", label: "Location" },
    { key: "status", label: "Status" },
  ];

  useEffect(() => {
    sortingState.sortColumn &&
      sortingState.order &&
      getWDCustomer(paging.currentPage);
  }, [sortingState]);

  const getWDCustomer = (p = 1) => {
    const pageSize = 20;
    let url =
      `admin/getCustomerList/${params.id}?page=` + p + "&pageSize=" + pageSize;

    if (sortingState.sortColumn != null) {
      url +=
        "&sortBy=" +
        sortingState.sortColumn +
        "&order=" +
        sortingState.order.toUpperCase();
    }

    if (filterByKeyObject.string) {
      url +=
        "&filterBy=" +
        filterByKeyObject.key +
        "&search=" +
        filterByKeyObject.string;
    }

    setLoader(true);
    GetData(url).then((response) => {
      setLoader(false);
      if (response.status) {
        // setFilterTypes([])
        if (response.result.customersData.length) {
          const tableData = [];
          response.result.customersData.map((data, index) => {
            let statusString = <div className="pending-div">Pending</div>;
            if (data.status === 1) {
              statusString = <div className="active-div">Active</div>;
            }
            if (data.status === 2) {
              statusString = <div className="approved-div">Approved</div>;
            }
            if (data.status === 3) {
              statusString = <div className="rejected-div">Rejected</div>;
            }
            if (data.status === 4) {
              statusString = <div className="disabled-div">Disabled</div>;
            }
            data.tempStatus = data.status;
            data.status = statusString;
            data.name = data.fullName;
            data.phone = data.mobile ? data.mobile : "--";
            data.location = (
              <p>
                <span>{data?.customerLocations[0]?.cities?.name}</span>
                {data.customerLocations.length - 1 > 0 && (
                  <>
                    &nbsp;&nbsp;
                    <span
                      className="tooltip-main"
                      id={`UncontrolledTooltipExample${index}`}
                      style={{ color: "#1e8fe1", fontWeight: "600" }}
                    >
                      +{data.customerLocations.length - 1}
                    </span>
                    <UncontrolledTooltip
                      className="wd-tool"
                      placement="left"
                      target={`UncontrolledTooltipExample${index}`}
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        boxShadow:
                          "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                        borderRadius: "4px",
                        border: "red",
                      }}
                    >
                      {data.customerLocations.map((location, j) => {
                        if (j !== 0) {
                          return (
                            <p className="m-1" key={j}>
                              {location?.cities?.name}
                            </p>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </UncontrolledTooltip>
                  </>
                )}
              </p>
            );

            tableData.push(data);
            return null;
          });

          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: response.result.count,
          });
          setWDCustomer(tableData);
        } else {
          setWDCustomer([]);
          setPaging({
            currentPage: 1,
            recordPerPage: pageSize,
            totalRecord: 0,
          });
        }
      } else {
        setWDCustomer([]);
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 });
        toastr.error(response.message, "Error");
      }
    });
  };
  useEffect(() => {
    getWDCustomer();
  }, []);

  const paginationHandler = (page) => {
    getWDCustomer(page);
  };

  const searchByText = (t) => {
    setSearchText(t);
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    searchTimer = setTimeout(() => {
      filterByKeyObject.string = t;
      getWDCustomer(1);
    }, 2000);
  };

  const handleSort = (e, column) => {
    e.preventDefault();
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: "asc" });
    } else if (sortingState.sortColumn === column) {
      if (sortingState.order === "desc") {
        setSortingState({ sortColumn: column, order: "asc" });
      } else setSortingState({ sortColumn: column, order: "desc" });
    } else {
      setSortingState({ sortColumn: column, order: "asc" });
    }
  };

  const handleRow = (rowData) => {
    setSelectedData(rowData);
    setDetailsModal(true);
  };

  const primaryLocations = selectedData?.customerLocations?.filter(
    (location) => location.isPrimaryLocation === 1
  );


  return (
    <div>
      <Loader show={loader} />
      <div
        className="d-flex justify-content-end"
        style={{ marginLeft: "3em", marginRight: "3em" }}
      >
        <div className="d-flex">
          <Dropdown isOpen={singlebtn} toggle={dropToggle}>
            <DropdownToggle className="customer-select text-dark">
              Filter By{" "}
              <span className="font-weight-bold">{selectedFilter.key}</span>
              <i className=" ms-2 bx bx-caret-down" />
            </DropdownToggle>
            <DropdownMenu>
              {filterTypes.map((filterObj, key) => {
                return (
                  <DropdownItem
                    key={key}
                    onClick={() => filterByKey(filterObj)}
                  >
                    {filterObj.key}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
          <div className="customer-search-form">
            <input
              className="form-control input-border customer-input-border"
              type="text"
              placeholder="Search"
              onChange={(e) => searchByText(e.target.value)}
              value={searchText}
            />
            <i className="bx bx-search-alt-2 search-icon"></i>
          </div>
        </div>
      </div>
      <div className="staff-main-table">
        <TableComponent
          columns={columns}
          content={wdCustomer}
          paging={paging}
          sorting={handleSort}
          paginationHandler={paginationHandler}
          arrowState={sortingState}
          onClick={handleRow}
        />
      </div>

      <Modal
        className="modal-main-body"
        isOpen={detailsModal}
        toggle={() => setDetailsModal(false)}
        centered
      >
        <div className="modal-header">
          <button
            type="button"
            onClick={() => {
              setDetailsModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div className="modal-detail-div">
            <p className="modal-details">Customer Details</p>
            <p className="modal-warehouse-name mt-2">
              <span className="warehouse-heading"> Company Name: </span>{" "}
              {selectedData?.companyName ? selectedData?.companyName : "--"}
            </p>
            <p className="modal-warehouse-name">
              <span className="warehouse-heading"> Customer Name: </span>{" "}
              {selectedData?.name ? selectedData?.name : "--"}
            </p>
            <p className="modal-warehouse-name">
              <span className="warehouse-heading"> Email: </span>{" "}
              {selectedData?.email ? selectedData?.email : "--"}
            </p>
            <p className="modal-warehouse-name">
              <span className="warehouse-heading"> Mobile Number: </span>{" "}
              {selectedData?.phone ? selectedData?.phone : "--"}
            </p>
            <p className="modal-warehouse-name">
              <span className="warehouse-heading"> Primary Location: </span>{" "}
              {primaryLocations && primaryLocations.length > 0
                ? primaryLocations.map((location, index) => (
                    <span key={index}>
                      {location.locationName}, {location?.cities?.name}, {location?.states?.name}
                      {/* {index < primaryLocations.length - 1 ? ", " : ""} */}
                    </span>
                  ))
                : "--"}
            </p>
            <p className="modal-warehouse-name">
              <span className="warehouse-heading"> Customer Type: </span>{" "}
              {selectedData?.isOpenAccount ? "Open Account" : "Normal Account"}
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CustomerList;
