/* eslint-disable eqeqeq */
import React, { createContext, useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  CardTitle,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal
} from 'reactstrap'

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import CustomTable from './CustomTable'
import { useHistory, useParams } from 'react-router-dom'
import { GetData, PostData, DeleteData, PutData } from 'apiHandler'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Loader from '../../components/Loader'

export const DeleteModalContext = createContext()

function AddNewCategory (props) {
  const history = useHistory()
  const { id } = useParams()
  const [data, setData] = useState(null)
  const [blogCategories, setBlogCategories] = useState(null)
  const [loader, setLoader] = useState(false)
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const [clickedData, setClickedData] = useState(null)

  id &&
    useEffect(() => {
      setLoader(true)
      GetData(`admin/getBlogCategory/${id}`).then((response) => {
        if (response.status == true) {
          setData(response.result)
          setLoader(false)
        } else {
          setLoader(false)
        }
      })
    }, [id])

  useEffect(() => {
    setLoader(true)
    GetData('admin/getAllBlogCategories').then((response) => {
      if (response.status == true) {
        setBlogCategories(response.result)
        setLoader(false)
      } else {
        setLoader(false)
      }
    })
  }, [])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: data?.title || '',
      slug: data?.slug || '',
      metaTitle: data?.metaTitle || '',
      metaDescription: data?.metaDescription || ''
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Please Enter title'),
      slug: Yup.string().required('Please Enter slug'),
      metaTitle: Yup.string().required('Please Enter Meta Title'),
      metaDescription: Yup.string().required('Please Enter Meta Description')
    }),
    onSubmit: (values, { resetForm }) => {
      SubmitData(values, { resetForm })
    }
  })

  function SubmitData (formData, { resetForm }) {
    setLoader(true)
    !id &&
      PostData('admin/addBlogCategory', formData).then((response) => {
        if (response.status == true) {
          toastr.success(response.message, 'Sent')
          resetForm()
          GetData('admin/getAllBlogCategories').then((response) => {
            if (response.status == true) {
              setBlogCategories(response.result)
              setLoader(false)
            }
          })
        } else {
          toastr.error(response.message, 'Error')
          setLoader(false)
        }
      })
    id &&
      PutData(`admin/updateBlogCategory/${id}`, formData).then((response) => {
        if (response.status == true) {
          toastr.success(response.message, 'Sent')
          resetForm()
          history.push('/add-category')
          GetData('admin/getAllBlogCategories').then((response) => {
            if (response.status == true) {
              setBlogCategories(response.result)
              setLoader(false)
            }
          })
        } else {
          toastr.error(response.message, 'Error')
          setLoader(false)
        }
      })
  }

  const handleDelete = (id, e) => {
    e.preventDefault()
    setLoader(true)
    DeleteData(`admin/deleteBlogCategory/${id}`).then((response) => {
      if (response.status == true) {
        toastr.success(response.message, 'Success')
        GetData('admin/getAllBlogCategories').then((response) => {
          if (response.status == true) {
            setBlogCategories(response.result)
            setLoader(false)

            setIsDisableOpen(false)
          } else {
            setLoader(false)
            toastr.error(response.message, 'Error')
            setIsDisableOpen(false)
          }
        })
      }
    })
  }
  const handleModal = (e, data) => {
    e.preventDefault()
    data && setClickedData(data)
  }

  function handleSearch (e) {
    e.preventDefault()
    GetData(`admin/getAllBlogCategories?search=${e.target.value}`).then(
      (response) => {
        if (response.status == true) {
          console.log(response.result)
        }
      }
    )
  }

  return (
    <React.Fragment>
      <DeleteModalContext.Provider value={{ isDisableOpen, setIsDisableOpen }}>
        <Loader show={loader} />
        <div className="page-content">
          <MetaTags>
            <title>QLS Admin | Home Page</title>
          </MetaTags>
          <Container fluid={true} className="homepage-wrap">
            <Breadcrumbs
              title="UI Elements"
              breadcrumbItem="Knowledge Database Categories"
              icon={
                <i
                  className="bx bx-arrow-back me-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push('/knowledge-base')}
                ></i>
              }
            />
            <Form>
              <Row className="mt-3">
                <Col lg={6}>
                  <Card className="py-2 px-4">
                    <CardTitle className="p-3 pb-0 ps-2">
                      ADD NEW CATEGORY
                    </CardTitle>
                    <CardBody>
                      <Col lg={12} className="pb-2">
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="title">
                            <b>Title</b>
                          </Label>
                          <Input
                            placeholder="Enter Category Title"
                            type="text"
                            id="title"
                            name="title"
                            value={validation.values.title}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              !!(validation.touched.title &&
                              validation.errors.title)
                            }
                          />
                          {validation.touched.title &&
                          validation.errors.title
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                              )
                            : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label" htmlFor="metaTitle">
                            <b>SEO Title</b>
                          </Label>
                          <Input
                            id="metaTitle"
                            placeholder="Enter SEO Title"
                            type="text"
                            name="metaTitle"
                            value={validation.values.metaTitle}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              !!(validation.touched.metaTitle &&
                              validation.errors.metaTitle)
                            }
                          />
                          {validation.touched.metaTitle &&
                          validation.errors.metaTitle
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.metaTitle}
                            </FormFeedback>
                              )
                            : null}
                        </div>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="metaDescription"
                          >
                            <b>SEO Description</b>
                          </Label>
                          <textarea
                            id="metaDescription"
                            className={
                              validation.touched.metaDescription &&
                              validation.errors.metaDescription
                                ? 'form-control description-height is-invalid'
                                : 'form-control description-height'
                            }
                            name="metaDescription"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.metaDescription}
                          ></textarea>
                          {validation.touched.metaDescription &&
                          validation.errors.metaDescription
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.metaDescription}
                            </FormFeedback>
                              )
                            : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            <b>Slug</b>
                          </Label>
                          <Input
                            placeholder="Enter Slug"
                            type="text"
                            className="form-control"
                            id="slug"
                            name="slug"
                            value={validation.values.slug}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              !!(validation.touched.slug && validation.errors.slug)
                            }
                          />
                          {validation.touched.slug && validation.errors.slug
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.slug}
                            </FormFeedback>
                              )
                            : null}
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6} style={{ textAlign: '-webkit-right' }}>
                  <Card>
                    <CardBody
                      style={{ height: '473.6px' }}
                      className="category-card-container"
                    >
                      <Col lg={12} className="pb-2 text-right">
                        <div className="search-form">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search"
                            onChange={(e) => handleSearch(e)}
                          />
                          <i className="bx bx-search-alt-2 search-icon"></i>
                        </div>
                      </Col>
                      <CustomTable
                        title1="Title"
                        title2="SEO Title"
                        title3="Slug"
                        title4="Description"
                        content={blogCategories}
                        handleModal={handleModal}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Button
                    className="btn btn-cancel mb-4 ms-0"
                    onClick={() => {
                      history.push('/knowledge-base')
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-add-new mb-4 ms-0"
                    type="submit"
                    onClick={validation.handleSubmit}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
            <Modal
              className="modal-main-body"
              isOpen={isDisableOpen}
              toggle={() => setIsDisableOpen(false)}
              centered
            >
              <div className="modal-header">
                <button
                  type="button"
                  onClick={() => {
                    setIsDisableOpen(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p className="disable-warehouse">
                  Are you sure you want to Delete this Category?
                </p>
                <div className="modal-detail-div">
                  <p className="modal-details">Details</p>
                  <p className="modal-warehouse-name">
                    <span className="warehouse-heading"> Title: </span>{' '}
                    {clickedData?.title}
                  </p>
                  <p className="modal-warehouse-name">
                    <span className="warehouse-heading"> Description: </span>{' '}
                    {clickedData?.metaDescription}
                  </p>
                </div>
                <div className="modal-btn-div">
                  <button
                    className="warehouse-btn-yes"
                    onClick={(e) => handleDelete(clickedData.id, e)}
                  >
                    Yes
                  </button>
                  <button
                    className=" warehouse-btn-no"
                    onClick={() => setIsDisableOpen(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </Modal>
          </Container>
        </div>
      </DeleteModalContext.Provider>
    </React.Fragment>
  )
}

export default AddNewCategory
