import React, { useEffect, useState } from 'react'
import {
  Row, Col, Card, CardBody, CardTitle,
  Toast,
  ToastHeader,
  ToastBody,
  Spinner
} from 'reactstrap'
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator'

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import './datatables.scss'

import { GetData } from '../../apiHandler'
import Loader from '../../components/Loader'
import logo from '../../assets/images/logo-small.png'

const DatatableTables = () => {
  const [loader, setLoader] = useState(false)
  const [toast, setToast] = useState({ show: false, heading: '', message: '' })
  useEffect(() => {
    getCustomers()
  }, [])

  const getCustomers = () => {
    setLoader(true)
    GetData('admin/customerDetails').then((response) => {
      setLoader(false)
      if (response.status === true) {
        // do something
      } else {
        setToast({
          show: true,
          heading: 'Error !',
          message: response.message
        })
      }

      console.log('response', response)
    })
  }

  const columns = [{
    dataField: 'id',
    text: 'Id',
    sort: true
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true
  }, {
    dataField: 'position',
    text: 'Position',
    sort: true
  }, {
    dataField: 'office',
    text: 'Office',
    sort: true
  }, {
    dataField: 'age',
    text: 'Age',
    sort: true
  }, {
    dataField: 'startdate',
    text: 'Start Date',
    sort: true
  }, {
    dataField: 'salary',
    text: 'Salary',
    sort: true
  }]

  // Table Data
  const productData = [
    { id: 1, name: 'Airi Satou', position: 'Accountant', office: 'Tokyo', age: '33', startdate: '2008/11/28', salary: '$162,700' }
  ]

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true
  }

  // Custom Pagination Toggle
  // eslint-disable-next-line no-unused-vars
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (productData).length }]

  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <Loader show={loader}/>
      <div className="page-content">
        <MetaTags>
          <title>Data Tables | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Data Tables" />

          <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: '1005' }}>
            <Toast isOpen={toast.show}>
              <ToastHeader toggle={() => setToast({ show: false })} icon={<Spinner type="grow" size="sm" color="danger" />}>
                <img src={logo} alt="" className="me-2" height="18" />
                  {toast.heading}
              </ToastHeader>
              <ToastBody>
                  {toast.message}
              </ToastBody>
          </Toast>
          </div>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Default Datatable </CardTitle>
                  <p className="card-title-desc">
                    react-bootstrap-table-next plugin has most features enabled by default,
                    so all you need to do to use it with your own tables is to
                    call the construction function:{' '}
                    <code>react-bootstrap-table-next </code>.
                  </p>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      'table align-middle table-nowrap'
                                    }
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DatatableTables
