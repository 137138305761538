/* eslint-disable multiline-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-useless-return */
import React, { useState, useRef, useEffect } from 'react'
import Breadcrumbs from 'components/Common/Breadcrumb'
import { Row, Col, Card, CardBody } from 'reactstrap'
import moment from 'moment'
import validator from 'validator'
import {
  GetData,
  PostData,
  PostWithoutToken,
  ToesterSettings
} from 'apiHandler'
import deleteLogo from '../../assets/images/ic_delete.svg'
import calender from '../../assets/images/calendar (1).svg'
import info from '../../assets/images/info.svg'
import Loader from '../../components/Loader'
import DatePicker from 'react-datepicker'
import { globalVariables } from 'globalVariables'
import 'react-datepicker/dist/react-datepicker.css'
import { useHistory } from 'react-router-dom'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import NumberFormat from 'react-number-format'

const billingOptions = [{
  label: '30 Days',
  value: 30
}, {
  label: '60 Days',
  value: 60
}, {
  label: '90 Days',
  value: 90
}]

export default function NewCustomerRegistration () {
  const history = useHistory()
  toastr.options = ToesterSettings
  const countryId = globalVariables.countryId
  const documentUploadRef = useRef()
  const locationObj = {
    locationName: '',
    shippingStreet: '',
    shippingCountryId: countryId,
    shippingStateId: '',
    shippingCityId: '',
    shippingZipCode: '',
    billingStreet: '',
    billingCountryId: countryId,
    billingStateId: '',
    billingCityId: '',
    billingZipCode: '',
    isPrimaryLocation: 1,
    shippingCityList: [],
    billingCityList: [],
    open: true,
    completed: false,
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactPhone: '',
    contactTitle: ''
  }

  const [step, setStep] = useState(1)
  const [locations, setLocations] = useState([locationObj])
  const [locationsError, setLocationsError] = useState([locationObj])
  const [states, setStates] = useState([])
  const [sliceArray, setSliceArray] = useState([])
  const [loader, showLoader] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    companyName: '',
    mobile: '',
    chainAffiliation: '',
    chainAffiliationOther: '',
    dateCompanyStartedTemp: '',
    ownershipType: '',
    businessType: '',
    businessTypeOther: '',
    reSaleTaxNumber: '',
    documentFile: '',
    documentFileUrl: '',
    noOfLocations: 1,
    isOpenAccount: false,
    allow_dropship: false,
    primaryFirstName: '',
    primaryLastName: '',
    primaryEmail: '',
    primaryPhone: '',
    primaryTitle: '',
    alternateFirstName: '',
    alternateLastName: '',
    alternateEmail: '',
    alternatePhone: '',
    alternateTitle: '',
    billingCycleDuration: '30'
  })
  const [formErrorsStep1, setFormErrorsStep1] = useState({
    companyName: '',
    email: '',
    mobile: '',
    chainAffiliation: '',
    dateCompanyStartedTemp: '',
    ownershipType: '',
    businessType: '',
    reSaleTaxNumber: '',
    primaryFirstName: '',
    primaryLastName: '',
    primaryEmail: '',
    primaryPhone: '',
    primaryTitle: ''
    // alternateFirstName: '',
    // alternateLastName: '',
    // alternateEmail: '',
    // alternatePhone: '',
    // alternateTitle: ''
  })
  const errorStrings1 = {
    email: 'Please enter email',
    companyName: 'Please enter company name',
    mobile: 'Please enter mobile number',
    chainAffiliation: 'Please select chain affiliation',
    // dateCompanyStartedTemp: 'Please select date',
    ownershipType: 'Please select at least one ownership type',
    businessType: 'Please select at least one business type',
    // reSaleTaxNumber: 'Please enter resale tax number',
    primaryFirstName: 'Please enter first name',
    primaryLastName: 'Please enter last name',
    primaryEmail: 'Please enter email',
    primaryPhone: 'Please enter phone',
    primaryTitle: 'Please enter title'
  }

  const locationErrorStrings = {
    locationName: 'Please enter location name',
    shippingStreet: 'Please enter steet',
    shippingStateId: 'Please select state',
    shippingCityId: 'Please select city',
    shippingZipCode: 'Please enter zip code',
    billingStreet: 'Please enter steet',
    billingStateId: 'Please select state',
    billingCityId: 'Please select city',
    billingZipCode: 'Please enter zip code',
    contactFirstName: 'Please enter first name',
    contactLastName: 'Please enter last name',
    contactEmail: 'Please enter email',
    contactPhone: 'Please enter phone',
    contactTitle: 'Please enter title'
  }

  const [businessType, setBusinessType] = useState([])
  const [ownershipType, setOwnershipType] = useState([])
  const [chainAffiliation, setChainAffiliation] = useState([])
  const [documentUploaded, setDocumentUploaded] = useState('')
  const [documentUploadedError, setDocumentUploadedError] = useState('')
  const [formErrorsStep2, setFormErrorsStep2] = useState({})
  const [billing, setBilling] = useState(false)
  const [existing, setExisting] = useState(false)
  const [dropDown, setDropDown] = useState(false)
  const [slicekey, setSliceKey] = useState(null)
  const [sameAddress, setSameAddress] = useState([false])
  const [selectBilling, setSelectBilling] = useState(false)

  useEffect(() => {
    setChainAffiliation(
      JSON.parse(localStorage.getItem('commonData')).chainAffilation
    )
    setOwnershipType(
      JSON.parse(localStorage.getItem('commonData')).ownershipType
    )
    setBusinessType(
      JSON.parse(localStorage.getItem('commonData')).businessType
    )
    GetData('states?countryId=' + countryId).then((response) => {
      if (response.status === true) {
        setStates(response.result)
      }
    })
  }, [])

  const inputFieldsHandler = (e) => {
    const { name, value } = e.target
    if (name === 'businessTypeOther') {
      setFormErrorsStep1((prevState) => ({
        ...prevState,
        businessType: ''
      }))
    }
    if (name === 'chainAffiliationOther') {
      setFormErrorsStep1((prevState) => ({
        ...prevState,
        chainAffiliation: ''
      }))
    }
    if (formErrorsStep1[name]) {
      setFormErrorsStep1((prevState) => ({
        ...prevState,
        [name]: ''
      }))
    }
    if (name === 'reSaleTaxNumber') {
      setFormErrorsStep1((prevState) => ({
        ...prevState,
        reSaleTaxNumber: ''
      }))
    }

    if (name === 'dateCompanyStarted') {
      setFormErrorsStep1((prevState) => ({
        ...prevState,
        dateCompanyStarted: ''
      }))
    }

    if (formErrorsStep2[name]) {
      setFormErrorsStep2((prevState) => ({
        ...prevState,
        [name]: ''
      }))
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleOpenAccount = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: !e.target.checked
    }))
  }

  const handleDropship = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: !e.target.checked
    }))
  }

  const checkAlreadyExists = (name, value) => {
    const formData = {
      [name]: value
    }
    let url = 'customer/validateEmail'
    if (name === 'mobile') {
      url = 'customer/validateMobile'
    }
    showLoader(true)
    PostData(url, formData).then((response) => {
      showLoader(false)
      if (response.status === true) {
        // do something
      } else {
        setFormErrorsStep1((prevState) => ({
          ...prevState,
          [name]: response.message
        }))
      }
    })
  }

  const uploadDocument = (event) => {
    const formData = new FormData()
    setDocumentUploadedError('')
    formData.append('document', event.target.files[0])

    // check file size in mb
    const file_size = event.target.files[0].size / 1024

    if (file_size > 1024 * 5) {
      setDocumentUploadedError('File should be less than 5MB')
    } else {
      showLoader(true)
      PostWithoutToken('customer/uploadDocument', formData).then((response) => {
        showLoader(false)
        if (response.status === true) {
          setFormData((prevState) => ({
            ...prevState,
            documentFile: response.result.newFile,
            documentFileUrl: response.result.url,
            documentFileName: response.result.originalFile
          }))
          setDocumentUploaded(event.target.files[0])
        } else {
          setDocumentUploadedError(response.message)
        }
      })
    }
  }

  const handleState = (e, index) => {
    const locationsTemp = [...locations]
    const locationErrorsTemp = JSON.parse(JSON.stringify(locationsError))
    locationsTemp[index][e.target.name] = e.target.value
    locationErrorsTemp[index][e.target.name] = ''
    showLoader(true)
    GetData(
      'cities?countryId=' + countryId + '&stateId=' + e.target.value
    ).then((response) => {
      showLoader(false)
      if (response.status === true) {
        if (e.target.name === 'shippingStateId') {
          locationsTemp[index].shippingCityList = response.result
        }
        if (e.target.name === 'billingStateId') {
          locationsTemp[index].billingCityList = response.result
        }
        setLocations(locationsTemp)
        setLocationsError(locationErrorsTemp)
      }
    })
  }
  const inputHandleNew = (name, value) => {
    if (value.length < 10) {
      setFormErrorsStep1((prevState) => ({
        ...prevState,
        [name]: 'Mobile number must be 10 digits'
      }))
    } else {
      setFormErrorsStep1((prevState) => ({
        ...prevState,
        [name]: ''
      }))
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleLocations = (e, index) => {
    const locationsTemp = [...locations]
    const locationErrorsTemp = JSON.parse(JSON.stringify(locationsError))
    locationErrorsTemp[index][e.target.name] = ''
    locationsTemp[index][e.target.name] = e.target.value
    setLocations(locationsTemp)
    setLocationsError(locationErrorsTemp)
  }

  const manageCheckbox = (value, key) => {
    setFormErrorsStep1((prevState) => ({
      ...prevState,
      [key]: ''
    }))

    const formDataTemp = JSON.parse(JSON.stringify(formData))
    let fieldValues = formDataTemp[key].split(',')

    if (fieldValues.includes(value)) {
      const ind = fieldValues.indexOf(value)
      fieldValues.splice(ind, 1)
    } else {
      fieldValues = [value]
    }
    setFormData((prevState) => ({
      ...prevState,
      [key]: fieldValues.join(',')
    }))
  }

  const submitHandler = () => {
    if (step === 1) {
      let error = 0
      const errorKeys = []
      formData.dateCompanyStarted = moment(
        formData.dateCompanyStartedTemp
      ).format('YYYY-MM-DD')
      if (formData.dateCompanyStartedTemp === '') {
        error = 0
      }
      for (const k in formErrorsStep1) {
        if (formData[k] === '') {
          error = 1
          setFormErrorsStep1((prevState) => ({
            ...prevState,
            [k]: errorStrings1[k]
          }))
          errorKeys.push(k)
          window.scrollTo(0, 0)
        }

        if (k === 'email' && validator.isEmail(formData[k]) === false) {
          error = 1
          setFormErrorsStep1((prevState) => ({
            ...prevState,
            email: 'Please enter valid email'
          }))
          errorKeys.push(k)
        }
        if (k == 'email') {
          if (formErrorsStep1[k] != '') {
            error = 1
            errorKeys.push(k)
            window.scrollTo(0, 0)
          }
        }

        if (k == 'mobile') {
          if (formErrorsStep1[k] != '') {
            error = 1
            errorKeys.push(k)
            window.scrollTo(0, 0)
          }
        }
        if (
          k === 'businessType' &&
          formData[k] === 'other' &&
          formData.businessTypeOther === ''
        ) {
          error = 1
          setFormErrorsStep1((prevState) => ({
            ...prevState,
            businessType: 'Please enter other business type'
          }))
          errorKeys.push('businessTypeOther')
        }
        if (
          k === 'chainAffiliation' &&
          formData[k] === 'other' &&
          formData.chainAffiliationOther === ''
        ) {
          error = 1
          setFormErrorsStep1((prevState) => ({
            ...prevState,
            chainAffiliation: 'Please enter other chain affiliation'
          }))
          errorKeys.push('chainAffiliationOther')
        }

        if (
          (k === 'primaryEmail' || k === 'alternateEmail') &&
          formData[k] !== '' &&
          validator.isEmail(formData[k]) === false
        ) {
          error = 1
          setFormErrorsStep1((prevState) => ({
            ...prevState,
            [k]: 'Please enter valid email'
          }))
          errorKeys.push(k)
        }

        if (k === 'reSaleTaxNumber' || k === 'dateCompanyStarted') {
          if (formData.reSaleTaxNumber === '' || formData.dateCompanyStarted === '') {
            error = 0
          }
        }
      }

      if (!error) {
        setStep(2)
        window.scrollTo(0, 0)
      } else {
        const focusId = errorKeys[0]
        if (document.getElementById(focusId)) {
          document.getElementById(focusId).focus()
        }
      }
    }

    if (step === 2) {
      let error = 0
      const checkError = checkLocationError()
      if (checkError.length) {
        error = 1
        setTimeout(() => {
          if (document.getElementById(checkError[0])) {
            document.getElementById(checkError[0]).focus()
          }
        }, 400)
      } else {
        for (const k in formErrorsStep2) {
          if (formData[k] === '') {
            error = 1
            setFormErrorsStep2((prevState) => ({
              ...prevState,
              [k]: errorStrings2[k]
            }))
          }
        }
      }

      if (!error) {
        formData.noOfLocations = formData.locations.length
        formData.ownershipType = formData.ownershipType.split(',')
        formData.businessType = formData.businessType.split(',')

        showLoader(true)
        PostData('admin/customer/register', formData).then((response) => {
          if (response.status === true) {
            toastr.success(response.message)
            setTimeout(() => {
              history.push('/customer')
            }, 1000)
          } else {
            showLoader(false)
            toastr.error(response.message, 'Error')
          }
        })
      }
    }
  }

  const checkLocationError = () => {
    const locationsTemp = JSON.parse(JSON.stringify(locations))
    const locationsErrorTemp = JSON.parse(JSON.stringify(locationsError))
    let error = 0
    const errorTitles = []
    const errorsIndexFocus = []
    const errorsIndex = []
    locationsTemp.map((location, index) => {
      for (const k in locationsTemp[index]) {
        if (locationsTemp[index][k] === '') {
          error = 1
          locationsErrorTemp[index][k] = locationErrorStrings[k]
          errorTitles.push(k)
        }

        if (
          (k === 'contactEmail' || k === 'alternateEmail') &&
          locationsTemp[index][k] !== '' &&
          validator.isEmail(locationsTemp[index][k]) === false
        ) {
          error = 1
          errorTitles.push(k)
          locationsErrorTemp[index][k] = 'Please enter valid email'
        }
      }
      setLocationsError(locationsErrorTemp)

      const formDataTemp = JSON.parse(JSON.stringify(formData))
      formDataTemp.locations = locations

      if (!error) {
        locationsTemp[index].open = false
        locationsTemp[index].completed = true
        setLocations(locationsTemp)
        setFormData(formDataTemp)
      } else {
        errorsIndexFocus.push(errorTitles[0] + '-' + index)
        errorsIndex.push(index)
      }
    })
    if (errorsIndex.length) {
      locationsTemp[errorsIndex[0]].open = true
      locationsTemp[errorsIndex[0]].completed = false
      setLocations(locationsTemp)
    }
    return errorsIndexFocus
  }

  const addLocation = (index, e) => {
    e.preventDefault()
    const locationsTemp = JSON.parse(JSON.stringify(locations))
    const locationsErrorTemp = JSON.parse(JSON.stringify(locationsError))
    let error = 0
    const errorTitles = []
    for (const k in locationsTemp[index]) {
      if (locationsTemp[index][k] === '') {
        error = 1
        locationsErrorTemp[index][k] = locationErrorStrings[k]
        errorTitles.push(k)
      }

      if (
        (k === 'contactEmail' || k === 'alternateEmail') &&
        locationsTemp[index][k] !== '' &&
        validator.isEmail(locationsTemp[index][k]) === false
      ) {
        error = 1
        errorTitles.push(k)
        locationsErrorTemp[index][k] = 'Please enter valid email'
      }
    }
    setLocationsError(locationsErrorTemp)

    const formDataTemp = { ...formData }
    formDataTemp.locations = locations

    if (!error) {
      locationsTemp[index].open = false
      locationsTemp[index].completed = true
      document.getElementById(`location-${index}`).style.color = '#0069a7'
      setLocations(locationsTemp)
    } else {
      document.getElementById(errorTitles[0] + '-' + index).focus()
    }
    setFormData(formDataTemp)
    if (locations[index].completed == true) {
      setExisting(false)
      setBilling(false)
    }
  }

  const addMoreLocation = (e) => {
    e.preventDefault()
    const locationsTemp = [...locations]
    const locationsErrorTemp = JSON.parse(JSON.stringify(locationsError))
    locationsErrorTemp.push(locationObj)
    setLocationsError(locationsErrorTemp)

    locationObj.isPrimaryLocation = 0
    locationObj.shippingCityList = []
    locationObj.billingCityList = []

    locationsTemp.push(locationObj)
    const locationsTempObj = []
    locationsTemp.map((l) => {
      l.open = false
      locationsTempObj.push(l)
      return null
    })
    locationsTempObj[locationsTempObj.length - 1].open = true
    setLocations(locationsTempObj)
    if (locations.at(-1).completed == false) {
      setBilling(true)
      setExisting(true)
      setDropDown(false)
    } else {
      setExisting(false)
      setBilling(false)
      setDropDown(false)
    }
  }

  const manageAccordion = (e, type, index) => {
    e.preventDefault()
    const locationsTemp = [...locations]
    const locationsErrorTemp = JSON.parse(JSON.stringify(locationsError))
    if (type === 'delete') {
      if (locationsTemp[index].isPrimaryLocation) {
        locationsTemp[0].isPrimaryLocation = 1
      }
      locationsTemp.splice(index, 1)
      locationsErrorTemp.splice(index, 1)
      setLocationsError(locationsErrorTemp)
      setLocations(locationsTemp)
    } else {
      const locationsTempObj = []
      locationsTemp.map((l) => {
        l.open = false
        locationsTempObj.push(l)
        return null
      })
      if (type === 'open') {
        locationsTempObj[index].open = true
      }
      setLocations(locationsTempObj)
    }
  }

  const setSameBillingAddress = (e, key) => {
    if (e.target.checked) {
      setSelectBilling(false)
      const locationsTemp = [...locations]
      const locationsErrorTemp = JSON.parse(JSON.stringify(locationsError))

      const sameAddressArray = [...sameAddress]
      sameAddressArray[key] = true
      setSameAddress(sameAddressArray)

      if (locationsTemp[key].shippingStateId) {
        locationsTemp[key].billingStateId =
          locationsTemp[key].shippingStateId
        locationsErrorTemp[key].billingStateId = ''
      }
      if (locationsTemp[key].shippingCityId) {
        locationsTemp[key].billingCityId =
          locationsTemp[key].shippingCityId
        locationsErrorTemp[key].billingCityId = ''
      }

      if (locationsTemp[key].shippingStreet) {
        locationsTemp[key].billingStreet =
          locationsTemp[key].shippingStreet
        locationsErrorTemp[key].billingStreet = ''
      }

      if (locationsTemp[key].shippingCityList) {
        locationsTemp[key].billingCityList =
          locationsTemp[key].shippingCityList
        locationsErrorTemp[key].billingCityList = ''
      }
      if (locationsTemp[key].shippingZipCode) {
        locationsTemp[key].billingZipCode =
          locationsTemp[key].shippingZipCode
        locationsErrorTemp[key].billingZipCode = ''
      }

      setLocations(locationsTemp)
      setLocationsError(locationsErrorTemp)
    } else {
      const sameAddressArray = [...sameAddress]
      sameAddressArray[key] = false
      setSameAddress(sameAddressArray)
    }
  }

  const setPrimaryLocation = (e, index) => {
    const locationsTemp = [...locations]
    if (!e.target.checked) {
      const locationData = []
      locationsTemp.map((location) => {
        location.isPrimaryLocation = 0
        locationData.push(location)
        return null
      })
      locationData[index].isPrimaryLocation = 1
      setLocations(locationData)
    } else {
      // locationsTemp[index].isPrimaryLocation = 0
      // setLocations(locationsTemp)
    }
  }
  const handleSelect = (e) => {
    e.preventDefault()
    setBilling(true)
    setDropDown(false)
    setSelectBilling(true)
    document.getElementById('input-selected').value = '+Add New Address'
  }

  const handleUpdateDetails = (e, i, j) => {
    e.preventDefault()
    setDropDown(false)
    setExisting(true)
    setBilling(true)

    // set billing address
    const locationsArr = [...locations]
    const locationsErrorArr = [...locationsError]

    locationsArr[j].billingStreet = locationsArr[i].billingStreet
    locationsArr[j].billingStateId = locationsArr[i].billingStateId
    locationsArr[j].billingCityId = locationsArr[i].billingCityId
    locationsArr[j].billingCityList = locationsArr[i].billingCityList
    locationsArr[j].billingZipCode = locationsArr[i].billingZipCode

    locationsErrorArr[j] = locationObj

    setLocations(locationsArr)
    setLocationsError(locationsErrorArr)
  }

  const handleLocationsInput = (name, value, index) => {
    const locationsTemp = [...locations]
    const locationErrorsTemp = JSON.parse(JSON.stringify(locationsError))
    locationErrorsTemp[index][name] = ''
    if (name === 'contactPhone' && value.length > 10) {
      return
    } else if (name === 'contactPhone' && value.length < 10) {
      locationErrorsTemp[index][name] = 'Must be 10 digits'
    }
    locationsTemp[index][name] = value
    locationsTemp[index][name] = value

    setLocations(locationsTemp)
    setLocationsError(locationErrorsTemp)
  }

  const setContactInformation = (e, index) => {
    const locationsTemp = [...locations]
    const locationErrorsTemp = JSON.parse(JSON.stringify(locationsError))
    if (e.target.checked) {
      locationsTemp[index].contactFirstName = formData.primaryFirstName
      locationErrorsTemp[index].contactFirstName = ''
      locationsTemp[index].contactLastName = formData.primaryLastName
      locationErrorsTemp[index].contactLastName = ''
      locationsTemp[index].contactEmail = formData.primaryEmail
      locationErrorsTemp[index].contactEmail = ''
      locationsTemp[index].contactPhone = formData.primaryPhone
      locationErrorsTemp[index].contactPhone = ''
      locationsTemp[index].contactTitle = formData.primaryTitle
      locationErrorsTemp[index].contactTitle = ''
    } else {
      locationsTemp[index].contactFirstName = ''
      locationsTemp[index].contactLastName = ''
      locationsTemp[index].contactEmail = ''
      locationsTemp[index].contactPhone = ''
      locationsTemp[index].contactTitle = ''
    }
    setLocations(locationsTemp)
    setLocationsError(locationErrorsTemp)
  }

  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <div className="d-flex align-items-center">
          <i
            onClick={() => history.push('/customer')}
            style={{ cursor: 'pointer' }}
            className="dripicons-arrow-thin-left me-3"
          ></i>
          <Breadcrumbs
            title="UI Elements"
            breadcrumbItem="New Customer Registration"
          />
        </div>
        <Row className="mt-3">
          <Col lg={12}>
            <Card>
              <CardBody className="new-registration-container">
                {step === 1 ? (
                  <div>
                    <div className="d-flex justify-content-between align-items-center wd-business-nav">
                      <h2 className="new-registration-heading-blue">
                        Business Information
                      </h2>
                      <span>* All marked fields are mandatory</span>
                    </div>
                    <Row>
                      <Col lg={6}>
                        <div
                          className="label input-label phn-email"
                          onClick={(e) => {
                            e.preventDefault()
                            setShow(true)
                          }}
                        >
                          Company Name*
                        </div>
                        <input
                          type="text"
                          id="companyName"
                          name="companyName"
                          value={formData.companyName}
                          onChange={(e) => inputFieldsHandler(e)}
                          className=" form-control"
                          placeholder="Enter company name"
                        />
                        <div className="error">
                          {formErrorsStep1.companyName}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="label input-label phn-email">
                          Email Address*
                        </div>
                        <input
                          type="text"
                          id="email"
                          name="email"
                          onBlur={(e) => checkAlreadyExists('email', e.target.value)}
                          value={formData.email}
                          onChange={(e) => inputFieldsHandler(e)}
                          className=" form-control"
                          placeholder="Enter email address"
                        />
                        <div className="error">{formErrorsStep1.email}</div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg={4}>
                        <div className="label input-label phn-email">
                          Mobile Number*
                        </div>
                        <NumberFormat
                        name="mobile" className=" form-control"
                        defaultValue={formData.mobile}
                        placeholder="Enter mobile number" format="(###) ###-####"
                        onValueChange={(val) => {
                          inputHandleNew('mobile', val.value)
                          if (val.value.length === 10) {
                            checkAlreadyExists('mobile', val.value)
                            if (isNaN(val.value)) {
                              return
                            }
                            if (val.value.length > 10) {
                              return
                            }
                          }
                        }}
                        />
                        {/* <input
                          type="text"
                          id="mobile"
                          name="mobile"
                          onBlur={(e) => checkAlreadyExists(e)}
                          value={formData.mobile}
                          onChange={(e) => {
                            if (isNaN(e.target.value)) {
                              return
                            }
                            if (e.target.value.length > 10) {
                              return
                            }
                            inputFieldsHandler(e)
                          }}
                          className=" form-control"
                          placeholder="Enter mobile number"
                        /> */}
                        <div className="error">{formErrorsStep1.mobile}</div>
                      </Col>
                      <Col lg={4}>
                        <div className="label input-label phn-email">
                          Chain Affiliation*
                        </div>
                        <select
                          id="chainAffiliation"
                          name="chainAffiliation"
                          value={formData.chainAffiliation}
                          onChange={(e) => inputFieldsHandler(e)}
                          className="register-input pointer"
                        >
                          <option value="">Select</option>
                          {Object.keys(chainAffiliation).map((ch, c) => {
                            return (
                              <option key={c} value={ch}>
                                {chainAffiliation[ch]}
                              </option>
                            )
                          })}
                        </select>
                        {formData.chainAffiliation === 'other'
                      ? (
                      <div className="other--input--row">
                        <input
                          type="text"
                          id="chainAffiliationOther"
                          name="chainAffiliationOther"
                          value={formData.chainAffiliationOther}
                          onChange={(e) => inputFieldsHandler(e)}
                          className="form-control mt-4"
                          placeholder="Please Specify"
                        />
                      </div>
                        )
                      : (
                      <div className="other--input--row"></div>
                        )}
                        <div className="error">
                          {formErrorsStep1.chainAffiliation}
                        </div>
                      </Col>
                      <Col lg={4} >
                        <div className="label input-label phn-email">
                          Date Company Started
                        </div>
                        <div style={{ position: 'relative' }}>
                        <DatePicker
                          selected={formData.dateCompanyStartedTemp}
                          onChange={(date) =>
                            inputFieldsHandler({
                              target: {
                                value: date,
                                name: 'dateCompanyStartedTemp'
                              }
                            })
                          }
                          className="form-control"
                          placeholderText="MM/DD/YYYY"
                          dateFormat={'MM/dd/yyyy'}
                          maxDate={new Date()}
                          showYearDropdown
                          showMonthDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                        />
                        <img src={calender} className="calender-img" />
                        </div>
                        <div className="error">
                          {formErrorsStep1.dateCompanyStartedTemp}
                        </div>
                      </Col>
                    </Row>
                    <div className="checkbox--box">
                      <div>
                        <div className="checkbox--title">
                          Ownership Type*
                          {/* <span className="info-icon">
                            <img src={info} className="info-icon cursor--p" alt="Info" />
                            <span className="tooltiptextt">
                              Lorem Ipsum is simply dummy text of the printing
                              and type setting industry.Lorem Ipsum
                            </span>
                          </span> */}
                        </div>
                        {/* <div className="checkbox--title">
                          Ownership Type*
                          <span className="info-icon">
                            <img src={info}
                            className="info-icon"
                            alt="Info"
                            />

                            <span className="tooltiptext">
                              Lorem Ipsum is simply dummy text of the printing
                              and type setting industry.Lorem Ipsum
                            </span>
                          </span>
                        </div> */}
                        {Object.keys(ownershipType).map((ot, key) => {
                          return (
                            <div key={key} className="check__box__gap">
                              <div className="flex-checkbox">
                                <input
                                  name={ot}
                                  onClick={() =>
                                    manageCheckbox(ot, 'ownershipType')
                                  }
                                  id={'type-' + key}
                                  type="checkbox"
                                  checked={
                                    !!formData.ownershipType
                                      .split(',')
                                      .includes(ot)
                                  }
                                  className="check---box"
                                  style={{ cursor: 'pointer' }}
                                />
                                <label
                                  htmlFor={'type-' + key}
                                  className="checkbox-name check-box-main"
                                  style={{ cursor: 'pointer' }}
                                >
                                  {ownershipType[ot]}
                                </label>
                              </div>
                            </div>
                          )
                        })}

                        <div className="error">
                          {formErrorsStep1.ownershipType}
                        </div>
                      </div>
                      <div style={{ width: '200px' }}>
                        <div className="checkbox--title">
                          Type of Business*
                          {/* <span className="info-icon">
                            <img src={info} className="info-icon cursor--p" alt="Info" />
                            <span className="tooltiptextt">
                              Lorem Ipsum is simply dummy text of the printing
                              and type setting industry.Lorem Ipsum
                            </span>
                          </span> */}
                        </div>
                        {Object.keys(businessType).map((bt, key) => {
                          return (
                            <div key={key} className="check__box__gap">
                              <div className="flex-checkbox">
                                <input
                                  name={bt}
                                  onClick={() =>
                                    manageCheckbox(bt, 'businessType')
                                  }
                                  id={'business-' + key}
                                  checked={
                                    !!formData.businessType
                                      .split(',')
                                      .includes(bt)
                                  }
                                  type="checkbox"
                                  className="check---box"
                                  style={{ cursor: 'pointer' }}
                                />

                                <label
                                  htmlFor={'business-' + key}
                                  className="checkbox-name check-box-main"
                                  style={{ cursor: 'pointer' }}
                                >
                                  {businessType[bt]}
                                </label>
                              </div>
                            </div>
                          )
                        })}
                        {formData.businessType === 'other'
                          ? (
                          <div className="other--input--row">
                            <input
                              type="text"
                              id="businessTypeOther"
                              name="businessTypeOther"
                              value={formData.businessTypeOther}
                              onChange={(e) => inputFieldsHandler(e)}
                              className=" form-control"
                              placeholder="Please Specify"
                            />
                          </div>
                            )
                          : (
                          <div className="other--input--row"></div>
                            )}

                        <div className="error">
                          {formErrorsStep1.businessType}
                        </div>
                      </div>
                    </div>
                    <h2 className="new-registration-heading-blue">
                      Tax Information
                    </h2>
                    <Row className="mobile-resale-div">
                      <Col lg={5}>
                        <div className="resale--div">
                          Resale Tax Number
                          {/* <span className="info-icon">
                            <img src={info} className="info-icon cursor--p" alt="Info" />
                            <span className="tooltiptextt">
                              Lorem Ipsum is simply dummy text of the printing
                              and type setting industry.Lorem Ipsum
                            </span>
                          </span> */}
                        </div>
                        <input
                          type="text"
                          id="reSaleTaxNumber"
                          name="reSaleTaxNumber"
                          value={formData.reSaleTaxNumber}
                          onChange={(e) => inputFieldsHandler(e)}
                          className=" form-control"
                          placeholder="Enter resale tax number"
                        />
                        <div className="error">
                          {formErrorsStep1.reSaleTaxNumber}
                        </div>
                      </Col>
                      <Col lg={6} className="upload-mobile-div">
                        <div className="resale--div">
                          Upload Document{' '}
                          <span className="optional">(Optional)</span>
                          {/* <span className="info-icon">
                            <img src={info} className="info-icon cursor--p" alt="Info" />
                            <span className="tooltiptextt">
                              Lorem Ipsum is simply dummy text of the printing
                              and type setting industry.Lorem Ipsum
                            </span>
                          </span> */}
                        </div>
                        <input
                          type="file"
                          accept=".pdf"
                          name="documentUpload"
                          value={documentUploaded}
                          onChange={(e) => uploadDocument(e)}
                          ref={documentUploadRef}
                          style={{ display: 'none' }}
                        />
                        {formData.documentFile
                          ? (
                          <div className="pdf-upload phn-email">
                            <button
                              onClick={() => documentUploadRef.current.click()}
                              className="choose--btn"
                            >
                              <i className="bx bx-cloud-upload"></i> Update File
                            </button>
                            <div
                              style={{
                                marginLeft: '10px',
                                position: 'relative'
                              }}
                              className="pdf-row"
                            >
                              <img
                                src={'/cross.svg'}
                                onClick={() => {
                                  setDocumentUploaded('')
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    documentFile: false,
                                    documentFileUrl: ''
                                  }))
                                }}
                                alt="close"
                                style={{
                                  position: 'absolute',
                                  right: '-7px',
                                  top: '-7px',
                                  cursor: 'pointer'
                                }}
                              />
                              <a
                                href={formData.documentFileUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src="/pdf2.svg" alt="pdf" />
                              </a>
                            </div>
                            <span className="pdf-input">
                              {formData.documentFileName}
                            </span>
                          </div>
                            )
                          : (
                          <button
                            onClick={() => documentUploadRef.current.click()}
                            className="choose--btn"
                          >
                            <i className="bx bx-cloud-upload"></i> Choose File
                          </button>
                            )}
                        <div className="error">{documentUploadedError}</div>
                      </Col>
                    </Row>
                    <h2 className="new-registration-heading-blue mt-4">
                      Contact Information
                    </h2>
                    <Row>
                      <Col lg={6}>
                        <div className="owner-primary">Owner / Primary </div>
                        <Row>
                          <Col lg={6}>
                            <div className="number--location">First Name*</div>
                            <input
                              type="text"
                              id="primaryFirstName"
                              name="primaryFirstName"
                              value={formData.primaryFirstName}
                              onChange={(e) => inputFieldsHandler(e)}
                              className=" form-control"
                            />
                            <div className="error">
                              {formErrorsStep1.primaryFirstName}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="number--location phn-email">
                              Last Name*
                            </div>
                            <input
                              type="text"
                              id="primaryLastName"
                              name="primaryLastName"
                              value={formData.primaryLastName}
                              onChange={(e) => inputFieldsHandler(e)}
                              className=" form-control"
                            />
                            <div className="error">
                              {formErrorsStep1.primaryLastName}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col>
                            <div className="number--location phn-email">
                              Email Address*
                            </div>
                            <input
                              type="email"
                              id="primaryEmail"
                              name="primaryEmail"
                              value={formData.primaryEmail}
                              onChange={(e) => inputFieldsHandler(e)}
                              className="form-control"
                            />
                            <div className="error">
                              {formErrorsStep1.primaryEmail}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col>
                            <div className="number--location phn-email">
                              Phone Number*
                            </div>
                            <NumberFormat
                            name="primaryPhone" className="form-control"
                            // id="primaryPhone"
                            defaultValue={formData.primaryPhone}
                             format="(###) ###-####"
                            onValueChange={(val) => {
                              inputHandleNew('primaryPhone', val.value)
                              if (isNaN(val.value)) {
                                return
                              }
                              if (val.value.length > 10) {
                                return
                              }
                            }}
                            />
                            {/* <input
                              type="text"
                              name="primaryPhone"
                              id="primaryPhone"
                              value={formData.primaryPhone}
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return
                                }
                                if (e.target.value.length > 10) {
                                  return
                                }
                                inputFieldsHandler(e)
                              }}
                              className="form-control"
                            /> */}
                            <div className="error">
                              {formErrorsStep1.primaryPhone}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col>
                            <div className="number--location phn-email">
                              Title*
                            </div>
                            <input
                              type="text"
                              id="primaryTitle"
                              name="primaryTitle"
                              value={formData.primaryTitle}
                              onChange={(e) => inputFieldsHandler(e)}
                              className="form-control"
                            />
                            <div className="error">
                              {formErrorsStep1.primaryTitle}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col lg={6}>
                        <div className="owner-primary alternate-heading">
                          Alternate
                        </div>
                        <Row>
                          <Col lg={6}>
                            <div className="number--location">First Name</div>
                            <input
                              type="text"
                              id="alternateFirstName"
                              name="alternateFirstName"
                              value={formData.alternateFirstName}
                              onChange={(e) => inputFieldsHandler(e)}
                              className="form-control"
                            />
                          </Col>
                          <Col lg={6}>
                            <div className="number--location phn-email">
                              Last Name
                            </div>
                            <input
                              type="text"
                              id="alternateLastName"
                              name="alternateLastName"
                              value={formData.alternateLastName}
                              onChange={(e) => inputFieldsHandler(e)}
                              className="form-control"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col>
                            <div className="number--location phn-email">
                              Email Address
                            </div>
                            <input
                              type="text"
                              id="alternateEmail"
                              name="alternateEmail"
                              value={formData.alternateEmail}
                              onChange={(e) => inputFieldsHandler(e)}
                              className="form-control"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col>
                            <div className="number--location phn-email">
                              Phone Number
                            </div>
                            <NumberFormat
                            defaultValue={formData.alternatePhone} name="alternatePhone" className="form-control"
                            format="(###) ###-####"
                            onValueChange={(val) => {
                              inputHandleNew('alternatePhone', val.value)
                              if (isNaN(val.value)) {
                                return
                              }
                              if (val.value.length > 10) {
                                return
                              }
                            }}
                            />

                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col>
                            <div className="number--location phn-email">
                              Title
                            </div>
                            <input
                              type="text"
                              id="alternateTitle"
                              name="alternateTitle"
                              value={formData.alternateTitle}
                              onChange={(e) => inputFieldsHandler(e)}
                              className="form-control"
                            />

                          </Col>
                        </Row>
                      </Col> */}
                    </Row>
                    <Row>
                    <Col lg={12}>
                    <div className="mt-3 mb-3 d-flex">
                    <input
                              type="checkbox"
                              id="open-account"
                              name="isOpenAccount"
                              onClick={(e) => handleOpenAccount(e)}
                              checked={Boolean(formData.isOpenAccount)}
                            />
                            <label htmlFor="open-account" style={{ marginLeft: '5px', marginBottom: '0px' }}>
                              Is Open Account Customer
                            </label>
                          </div>
                    </Col>
                  </Row>
                  {
                    formData.isOpenAccount && <Row>
                    <Col lg={6}>
                    <div className="number--location phn-email">
                              Billing Cycle Duration*
                            </div>
                    <select
                          id="billingCycleDuration"
                          name="billingCycleDuration"
                          value={formData.billingCycleDuration}
                          onChange={(e) => inputFieldsHandler(e)}
                          className="register-input pointer"
                        >
                          {billingOptions.map((ch, c) => {
                            return (
                              <option key={c} value={ch.value}>
                                {ch.label}
                              </option>
                            )
                          })}
                        </select>
                    </Col>
                  </Row>
                  }
                  <Row>
                    <Col lg={12}>
                    <div className="mt-3 d-flex">
                    <input
                              type="checkbox"
                              id="allow_dropship"
                              name="allow_dropship"
                              onClick={(e) => handleDropship(e)}
                              checked={Boolean(formData?.allow_dropship)}
                            />
                            <label htmlFor="allow_dropship" style={{ marginLeft: '5px', marginBottom: '0px' }}>
                              Allow Dropship
                            </label>
                          </div>
                    </Col>
                  </Row>
                    <div className="continue--div">
                      <button
                        className="continue--btn form-continue-btn"
                        onClick={() => submitHandler()}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    {locations.map((location, key) => {
                      return (
                        <React.Fragment key={key}>
                          <form onSubmit={(e) => addLocation(key, e)}>
                            <div className="location--row">
                              <div
                                className="location newlocation"
                                style={{ display: 'flex' }}
                              >
                                <div id={`location-${key}`}>
                                  Location - {key + 1}
                                </div>{' '}
                                {location.completed
                                  ? (
                                  <div style={{ marginLeft: '18px' }}>
                                    {' '}
                                    <i className="bx bx-comment-check"></i>
                                  </div>
                                    )
                                  : (
                                      ''
                                    )}{' '}
                                {location.isPrimaryLocation
                                  ? (
                                  <span className="first-location">
                                    Primary Location
                                  </span>
                                    )
                                  : (
                                      ''
                                    )}{' '}
                              </div>
                              {location.open
                                ? (
                                <div>
                                  {key !== 0
                                    ? (
                                    <a
                                      href="/#"
                                      className="delete--icon"
                                      onClick={(e) =>
                                        manageAccordion(e, 'delete', key)
                                      }
                                    >
                                      <img src={deleteLogo} alt="trash" />
                                    </a>
                                      )
                                    : (
                                        ''
                                      )}
                                  <span
                                    href="/#"
                                    onClick={(e) =>
                                      manageAccordion(e, 'close', key)
                                    }
                                    className="hover-plus-icon"
                                  >
                                    <i className="fa fa-minus"></i>
                                  </span>
                                </div>
                                  )
                                : (
                                <div>
                                  {key !== 0
                                    ? (
                                    <a
                                      href="/#"
                                      className="delete--icon hover-del-icon"
                                      onClick={(e) =>
                                        manageAccordion(e, 'delete', key)
                                      }
                                    >
                                      <img src={deleteLogo} alt="Trash" />
                                    </a>
                                      )
                                    : (
                                        ''
                                      )}
                                  <span
                                    href="/#"
                                    onClick={(e) =>
                                      manageAccordion(e, 'open', key)
                                    }
                                    className="hover-plus-icon"
                                  >
                                    <i className="fa fa-plus"></i>
                                  </span>
                                </div>
                                  )}
                            </div>
                            {location.open ? (
                              <div>
                                <Row>
                                  <Col>
                                    <div className="number--location">
                                      Location Name / Title*
                                      <span className="info-icon">
                                        <img
                                          src={info}
                                          className="info-icon cursor--p"
                                          alt="Info"
                                        />
                                        <span className="tooltiptextt">
                                          Lorem Ipsum is simply dummy text of
                                          the printing and type setting
                                          industry.Lorem Ipsum
                                        </span>
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      id={'locationName-' + key}
                                      name="locationName"
                                      onChange={(e) => handleLocations(e, key)}
                                      value={location.locationName}
                                      className="form-control"
                                    />
                                    <div className="error">
                                      {locationsError[key].locationName}
                                    </div>
                                  </Col>
                                </Row>
                                <div
                                  id="checkbox--flex"
                                  className="new-same-address"
                                >
                                  <div className="flex-checkbox">
                                    <input
                                      onChange={(e) =>
                                        setSameBillingAddress(e, key)
                                      }
                                      type="checkbox"
                                      id={'billing-' + key}
                                    />
                                    <label
                                      className="checkbox-name check-box-main"
                                      htmlFor={'billing-' + key}
                                    >
                                      Same as Location Address
                                    </label>
                                  </div>
                                </div>
                                <Row>
                                  <Col lg={6}>
                                    <div className="shipping-div mt-2">
                                      Location Address
                                    </div>
                                    <Row>
                                      <Col className="mt-2">
                                        <div className="number--location">
                                          Street*
                                        </div>
                                        <input
                                          type="text"
                                          id={'shippingStreet-' + key}
                                          name="shippingStreet"
                                          onChange={(e) =>
                                            handleLocations(e, key)
                                          }
                                          value={location.shippingStreet}
                                          className="form-control"
                                        />
                                        <div className="error">
                                          {locationsError[key].shippingStreet}
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="mt-2">
                                        <div className="number--location">
                                        State/Province*
                                        </div>
                                        <select
                                          id={'shippingStateId-' + key}
                                          name="shippingStateId"
                                          onChange={(e) => handleState(e, key)}
                                          value={location.shippingStateId}
                                          className="register-input"
                                        >
                                          <option value="">Select State</option>
                                          {states.map((st, skey) => {
                                            return (
                                              <option key={skey} value={st.id}>
                                                {st.name}
                                              </option>
                                            )
                                          })}
                                        </select>
                                        <div className="error">
                                          {locationsError[key].shippingStateId}
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-2">
                                      <Col lg={6}>
                                        <div className="number--location">
                                          City*
                                        </div>
                                        {/* <input type='text' className='company_input city--input'/> */}
                                        <select
                                          // style={{width:'130px'}}
                                          id={'shippingCityId-' + key}
                                          name="shippingCityId"
                                          onChange={(e) => {
                                            const locationErrorsTemp = JSON.parse(
                                              JSON.stringify(locationsError)
                                            )
                                            const locationsTemp = [...locations]
                                            locationsTemp[key].shippingCityId = e.target.value
                                            locationErrorsTemp[key].shippingCityId = ''
                                            setLocations(locationsTemp)
                                            setLocationsError(
                                              locationErrorsTemp
                                            )
                                          }}
                                          value={location.shippingCityId}
                                          className="register-input"
                                        >
                                          <option value="">Select City</option>
                                          {location.shippingCityList.map(
                                            (ct, ckey) => {
                                              return (
                                                <option
                                                  key={ckey}
                                                  value={ct.id}
                                                >
                                                  {ct.name}
                                                </option>
                                              )
                                            }
                                          )}
                                        </select>
                                        <div className="error">
                                          {locationsError[key].shippingCityId}
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className="number--location">
                                        Zip Code/Postal Code*
                                        </div>
                                        <input
                                          type="text"
                                          id={'shippingZipCode-' + key}
                                          name="shippingZipCode"
                                          onChange={(e) => {
                                            if (isNaN(e.target.value)) {
                                              return
                                            }
                                            handleLocations(e, key)
                                          }}
                                          value={location.shippingZipCode}
                                          className="form-control"
                                        />
                                        <div className="error">
                                          {locationsError[key].shippingZipCode}
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className="set-primart-location mt-2">
                                      <input
                                        id={'primary-' + key}
                                        onClick={(e) =>
                                          setPrimaryLocation(e, key)
                                        }
                                        checked={
                                          !!location.isPrimaryLocation
                                        }
                                        type="checkbox"
                                        className="check"
                                        style={{ cursor: 'pointer' }}
                                      />
                                      <label
                                        htmlFor={'primary-' + key}
                                        className="set-primary mb-0"
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <span className="info-icon">
                                          Set as Primary Location
                                          {locations.length == 1 && (
                                            <span className="tooltiptextt">
                                              To change primary location please
                                              add more than 1 locations
                                            </span>
                                          )}
                                        </span>
                                      </label>
                                    </div>
                                  </Col>

                                  <Col lg={6}>
                                    <div
                                      className="shipping-div billing-address-check align-items-center mt-2"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                      }}
                                    >
                                      <div className="billing---div">
                                        Billing Address
                                      </div>
                                      <div
                                        id="checkbox--flex"
                                        className="same-ship-address"
                                      >
                                        <div className="flex-checkbox">
                                          <input
                                            onClick={(e) => {
                                              const val = !e.target.checked
                                              const obj = {
                                                target: {
                                                  checked: val
                                                }
                                              }
                                              setSameBillingAddress(obj, key)
                                            }}
                                            type="checkbox"
                                            id={'billing-shipping' + key}
                                            checked={
                                              !!sameAddress[key]
                                            }
                                          />
                                          <label
                                            className="checkbox-name check-box-main"
                                            htmlFor={'billing-shipping' + key}
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Same as Shipping Address
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    {location.isPrimaryLocation ? (
                                      <>
                                        <Row>
                                          <Col className="mt-2">
                                            <div className="number--location">
                                              Street*
                                            </div>
                                            <input
                                              type="text"
                                              id={'billingStreet-' + key}
                                              name="billingStreet"
                                              onChange={(e) =>
                                                handleLocations(e, key)
                                              }
                                              value={
                                                sameAddress[key]
                                                  ? location.shippingStreet
                                                  : location.billingStreet
                                              }
                                              className="form-control"
                                              readOnly={sameAddress[key]}
                                            />
                                            <div className="error">
                                              {
                                                locationsError[key]
                                                  .billingStreet
                                              }
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col className="mt-2">
                                            <div className="number--location">
                                            State/Province*
                                            </div>
                                            <select
                                              id={'billingStateId-' + key}
                                              name="billingStateId"
                                              onChange={(e) =>
                                                handleState(e, key)
                                              }
                                              value={
                                                sameAddress[key]
                                                  ? location.shippingStateId
                                                  : location.billingStateId
                                              }
                                              className="register-input"
                                              disabled={sameAddress[key]}
                                            >
                                              <option value="">
                                                Select State
                                              </option>
                                              {states.map((st, skey) => {
                                                return (
                                                  <option
                                                    key={skey}
                                                    value={st.id}
                                                  >
                                                    {st.name}
                                                  </option>
                                                )
                                              })}
                                            </select>
                                            <div className="error">
                                              {
                                                locationsError[key]
                                                  .billingStateId
                                              }
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row className="mt-2">
                                          <Col lg={6}>
                                            <div className="number--location">
                                              City*
                                            </div>
                                            <select
                                              id={'billingCityId-' + key}
                                              name="billingCityId"
                                              onChange={(e) => {
                                                const locationErrorsTemp =
                                                  JSON.parse(
                                                    JSON.stringify(
                                                      locationsError
                                                    )
                                                  )
                                                const locationsTemp = [
                                                  ...locations
                                                ]
                                                locationErrorsTemp[key].billingCityId = ''
                                                locationsTemp[key].billingCityId = e.target.value
                                                setLocations(locationsTemp)
                                                setLocationsError(
                                                  locationErrorsTemp
                                                )
                                              }}
                                              value={
                                                sameAddress[key]
                                                  ? location.shippingCityId
                                                  : location.billingCityId
                                              }
                                              className="register-input"
                                              disabled={sameAddress[key]}
                                            >
                                              <option value="">
                                                Select City
                                              </option>
                                              {sameAddress[key]
                                                ? (
                                                <>
                                                  {location.shippingCityList.map(
                                                    (ct, ckey) => {
                                                      return (
                                                        <option
                                                          key={ckey}
                                                          value={ct.id}
                                                        >
                                                          {ct.name}
                                                        </option>
                                                      )
                                                    }
                                                  )}
                                                </>
                                                  )
                                                : (
                                                <>
                                                  {location?.billingCityList
                                                    .length > 0 &&
                                                    location?.billingCityList.map(
                                                      (ct, ckey) => {
                                                        return (
                                                          <option
                                                            key={ckey}
                                                            value={ct.id}
                                                          >
                                                            {ct.name}
                                                          </option>
                                                        )
                                                      }
                                                    )}
                                                </>
                                                  )}
                                            </select>
                                            <div className="error">
                                              {
                                                locationsError[key]
                                                  .billingCityId
                                              }
                                            </div>
                                          </Col>
                                          <Col lg={6}>
                                            <div className="number--location">
                                            Zip Code/Postal Code*
                                            </div>
                                            <input
                                              id={'billingZipCode-' + key}
                                              name="billingZipCode"
                                              onChange={(e) => {
                                                if (isNaN(e.target.value)) {
                                                  return
                                                }
                                                handleLocations(e, key)
                                              }}
                                              value={
                                                sameAddress[key]
                                                  ? location.shippingZipCode
                                                  : location.billingZipCode
                                              }
                                              type="text"
                                              className="form-control"
                                              readOnly={sameAddress[key]}
                                            />
                                            <div className="error">
                                              {
                                                locationsError[key]
                                                  .billingZipCode
                                              }
                                            </div>
                                          </Col>
                                        </Row>
                                      </>
                                    ) : (
                                      <>
                                        <div className="main-state">
                                          {existing ? null : (
                                            <>
                                              {' '}
                                              <div className="number--location">
                                                Select From List*
                                              </div>
                                              <div className="input-select-container">
                                                <input
                                                  type="text"
                                                  id="input-selected"
                                                  className="input-select form-control"
                                                  Value="Select"
                                                  style={{
                                                    borderRadiur: '2px',
                                                    cursor: 'pointer'
                                                  }}
                                                  readOnly
                                                  onClick={(e) => {
                                                    e.preventDefault()
                                                    setDropDown(true)
                                                  }}
                                                ></input>
                                                {/* <GoTriangleDown className="arrow-icon" /> */}
                                                {dropDown && (
                                                  <div className="dropdown--list">
                                                    <div
                                                      className="add-new-address"
                                                      onClick={(e) => {
                                                        handleSelect(e, key)
                                                      }}
                                                    >
                                                      + Add New Address
                                                    </div>
                                                    {sliceArray.length > 0 &&
                                                      sliceArray.map(
                                                        (items, j) => {
                                                          return (
                                                            <div key={j}>
                                                              {' '}
                                                              <hr></hr>
                                                              <div
                                                                className="country-names-list d-flex flex-column"
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  handleUpdateDetails(
                                                                    e,
                                                                    j,
                                                                    key
                                                                  )
                                                                  setSliceKey(
                                                                    j
                                                                  )
                                                                }}
                                                              >
                                                                <span>
                                                                  {
                                                                    items?.billingCityList?.filter(
                                                                      (
                                                                        item
                                                                      ) => {
                                                                        return (
                                                                          item.id ==
                                                                          items.billingCityId
                                                                        )
                                                                      }
                                                                    )[0]?.name
                                                                  }
                                                                </span>
                                                                <span className="country-street-name">
                                                                  {
                                                                    items.billingStreet
                                                                  }
                                                                </span>
                                                              </div>
                                                            </div>
                                                          )
                                                        }
                                                      )}
                                                  </div>
                                                )}
                                              </div>
                                              <div className="error"></div>
                                            </>
                                          )}

                                          {billing && (
                                            <>
                                              <div className="main-state">
                                                <div className="number--location">
                                                  Street*
                                                </div>
                                                <input
                                                  type="text"
                                                  id={'billingStreet-' + key}
                                                  name="billingStreet"
                                                  onChange={(e) =>
                                                    handleLocations(e, key)
                                                  }
                                                  value={
                                                    selectBilling
                                                      ? null
                                                      : sameAddress[key]
                                                        ? location.shippingStreet
                                                        : location.billingStreet ||
                                                        sliceArray[slicekey]
                                                          ?.billingStreet
                                                  }
                                                  className="form-control"
                                                  readOnly={
                                                    selectBilling
                                                      ? false
                                                      : sameAddress[key]
                                                  }
                                                />
                                                <div className="error">
                                                  {
                                                    locationsError[key]
                                                      .billingStreet
                                                  }
                                                </div>
                                              </div>
                                              <div className="main-state">
                                                <div className="number--location">
                                                State/Province*
                                                </div>
                                                <select
                                                  id={'billingStateId-' + key}
                                                  name="billingStateId"
                                                  onChange={(e) =>
                                                    handleState(e, key)
                                                  }
                                                  value={
                                                    selectBilling
                                                      ? null
                                                      : sameAddress[key]
                                                        ? location.shippingStateId
                                                        : location.billingStateId ||
                                                        sliceArray[slicekey]
                                                          ?.billingStateId
                                                  }
                                                  className="register-input"
                                                  disabled={
                                                    selectBilling
                                                      ? false
                                                      : sameAddress[key]
                                                  }
                                                >
                                                  <option value="">
                                                    Select State
                                                  </option>
                                                  {states.map((st, skey) => {
                                                    return (
                                                      <option
                                                        key={skey}
                                                        value={st.id}
                                                      >
                                                        {st.name}
                                                      </option>
                                                    )
                                                  })}
                                                </select>
                                                <div className="error">
                                                  {
                                                    locationsError[key]
                                                      .billingStateId
                                                  }
                                                </div>
                                              </div>
                                              <Row>
                                                <Col lg={6}>
                                                  <div className="number--location">
                                                    City*
                                                  </div>
                                                  <select
                                                    id={'billingCityId-' + key}
                                                    name="billingCityId"
                                                    onChange={(e) => {
                                                      const locationErrorsTemp =
                                                        JSON.parse(
                                                          JSON.stringify(
                                                            locationsError
                                                          )
                                                        )
                                                      const locationsTemp = [
                                                        ...locations
                                                      ]
                                                      locationErrorsTemp[key].billingCityId = ''
                                                      locationsTemp[key].billingCityId = e.target.value
                                                      setLocations(
                                                        locationsTemp
                                                      )
                                                      setLocationsError(
                                                        locationErrorsTemp
                                                      )
                                                    }}
                                                    value={
                                                      sameAddress[key]
                                                        ? location.shippingCityId
                                                        : location.billingCityId ||
                                                          sliceArray[slicekey]
                                                            ?.billingCityId
                                                    }
                                                    className="register-input"
                                                    disabled={
                                                      selectBilling
                                                        ? false
                                                        : sameAddress[key]
                                                    }
                                                  >
                                                    <option value="">
                                                      Select City
                                                    </option>
                                                    {sameAddress[key]
                                                      ? (
                                                      <>
                                                        {location.shippingCityList.map(
                                                          (ct, ckey) => {
                                                            return (
                                                              <option
                                                                key={ckey}
                                                                value={ct.id}
                                                              >
                                                                {ct.name}
                                                              </option>
                                                            )
                                                          }
                                                        )}
                                                      </>
                                                        )
                                                      : (
                                                      <>
                                                        {location
                                                          ?.billingCityList
                                                          .length > 0 &&
                                                          location?.billingCityList.map(
                                                            (ct, ckey) => {
                                                              return (
                                                                <option
                                                                  key={ckey}
                                                                  value={ct.id}
                                                                >
                                                                  {ct.name}
                                                                </option>
                                                              )
                                                            }
                                                          )}
                                                      </>
                                                        )}
                                                  </select>
                                                  <div className="error">
                                                    {
                                                      locationsError[key]
                                                        ?.billingCityId
                                                    }
                                                  </div>
                                                </Col>
                                                <Col lg={6}>
                                                  <div className="number--location">
                                                  Zip Code/Postal Code*
                                                  </div>
                                                  <input
                                                    id={'billingZipCode-' + key}
                                                    name="billingZipCode"
                                                    onChange={(e) => {
                                                      if (
                                                        isNaN(e.target.value)
                                                      ) {
                                                        return
                                                      }
                                                      handleLocations(e, key)
                                                    }}
                                                    value={
                                                      selectBilling
                                                        ? null
                                                        : sameAddress[key]
                                                          ? location.shippingZipCode
                                                          : location.billingZipCode ||
                                                          sliceArray[slicekey]
                                                            ?.billingZipCode
                                                    }
                                                    type="text"
                                                    className="form-control"
                                                    readOnly={
                                                      selectBilling
                                                        ? false
                                                        : sameAddress[key]
                                                    }
                                                  />
                                                  <div className="error">
                                                    {
                                                      locationsError[key]
                                                        .billingZipCode
                                                    }
                                                  </div>
                                                </Col>
                                              </Row>
                                            </>
                                          )}

                                          <div className="error">
                                            {locationsError[key].billingStateId}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                                <div
                                  className="location information-contact d-flex align-items-center justify-content-between mt-2"
                                  id="contact-information"
                                >
                                  Contact Information{' '}
                                  <div
                                  id="checkbox--flex"
                                  className="same-ship-address"
                                >
                                  <div className="flex-checkbox">
                                    <input
                                      onChange={(e) => {
                                        setContactInformation(e, key)
                                      }}
                                      name={'userInfo-' + key}
                                      type="checkbox"
                                      id={'userInfo-' + key}
                                      className="register-check"
                                    />
                                    <label
                                      className="checkbox-name check-box-main"
                                      htmlFor={'userInfo-' + key}
                                    >
                                      Use Owner Information
                                    </label>
                                  </div>
                                </div>
                                </div>
                                <Row>
                                  <Col lg={6} className="mt-2">
                                    <div className="number--location">
                                      First Name*
                                    </div>
                                    <input
                                      type="text"
                                      id={'contactFirstName-' + key}
                                      name="contactFirstName"
                                      value={location.contactFirstName}
                                      onChange={(e) => handleLocations(e, key)}
                                      className="form-control"
                                    />
                                    <div className="error">
                                      {locationsError[key].contactFirstName}
                                    </div>
                                  </Col>
                                  <Col lg={6} className="mt-2">
                                    <div className="number--location">
                                      Last Name*
                                    </div>
                                    <input
                                      type="text"
                                      id={'contactLastName-' + key}
                                      name="contactLastName"
                                      value={location.contactLastName}
                                      onChange={(e) => handleLocations(e, key)}
                                      className="form-control"
                                    />
                                    <div className="error">
                                      {locationsError[key].contactLastName}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6} className="mt-2">
                                    <div className="number--location">
                                      Title*
                                    </div>
                                    <input
                                      type="text"
                                      id={'contactTitle-' + key}
                                      name="contactTitle"
                                      value={location.contactTitle}
                                      onChange={(e) => handleLocations(e, key)}
                                      className="form-control"
                                    />
                                    <div className="error">
                                      {locationsError[key].contactTitle}
                                    </div>
                                  </Col>
                                  <Col lg={6} className="mt-2">
                                    <div className="number--location">
                                      Email Address*
                                    </div>
                                    <input
                                      type="email"
                                      id={'contactEmail-' + key}
                                      name="contactEmail"
                                      value={location.contactEmail}
                                      onChange={(e) => handleLocations(e, key)}
                                      className="form-control"
                                    />
                                    <div className="error">
                                      {locationsError[key].contactEmail}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6} className="mt-2">
                                    <div className="main-phone">
                                      <div className="number--location">
                                        Phone Number*
                                      </div>
                                      <NumberFormat
                                      value={location.contactPhone} name="contactPhone"
                                      format="(###) ###-####"
                                      className="form-control"
                                      onValueChange={(val) => {
                                        handleLocationsInput('contactPhone', val.value, key)
                                        if (isNaN(val.value)) {
                                          return
                                        }
                                        if (val.value.length > 10) {
                                          return
                                        }
                                      }}
                                      />
                                      {/* <input
                                        type="text"
                                        id={'contactPhone-' + key}
                                        name="contactPhone"
                                        value={location.contactPhone}
                                        onChange={(e) => {
                                          if (isNaN(e.target.value)) {
                                            return
                                          }
                                          if (e.target.value.length > 10) {
                                            return
                                          }
                                          handleLocations(e, key)
                                        }}
                                        className="form-control"
                                      /> */}
                                      <div className="error">
                                        {locationsError[key].contactPhone}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <div
                                  className="primary-location mt-2"
                                  style={{ marginBottom: '20px' }}
                                >
                                  <button
                                    type="submit"
                                    className="done--btn mt-2"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            <hr className="btn-line m-0" />
                          </form>
                        </React.Fragment>
                      )
                    })}
                    <div className="add-more-flex">
                      <a
                        href="/#"
                        onClick={(e) => {
                          e.preventDefault()
                          addMoreLocation(e)
                          setSliceArray(locations)
                        }}
                        className="add-more"
                      >
                        <i className="fa fa-plus"></i> Add More Location
                      </a>
                    </div>
                    <div
                      className="register--row"
                      style={{ justifyContent: 'space-around' }}
                    >
                      {step == 2 && (
                        <button
                          onClick={() => setStep(1)}
                          className="register--btn"
                        >
                          Back
                        </button>
                      )}
                      <button
                        onClick={() => submitHandler()}
                        className="register--btn"
                      >
                        Register
                      </button>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}
