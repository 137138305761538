/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  Button
} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { useHistory, Link, useParams } from 'react-router-dom'
// import { Editor } from 'react-draft-wysiwyg'
import Dropzone from 'react-dropzone'
import { GetData, PutData, PostData, PatchData } from 'apiHandler'
import Loader from '../../components/Loader'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import delIcon from '../../assets/images/ic_delete.svg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { Editor } from '@tinymce/tinymce-react'

export function slugify (text) {
  return text
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}

function AddTechnical () {
  const history = useHistory()
  const { id } = useParams()
  const [loader, setLoader] = useState(false)
  const [deleteQIds, setDeleteQIds] = useState([])
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [technicalData, setTechnicalData] = useState({})

  function handleChange (type, e) {
    e.preventDefault()
    if (e.target.name === 'title') {
      setTechnicalData({
        ...technicalData,
        [e.target.name]: e.target.value
      })
    } else setTechnicalData({ ...technicalData, [e.target.name]: e.target.value })
  }

  function handleSubmit (e) {
    e.preventDefault()
    setLoader(true)
    const deleteArr = deleteQIds.length ? deleteQIds : undefined
    const formData = {
      title: technicalData.title
    }
    const createFormData = {
      title: technicalData.title
    }
    id
      ? PutData(`admin/technicalBulletin/${id}`, formData).then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Sent')
          setLoader(false)
          getTechnicalById(id)
        } else {
          toastr.error(response.message, 'Error')
          setLoader(false)
        }
      })
      : PostData('admin/technicalBulletin', createFormData).then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Sent')
          setTimeout(() => {
            history.push(`/technical-bulletin/${response.result.id}`)
          }, 1000)
          setLoader(false)
        } else {
          toastr.error(response.message, 'Error')
          setLoader(false)
        }
      })
  }

  const getTechnicalById = (id) => {
    setLoader(true)
    GetData(`admin/technicalBulletin/${id}`).then((response) => {
      if (response.status === true) {
        setTechnicalData(response.result)
        setLoader(false)
      }
    })
  }

  useEffect(() => {
    if (id) {
      getTechnicalById(id)
    }
  }, [id])

  const handleUpload = async (file) => {
    setLoader(true)
    const formImage = new FormData()
    formImage.append('image', file)
    const response = await PostData('admin/uploadPostImage', formImage)
    if (response) {
      setLoader(false)
    }
    return response.status
      ? response.result.url
      : toastr.error(response.message, 'Error')
  }

  return (
    <>
      <Loader show={loader}></Loader>
      <div className="page-content">
        <Container fluid={true} className="homepage-wrap">
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <Breadcrumbs
              title="UI Elements"
              breadcrumbItem={id ? 'Edit Technical Bulletin' : 'Add New Technical Bulletin'}
              icon={
                <i
                  className="bx bx-arrow-back me-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push('/technical-bulletins')}
                ></i>
              }
            />
            {
              id && <Link
              to={`/technical-bulletin/${id}/links`}
            >
              <b>View Links</b>
            </Link>
            }
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label">
                      <b>Title</b>
                    </Label>
                    <Input
                      placeholder="Enter Title"
                      type="  "
                      id="title"
                      name="title"
                      className="input-border"
                      value={technicalData.title}
                      onChange={(e) => handleChange('', e)}
                    />
                  </div>
                  <hr />
                  <p className='text-center'>
                  {
                    !id && 'Note: Add Title first to add technical bulletin points.'
                  }
                  </p>
                </CardBody>
              </Card>
              <Link to="/technical-bulletins">
                <Button className="btn btn-cancel mb-4 ms-0">Cancel</Button>
              </Link>
              {}
              <Button
                className="btn btn-add-new mb-4 ms-0"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default AddTechnical
