/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Label, Input } from 'reactstrap'
import CrossIcon from '../../assets/images/cross2.svg'
import PropTypes from 'prop-types'
import { GetData, PutData } from 'apiHandler'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Loader from '../Loader'

const FeaturedBrands = (props) => {
  const [featuredData, setFeaturedData] = useState({
    title: null,
    brandIds: []
  })
  const [checklist, setChecklist] = useState(null)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    props.data && setFeaturedData(props.data)
  }, [props.data])

  useEffect(() => {
    GetData('admin/getAllBrands').then((response) => {
      if (response.status == true) {
        setFoundUsers(response.result)
        setChecklist(response.result)
      }
    })
  }, [])
  const [foundUsers, setFoundUsers] = useState(null)
  const filter = (e) => {
    GetData(`admin/getAllBrands?search=${e.target.value}`).then((response) => {
      if (response.status == true) {
        setFoundUsers(response.result)
      } else {
        setFoundUsers(null)
      }
    })
  }

  function handleChange (e) {
    e.preventDefault()
    setFeaturedData({ ...featuredData, [e.target.name]: e.target.value })
  }

  function handleCheckbox (index, e) {
    if (e.target.checked) {
      const updatedCheck = [...featuredData.brandIds]
      updatedCheck.push(parseInt(e.target.name))
      setFeaturedData({ ...featuredData, brandIds: updatedCheck })
    } else if (!e.target.checked) {
      const data = featuredData.brandIds.filter((item, i) => {
        return item != parseInt(e.target.name)
      })
      setFeaturedData({ ...featuredData, brandIds: data })
    }
  }

  function handleDelete (e) {
    e.preventDefault()
    const data = featuredData.brandIds.filter((item) => {
      return item != parseInt(e.target.id)
    })
    setFeaturedData({ ...featuredData, brandIds: data })
  }

  function handleSubmit (e) {
    e.preventDefault()
    setLoader(true)
    PutData('admin/updateHomeSection9', featuredData)
      .then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Sent')
          setLoader(false)
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-undef
        toastr.error(error.message, 'Error')
        setLoader(false)
      })
  }

  return (
    <React.Fragment>
      <Loader show={loader}></Loader>
      <div className="home-banner-right-wrap">
        <div className="supply-resource-wrap">
          <div className="banner-fields-box">
            <div className="sr-title-top">
              <Label htmlFor="formrow-firstname-Input">Section Title</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter section title"
                name="title"
                defaultValue={featuredData?.title}
                onChange={(e) => {
                  handleChange(e)
                }}
              />
            </div>
          </div>
        </div>
        {/* banner1 */}
        <div className="home-banner-outer featured-product-outer">
          <div className="featured-product-wrap">
            <h4>Brands Selection</h4>
            <div className="selected-value">
              {featuredData?.brandIds?.map((data, i) => {
                let newIndex
                if (
                  checklist?.map((item, index) => {
                    if (item.id == data) {
                      newIndex = index
                    }
                  })
                ) {
                  return (
                    <span key={i} className="mt-2">
                      {checklist[newIndex].name}
                      <div className="cross-btn">
                        <img
                          src={CrossIcon}
                          alt="cross"
                          id={data}
                          onClick={(e) => handleDelete(e)}
                        />
                      </div>
                    </span>
                  )
                }
              })}
            </div>
            <div className="featured-inner-box">
              <div className="position-relative search-featured">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  onChange={filter}
                />
                <span className="bx bx-search search-icon" />
              </div>
              <div className="check-listing-container">
                <div className="check-listing">
                  {foundUsers && foundUsers.length > 0
                    ? (
                        foundUsers?.map((data, index) => {
                          return (
                        <div className="checkbox-line" key={index}>
                          <div className="d-flex align-items-center">
                            <input
                              className="me-2"
                              type="checkbox"
                              value={data.id}
                              name={data.id}
                              id={`brand-${data.id}`}
                              onClick={(e) => handleCheckbox(index, e)}
                              defaultChecked={
                                props.data &&
                                featuredData.brandIds.includes(data.id)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`brand-${data.id}`}
                            >
                              {data.name}
                            </label>
                          </div>
                        </div>
                          )
                        })
                      )
                    : (
                    <div className="no-result">No results found!</div>
                      )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="save-btn">
          <button type="submit" onClick={(e) => handleSubmit(e)}>
            Save
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FeaturedBrands

FeaturedBrands.propTypes = {
  data: PropTypes.object
}
