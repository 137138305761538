/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import TableComponent from '../../components/Table'

import { GetData } from '../../apiHandler'
import Loader from '../../components/Loader'
import 'toastr/build/toastr.min.css'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'

const pageSize = 20
function StaffList ({ display }) {
  const params = useParams()
  const [loader, setLoader] = useState(false)
  const [wdStaff, setWDStaff] = useState([])

  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })

  const columns = [
    { key: 'id', label: 'Payment ID' },
    { key: 'type', label: 'Payment Type' },
    { key: 'cardType', label: 'Card Type' },
    { key: 'cardNumber', label: 'Card Number' }
    // { key: "status", label: "Status", html: true },
  ]

  const getWDStaff = (p = 1) => {
    const url =
      'admin/getCustomerPaymentInfo?page=' +
      p +
      '&pageSize=' +
      pageSize +
      '&customerId=' +
      params.id
    setLoader(true)
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status === true || response.status === 200) {
        if (response.result.data.length) {
          const tableData = []
          response.result.data.map((data, index) => {
            const temp = []
            temp.push('disable')
            temp.push('delete')
            data.created = moment(data.created).format('Do MMMM YYYY, h:mm A')
            data.cardType = data.card.brand
            data.cardNumber = '**** **** **** ' + data.card.last4
            data.action = temp
            tableData.push(data)
            return null
          })

          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: response.result.total
          })
          setWDStaff(tableData)
        } else {
          setWDStaff([])
          setPaging({
            currentPage: 1,
            recordPerPage: pageSize,
            totalRecord: 0
          })
        }
      } else {
        // setWDStaff([])
        // setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
        // toastr.error(response.message, 'Error')
      }
    })
  }

  useEffect(() => {
    if (display === true) {
      getWDStaff()
    }
  }, [display])

  const paginationHandler = (page) => {
    getWDStaff(page)
  }

  const handleActions = (key, data) => {
    // Handle Actions
  }

  return (
    <div>
      <Loader show={loader} />
      <div className="staff-main-table">
        <TableComponent
          columns={columns}
          content={wdStaff}
          handleActions={handleActions}
          paging={paging}
          paginationHandler={paginationHandler}
        />
      </div>
    </div>
  )
}

export default StaffList

StaffList.propTypes = {
  locations: PropTypes.array,
  display: PropTypes.any
}
