/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import back from '../../assets/images/back2.svg'
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormFeedback,
  Form,
  Input
} from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { PostData } from 'apiHandler'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toastr from 'toastr'
import Loader from '../../components/Loader'

function AddProduct () {
  const history = useHistory()
  const [loader, setLoader] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      PartNumber: '',
      Price: ''
    },
    validationSchema: Yup.object().shape({
      PartNumber: Yup.string().required('Please enter Part Number'),
      Price: Yup.string().required('Please enter Price')
    }),
    onSubmit: (values) => {
      setLoader(true)
      PostData('vendor/addWDProduct', values).then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Success')
          history.push('/products')
          setLoader(false)
        } else {
          toastr.error(response.message, 'Error')
          setLoader(false)
        }
      })
    }
  })

  const handleCancel = () => {
    history.push('/products')
  }

  return (
    <>
    <Loader show={loader}></Loader>
      <div className="page-content">
        <Container fluid={true} className="customer-container">
          <div className="staff-management-heading Add-staff">
            <Link to="/products">
              {' '}
              <img src={back} style={{ marginRight: '8px' }} />
            </Link>{' '}
            Add New Product
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault()
                    validation.handleSubmit()
                  }}
                >
                  <CardBody className="card-main-body">
                    <div className="staff-edit-main">
                      <div className="edit-first-name">
                        <div className="staff-manafement-light">
                          Part Number*
                        </div>
                        <Input
                          type="text"
                          className="staff-first-input"
                          name="PartNumber"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            !!(validation.touched.PartNumber &&
                            validation.errors.PartNumber)
                          }
                        />
                        {validation.touched.PartNumber &&
                        validation.errors.PartNumber
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.PartNumber}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                      <div className="edit-first-name">
                        <div className="staff-manafement-light">Price*</div>
                        <Input
                          type="text"
                          className="staff-first-input"
                          name="Price"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            !!(validation.touched.Price &&
                            validation.errors.Price)
                          }
                        />
                        {validation.touched.Price &&
                        validation.errors.Price
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.Price}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                    </div>
                    <button type="submit" className="staff-save-changes staff-btn-clr">
                      Save Changes
                    </button>
                    <button onClick={handleCancel} className="staff-cancel-btn staff-btn-clr">
                      Cancel
                    </button>
                  </CardBody>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default AddProduct
