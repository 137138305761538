import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Form,
  Button
} from 'reactstrap'
import Loader from '../../components/Loader'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { convertToHTML } from 'draft-convert'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { PutData, GetData, ToesterSettings } from '../../apiHandler'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function Policies (props) {
  toastr.options = ToesterSettings
  const [privacyPolicy, setPolicyPolicy] = useState(EditorState.createEmpty())
  const [termsAndConditions, setTermsAndConditions] = useState(EditorState.createEmpty())
  const [legalDisclaimer, setLegalDisclaimer] = useState(EditorState.createEmpty())
  const [shipping, setShipping] = useState(EditorState.createEmpty())
  const [cookiePolicy, setCookiePolicy] = useState(EditorState.createEmpty())
  // eslint-disable-next-line no-unused-vars
  const [whyQls, setWhyQls] = useState(EditorState.createEmpty())
  const [loader, setLoader] = useState(false)

  const onEditorStateChange = (editorState, type) => {
    if (type === 'privacyPolicy') {
      return setPolicyPolicy(editorState)
    }
    if (type === 'termsAndConditions') {
      return setTermsAndConditions(editorState)
    }
    if (type === 'legalDisclaimer') {
      return setLegalDisclaimer(editorState)
    }
    if (type === 'shipping') {
      return setShipping(editorState)
    }
    if (type === 'cookiePolicy') {
      return setCookiePolicy(editorState)
    }
    if (type === 'whyQls') {
      return setWhyQls(editorState)
    }
  }

  useEffect(() => {
    getCmsData()
  }, [])

  const getCmsData = () => {
    setLoader(true)
    GetData('admin/getAllPages').then((response) => {
      setLoader(false)
      if (response.status === true) {
        const privacyData = response.result.find(x => x.slug === 'privacy-policy')
        const privacyDataState = ContentState.createFromBlockArray(convertFromHTML(`${privacyData.description}`))
        setPolicyPolicy(EditorState.createWithContent(privacyDataState))

        const termsData = response.result.find(x => x.slug === 'terms-and-conditions')
        const termsDataState = ContentState.createFromBlockArray(convertFromHTML(`${termsData.description}`))
        setTermsAndConditions(EditorState.createWithContent(termsDataState))

        const legalData = response.result.find(x => x.slug === 'legal-disclaimer')
        const legalDataState = ContentState.createFromBlockArray(convertFromHTML(`${legalData.description}`))
        setLegalDisclaimer(EditorState.createWithContent(legalDataState))

        const shippingData = response.result.find(x => x.slug === 'shipping-and-returns')
        const shippingDataState = ContentState.createFromBlockArray(convertFromHTML(`${shippingData.description}`))
        setShipping(EditorState.createWithContent(shippingDataState))

        const cookieData = response.result.find(x => x.slug === 'cookie-policy')
        const cookieDataState = ContentState.createFromBlockArray(convertFromHTML(`${cookieData.description}`))
        setCookiePolicy(EditorState.createWithContent(cookieDataState))

        const whyQlsData = response.result.find(x => x.slug === 'why-qls')
        const whyQlsDataState = ContentState.createFromBlockArray(convertFromHTML(`${whyQlsData.description}`))
        setWhyQls(EditorState.createWithContent(whyQlsDataState))
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const saveCms = (slug, description) => {
    const descriptionFormatted = convertToHTML(description.getCurrentContent())

    setLoader(true)
    PutData('admin/updateCmsPages', { slug, description: descriptionFormatted }).then((response) => {
      setLoader(false)
      if (response.status === true) {
        toastr.success(response.message, 'Success')
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  return (
    <React.Fragment>
      <Loader show={loader}/>
      <div className="page-content">
        <MetaTags>
          <title>QLS Admin | CMS</title>
        </MetaTags>
        <Container fluid={true} className="homepage-wrap">
          <Breadcrumbs title="UI Elements" breadcrumbItem="Policies" />
          <Row className="mt-3">
            <Col lg={12}>
              <Card className="py-2 px-4">
                <CardTitle className="p-3 pb-0">PRIVACY POLICY</CardTitle>
                <CardBody>
                  <Form method="post">
                    <Editor
                      editorState={privacyPolicy}
                      onEditorStateChange={(e) => onEditorStateChange(e, 'privacyPolicy')}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                    />
                  </Form>
                  <Button onClick={() => saveCms('privacy-policy', privacyPolicy)} color="primary" className="btn btn-add-new mt-3 ms-0">
                    Save
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card className="py-2 px-4">
                <CardTitle className="p-3 pb-0">TERMS & CONDITIONS</CardTitle>
                <CardBody>
                  <Form method="post">
                    <Editor
                      editorState={termsAndConditions}
                      onEditorStateChange={(e) => onEditorStateChange(e, 'termsAndConditions')}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                    />
                  </Form>
                  <Button onClick={() => saveCms('terms-and-conditions', termsAndConditions)} color="primary" className="btn btn-add-new mt-3 ms-0">
                    Save
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card className="py-2 px-4">
                <CardTitle className="p-3 pb-0">LEGAL DISCLAIMER</CardTitle>
                <CardBody>
                  <Form method="post">
                    <Editor
                      editorState={legalDisclaimer}
                      onEditorStateChange={(e) => onEditorStateChange(e, 'legalDisclaimer')}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                    />
                  </Form>
                  <Button onClick={() => saveCms('legal-disclaimer', legalDisclaimer)} color="primary" className="btn btn-add-new mt-3 ms-0">
                    Save
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card className="py-2 px-4">
                <CardTitle className="p-3 pb-0">SHIPPING AND RETURNS</CardTitle>
                <CardBody>
                  <Form method="post">
                    <Editor
                      editorState={shipping}
                      onEditorStateChange={(e) => onEditorStateChange(e, 'shipping')}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                    />
                  </Form>
                  <Button onClick={() => saveCms('shipping-and-returns', shipping)} color="primary" className="btn btn-add-new mt-3 ms-0">
                    Save
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card className="py-2 px-4">
                <CardTitle className="p-3 pb-0">COOKIE POLICY</CardTitle>
                <CardBody>
                  <Form method="post">
                    <Editor
                      editorState={cookiePolicy}
                      onEditorStateChange={(e) => onEditorStateChange(e, 'cookiePolicy')}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                    />
                  </Form>
                  <Button onClick={() => saveCms('cookie-policy', cookiePolicy)} color="primary" className="btn btn-add-new mt-3 ms-0">
                    Save
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

export default Policies
