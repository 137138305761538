/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import {
  Modal
} from 'reactstrap'
import TableComponent from '../../components/Table'

import { GetData, DeleteData } from '../../apiHandler'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

function StaffList (props) {
  const params = useParams()
  const [loader, setLoader] = useState(false)
  const [wdStaff, setWDStaff] = useState([])

  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [selectedData, setSelectedData] = useState({})
  const [warehouses, setWarehouses] = useState([])
  const [selectedWarehouse, setSelectedWarehouse] = useState(null)

  const columns = [
    { key: 'name', label: 'Name' },
    { key: 'phone', label: 'Phone Number' },
    { key: 'email', label: 'Email Address' },
    { key: 'type', label: 'Type' },
    { key: 'designation', label: 'Title' },
    // { key: "status", label: "Status", html: true },
    { key: 'action', label: '' }
  ]

  const getWDStaff = (p = 1) => {
    const pageSize = 10
    let url =
      'admin/getWdStaffList?page=' +
      p +
      '&pageSize=' +
      pageSize +
      '&vendorId=' +
      params.id
    if (selectedWarehouse) {
      url += '&vendorWarehouseLocationId=' + selectedWarehouse
    }
    setLoader(true)
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status === true || response.status === 200) {
        if (response.result.data.length) {
          const tableData = []
          response.result.data.map((data, index) => {
            let statusString = <div className="pending-div">Pending</div>
            if (data.status === 1) {
              statusString = <div className="active-div">Active</div>
            }
            if (data.status === 2) {
              statusString = <div className="approved-div">Approved</div>
            }
            if (data.status === 3) {
              statusString = <div className="rejected-div">Rejected</div>
            }
            if (data.status === 4) {
              statusString = <div className="disabled-div">Disabled</div>
            }
            data.tempStatus = data.status
            data.status = statusString
            data.name = data.firstName + ' ' + data.lastName
            if (data.roleId == 4) {
              data.type = 'Staff'
            } else {
              data.type = 'Driver'
            }
            const temp = []
            if (data.tempStatus === 1) {
              temp.push('disable')
            } else if (data.tempStatus === 4) {
              temp.push('enable')
            }
            temp.push('delete')
            data.action = temp
            tableData.push(data)
            return null
          })

          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: response.result.count
          })
          setWDStaff(tableData)
        } else {
          setWDStaff([])
          setPaging({
            currentPage: 1,
            recordPerPage: pageSize,
            totalRecord: 0
          })
        }
      } else {
        setWDStaff([])
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
        toastr.error(response.message, 'Error')
      }
    })
  }

  useEffect(() => {
    return () => {
      setSelectedWarehouse(null)
    }
  }, [])

  useEffect(() => {
    getWDStaff()
  }, [])

  const handleActions = (key, data) => {
    if (key === 'disable' || key === 'enable') {
      setIsDisableOpen(true)
      setSelectedData(data)
    }
    if (key === 'delete') {
      setIsDeleteOpen(true)
      setSelectedData(data)
    }
  }

  const paginationHandler = (page) => {
    getWDStaff(page)
  }

  const handleDisable = (id) => (e) => {
    e.preventDefault()
    setLoader(true)
    const url = 'admin/changeWDStaffstatus/' + params.id + '/' + id
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status == true) {
        toastr.success(response.message, 'Success')
        getWDStaff()
      } else {
        toastr.error(response.message, 'Error')
      }
    })

    setIsDisableOpen(false)
  }

  const handleDelete = (id) => (e) => {
    e.preventDefault()
    setLoader(true)
    const url = 'admin/removeWdStaff/' + params.id + '/' + id
    DeleteData(url).then((response) => {
      setLoader(false)
      if (response.status == true) {
        toastr.success(response.message, 'Success')
        getWDStaff()
      } else {
        toastr.error(response.message, 'Error')
      }
    })

    setIsDeleteOpen(false)
  }

  useEffect(() => {
    if (props.locations.length > 0) {
      setWarehouses(props.locations.map((warehouse) => {
        return { label: warehouse.locationName, value: warehouse.id }
      }))
    }
  }, [props.locations])

  useEffect(() => {
    if (selectedWarehouse) {
      getWDStaff(1)
    }
  }, [selectedWarehouse])

  const handleWarehouseSelect = (e) => {
    setSelectedWarehouse(e.target.value)
  }

  return (
    <div>
      <Loader show={loader} />
      <div className="warehouse--staff ">
        <div></div>
        <div className="input-label">Warehouse</div>

        <div className="dropdown__staff main-drop-staff">
          <select className="drop-staf" onChange={handleWarehouseSelect}>
            <option value={''}>Select Warehouse</option>
            {warehouses.map((warehouse, i) => {
              return <option value={warehouse.value} key={i}>{warehouse.label}</option>
            })}
          </select>
        </div>
      </div>
      <div className="staff-main-table">
        <TableComponent
          columns={columns}
          content={wdStaff}
          handleActions={handleActions}
          paging={paging}
          paginationHandler={paginationHandler}
        />
      </div>
      <Modal
        className="modal-main-body"
        isOpen={isDisableOpen}
        toggle={() => setIsDisableOpen(false)}
        centered
      >
        <div className="modal-header">
          <button
            type="button"
            onClick={() => {
              setIsDisableOpen(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <p className="disable-warehouse">
            Are you sure you want to{' '}
            {selectedData.tempstatus === 4 ? ' Enable' : ' Disable'} WD Staff?
          </p>
          <div className="modal-detail-div">
            <p className="modal-details">Details</p>
            <p className="modal-warehouse-name">
              <span className="warehouse-heading"> Name: </span>{' '}
              {selectedData?.name}
            </p>
            <p className="modal-warehouse-name">
              <span className="warehouse-heading"> Email: </span>{' '}
              {selectedData?.email}
            </p>
            <p className="modal-warehouse-name">
              <span className="warehouse-heading"> Phone Number: </span>{' '}
              {selectedData?.phone ? selectedData?.phone : '--'}
            </p>
            <p className="modal-warehouse-name">
              <span className="warehouse-heading"> Title: </span>{' '}
              {selectedData?.designation ? selectedData?.designation : '--'}
            </p>
          </div>
          <div className="modal-btn-div">
            <button
              className="warehouse-btn-yes"
              onClick={handleDisable(selectedData.id)}
            >
              Yes
            </button>
            <button
              className="warehouse-btn-yes warehouse-btn-no"
              onClick={() => setIsDisableOpen(false)}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        className="modal-main-body"
        isOpen={isDeleteOpen}
        toggle={() => setIsDeleteOpen(false)}
        centered
      >
        <div className="modal-header">
          <button
            type="button"
            onClick={() => {
              setIsDeleteOpen(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <p className="disable-warehouse">
            Are you sure you want to Delete this WD Staff?
          </p>
          <div className="modal-detail-div">
            <p className="modal-details">Details</p>
            <p className="modal-warehouse-name">
              <span className="warehouse-heading"> Name: </span>{' '}
              {selectedData?.name}
            </p>
            <p className="modal-warehouse-name">
              <span className="warehouse-heading"> Email: </span>{' '}
              {selectedData?.email}
            </p>
            <p className="modal-warehouse-name">
              <span className="warehouse-heading"> Phone Number: </span>{' '}
              {selectedData?.phone ? selectedData?.phone : '--'}
            </p>
            <p className="modal-warehouse-name">
              <span className="warehouse-heading"> Title: </span>{' '}
              {selectedData?.designation ? selectedData?.designation : '--'}
            </p>
          </div>
          <div className="modal-btn-div">
            <button
              className="warehouse-btn-yes"
              onClick={handleDelete(selectedData.id)}
            >
              Yes
            </button>
            <button
              className="warehouse-btn-yes warehouse-btn-no"
              onClick={() => setIsDeleteOpen(false)}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default StaffList

StaffList.propTypes = {
  locations: PropTypes.array
}
