/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Modal, Row, Col, Label, Form, FormFeedback } from 'reactstrap'
import PropTypes from 'prop-types'
import Loader from '../../components/Loader'
import { PostData } from 'apiHandler'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toastr from 'toastr'

export default function LocationViewModal (props) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [locationData, setLocationData] = useState(null)
  const [changedLocationData, setChangedLocationData] = useState(null)
  const [loader, setLoader] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)

  useEffect(() => {
    if (
      props?.locationData &&
      props?.locationData?.customerLocationChangeRequests?.length == 1
    ) {
      setLocationData(props.locationData)
      setChangedLocationData(
        props.locationData.customerLocationChangeRequests[0]
      )
    }
  }, [props.locationData])

  const handleApprove = () => {
    setLoader(true)
    const url =
      'admin/updateCustomerChangeLocationStatus/' + changedLocationData.id
    const body = {
      action: 1,
      reason: ''
    }
    PostData(url, body).then((response) => {
      setLoader(false)
      if (response.status === true) {
        toastr.success(response.message, 'Success')
        props.getLocations()
        setShow(false)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      reason: ''
    },
    validationSchema: Yup.object({
      reason: Yup.string().required('Please Enter Reason')
    }),
    onSubmit: (values) => {
      setLoader(true)
      const url =
        'admin/updateCustomerChangeLocationStatus/' + changedLocationData.id
      const body = {
        action: 2,
        ...values
      }
      PostData(url, body).then((response) => {
        setLoader(false)
        if (response.status === true) {
          toastr.success(response.message, 'Success')
          setShow(false)
          setShowRejectModal(false)
          props.getLocations()
        } else {
          toastr.error(response.message, 'Error')
        }
      })
    }
  })

  return (
    <>
      <Loader show={loader} />
      <button className="approve-btn new--req-btn" onClick={handleShow}>
        Change Request Received
      </button>
      <Modal
        size="md"
        isOpen={show}
        toggle={() => {
          handleClose()
        }}
        centered
      >
        <div className="modal-header border-0">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            <b> Location Change Request</b>
          </h5>
          <button
            onClick={() => {
              handleClose()
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body ">
          <Row>
            <Col lg={12}>
              <div className="mb-2">
                <Label>Location Name/Title*</Label>
                <p>
                  {locationData?.locationName
                    ? (
                        locationData?.locationName ===
                    changedLocationData?.locationName
                          ? (
                      <b>{locationData?.locationName}</b>
                            )
                          : (
                      <b>
                        <span className="text-danger">
                          {changedLocationData?.locationName}
                        </span>
                      </b>
                            )
                      )
                    : (
                        '--'
                      )}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="mb-2">
                <Label>Shipping Address</Label>
                <p className="m-0">
                  {locationData?.shippingStreet
                    ? (
                        locationData?.shippingStreet ===
                    changedLocationData?.shippingStreet
                          ? (
                      <b>{locationData?.shippingStreet}</b>
                            )
                          : (
                      <b>
                        <span className="text-danger">
                          {changedLocationData?.shippingStreet}
                        </span>
                      </b>
                            )
                      )
                    : (
                        '--'
                      )}
                </p>
                <p>
                  {locationData?.shippingZipCode
                    ? (
                        locationData?.shippingZipCode ===
                    changedLocationData?.shippingZipCode
                          ? (
                      <b>{locationData?.shippingZipCode}</b>
                            )
                          : (
                      <b>
                        <span className="text-danger">
                          {changedLocationData?.shippingZipCode}
                        </span>
                      </b>
                            )
                      )
                    : (
                        '--'
                      )}
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-2">
                <Label>Contact Information</Label>
                <p className="m-0">
                  {locationData?.contactTitle
                    ? (
                        locationData?.contactTitle ===
                    changedLocationData?.contactTitle
                          ? (
                      <b>{locationData?.contactTitle}</b>
                            )
                          : (
                      <b>
                        <span className="text-danger">
                          {changedLocationData?.contactTitle}
                        </span>
                      </b>
                            )
                      )
                    : (
                        '--'
                      )}
                </p>
                <p className="m-0">
                  {locationData?.contactFirstName
                    ? (
                        locationData?.contactFirstName ===
                    changedLocationData?.contactFirstName
                          ? (
                      <b>{locationData?.contactFirstName}</b>
                            )
                          : (
                      <b>
                        <span className="text-danger">
                          {changedLocationData?.contactFirstName}
                        </span>
                      </b>
                            )
                      )
                    : (
                        '--'
                      )}
                  {locationData?.contactLastName
                    ? (
                        locationData?.contactLastName ===
                    changedLocationData?.contactLastName
                          ? (
                      <b>{locationData?.contactLastName}</b>
                            )
                          : (
                      <b>
                        <span className="text-danger">
                          {changedLocationData?.contactLastName}
                        </span>
                      </b>
                            )
                      )
                    : (
                        '--'
                      )}
                </p>
                <p className="m-0">
                  {locationData?.contactEmail
                    ? (
                        locationData?.contactEmail ===
                    changedLocationData?.contactEmail
                          ? (
                      <b>{locationData?.contactEmail}</b>
                            )
                          : (
                      <b>
                        <span className="text-danger">
                          {changedLocationData?.contactEmail}
                        </span>
                      </b>
                            )
                      )
                    : (
                        '--'
                      )}
                </p>
                <p>
                  {locationData?.contactPhone
                    ? (
                        locationData?.contactPhone ===
                    changedLocationData?.contactPhone
                          ? (
                      <b>{locationData?.contactPhone}</b>
                            )
                          : (
                      <b>
                        <span className="text-danger">
                          {changedLocationData?.contactPhone}
                        </span>
                      </b>
                            )
                      )
                    : (
                        '--'
                      )}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}></Col>
          </Row>
          <div className="mt-2 mb-3 text-center">
            <button
              onClick={() => setShowRejectModal(true)}
              className="reject-btn"
            >
              Reject
            </button>
            <button onClick={() => handleApprove()} className="approve-btn">
              Approve
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        size="md"
        isOpen={showRejectModal}
        // toggle={() => {
        //   tog_large()
        // }}
        centered
      >
        <div className="modal-header border-0">
          <button
            onClick={() => {
              setShowRejectModal(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body rejected-modal-container">
          <p className="reject-modal-heading-red">
            Are you sure you want to reject customer change request?
          </p>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <p className="reject-modal-heading-black">Rejection Reason*</p>
            <textarea
              name="reason"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.reason || ''}
              invalid={
                !!(validation.touched.reason && validation.errors.reason)
              }
            ></textarea>
            {validation.touched.reason && validation.errors.reason
              ? (
              <FormFeedback type="invalid" style={{ display: 'block' }}>
                {validation.errors.reason}
              </FormFeedback>
                )
              : null}
            <div className="text-center mt-4 mb-2">
              <button
                className="reject-no-btn me-3"
                type="cancel"
                onClick={() => {
                  setShowRejectModal(false)
                }}
              >
                No
              </button>
              <button className="reject-submit-btn" type="submit">
                Submit
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  )
}

LocationViewModal.propTypes = {
  locationData: PropTypes.object,
  getLocations: PropTypes.func
}
