/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  Dropdown,
  DropdownItem,
  Modal,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap'
import classnames from 'classnames'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import TableComponent from '../../components/Table'

import { GetData, ToesterSettings } from '../../apiHandler'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { useHistory, Link } from 'react-router-dom'

let filterByStatus = 'all'
let searchTimer
let filterByKeyObject = { key: 'all' }
export default function Customer () {
  toastr.options = ToesterSettings
  const history = useHistory()
  const [loader, setLoader] = useState(false)
  const [tab, setTab] = useState('1')
  const [singlebtn, setSinglebtn] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState({
    label: 'All',
    key: 'all'
  })
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  //   const [customers,setCustomers] = useState([])
  const [staffs, setStaffs] = useState([])
  const [staffsCount, setStaffsCount] = useState({})
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const [columns, setColumns] = useState([
    { key: 'name', label: 'Name', sorting: true },
    { key: 'phone', label: 'Phone Number' },
    { key: 'email', label: 'Email Address', sorting: true },
    { key: 'designation', label: 'Title', sorting: true },
    { key: 'action', label: 'View/Disable' }
  ])
  const [searchText, setSearchText] = useState('')
  const [selectedData, setSelectedData] = useState({})
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const filterTypes = [
    { label: 'All', key: 'all' },
    { label: 'Name', key: 'name' },
    { label: 'Email Address', key: 'email' },
    { label: 'Title', key: 'designation' },
    { label: 'Status', key: 'status' },
    { label: 'Phone Number', key: 'phone' }
  ]

  // eslint-disable-next-line no-unused-vars
  const [currentRole, setCurrentRole] = useState(1)

  useEffect(() => {
    if (localStorage.authUser) {
      const userObj = JSON.parse(localStorage.authUser)
      setCurrentRole(userObj.roleId)
    }
  }, [])

  useEffect(() => {
    getStaffs()
    getStaffCount()
    return () => {
      setTab('1')
      filterByStatus = 'all'
      filterByKeyObject = { key: 'all' }
    }
  }, [])

  const getStaffCount = () => {
    const userObj = JSON.parse(localStorage.authUser)
    let url = 'admin/getStaffCounts'
    if (userObj.roleId == 3 || userObj.roleId == 4) {
      url = 'vendor/getStaffCounts'
    }
    GetData(url).then((response) => {
      if (response.status === true || response.status === 200) {
        setStaffsCount(response.result.data)
      }
    })
  }

  const handleDisable = (id) => (e) => {
    e.preventDefault()
    const userObj = JSON.parse(localStorage.authUser)
    let url = 'admin/changeStaffStatus/' + id
    if (userObj.roleId == 3 || userObj.roleId == 4) {
      url = 'vendor/changeStaffstatus/' + id
    }
    GetData(url).then((response) => {
      if (response.status == true) {
        toastr.success(response.message, 'Success')
        getStaffs()
        getStaffCount()
      } else {
        toastr.error(response.message, 'Error')
      }
    })

    setIsDisableOpen(false)
  }

  const getStaffs = (p = 1) => {
    const pageSize = 20
    const userObj = JSON.parse(localStorage.authUser)
    let url = 'admin/getAllStaff?page=' + p + '&pageSize=' + pageSize
    if (userObj.roleId == 3 || userObj.roleId == 4) {
      url = 'vendor/getStaffList?page=' + p + '&pageSize=' + pageSize
      setColumns([
        { key: 'name', label: 'Name', sorting: true },
        { key: 'phone', label: 'Phone Number' },
        { key: 'email', label: 'Email Address', sorting: true },
        { key: 'type', label: 'Type' },
        { key: 'designation', label: 'Title', sorting: true },
        { key: 'action', label: 'View/Disable' }
      ])
    }
    if (filterByStatus !== 'all') {
      url += '&status=' + filterByStatus
    }
    if (filterByKeyObject.string) {
      url +=
        '&filterBy=' +
        filterByKeyObject.key +
        '&search=' +
        filterByKeyObject.string
    }
    if (sortingState.sortColumn != null) {
      url +=
        '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order
    }
    setLoader(true)
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status === true || response.status === 200) {
        if (response.result.data.length) {
          const tableData = []
          response.result.data.map((data, index) => {
            data.name = data.firstName + ' ' + data.lastName
            let statusString = <div className="pending-div">Pending</div>
            if (data.status === 1) {
              statusString = <div className="active-div">Active</div>
            }
            if (data.status === 2) {
              statusString = <div className="approved-div">Approved</div>
            }
            if (data.status === 3) {
              statusString = <div className="rejected-div">Rejected</div>
            }
            if (data.status === 4) {
              statusString = <div className="disabled-div">Disabled</div>
            }

            data.tempstatus = data.status
            data.status = statusString
            const temp = ['view']
            if (data.tempstatus === 1) {
              temp.push('disable')
            } else if (data.tempstatus === 4) {
              temp.push('enable')
            }
            if (data.roleId == 4) {
              data.type = 'Staff'
            } else {
              data.type = 'Driver'
            }
            data.action = temp
            tableData.push(data)
            return null
          })

          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: response.result.total
          })
          setStaffs(tableData)
        } else {
          setStaffs([])
          setPaging({
            currentPage: 1,
            recordPerPage: pageSize,
            totalRecord: 0
          })
        }
      } else {
        setStaffs([])
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
        toastr.error(response.message, 'Error')
      }
    })
  }

  const filterData = (key, type) => {
    setTab(key)
    filterByStatus = type
    getStaffs(1)
  }

  const handleActions = (key, data) => {
    if (key === 'view') {
      history.push('/staff-information/' + data.id)
    } else if (key === 'disable' || key === 'enable') {
      setSelectedData(data)
      setIsDisableOpen(true)
    }
  }

  const handleRow = (data) => {
    history.push('/staff-information/' + data.id)
  }

  const paginationHandler = (page) => {
    getStaffs(page)
  }

  const filterByKey = (obj) => {
    filterByKeyObject.key = obj.key
    setSelectedFilter(obj)
    if (filterByKeyObject.string) {
      getStaffs(1)
    }
  }

  const searchByText = (t) => {
    setSearchText(t)
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      filterByKeyObject.string = t
      getStaffs(1)
    }, 2000)
  }

  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn == column) {
      if (sortingState.order == 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }

  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getStaffs(1)
  }, [sortingState])

  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <MetaTags>
          <title>QLS Admin | Staff</title>
        </MetaTags>
        <Container fluid={true} className="customer-container">
          <div className="breadcrumb-main-nav ">
            <Breadcrumbs
              title="UI Elements"
              breadcrumbItem="Staff Management"
            />
            <div className="imp-exp-div">
              <button className="bg-white export-btn me-2">Export</button>
              <Link to="/Add-staff">
                <button className="add-new-customer-btn">
                  + Add New Staff
                </button>
              </Link>
            </div>
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="warehouse-navbar">
                    <Nav pills className="wd-bottom">
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '1'
                          })}
                          onClick={() => filterData('1', 'all')}
                        >
                          All ({staffsCount?.all})
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '2'
                          })}
                          onClick={() => filterData('2', 'disabled')}
                        >
                          Disabled ({staffsCount?.disabled})
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div className="d-flex drop-search">
                      <Dropdown
                        isOpen={singlebtn}
                        toggle={() => setSinglebtn(!singlebtn)}
                      >
                        <DropdownToggle className="customer-select text-dark">
                          Filter By{' '}
                          <span className="font-weight-bold">
                            {selectedFilter.label}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {filterTypes.map((filterObj, key) => {
                            return (
                              <DropdownItem
                                key={key}
                                onClick={() => filterByKey(filterObj)}
                              >
                                {filterObj.label}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      <div className="customer-search-form">
                        <input
                          className="form-control input-border customer-input-border"
                          type="text"
                          placeholder="Search"
                          onChange={(e) => searchByText(e.target.value)}
                          value={searchText}
                        />
                        <i className="bx bx-search-alt-2 search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <hr className=" mt-0 mb-4 tab-hr"></hr>
                  <TableComponent
                    columns={columns}
                    content={staffs}
                    handleActions={handleActions}
                    paging={paging}
                    paginationHandler={paginationHandler}
                    onClick={handleRow}
                    sorting={handleSort}
                    arrowState={sortingState}
                  />
                </CardBody>
                <Modal
                  className="modal-main-body"
                  isOpen={isDisableOpen}
                  toggle={() => setIsDisableOpen(false)}
                  centered
                >
                  <div className="modal-header">
                    <button
                      type="button"
                      onClick={() => {
                        setIsDisableOpen(false)
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p className="disable-warehouse">
                      Are you sure you want to{' '}
                      {selectedData.tempstatus === 4 ? ' Enable' : ' Disable'}{' '}
                      Staff?
                    </p>
                    <div className="modal-detail-div">
                      <p className="modal-details">Details</p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Name: </span>{' '}
                        {selectedData.name}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Email: </span>{' '}
                        {selectedData.email}
                      </p>
                    </div>
                    <div className="modal-btn-div">
                      <button
                        className="warehouse-btn-yes"
                        onClick={handleDisable(selectedData.id)}
                      >
                        Yes
                      </button>
                      <button
                        className="warehouse-btn-yes warehouse-btn-no"
                        onClick={() => setIsDisableOpen(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </Modal>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
