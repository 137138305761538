/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  // Nav,
  // NavLink,
  // NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap'
// import classnames from 'classnames'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import TableComponent from '../../components/Table'
// import moment from 'moment'

import { GetData, ToesterSettings } from '../../apiHandler'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { useHistory } from 'react-router-dom'

let filterByStatus = 'all'
let searchTimer
let filterByKeyObject = { key: 'all' }

const columns = [
  { key: 'companyName', label: 'Company Name', sorting: true },
  { key: 'mobile', label: 'Mobile Number', sorting: false },
  { key: 'email', label: 'Email Address', sorting: true },
  { key: 'action', label: 'View', sorting: false }
]
const filterTypes = [
  { label: 'All', key: 'all' },
  { label: 'Company Name', key: 'companyName' },
  { label: 'Email', key: 'email' },
  { label: 'Mobile', key: 'mobile' }
]

export default function Invoices () {
  toastr.options = ToesterSettings
  const history = useHistory()
  const [loader, setLoader] = useState(false)
  // const [tab, setTab] = useState('1')
  const [singlebtn, setSinglebtn] = useState(false)
  const [isStatusOpen, setIsStatusOpen] = useState(false)
  // const [invoiceStatus, setInvoiceStatus] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState({
    label: 'All',
    key: 'all'
  })
  const [invoices, setInvoices] = useState([])
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const [searchText, setSearchText] = useState('')
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  const userObj = JSON.parse(localStorage.authUser)
  const statusTypesData =
[
  { label: 'Pending', key: '0' },
  { label: 'Active', key: '1' },
  { label: 'Approved', key: '2' },
  { label: 'Rejected', key: '3' },
  { label: 'Disabled', key: '4' }
]
  const [selectStatus, setSelectStatus] = useState('Select Status')

  useEffect(() => {
    getInvoices()
    return () => {
      // setTab('1')
      filterByStatus = 'all'
      filterByKeyObject = { key: 'all' }
    }
  }, [])

  const getInvoices = (p = 1) => {
    const pageSize = 20
    let url = 'admin/openAccountCustomers?page=' + p + '&pageSize=' + pageSize
    if (filterByStatus !== 'all') {
      url += '&status=' + filterByStatus
    }
    if (filterByKeyObject.string) {
      url +=
        '&filterBy=' +
        filterByKeyObject.key +
        '&search=' +
        filterByKeyObject.string
    }
    if (sortingState.sortColumn != null) {
      url +=
        '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order
    }
    setLoader(true)
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status === true) {
        if (response?.result?.customers?.length) {
          const modifiedData = response.result.customers.map((data) => {
            return { id: data.id, companyName: data.companyName, email: data.email, mobile: data.mobile, action: ['view'] }
          })

          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: response.result.total
          })
          setInvoices(modifiedData)
        } else {
          setInvoices([])
          setPaging({
            currentPage: 1,
            recordPerPage: pageSize,
            totalRecord: 0
          })
        }
      } else {
        setInvoices([])
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
        toastr.error(response.message, 'Error')
      }
    })
  }

  // const filterData = (key, type) => {
  //   setTab(key)
  //   filterByStatus = type
  //   // if (type === 'pending') {
  //   //   setInvoiceStatus(0)
  //   // } else if (type === 'approved') {
  //   //   setInvoiceStatus(2)
  //   // } else if (type === 'rejected') {
  //   //   setInvoiceStatus(3)
  //   // } else if (type === 'active') {
  //   //   setInvoiceStatus(1)
  //   // } else if (type === 'disabled') {
  //   //   setInvoiceStatus(4)
  //   // } else {
  //   //   setInvoiceStatus(null)
  //   // }
  //   getInvoices(1)
  // }

  const handleActions = (key, data) => {
    if (key === 'view') {
      history.push('/invoices/' + data.id)
    }
  }

  const handleRow = (data) => {
    if (userObj.roleId === 1 || userObj.roleId === 2) {
      history.push('/invoices/' + data.id)
    }
  }

  const paginationHandler = (page) => {
    getInvoices(page)
  }

  const filterByKey = (obj) => {
    setSelectStatus('Select Status')
    filterByKeyObject.key = obj.key
    setSelectedFilter(obj)
    if (filterByKeyObject.string) {
      getInvoices(1)
    }
  }

  const searchByText = (t) => {
    setSearchText(t)
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      filterByKeyObject.string = t
      getInvoices(1)
    }, 2000)
  }

  const selectByStatus = (obj) => {
    filterByKeyObject.string = obj.key
    getInvoices(1)
    setSelectStatus(obj.label)
  }

  // let columns = [
  //   { key: 'companyName', label: 'Company Name', sorting: true },
  //   { key: 'mobile', label: 'Mobile Number', sorting: false },
  //   { key: 'email', label: 'Email Address', sorting: true },
  //   { key: 'priceLevel', label: 'Price Level', sorting: false },
  //   { key: 'locations', label: 'Locations', html: true, sorting: false },
  //   { key: 'status', label: 'Status', html: true, sorting: false },
  //   { key: 'action', label: 'Action', sorting: false }
  // ]

  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn == column) {
      if (sortingState.order == 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }

  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getInvoices(1)
  }, [sortingState])

  const handleRemoveSearchWord = () => {
    setSearchText('')
    filterByKeyObject.string = ''
    getInvoices(1)
  }

  // function handleExport (e) {
  //   e.preventDefault()
  //   PostData('admin/export/customers', { status: invoiceStatus }).then(
  //     (response) => {
  //       if (response.status == true) {
  //         const a = document.createElement('a')
  //         a.href = 'https://' + response.result
  //         a.download = 'download'
  //         a.click()
  //       }
  //     }
  //   )
  // }
  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <MetaTags>
          <title>QLS Admin | Open Account Invoices</title>
        </MetaTags>
        <Container fluid={true} className="customer-container">
          <div className="breadcrumb-main-nav">
            <Breadcrumbs title="UI Elements" breadcrumbItem="Open Account Invoices" />
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="warehouse-navbar" style={{ justifyContent: 'flex-end', marginBottom: '10px' }}>
                    <div className="d-flex drop-search">
                      <Dropdown
                        isOpen={singlebtn}
                        toggle={() => setSinglebtn(!singlebtn)}
                      >
                        <DropdownToggle className="customer-select text-dark">
                          Filter By{' '}
                          <span className="font-weight-bold">
                            {selectedFilter.label}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {filterTypes.map((filterObj, key) => {
                            return (
                              <DropdownItem
                                key={key}
                                onClick={() => filterByKey(filterObj)}
                              >
                                {filterObj.label}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      {filterByKeyObject.key === 'status'
                        ? (
                          <Dropdown
                            isOpen={isStatusOpen}
                            toggle={() => setIsStatusOpen(!isStatusOpen)}
                          >
                          <DropdownToggle className="customer-select status-dropdown text-dark">
                           {selectStatus}
                            <i className=" ms-2 bx bx-caret-down" />
                          </DropdownToggle>
                          <DropdownMenu className='status-dropdown-menu'>
                            {statusTypesData.map((item) => {
                              return (
                                <DropdownItem
                                  key={item.key}
                                  onClick={() => selectByStatus(item)}
                                >
                                {item.label}
                                </DropdownItem>
                              )
                            })}
                          </DropdownMenu>
                        </Dropdown>)
                        : (<div className="customer-search-form">
                        <input
                          className="form-control input-border customer-input-border"
                          type="text"
                          placeholder="Search"
                          onChange={(e) => searchByText(e.target.value)}
                          value={searchText}
                        />
                        {searchText && (
                          <i
                            className="mdi mdi-close empty-search-icon"
                            onClick={() => handleRemoveSearchWord()}
                          ></i>
                        )}
                        <i className="bx bx-search-alt-2 search-icon"></i>
                      </div>)
                      }
                    </div>
                  </div>
                  <hr className=" mt-0 mb-4 tab-hr"></hr>
                  <TableComponent
                    columns={columns}
                    content={invoices}
                    handleActions={handleActions}
                    paging={paging}
                    paginationHandler={paginationHandler}
                    onClick={handleRow}
                    sorting={handleSort}
                    arrowState={sortingState}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
