import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import PdfReports from 'components/PdfReports/PdfReports'

function ReportsProductsPdfVendor () {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(null)
  const [pdfData, setPdfData] = useState(null)
  const location = useLocation()
  React.useEffect(() => {
    setData(location.state)
  }, [location])

  const columns = [
    { key: 'category', label: 'Categories' },
    { key: 'subcategory', label: 'Sub Categories' },
    { key: 'quantitySold', label: 'Qty Sold' },
    { key: 'totalSold', label: 'Total Sales' }
  ]

  React.useEffect(() => {
    if (data) {
      setPdfData(data.data.map((item) => {
        return {
          category: item.categoryName,
          subcategory: item.subCategoryName,
          quantitySold: item.quantitySold,
          totalSold: item.totalSales
        }
      }))
    }
  }, [data])

  return (
        <>
        {
          pdfData && <PdfReports
          columns={columns}
          content={pdfData}
          />
        }
        </>
  )
}
export default ReportsProductsPdfVendor
