/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import React, { useEffect, useState } from 'react'

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from 'reactstrap'

// redux
import { useSelector, useDispatch } from 'react-redux'

import { withRouter, Link } from 'react-router-dom'

// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

// actions
import { loginUser, socialLogin } from '../../store/actions'

// import images
import profile from 'assets/images/profile-img.png'
import logo from 'assets/images/logo.svg'
import Loader from '../../components/Loader'
// Import config

const VerifyOTP = props => {
  const [otpMessage, setOtpMessage] = useState('')
  const [userName, setUserName] = useState('')
  const dispatch = useDispatch()
  const loginSuccess = useSelector(state => state.Login)

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      props.history.push('/dashboard')
    }
    const loginFormData = JSON.parse(localStorage.loginCreds)
    setUserName(loginFormData.email)
    setOtpMessage('OTP sent to ' + loginFormData.email + ' , ' + loginFormData.otp)
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Please Enter Your OTP')
    }),
    onSubmit: (values) => {
      values.step = 2
      values.userName = userName
      dispatch(loginUser(values, props.history))
    }
  })

  const { error } = useSelector(state => ({
    error: state.Login.error
  }))

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props.history))
  }

  const signIn = (res, type) => {
    if (type === 'google' && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId
      }
      dispatch(socialLogin(postData, props.history, type))
    } else if (type === 'facebook' && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId
      }
      dispatch(socialLogin(postData, props.history, type))
    }
  }

  // handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, 'google')
  }

  // handleTwitterLoginResponse
  // const twitterResponse = e => {}

  // handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, 'facebook')
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Verify OTP | QLS</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Loader show={loginSuccess.loading}/>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Verify OTP</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4" style={{ width: '100%', marginTop: '0px' }}>
                        <span className="avatar-title" style={{ backgroundColor: 'transparent' }}>
                          <img
                            src={logo}
                            alt=""
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      {otpMessage ? <Alert color="success">{otpMessage}</Alert> : null}
                      <div className="mb-3">
                        <Label className="form-label">OTP</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ''}
                          type="text"
                          placeholder="Enter OTP"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            !!(validation.touched.password && validation.errors.password)
                          }
                        />
                        {validation.touched.password && validation.errors.password
                          ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            )
                          : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Verify
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  <Link to="/login" className="fw-medium text-primary">
                    {' '}
                    Login{' '}
                  </Link>{' '}
                </p>
                {/* <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(VerifyOTP)

VerifyOTP.propTypes = {
  history: PropTypes.object
}
