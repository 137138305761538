/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Table, Card, Modal, Button } from 'reactstrap'
import bitmap from '../../../assets/images/Bitmap.png'
import oilFilterImg from '../../../assets/images/placeholderImages/OilFilter.png'
import airFilterImg from '../../../assets/images/placeholderImages/AirFilter.png'
import cabinAirFilterImg from '../../../assets/images/placeholderImages/CabinAirFilter.png'
import fuelFilterImg from '../../../assets/images/placeholderImages/FuelFilter.png'
import transmissionFilterImg from '../../../assets/images/placeholderImages/TransmissionFilter.png'
import drainPlugImg from '../../../assets/images/placeholderImages/drainPlug.png'
import oilCapImg from '../../../assets/images/placeholderImages/oilCap.png'
import wiperBladeImg from '../../../assets/images/placeholderImages/wiperBlade.png'

import './index.scss'
import Loader from '../../../components/Loader'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { GetData, PostData } from 'apiHandler'
import { useParams, Link, useHistory } from 'react-router-dom'
import { formatPhoneNumber, formatDate } from 'commonFunction'
import orderStatusIcon from '../../../assets/images/order-details-status.png'
import paidStatus from '../../../assets/images/paid-status.png'
import warningIcon from '../../../assets/images/warning.png'
import moment from 'moment'
import { getAdminStatusString, getWDStatusString, getShippingStatusString } from '../Common'
import NumberFormat from 'react-number-format'

function OrderDetails() {
  const { id } = useParams()
  const history = useHistory()
  const [loader, setLoader] = useState(false)
  const [orderData, setOrderData] = useState(null)
  const [showNotesModal, setNotesModal] = useState(false)
  const userObj = JSON.parse(localStorage.authUser)
  const [note, setNote] = useState('')
  const [reason, setReason] = useState('')
  const [noteError, setNoteError] = useState('')
  const [rejectError, setRejectError] = useState('')
  const [paymentMethod, setPaymentMethod] = useState({})
  const [orderModal, setOrderModal] = useState(false)
  const [dropDownFlag, setDropDownFlag] = useState(false)
  const [orderStatus, setOrderStatus] = useState('')
  const [readMore, setReadMore] = useState(false)
  const [rejectMore, setRejectMore] = useState(false)
  const [rejectModal, setRejectModal] = useState(false)
  const [vendorOrderProductsData, setVendorOrderProductsData] = useState([])
  const shipmentStatusKeys = ['Pick Ticket', 'Packing Slip', 'Assign Driver', 'Packed', 'Shipped', 'Delivered']
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const [addedNotes, setAddedNotes] = useState([])
  const shipStatus = {
    0: 'Pick Ticket Ready',
    1: 'Packing Slip Ready',
    2: 'Assigned Driver',
    3: 'Packed',
    4: 'Shipped',
    5: 'Delivered'
  }

  const paymentStatus = {
    0: 'Awaiting Payment',
    1: 'Pending',
    2: 'Paid',
    3: 'Failed'
  }

  useEffect(() => {
    getOrderData()
    getAddedNotes()
    if (userObj.roleId === 3 || userObj.roleId === 4) {
      getOrderProductListing()
    }
  }, [])

  useEffect(() => {
    if (dropDownFlag) {
      if (orderStatus !== '') {
        updateStatus(orderStatus)
      }
    }
  }, [dropDownFlag, orderStatus])

  const getOrderProductListing = () => {
    const url = `vendor/vendorOrderProductlisting?orderId=${id}`
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status === true) {
        const products = response.result.vendorOrderProducts
        // eslint-disable-next-line array-callback-return
        products.map((product) => {
          product.qtyToShip = product.orderedQuantity
        })
        response.result.vendorOrderProducts = products
        setVendorOrderProductsData(response.result)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getOrderData = () => {
    setLoader(true)
    let url = `admin/getOrder?vendorOrderId=${id}`

    if (userObj.roleId === 3 || userObj.roleId === 4) {
      url = `vendor/getOrder?vendorOrderId=${id}`
    }

    GetData(url).then((response) => {
      setLoader(false)
      if (response.status === true) {
        if (userObj.roleId === 3 || userObj.roleId === 4) {
          setOrderData(response.result)
        } else {
          setPaymentMethod(response.result.paymentDetail.paymentMethod ? response.result.paymentDetail.paymentMethod : {})
          setOrderData(response.result)
          // setNote(response.result.orderNote)
        }
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const openNotesPopup = (e) => {
    e.preventDefault()
    setNoteError('')
    setNotesModal(true)
    setNote(orderData.orderNote)
  }

  const submitNote = () => {
    if (note === '') {
      setNoteError('Please enter note')
      return
    }
    setLoader(true)
    PostData('vendor/updateOrderNote', { vendorOrderId: parseInt(id), orderNote: note }).then((response) => {
      setLoader(false)
      if (response.status === true) {
        // setNotesModal(false)
        setNote('')
        toastr.success(response.message)
        getAddedNotes()
        // getOrderData()
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const updateStatus = (status) => {
    const url = 'vendor/updateshipmentstatus'
    const body = {
      shipmentId: orderData?.orderShipmentDetail?.shipmentId,
      shipmentStatus: parseInt(status)
    }

    PostData(url, body).then((response) => {
      setLoader(false)
      if (response.status === true) {
        setLoader(false)
        setOrderModal(false)
        toastr.success(response.message)
        getOrderData()
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    }).catch((err) => {
      toastr.error(err, 'Error')
    })
  }

  const handleSelectDropDown = (e) => {
    setOrderStatus(e.target.value)
    setDropDownFlag(true)
  }

  const handleStatusUpdate = (e) => {
    e.preventDefault()
    if (orderStatus === '') {
      toastr.error('Please select atleast one option from dropdown', 'Error')
    } else {
      setLoader(true)
      const url = 'vendor/updateManualOrderStatus'
      const productsArray = []

      // eslint-disable-next-line array-callback-return
      vendorOrderProductsData?.vendorOrderProducts.map((product) => {
        const productObj = {
          productId: product.part_id,
          quantity: product.qtyToShip
        }
        productsArray.push(productObj)
      })

      const body = {
        vendorOrderId: parseInt(id),
        status: parseInt(orderStatus),
        products: productsArray
      }

      PostData(url, body).then((response) => {
        setLoader(false)
        if (response.status === true) {
          setLoader(false)
          setOrderModal(false)
          toastr.success(response.message)
          getOrderData()
        } else {
          setLoader(false)
          toastr.error(response.message, 'Error')
        }
      }).catch((err) => {
        toastr.error(err, 'Error')
      })
    }
  }

  const submitRejection = (e) => {
    e.preventDefault()
    if (reason === '') {
      setRejectError('Please enter note')
      return
    }
    setLoader(true)
    PostData('vendor/rejectOrder', { vendorOrderId: parseInt(id), reason }).then((response) => {
      setLoader(false)
      if (response.status === true) {
        setRejectModal(false)
        toastr.success(response.message)
        getOrderData()
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  const cancelOrder = () => {
    const data = {
      vendorOrderId: id
    }
    setLoader(true)
    PostData('admin/cancelOrder', data).then((response) => {
      if (response.status === true) {
        setLoader(false)
        setIsDisableOpen(false)
        toastr.success(response.message)
        getOrderData()
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleMinusQuantity = (e, index) => {
    if (vendorOrderProductsData?.vendorOrderProducts[index].qtyToShip === 0) {
      toastr.error('Quantity to ship can\'t be less than 0', 'Error')
    } else {
      const productsArr = [...vendorOrderProductsData?.vendorOrderProducts]
      productsArr[index].qtyToShip -= 1
      setVendorOrderProductsData({ ...vendorOrderProductsData, vendorOrderProducts: productsArr })
    }
  }

  const handleAddQuantity = (e, index) => {
    if (vendorOrderProductsData?.vendorOrderProducts[index].qtyToShip < vendorOrderProductsData?.vendorOrderProducts[index].orderedQuantity) {
      const productsArr = [...vendorOrderProductsData?.vendorOrderProducts]
      productsArr[index].qtyToShip += 1
      setVendorOrderProductsData({ ...vendorOrderProductsData, vendorOrderProducts: productsArr })
    } else {
      toastr.error('Quantity to ship can\'t be greater than Quantity Ordered', 'Error')
    }
  }

  const handleQuantity = (e, index) => {
    if (e.target.value < 0) {
      toastr.error('Quantity to ship can\'t be less than 0', 'Error')
    } else if (e.target.value > vendorOrderProductsData?.vendorOrderProducts[index].orderedQuantity) {
      toastr.error('Quantity to ship can\'t be greater than Quantity Ordered', 'Error')
    } else {
      const productsArr = [...vendorOrderProductsData?.vendorOrderProducts]
      productsArr[index].qtyToShip = e.target.value
      setVendorOrderProductsData({ ...vendorOrderProductsData, vendorOrderProducts: productsArr })
    }
  }

  const handleZeroQty = (e) => {
    e.preventDefault()
    setOrderModal(false)
    setRejectModal(true)
  }

  // ------------------------------------------Conditional product image function---------------------------------

  const conditionalProductImg = (productData) => {

    const { productDetail, vendorOrderProductData, vendorProduct
    } = productData

    let brandName
    let categoryName
    let subCategoryName
    if (productDetail) {
      brandName = productDetail?.brands[0].BrandName
      categoryName = productDetail?.partCategoryMapping?.partCategory?.categoryName
      subCategoryName = productDetail?.partCategoryMapping?.partSubCategory?.subCategoryName
    } else if (vendorOrderProductData && vendorProduct) {
      brandName = vendorProduct?.brand
      categoryName = vendorOrderProductData?.partCategoryMapping?.partCategory?.categoryName
      subCategoryName = vendorOrderProductData?.partCategoryMapping?.partSubCategory?.subCategoryName
    }

    if (categoryName === 'Filters' && (brandName === 'Premium Guard' || brandName === 'ECOGARD')) {
      if (subCategoryName === 'Oil Filter') {
        return oilFilterImg
      } else if (subCategoryName === 'Air Filter') {
        return airFilterImg
      } else if (subCategoryName === 'Cabin Air Filter') {
        return cabinAirFilterImg
      } else if (subCategoryName === 'Fuel Filter') {
        return fuelFilterImg
      } else if (subCategoryName === 'Transmission') {
        return transmissionFilterImg
      } else if (subCategoryName === 'Drain Plugs and Gaskets') {
        return drainPlugImg
      } else if (subCategoryName === 'Oil Filter Housing Caps') {
        return oilCapImg
      }
    } else if (categoryName === 'Wiper Blades' && (subCategoryName === 'Wiper Blades' || subCategoryName === 'Rear Wiper Blade') && (brandName === 'Premium Guard' || brandName === 'ECOGARD')) {
      return wiperBladeImg
    }

    return bitmap
  }

  const sortedShipmentHistory = orderData?.shipmentHistory
  ? orderData.shipmentHistory.sort((a, b) => {
      return a.shipmentStatus - b.shipmentStatus
    })
  : []

  // ---------------------function to get added notes--------------
  const getAddedNotes = () => {
    const url = `vendor/getOrderNotes`
    PostData(url, {vendorOrderId: id}).then((response) => {
      // setLoader(false)
      if (response.status === true) {
        setAddedNotes(response?.result)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }
  return (
    <>
      <Loader show={loader} />
      <div className='page-content'>
        <Container fluid={true}>
          <Row>
            <Col lg={8}>
              <div className='d-flex align-items-center'>
                <span className='order-number'><i onClick={() => history.push('/orders')} className="bx bx-arrow-back me-3"></i>{'  '}{orderData?.po_number ? orderData?.po_number : '--'}</span>
                <span className='ms-2 me-2 order-date'>{orderData?.createdAt ? formatDate(orderData?.createdAt) : '--'}</span>
                <span className={`status-${orderData?.orderStatus}`}>{(userObj.roleId === 3 || userObj.roleId === 4) ? getWDStatusString(orderData?.orderStatus) : getAdminStatusString(orderData?.orderStatus)}</span>
              </div>
            </Col>
            {
              (userObj.roleId === 3 || userObj.roleId === 4)
                ? <>
                  {
                    parseInt(orderData?.orderStatus) !== 3 && <>
                      {
                        (orderData?.orderStatus !== 4 && orderData?.orderStatus !== 5) && <Col lg={4} style={{ textAlign: 'right' }}>
                          <button onClick={() => setRejectModal(true)} className="reject-btn me-3">Reject Order</button>
                        </Col>
                      }
                    </>
                  }
                </>
                : <>
                  {
                    (orderData?.orderStatus !== 4 && orderData?.orderStatus !== 5 && orderData?.orderStatus !== 3) && <Col lg={4} style={{ textAlign: 'right' }}>
                      <button type='button' onClick={() => setIsDisableOpen(true)} className="reject-btn me-3">Cancel Order</button>
                    </Col>
                  }
                </>
            }
          </Row>
          {!(userObj.roleId === 3 || userObj.roleId === 4) &&
            orderData?.orderStatus === 4 &&
            <Row className='warning-container'>
              <div className="d-flex error-warning">
                <i className="bx bxs-info-circle me-2"></i>
                <div className='content-area'>
                  <div>
                    <p className="m-0 heading"><b>Warehouse unable to fulfill this order</b></p>
                    <p className='m-0 desc'>
                      {orderData?.rejectReason ? (rejectMore ? orderData?.rejectReason : (orderData?.rejectReason.length > 60 ? `${orderData?.rejectReason.substring(0, 60)}... ` : orderData?.rejectReason)) : 'N/A'}
                      {
                        (orderData?.rejectReason.length > 60 && !rejectMore) && <span className='read-more' onClick={() => setRejectMore(true)}>Read More</span>
                      }
                      {
                        readMore && <span className='read-more' onClick={() => setRejectMore(false)}>Read Less</span>
                      }
                    </p>
                  </div>
                  <div>
                    <button type='button' onClick={() => setIsDisableOpen(true)}>Cancel Order</button>
                  </div>
                </div>
              </div>
            </Row>
          }
          <Row className="mt-3">
            <Col lg={9}>
              <Card>
                <div>
                  <div className='products__table__container'>
                    <div className="extra__div">
                      <img src={orderStatusIcon} alt="order status" />{orderData?.orderNumber ? orderData?.orderNumber : '--'} ({orderData?.orderProducts?.length ? orderData?.orderProducts.length : 0}) {orderData?.orderShipmentDetail?.shipmentStatus !== undefined ? getShippingStatusString(orderData?.orderShipmentDetail?.shipmentStatus) : getShippingStatusString(11)}
                    </div>
                    <div className="products__table__header">
                      <p>Image</p>
                      <p>Part No.</p>
                      <p>Brand</p>
                      <p>Type</p>
                      <p>Price</p>
                      <p>Qty/UOM</p>
                      <p>Total</p>
                    </div>
                    {
                      orderData?.orderProducts.length > 0
                        ? orderData?.orderProducts.map((product, key) => {
                          return (
                            <div key={key} className="product-item-container-updated">
                              <div className="item" id="item-checkbox">
                                <img src={product?.productDetail?.imageMapper?.length ? product.productDetail.imageMapper[0].imageUrl : conditionalProductImg(product)} className="item-product-checkbox" />
                              </div>
                              <div className="item" id="item-image">
                                <Link to={'/products/' + product.productId}>{product?.productDetail?.partNumber}</Link>
                              </div>
                              <div className="item" id="item-part">
                                <div>
                                  <div className="d-flex each__price__text ">
                                    {/* <span className="d-block d-md-none">Part No:</span> */}
                                    <span className="product__partno__text">
                                      {
                                        product?.productDetail?.brands[0]?.BrandName
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="item" id="item-product">
                                <div>
                                  <a className='product-item-dark-text'>
                                    {product?.productDetail?.partCategoryMapping?.partCategory?.categoryName}
                                  </a>
                                </div>
                              </div>
                              <div className="item item-single-price" id="item-brand">
                                <div className="">
                                  <span className="products__upper__description">
                                    {(userObj.roleId === 3 || userObj.roleId === 4) ? '$' + product.cost_price : '$' + product.productPrice}
                                  </span>
                                </div>
                              </div>
                              <div className="item" id="item-quantity">
                                <div className='mobile-qty'>Qty : &nbsp;</div>
                                <div>
                                  {product.quantity}
                                </div>
                              </div>
                              <div className="item" id="item-total-quan">
                                <div className="">
                                  <div className="d-flex flex-column item-total-price">
                                    <span className='mobile-qty'>Total</span>
                                    {
                                      (userObj.roleId === 3 || userObj.roleId === 4)
                                        ? <span className='item-total-amount'>{'$' + (parseInt(product.cost_price) * product.quantity)}</span>
                                        : <span className='item-total-amount'>{'$' + (parseInt(product.productPrice) * product.quantity)}</span>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                        : <div className='data-not-found'>{orderData?.orderProducts?.length === 0 ? 'Data Not Found...' : 'Loading...'}</div>
                    }
                  </div>
                  {
                    userObj.roleId === 3 || userObj.roleId === 4
                      ? orderData?.flowType === null && (orderData?.orderStatus !== 4 && orderData?.orderStatus !== 5) && <div className='select-order-status'>
                        <div>
                          <button onClick={() => setOrderModal(true)} className='shipment-btn'>Change Order Status</button>
                          {/* <div className='description'>Lorem Ipsum is simply dummy Lorem Ipsum is simply dummy</div> */}
                        </div>
                        <div className='center-portion'>
                          <span>OR</span>
                        </div>
                        <div>
                          <button onClick={() => history.push('/orders/process/' + id)} className="cancel-btn me-3">Create Shipment</button>
                          {/* <div className='description'>Lorem Ipsum is simply dummy Lorem Ipsum is simply dummy</div> */}
                        </div>
                      </div>
                      : (orderData?.orderStatus !== 4 && orderData?.orderStatus !== 5) && <div className='order-steps-container'>
                        <div className='heading'>Order Status</div>
                        {
                          shipmentStatusKeys?.length &&
                          <div className='order-steps'>
                            {
                              shipmentStatusKeys.map((shipKey, key) => {
                                const shipment = sortedShipmentHistory[key] || {}
                                return (
                                  <div key={key} className={shipment?.shipmentStatus === key ? `step ${key === 0 ? '' : 'step-line'} step-` + (key + 1) : `step ${key === 0 ? '' : 'step-line'} in-active step-` + (key + 1)}>
                                    <div className='bullet'></div>
                                    <div className='step-desc'>
                                      <p className='desc-heading'>{shipKey}</p>
                                      <p className='desc-date'>{shipment?.createdAt ? moment(shipment?.createdAt).format('Do MMMM YYYY, h:mm A') : ''}</p>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        }
                      </div>
                  }
                  {
                    orderData?.flowType === 1 && (userObj.roleId === 3 || userObj.roleId === 4) && (orderData?.orderStatus !== 4 && orderData?.orderStatus !== 5) && <><div className='order-dropdown p-3'>
                      <div className='order-detils-div'>Order Status</div>
                      <div>
                        <select className='shipment-drop' value={orderData?.orderShipmentDetail?.shipmentStatus === undefined ? 3 : orderData?.orderShipmentDetail?.shipmentStatus} disabled={orderData?.orderShipmentDetail.shipmentStatus === undefined} onChange={handleSelectDropDown}>
                          <option>Select</option>
                          <option disabled={orderData?.orderShipmentDetail?.shipmentStatus === 4 || orderData?.orderShipmentDetail?.shipmentStatus === 5} value={3}>Packed</option>
                          <option disabled={orderData?.orderShipmentDetail?.shipmentStatus === 5} value={4}>Picked Up / Shipped</option>
                          <option value={5}>Delivered</option>
                        </select>
                      </div>
                    </div>
                      {
                        orderData?.orderShipmentDetail?.shipmentStatus === undefined && <p style={{ marginLeft: '15px' }}><b>Note:</b> Payment Status needs to be changed to paid to complete the process.</p>
                      }
                    </>
                  }
                  {
                    orderData?.flowType === 0 && <>
                      {
                        (userObj.roleId === 3 || userObj.roleId === 4) && <>
                          {
                            (orderData?.orderShipmentDetail?.shipmentStatus === 0 || orderData?.orderShipmentDetail?.shipmentStatus === 1 || orderData?.orderShipmentDetail?.shipmentStatus === undefined)
                              ? (orderData?.orderStatus !== 4 && orderData?.orderStatus !== 5) && <div className='p-3'>
                                <div>
                                  <button onClick={() => history.push('/orders/process/' + id + '/' + orderData?.orderShipmentDetail?.shipmentId)} className="cancel-btn me-3" disabled={orderData?.orderShipmentDetail?.shipmentStatus === undefined}>Continue Shipment Process</button>
                                </div>
                                {
                                  orderData?.orderShipmentDetail?.shipmentStatus === undefined && <p style={{ marginTop: '15px' }}><b>Note:</b> Payment Status needs to be changed to paid to complete the process.</p>
                                }
                              </div>
                              : (orderData?.orderStatus !== 4 && orderData?.orderStatus !== 5) && <div className='order-dropdown p-3'>
                                <div className='order-detils-div'>Order Status</div>
                                <div>
                                  <select className='shipment-drop' value={orderData?.orderShipmentDetail?.shipmentStatus} onChange={handleSelectDropDown}>
                                    <option>Select</option>
                                    <option disabled={orderData?.orderShipmentDetail?.shipmentStatus === 4 || orderData?.orderShipmentDetail?.shipmentStatus === 5} value={3}>Packed</option>
                                    <option disabled={orderData?.orderShipmentDetail?.shipmentStatus === 5} value={4}>Picked Up / Shipped</option>
                                    <option value={5}>Delivered</option>
                                  </select>
                                </div>
                              </div>
                          }
                        </>
                      }
                    </>
                  }
                </div>
              </Card>
              {
                orderData?.cancelProductData?.length > 0 &&
                <Card>
                  <div>
                    <div className='products__table__container'>
                      <div className="extra__div">
                        <img src={orderStatusIcon} alt="order status" />{orderData?.orderNumber ? orderData?.orderNumber : '--'} ({orderData?.cancelProductData.length ? orderData?.cancelProductData.length : 0}) {getShippingStatusString(10)}
                      </div>
                      <div className="products__table__header">
                        <p>Image</p>
                        <p>Part No.</p>
                        <p>Brand</p>
                        <p>Type</p>
                        <p>Price</p>
                        <p>Qty/UOM</p>
                        <p>Total</p>
                      </div>
                      {
                        orderData?.cancelProductData?.length > 0
                          // eslint-disable-next-line multiline-ternary
                          ? orderData?.cancelProductData.map((product, key) => {
                            return (
                              <div key={key} className="product-item-container-updated">
                                <div className="item" id="item-checkbox">
                                  <img src={product?.productDetail?.imageMapper?.length ? product.productDetail.imageMapper[0].imageUrl : conditionalProductImg(product)} className="item-product-checkbox" />
                                </div>
                                <div className="item" id="item-image">
                                  <Link to={'/products/' + product.productId}>{product?.productDetail?.partNumber}</Link>
                                </div>
                                <div className="item" id="item-part">
                                  <div>
                                    <div className="d-flex each__price__text ">
                                      {/* <span className="d-block d-md-none">Part No:</span> */}
                                      <span className="product__partno__text">
                                        {
                                          product?.productDetail?.brands[0].BrandName
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="item" id="item-product">
                                  <div>
                                    <a className='product-item-dark-text'>
                                      {product?.productDetail?.partCategoryMapping?.partCategory?.categoryName}
                                    </a>
                                  </div>
                                </div>
                                <div className="item item-single-price" id="item-brand">
                                  <div className="">
                                    <span className="products__upper__description">
                                      {
                                        (userObj.roleId === 3 || userObj.roleId === 4)
                                          ? '$' + product.cost_price
                                          : '$' + product.productPrice
                                      }
                                    </span>
                                  </div>
                                </div>
                                <div className="item" id="item-quantity">
                                  <div className='mobile-qty'>Qty : &nbsp;</div>
                                  <div>
                                    {product?.quantity}
                                  </div>
                                </div>
                                <div className="item" id="item-total-quan">
                                  <div className="">
                                    <div className="d-flex flex-column item-total-price">
                                      <span className='mobile-qty'>Total</span>
                                      <span className='item-total-amount'>{(userObj.roleId === 3 || userObj.roleId === 4)
                                        ? '$' + (parseInt(product?.cost_price) * product?.quantity)
                                        : '$' + (parseInt(product?.productPrice) * product?.quantity)
                                      }</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }) : <div className='data-not-found'>{orderData?.cancelProductData?.length === 0 ? 'Data Not Found...' : 'Loading...'}</div>
                      }
                    </div>
                  </div>
                </Card>
              }

              {!(userObj.roleId === 3 || userObj.roleId === 4) &&
              <>
                <Card className='paid-card'>
                  <div className='paid'>Shipment Information</div>
                  <div className='payment-container'>
                    <div className='payment-box-1'>
                      <div className='payment-row'>
                        <div className='subtotal'>Shipment No. : </div>
                        <div className='paid-items text-capitalize'> {orderData?.orderShipmentDetail?.shipmentNo ? orderData?.orderShipmentDetail?.shipmentNo : '--'}</div>
                      </div>
                      <div className='payment-row'>
                        <div className='subtotal'>Shipment Date : </div>
                        <div className='paid-items ps-2'>{orderData?.orderShipmentDetail?.createdAt ? moment(orderData?.orderShipmentDetail?.createdAt).format('MMM DD, YYYY HH:mm A') : '--'}</div>
                      </div>
                      <div className='payment-row'>
                        <div className='subtotal'>Tracking Number :</div>
                        <div className='paid-items text-capitalize'>{orderData?.orderShipmentDetail?.trackingNumber ? orderData?.orderShipmentDetail?.trackingNumber : '--'}</div>
                      </div>
                      <div className='payment-row'>
                        <div className='subtotal'>Tracking Link :</div>
                        <div className='paid-items'>{orderData?.orderShipmentDetail?.trackingLink ? <a href={orderData?.orderShipmentDetail?.trackingLink} target='_blank'>{orderData?.orderShipmentDetail?.trackingLink}</a> : '--'}</div>
                      </div>
                    </div>
                    <div className='payment-box-2'>
                    <div className='payment-row'>
                        <div className='subtotal'>Carrier Name : </div>
                        <div className='paid-items ps-2'>{orderData?.orderShipmentDetail?.carrierName ? orderData?.orderShipmentDetail?.carrierName : '--'}</div>
                      </div>
                      <div className='payment-row'>
                        <div className='subtotal'>Carrier Code :</div>
                        <div className='paid-items'>{orderData?.orderShipmentDetail?.carrierCode ? orderData?.orderShipmentDetail?.carrierCode  : '--'}</div>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className='paid-card'>
                  <div className='paid'>Payment Information</div>
                  <div className='payment-container'>
                    <div className='payment-box-1'>
                      <div className='payment-row'>
                        <div className='subtotal'>Payment Method : </div>
                        <div className='paid-items text-capitalize'> {paymentMethod.type ? paymentMethod.type : '--'}</div>
                      </div>
                      <div className='payment-row'>
                        <div className='subtotal'>Payment Method ID : </div>
                        <div className='paid-items ps-2'>{paymentMethod.id ? paymentMethod.id : '--'}</div>
                      </div>
                      <div className='payment-row'>
                        <div className='subtotal'>Payment ID : </div>
                        <div className='paid-items ps-2'>{orderData?.paymentIntentData?.id ? orderData?.paymentIntentData?.id : '--'}</div>
                      </div>
                      <div className='payment-row'>
                        <div className='subtotal'>Date and Time :</div>
                        <div className='paid-items'>{orderData?.paymentIntentData?.created ? formatDate(orderData?.paymentIntentData?.created * 1000) : '--'}</div>
                      </div>
                    </div>
                    <div className='payment-box-2'>
                      <div className='payment-row'>
                        <div className='subtotal'>Card Type :</div>
                        <div className='paid-items text-capitalize'>{paymentMethod.card ? paymentMethod.card?.brand : '--'}</div>
                      </div>
                      <div className='payment-row'>
                        <div className='subtotal'>Card Number :</div>
                        <div className='paid-items'>{paymentMethod.card ? '**** **** **** ' + paymentMethod.card?.last4 : '--'}</div>
                      </div>
                    </div>
                  </div>
                </Card>
              </>
              }
              <Card className='paid-card'>
                <div className='paid d-flex align-items-center'>
                  {
                    parseInt(orderData?.paymentStatus) !== 2 ? <><i className='bx bxs-error-circle' style={{ fontSize: '16px', color: '#d8d874' }}></i>&nbsp;&nbsp;</> : <img src={paidStatus} alt="paid" />
                  }
                  {paymentStatus[orderData?.paymentStatus]}
                </div>
                <div className='paid-main-div'>
                  <div className='paid-bill-div'>
                    <div className='paid-subtotal'>
                      <span className='subtotal'>Subtotal</span>
                    </div>
                    <div className='paid-subtotal'>
                      <span className='paid-items'>{orderData?.orderProducts?.length ? orderData?.orderProducts.length : 0} items</span>
                    </div>
                  </div>
                  <div className='paid-subtotal'>
                    <span className='paid-items'>
                      {
                        (userObj.roleId === 3 || userObj.roleId === 4)
                          ? <>
                            ${
                              orderData?.orderProducts.reduce((acc, item) => {
                                acc += (item.quantity * parseFloat(item.cost_price))
                                return acc
                              }, 0).toFixed(2)}
                          </>
                          : <>
                            ${
                              orderData?.orderProducts.reduce((acc, item) => {
                                acc += (item.quantity * parseFloat(item.productPrice))
                                return acc
                              }, 0).toFixed(2)}
                          </>
                      }
                    </span>
                  </div>
                </div>
                <div className='paid-main-div'>
                  <div className='paid-bill-div'>
                    <div className='paid-subtotal'>
                      <span className='subtotal'>Shipping</span>
                    </div>
                  </div>
                  <div className='paid-subtotal'>
                    <span className='paid-items'>
                      {
                         <>
                                <NumberFormat
                                // value={`${
                                //   (order.total !== "" && parseFloat(order.total)) +
                                //   (order.shipping !== "" && parseFloat(order.shipping))
                                // }`}
                                value={orderData?.shipping}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={"$"}
                              />            
                          </>
                      }
                    </span>
                  </div>
                </div>
                <hr />
                <div className='paid-total'>
                  <span className='total-text'>Total</span>
                  <span className='total-text'>
                    {
                      (userObj.roleId === 3 || userObj.roleId === 4)
                        ? <>
                          <NumberFormat
                                value={`${
                                  (orderData?.orderProducts.reduce((acc, item) => {
                                    acc += (item.quantity * parseFloat(item.cost_price))
                                    return acc
                                  }, 0)) +
                                  (orderData?.shipping !== "" && parseFloat(orderData?.shipping))
                                }`}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={"$"}
                              />  
                          {/* ${
                            orderData?.orderProducts.reduce((acc, item) => {
                              acc += (item.quantity * parseFloat(item.cost_price))
                              return acc
                            }, 0).toFixed(2)
                          } */}
                        </>
                        : <>
                            <NumberFormat
                                value={`${
                                  (orderData?.orderProducts.reduce((acc, item) => {
                                    acc += (item.quantity * parseFloat(item.productPrice))
                                    return acc
                                  }, 0)) +
                                  (orderData?.shipping !== "" && parseFloat(orderData?.shipping))
                                }`}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={"$"}
                              />   
                          {/* ${
                            orderData?.orderProducts.reduce((acc, item) => {
                              acc += (item.quantity * parseFloat(item.productPrice))
                              return acc
                            }, 0).toFixed(2)
                          } */}
                        </>
                    }
                  </span>
                </div>
              </Card>
            </Col>
            {
              orderData?.customer &&
              <Col lg={3}>
                <Card className='card-1' style={{ padding: '20px' }}>
                  <div className='d-flex flex-column'>
                    <div className='d-flex flex-row justify-content-between mb-2'>
                      <span className='comman-heading'>Notes</span>
                      {/* {(userObj.roleId === 3 || userObj.roleId === 4) && <a onClick={(e) => openNotesPopup(e)} style={{ color: '#0069a7' }}>Edit</a>} */}
                    </div>
                    <section className='wrapper-notes'>
                        <div className='added-notes'>
                          {
                            addedNotes?.map((note) => (
                              <div key={note?.id}>
                                <div
                                  className={`box-content mb-0`}
                                >
                                  <p className="mb-0">{note?.note}</p>
                                </div>
                                <span className="desc gap-1 d-flex justify-content-between mt-1 mb-2">
                                  {moment(note?.createdAt)?.format('DD/MM/YYYY hh:mm A')}
                                  <p className="desc m-0">by <span className='text-capitalize fw-bold'>{note?.notesAddedBy?.firstName + " " + note?.notesAddedBy?.lastName}</span></p>
                                </span>
                              </div>
                            ))
                          }
                        </div>
                        <div className='add-note'>
                          <div className="form-input">
                            <textarea value={note} onChange={(e) => { setNoteError(''); setNote(e.target.value) }}>{note}</textarea>
                            <div className='error-msg'>{noteError}</div>
                          </div>
                            <button onClick={() => submitNote()} className="add-note-btn">Submit</button>
                        </div>
                      </section>
                      {/* <div className='comman-text read-note d-flex'>
                      {orderData?.orderNote ? (readMore ? orderData?.orderNote : (orderData?.orderNote.length > 60 ? `${orderData?.orderNote.substring(0, 60)}... ` : orderData?.orderNote)) : 'N/A'}
                      {
                        (orderData?.orderNote.length > 60 && !readMore) && <span className='read-more' onClick={() => setReadMore(true)}>Read More</span>
                      }
                      {
                        readMore && <span className='read-more' onClick={() => setReadMore(false)}>Read Less</span>
                      }
                    </div> */}
                  </div>
                </Card>
                {
                  orderData?.customer &&
                  <Card className={'card-1'} style={{ padding: '20px' }}>
                    <div className='comman-heading mb-2'>Customer Contact</div>
                    <div className='d-flex flex-column'>
                      <span className='comman-text'>{orderData?.customer?.companyName ? orderData?.customer?.companyName : ''}</span>
                      <span className='comman-text'>{orderData?.customer?.email ? orderData?.customer?.email : ''}</span>
                      <span className='comman-text'>{orderData?.customer?.mobile ? formatPhoneNumber(orderData?.customer?.mobile) : ''}</span>
                    </div>
                  </Card>
                }
                {/* {
                            orderData?.getOrderData?.parentOrder
                              ?
                              : ''
                          } */}
                <Card className={'card-1'} style={{ padding: '20px' }}>
                  <div className='comman-heading mb-1'>Shipping Address</div>
                  <div className='d-flex flex-column'>
                    {/* <span className='comman-text comman-text-bold'>{orderData?.shippingFirstName + ' ' + orderData?.shippingLastName}</span> */}
                    <span className='comman-text'>{orderData?.shippingAddress1}</span>
                    <span className='comman-text'>{orderData?.shippingStreet}</span>
                    {orderData?.shippingAddress2 ? <span className='comman-text'>{orderData?.shippingAddress2}</span> : ''}
                    <span className='comman-text'>{orderData?.shippingCity + ', ' + orderData?.shippingState}</span>
                    <span className='comman-text'>{orderData?.shippingZipCode}</span>
                    {/* <span className='comman-text'>{formatPhoneNumber(orderData?.shippingPhone)}</span> */}
                  </div>
                </Card>
                {
                  !(userObj.roleId === 3 || userObj.roleId === 4) &&
                  <Card style={{ padding: '20px' }}>
                    <div className='comman-heading mb-1'>WD Details</div>
                    <div className='d-flex flex-column'>
                      <span className='comman-text comman-text-bold'>{orderData?.vendorOrderWarehouseLocationDetails?.locationName ? orderData?.vendorOrderWarehouseLocationDetails?.locationName : '--'}</span>
                      <span className='comman-text'>{orderData?.vendorOrderWarehouseLocationDetails?.cities?.name + ', ' + orderData?.vendorOrderWarehouseLocationDetails?.states?.name + ', ' + orderData?.vendorOrderWarehouseLocationDetails?.countries?.name}</span>
                      <span className='comman-text comman-text-bold' style={{ marginTop: '10px' }}>Warehouse</span>
                      <span className='comman-text'>{orderData?.vendor?.vendorDetails?.companyName ? orderData?.vendor?.vendorDetails?.companyName : ''}</span>
                      <span className='comman-text'>{orderData?.vendor?.vendorDetails?.primaryBillingCity?.name + ', ' + orderData?.vendor?.vendorDetails?.primaryBillingState?.name + ', ' + orderData?.vendor?.vendorDetails?.vendorCountry?.name}</span>
                      <span className='comman-text' style={{ marginTop: '10px' }}>{orderData?.vendor?.firstName + ' ' + orderData?.vendor?.lastName}</span>
                      <span className='comman-text'>{orderData?.vendor?.designation ? orderData?.vendor?.designation : ''}</span>
                      <span className='comman-text'>{orderData?.vendor?.email ? orderData?.vendor?.email : ''}</span>
                      <span className='comman-text'>{orderData?.vendor?.phone ? formatPhoneNumber(orderData?.vendor?.phone) : ''}</span>
                    </div>
                  </Card>
                }
                {
                  Object.keys(orderData?.orderedByDetails).length > 0 &&
                  <Card className={'card-1'} style={{ padding: '20px' }}>
                    <div className='comman-heading mb-2'>Staff Details</div>
                    <div className='d-flex flex-column'>
                      <span className='comman-text'>{orderData?.orderedByDetails?.name ? orderData?.orderedByDetails?.name : ''}</span>
                      <span className='comman-text'>{orderData?.orderedByDetails?.email ? orderData?.orderedByDetails?.email : ''}</span>
                      <span className='comman-text'>{orderData?.orderedByDetails?.mobile ? formatPhoneNumber(orderData?.orderedByDetails?.mobile) : ''}</span>
                    </div>
                  </Card>
                }
              </Col>
            }
          </Row>
        </Container>
        <Modal
          size="md"
          isOpen={showNotesModal}
          toggle={() => {
            setNotesModal(false)
          }}
          centered
        >
          <div className="modal-header border-0">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              <b>Manage Note</b>
            </h5>
            <button
              onClick={() => {
                setNotesModal(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-input">
              <textarea onChange={(e) => { setNoteError(''); setNote(e.target.value) }}>{note}</textarea>
              <div className='error-msg'>{noteError}</div>
            </div>
            <div className="form-input">
              <button onClick={() => submitNote()} className="shipment-btn">Submit</button>
            </div>
          </div>
        </Modal>

        <Modal
          size="md"
          isOpen={rejectModal}
          toggle={() => {
            setRejectModal(false)
          }}
          centered
        >
          <div className="modal-header border-0">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              <b>Reason for rejection</b>
            </h5>
            <button
              onClick={() => {
                setRejectModal(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-input">
              <textarea onChange={(e) => { setRejectError(''); setReason(e.target.value) }}>{reason}</textarea>
              <div className='error-msg'>{noteError}</div>
            </div>
            <div className="form-input">
              <button onClick={(e) => submitRejection(e)} className="shipment-btn">Submit</button>
            </div>
          </div>
        </Modal>

        <Modal
          size="lg"
          isOpen={orderModal}
          toggle={() => {
            setOrderModal(false)
          }}
          centered
        >
          <div className="modal-header border-0">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              <b>Change Order Status</b>
            </h5>
            <button
              onClick={() => {
                setOrderModal(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Col className='details-container' lg={12}>
              <Table bordered>
                <thead>
                  <tr>
                    <th colSpan={1}>Image</th>
                    <th colSpan={1}>Part Number</th>
                    <th colSpan={1}>Brand</th>
                    <th colSpan={1}>Type</th>
                    <th colSpan={1}>Price</th>
                    <th colSpan={1}>Qty/UOM</th>
                    <th colSpan={1}>Total</th>
                    <th colSpan={1} style={{ textAlign: 'center' }}>Fullfilling Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    vendorOrderProductsData?.vendorOrderProducts &&
                    vendorOrderProductsData?.vendorOrderProducts.map((product, key) => {
                      return (
                        <tr key={key}>
                          <td colSpan={1}>
                            <div className="item" id="">
                              <img src={product?.vendorOrderProductData?.imageMapper?.length ? product.vendorOrderProductData.imageMapper[0].imageUrl : conditionalProductImg(product)} className="item-product-checkbox item-product-image" />
                            </div>
                          </td>
                          <td colSpan={1}>{product?.vendorOrderProductData?.partNumber}</td>
                          <td className='brand' colSpan={1}>
                            {
                              product?.vendorProduct?.brand
                            }
                          </td>
                          <td colSpan={1}>{product?.vendorOrderProductData?.partCategoryMapping?.partCategory?.categoryName}</td>
                          <td colSpan={1}>{(userObj.roleId === 3 || userObj.roleId === 4) ? '$' + (product?.cost_price) : '$' + (product?.productPrice)}</td>
                          <td colSpan={1} style={{ textAlign: 'center' }}><p className='qty'>{product?.orderedQuantity}</p>
                            <p className='case'>{'Each'}</p>
                          </td>
                          <td colSpan={1}>{(userObj.roleId === 3 || userObj.roleId === 4) ? '$' + (parseInt(product?.cost_price) * product?.orderedQuantity) : '$' + (parseInt(product?.productPrice) * product?.orderedQuantity)}</td>
                          <td colSpan={1} style={{ textAlign: 'center' }}><div className="quantity__input__container">
                            <i className='bx bx-message-alt-minus products__minus '
                              onClick={(e, index = key) =>
                                handleMinusQuantity(e, index)
                              }
                            ></i>
                            <input
                              min="1"
                              type="number"
                              className="quantity__input all-product-quantity-input"
                              value={product?.qtyToShip}
                              onChange={(e, index = key) =>
                                handleQuantity(e, index)
                              }
                            ></input>
                            <i className='bx bx-plus products__plus'
                              onClick={(e, index = key) =>
                                handleAddQuantity(e, index)
                              }
                            ></i>
                          </div></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </Col>
            <div className='order-footer'>
              <div className='warning'><img src={warningIcon} alt="warning" /><span className='bold'>Disclaimer</span> Loreu ipsum</div>
              <div className='select-order-status on-modal'>
                <div className='order-dropdown'>
                  <div className='order-detils-div'>Order Status</div>
                  <div>
                    <select className='shipment-drop' onChange={(e) => { setOrderStatus(e.target.value) }}>
                      <option>Select</option>
                      <option value={3}>Packed</option>
                      <option value={4}>Picked Up / Shipped</option>
                      <option value={5}>Delivered</option>
                    </select>
                  </div>
                </div>
                <div>
                  <button onClick={() => setOrderModal(false)} className="cancel-btn me-3">Cancel</button>
                  <button className='shipment-btn' onClick={
                    vendorOrderProductsData?.vendorOrderProducts && vendorOrderProductsData?.vendorOrderProducts.reduce((acc, item) => {
                      acc += item.qtyToShip
                      return acc
                    }, 0) === 0
                      ? handleZeroQty
                      : handleStatusUpdate
                  }>Update Status</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal-main-body"
          isOpen={isDisableOpen}
          toggle={() => setIsDisableOpen(false)}
          centered
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={() => {
                setIsDisableOpen(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p className="disable-warehouse">
              Are you sure you want to cancel order?
            </p>
            <div className="modal-detail-div">
              <p className="modal-details">Order Details</p>
              <p className="modal-warehouse-name" style={{ marginTop: '10px' }}>
                <span className="warehouse-heading">
                  {' '}
                  Order Number:{' '}
                </span>{' '}
                {orderData?.orderNumber ? orderData?.orderNumber : '--'}
              </p>
              <p className="modal-warehouse-name">
                <span className="warehouse-heading">
                  {' '}
                  Order Value:{' '}
                </span>{' '}
                ${orderData?.total ? orderData?.total : '0.00'}
              </p>
              <p className="modal-warehouse-name">
                <span className="warehouse-heading">
                  {' '}
                  Customer:{' '}
                </span>{' '}
                {orderData?.customer?.name ? orderData?.customer?.name : 'N/A'}
              </p>
              <p className="modal-warehouse-name">
                <span className="warehouse-heading">
                  {' '}
                  WD:{' '}
                </span>{' '}
                {orderData?.vendor?.vendorDetails?.companyName ? orderData?.vendor?.vendorDetails?.companyName : '--'}
              </p>
            </div>
            <div className="modal-btn-div">
              <button
                className="warehouse-btn-yes"
                onClick={() => cancelOrder()}
              >
                Yes
              </button>
              <button
                className=" warehouse-btn-no"
                onClick={() => setIsDisableOpen(false)}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default OrderDetails
