import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import PdfReports from 'components/PdfReports/PdfReports'
import moment from 'moment'
function ReportsOrderHistoryAdmin () {
  const [data, setData] = useState(null)
  const [pdfData, setPdfData] = useState(null)
  const location = useLocation()
  React.useEffect(() => {
    setData(location.state)
  }, [location])
  const columns = [
    { key: 'po_number', label: 'P.O. Number' },
    { key: 'orderNumber', label: 'Order Number' },
    { key: 'createdAt', label: 'Order Date' },
    { key: 'name', label: 'Name' },
    { key: 'total', label: 'Total' }
  ]
  React.useEffect(() => {
    if (data) {
      setPdfData(data.data.map((item) => {
        return {
          orderNumber: item.orderNumber,
          createdAt: moment(item.createdAt).format('Do MMMM YYYY, h:mm A'),
          name: item.customer.primaryFirstName + ' ' + item.customer.primaryLastName,
          total: item.total,
          po_number: item.po_number,
        }
      }))
    }
  }, [data])
  return (
        <>
        {
          pdfData && <PdfReports
          columns={columns}
          content={pdfData}
          />
        }
        </>
  )
}
export default ReportsOrderHistoryAdmin
