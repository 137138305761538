
// Authentication related pages
import Login from '../pages/Authentication/Login'
import VerifyOtpScreen from '../pages/Authentication/VerifyOtp'

import Logout from '../pages/Authentication/Logout'
import ForgetPwd from '../pages/Authentication/ForgetPassword'

//  // Inner Authentication
import Customers from '../pages/Customer/List'
import Dashboard from '../pages/Dashboard/index'
import UiToasts from '../pages/Ui/UiToast'
import WDList from '../pages/WD/List'
import HomePage from '../pages/Homepage/HomePage'
import KnowledgeBase from 'pages/KnowledgeBase/KnowledgeBase'
import Policies from 'pages/Policies/Policies'
import GlobalSettings from 'pages/GlobalSettings/GlobalSettings'
import AddNewArticle from 'pages/KnowledgeBase/AddNewArticle'
import AddNewCategory from 'pages/KnowledgeBase/AddNewCategory'
import Customer from 'pages/Customer/Customer'
// import ActiveCustomer from "pages/Customer/ActiveCustomer"
// import SetPassword from "pages/Authentication/SetPassword"
import SetPasswordWD from 'pages/Authentication/SetPasswordWD'
import ForgetPasswordWD from 'pages/Authentication/ForgetPasswordWD'
import PasswordSuccessfull from 'pages/Authentication/PasswordSuccessful'
import ActiveCustomer from 'pages/Customer/View'
import WDManagement from 'pages/WD Management/WDManagement'
import NewWDRegistration from 'pages/WD Management/NewWDRegistration'
import NewRegister from 'pages/Authentication/NewRegister'
import ActiveWD from 'pages/WD Management/ActiveWD'
import StaffManagement from 'pages/Staff/StaffManagement'
import StaffInformation from 'pages/Staff/StaffInformation'
import AddStaff from 'pages/Staff/AddStaff'
import WarehouseProfile from 'pages/Warehouse Management/WarehouseProfile'
import WarehouseTable from 'pages/Warehouse Management/WarehouseTable'
import NewCustomerRegistration from 'pages/Customer/NewCustomerRegistration'
import AddWarehouse from 'pages/Warehouse Management/AddWarehouse'
import EditWarehouse from 'pages/Warehouse Management/EditWarehouse'
import DiscountTable from 'pages/Discount/DiscountTable'
import CreateDiscount from '../pages/Discount/CreateDiscount'
import ProductTable from 'pages/Products/ProductTable'
import ProductDetail from 'pages/Products/ProductDetail/ProductDetail'
import InventoryTable from 'pages/Inventory/InventoryTable'
import InventoryPart from 'pages/Inventory/InventoryPart'
import AddProduct from 'pages/Products/AddProduct'
import OrderProcess from '../pages/Orders/Details/Process'
import OrdersTable from 'pages/Orders/OrdersTable'
import OrderDetails from 'pages/Orders/Details/index'
import MyDocument from 'pages/Orders/PdfViewer/PdfViewer'
import Services from 'pages/Services/Services'
import AddNewServices from 'pages/Services/AddNewServices'
import Industry from 'pages/Industry/Industry'
import AddNewIndustry from 'pages/Industry/AddNewIndustry'
import FAQ from 'pages/FAQ/FAQ'
import AddFAQ from 'pages/FAQ/AddFAQ'
import AddTechnical from 'pages/Technical/AddTechnical'
import AddTechnicalLinks from 'pages/Technical/AddTechnicalLinks'
import TechnicalList from 'pages/Technical/TechnicalList'
import InvoicingTable from 'pages/Invoicing/InvoicingTable'
import InvoicingDetails from 'pages/Invoicing/InvoicingDetails'
import ReportSale from 'pages/Reports/ReportSale'
import ReportCustomer from 'pages/Reports/ReportCustomer'
import ReportOrderOpen from 'pages/Reports/ReportOrderOpen'
import ReportProduct from 'pages/Reports/ReportProduct'
import ReportOrderFill from 'pages/Reports/ReportOrderFill'
import ReportsSalesHistoryPdf from 'pages/Reports/ReportsPdf/ReportsSalesHistoryPdfVendor'
import ReportsBillingHistoryPdfVendor from 'pages/Reports/ReportsPdf/ReportsBillingHistoryPdfVendor'
import ReportsSuggestedSalesPdf from 'pages/Reports/ReportsPdf/ReportsSuggestedSalesPdfVendor'
import ReportsProductsPdfVendor from 'pages/Reports/ReportsPdf/ReportsProductsPdfVendor'
import ReportsProductsPdfAdmin from 'pages/Reports/ReportsPdf/ReportsProductsPdfAdmin'
import InvoicingPdf from 'pages/Invoicing/InvoicingPdf'
import InvoicingDetailspdf from 'pages/Invoicing/InvoicingDetailspdf'
import AccountPricing from 'pages/AccountPricing/AccountPricing'
import CategoryMapping from 'pages/Category/Mapping'
import ReportsNewCustomer from 'pages/Reports/ReportsPdf/ReportsNewCustomer'
import ReportsNewCustomerAdmin from 'pages/Reports/ReportsPdf/ReportsNewCustomerAdmin'
import ReportsOrderOpenVendor from 'pages/Reports/ReportsPdf/ReportsOrderOpenVendor'
import ReportsOrderOpenAdmin from 'pages/Reports/ReportsPdf/ReportsOpenOrderAdmin'
import ReportsOrderHistoryAdmin from 'pages/Reports/ReportsPdf/ReportsOrderHistoryAdmin'
import ReportsBillingHistoryAdmin from 'pages/Reports/ReportsPdf/ReportsBillingHistoryAdmin'
import ReportsSuggestedHistoryAdmin from 'pages/Reports/ReportsPdf/ReportsSuggestedOrderAdmin'
import Notifications from 'pages/Notifications/Notifications'
import ReportsOrderFillVendor from 'pages/Reports/ReportsPdf/ReportsOrderFillVendor'
import ReportsOrderFillAdmin from 'pages/Reports/ReportsPdf/ReportsOrderFillAdmin'
import SafetyDataSheet from 'pages/SafetyDataSheet/SafetyDataSheetTable'
import ContactUs from 'pages/ContactUs/ContactUs'
import Invoices from 'pages/Invoices/Invoices'
import InvoicesDetail from 'pages/Invoices/InvoicesDetail'
import InvoicesList from 'pages/Invoices/InvoicesList'
import InvoiceOrder from 'pages/Invoices/InvoiceOrder'
import ProductManagement from 'pages/ProductManagement/ProductManagement'
import ShippingManagement from 'pages/OrderConfigurations/ShippingManagement'
import CartSettings from 'pages/OrderConfigurations/CartSettings'

const authProtectedRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/customers-list', component: Customers },
  { path: '/wd-list', component: WDList },
  { path: '/toast', component: UiToasts },
  { path: '/home-page', component: HomePage },
  { path: '/knowledge-base', component: KnowledgeBase },
  { path: '/policies', component: Policies },
  { path: '/global-settings', component: GlobalSettings },
  { path: '/add-category', component: AddNewCategory },
  { path: '/add-article', component: AddNewArticle },
  { path: '/article/:id', component: AddNewArticle },
  { path: '/category/:id', component: AddNewCategory },
  { path: '/customer', component: Customer },
  { path: '/customer/add-new', component: NewCustomerRegistration },
  { path: '/customer/:id', component: ActiveCustomer },
  { path: '/wd-management', component: WDManagement },
  { path: '/wd-management/add-new', component: NewWDRegistration },
  { path: '/wd-management/:id', component: ActiveWD },
  { path: '/staff-management', component: StaffManagement },
  { path: '/staff-information/:id', component: StaffInformation },
  { path: '/add-staff', component: AddStaff },
  { path: '/warehouse-profile/:id', component: WarehouseProfile },
  { path: '/warehouse-management', component: WarehouseTable },
  { path: '/add-warehouse', component: AddWarehouse },
  { path: '/edit-warehouse/:id', component: EditWarehouse },
  { path: '/discount', component: DiscountTable },
  { path: '/create-discount', component: CreateDiscount },
  { path: '/create-discount/:auto', component: CreateDiscount },
  { path: '/edit-discount/:id', component: CreateDiscount },
  { path: '/products', component: ProductTable },
  { path: '/products/:id', component: ProductDetail },
  { path: '/inventory', component: InventoryTable },
  { path: '/inventory-parts', component: InventoryPart },
  { path: '/add-product', component: AddProduct },
  { path: '/orders', component: OrdersTable },
  { path: '/orders/:id', component: OrderDetails },
  { path: '/orders/process/:id', component: OrderProcess },
  { path: '/orders/process/:id/:shipId', component: OrderProcess },
  { path: '/document/:id/:slip', component: MyDocument },
  { path: '/services', component: Services },
  { path: '/add-services', component: AddNewServices },
  { path: '/services/:id', component: AddNewServices },
  { path: '/industry-links', component: Industry },
  { path: '/invoices', component: Invoices },
  { path: '/invoices/:id', component: InvoicesDetail },
  { path: '/invoices/:customerId/:year/:month', component: InvoicesList },
  { path: '/invoices/order/:id', component: InvoiceOrder },
  { path: '/add-industry-link', component: AddNewIndustry },
  { path: '/industry-links/:id', component: AddNewIndustry },
  { path: '/contact-us', component: ContactUs },
  { path: '/faq', component: FAQ },
  { path: '/technical-bulletins', component: TechnicalList },
  { path: '/add-faq', component: AddFAQ },
  { path: '/add-technical-bulletin', component: AddTechnical },
  { path: '/technical-bulletin/:id', component: AddTechnical },
  { path: '/technical-bulletin/:id/links', component: AddTechnicalLinks },
  { path: '/faq/:id', component: AddFAQ },
  { path: '/invoicing', component: InvoicingTable },
  { path: '/notifications', component: Notifications },
  { path: '/incoicing-details/:id', component: InvoicingDetails },
  { path: '/reports-sales', component: ReportSale },
  { path: '/reports-products', component: ReportProduct },
  { path: '/reports-order', component: ReportCustomer },
  { path: '/reports-order-open', component: ReportOrderOpen },
  { path: '/reports-order-fill', component: ReportOrderFill },
  { path: '/reports-customer', component: ReportCustomer },
  { path: '/invoicing/:id', component: InvoicingDetails },
  { path: '/reports-sales-history', component: ReportsSalesHistoryPdf },
  { path: '/reports-billing-history', component: ReportsBillingHistoryPdfVendor },
  { path: '/reports-suggested-history', component: ReportsSuggestedSalesPdf },
  { path: '/reports-product-type', component: ReportsProductsPdfVendor },
  { path: '/reports-products-types', component: ReportsProductsPdfAdmin },
  { path: '/invoicing-pdf', component: InvoicingPdf },
  { path: '/invoicing-Details-pdf', component: InvoicingDetailspdf },
  { path: '/account-pricing', component: AccountPricing },
  { path: '/category-mapping', component: CategoryMapping },
  { path: '/report-new-customer', component: ReportsNewCustomer },
  { path: '/reports-new-customr', component: ReportsNewCustomerAdmin },
  { path: '/report-open-order-export', component: ReportsOrderOpenVendor },
  { path: '/reports-open-order-export', component: ReportsOrderOpenAdmin },
  { path: '/reports-sales-orderhistory-export', component: ReportsOrderHistoryAdmin },
  { path: '/reports-sales-billinghistory-export', component: ReportsBillingHistoryAdmin },
  { path: '/reports-sales-suggested-export', component: ReportsSuggestedHistoryAdmin },
  { path: '/report-fill-order-export', component: ReportsOrderFillVendor },
  { path: '/report-fill-order-report-export', component: ReportsOrderFillAdmin },
  { path: '/sds', component: SafetyDataSheet },
  { path: '/product-management', component: ProductManagement },
  { path: '/shipping-management', component: ShippingManagement },
  { path: '/cart-settings', component: CartSettings }
]

const publicRoutes = [
  { path: '/', component: Login },
  { path: '/login', component: Login },
  { path: '/logout', component: Logout },
  { path: '/verify-otp', component: VerifyOtpScreen },
  { path: '/forgot-password', component: ForgetPwd },
  // { path: "/set-password", component: SetPassword},
  { path: '/password-successful', component: PasswordSuccessfull },
  { path: '/create-account', component: NewRegister },
  { path: '/verify-user-email/:token', component: SetPasswordWD },
  { path: '/forgotPassword', component: ForgetPasswordWD }
]

export { authProtectedRoutes, publicRoutes }
