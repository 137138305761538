/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Label, Input, Modal } from 'reactstrap'
import PropTypes from 'prop-types'
import { GetData, DeleteData, PutData } from 'apiHandler'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Loader from '../Loader'
import delIcon from '../../assets/images/ic_delete.svg'

const Testimonials = (props) => {
  const [testimonialData, setTestimonialData] = useState([{
    title: null,
    description: null,
    name: null,
    place: null,
    status: 1
  }])
  const [loader, setLoader] = useState(false)
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  // const [putApiFlag, setPutApiFlag] = useState(false)
  const [modalData, setModalData] = useState({ title: null, description: null, name: null, place: null })

  // useEffect(() => {
  //   props.data && setTestimonialData(props.data)
  // }, [props.data])

  useEffect(() => {
    fetchReviews()
  }, [])

  const fetchReviews = () => {
    setLoader(true)
    GetData('admin/reviews').then((response) => {
      if (response.status == true) {
        setLoader(false)
        if (response.result.length) {
          setTestimonialData((prevState) => [])
          for (let i = 0; i < response.result.length; i++) {
            if (i === 3) {
              break
            }
            setTestimonialData((prevState) => [...prevState, response.result[i]])
          }
        }
      }
    })
  }

  function handleChange (e, index) {
    e.preventDefault()
    const arr = [...testimonialData]
    arr[index][e.target.name] = e.target.value
    setTestimonialData(arr)
  }

  function handleSubmit (e) {
    e.preventDefault()
    setLoader(true)
    PutData('admin/reviews', { reviews: testimonialData })
      .then((response) => {
        if (response.status === true) {
          setLoader(false)
          toastr.success(response.message, 'Sent')
        }
      })
      .catch((response) => {
        setLoader(false)
        toastr.error(response.message, 'Error')
      })
  }

  const handleAddTestimonial = () => {
    setTestimonialData((prevState) => {
      return [...prevState, { title: null, description: null, name: null, place: null, status: 1 }]
    })
  }

  const handleDeleteClick = (index) => {
    setIsDisableOpen(true)
    setModalData(testimonialData[index])
    // setTestimonialData((prevState) => {
    //   const newState = [...prevState]
    //   newState.splice(index, 1)
    //   return newState
    // })
  }

  const handleRemove = (index) => {
    setTestimonialData((prevState) => {
      const arr = [...prevState]
      arr.splice(index, 1)
      return arr
    })
  }

  const handleModalDelete = (e, id) => {
    e.preventDefault()
    setLoader(true)
    DeleteData(`admin/review/${id}`)
      .then((response) => {
        if (response.status === true) {
          setLoader(false)
          toastr.success(response.message, 'Sent')
          fetchReviews()
        }
      })
      .catch((response) => {
        setLoader(false)
        toastr.error(response.message, 'Error')
      })
    setIsDisableOpen(false)
  }

  return (
    <React.Fragment>
      <Loader show={loader}></Loader>
      <div className="home-banner-right-wrap">
        <div className="supply-resource-wrap">
          <div className="banner-fields-box">
            {
              testimonialData.map((item, i) => {
                return (
                <div className="sr-title-top" key={i}>
                  <div className='d-flex mb-3' style={{ justifyContent: 'space-between' }}>
                    <h4 style={{ fontSize: '20px', fontWeight: '600' }}>Testimonial {i + 1}</h4>
                    {
                              i === 0 || <img
                              src={delIcon}
                              className="del--icon"
                              style={{ top: '0px', right: '0px' }}
                              onClick={ item.id ? () => handleDeleteClick(i) : () => handleRemove(i) }
                            />
                    }
                  </div>
              <Label htmlFor="formrow-firstname-Input">Title</Label>
              <Input
                type="text"
                className="form-control mb-3"
                id="formrow-firstname-Input"
                placeholder="Enter section title"
                defaultValue={item.title}
                onChange={(e) => handleChange(e, i)}
                name="title"
              />
              <Label htmlFor="formrow-firstname-Input">Description</Label>
              <textarea
                type="text"
                className="form-control mb-3"
                id="formrow-firstname-Input"
                placeholder="Enter section title"
                defaultValue={item.description}
                onChange={(e) => handleChange(e, i)}
                name="description"
                style={{ height: '100px' }}
              />
              <Label htmlFor="formrow-firstname-Input">Person Name</Label>
              <Input
                type="text"
                className="form-control mb-3"
                id="formrow-firstname-Input"
                placeholder="Enter section title"
                defaultValue={item.name}
                onChange={(e) => handleChange(e, i)}
                name="name"
              />
              <Label htmlFor="formrow-firstname-Input">Place</Label>
              <Input
                type="text"
                className="form-control mb-3"
                id="formrow-firstname-Input"
                placeholder="Enter section title"
                defaultValue={item.place}
                onChange={(e) => handleChange(e, i)}
                name="place"
              />
            </div>
                )
              })
            }
          </div>
        </div>
        {
          testimonialData.length < 3 && <div className="mb-3">
          <p style={{ width: '100%' }} className="text-center">
            <button
              style={{
                color: '#4646ea',
                background: 'transparent',
                border: 'unset'
              }}
              onClick={handleAddTestimonial}
            >
              <b>
                <u>Add More Testimonial</u>
              </b>
            </button>
          </p>
        </div>
        }
        <div className="save-btn">
          <button type="submit" onClick={(e) => handleSubmit(e)}>
            Save
          </button>
        </div>
      </div>
      <Modal
            className="modal-main-body"
            isOpen={isDisableOpen}
            toggle={() => setIsDisableOpen(false)}
            centered
          >
            <div className="modal-header">
              <button
                type="button"
                onClick={() => {
                  setIsDisableOpen(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="disable-warehouse">
                Are you sure you want to Delete this Testimonial?
              </p>
              <div className="modal-detail-div">
                <p className="modal-details">Details</p>
                <p className="modal-warehouse-name">
                  <span className="warehouse-heading"> Title: </span>{' '}
                  {modalData?.title}
                </p>
                <p className="modal-warehouse-name">
                  <span className="warehouse-heading"> Description: </span>{' '}
                  {modalData?.description}
                </p>
                <p className="modal-warehouse-name">
                  <span className="warehouse-heading"> Person Name: </span>{' '}
                  {modalData?.name}
                </p>
                <p className="modal-warehouse-name">
                  <span className="warehouse-heading"> Place: </span>{' '}
                  {modalData?.place}
                </p>
              </div>
              <div className="modal-btn-div">
                <button
                  className="warehouse-btn-yes"
                  onClick={(e) => handleModalDelete(e, modalData?.id)}
                >
                  Yes
                </button>
                <button
                  className=" warehouse-btn-no"
                  onClick={() => setIsDisableOpen(false)}
                >
                  No
                </button>
              </div>
            </div>
          </Modal>
    </React.Fragment>
  )
}

export default Testimonials

Testimonials.propTypes = {
  data: PropTypes.object
}
