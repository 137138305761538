/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useContext, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Modal,
  Label,
  FormFeedback,
  Form
} from 'reactstrap'
import { ProfileModalContext } from 'pages/Customer/View'
import { PostData } from '../../apiHandler'
import PropTypes from 'prop-types'
import toastr from 'toastr'
import { useParams } from 'react-router-dom'

// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

export default function ProfileModal (props) {
  const params = useParams()
  const [businessType, setBusinessType] = useState({})
  const [profileData, setProfileData] = useState(null)
  const [changedProfileData, setChangedProfileData] = useState({})
  const [ownershipType, setOwnershipType] = useState({})
  const [chainAffilation, setChainAffilation] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [priceLevels, setPriceLevels] = useState([])
  const [showRejectModal, setShowRejectModal] = useState(false)

  const { profileViewModal, setProfileViewModal, setLoader, getCustomerData } =
    useContext(ProfileModalContext)

  useEffect(() => {
    const commonData = JSON.parse(localStorage.commonData)
    if (commonData.businessType) {
      setBusinessType(commonData.businessType)
    }
    if (commonData.ownershipType) {
      setOwnershipType(commonData.ownershipType)
    }
    if (commonData.chainAffilation) {
      setChainAffilation(commonData.chainAffilation)
    }
    if (commonData.priceLevels) {
      setPriceLevels(commonData.priceLevels)
    }
  }, [])

  useEffect(() => {
    if (
      props.profileData &&
      props?.profileData?.customerChangeRequests?.length == 1
    ) {
      setProfileData(props.profileData)
      setChangedProfileData(props.profileData.customerChangeRequests[0])
    }
  }, [props.profileData])

  function removeBodyCss () {
    document.body.classList.add('no_padding')
  }
  // eslint-disable-next-line camelcase
  function tog_large () {
    // setmodal_large(!modal_large);
    setProfileViewModal(false)
    setShowRejectModal(false)
    removeBodyCss()
  }

  const handleApprove = () => {
    setLoader(true)
    const url =
      'admin/updateCustomerProfileChangeRequest/' + changedProfileData.id
    const body = {
      action: 1,
      reason: ''
    }
    PostData(url, body).then((response) => {
      setLoader(false)
      if (response.status === true) {
        toastr.success(response.message, 'Success')
        setProfileViewModal(false)
        setShowRejectModal(false)
        getCustomerData(params.id)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      reason: ''
    },
    validationSchema: Yup.object({
      reason: Yup.string().required('Please Enter Reason')
    }),
    onSubmit: (values) => {
      setLoader(true)
      const url =
        'admin/updateCustomerProfileChangeRequest/' + changedProfileData.id
      const body = {
        action: 2,
        ...values
      }
      PostData(url, body).then((response) => {
        setLoader(false)
        if (response.status === true) {
          toastr.success(response.message, 'Success')
          setProfileViewModal(false)
          setShowRejectModal(false)
          getCustomerData(params.id)
        } else {
          toastr.error(response.message, 'Error')
        }
      })
    }
  })

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col lg={12}>
            <Modal
              size="lg"
              isOpen={profileViewModal}
              toggle={() => {
                tog_large()
              }}
            >
              <div className="modal-header border-0 modal-new-title ">
                <h5 className="modal-title mt-0" id="myLargeModalLabel">
                  <b> Change Request</b>
                </h5>
                <button
                  onClick={() => {
                    setProfileViewModal(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body location-modal-body new-modal-body">
                <div className="bottom-customer-card-inner-location">
                  <div className="d-flex justify-content-between">
                    <h5 className="section-main-heading-blue">
                      Business Information
                    </h5>
                  </div>
                  <div className="bottom-customer-card-inner-profile">
                    <div className="d-flex justify-content-between">
                      <div style={{ flex: '1' }}>
                        <Label>Company Name</Label>
                        <p>
                          {profileData?.companyName
                            ? (
                                profileData?.companyName ===
                            changedProfileData?.companyName
                                  ? (
                                      profileData?.companyName
                                    )
                                  : (
                              <div className="text-danger">
                                {changedProfileData?.companyName}
                              </div>
                                    )
                              )
                            : (
                                '--'
                              )}
                        </p>
                      </div>
                      <div style={{ flex: '1' }}>
                        <Label>Email Address</Label>
                        <p>{profileData?.email ? profileData.email : '--'} </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div style={{ flex: '1' }}>
                        <Label>Phone Number</Label>
                        <p>{profileData?.mobile ? profileData.mobile : '--'}</p>
                      </div>
                      <div style={{ flex: '1' }}>
                        <Label>Chain Affiliation</Label>
                        <p>
                          {chainAffilation && profileData?.chainAffiliation
                            ? (
                                profileData?.chainAffiliation ===
                            changedProfileData?.chainAffiliation
                                  ? (
                                      profileData?.chainAffiliation === 'other'
                                        ? (
                                <span>
                                  {'Other - ' +
                                    profileData?.chainAffiliationOther}
                                </span>
                                          )
                                        : (
                                            chainAffilation[profileData?.chainAffiliation]
                                          )
                                    )
                                  : (
                              <div className="text-danger">
                                {profileData?.chainAffiliation === 'other'
                                  ? (
                                  <span>
                                    {'Other - ' +
                                      changedProfileData?.chainAffiliationOther}
                                  </span>
                                    )
                                  : (
                                      chainAffilation[
                                        changedProfileData?.chainAffiliation
                                      ]
                                    )}
                              </div>
                                    )
                              )
                            : (
                                '--'
                              )}
                          {/* {chainAffilation &&
                            (profileData?.chainAffiliation ? (
                              profileData?.chainAffiliation === "other" ? (
                                <span>
                                  {"Other - " +
                                    profileData?.chainAffiliationOther}
                                </span>
                              ) : (
                                chainAffilation[profileData?.chainAffiliation]
                              )
                            ) : (
                              "--"
                            ))} */}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div style={{ flex: '1' }}>
                        <Label>Date Company Started</Label>
                        <p>
                          {profileData?.dateCompanyStarted
                            ? (
                                profileData?.dateCompanyStarted ===
                            changedProfileData?.dateCompanyStarted
                                  ? (
                                      profileData?.dateCompanyStarted
                                    )
                                  : (
                              <div className="text-danger">
                                {changedProfileData?.dateCompanyStarted}
                              </div>
                                    )
                              )
                            : (
                                '--'
                              )}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div style={{ flex: '1' }}>
                        <Label className="heading-label mb-3">
                          Ownership Type* (Check One)
                        </Label>
                        {Object.keys(ownershipType).length
                          ? Object.keys(ownershipType).map((k, i) => {
                            return (
                                <div key={i} className="form-check mb-1">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={k}
                                    readOnly={true}
                                    checked={
                                      profileData?.ownershipType === k
                                    }
                                    id={'ownership-' + k}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={'ownership-' + k}
                                  >
                                    {ownershipType[k]}
                                  </label>
                                </div>
                            )
                          })
                          : '--'}
                      </div>
                      <div style={{ flex: '1' }}>
                        <Label className=" heading-label mb-3">
                          Type of Business* (Check One)
                        </Label>
                        {Object.keys(businessType).length
                          ? Object.keys(businessType).map((k, i) => {
                            return (
                                <div key={i} className="form-check mb-1">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    readOnly={true}
                                    value={k}
                                    checked={
                                      profileData?.businessType === k
                                    }
                                    id={'businessType-' + k}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={'businessType-' + k}
                                  >
                                    {businessType[k]}
                                  </label>
                                </div>
                            )
                          })
                          : '--'}
                        {profileData?.businessType === 'other'
                          ? (
                          <p>{'Other - ' + profileData?.businessTypeOther}</p>
                            )
                          : (
                              ''
                            )}
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <h5 className="section-main-heading-blue">
                    Contact Information
                  </h5>
                  <div className="bottom-customer-card-inner-profile">
                    <h5 className="section-inner-heading">Primary / Owner</h5>
                    <div className="d-flex justify-content-between">
                      <div style={{ flex: '1' }}>
                        <Label>First Name</Label>
                        <p>
                          {profileData?.primaryFirstName
                            ? (
                                profileData?.primaryFirstName ===
                            changedProfileData?.primaryFirstName
                                  ? (
                                      profileData?.primaryFirstName
                                    )
                                  : (
                              <div className="text-danger">
                                {changedProfileData?.primaryFirstName}
                              </div>
                                    )
                              )
                            : (
                                '--'
                              )}
                        </p>
                      </div>
                      <div style={{ flex: '1' }}>
                        <Label>Last Name</Label>
                        <p>
                          {profileData?.primaryLastName
                            ? (
                                profileData?.primaryLastName ===
                            changedProfileData?.primaryLastName
                                  ? (
                                      profileData?.primaryLastName
                                    )
                                  : (
                              <div className="text-danger">
                                {changedProfileData?.primaryLastName}
                              </div>
                                    )
                              )
                            : (
                                '--'
                              )}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div style={{ flex: '1' }}>
                        <Label>Email Address</Label>
                        <p>
                          {profileData?.primaryEmail
                            ? (
                                profileData?.primaryEmail ===
                            changedProfileData?.primaryEmail
                                  ? (
                                      profileData?.primaryEmail
                                    )
                                  : (
                              <div className="text-danger">
                                {changedProfileData?.primaryEmail}
                              </div>
                                    )
                              )
                            : (
                                '--'
                              )}
                        </p>
                      </div>
                      <div style={{ flex: '1' }}>
                        <Label>Phone Number</Label>
                        <p>
                          {profileData?.primaryPhone
                            ? (
                                profileData?.primaryPhone ===
                            changedProfileData?.primaryPhone
                                  ? (
                                      profileData?.primaryPhone
                                    )
                                  : (
                              <div className="text-danger">
                                {changedProfileData?.primaryPhone}
                              </div>
                                    )
                              )
                            : (
                                '--'
                              )}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div style={{ flex: '1' }}>
                        <Label>Title</Label>
                        <p>
                          {profileData?.primaryTitle
                            ? (
                                profileData?.primaryTitle ===
                            changedProfileData?.primaryTitle
                                  ? (
                                      profileData?.primaryTitle
                                    )
                                  : (
                              <div className="text-danger">
                                {changedProfileData?.primaryTitle}
                              </div>
                                    )
                              )
                            : (
                                '--'
                              )}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="bottom-customer-card-inner-profile">
                    <h5 className="section-inner-heading">Alternate</h5>
                    <div className="d-flex justify-content-between">
                      <div style={{ flex: '1' }}>
                        <Label>First Name</Label>
                        <p>
                          {profileData?.alternateFirstName
                            ? (
                                profileData?.alternateFirstName ===
                            changedProfileData?.alternateFirstName
                                  ? (
                                      profileData?.alternateFirstName
                                    )
                                  : (
                              <div className="text-danger">
                                {changedProfileData?.alternateFirstName}
                              </div>
                                    )
                              )
                            : (
                                '--'
                              )}
                        </p>
                      </div>
                      <div style={{ flex: '1' }}>
                        <Label>Last Name</Label>
                        <p>
                          {profileData?.alternateLastName
                            ? (
                                profileData?.alternateLastName ===
                            changedProfileData?.alternateLastName
                                  ? (
                                      profileData?.alternateLastName
                                    )
                                  : (
                              <div className="text-danger">
                                {changedProfileData?.alternateLastName}
                              </div>
                                    )
                              )
                            : (
                                '--'
                              )}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div style={{ flex: '1' }}>
                        <Label>Email Address</Label>
                        <p>
                          {profileData?.alternateEmail
                            ? (
                                profileData?.alternateEmail ===
                            changedProfileData?.alternateEmail
                                  ? (
                                      profileData?.alternateEmail
                                    )
                                  : (
                              <div className="text-danger">
                                {changedProfileData?.alternateEmail}
                              </div>
                                    )
                              )
                            : (
                                '--'
                              )}
                        </p>
                      </div>
                      <div style={{ flex: '1' }}>
                        <Label>Phone Number</Label>
                        <p>
                          {profileData?.alternatePhone
                            ? (
                                profileData?.alternatePhone ===
                            changedProfileData?.alternatePhone
                                  ? (
                                      profileData?.alternatePhone
                                    )
                                  : (
                              <div className="text-danger">
                                {changedProfileData?.alternatePhone}
                              </div>
                                    )
                              )
                            : (
                                '--'
                              )}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div style={{ flex: '1' }}>
                        <Label>Title</Label>
                        <p>
                          {profileData?.alternateTitle
                            ? (
                                profileData?.alternateTitle ===
                            changedProfileData?.alternateTitle
                                  ? (
                                      profileData?.alternateTitle
                                    )
                                  : (
                              <div className="text-danger">
                                {changedProfileData?.alternateTitle}
                              </div>
                                    )
                              )
                            : (
                                '--'
                              )}
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <hr></hr>
                  <h5 className="section-main-heading-blue">Tax Information</h5>
                  <div className="bottom-customer-card-inner-profile">
                    <div className="d-flex justify-content-between">
                      <div style={{ flex: '1' }}>
                        <Label>Resale Tax Number</Label>
                        <p>
                          {profileData?.reSaleTaxNumber
                            ? (
                                profileData?.reSaleTaxNumber ===
                            changedProfileData?.reSaleTaxNumber
                                  ? (
                                      profileData?.reSaleTaxNumber
                                    )
                                  : (
                              <div className="text-danger">
                                {changedProfileData?.reSaleTaxNumber}
                              </div>
                                    )
                              )
                            : (
                                '--'
                              )}
                        </p>
                      </div>
                      <div style={{ flex: '1' }}>
                        <Label>Upload Document</Label>
                        {profileData &&
                          (profileData?.customerDocuments.length == 0 &&
                          changedProfileData?.documentFile == null
                            ? (
                            <p>--</p>
                              )
                            : profileData?.customerDocuments[0]
                              ? (
                                  profileData?.customerDocuments[0]?.name ===
                            changedProfileData?.documentFile
                                    ? (
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                className="text-dark"
                                href={
                                  profileData?.customerDocuments[0].documentUrl
                                }
                              >
                                <b> {profileData?.customerDocuments[0].name == null ? <p>--</p> : profileData?.customerDocuments[0].name}</b>
                              </a>
                                      )
                                    : (
                              <div className="text-danger">
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={changedProfileData?.documentUrl}
                                >
                                  <b> {changedProfileData?.documentFile == null ? <p>--</p> : changedProfileData?.documentFile}</b>
                                </a>
                              </div>
                                      )
                                )
                              : (
                            <div className="text-danger">
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={changedProfileData?.documentUrl}
                              >
                                <b> {changedProfileData?.documentFile}</b>
                              </a>
                            </div>
                                ))}
                        {/* {profileData.documentFile ? (
                          <p>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={profileData.documentUrl}
                            >
                              {profileData.documentFile}
                            </a>
                          </p>
                        ) : (
                          <p>--</p>
                        )} */}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div style={{ flex: '1' }}>
                        <Label>Price Group</Label>
                        <p>
                          {profileData?.priceLevels
                            ? profileData.priceLevels?.name
                            : '--'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 mb-3 text-center">
                  <button
                    onClick={() => setShowRejectModal(true)}
                    className="reject-btn"
                  >
                    Reject
                  </button>
                  <button
                    onClick={() => handleApprove()}
                    className="approve-btn"
                  >
                    Approve
                  </button>
                </div>
              </div>
            </Modal>
            <Modal
              size="md"
              isOpen={showRejectModal}
              toggle={() => {
                tog_large()
              }}
              centered
            >
              <div className="modal-header border-0">
                <button
                  onClick={() => {
                    setShowRejectModal(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body rejected-modal-container">
                <p className="reject-modal-heading-red">
                  Are you sure you want to reject customer change request?
                </p>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <p className="reject-modal-heading-black">
                    Rejection Reason*
                  </p>
                  <textarea
                    name="reason"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.reason || ''}
                    invalid={
                      !!(validation.touched.reason && validation.errors.reason)
                    }
                  ></textarea>
                  {validation.touched.reason && validation.errors.reason
                    ? (
                    <FormFeedback type="invalid" style={{ display: 'block' }}>
                      {validation.errors.reason}
                    </FormFeedback>
                      )
                    : null}
                  <div className="text-center mt-4 mb-2">
                    <button
                      className="reject-no-btn me-3"
                      type="cancel"
                      onClick={() => {
                        setShowRejectModal(false)
                      }}
                    >
                      No
                    </button>
                    <button className="reject-submit-btn" type="submit">
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  )
}
ProfileModal.propTypes = {
  profileData: PropTypes.object,
  saveProfile: PropTypes.func
}
