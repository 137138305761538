/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import 'react-datepicker/dist/react-datepicker.css'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from 'reactstrap'
// import classnames from 'classnames'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import TableComponent from '../../components/Table'
import NumberFormat from 'react-number-format'
// import moment from 'moment'

import { GetData, ToesterSettings } from '../../apiHandler'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'

const columns = [
  { key: 'partNumber', label: 'Part No.', sorting: false },
  { key: 'PartTerminologyName', label: 'Product Type', sorting: false },
  { key: 'brandDesc', label: 'Brand/Description', sorting: false },
  { key: 'sell_price', label: 'Price', sorting: false },
  { key: 'orderedQuantity', label: 'Quantity', sorting: false },
  { key: 'total', label: 'Total', sorting: false }
]

export default function InvoiceOrder () {
  toastr.options = ToesterSettings
  const history = useHistory()
  const { id } = useParams()
  const [loader, setLoader] = useState(false)
  const [invoices, setInvoices] = useState([])
  const [orderInfo, setOrderInfo] = useState(null)
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })

  useEffect(() => {
    getInvoices()
    return () => {
    }
  }, [])

  const getInvoices = (p = 1) => {
    const pageSize = 20
    const url = `admin/orderInfo/${id}`
    setLoader(true)
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status === true) {
        setOrderInfo(response.result.orderDetails)
        if (response?.result?.orderDetails?.orderProducts?.length) {
          setInvoices(response.result.orderDetails.orderProducts.map((data) => {
            const myObj = {
              partNumber: data.partnumberinfo.partNumber || '--',
              PartTerminologyName: data.partnumberinfo.PartTerminology.PartTerminologyName || '--',
              brandDesc: data.partnumberinfo.brands[0].BrandName || '--',
              sell_price: data.partnumberinfo.price.sell_price
                ? <NumberFormat
              value={data.partnumberinfo.price.sell_price}
              displayType={'text'}
              thousandSeparator={true}
              valueIsNumericString={true}
              decimalScale={2}
              prefix={'$'}
            />
                : '--',
              orderedQuantity: data.orderedQuantity || '--',
              total: (data.partnumberinfo.price.sell_price && data.orderedQuantity)
                ? <NumberFormat
              value={(data.partnumberinfo.price.sell_price * data.orderedQuantity)}
              displayType={'text'}
              thousandSeparator={true}
              valueIsNumericString={true}
              decimalScale={2}
              prefix={'$'}
            />
                : '--'
            }
            return myObj
          }))

          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: response.result.total
          })
        } else {
          setInvoices([])
          setPaging({
            currentPage: 1,
            recordPerPage: pageSize,
            totalRecord: 0
          })
        }
      } else {
        setInvoices([])
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
        toastr.error(response.message, 'Error')
      }
    })
  }

  // const filterData = (key, type) => {
  //   setTab(key)
  //   filterByStatus = type
  //   // if (type === 'pending') {
  //   //   setInvoiceStatus(0)
  //   // } else if (type === 'approved') {
  //   //   setInvoiceStatus(2)
  //   // } else if (type === 'rejected') {
  //   //   setInvoiceStatus(3)
  //   // } else if (type === 'active') {
  //   //   setInvoiceStatus(1)
  //   // } else if (type === 'disabled') {
  //   //   setInvoiceStatus(4)
  //   // } else {
  //   //   setInvoiceStatus(null)
  //   // }
  //   getInvoices(1)
  // }

  const paginationHandler = (page) => {
    getInvoices(page)
  }

  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn == column) {
      if (sortingState.order == 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }

  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getInvoices(1)
  }, [sortingState])

  // function handleExport (e) {
  //   e.preventDefault()
  //   PostData('admin/export/invoicedata').then(
  //     (response) => {
  //       if (response.status == true) {
  // const a = document.createElement('a')
  // a.href = 'https://' + response.result
  // a.download = 'download'
  // a.click()
  //       }
  //     }
  //   )
  // }
  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <MetaTags>
          <title>QLS Admin | Open Account Invoices</title>
        </MetaTags>
        <Container fluid={true} className="customer-container">
          <div className="breadcrumb-main-nav">
          <Breadcrumbs
              title="UI Elements"
              breadcrumbItem={'Order Details'}
              icon={
                <i
                  className="bx bx-arrow-back me-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.goBack()}
                ></i>
              }
            />
              {/* <button
                className="bg-white export-btn me-2"
                onClick={(e) => {
                  handleExport(e)
                }}
              >
                Export
              </button> */}
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="warehouse-navbar" style={{ marginBottom: '10px' }}>
                    {/* {
                      (userObj.roleId === 1 || userObj.roleId === 2)
                        ? <Nav pills className="wd-bottom">
                      <NavItem className="nav-links-hover">
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '1'
                          })}
                          onClick={() => filterData('1', 'all')}
                        >
                          All
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '2'
                          })}
                          onClick={() => filterData('2', 'pending')}
                          id="pending"
                        >
                          New/Pending
                          <UncontrolledTooltip
                          placement="top"
                          target={'pending'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            <div>New: Customer application received.</div>
                            <div>Pending: QLS Admin to approve account.</div>
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '4'
                          })}
                          onClick={() => filterData('4', 'approved')}
                          id="approved"
                        >
                          Approved
                          <UncontrolledTooltip
                          placement="top"
                          target={'approved'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            Customer account has been approved. Email confirmation sent to customer to create password.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '3'
                          })}
                          onClick={() => filterData('3', 'active')}
                          id="active"
                        >
                          Active
                          <UncontrolledTooltip
                          placement="top"
                          target={'active'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            Customer has created their password and logged into their account.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '5'
                          })}
                          onClick={() => filterData('5', 'rejected')}
                          id="rejected"
                        >
                          Rejected
                          <UncontrolledTooltip
                          placement="top"
                          target={'rejected'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            Customer account not approved.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '6'
                          })}
                          onClick={() => filterData('6', 'disabled')}
                          id="disabled"
                        >
                          Disabled
                           <UncontrolledTooltip
                          placement="top"
                          target={'disabled'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            Customer account has been permanently disabled. Account cannot be reinstated.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                    </Nav>
                        : ''
                    } */}
                  </div>
                  {
                    orderInfo && <Row>
                    <Col lg={6}>
                      <div>
                        <p>P.O. No: <b>{orderInfo.po_number ? orderInfo?.po_number : '--'}</b></p>
                        <p>Order No: <b>{orderInfo.orderNumber || '--'}</b></p>
                        <p>Ordered On: <b>{orderInfo.orderDate ? moment(orderInfo.orderDate).format('MM-DD-YYY') : '--'}</b></p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                          <p style={{ textAlign: 'right' }}>Order Total: <b>{orderInfo.total || '--'}</b></p>
                      </div>
                    </Col>
                  </Row>
                  }
                  <TableComponent
                    columns={columns}
                    content={invoices}
                    paging={paging}
                    paginationHandler={paginationHandler}
                    sorting={handleSort}
                    arrowState={sortingState}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
