/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledTooltip
} from 'reactstrap'
import classnames from 'classnames'
import BulkUploadModal from 'components/Common/BulkUploadModal/BulkUploadModal'
import { GetData, PostData } from 'apiHandler'
import TableComponent from 'components/Table'
import { useHistory, Link } from 'react-router-dom'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import moment from 'moment'
import 'toastr/build/toastr.min.css'
import './OrdersTable.scss'
import { getWDStatusString, getAdminStatusString } from './Common'

let searchTimer
let filterByKeyObject = { key: 'all' }

const OrdersTable = () => {
  const history = useHistory()
  const [searchText, setSearchText] = useState('')
  const [singlebtn, setSinglebtn] = useState(false)
  const [tab, setTab] = useState('1')
  const [orders, setOrders] = useState([])
  const [loader, setLoader] = useState(false)
  const [filterTypes, setFilterTypes] = useState([])
  const [exportState, setExportState] = useState([])
  const [bulkError, setBulkError] = useState('')
  const [status, setStatus] = useState('')
  const [filterByStatus, setFilterByStatus] = useState('all')
  const userObj = JSON.parse(localStorage.authUser)
  const [allTotals, setAllTotals] = useState([])
  const [locId, setLocId] = useState()
  const [locationdata, setLocationData] = useState()
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  const [selectedFilter, setSelectedFilter] = useState({
    value: 'all',
    key: 'All'
  })
  const [columns, setColumns] = useState([
    { key: 'po_number', label: 'P.O. Number' },
    { key: 'orderNumber', label: 'Order', sorting: true },
    { key: 'createdAt', label: 'Order Date', sorting: true },
    { key: 'customerName', label: 'Customer Name', sorting: true },
    { key: 'WDName', label: 'WD Name', sorting: true },
    { key: 'orderStatus', label: 'Order Status', sorting: true },
    { key: 'items', label: 'Items' }
  ])

  useEffect(() => {
    return () => {
      setTab('1')
      filterByKeyObject = { key: 'all' }
    }
  }, [])
  useEffect(() => {
    getLocation()
  }, [])
  // if (setLocId) {
  //   console.log('33w2', locId)
  // }
  // const getLocation = () => {
  //   if (userObj.roleId === 3 || userObj.roleId === 2) {
  //     const url = 'vendor/getWarehouseLocationsWithoutPagination'
  //     GetData(url).then((response) => {
  //       if (response.status === true) {
  //         setLocationData(response.result)
  //       }
  //     })
  //   }
  // }

  const getLocation = () => {
    if (userObj.roleId === 3 || userObj.roleId === 4) {
      const url = 'vendor/getWarehouseLocationsWithoutPagination'
      GetData(url).then((response) => {
        if (response.status === true) {
          setLocationData(response.result)
        }
      })
    }
  }
  function newLocationID(e) {
    setLocId(e.target.value)
  }

  const statusObj = {
    new: 0,
    inProgress: 1,
    inTransit: 2,
    delivered: 3,
    rejected: 4,
    cancelled: 5
  }

  useEffect(() => {
    getOrders(1)
  }, [filterByStatus, locId])

  const navHandle = (key, status) => e => {
    e.preventDefault()
    setTab(key)
    if (status === 'new') {
      setStatus(0)
    } else if (status === 'inProgress') {
      setStatus(1)
    } else if (status === 'inTransit') {
      setStatus(2)
    } else if (status === 'delivered') {
      setStatus(3)
    } else if (status === 'rejected') {
      setStatus(4)
    } else if (status === 'cancelled') {
      setStatus(5)
    } else {
      setStatus('')
    }

    setFilterByStatus(status)
  }

  const dropToggle = () => {
    setSinglebtn(!singlebtn)
  }

  const handleActions = (key, data) => {
    if (key === 'view') {
      history.push('/orders/' + data.id)
    }
  }

  const handleRow = (data) => {
    history.push('/orders/' + data.id)
  }

  const paginationHandler = (page) => {
    getOrders(page)
  }

  useEffect(() => {
    if (!bulkError) {
      setBulkError('')
    }
  }, [bulkError])
  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn === column) {
      if (sortingState.order === 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }

  const filterByKey = (obj) => {
    filterByKeyObject.key = obj.value
    setSelectedFilter(obj)
    if (filterByKeyObject.string) {
      getOrders(1)
    }
  }

  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getOrders(paging.currentPage)
  }, [sortingState])

  const updateSellPrice = (e, data, price, page) => {
    e.preventDefault()
    const body = {
      part_id: data.id,
      sell_price: price
    }
    setLoader(true)
    PostData('admin/updateSellPrice', body).then((response) => {
      if (response.status === true) {
        setLoader(false)
        getOrders(page)
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getItemList = (products, i) => {
    return <div className='info-icon'><div className='d-flex align-items-center'><span>{`${products.length} Item (+${products.reduce((acc, item) => {
      acc += item.orderedQuantity
      return acc
    }, 0)
      })`}</span>{' '}<i className='bx bxs-down-arrow'></i>
    </div>
      <div className="tooltiptext1 arrow">
        <div className='tooltipdata'>
          <span className='part--id'>Part No.</span>
          <span className='part--id'>QTY</span>
        </div>
        <div className='tooltip-innerdata'>
          {
            products.map((product, key) => {
              return (
                <div className='partid-qty-div' key={key}>
                  <span>{product?.vendorOrderProductData?.partNumber ? product?.vendorOrderProductData?.partNumber : '--'}</span>
                  <span>{product?.orderedQuantity ? product?.orderedQuantity : '--'}</span>
                </div>
              )
            })
          }
        </div>
      </div></div>
  }

  const getOrders = (p = 1) => {
    setLoader(true)
    const pageSize = 20
    let url = 'admin/getWDOrders?page=' + p + '&pageSize=' + pageSize

    if (filterByStatus === 'all') {
      url = 'admin/getWDOrders?page=' + p + '&pageSize=' + pageSize
    } else {
      url = 'admin/getWDOrders?page=' + p + '&pageSize=' + pageSize + '&orderStatus=' + statusObj[filterByStatus]
    }

    if (userObj.roleId === 3 || userObj.roleId === 4) {
      if (filterByStatus === 'all') {
        url = 'vendor/getOrderlist?page=' + p + '&pageSize=' + pageSize
      } else {
        url = 'vendor/getOrderlist?page=' + p + '&pageSize=' + pageSize + '&orderStatus=' + statusObj[filterByStatus]
      }
      setColumns([
        { key: 'po_number', label: 'P.O. Number' },
        { key: 'orderNumber', label: 'Order', sorting: true },
        { key: 'createdAt', label: 'Order Date', sorting: true },
        { key: 'customerName', label: 'Customer Name', sorting: true },
        { key: 'orderStatus', label: 'Order Status', sorting: true },
        { key: 'location', label: 'Warehouse' },
        { key: 'items', label: 'Items' }
      ])
    }
    if (filterByKeyObject.string) {
      url +=
        '&filterBy=' +
        filterByKeyObject.key +
        '&search=' +
        filterByKeyObject.string
    }
    if (sortingState.sortColumn != null) {
      url +=
        '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order.toUpperCase()
    }
    if (locId) {
      url +=
        '&locationId=' + locId
    }
    GetData(url).then((response) => {
      if (response.status === true || response.status === 200) {
        setFilterTypes(response.result.filterTitle)
        setAllTotals(response.result.totalStatusCounts)
        setLoader(false)
        setExportState(response.result.orders.map((order) => {
          return order.parentOrder.orderNumber
        }))
        if (userObj.roleId === 3 || userObj.roleId === 4) {
          setOrders(response.result.orders.map((order, index) => {
            const loc = <div> <b> {order.vendorOrderWarehouseLocationDetails.locationName + ', ' + order.vendorOrderWarehouseLocationDetails.cities.name} </b> <div>{order.vendorOrderWarehouseLocationDetails.states.name} </div> </div>
            return {
              id: order.vendorOrderId,
              orderNumber: order.parentOrder.orderNumber,
              createdAt: moment(order.createdAt).format('Do MMMM YYYY, h:mm A'),
              customerName:  order?.parentOrder?.customer?.companyName,
              orderStatus: getWDStatusString(order.orderStatus),
              location: loc,
              items: getItemList(order.vendorOrderProducts, index),
              po_number: order?.parentOrder?.po_number,
            }
          }))
        } else {
          setOrders(response.result.orders.map((order, index) => {
            return {
              id: order.vendorOrderId,
              orderNumber: order.parentOrder.orderNumber,
              createdAt: moment(order.createdAt).format('Do MMMM YYYY, h:mm A'),
              customerName: order?.parentOrder?.customer?.companyName,
              orderStatus: getAdminStatusString(order.orderStatus),
              items: getItemList(order.vendorOrderProducts, index),
              WDName: order?.orderVendorAddDetails?.companyName,
              po_number: order?.parentOrder?.po_number,
            }
          }))
        }
        setPaging({
          currentPage: p,
          recordPerPage: pageSize,
          totalRecord: response.result.total
        })
      } else {
        setLoader(false)
        setOrders([])
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
        toastr.error(response.message, 'Error')
      }
    })
  }

  const searchByText = (t) => {
    setSearchText(t)
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      filterByKeyObject.string = t
      getOrders(1)
    }, 2000)
  }

  function handleExport(e) {
    e.preventDefault()
    setLoader(true)

    let url = 'admin/exportWDOrders'

    if (userObj.roleId === 3 || userObj.roleId === 4) {
      url = 'vendor/exportOrderList'
    }

    if (status) {
      url += '?orderStatus=' + status
    }

    GetData(url).then(
      (response) => {
        if (response.status === true) {
          setLoader(false)
          const a = document.createElement('a')
          a.href = 'https://' + response.result
          a.download = 'download'
          a.click()
        } else {
          setLoader(false)
          toastr.error(response.message, 'Error')
        }
      }
    )
  }
  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <Container fluid={true} className="customer-container">
          <div className="products-nav">
            <span className="products-heading">Orders</span>
            <div className='d-flex'>
              {/* {(userObj.roleId === 3 || userObj.roleId === 4)
                ? <BulkUploadModal modalType='Bulk Products Upload' productsBulkUpload={productsBulkUpload} bulkError={bulkError} bulkErrorSheet={bulkErrorSheet} sheetUrl={sheetUrl}/>
                : <BulkUploadModal modalType='Import Products Detail' importSellingPrice={importSellingPrice} bulkError={bulkError} bulkErrorSheet={bulkErrorSheet} sheetUrl={sheetUrlAdmin}/>} */}
              <button className="products-nav-btn products-export-btn" onClick={handleExport}>
                Export
              </button>
            </div>
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="warehouse-navbar">
                    <Nav>
                      <NavItem className='navItems'>
                        <NavLink
                          style={{ padding: '10px' }}
                          className={classnames({
                            active: tab === '1'
                          })}
                          onClick={navHandle('1', 'all')}
                        >
                          All ({allTotals.length > 0 && allTotals.reduce((sum, total) => sum + total.count, 0)})
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ padding: '10px' }}
                          className={classnames({
                            active: tab === '2'
                          })}
                          onClick={navHandle('2', 'new')}
                          id="new"
                        >
                          New ({allTotals.length > 0 && (allTotals.filter((total) => {
                            return total.orderStatus === 0
                          })[0]?.count || 0)})
                          <UncontrolledTooltip
                            placement="top"
                            target={'new'}
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              boxShadow:
                                '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                            }}>
                            New order received.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ padding: '10px' }}
                          className={classnames({
                            active: tab === '3'
                          })}
                          onClick={navHandle('3', 'inProgress')}
                          id="inProgress"
                        >
                          In progress ({allTotals.length > 0 && (allTotals.filter((total) => {
                            return total.orderStatus === 1
                          })[0]?.count || 0)})
                          <UncontrolledTooltip
                            placement="top"
                            target={'inProgress'}
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              boxShadow:
                                '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                            }}>
                            The order fulfillment process has started.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ padding: '10px' }}
                          className={classnames({
                            active: tab === '4'
                          })}
                          onClick={navHandle('4', 'inTransit')}
                          id="inTransit"
                        >
                          In transit ({allTotals.length > 0 && (allTotals.filter((total) => {
                            return total.orderStatus === 2
                          })[0]?.count || 0)})
                          <UncontrolledTooltip
                            placement="top"
                            target={'inTransit'}
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              boxShadow:
                                '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                            }}>
                            The order is out for delivery or is being shipped.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ padding: '10px' }}
                          className={classnames({
                            active: tab === '5'
                          })}
                          onClick={navHandle('5', 'delivered')}
                          id="delivered"
                        >
                          Delivered ({allTotals.length > 0 && (allTotals.filter((total) => {
                            return total.orderStatus === 3
                          })[0]?.count || 0)})
                          <UncontrolledTooltip
                            placement="top"
                            target={'delivered'}
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              boxShadow:
                                '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                            }}>
                            The order was successfully delivered.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ padding: '10px' }}
                          className={classnames({
                            active: tab === '6'
                          })}
                          onClick={navHandle('6', 'rejected')}
                          id="rejected"
                        >
                          Rejected ({allTotals.length > 0 && (allTotals.filter((total) => {
                            return total.orderStatus === 4
                          })[0]?.count || 0)})
                          <UncontrolledTooltip
                            placement="top"
                            target={'rejected'}
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              boxShadow:
                                '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                            }}>
                            The order was unable to be fulfilled.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ padding: '10px' }}
                          className={classnames({
                            active: tab === '7'
                          })}
                          onClick={navHandle('7', 'cancelled')}
                          id="cancelled"
                        >
                          Cancelled ({allTotals.length > 0 && (allTotals.filter((total) => {
                            return total.orderStatus === 5
                          })[0]?.count || 0)})
                          <UncontrolledTooltip
                            placement="top"
                            target={'cancelled'}
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              boxShadow:
                                '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                            }}>
                            The order was cancelled by QLS Admin at the customer’s request.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div className="d-flex">
                      <Dropdown
                        isOpen={singlebtn}
                        toggle={dropToggle}
                      >
                        <DropdownToggle className="customer-select text-dark">
                          Filter By{' '}<span className="font-weight-bold">
                            {selectedFilter.key}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {filterTypes.length > 0 && filterTypes.map((filterObj, key) => {
                            return (
                              <DropdownItem
                                key={key}
                                onClick={() => filterByKey(filterObj)}
                              >
                                {filterObj.key}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      <div className="customer-search-form">
                        <input
                          className="form-control input-border customer-input-border"
                          type="text"
                          placeholder="Search"
                          onChange={(e) => searchByText(e.target.value)}
                          value={searchText}
                        />
                        <i className="bx bx-search-alt-2 search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <hr className=" mt-0 mb-4 tab-hr"></hr>
                  {/* {
                    (userObj.roleId === 3 || userObj.roleId === 2)
                      ? <div className="dropdown__staff main-drop-staff">
                    <span>Select Warehouse:</span>
                      <select className="drop-staf drop-order" onChange={(e) => newLocationID(e)}>
                      <option>All</option>
                        {
                          locationdata?.data?.map((item, i) => {
                            return (
                              <option key={i} value={item.id}>{item.locationName}  {item.cities?.name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                      : ''
                    } */}

                  {
                    (userObj.roleId === 3 || userObj.roleId === 4)
                      ? <div className="dropdown__staff main-drop-staff">
                        <span>Select Warehouse:</span>
                        <select className="drop-staf drop-order" onChange={(e) => newLocationID(e)}>
                          <option>All</option>
                          {
                            locationdata?.data?.map((item, i) => {
                              return (
                                <option key={i} value={item.id}>{item.locationName}  {item.cities?.name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      : ''
                  }
                  <div className='order-table'>
                    <TableComponent
                      columns={columns}
                      content={orders}
                      handleActions={handleActions}
                      updateSellingPrice={updateSellPrice}
                      paging={paging}
                      paginationHandler={paginationHandler}
                      onClick={handleRow}
                      sorting={handleSort}
                      arrowState={sortingState}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default OrdersTable
