/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import back from '../../assets/images/back2.svg'
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormFeedback,
  Form,
  Input,
  Label
} from 'reactstrap'
import { Link, useHistory, useParams } from 'react-router-dom'
import { GetData, PutData } from 'apiHandler'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toastr from 'toastr'
import Loader from '../../components/Loader'
import NumberFormat from 'react-number-format'
import { globalVariables } from 'globalVariables'

const EditWarehouse = () => {
  // const userObj = JSON.parse(localStorage.authUser)
  const history = useHistory()
  const { id } = useParams()

  // eslint-disable-next-line no-unused-vars
  const [currentRole, setCurrentRole] = useState(1)
  // eslint-disable-next-line no-unused-vars
  const [permissionsDataLeft, setPermissionsDataLeft] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [permissionsDataRight, setPermissionsDataRight] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [selectedPermission, setselectedPermission] = useState([])
  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [stateBtn, setStateBtn] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [cityBtn, setCityBtn] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [needValidation, setNeedValidation] = useState(false)
  const [warehouseData, setWarehouseData] = useState(null)
  const [loader, setLoader] = useState(false)
  console.log(cities)

  // const newUrl= "vendor/editWarehouseLocation"
  // PutData(newUrl, body).then((response) =>{
  //     if(response.status === true){
  //           toastr.success(response.message, "Success");

  //     }
  // })

  const getState = async () => {
    const response = await GetData(`states?countryId=${globalVariables.countryId}`)
    setStates(response?.result)
  }

  const getCities = async (stateId) => {
    console.log('state is', stateId)
    const response = await GetData(`cities?countryId=${globalVariables.countryId}&stateId=${stateId}`)
    setCities(response?.result)
  }

  const clearData = () => {
    setNeedValidation(false)
  }

  useEffect(() => {
    setLoader(true)
    GetData(`vendor/getWarehouseLocation/${id}`).then((response) => {
      setLoader(false)
      if (response.status === true) {
        setWarehouseData(response.result[0])
        console.log('update data ', response.result[0])
      }
    })
  }, [])

  // get cities list when we have ware house data
  useEffect(() => {
    if (warehouseData) {
      getCities(warehouseData.shippingStateId)
    }
  }, [warehouseData])

  useEffect(() => {
    if (localStorage.authUser) {
      const userObj1 = JSON.parse(localStorage.authUser)
      setCurrentRole(userObj1.roleId)
      getState()
      clearData()
    }
  }, [])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      locationName: warehouseData?.locationName,
      firstName: warehouseData?.contactFirstName,
      lastName: warehouseData?.contactLastName,
      email: warehouseData?.contactEmail,
      mobile: warehouseData?.contactPhone,
      title: warehouseData?.contactTitle,
      street: warehouseData?.shippingStreet,
      zipcode: warehouseData?.shippingZipCode,
      shippingStateId: warehouseData?.shippingStateId,
      shippingCityId: warehouseData?.shippingCityId,
      primary: warehouseData?.isPrimaryLocation == 1
    },
    validationSchema: Yup.object().shape({
      locationName: Yup.string().trim().required('Please enter Location Name'),
      firstName: Yup.string().trim().required('Please enter Name'),
      lastName: Yup.string().trim().required('Please enter Name'),
      email: Yup.string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
      mobile: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required('Please enter Phone Number')
        .test(
          'maxDigits',
          'Must be 10 digits',
          (number) => String(number).length === 10
        ),
      title: Yup.string().trim().required('Please enter title'),
      street: Yup.string().trim().required('Please enter street'),
      zipcode: Yup.string().trim().required('Please enter zipcode'),
      shippingStateId: Yup.string().required('Please select state'),
      shippingCityId: Yup.string().required('Please Select City')
    }),
    onSubmit: (values) => {
      setLoader(true)
      // eslint-disable-next-line no-unused-vars
      const url = 'vendor/addWarehouseLocation'

      const SubmitData = {
        locationName: values?.locationName,
        shippingStreet: values?.street,
        shippingStateId: values?.shippingStateId,
        shippingCityId: values?.shippingCityId,
        shippingCountryId: globalVariables.countryId,
        shippingZipCode: values?.zipcode,
        contactFirstName: values?.firstName,
        contactLastName: values?.lastName,
        contactEmail: values?.email,
        contactPhone: values?.mobile,
        contactTitle: values?.title,
        isPrimaryLocation: values?.primary ? 1 : 0,
        id
      }
      setNeedValidation(true)

      console.log('new', SubmitData)

      const newUrl = 'vendor/editWarehouseLocation'
      PutData(newUrl, SubmitData).then((response) => {
        console.log('responseeee', response)
        if (response.status) {
          toastr.success(response.message, 'Success')
          history.push(`/warehouse-profile/${id}`)
        } else {
          toastr.error(response.message, 'Error')
        }
      })

      //  if(isEmpty(selectedState) || isEmpty(selectedCity)){
      //    return
      //  }

      //   PostData(url, SubmitData).then((response) => {
      //     if (response.status === true) {
      //       toastr.success(response.message, "Success");
      //       setLoader(false)
      //       clearData();
      //       history.push("/warehouse-management");
      //     } else {
      //       setLoader(false)
      //       toastr.error(response.message, "Error");
      //     }
      //   });
    }
  })
  return (
    <>
      <div className="page-content">
        <Loader show={loader}></Loader>
        <Container fluid={true} className="customer-container">
          <div className="staff-management-heading Add-staff">
            <Link to="/warehouse-management">
              {' '}
              <img src={back} style={{ marginRight: '8px' }} />
            </Link>{' '}
            Edit New Warehouse
          </div>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              validation.handleSubmit()
            }}
          >
            <Row className="mt-3">
              <Col lg={12}>
                <Card>
                  <div className="street--main--div warehouse-location">
                    <div className="edit-full-wt">
                      <div className="staff-manafement-light">
                        Location Name*
                      </div>
                      <Input
                        type="text"
                        className="staff-first-input"
                        name="locationName"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.locationName || ''}
                        invalid={
                          !!(validation.touched.locationName &&
                          validation.errors.locationName)
                        }
                        handleChange
                      />
                      {validation.touched.locationName &&
                      validation.errors.locationName
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.locationName}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                  </div>
                  <CardBody className="card-main-body">
                    <Row>
                      <Col lg={6}>
                        <div className="warehouse-contact-text">
                          Shipping Address
                        </div>

                        {/* <div className="street--main--div">
                          <div className="edit-full-wt">
                            <div className="staff-manafement-light">
                              Location Name*
                            </div>
                            <Input
                              type="text"
                              className="staff-first-input"
                              name="locationName"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.locationName || ""}
                              invalid={
                                validation.touched.locationName &&
                                validation.errors.locationName
                                  ? true
                                  : false
                              }
                              handleChange
                            />
                            {validation.touched.locationName &&
                            validation.errors.locationName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.locationName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div> */}

                        <div className="street--main--div">
                          <div className="edit-full-wt">
                            <div className="staff-manafement-light">
                              Street*
                            </div>
                            <Input
                              type="text"
                              className="staff-first-input"
                              name="street"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.street || ''}
                              invalid={
                                !!(validation.touched.street &&
                                validation.errors.street)
                              }
                            />
                            {validation.touched.street &&
                            validation.errors.street
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.street}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </div>
                        {/* <div className="street--main--div">
                          <Label htmlFor="formrow-firstname-Input">
                            State*
                          </Label>
                          <select  className="staff-first-input" name="state"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}

                          >
                            {console.log("staeesda", states)}
                            {
                              states ? states.map((item, i) =>{
                                return(
                                <option value={item} key={i} >{item.name}</option>)
                              }):"hello"
                            }

                          </select>
                        </div> */}

                        {/* <div className="street--main--div">
                        <Label htmlFor="formrow-firstname-Input">City*</Label>
                        <select className="staff-first-input" name="city" >

                        </select>

                        </div> */}

                        <div className="street--main--div">
                          <Label htmlFor="formrow-firstname-Input">
                          State/Province*
                          </Label>
                          <Input
                            type="select"
                            className="staff-first-input"
                            name="shippingStateId"
                            onChange={(e) => {
                              validation.setFieldValue(
                                'shippingStateId',
                                e.target.value
                              )
                              getCities(e.target.value)
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.shippingStateId || ''}
                            invalid={
                              !!(validation.touched.shippingStateId &&
                              validation.errors.shippingStateId)
                            }
                          >
                            <option value={''}>-Select State-</option>
                            {states?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.shippingStateId &&
                          validation.errors.shippingStateId
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.shippingStateId}
                            </FormFeedback>
                              )
                            : null}
                        </div>
                        <div className="street--main--div">
                          <Label htmlFor="formrow-firstname-Input">City*</Label>
                          <Input
                            type="select"
                            className="staff-first-input"
                            name="shippingCityId"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.shippingCityId || ''}
                            invalid={
                              !!(validation.touched.shippingCityId &&
                              validation.errors.shippingCityId)
                            }
                          >
                            <option value={''}>-Select City-</option>
                            {cities?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.shippingCityId &&
                          validation.errors.shippingCityId
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.shippingCityId}
                            </FormFeedback>
                              )
                            : null}
                        </div>

                        <div className="street--main--div">
                          <div className="edit-full-wt">
                            <div className="staff-manafement-light">
                            Zip Code/Postal Code*
                            </div>
                            <Input
                              type="text"
                              className="staff-first-input"
                              name="zipcode"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.zipcode || ''}
                              invalid={
                                !!(validation.touched.zipcode &&
                                validation.errors.zipcode)
                              }
                            />
                            {validation.touched.zipcode &&
                            validation.errors.zipcode
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.zipcode}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </div>

                        <div className="street--main--div">
                          <div className="edit-full-wt">
                            <Input
                              type="checkbox"
                              name="primary"
                              id="primary"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.primary || ''}
                              className="pointer-cursor"
                            />
                            &nbsp;&nbsp;
                            <label htmlFor="primary " className="pointer-cursor">
                              Set as Primary Location
                            </label>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="warehouse-contact-text">
                          Contact Information
                        </div>
                        <div className="street--main--div">
                          <div className="street--main--div warehouse--first-name">
                            <div className="name--first">
                              <div className="staff-manafement-light">
                                First Name*
                              </div>
                              <Input
                                type="text"
                                className="staff-first-input"
                                name="firstName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstName || ''}
                                invalid={
                                  !!(validation.touched.firstName &&
                                  validation.errors.firstName)
                                }
                              />
                              {validation.touched.firstName &&
                              validation.errors.firstName
                                ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.firstName}
                                </FormFeedback>
                                  )
                                : null}
                            </div>
                            <div className="name--first">
                              <div className="staff-manafement-light">
                                Last Name*
                              </div>
                              <Input
                                type="text"
                                className="staff-first-input"
                                name="lastName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastName || ''}
                                invalid={
                                  !!(validation.touched.lastName &&
                                  validation.errors.lastName)
                                }
                              />
                              {validation.touched.lastName &&
                              validation.errors.lastName
                                ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.lastName}
                                </FormFeedback>
                                  )
                                : null}
                            </div>
                          </div>
                        </div>
                        <div className="street--main--div">
                          <div className="edit-full-wt">
                            <div className="staff-manafement-light">
                              Email Address*
                            </div>
                            <Input
                              type="email"
                              className="staff-first-input"
                              name="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ''}
                              invalid={
                                !!(validation.touched.email &&
                                validation.errors.email)
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </div>

                        <div className="street--main--div">
                          <div className="edit-full-wt">
                            <div className="staff-manafement-light">
                              Mobile Number*
                            </div>
                            <NumberFormat
                            className="staff-first-input"
                            name="mobile" displayType="input" format="(###) ###-####"
                            value={validation.values.mobile || ''}
                            onValueChange={(values) => {
                              if (values.value.length < 11) {
                                validation.setFieldValue(
                                  'mobile',
                                  values.value
                                )
                              }
                            }}
                            />
                            {/* <Input
                              type="text"
                              className="staff-first-input"
                              name="mobile"
                              onChange={(e) => {
                                if (e.target.value.length < 11) {
                                  validation.setFieldValue(
                                    'mobile',
                                    e.target.value
                                  )
                                }
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.mobile || ''}
                              invalid={
                                !!(validation.touched.mobile &&
                                validation.errors.mobile)
                              }
                            /> */}
                            {validation.touched.mobile &&
                            validation.errors.mobile
                              ? (
                              <div className='error' type="invalid">
                                {validation.errors.mobile}
                              </div>
                                )
                              : null}
                          </div>
                        </div>

                        <div className="street--main--div">
                          <div className="edit-full-wt">
                            <div className="staff-manafement-light">Title*</div>
                            <Input
                              type="text"
                              className="staff-first-input"
                              name="title"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.title || ''}
                              invalid={
                                !!(validation.touched.title &&
                                validation.errors.title)
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title
                              ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                                )
                              : null}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <button
                      className="staff-save-changes staff-btn-clr"
                      type="submit"
                    >
                      Update Details
                    </button>
                    <button
                      className="staff-cancel-btn staff-btn-clr"
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  )
}

export default EditWarehouse
