import axios from 'axios'
import Swal from 'sweetalert2'

export const ToesterSettings = {
  positionClass: 'toast-top-right',
  timeOut: 2000,
  extendedTimeOut: 1000,
  closeButton: false,
  debug: false,
  progressBar: false,
  preventDuplicates: false,
  newestOnTop: true,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
  showDuration: 300,
  hideDuration: 1000
}

export const PostData = async (url, data, file = false) => {
  const accessToken = JSON.parse(localStorage.authUser).access_token
  return await axios.post(process.env.REACT_APP_API_URL + url, data, {
    headers: {
      'Content-Type': file ? 'multipart/form-data' : 'application/json',
      access_token: accessToken
    }
  }).then(function (response) {
    return response.data
  })
    .catch(function (error) {
      if (error?.response?.status === 401) {
        setTimeout(() => { localStorage.clear(); window.location.href = '/' }, 1000)
      }
      if (error?.response?.status === 413) {
        Swal.fire({
          title: 'Error!',
          text: 'Token is invalid! Please login again',
          icon: 'error',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/login'
          }
        })
        return false
      }
      return error.response.data
    })
}

export const PostWithoutToken = async (url, data) => {
  return await axios.post(process.env.REACT_APP_API_URL + url, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(function (response) {
    return response.data
  })
    .catch(function (error) {
      if (error?.response?.data) {
        return error.response.data
      } else {
        return error
      }
    })
}

export const GetData = async (url) => {
  const accessToken = JSON.parse(localStorage.authUser).access_token
  return await axios.get(process.env.REACT_APP_API_URL + url, {
    headers: {
      'Content-Type': 'application/json',
      access_token: accessToken
    }
  }).then(function (response) {
    return response.data
  })
    .catch(function (error) {
      if (error?.response) {
        if (error?.response?.status === 401) {
          setTimeout(() => { localStorage.clear(); window.location.href = '/' }, 1000)
        }
        if (error?.response?.status === 413) {
          Swal.fire({
            title: 'Error!',
            text: 'Token is invalid! Please login again',
            icon: 'error',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/login'
            }
          })
          return false
        }
        return error?.response?.data
      } else {
        return error
      }
    })
}

export const PutData = async (url, formData) => {
  const accessToken = JSON.parse(localStorage.authUser).access_token
  return await axios.put(process.env.REACT_APP_API_URL + url, formData, {
    headers: {
      'Content-Type': 'application/json',
      access_token: accessToken
    }
  }).then(function (response) {
    return response.data
  })
    .catch(function (error) {
      if (error?.response) {
        if (error?.response?.status === 401) {
          setTimeout(() => { localStorage.clear(); window.location.href = '/' }, 1000)
        }
        if (error?.response?.status === 413) {
          Swal.fire({
            title: 'Error!',
            text: 'Token is invalid! Please login again',
            icon: 'error',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/login'
            }
          })
          return false
        }
        return error?.response?.data
      } else {
        return error
      }
    })
}

export const PatchData = async (url, formData) => {
  const accessToken = JSON.parse(localStorage.authUser).access_token
  return await axios.patch(process.env.REACT_APP_API_URL + url, formData, {
    headers: {
      'Content-Type': 'application/json',
      access_token: accessToken
    }
  }).then(function (response) {
    return response.data
  })
    .catch(function (error) {
      if (error?.response) {
        if (error?.response?.status === 401) {
          setTimeout(() => { localStorage.clear(); window.location.href = '/' }, 1000)
        }
        if (error?.response?.status === 413) {
          Swal.fire({
            title: 'Error!',
            text: 'Token is invalid! Please login again',
            icon: 'error',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/login'
            }
          })
          return false
        }
        return error?.response?.data
      } else {
        return error
      }
    })
}

export const DeleteData = async (url, data) => {
  const accessToken = JSON.parse(localStorage.authUser).access_token
  return await axios.delete(process.env.REACT_APP_API_URL + url, {
    headers: {
      'Content-Type': 'application/json',
      access_token: accessToken
    }
  }).then(function (response) {
    return response.data
  })
    .catch(function (error) {
      if (error?.response?.status === 401) {
        setTimeout(() => { localStorage.clear(); window.location.href = '/' }, 1000)
      }
      if (error?.response?.status === 413) {
        Swal.fire({
          title: 'Error!',
          text: 'Token is invalid! Please login again',
          icon: 'error',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/login'
          }
        })
        return false
      }
      return error.response.data
    })
}

export const GetWithoutToken = async (url) => {
  return await axios.get(process.env.REACT_APP_API_URL + url, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(function (response) {
    return response.data
  })
    .catch(function (error) {
      if (error?.response?.data) {
        return error.response.data
      } else {
        return error
      }
    })
}
