const inboxmails = [
  {
    id: 1,
    name: 'Peter, me (3)',
    description: "<a class='subject' href='#'>Hello – <span class='teaser'>Trip home from Colombo has been arranged, then Jenna will come get me from Stockholm. :)</span></a>",
    read: true,
    fav: false,
    date: 'Mar 6'
  },
  {
    id: 2,
    name: 'me, Susanna (7)',
    description: "<a href='#' class='subject'> <span class='bg-warning badge me-2'> Freelance </span> Since you asked... and i'm inconceivably bored at the train station – <span class='teaser'> Alright thanks. I'll have to re-book that somehow, i'll get back to you. </span></a>",
    read: true,
    fav: false,
    date: 'Mar 6'
  },
  {
    id: 3,
    name: 'Web Support Dennis',
    description: "<a href='#' class='subject'> Re: New mail settings – <span class='teaser'> Will you answer him asap? </span></a>",
    read: true,
    fav: false,
    date: 'Mar 7'
  },
  {
    id: 4,
    name: 'me, Peter (2)',
    description: "<a href='#' class='subject'> <span class='bg-info badge me-2'> Support </span> Off on Thursday - <span class='teaser'> Eff that place, you might as well stay here with us instead! Sent from my iPhone 4 4 mar 2014 at 5:55 pm </span></a>",
    read: true,
    fav: false,
    date: 'Mar 4'
  },
  {
    id: 5,
    name: 'Medium',
    description: "<a href='#' class='subject'> <span class='bg-primary badge me-2'> Social </span> This Week's Top Stories – <span class='teaser'> Our top pick for you on Medium this week The Man Who Destroyed America’s Ego </span></a>",
    read: true,
    fav: false,
    date: 'Feb 28'
  },
  {
    id: 6,
    name: 'Death to Stock',
    description: "<a href='#' class='subject'> Montly High-Res Photos – <span class='teaser'> To create this month's pack, we hosted a party with local musician Jared Mahone here in Columbus, Ohio. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 28'
  },
  {
    id: 7,
    name: 'Randy, me (5)',
    description: "<a href='#' class='subject'> <span class='bg-success badge me-2'> Family </span> Last pic over my village – <span class='teaser'> Yeah i'd like that! Do you remember the video you showed me of your train ride between Colombo and Kandy? The one with the mountain view? I would love to see that one again! </span></a>",
    read: false,
    fav: false,
    date: '5:01 am'
  },
  {
    id: 8,
    name: 'Andrew Zimmer',
    description: "<a href='#' class='subject'> Mochila Beta: Subscription Confirmed – <span class='teaser'> You've been confirmed! Welcome to the ruling className of the inbox. For your records, here is a copy of the information you submitted to us... </span></a>",
    read: true,
    fav: false,
    date: 'Mar 8'
  },
  {
    id: 9,
    name: 'Infinity HR',
    description: "<a href='#' class='subject'> Sveriges Hetaste sommarjobb – <span class='teaser'> Hej Nicklas Sandell! Vi vill bjuda in dig till 'First tour 2014', ett rekryteringsevent som erbjuder jobb på 16 semesterorter iSverige. </span></a>",
    read: true,
    fav: false,
    date: 'Mar 8'
  },
  {
    id: 10,
    name: 'Revibe',
    description: "<a href='#' class='subject'> <span class='bg-danger badge me-2'> Friends </span> Weekend on Revibe – <span class='teaser'> Today's Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen! </span></a>",
    read: true,
    fav: false,
    date: 'Feb 27'
  },
  {
    id: 11,
    name: 'Erik, me (5)',
    description: "<a href='#' class='subject'> Regarding our meeting – <span class='teaser'> That's great, see you on Thursday! </span></a>",
    read: true,
    fav: false,
    date: 'Feb 24'
  },
  {
    id: 12,
    name: 'KanbanFlow',
    description: "<a href='#' class='subject'> <span class='bg-primary badge me-2'> Social </span> Task assigned: Clone ARP's website – <span class='teaser'> You have been assigned a task by Alex@Work on the board Web. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 24'
  },
  {
    id: 13,
    name: 'Tobias Berggren',
    description: "<a href='#' class='subject'> Let's go fishing! – <span class='teaser'> Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 23'
  },
  {
    id: 14,
    name: 'Charukaw, me (7)',
    description: "<a href='#' class='subject'> Hey man – <span class='teaser'> Nah man sorry i don't. Should i get it? </span></a>",
    read: true,
    fav: false,
    date: 'Feb 23'
  },
  {
    id: 15,
    name: 'me, Peter (5)',
    description: "<a href='#' class='subject'> <span class='bg-info badge me-2'> Support </span> Home again! – <span class='teaser'> That's just perfect! See you tomorrow. </span></a>",
    read: false,
    fav: false,
    date: 'Feb 21'
  },
  {
    id: 16,
    name: 'Stack Exchange',
    description: "<a href='#' class='subject'> 1 new items in your Stackexchange inbox – <span class='teaser'> The following items were added to your Stack Exchange global inbox since you last checked it. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 21'
  },
  {
    id: 17,
    name: 'Google Drive Team',
    description: "<a href='#' class='subject'> You can now use your storage in Google Drive – <span class='teaser'> Hey Nicklas Sandell! Thank you for purchasing extra storage space in Google Drive. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 20'
  },
  {
    id: 18,
    name: 'me, Susanna (11)',
    description: "<a href='#' class='subject'> Train/Bus – <span class='teaser'> Yes ok, great! I'm not stuck in Stockholm anymore, we're making progress. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 19'
  }
]

const starredmails = [
  {
    id: 1,
    name: 'Charukaw, me (7)',
    description: "<a href='#' class='subject'> Hey man – <span class='teaser'> Nah man sorry i don't. Should i get it? </span></a>",
    read: true,
    fav: false,
    date: 'Feb 23'
  },
  {
    id: 2,
    name: 'me, Peter (5)',
    description: "<a href='#' class='subject'> <span class='bg-info badge me-2'> Support </span> Home again! – <span class='teaser'> That's just perfect! See you tomorrow. </span></a>",
    read: false,
    fav: false,
    date: 'Feb 21'
  },
  {
    id: 3,
    name: 'Stack Exchange',
    description: "<a href='#' class='subject'> 1 new items in your Stackexchange inbox – <span class='teaser'> The following items were added to your Stack Exchange global inbox since you last checked it. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 21'
  },
  {
    id: 4,
    name: 'Google Drive Team',
    description: "<a href='#' class='subject'> You can now use your storage in Google Drive – <span class='teaser'> Hey Nicklas Sandell! Thank you for purchasing extra storage space in Google Drive. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 20'
  },
  {
    id: 5,
    name: 'me, Susanna (11)',
    description: "<a href='#' class='subject'> Train/Bus – <span class='teaser'> Yes ok, great! I'm not stuck in Stockholm anymore, we're making progress. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 19'
  }
]

const importantmails = [
  {
    id: 1,
    name: 'Revibe',
    description: "<a href='#' class='subject'> <span class='bg-danger badge me-2'> Friends </span> Weekend on Revibe – <span class='teaser'> Today's Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen! </span></a>",
    read: true,
    fav: false,
    date: 'Feb 27'
  },
  {
    id: 2,
    name: 'Erik, me (5)',
    description: "<a href='#' class='subject'> Regarding our meeting – <span class='teaser'> That's great, see you on Thursday! </span></a>",
    read: true,
    fav: false,
    date: 'Feb 24'
  },
  {
    id: 3,
    name: 'KanbanFlow',
    description: "<a href='#' class='subject'> <span class='bg-primary badge me-2'> Social </span> Task assigned: Clone ARP's website – <span class='teaser'> You have been assigned a task by Alex@Work on the board Web. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 24'
  },
  {
    id: 4,
    name: 'Tobias Berggren',
    description: "<a href='#' class='subject'> Let's go fishing! – <span class='teaser'> Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 23'
  },
  {
    id: 5,
    name: 'Charukaw, me (7)',
    description: "<a href='#' class='subject'> Hey man – <span class='teaser'> Nah man sorry i don't. Should i get it? </span></a>",
    read: true,
    fav: false,
    date: 'Feb 23'
  },
  {
    id: 6,
    name: 'me, Peter (5)',
    description: "<a href='#' class='subject'> <span class='bg-info badge me-2'> Support </span> Home again! – <span class='teaser'> That's just perfect! See you tomorrow. </span></a>",
    read: false,
    fav: false,
    date: 'Feb 21'
  }
]

const draftmails = [
  {
    id: 1,
    name: 'Medium',
    description: "<a href='#' class='subject'> <span class='bg-primary badge me-2'> Social </span> This Week's Top Stories – <span class='teaser'> Our top pick for you on Medium this week The Man Who Destroyed America’s Ego </span></a>",
    read: true,
    fav: false,
    date: 'Feb 28'
  },
  {
    id: 2,
    name: 'Death to Stock',
    description: "<a href='#' class='subject'> Montly High-Res Photos – <span class='teaser'> To create this month's pack, we hosted a party with local musician Jared Mahone here in Columbus, Ohio. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 28'
  },
  {
    id: 3,
    name: 'Randy, me (5)',
    description: "<a href='#' class='subject'> <span class='bg-success badge me-2'> Family </span> Last pic over my village – <span class='teaser'> Yeah i'd like that! Do you remember the video you showed me of your train ride between Colombo and Kandy? The one with the mountain view? I would love to see that one again! </span></a>",
    read: false,
    fav: false,
    date: '5:01 am'
  },
  {
    id: 4,
    name: 'Andrew Zimmer',
    description: "<a href='#' class='subject'> Mochila Beta: Subscription Confirmed – <span class='teaser'> You've been confirmed! Welcome to the ruling className of the inbox. For your records, here is a copy of the information you submitted to us... </span></a>",
    read: true,
    fav: false,
    date: 'Mar 8'
  },
  {
    id: 5,
    name: 'Infinity HR',
    description: "<a href='#' class='subject'> Sveriges Hetaste sommarjobb – <span class='teaser'> Hej Nicklas Sandell! Vi vill bjuda in dig till 'First tour 2014', ett rekryteringsevent som erbjuder jobb på 16 semesterorter iSverige. </span></a>",
    read: true,
    fav: false,
    date: 'Mar 8'
  },
  {
    id: 6,
    name: 'Revibe',
    description: "<a href='#' class='subject'> <span class='bg-danger badge me-2'> Friends </span> Weekend on Revibe – <span class='teaser'> Today's Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen! </span></a>",
    read: true,
    fav: false,
    date: 'Feb 27'
  },
  {
    id: 7,
    name: 'Erik, me (5)',
    description: "<a href='#' class='subject'> Regarding our meeting – <span class='teaser'> That's great, see you on Thursday! </span></a>",
    read: true,
    fav: false,
    date: 'Feb 24'
  }
]

const sentmails = [
  {
    id: 1,
    name: 'Infinity HR',
    description: "<a href='#' class='subject'> Sveriges Hetaste sommarjobb – <span class='teaser'> Hej Nicklas Sandell! Vi vill bjuda in dig till 'First tour 2014', ett rekryteringsevent som erbjuder jobb på 16 semesterorter iSverige. </span></a>",
    read: true,
    fav: false,
    date: 'Mar 8'
  },
  {
    id: 2,
    name: 'Revibe',
    description: "<a href='#' class='subject'> <span class='bg-danger badge me-2'> Friends </span> Weekend on Revibe – <span class='teaser'> Today's Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen! </span></a>",
    read: true,
    fav: false,
    date: 'Feb 27'
  },
  {
    id: 3,
    name: 'Erik, me (5)',
    description: "<a href='#' class='subject'> Regarding our meeting – <span class='teaser'> That's great, see you on Thursday! </span></a>",
    read: true,
    fav: false,
    date: 'Feb 24'
  },
  {
    id: 4,
    name: 'KanbanFlow',
    description: "<a href='#' class='subject'> <span class='bg-primary badge me-2'> Social </span> Task assigned: Clone ARP's website – <span class='teaser'> You have been assigned a task by Alex@Work on the board Web. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 24'
  },
  {
    id: 5,
    name: 'Tobias Berggren',
    description: "<a href='#' class='subject'> Let's go fishing! – <span class='teaser'> Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 23'
  },
  {
    id: 6,
    name: 'Charukaw, me (7)',
    description: "<a href='#' class='subject'> Hey man – <span class='teaser'> Nah man sorry i don't. Should i get it? </span></a>",
    read: true,
    fav: false,
    date: 'Feb 23'
  },
  {
    id: 7,
    name: 'me, Peter (5)',
    description: "<a href='#' class='subject'> <span class='bg-info badge me-2'> Support </span> Home again! – <span class='teaser'> That's just perfect! See you tomorrow. </span></a>",
    read: false,
    fav: false,
    date: 'Feb 21'
  },
  {
    id: 8,
    name: 'Stack Exchange',
    description: "<a href='#' class='subject'> 1 new items in your Stackexchange inbox – <span class='teaser'> The following items were added to your Stack Exchange global inbox since you last checked it. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 21'
  }
]

const trashmails = [
  {
    id: 1,
    name: 'KanbanFlow',
    description: "<a href='#' class='subject'> <span class='bg-primary badge me-2'> Social </span> Task assigned: Clone ARP's website – <span class='teaser'> You have been assigned a task by Alex@Work on the board Web. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 24'
  },
  {
    id: 2,
    name: 'Tobias Berggren',
    description: "<a href='#' class='subject'> Let's go fishing! – <span class='teaser'> Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome. </span></a>",
    read: true,
    fav: false,
    date: 'Feb 23'
  },
  {
    id: 3,
    name: 'Charukaw, me (7)',
    description: "<a href='#' class='subject'> Hey man – <span class='teaser'> Nah man sorry i don't. Should i get it? </span></a>",
    read: true,
    fav: false,
    date: 'Feb 23'
  },
  {
    id: 4,
    name: 'me, Peter (5)',
    description: "<a href='#' class='subject'> <span class='bg-info badge me-2'> Support </span> Home again! – <span class='teaser'> That's just perfect! See you tomorrow. </span></a>",
    read: false,
    fav: false,
    date: 'Feb 21'
  }
]

export { inboxmails, starredmails, importantmails, draftmails, sentmails, trashmails }
