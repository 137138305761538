/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Container, Button, Row, Col, Table, Card, CardBody, Modal } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Link, useHistory } from 'react-router-dom'
import { GetData, DeleteData } from 'apiHandler'
import Loader from '../../components/Loader'
import delIcon from '../../assets/images/ic_delete.svg'
import toastr from 'toastr'
import moment from 'moment'
import MyTable from 'components/DraggableTable/MyTable'
import 'toastr/build/toastr.min.css'

const columns = [
  { id: 1, label: 'Title', key: 'title' },
  { id: 2, label: 'Date', key: 'date' },
  { id: 3, label: 'Actions', key: 'actions' }
]

function TechnicalList () {
  const history = useHistory()
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)
  const [totalPages, setTotalPages] = useState(null)
  const [page, setPage] = useState(1)
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const [indexId, setIndexId] = useState({ id: null, index: null })

  function formatText (str) {
    str = str?.replace(/(<([^>]+)>)/ig, '')
    return str.length > 250 ? str.substr(0, 250) + '...' : str
  }

  const getAllTechnicalBulletins = () => {
    setData((prevState) => {
      prevState = []
      return prevState
    })
    const pageSize = 20
    setLoader(true)
    GetData(
      'admin/technicalBulletins'
    ).then((response) => {
      if (response.status === true) {
        setLoader(false)
        const updatedData = []
        response.result.forEach((item) => {
          const singleData = {}

          singleData.id = item.id
          singleData.title = item.title
          singleData.date = moment(item.createdAt).format('MMMM Do YYYY')
          singleData.actions = ['edit', 'delete']

          updatedData.push(singleData)
        })
        setData((prevState) => {
          prevState = updatedData
          return prevState
        })
        setTotalPages(Math.ceil(response.result.total / pageSize))
      } else {
        setLoader(false)
      }
    })
  }

  useEffect(() => {
    getAllTechnicalBulletins()
  }, [page])

  function handleSearch (e) {
    e.preventDefault()
    const pageSize = 20
    GetData(
      `admin/faqs/title?page=${page}&pageSize=${pageSize}&serach=${e.target.value}`
    ).then((response) => {
      if (response.status === true) {
        setData(response.result)
      } else {
        // toastr.warning("Post Deleted", "Deleted");
      }
    })
  }

  function handlePage (pageNo, e) {
    e.preventDefault()
    setPage(pageNo)
  }

  function handleDelete (id, e) {
    e.preventDefault()
    const pageSize = 20
    setLoader(true)
    DeleteData(`admin/technicalBulletin/${id}`).then((response) => {
      if (response.status === true) {
        setLoader(false)
        getAllTechnicalBulletins()
        setIsDisableOpen(false)
      } else {
        setLoader(false)
      }
    })
  }

  const handleDeleteAction = (details, index) => {
    setIsDisableOpen(true)
    setIndexId(details)
  }

  return (
        <>
        <Loader show={loader}></Loader>
        <div className="page-content">
        <Container fluid={true} className="homepage-wrap">
        <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <Breadcrumbs
              title="UI Elements"
              breadcrumbItem="Technical Bulletins"
            />
            <div>
              <div className="d-flex align-items-center knowledge-base-button-container">
                <Link to="/add-technical-bulletin">
                  <Button color="primary" className="btn btn-add-new">
                    Add New
                  </Button>
                </Link>
              </div>
            </div>
          </div>

          <Row>
            <Col lg={12} style={{ textAlign: '-webkit-right' }}>
              <Card>
                <CardBody>
                  <Col lg={12} className="pb-2 text-right">
                    <div className="search-form">
                      <input
                        className="form-control input-border"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => handleSearch(e)}
                      />
                      <i className="bx bx-search-alt-2 search-icon"></i>
                    </div>
                  </Col>

                  <div className="table-responsive">
                    {
                      data.length
                        ? <MyTable
                        data={data}
                        actions={true}
                        columns={columns}
                        deleteAction={handleDeleteAction}
                        firstCol='title'
                      />
                        : <p>No record found</p>
                    }
                    {data?.total > 20 && (
                      <div className="d-flex align-items-center justify-content-center mt-3 mb-1">
                        <div
                          className="pagination-box cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault()
                            page !== 1 && setPage(page - 1)
                          }}
                        >
                          <i
                            className="fas fa-angle-left "
                            style={{ fontSize: '18px' }}
                          ></i>{' '}
                        </div>
                        {Array.from(Array(totalPages), (e, index) => {
                          return (
                            <div
                              className={`pagination-box cursor-pointer ${
                                page === index + 1 && 'pagination-box-active'
                              }`}
                              onClick={(event) => handlePage(index + 1, event)}
                              key={index}
                            >
                              {index + 1}
                            </div>
                          )
                        })}
                        <div
                          className="pagination-box cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault()
                            page !== totalPages && setPage(page + 1)
                          }}
                        >
                          <i
                            className="fas fa-angle-right cursor-pointer"
                            style={{ fontSize: '18px' }}
                          ></i>{' '}
                        </div>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            className="modal-main-body"
            isOpen={isDisableOpen}
            toggle={() => setIsDisableOpen(false)}
            centered
          >
            <div className="modal-header">
              <button
                type="button"
                onClick={() => {
                  setIsDisableOpen(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="disable-warehouse">
                Are you sure you want to Delete this Technical Bulletin?
              </p>
              <div className="modal-detail-div">
                <p className="modal-details">Details</p>
                <p className="modal-warehouse-name">
                  <span className="warehouse-heading"> Title: </span>{' '}
                  {indexId?.title}
                </p>
                <p className="modal-warehouse-name">
                  <span className="warehouse-heading"> Date </span>{' '}
                  {moment(indexId?.createdAt).format('MMMM Do YYYY')}
                </p>
              </div>
              <div className="modal-btn-div">
                <button
                  className="warehouse-btn-yes"
                  onClick={(e) => handleDelete(indexId?.id, e)}
                >
                  Yes
                </button>
                <button
                  className=" warehouse-btn-no"
                  onClick={() => setIsDisableOpen(false)}
                >
                  No
                </button>
              </div>
            </div>
          </Modal>
        </Container>
        </div>
        </>
  )
}

export default TechnicalList
