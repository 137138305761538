import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";
import BulkUploadModal from "components/Common/BulkUploadModal/BulkUploadModal";
import { GetData, PostData } from "apiHandler";
import TableComponent from "components/Table";
import Loader from "../../components/Loader";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

let searchTimer;
let filterByKeyObject = { key: "all" };

const ProductManagement = () => {
  const [searchText, setSearchText] = useState("");
  const [singlebtn, setSinglebtn] = useState(false);
  const [filterTypes, setFilterTypes] = useState([]);
  const [tab, setTab] = useState("1");
  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState(false);
  const [bulkError, setBulkError] = useState(null);
  const [bulkErrorSheet, setBulkErrorSheet] = useState("");
  const [successAvailability, setSuccessAvailability] = useState(null);
  const [availabilityMark, setAvailabilityMark] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [availabilityType, setAvailabilityType] = useState(null);

  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0,
  });

  const [selectedFilter, setSelectedFilter] = useState({
    value: "all",
    key: "All",
  });

  // const [sortingState, setSortingState] = useState({
  //   sortColumn: null,
  //   order: null,
  // });

  const columns = [
    {
      key: "checkBox",
      label: "",
      sorting: false,
    },
    { key: "partNumber", label: "Part Number" },
    { key: "product", label: "Product" },
    { key: "brand", label: "Brand" },
    { key: "category", label: "Category" },
    { key: "subcategory", label: "Sub Category" },
  ];

  useEffect(() => {
    return () => {
      setTab("1");
      filterByKeyObject = { key: "all" };
    };
  }, []);

  useEffect(() => {
    getProducts(1);
  }, [activeTab]);

  const dropToggle = () => {
    setSinglebtn(!singlebtn);
  };

  const navHandle = (key, status) => (e) => {
    e.preventDefault();
    setTab(key);
    status !== activeTab && setProducts([]);
    setPaging({
      currentPage: 1,
      recordPerPage: 20,
      totalRecord: 0,
    });
    setActiveTab(status);
    setAvailabilityMark([]);
    setSearchText("");
    filterByKeyObject = { key: "all" };
    setSelectedFilter({
      value: "all",
      key: "All",
    });
  };

  const paginationHandler = (page) => {
    getProducts(page);
  };

  // const handleSort = (e, column) => {
  //   e.preventDefault();
  //   if (sortingState.sortColumn == null) {
  //     setSortingState({ sortColumn: column, order: "asc" });
  //   } else if (sortingState.sortColumn === column) {
  //     if (sortingState.order === "desc") {
  //       setSortingState({ sortColumn: column, order: "asc" });
  //     } else setSortingState({ sortColumn: column, order: "desc" });
  //   } else {
  //     setSortingState({ sortColumn: column, order: "asc" });
  //   }
  // };

  // useEffect(() => {
  //   sortingState.sortColumn &&
  //     sortingStsetAvailabilityMarkate.order &&
  //     getProducts(paging.currentPage);
  // }, [sortingState]);

  const filterByKey = (obj) => {
    filterByKeyObject.key = obj.value;
    setSelectedFilter(obj);
    if (filterByKeyObject.string) {
      getProducts(1);
    }
  };
  // ------------------------Function to handle checkboxes----------------------
  const handleCheck = (e, productData) => {
    if (e.target.checked) {
      setAvailabilityMark((prevState) => {
        return [
          ...prevState,
          {
            partNumber: productData.partNumber,
            BrandID: productData.BrandID,
            isAvailable: activeTab === "byQls" ? 1 : 0,
          },
        ];
      });
    } else {
      setAvailabilityMark((prevState) => {
        const newArr = prevState.filter((data) => {
          return data.partNumber !== productData.partNumber;
        });
        return newArr;
      });
    }
  };

  const getProducts = (p = 1) => {
    setLoader(true);
    const pageSize = 20;
    let url = "admin/getProductList?page=" + p + "&pageSize=" + pageSize;

    if (activeTab === 'all') {
      url += "&tab=ALL_PARTS_FOR_DISCONTINUE"
    }
    if (activeTab === "byDpi") {
      url += "&tab=discountinue";
    }

    if (activeTab === "byQls") {
      url = `admin/discountinuedProducts?page=${p}&pageSize=${pageSize}`;
    }

    if (filterByKeyObject.string) {
      url +=
        "&filterBy=" +
        filterByKeyObject.key +
        "&search=" +
        filterByKeyObject.string;
    }

    // if (sortingState.sortColumn != null) {
    //   url +=
    //     "&sortBy=" + sortingState.sortColumn + "&order=" + sortingState.order;
    // }

    GetData(url).then((response) => {
      if (response.status === true || response.status === 200) {
        setFilterTypes(response.result.filterTitle);
        setLoader(false);
        setBulkError(null);
        setProducts(
          response?.result?.products?.map((product) => {
            const productObj = {
              id: product?.part_id,
              product:
                product?.PartTerminology?.PartTerminologyName ||
                product?.subCategoryName,
              partNumber: product?.partNumber,
              brand: product?.brand,
              category:
                product?.partCategoryMapping?.partCategory?.categoryName ||
                product?.categoryName,
              subcategory:
                product.partCategoryMapping?.partSubCategory?.subCategoryName ||
                product?.subCategoryName,
            };
            if (activeTab !== "byDpi") {
              productObj.checkBox = (
                <div>
                  {" "}
                  <input
                    type="checkbox"
                    style={{ height: "18px", width: "18px" }}
                    // checked={availabilityMark.some((mark) => mark.partNumber === product.partNumber)}
                    onChange={(e) => handleCheck(e, product)}
                  />
                </div>
              );
            }
            return productObj;
          })
        );
        setPaging({
          currentPage: p,
          recordPerPage: pageSize,
          totalRecord: response.result.total,
        });
      } else {
        setLoader(false);
        setProducts([]);
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 });
        toastr.error(response.message, "Error");
      }
    });
  };
  // ------------------Function for mark as unavailable and available-------------------------
  const handleMultipleAvailabilityMark = () => {
    setLoader(true);
    const url = "admin/updateDiscountinueProducts";
    const payload = {
      products: availabilityMark,
    };

    PostData(url, payload).then((response) => {
      setLoader(false);
      if (response.status === true) {
        toastr.success(response.message, "Success");
        setAvailabilityMark([]);
        getProducts();
        window.location.reload();
      } else {
        toastr.error(response.message, "Error");
      }
    });
  };

  const searchByText = (t) => {
    setSearchText(t);
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    searchTimer = setTimeout(() => {
      filterByKeyObject.string = t;
      getProducts(1);
    }, 2000);
  };

  // ----------------------Function to export By QLS Products (i.e unavailable products)---------------------
  const handleExport = (e) => {
    e.preventDefault();
    setLoader(true);
    let url;
    if (activeTab === "byQls") {
      url = `admin/exportDiscountinuedProductsQLS?page=${paging?.currentPage}&pageSize=${paging?.recordPerPage}&withoutPagination=1`;
    }
    if (activeTab === "all") {
      url = `admin/exportProductsForDiscountinued?page=${paging?.currentPage}&pageSize=${paging?.recordPerPage}&withoutPagination=1&tab=ALL_PARTS_FOR_DISCONTINUE`;
    }

    if (activeTab === "byDpi") {
      url = `admin/exportDiscountinuedProductsDPI?page=${paging?.currentPage}&pageSize=${paging?.recordPerPage}&tab=discountinue&withoutPagination=1`;
    }

    if (filterByKeyObject.string) {
      url +=
        "&filterBy=" +
        filterByKeyObject.key +
        "&search=" +
        filterByKeyObject.string;
    }

    GetData(url).then((response) => {
      if (response.status === true) {
        setLoader(false);
        const a = document.createElement("a");
        a.href = "https://" + response?.result?.path;
        a.download = "download";
        a.click();
      } else {
        setLoader(false);
        toastr.error(response?.message, "Error");
      }
    });
  };

  // ----------------Function for import product availability(i.e mark as available and unavailable)--------------------
  const importAvailability = (e, file) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("document", file);
    formData.append("availability", availabilityType);

    setLoader(true);
    PostData("admin/discountinueProductsFromCsv", formData, true).then(
      (response) => {
        if (response.status === true) {
          setLoader(false);
          getProducts();
          setBulkError(false);
          toastr.success(response.message, "Success");
        } else {
          getProducts();
          setBulkError(response?.result?.errorsPartsInfo);
          setBulkErrorSheet(response?.result?.path);
          setSuccessAvailability(response?.result?.successCount);
          // toastr.error(response?.message, 'Error')
          setLoader(false);
        }
      }
    );
  };
  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <Container fluid={true} className="customer-container">
          <div className="products-nav">
            <span className="products-heading">Product Management</span>
            <div className="d-flex">
              {activeTab !== "byDpi" && (
                <BulkUploadModal
                  modalType="Import Product Availability"
                  importSellingPrice={importAvailability}
                  bulkError={bulkError}
                  bulkErrorSheet={bulkErrorSheet}
                  setAvailabilityType={setAvailabilityType}
                  availabilityType={availabilityType}
                  successAvailability={successAvailability}
                  setSuccessAvailability={setSuccessAvailability}
                />
              )}
              <button
                className="products-nav-btn products-export-btn"
                onClick={handleExport}
              >
                Export
              </button>
            </div>
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="warehouse-navbar">
                    <Nav>
                      <NavItem className="navItems">
                        <NavLink
                          className={classnames({
                            active: tab === "1",
                          })}
                          onClick={navHandle("1", "all")}
                        >
                          {/* All ({paging.totalRecord}) */}
                          All
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: tab === "2",
                          })}
                          onClick={navHandle("2", "byQls")}
                        >
                          Discontinued by QLS
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: tab === "3",
                          })}
                          onClick={navHandle("3", "byDpi")}
                        >
                          Discontinued by DPI
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div className="d-flex product_nav">
                      <Dropdown
                        isOpen={singlebtn}
                        toggle={dropToggle}
                        // className="pe-none"
                      >
                        <DropdownToggle className="customer-select text-dark">
                          Filter By{" "}
                          <span className="font-weight-bold">
                            {" "}
                            {selectedFilter.key}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {filterTypes.map((filterObj, key) => {
                            return (
                              <DropdownItem
                                key={key}
                                onClick={() => filterByKey(filterObj)}
                              >
                                {filterObj.key}
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      <div className="customer-search-form">
                        <input
                          className="form-control input-border customer-input-border"
                          type="text"
                          placeholder="Search"
                          onChange={(e) => searchByText(e.target.value)}
                          value={searchText}
                        />
                        <i className="bx bx-search-alt-2 search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <hr className=" mt-0 mb-4 tab-hr"></hr>
                  <TableComponent
                    columns={
                      activeTab !== "byDpi"
                        ? columns
                        : columns.filter((col) => col.key !== "checkBox")
                    }
                    content={products}
                    paging={paging}
                    paginationHandler={paginationHandler}
                    // sorting={handleSort}
                    // arrowState={sortingState}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {availabilityMark?.length ? (
            <div className="invoice-float-btn">
              <div className="container-fluid product-item-selected-row product-management position-fixed">
                <div className="product-select-item-row">
                  <button
                    className="product-selected-item position-relative"
                    onClick={handleMultipleAvailabilityMark}
                  >
                    {activeTab === "byQls"
                      ? "Mark as available"
                      : "Mark as unavailable"}
                    <div className="products__quantity d-flex align-items-center justify-content-center">
                      {availabilityMark?.length}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </Container>
      </div>
    </>
  );
};

export default ProductManagement;
