import MetaTags from 'react-meta-tags'
import React from 'react'

import {
  Row,
  Col,
  CardBody,
  Card,
  Container
} from 'reactstrap'
import { Link } from 'react-router-dom'
import logo from 'assets/images/QLlogo.svg'
import tick from 'assets/images/success.svg'

const PasswordSuccessfull = (props) => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | QLS</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={7} lg={5} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="p-4">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div
                        className="avatar-md profile-user-wid mb-2 mt-3"
                        style={{ width: '100%', marginTop: '0px' }}
                      >
                        <span
                          className="avatar-title"
                          style={{ backgroundColor: 'transparent' }}
                        >
                          <img src={logo} alt="" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="green-tick mt-3">
                    <img src={tick} />
                  </div>
                  <div className="d-flex justify-content-center mt-2 mb-1 set-password">
                    Successful!
                  </div>
                  <div className="d-flex justify-content-center mb-4 please-set-password">
                    Your password has been created successfully.
                  </div>
                  <div className="d-flex justify-content-center mb-4">
                    <Link to="/login">
                      <button className="login-here">Login Here</button>
                    </Link>
                  </div>
                  <div className="p-2"></div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="lower-privacy">Privacy & Policy | Terms of Use</p>
                <p className="lower-copyright">
                  Copyright © {new Date().getFullYear()} QL SUPPLY. All rights
                  reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PasswordSuccessfull
