import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import React, { useState } from 'react'
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form
} from 'reactstrap'
import Loader from '../../components/Loader'
// redux

import { withRouter, Link } from 'react-router-dom'

// Formik Validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

// import images
import logo from '../../assets/images/QLlogo.svg'
import { PostWithoutToken } from '../../apiHandler'

const ForgetPasswordPage = (props) => {
  const [loader, setLoader] = useState(false)
  const [forgetError, setForgetError] = useState('')
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState('')

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email')
    }),
    onSubmit: (values) => {
      setLoader(true)
      PostWithoutToken('admin/forgotPass', values).then((response) => {
        setLoader(false)
        if (response.status === true) {
          setForgetSuccessMsg(response.message)
        } else {
          setForgetError(response.message)
        }
      })
    }
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Forget Password | Skote - React Admin & Dashboard Template
        </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Loader show={loader} />
          <Row className="justify-content-center">
            <Col md={7} lg={5} xl={5}>
              <Card className="overflow-hidden">
                {/* <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Skote.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div> */}
                <CardBody className="p-4">
                  <div>
                    <Link to="/">
                      <div
                        className="avatar-md profile-user-wid mb-2 mt-1"
                        style={{ width: '100%', marginTop: '0px' }}
                      >
                        <span
                          className="avatar-title"
                          style={{ backgroundColor: 'transparent' }}
                        >
                          <img src={logo} alt="" height="42" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="d-flex justify-content-center mb-2 set-password">
                    Forget Your Password
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError
                      ? (
                      <Alert color="danger" style={{ marginTop: '13px' }}>
                        {forgetError}
                      </Alert>
                        )
                      : null}
                    {forgetSuccessMsg
                      ? (
                      <Alert color="success" style={{ marginTop: '13px' }}>
                        {forgetSuccessMsg}
                      </Alert>
                        )
                      : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          className="new-password form-control"
                          name="email"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            !!(validation.touched.email && validation.errors.email)
                          }
                        />
                        {validation.touched.email && validation.errors.email
                          ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                            )
                          : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md set-password-btn "
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p style={{ fontSize: '14px' }}>
                  Go back to{' '}
                  <Link to="login" className="font-weight-medium">
                    <a>
                    Login
                    </a>
                  </Link>{' '}
                </p>
                <p className="lower-privacy">
                  {' '}
                  <a
                    href="https://qlsweb.mobikasa.net/page/privacy-policy"
                    rel="noreferrer"
                    target="_blank"

                  >
                    Privacy & Policy
                  </a>{' '}
                  |{' '}
                  <a
                    href="https://qlsweb.mobikasa.net/page/terms-and-conditions"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Terms of Use
                  </a>
                </p>
                <p className="lower-copyright">
                  Copyright © {new Date().getFullYear()} QL SUPPLY. All rights
                  reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object
}

export default withRouter(ForgetPasswordPage)
