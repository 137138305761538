/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Label,
  Input,
  Form
} from 'reactstrap'
import Dropzone from 'react-dropzone'

// import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { useHistory, useParams, Link } from 'react-router-dom'
import { GetData, PostData, PutData } from 'apiHandler'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
// import { EditorState, ContentState, convertToRaw } from 'draft-js'
// import draftToHtml from 'draftjs-to-html'
// import htmlToDraft from 'html-to-draftjs'
import { slugify } from 'pages/Services/AddNewServices'
import { Editor } from '@tinymce/tinymce-react'
import InfiniteScroll from 'react-infinite-scroll-component'

let searchTimer
let searchKeyword = ''

function AddNewArticle () {
  const categoryRef = useRef()
  const history = useHistory()
  const { id } = useParams()
  const [editorContent, setEditorContent] = useState()
  const [articleData, setArticleData] = useState({
    products: [],
    categories: []
  })
  const [loader, setLoader] = useState(false)
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [parts, setParts] = useState(null)
  const [blogCategory, setBlogCategory] = useState(null)
  const [blogImage, setBlogImage] = useState(null)
  const [partsData, setPartsData] = useState([])
  const [categoriesData, setCategoriesData] = useState([])
  const [imageError, setImageError] = useState('')
  const [status, setStatus] = useState(null)
  const [categoryError, setCategoryError] = useState('')
  const [allProducts, setAllProducts] = useState([])
  const [productPage, setProductPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedProductIds, setSelectedProductIds] = useState([])
  const scrollRef = useRef()

  id &&
    useEffect(() => {
      setLoader(true)
      GetData(`admin/getPost/${id}`).then((response) => {
        if (response.status == true) {
          setStatus(response.result.status)
          setArticleData({
            ...response.result,
            categories: response.result.categories.map((item, i) => {
              return item.id
            }),
            products: response.result.Products.map((item) => {
              return item.part_id
            })
          })
          setSelectedProducts(response.result.Products.map((product) => {
            return { value: product.part_id, label: `${product.partNumber} - ${product.PartTerminologyName}` }
          }))
          setSelectedProductIds(response.result.Products.map((item) => {
            return item.part_id
          }))
          setLoader(false)
          setEditorContent(response.result.description)
          // const descriptionDataState = ContentState.createFromBlockArray(
          //   htmlToDraft(`${response.result.description}`)
          // )
          // setEditorState(EditorState.createWithContent(descriptionDataState))
        }
      })
    }, [id])

  useEffect(() => {
    !id && setArticleData({ ...articleData, status: 0 })
    setLoader(true)
    GetData('admin/getAllProducts').then((response) => {
      if (response.status == true) {
        setLoader(false)
        setParts(response.result)
        setPartsData(response.result)
      }
    })
    GetData('admin/getAllBlogCategories').then((response) => {
      if (response.status == true) {
        setLoader(false)
        setBlogCategory(response.result)
        setCategoriesData(response.result)
      }
    })
  }, [])

  function handleChange (type, e) {
    if (type == 'tags') {
      // do something
    } else if (type == 'image') {
      if (e.target.files[0].size >= 2 * 1024 * 1024) {
        setImageError('Please Upload Small Size File')
      } else {
        setImageError('')
        setLoader(true)
        const formImage = new FormData()
        formImage.append('image', e.target.files[0])
        PostData('admin/uploadPostImage', formImage).then((response) => {
          if (response.status == true) {
            setLoader(false)
            setArticleData({
              ...articleData,
              [e.target.name]: response.result.newFile
            })
            setBlogImage(response.result.url)
          }
        })
      }
    } else if (e.target.name === 'title') {
      setArticleData({
        ...articleData,
        [e.target.name]: e.target.value,
        slug: slugify(e.target.value)
      })
    } else setArticleData({ ...articleData, [e.target.name]: e.target.value })
  }

  function handleSplit (e) {
    e.preventDefault()
    if (e.keyCode === 13) {
      if (e.target.value == '') {
        setArticleData({ ...articleData, [e.target.name]: [] })
      } else {
        const tempTags = [...articleData.tags]
        tempTags.push(e.target.value)
        setArticleData({
          ...articleData,
          [e.target.name]: tempTags
        })
        e.target.value = ''
      }
    }
  }
  function handleAcceptedFiles (files) {
    setLoader(true)
    if (files[0].size >= 2 * 1024 * 1024) {
      setImageError('Please Upload Small Size File')
      setLoader(false)
    } else {
      setImageError('')
      const formImage = new FormData()
      formImage.append('image', files[0])
      PostData('admin/uploadPostImage', formImage).then((response) => {
        if (response.status == true) {
          setLoader(false)
          setArticleData({
            ...articleData,
            postImage: response.result.newFile
          })
          setBlogImage(response.result.url)
        }
      })
    }
  }

  function handleCheckbox (type, e) {
    if (type === 'categories') {
      if (!e.target.checked) {
        setCategoryError('')
        const updatedCheck = [...articleData[e.target.name]]
        updatedCheck.push(parseInt(e.target.value))
        setArticleData({ ...articleData, [e.target.name]: updatedCheck })
      } else if (e.target.checked) {
        const data = articleData.categories.filter((item) => {
          return item != parseInt(e.target.value)
        })
        setArticleData({ ...articleData, categories: data })
      } else {
        e.preventDefault()
      }
    } else {
      if (!e.target.checked) {
        const updatedCheck = [...articleData[e.target.name]]
        updatedCheck.push(parseInt(e.target.value))
        setArticleData({ ...articleData, [e.target.name]: updatedCheck })
      } else {
        if (type == 'categories') {
          const data = articleData.categories.filter((item) => {
            return item != parseInt(e.target.value)
          })
          setArticleData({ ...articleData, categories: data })
        } else {
          const data = articleData.products.filter((item) => {
            return item != parseInt(e.target.value)
          })
          setArticleData({ ...articleData, products: data })
        }
      }
    }
  }

  function resetUploadBanner (e) {
    e.preventDefault()
    setArticleData({
      ...articleData,
      postImage: null
    })
    setBlogImage(null)
  }

  function handleSubmit (e) {
    e.preventDefault()
    if (articleData.categories.length > 0) {
      setLoader(true)
      const formData = {
        ...articleData,
        description: editorContent,
        // description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        productIds: [...selectedProductIds]
      }
      id
        ? PutData(`admin/updatePost/${id}`, formData).then((response) => {
          if (response.status == true) {
            toastr.success(response.message, 'Sent')
            history.push('/knowledge-base')
            setLoader(false)
          } else {
            toastr.error(response.message, 'Error')
            setLoader(false)
          }
        })
        : PostData('admin/addPost', formData).then((response) => {
          if (response.status == true) {
            toastr.success(response.message, 'Sent')
            history.push('/knowledge-base')
            setLoader(false)
          } else {
            toastr.error(response.message, 'Error')
            setLoader(false)
          }
        })
    } else {
      setCategoryError('Please select a blog category')
      categoryRef.current.scrollIntoView()
    }
  }

  function handleDelete (type, e) {
    if (type == 'categories') {
      document.getElementById(`categories-${e.target.id}`).checked = false
      const data = articleData.categories.filter((item) => {
        return item != parseInt(e.target.id)
      })
      setArticleData({ ...articleData, categories: data })
    } else {
      document.getElementById(`products-${e.target.id}`).checked = false
      const data = articleData.products.filter((item) => {
        return item != parseInt(e.target.id)
      })
      setArticleData({ ...articleData, products: data })
    }
  }

  const filter = (type, e) => {
    e.preventDefault()
    if (type == 'products') {
      GetData(`admin/getAllProducts?search=${e.target.value}`).then(
        (response) => {
          if (response.status == true) {
            setParts(response.result)
          } else {
            setParts(null)
          }
        }
      )
    } else {
      GetData(`admin/getAllBlogCategories?search=${e.target.value}`).then(
        (response) => {
          if (response.status == true) {
            setBlogCategory(response.result)
          } else {
            setBlogCategory(null)
          }
        }
      )
    }
  }

  function handleRemoveTags (tag, e) {
    e.preventDefault()
    const data = articleData.tags.filter((item) => {
      return item != tag
    })
    setArticleData({ ...articleData, tags: data })
    if (articleData?.tags?.length == 1) {
      document.getElementById('tags').value = ''
    } else {
      let data = document.getElementById('tags').value
      if (data.includes(tag)) {
        data = data.replace(`${tag},`, '')
        document.getElementById('tags').value = data
      }
    }
  }

  // const handleUpload = (file) => {
  //   return new Promise((resolve) => {
  //     const formImage = new FormData()
  //     formImage.append('image', file)
  //     resolve(PostData('admin/uploadPostImage', formImage).then((response) => {
  //       return { data: { link: response.result.url } }
  //     }))
  //   })
  // }

  const handleUpload = async (file) => {
    setLoader(true)
    const formImage = new FormData()
    formImage.append('image', file)
    const response = await PostData('admin/uploadPostImage', formImage)
    if (response) {
      setLoader(false)
    }
    return response.status ? response.result.url : toastr.error(response.message, 'Error')
  }

  const getProducts = (p) => {
    setProductPage(p)
    setLoader(true)
    let url
    if (searchKeyword !== '') {
      url = `admin/getProductsForBlog?page=${p}&pageSize=20&search=${searchKeyword}`
    } else {
      url = `admin/getProductsForBlog?page=${p}&pageSize=20`
    }
    GetData(url).then((response) => {
      if (response.status === true) {
        setLoader(false)
        const newProducts = response.result.data.map((item) => {
          return { value: item.part_id, label: `${item.partNumber} - ${item.PartTerminologyName}` }
        })
        if (searchKeyword !== '') {
          scrollRef.current.scrollTo(0, 0)
          setAllProducts(newProducts)
        } else setAllProducts(prevState => [...prevState, ...newProducts])
      } else {
        toastr.error(response.message, 'Error')
        setLoader(false)
      }
    })
  }

  const filterProduct = (e) => {
    setSearchText(e.target.value)
    if (e.target.value === '') {
      setAllProducts([])
    }
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      searchKeyword = e.target.value
      getProducts(1)
    }, 1000)
  }

  const handleProductCheckbox = (product, e) => {
    if (!e.target.checked) {
      const updatedCheck = [...selectedProducts]
      const brandData = { ...product }
      brandData.checked = true
      updatedCheck.push(brandData)
      setSelectedProducts(updatedCheck)
      setSelectedProductIds(updatedCheck.map((product) => {
        return product.value
      }))
    } else if (e.target.checked) {
      const data = selectedProducts.filter((item, i) => {
        return item.value !== product.value
      })
      setSelectedProducts(data)
      setSelectedProductIds(data.map((brand) => {
        return brand.value
      }))
    }
  }

  const fetchNextProducts = () => {
    getProducts(parseInt(productPage) + 1)
  }

  const handleDeleteProduct = (e, product) => {
    const data = selectedProducts.filter((item, i) => {
      return item.value !== product.value
    })
    setSelectedProducts(data)
    setSelectedProductIds(data.map((brand) => {
      return brand.value
    }))
  }

  useEffect(() => {
    getProducts(productPage)
  }, [])

  return (
    <React.Fragment>
      <Loader show={loader}></Loader>
      <div className="page-content">
        <MetaTags>
          <title>QLS Admin | Home Page</title>
        </MetaTags>
        <Container fluid={true} className="homepage-wrap">
          <div className="d-flex justify-content-between align-items-center">
            <Breadcrumbs
              title="UI Elements"
              breadcrumbItem={`${id ? 'Edit' : 'Add'} New Article`}
              icon={
                <i
                  className="bx bx-arrow-back me-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push('/knowledge-base')}
                ></i>
              }
            />
            <a
              href={`${process.env.REACT_APP_WEB_URL}/blogs/${articleData.slug}`}
              target={'_blank'} rel="noreferrer"
            >
              {status == 1 && 'View on Website'}
            </a>
          </div>

          <Row className="mt-3">
            <Col lg={9}>
              <Card className="py-2 px-4">
                <CardBody>
                  <Col lg={12} className="pb-2">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="title">
                        <b>Title</b>
                      </Label>
                      <Input
                        placeholder="Enter Title"
                        type="text"
                        id="title"
                        name="title"
                        className="input-border"
                        defaultValue={articleData?.title}
                        onChange={(e) => handleChange('', e)}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="slug">
                        <b>Slug</b>
                      </Label>
                      <Input
                        id="slug"
                        placeholder="Enter Slug"
                        type="text"
                        name="slug"
                        className="input-border"
                        defaultValue={articleData?.slug}
                        onChange={(e) => handleChange('', e)}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="description">
                        <b>Description</b>
                      </Label>
                      <Editor
                        value={editorContent}
                        onEditorChange={e => setEditorContent(e)}
                        apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                        init={{
                          height: 350,
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                            'table', 'image', 'link'
                          ],
                          toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist | ' +
                            'table |link | image',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          default_link_target: '_blank',
                          automatic_uploads: true,
                          file_picker_types: 'image',
                          image_uploadtab: true,
                          file_picker_callback: function (callback, value, meta) {
                            if (meta.filetype == 'image') {
                              const input = document.createElement('input')
                              input.setAttribute('type', 'file')
                              input.setAttribute('accept', 'image/*')
                              input.click()
                              input.onchange = async function () {
                                const file = input.files[0]
                                const reader = new FileReader()
                                const fileUrl = await handleUpload(file)
                                callback(fileUrl, {
                                  alt: file.name
                                })
                                reader.readAsDataURL(file)
                              }
                            }
                          }
                        }}
                      />

                      {/* <Form method="post"> */}
                      {/* <Editor
                          editorState={editorState}
                          toolbarClassName="articleToolbar"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) => {
                            setEditorState(e)
                          }}
                          toolbar={{
                            image: {
                              urlEnabled: true,
                              uploadEnabled: true,
                              alignmentEnabled: true,
                              uploadCallback: (e) => handleUpload(e).then((response) => {
                                return response
                              }),
                              previewImage: true,
                              inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                              alt: { present: false, mandatory: false },
                              defaultSize: {
                                height: 'auto',
                                width: 'auto'
                              }
                            }
                          }}
                        /> */}
                      {/* </Form> */}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="sortDescription">
                        <b>Excerpt (Short Description)</b>
                      </Label>
                      <textarea
                        id="sortDescription"
                        className="form-control description-height input-border"
                        name="sortDescription"
                        onChange={(e) => handleChange('', e)}
                        defaultValue={articleData?.sortDescription}
                      ></textarea>
                      <div className="summary-text">
                        Add a summary of the post to appear on your home page or
                        blog.
                      </div>
                    </div>

                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">
                        <b>Part Number</b>
                      </Label>
                      <div className="selected-value d-flex flex-wrap">
                        {selectedProducts.map((product, i) => {
                          return <div
                            className="d-flex crumb-container align-items-center"
                            key={i}
                          >
                            <span className="p-1 d-flex align-items-center">
                              {product.label}
                              <a className='ms-2 text-dark'>
                                <i
                                  className="mdi mdi-close close-icons"
                                  onClick={(e) => { handleDeleteProduct(e, product) }}
                                ></i>
                              </a>
                            </span>
                          </div>
                        })}
                      </div>
                      <div className="search-check-container p-2">
                        <div className="search-form-w100 ">
                          <Input
                            className="form-control input-border"
                            type="text"
                            placeholder="Search"
                            onChange={filterProduct}
                            value={searchText}
                          />
                          <i className="bx bx-search-alt-2 search-icon"></i>
                        </div>

                        <div className="search-check-inner-container mt-2 " id='scrollable' ref={scrollRef}>
                          <InfiniteScroll
                            dataLength={allProducts.length}
                            next={fetchNextProducts}
                            hasMore={true}
                            endMessage={
                              <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                              </p>
                            }
                            scrollableTarget="scrollable"
                          >
                            {allProducts.map((product, i) => {
                              return <div className="checkbox-line" key={i}>
                                <div className="d-flex align-items-center">
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    value={product.value}
                                    id={`product-${product.value}`}
                                    onClick={(e) => handleProductCheckbox(product, e)}
                                    checked={
                                      selectedProductIds.includes(product.value)
                                    }
                                    readOnly
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`product-${product.value}`}
                                  >
                                    {product.label}
                                  </label>
                                </div>
                              </div>
                            })}
                          </InfiniteScroll>
                        </div>
                      </div>
                    </div>
                  </Col>
                </CardBody>
              </Card>
              <Card className="py-2 px-4">
                <CardBody>
                  <Col lg={12} className="pb-2">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="metaTitle">
                        <b>SEO Title</b>
                      </Label>
                      <Input
                        placeholder="Enter SEO Title"
                        type="text"
                        id="metaTitle"
                        className="input-border"
                        name="metaTitle"
                        defaultValue={articleData?.metaTitle}
                        onChange={(e) => handleChange('', e)}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="metaDescription">
                        <b>SEO Description</b>
                      </Label>
                      <textarea
                        id="metaDescription"
                        className="form-control description-height input-border"
                        defaultValue={articleData?.metaDescription}
                        name="metaDescription"
                        onChange={(e) => handleChange('', e)}
                      ></textarea>
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Col>

            <Col lg={3}>
              <Card className="px-3">
                <CardBody>
                  <div className="mb-3">
                    <Label className="d-block">
                      <b>Status</b>
                    </Label>
                    <div className="form-check">
                      <Input
                        type="radio"
                        id="draft"
                        name="status"
                        className="form-check-input cursor-pointer"
                        value={0}
                        checked={articleData.status == 0}
                        onClick={(e) => handleChange('', e)}
                      />
                      <Label className="form-check-label" htmlFor="draft">
                        Draft
                      </Label>
                    </div>
                    <div className="form-check">
                      <Input
                        type="radio"
                        id="Published"
                        name="status"
                        value={1}
                        className="form-check-input cursor-pointer"
                        checked={articleData.status == 1}
                        onClick={(e) => handleChange('', e)}
                      />
                      <Label className="form-check-label" htmlFor="Published">
                        Published
                      </Label>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="px-3">
                <CardBody>
                  <Label className="font-weight-bold">
                    <b>Cover Image</b>
                  </Label>
                  <div className="form-outer-dropzone">
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone drop-zone-container-left ">
                          <div
                            className="dz-message needsclick mt-2 p-0"
                            {...getRootProps()}
                          >
                            <input
                              {...getInputProps()}
                              onChange={(e) => handleChange('image', e)}
                              name="postImage"
                            />
                            <div className="">
                              <i className="display-4 text-muted bx bxs-cloud-upload drop-zone-icon" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {articleData?.postImage && (
                      <div className="preview-container">
                        <div className="file-preview-container">
                          <div className="file-box">
                            <img
                              data-dz-thumbnail=""
                              className="avatar-sm"
                              src={blogImage || articleData?.postImageUrl}
                              name="postImage"
                            />
                          </div>
                          <div
                            className="cross-btn-upload"
                            onClick={(e) => {
                              resetUploadBanner(e)
                            }}
                          >
                            X
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="error mt-2">{imageError}</div>
                  <div className="image-supported-text align-self-end mt-2">
                    <div>Format Supported: Jpeg, JPG, PNG, GIF</div>
                    <div>Preferred Dimensions: 1920x1080 (pixels) </div>
                    <div>Maximum Size: 2 MB </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="px-3">
                <CardBody>
                  <div className="mb-3" ref={categoryRef}>
                    <Label className="d-block">
                      <b>Categories</b>
                    </Label>
                    <div className="search-check-container p-2">
                      <div className="search-form-w100 ">
                        <Input
                          className="form-control input-border"
                          type="text"
                          placeholder="Search"
                          onChange={(e) => filter('categories', e)}
                        />
                        <i className="bx bx-search-alt-2 search-icon"></i>
                      </div>

                      <div className="search-check-inner-container mt-2  ">
                        {blogCategory?.map((item, index) => {
                          return (
                            <div className="form-check " key={index}>
                              <a>
                                <Input
                                  className="form-check-input cursor-pointer"
                                  type="checkbox"
                                  value={item.id}
                                  id={`categories-${item.id}`}
                                  name="categories"
                                  onClick={(e) =>
                                    handleCheckbox('categories', e)
                                  }
                                  checked={articleData.categories.includes(
                                    item.id
                                  )}
                                  readOnly
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`categories-${item.id}`}
                                >
                                  {item.title}
                                </label>
                              </a>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className="selected-value d-flex flex-wrap">
                      {articleData?.categories?.map((data, i) => {
                        let newIndex
                        if (
                          categoriesData?.map((item, index) => {
                            if (item.id == data) {
                              newIndex = index
                            }
                          })
                        ) {
                          return (
                            <div
                              className="d-flex crumb-container align-items-center"
                              key={i}
                            >
                              <span>{categoriesData[newIndex]?.title}</span>
                              <a>
                                <i
                                  id={data}
                                  className="mdi mdi-close close-icons ml-2"
                                  onClick={(e) => handleDelete('categories', e)}
                                ></i>
                              </a>
                            </div>
                          )
                        }
                      })}
                    </div>
                    <div className='error'>{categoryError}</div>
                  </div>
                </CardBody>
              </Card>
              <Card className="px-3">
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="tags">
                      <b>Tags</b>
                    </Label>
                    <Input
                      placeholder="Enter Tags"
                      type="text"
                      id="tags"
                      name="tags"
                      className="input-border"
                      defaultValue={articleData?.tags}
                      onChange={(e) => handleChange('tags', e)}
                      onKeyUp={(e) => handleSplit(e)}
                    />
                    <div className="d-flex flex-wrap">
                      {articleData?.tags?.map((item, index) => {
                        return (
                          <>
                            {item != '' && (
                              <div
                                className="d-flex crumb-container align-items-center"
                                key={index}
                              >
                                {item}
                                <i
                                  className="mdi mdi-close close-icons ml-2 cursor-pointer"
                                  onClick={(e) => handleRemoveTags(item, e)}
                                ></i>
                              </div>
                            )}
                          </>
                        )
                      })}
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="px-3">
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="author">
                      <b>Author</b>
                    </Label>
                    <Input
                      placeholder="Enter Title"
                      type="text"
                      id="author"
                      name="author"
                      className="input-border"
                      defaultValue={articleData?.author}
                      onChange={(e) => handleChange('', e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={12}>
              <Link to="/knowledge-base">
                <Button className="btn btn-cancel mb-4 ms-0">Cancel</Button>
              </Link>
              <Button
                className="btn btn-add-new mb-4 ms-0"
                onClick={(e) => handleSubmit(e)}
              >
                Save
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddNewArticle
