/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  Button
} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { useHistory, Link, useParams } from 'react-router-dom'
// import { Editor } from 'react-draft-wysiwyg'
import Dropzone from 'react-dropzone'
import { GetData, PutData, PostData, PatchData } from 'apiHandler'
import Loader from '../../components/Loader'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import delIcon from '../../assets/images/ic_delete.svg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { Editor } from '@tinymce/tinymce-react'

export function slugify (text) {
  return text
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}

function AddFAQ () {
  const history = useHistory()
  const { id } = useParams()
  const [loader, setLoader] = useState(false)
  const [questions, setQuestions] = useState([{ question: '', answer: '' }])
  const [deleteQIds, setDeleteQIds] = useState([])
  const [editorContent, setEditorContent] = useState()
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [faqData, setFaqData] = useState({})
  const [status, setStatus] = useState(null)
  const [imageError, setImageError] = useState({
    titleImage: '',
    bannerImage1: '',
    bannerImage2: ''
  })

  function handleChange (type, e) {
    e.preventDefault()
    if (e.target.name === 'title') {
      setFaqData({
        ...faqData,
        [e.target.name]: e.target.value
      })
    } else setFaqData({ ...faqData, [e.target.name]: e.target.value })
  }

  function handleQA (e, index) {
    setQuestions((prevState) => {
      const newArr = [...prevState]
      newArr[index][e.target.name] = e.target.value
      prevState = newArr
      return newArr
    })
  }

  function resetUploadBanner (name, e) {
    e.preventDefault()
    setFaqData({
      ...faqData,
      [name]: null
    })
  }

  function handleSubmit (e) {
    e.preventDefault()
    setLoader(true)
    const deleteArr = deleteQIds.length ? deleteQIds : undefined
    const formData = {
      title: faqData.title,
      faqs: questions,
      faqsDelete: deleteArr
    }
    const createFormData = {
      title: faqData.title,
      faqs: questions
    }
    id
      ? PutData(`admin/faq/${id}`, formData).then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Sent')
          history.push('/faq')
          setLoader(false)
        } else {
          toastr.error(response.message, 'Error')
          setLoader(false)
        }
      })
      : PostData('admin/faq', createFormData).then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Sent')
          history.push('/faq')
          setLoader(false)
        } else {
          toastr.error(response.message, 'Error')
          setLoader(false)
        }
      })
  }

  const handleAddQuestion = () => {
    setQuestions((prevState) => {
      return [...prevState, { question: '', answer: '' }]
    })
  }

  const getFaqById = (id) => {
    setLoader(true)
    GetData(`admin/faq/${id}`).then((response) => {
      if (response.status === true) {
        setStatus(response.result.status)
        setFaqData(response.result)
        setQuestions(response.result.faqs)
        setLoader(false)
        // const descriptionDataState = ContentState.createFromBlockArray(
        //   htmlToDraft(`${response.result.description}`)
        // )
        // setEditorState(EditorState.createWithContent(descriptionDataState))
        setEditorContent(response.result.description)
      }
    })
  }

  useEffect(() => {
    if (id) {
      getFaqById(id)
    }
  }, [id])

  const handleUpload = async (file) => {
    setLoader(true)
    const formImage = new FormData()
    formImage.append('image', file)
    const response = await PostData('admin/uploadPostImage', formImage)
    if (response) {
      setLoader(false)
    }
    return response.status
      ? response.result.url
      : toastr.error(response.message, 'Error')
  }

  const handleDeleteClick = (index, qId) => {
    if (qId) {
      setDeleteQIds((prevState) => {
        return [...prevState, qId]
      })
    }
    setQuestions((prevState) => {
      const newState = [...prevState]
      newState.splice(index, 1)
      return newState
    })
  }

  return (
    <>
      <Loader show={loader}></Loader>
      <div className="page-content">
        <Container fluid={true} className="homepage-wrap">
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <Breadcrumbs
              title="UI Elements"
              breadcrumbItem={id ? 'Edit FAQ' : 'Add New FAQ'}
              icon={
                <i
                  className="bx bx-arrow-back me-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push('/faq')}
                ></i>
              }
            />
            {/* <a
              href={`${process.env.REACT_APP_WEB_URL}/service-opportunities/${serviceData.slug}`}
              target={'_blank'} rel="noreferrer"
            >
              {status === 1 && 'View on Website'}
            </a> */}
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label">
                      <b>Title</b>
                    </Label>
                    <Input
                      placeholder="Enter Title"
                      type="  "
                      id="title"
                      name="title"
                      className="input-border"
                      value={faqData.title}
                      onChange={(e) => handleChange('', e)}
                    />
                  </div>
                  <hr />
                  {questions.map((question, i) => {
                    return (
                      <div key={i}>
                        <div className="mb-3 relative">
                          <Label className="form-label">
                            <b>Question {i + 1}</b>
                          </Label>
                          <Input
                            placeholder="Enter Question"
                            type="text"
                            id={`question${i + 1}`}
                            name={'question'}
                            className="input-border"
                            value={question.question}
                            onChange={(e) => handleQA(e, i)}
                          />
                          {
                              i === 0 || <img
                              src={delIcon}
                              className="del--icon absolute"
                              style={{ top: '0px', right: '0px' }}
                              onClick={ () => handleDeleteClick(i, question.id) }
                            />
                          }
                        </div>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor={`answer${i + 1}`}
                          >
                            <b>Answer {i + 1}</b>
                          </Label>
                          <textarea
                            id={`answer${i + 1}`}
                            className="form-control description-height input-border"
                            name={'answer'}
                            value={question.answer}
                            onChange={(e) => handleQA(e, i)}
                          ></textarea>
                        </div>
                        <hr />
                      </div>
                    )
                  })}
                  <div className="mb-3">
                    <p style={{ width: '100%' }} className="text-center">
                      <button
                        style={{
                          color: '#4646ea',
                          background: 'transparent',
                          border: 'unset'
                        }}
                        onClick={handleAddQuestion}
                      >
                        <b>
                          <u>Add More Question</u>
                        </b>
                      </button>
                    </p>
                  </div>
                </CardBody>
              </Card>
              <Link to="/faq">
                <Button className="btn btn-cancel mb-4 ms-0">Cancel</Button>
              </Link>
              <Button
                className="btn btn-add-new mb-4 ms-0"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default AddFAQ
