/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import discountIcon from '../../assets/images/discount-code.svg'
import autoIcon from '../../assets/images/auto-code.svg'
import crossIcon from '../../assets/images/cross2.svg'
import arrowIcon from '../../assets/images/pagi-arrow-right.svg'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import classnames from 'classnames'
import { DeleteData, GetData } from 'apiHandler'
import Loader from '../../components/Loader'
import CheckBoxTable from 'components/Table/CheckBoxTable'
import moment from 'moment'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const DiscountTable = () => {
  const history = useHistory()
  const [dropdownOpen, setOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('0')
  const [modal, setModal] = useState(false)
  const [disModal, setDisModal] = useState(false)
  const [delModal, setDelModal] = useState(false)
  const [modalData, setModalData] = useState(null)
  const toggle = () => setModal(!modal)
  // eslint-disable-next-line no-unused-vars
  const [tableData, setTableData] = useState(null)
  const [loader, setLoader] = useState(false)
  const [content, setContent] = useState(null)
  const [filterValue, setFilterValue] = useState('all')
  const [status, setStatus] = useState('')
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })

  const filterTypes = {
    all: 'All',
    coupancode: 'Discount Code',
    coupanvalue: 'Discount Value'
  }

  useEffect(() => {
    getTableData('', 1)
  }, [filterValue, status])

  // function that fetches data according to the page number
  const paginationHandler = (page) => {
    getTableData('', page)
  }

  // function that changes the current tab
  const filterData = (tab) => {
    if (activeTab != tab) {
      setActiveTab(tab)
    }
  }

  // function to get data on the basis of page,pageSize,status,filter,and search query
  function getTableData (searchWord, page) {
    setLoader(true)
    const search = searchWord || ''
    const pageSize = 20
    GetData(
      `admin/getDiscounts?page=${page}&pageSize=${pageSize}&filterBy=${filterValue}&search=${search}${status !== '' ? '&status=' + status : ''}`
    ).then((response) => {
      if (response.status == true) {
        setContent(
          response.result.data.map((item) => {
            const updatedItem = { ...item }
            updatedItem.checked = false
            return updatedItem
          })
        )
        setPaging({
          currentPage: page,
          recordPerPage: pageSize,
          totalRecord: response.result.total
        })
        setTableData(response.result)
        setLoader(false)
      } else {
        setLoader(false)
        console.log(response.message)
      }
    })
  }

  const columns = [
    { key: 'code', label: 'Discount Code' },
    { key: 'discountValue', label: 'Discount' },
    { key: 'type', label: 'Discount Type' },
    { key: 'status', label: 'Status' },
    { key: 'discountType', label: 'Availability' },
    { key: 'totalUsage', label: 'Used' },
    { key: 'startdate', label: 'Code Active From' },
    { key: 'action', label: 'View/Disable' }
  ]

  const handleRow = (e, data) => {
    // e.preventDefault();
    history.push(`edit-discount/${data.id}`)
  }

  // function to enable disable modal and also get data of the clicked disable
  function distoggle (e, type, data) {
    e.preventDefault()
    if (type == 'disable') {
      setDisModal(!disModal)
    } else {
      setDelModal(!delModal)
    }
    data && setModalData(data)
  }

  // function that handles the disable and enable functionality of discount coupon
  function handleDisable () {
    setLoader(true)
    GetData(`admin/changeDiscountStatus/${modalData.id}`).then((response) => {
      if (response.status == true) {
        setLoader(false)
        getTableData('', 1)
        setDisModal(false)
        toastr.success(response.message, 'Success')
      } else {
        console.log(response.error)
        setLoader(false)
        setDisModal(false)
      }
    })
  }

  function handleDelete () {
    setLoader(true)
    DeleteData(`admin/deleteDiscount/${modalData.id}`).then((response) => {
      if (response.status == true) {
        setLoader(false)
        getTableData('', 1)
        setDelModal(false)
        toastr.success(response.message, 'Discount Deleted')
      } else {
        console.log(response.error)
        setLoader(false)
        setDelModal(false)
      }
    })
  }

  // function that handles the search query input
  function handleChange (e) {
    e.preventDefault()
    getTableData(e.target.value, 1)
  }

  function handleExport (e) {
    e.preventDefault()
    setLoader(true)
    GetData('admin/exportAllDiscountCode').then(
      (response) => {
        if (response.status === true) {
          setLoader(false)
          const a = document.createElement('a')
          a.href = 'https://' + response.result
          a.download = 'download'
          a.click()
        } else {
          setLoader(false)
          toastr.error(response.message, 'Error')
        }
      }
    )
  }

  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <Container fluid={true} className="customer-container">
          <div className="discount-table-head">
            <div className="discount-text">Discount</div>
            <div>
              <button className="discount-export-btn" onClick={handleExport}>Export</button>
              <button className="discount-create-btn" onClick={toggle}>
                Create Discount
              </button>
            </div>
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between flex-wrap ">
                    <Nav>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            // eslint-disable-next-line no-unused-expressions
                            filterData('0')
                            setStatus('')
                          }}
                          className={`tab-border ${classnames({
                            active: activeTab === '0'
                          })}`}
                        >
                          All
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            // eslint-disable-next-line no-unused-expressions
                            filterData('1')
                            setStatus('active')
                          }}
                          className={`tab-border ${classnames({
                            active: activeTab === '1'
                          })}`}
                        >
                          Active
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            filterData('2')
                            setStatus('disabled')
                          }}
                          className={`tab-border ${classnames({
                            active: activeTab === '2'
                          })}`}
                        >
                          Expired
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div className="d-flex discount-dropdown-container">
                      <Dropdown
                        toggle={() => {
                          setOpen(!dropdownOpen)
                        }}
                        isOpen={dropdownOpen}
                      >
                        <DropdownToggle className="d-flex justify-content-between align-items-center customer-select text-dark">
                          <div style={{ width: 'max-content' }}>
                            Filter By {filterTypes[filterValue]}
                          </div>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.keys(filterTypes).map((title, index) => {
                            return (
                              <DropdownItem
                                key={index}
                                onClick={(e) => {
                                  e.preventDefault()
                                  setFilterValue(title)
                                }}
                              >
                                {filterTypes[title]}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      <span>&nbsp;</span>
                      <div className="customer-search-form m-0">
                        <input
                          className="form-control input-border customer-input-border"
                          type="text"
                          placeholder="Search"
                          onChange={(e) => handleChange(e)}
                        />
                        <i className="bx bx-search-alt-2 search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <hr className=" mt-0 mb-4"></hr>
                  <CheckBoxTable
                    columns={columns}
                    content={content}
                    action={distoggle}
                    paging={paging}
                    paginationHandler={paginationHandler}
                    handleRow={handleRow}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Create Discount Modal */}
          <div>
            <Modal isOpen={modal} toggle={toggle} centered>
              <div className="modal-heading">
                <div className="create-heading">Create Discount</div>

                <img src={crossIcon} onClick={toggle} className="modal-cross" />
              </div>

              <hr className="modal--hr" />
              <Link to="/create-discount">
                <div style={{ padding: '20px 16px' }}>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="d-flex discount-row">
                      <div>
                        <img src={discountIcon} />{' '}
                      </div>
                      <div className="discount-code-row">
                        <div className="discount-heading">Discount Code</div>
                        <div className="discount-sub-heading">
                          Customer will get a discount if they enter a code at
                          checkout.
                        </div>
                      </div>
                    </div>
                    <div>
                      <img src={arrowIcon} />{' '}
                    </div>
                  </div>
                </div>
              </Link>
              <hr className="modal--hr" />
              <Link to="/create-discount/auto">
                <div style={{ padding: '20px 16px' }}>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="d-flex discount-row">
                      <div>
                        <img src={autoIcon} />{' '}
                      </div>
                      <div className="discount-code-row">
                        <div className="discount-heading">
                          Automatic Discount
                        </div>
                        <div className="discount-sub-heading">
                          Customer will get discount automatically on their
                          cart.
                        </div>
                      </div>
                    </div>
                    <div>
                      <img src={arrowIcon} />{' '}
                    </div>
                  </div>
                </div>
              </Link>
              <hr className="modal--hr" />
              <div className="modal-footer discount-sub-heading">
                Customers can’t combine discounts at checkout
              </div>
            </Modal>
          </div>

          <Modal
            isOpen={disModal}
            toggle={(e) => {
              distoggle(e, 'disable')
            }}
            centered
          >
            <div>
              <img
                src={crossIcon}
                onClick={(e) => {
                  distoggle(e, 'disable')
                }}
                className="modal-cross disable-cross-icon"
              />
            </div>
            <ModalHeader>
              <div className="heading-disable">
                Are you sure you want to{' '}
                {modalData?.status == 0 ? 'enable' : 'disable'} discount ?
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="details--div">
                <div className="disable-details">Details</div>
                <div className="disable-modal-row">
                  <span className="discount__code">Discount Code: </span>
                  <span>{modalData?.code}</span>
                </div>
                <div className="disable-modal-row">
                  <span className="discount__code">Code Active From: </span>
                  <span>
                    From {moment(modalData?.startdate).format('MMM Do YY')}
                  </span>
                </div>
                <div className="disable-modal-row">
                  <span className="discount__code">Discount: </span>
                  <span>{modalData?.discountValue}</span>
                </div>
              </div>
            </ModalBody>
            <div
              className="d-flex justify-content-center"
              style={{ paddingBottom: '30px' }}
            >
              <button
                className="disable-yes-btn"
                onClick={(e) => handleDisable(e)}
              >
                Yes
              </button>
              <button
                className="disable-yes-btn disable-no-btn"
                onClick={(e) => {
                  distoggle(e, 'disable')
                }}
              >
                No
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={delModal}
            toggle={(e) => {
              distoggle(e, 'delete')
            }}
            centered
          >
            <div>
              <img
                src={crossIcon}
                onClick={(e) => {
                  distoggle(e, 'delete')
                }}
                className="modal-cross disable-cross-icon"
              />
            </div>
            <ModalHeader>
              <div className="heading-disable">
                Are you sure you want to Delete Discount ?
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="details--div">
                <div className="disable-details">Details</div>
                <div className="disable-modal-row">
                  <span className="discount__code">Discount Code: </span>
                  <span>{modalData?.code}</span>
                </div>
                <div className="disable-modal-row">
                  <span className="discount__code">Code Active From: </span>
                  <span>
                    From {moment(modalData?.startdate).format('MMM Do YY')}
                  </span>
                </div>
                <div className="disable-modal-row">
                  <span className="discount__code">Discount: </span>
                  <span>{modalData?.discountValue}</span>
                </div>
              </div>
            </ModalBody>
            <div
              className="d-flex justify-content-center"
              style={{ paddingBottom: '30px' }}
            >
              <button
                className="disable-yes-btn"
                onClick={(e) => handleDelete(e)}
              >
                Yes
              </button>
              <button
                className="disable-yes-btn disable-no-btn"
                onClick={(e) => {
                  distoggle(e, 'delete')
                }}
              >
                No
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  )
}
export default DiscountTable
