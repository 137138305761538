/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  // Nav,
  // NavLink,
  // NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledTooltip
} from 'reactstrap'
// import classnames from 'classnames'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import TableComponent from '../../components/Table'
import NumberFormat from 'react-number-format'
// import moment from 'moment'

import { GetData, PostData, ToesterSettings } from '../../apiHandler'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { Link, useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import './invoices.scss'

let filterByStatus = 'all'
let searchTimer
let filterByKeyObject = { key: 'all' }

const columns = [
  { key: 'checkBox', label: '', sorting: false },
  { key: 'invoiceNumber', label: 'Invoice No.', sorting: true },
  { key: 'createdAt', label: 'Invoice Date', sorting: false },
  { key: 'dueDate', label: 'Past Date', sorting: false },
  { key: 'paymentStatus', label: 'Status', sorting: true },
  { key: 'po_number', label: 'P.O. Number', sorting: false },
  { key: 'orderNumber', label: 'Order No.', sorting: true },
  { key: 'amount', label: 'Total', sorting: true },
  // { key: 'markAsPaid', label: 'Mark as Paid', sorting: false },
  { key: 'action', label: 'Action', sorting: false },
]
const filterTypes = [
  { label: 'All', key: 'all' },
  { label: 'Invoice No.', key: 'invoiceNumber' },
  { label: 'Status', key: 'paymentStatus' },
  { label: 'Order No.', key: 'orderNumber' },
  { label: 'Total', key: 'amount' }
]

class NotificationBody {
  constructor(customerId) {
    this.customerId = parseInt(customerId)
  }

  singleNotification(invoiceId) {
    this.invoiceId = invoiceId
    return this
  }

  multipleNotification(fromDate, toDate) {
    this.startDate = moment(fromDate).format('YYYY-MM-DD')
    this.endDate = moment(toDate).format('YYYY-MM-DD')
    return this
  }
}

export default function InvoicesList() {
  toastr.options = ToesterSettings
  const history = useHistory()
  const { customerId, year, month } = useParams()
  const [loader, setLoader] = useState(false)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  // const [tab, setTab] = useState('1')
  const [singlebtn, setSinglebtn] = useState(false)
  const [isStatusOpen, setIsStatusOpen] = useState(false)
  // const [invoiceStatus, setInvoiceStatus] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState({
    label: 'All',
    key: 'all'
  })
  const [invoices, setInvoices] = useState([])
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const [searchText, setSearchText] = useState('')
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  const statusTypesData =
    [
      { label: 'Current', key: '0' },
      { label: 'Past Due', key: '1' },
      { label: 'Pending Verification', key: '2' },
      { label: 'Pending', key: '3' },
      { label: 'Payment Processing', key: '4' },
      { label: 'Paid', key: '5' },
      { label: 'Failed', key: '6' }
    ]
  const [selectStatus, setSelectStatus] = useState('Select Status')
  const [modal, setModal] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null)
  const [invoiceNotes, setInvoiceNotes] = useState('')
  const [savedInvoiceNotes, setSavedInvoiceNotes] = useState('')
  const [markasPaid, setMarkasPaid] = useState([])

  useEffect(() => {
    getInvoices()
    return () => {
      // setTab('1')
      filterByStatus = 'all'
      filterByKeyObject = { key: 'all' }
    }
  }, [])

  useEffect(() => {
    if (fromDate && toDate) {
      getInvoices(1)
    }
  }, [fromDate, toDate])

  // ---------------------------------Add comment modal --------------------------

  const toggle = () => setModal(!modal);

  const handleModalAddComment = (invoiceId, note) => {
    setSavedInvoiceNotes(note)
    setModal(true)
    setInvoiceId(invoiceId)
  }

  const getInvoiceNotes = (e) => {
    setInvoiceNotes(e.target.value)
  }

  const handleAddComment = () => {
    setLoader(true)
    PostData('admin/updateInvoiceNote', {
      invoiceId: invoiceId,
      note: invoiceNotes
    }).then((response) => {
      if (response.status == true) {
        toastr.success(response.message, 'Success')
        setLoader(false)
        getInvoices(1)
        setModal(false)
      } else {
        toastr.error(response.message, 'Error')
        setLoader(false)
      }
    })

  }

  const getInvoices = (p = 1) => {
    const pageSize = 20
    let url = `admin/openAccountCustomerInvoices/${customerId}/${year}/${month}?page=${p}&pageSize=${pageSize}`
    if (filterByStatus !== 'all') {
      url += '&status=' + filterByStatus
    }
    if (filterByKeyObject.string) {
      url +=
        '&filterBy=' +
        filterByKeyObject.key +
        '&search=' +
        filterByKeyObject.string
    }
    if (sortingState.sortColumn != null) {
      url +=
        '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order
    }
    if (fromDate && toDate) {
      url +=
        '&startDate=' + moment(fromDate).format('YYYY-MM-DD') + '&endDate=' + moment(toDate).format('YYYY-MM-DD')
    }
    setLoader(true)
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status === true) {
        if (response?.result?.invoices?.length) {
          setInvoices(response.result.invoices.map((data, index) => {
            data.action = ['paybtn']
            let status = <div className="disabled-div">Pending</div>
            if (parseInt(data.paymentStatus) === 0) {
              status = <div className="approved-div">Current</div>
            } if (parseInt(data.paymentStatus) === 1) {
              status = <div className="approved-div">Past Due</div>
            } else if (parseInt(data.paymentStatus) === 2) {
              status = <div className="disabled-div">Pending Verification</div>
            } else if (parseInt(data.paymentStatus) === 3) {
              status = <div className="disabled-div">Pending</div>
            } else if (parseInt(data.paymentStatus) === 4) {
              status = <div className="disabled-div">Payment Processing</div>
            } else if (parseInt(data.paymentStatus) === 5) {
              status = <div className="active-div">Paid</div>
            } else if (parseInt(data.paymentStatus) === 6) {
              status = <div className="rejected-div">Failed</div>
            }
            const myObj = {
              invoiceId: data.id,
              checkBox: <div>  <input
                type="checkbox"
                style={{ height: '18px', width: '18px' }}
                disabled={activePayBtn(data?.paymentStatus)}
                onClick={(e) => handleCheck(e, data)}
              /></div>,
              invoiceNumber: <div style={{ display: 'flex', alignItems: 'center' }} id={`tooltip-${index}`}>{data?.invoiceNumber}<span className='ps-2' onClick={() => handleModalAddComment(data?.id, data?.note)}><i className= {` bx bxs-note ${data?.note && 'active-note'}`} style={{ fontSize: '18px' }}></i></span>
                {
                  data?.note &&
                  <UncontrolledTooltip
                    placement="top"
                    target={`tooltip-${index}`}
                    autohide={false}
                    style={{
                      backgroundColor: 'white',
                      color: '#645a5b',
                      boxShadow:
                        '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
                      minWidth: '200px',
                      fontSize: '14px'
                    }}>
                    {data?.note}
                  </UncontrolledTooltip>
                }
              </div>,
              notes: data?.note,
              createdAt: moment(data.createdAt).format('MM-DD-YYYY') || '--',
              dueDate: data.dueDate ? moment(data.dueDate).format('MM-DD-YYYY') : '--',
              paymentStatus: status,
              orderNumber: <Link to={`/invoices/order/${data.orderId}`}>{data.orderNumber}</Link> || '--',
              po_number: data?.po_number ? <Link to={`/invoices/order/${data.orderId}`}>{data?.po_number}</Link> : '--',
              amount: <NumberFormat
                value={parseFloat(data.amount) + (data?.order?.shipping !== "" && parseFloat(data?.order?.shipping))}
                displayType={'text'}
                thousandSeparator={true}
                valueIsNumericString={true}
                decimalScale={2}
                prefix={'$'}
              /> || '--',
              // markAsPaid: <div><button onClick={() => handleSingleMarkasPaid(data?.id)} className='btn btn-info' style={{cursor: 'pointer'}} disabled={activePayBtn(data?.paymentStatus)}>Mark Paid</button></div>
            }

            if (data.paymentStatus === 0) {
              myObj.action = ['paybtn']
            } else {
              myObj.action = ['disablePaybtn']
            }

            return myObj
          }))

          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: response.result.total
          })
        } else {
          setInvoices([])
          setPaging({
            currentPage: 1,
            recordPerPage: pageSize,
            totalRecord: 0
          })
        }
      } else {
        setInvoices([])
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
        toastr.error(response.message, 'Error')
      }
    })
  }
  // const filterData = (key, type) => {
  //   setTab(key)
  //   filterByStatus = type
  //   // if (type === 'pending') {
  //   //   setInvoiceStatus(0)
  //   // } else if (type === 'approved') {
  //   //   setInvoiceStatus(2)
  //   // } else if (type === 'rejected') {
  //   //   setInvoiceStatus(3)
  //   // } else if (type === 'active') {
  //   //   setInvoiceStatus(1)
  //   // } else if (type === 'disabled') {
  //   //   setInvoiceStatus(4)
  //   // } else {
  //   //   setInvoiceStatus(null)
  //   // }
  //   getInvoices(1)
  // }

  const handleActions = (key, data) => {
    if (key === 'paybtn') {
      handleNotificationApi(data.invoiceId)
    }
  }

  const handleNotificationApi = (invoiceId = null) => {
    setLoader(true)
    const url = 'admin/sendCustomerInvoiceReminder'
    let body
    if (invoiceId) {
      body = new NotificationBody(customerId).singleNotification(invoiceId)
    } else {
      body = new NotificationBody(customerId).multipleNotification(fromDate, toDate)
    }
    PostData(url, body).then((response) => {
      setLoader(false)
      if (response.status === true) {
        toastr.success(response.message, 'Success')
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const paginationHandler = (page) => {
    getInvoices(page)
  }

  const filterByKey = (obj) => {
    setSelectStatus('Select Status')
    filterByKeyObject.key = obj.key
    setSelectedFilter(obj)
    if (filterByKeyObject.string) {
      getInvoices(1)
    }
  }

  const searchByText = (t) => {
    setSearchText(t)
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      filterByKeyObject.string = t
      getInvoices(1)
    }, 2000)
  }

  const selectByStatus = (obj) => {
    filterByKeyObject.string = obj.key
    getInvoices(1)
    setSelectStatus(obj.label)
  }

  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn == column) {
      if (sortingState.order == 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }

  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getInvoices(1)
  }, [sortingState])

  const handleRemoveSearchWord = () => {
    setSearchText('')
    filterByKeyObject.string = ''
    getInvoices(1)
  }

  function handleExport(e) {
    e.preventDefault()
    GetData(`admin/export/openAccountCustomerInvoices/${customerId}/${year}/${month}`).then(
      (response) => {
        if (response.status == true) {
          const a = document.createElement('a')
          a.href = 'https://' + response.result.path
          a.download = 'download'
          a.click()
        } else {
          toastr.error('Problem Occured', 'Error')
        }
      }
    )
  }

  // ----------------------------------------Mark as paid start------------------------------------
  const activePayBtn = (paymentStatus) => {
    if (Number(paymentStatus) === 5) {
      return true
    }
    return false
  }

  const handleCheck = (e, invoiceData) => {
    if (e.target.checked) {
      setMarkasPaid((prevState) => {
        return [...prevState, invoiceData.id]
      })
    } else {
      setMarkasPaid((prevState) => {
        const newArr = prevState.filter((data) => {
          return data !== invoiceData.id
        })
        return newArr
      })
    }
  }


  const handleMultipleMarkasPaid = (invoicesArray) => {

    setLoader(true)
    const url = 'admin//updateOpenAccountInvoiceStatus'
    const body = {
      invoiceIds: invoicesArray,
      status: 5
    }

    PostData(url, body).then((response) => {
      setLoader(false)
      if (response.status === true) {
        toastr.success(response.message, 'Success')
        setMarkasPaid([])
        getInvoices(1)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  // const handleSingleMarkasPaid = (invoiceId) => {
  //   setLoader(true)
  //   const url = 'admin//updateOpenAccountInvoiceStatus'
  //   const body = {
  //     invoiceIds: [invoiceId],
  //     status: 5
  //   }

  //   PostData(url, body).then((response) => {
  //     setLoader(false)
  //     if (response.status === true) {
  //       toastr.success(response.message, 'Success')
  //       getInvoices(1)
  //     } else {
  //       toastr.error(response.message, 'Error')
  //     }
  //   })
  // }

  // ----------------------------------------Mark as paid end------------------------------------


  return (
    <>
      <Loader show={loader} />
      <div className="page-content">

        {/* --------------------Modal for add note starts---------------- */}
        <Modal isOpen={modal} toggle={toggle}
          size="md">
          <ModalHeader toggle={toggle}><h4>Invoice Note</h4></ModalHeader>
          <ModalBody>
            <textarea onChange={getInvoiceNotes} defaultValue={savedInvoiceNotes} type="text" style={{ height: "60px", width: "100%", border: "1px solid #ced4da", borderRadius: "0.25rem", color: "#495057", padding: "10px", resize: 'none' }}></textarea>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleAddComment}>
              Save
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {/* --------------------Modal for add note ends---------------- */}

        <MetaTags>
          <title>QLS Admin | Open Account Invoices</title>
        </MetaTags>
        <Container fluid={true} className="customer-container">
          <div className="breadcrumb-main-nav">
            <Breadcrumbs
              title="UI Elements"
              breadcrumbItem={'Open Account Customer Invoices List'}
              icon={
                <i
                  className="bx bx-arrow-back me-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push(`/invoices/${customerId}`)}
                ></i>
              }
            />
            <button
              className="bg-white export-btn me-2"
              onClick={(e) => {
                handleExport(e)
              }}
            >
              Export
            </button>
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="warehouse-navbar" style={{ marginBottom: '10px' }}>
                    {/* {
                      (userObj.roleId === 1 || userObj.roleId === 2)
                        ? <Nav pills className="wd-bottom">
                      <NavItem className="nav-links-hover">
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '1'
                          })}
                          onClick={() => filterData('1', 'all')}
                        >
                          All
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '2'
                          })}
                          onClick={() => filterData('2', 'pending')}
                          id="pending"
                        >
                          New/Pending
                          <UncontrolledTooltip
                          placement="top"
                          target={'pending'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            <div>New: Customer application received.</div>
                            <div>Pending: QLS Admin to approve account.</div>
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '4'
                          })}
                          onClick={() => filterData('4', 'approved')}
                          id="approved"
                        >
                          Approved
                          <UncontrolledTooltip
                          placement="top"
                          target={'approved'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            Customer account has been approved. Email confirmation sent to customer to create password.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '3'
                          })}
                          onClick={() => filterData('3', 'active')}
                          id="active"
                        >
                          Active
                          <UncontrolledTooltip
                          placement="top"
                          target={'active'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            Customer has created their password and logged into their account.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '5'
                          })}
                          onClick={() => filterData('5', 'rejected')}
                          id="rejected"
                        >
                          Rejected
                          <UncontrolledTooltip
                          placement="top"
                          target={'rejected'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            Customer account not approved.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '6'
                          })}
                          onClick={() => filterData('6', 'disabled')}
                          id="disabled"
                        >
                          Disabled
                           <UncontrolledTooltip
                          placement="top"
                          target={'disabled'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            Customer account has been permanently disabled. Account cannot be reinstated.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                    </Nav>
                        : ''
                    } */}
                    <div style={{ display: 'flex' }}>
                      <DatePicker
                        selected={fromDate}
                        onChange={(date) =>
                          setFromDate(date)
                        }
                        className="form-control pointer"
                        placeholderText="From Date"
                        showYearDropdown
                        showMonthDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown={true}
                      />&nbsp;&nbsp;
                      <DatePicker
                        selected={toDate}
                        onChange={(date) =>
                          setToDate(date)
                        }
                        className="form-control pointer"
                        placeholderText="To Date"
                        maxDate={new Date()}
                        showYearDropdown
                        showMonthDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown={true}
                      />
                    </div>
                    <div className="d-flex drop-search">
                      <Dropdown
                        isOpen={singlebtn}
                        toggle={() => setSinglebtn(!singlebtn)}
                      >
                        <DropdownToggle className="customer-select text-dark">
                          Filter By{' '}
                          <span className="font-weight-bold">
                            {selectedFilter.label}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {filterTypes.map((filterObj, key) => {
                            return (
                              <DropdownItem
                                key={key}
                                onClick={() => filterByKey(filterObj)}
                              >
                                {filterObj.label}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      {filterByKeyObject.key === 'paymentStatus'
                        ? (
                          <Dropdown
                            isOpen={isStatusOpen}
                            toggle={() => setIsStatusOpen(!isStatusOpen)}
                          >
                            <DropdownToggle className="customer-select status-dropdown text-dark">
                              {selectStatus}
                              <i className=" ms-2 bx bx-caret-down" />
                            </DropdownToggle>
                            <DropdownMenu className='status-dropdown-menu'>
                              {statusTypesData.map((item) => {
                                return (
                                  <DropdownItem
                                    key={item.key}
                                    onClick={() => selectByStatus(item)}
                                  >
                                    {item.label}
                                  </DropdownItem>
                                )
                              })}
                            </DropdownMenu>
                          </Dropdown>)
                        : (<div className="customer-search-form">
                          <input
                            className="form-control input-border customer-input-border"
                            type="text"
                            placeholder="Search"
                            onChange={(e) => searchByText(e.target.value)}
                            value={searchText}
                          />
                          {searchText && (
                            <i
                              className="mdi mdi-close empty-search-icon"
                              onClick={() => handleRemoveSearchWord()}
                            ></i>
                          )}
                          <i className="bx bx-search-alt-2 search-icon"></i>
                        </div>)
                      }
                    </div>
                  </div>
                  <hr className=" mt-0 mb-4 tab-hr"></hr>
                  {
                    (fromDate && toDate) && <div style={{ textAlign: 'right', marginBottom: '15px' }}>
                      <button className='btn btn-secondary' onClick={() => handleNotificationApi(null)}>Notify for all Invoices</button>
                    </div>
                  }
                  <TableComponent
                    columns={columns}
                    content={invoices}
                    handleActions={handleActions}
                    paging={paging}
                    paginationHandler={paginationHandler}
                    sorting={handleSort}
                    arrowState={sortingState}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {
            markasPaid.length
              ? <div className='invoice-float-btn'>
                <div className="container-fluid product-item-selected-row position-fixed">
                  <div className="product-select-item-row">
                    <button
                      className="product-selected-item position-relative"
                      onClick={(e) =>
                        handleMultipleMarkasPaid(markasPaid)
                      }
                    >
                      Mark as Paid
                      <div className="products__quantity d-flex align-items-center justify-content-center">
                        {markasPaid.length}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              : null
          }
        </Container>

      </div>
    </>
  )
}
