import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Button
} from 'reactstrap'
// import BulkUploadModal from 'components/Common/BulkUploadModal/BulkUploadModal'
import { GetData, PutData } from 'apiHandler'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import TableComponent from 'components/Table'
import 'toastr/build/toastr.min.css'
import MetaTags from 'react-meta-tags'

const filterByKeyObject = { key: 'all' }
let searchTimer
const InventoryPart = () => {
  const [singlebtn, setSinglebtn] = useState(false)
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)
  const [partNumber, setPartNumber] = useState('')
  const [searchText, setSearchText] = useState('')
  const [totalQuantity, setTotalQuantity] = useState(0)
  // const [bulkError, setBulkError] = useState('')
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const [selectedFilter, setSelectedFilter] = useState({
    label: 'All',
    key: 'all'
  })
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  const filterTypes = [
    { label: 'All', key: 'all' },
    { label: 'Location', key: 'location' },
    { label: 'Status', key: 'status' }
  ]
  const columns = [
    { key: 'location', label: 'Location' },
    { key: 'status', label: 'Product Status' },
    { key: 'price', label: 'WD Price', sorting: true },
    { key: 'quantity', label: 'Qty', sorting: true, input: true },
    { key: 'uom', label: 'UoM' }
  ]
  const dropToggle = () => {
    setSinglebtn(!singlebtn)
  }

  const filterByKey = (obj) => {
    filterByKeyObject.key = obj.key
    setSelectedFilter(obj)
    if (filterByKeyObject.string) {
      getLocations(1)
    }
  }
  const searchByText = (t) => {
    setSearchText(t)
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      filterByKeyObject.string = t
      getLocations(1)
    }, 2000)
  }
  const getLocations = (p) => {
    if (partNumber.trim() === '') { return }
    setLoader(true)
    const pageSize = 20
    let url = 'vendor/getPartNumberBasedInventory?page=' + p + '&pageSize=' + pageSize + '&partnumber=' + partNumber.trim()
    if (filterByKeyObject.string) {
      url +=
        '&filterBy=' +
        filterByKeyObject.key +
        '&search=' +
        filterByKeyObject.string
    }
    if (sortingState.sortColumn != null) {
      url +=
        '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order.toUpperCase()
    }

    GetData(url).then((response) => {
      if (response.status === true || response.status === 200) {
        setLoader(false)
        const parsedParts = []
        response.result.products.map((part) => {
          let statusString = <div className="disabled-div">Not Listed</div>
          if (part.status === 1) {
            statusString = <div className="active-div">Active</div>
          }
          parsedParts.push({
            part_id: part.part_id,
            partnumber: part.partNumber,
            quantity: part.quantity,
            product: part.PartTerminology.PartTerminologyName,
            price: part.price?.cost_price ? '$' + part.price?.cost_price : 'Not Set',
            status: statusString,
            uom: part.uom,
            sellingPrice: part.quantity,
            location: <><div>{part.vendorWarehouseLocations.locationName}</div><div>{part?.vendorWarehouseLocations?.cities?.name + ', ' + part.vendorWarehouseLocations?.countries?.name}</div></>,
            locationId: part.vendorWarehouseLocations.location_id
          })
          return null
        })
        setTotalQuantity(response.result.totalQuantity)
        setData(parsedParts)
        setPaging({
          currentPage: p,
          recordPerPage: pageSize,
          totalRecord: 20
        })
      } else {
        setLoader(false)
        setData([])
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn === column) {
      if (sortingState.order === 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }

  const updateQuantity = (e, data, qty, page) => {
    e.preventDefault()
    const body = {
      part_id: data.part_id,
      vendorWarehouseLocationId: data.locationId,
      quantity: parseInt(qty)
    }
    setLoader(true)
    PutData('vendor/updateInventoryQuantity', body).then((response) => {
      if (response.status === true) {
        setLoader(false)
        toastr.success(response.message)
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  /* const inventoryUpload = (e, file) => {
    if (partNumber.trim() === '') {
      toastr.error('Please enter part number', 'Error')
      return
    }
    const data = new FormData()
    data.append('document', file)
    data.append('partnumber', partNumber)
    setLoader(true)
    PostData('vendor/bulkInventoryUploadByPartNumber/' + partNumber.trim(), data).then((response) => {
      if (response.status === true) {
        getLocations(1)
        setBulkError(false)
        setTimeout(() => { setBulkError('') }, 200)
        toastr.success(response.message)
      } else {
        setLoader(false)
        setBulkError(true)
        toastr.error(response.message, 'Error')
      }
    })
  } */

  const downloadFile = (link) => {
    const element = document.createElement('a')
    element.setAttribute('href', link)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  const exportInventory = () => {
    if (partNumber.trim() === '') {
      toastr.error('Please enter part number', 'Error')
      return
    }
    setLoader(true)
    GetData('vendor/exportPartNumberBasedInventory?partnumber=' + partNumber).then((response) => {
      setLoader(false)
      if (response.status === true) {
        downloadFile('https://' + response.result)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getLocations(1)
  }, [sortingState])

  return (
    <>
      <MetaTags>
        <title>Inventory By Part Number :: QLS Admin Panel</title>
      </MetaTags>
      <Loader show={loader} />
      <div className="page-content">
        <Container fluid={true} className="customer-container">
          <div className="products-nav">
            <div className='inventory-dropdown-div'>
              <span className="products-heading">Inventory By Part No.</span>
            </div>
            <div className='d-flex'>
              {/* <BulkUploadModal modalType='Inventory Upload' productsBulkUpload={inventoryUpload} importSellingPrice={inventoryUpload} bulkError={bulkError} /> */}
              <button className="products-nav-btn products-export-btn" onClick={exportInventory}>
                Export
              </button>
            </div>
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="inventory-navbar">
                    <div className="d-flex ">
                      <Dropdown
                        isOpen={singlebtn}
                        toggle={dropToggle}
                      >
                        <DropdownToggle className="customer-select text-dark">
                          Filter By{' '}
                          <span className="font-weight-bold">
                            {selectedFilter.label}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {filterTypes.map((filterObj, key) => {
                            return (
                              <DropdownItem
                                key={key}
                                onClick={() => filterByKey(filterObj)}
                              >
                                {filterObj.label}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      <div className="customer-search-form">
                        <input
                          className="form-control input-border customer-input-border"
                          type="text"
                          placeholder="Search"
                          onChange={(e) => searchByText(e.target.value)}
                          value={searchText}
                        />
                        <i className="bx bx-search-alt-2 search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <hr className='hr-line'/>
                  <div className='inventyory-part-div'>
                      <div className='part-input-div'>
                          <span className='part-text'>Part Number</span>
                          <div>
                              <input type='text' value={partNumber} onChange={(e) => { setPartNumber(e.target.value) }} className='part--input'/>
                              <Button onClick={() => getLocations(1)} className='inventory-search-btn'>Search</Button>
                          </div>
                      </div>
                      <div className='total-quantity'>
                      Total Quantity Found: <span className='total-num'>{totalQuantity}</span>
                      </div>
                  </div>
                  <hr className='hr-line'/>
                  <TableComponent
                    columns={columns}
                    content={data}
                    paging={paging}
                    inputField="quantity"
                    arrowState={sortingState}
                    updateSellingPrice={updateQuantity}
                    sorting={handleSort}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default InventoryPart
