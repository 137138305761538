/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import back from '../../assets/images/back2.svg'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'
import { GetData } from 'apiHandler'
import Loader from '../../components/Loader'
import {
  Container
} from 'reactstrap'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import NumberFormat from 'react-number-format'

function WarehouseProfile () {
  const [loader, setLoader] = useState(false)
  const [data, setData] = useState([])
  const { id } = useParams()

  useEffect(() => {
    fetchWarehouseData()
  }, [])

  const fetchWarehouseData = () => {
    setLoader(true)
    GetData(`vendor/getWarehouseLocation/${id}`).then((response) => {
      setLoader(false)
      if (response.status === true) {
        setData(response.result[0])
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleStatusChange = (e) => {
    console.log('working')
    setLoader(true)
    const newUrl = `vendor/changeWarehouseLocationStatus/${id}`
    GetData(newUrl).then((response) => {
      if (response.status === true) {
        setLoader(false)
        fetchWarehouseData()
        toastr.success(response.message, 'Success')
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  return (
    <>
      <Loader show={loader}></Loader>
      <div className="page-content">
        <Container fluid={true} className="customer-container">
          <div className="staff-management-main">
            <div className="staff-management-heading">
              <Link to="/warehouse-management">
                <img
                  src={back}
                  style={{ marginRight: '8px', marginBottom: '4px' }}
                />
              </Link>
              <span>Rytek Automotive</span>
            </div>
            <div className="status-main-div">
              {
                (data?.status == 1 || data?.status == 4)
                  ? (
                  <>
                    <span className="staff-status">
                      Status: <span className="status-active">{
                          data?.status == 1 ? 'Active' : data?.status == 4 ? 'Disabled' : null
                      }</span>
                    </span>
                    <div className="form-check form-switch form-switch-lg">
                      {
                        (data?.status == 1 || data?.status == 4)
                          ? (
                              data?.status != undefined && <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizelg"
                            defaultChecked={data?.status == 1}
                            onChange={(e) => handleStatusChange(e)}
                          />
                            )
                          : null
                      }
                    </div>
                  </>
                    )
                  : null
              }
            </div>
          </div>

          <div className="profile-top-div">
            <div className="warehouse-register-div">
              <div className="registration-date">Registration Date</div>
              <div className="warehouse-date">
              {moment(data?.createdAt).format('DD-MM-YYYY')}
              </div>
            </div>
            <div className="warehouse-register-div border-div">
              <div className="registration-date">Approval Date</div>
              <div className="warehouse-date">
              {data?.approvedAt ? moment(data?.approvedAt).format('DD-MM-YYYY') : '--'}
              </div>
            </div>
            <div className="warehouse-register-div">
              <div className="registration-date"> Last Updated</div>
              <div className="warehouse-date">
                {moment(data?.updatedAt).format('DD-MM-YYYY ')}
              </div>
            </div>
          </div>

          <div className="buisness-information-div">
            <div className="inner-div">
              <div className="business-edit-div">
                <span className=" buisness-text">Business Information</span>
                <Link className=" buisness-text buisness-text-edit" to={`/edit-warehouse/${id}`}>
                  Edit
                </Link>
              </div>
              <div style={{ paddingTop: '20px' }}>
                <span className="warehouse-contact-text">Location Name</span>
                <div className="warehouse-street-name"><b> {data?.locationName} </b></div>
              </div>
              <div className="warehouse-address-div">
                <div className="warehouse--address">
                  <div className="warehouse-contact-text">Shipping Address</div>
                  <div className="street-div">
                    <div className="warehouse-street">Street</div>
                    <div
                      className="warehouse-street-name"
                      style={{ textTransform: 'capitalize', fontWeight: '700' }}
                    >
                      {data?.shippingStreet}
                    </div>
                  </div>
                  <div className="street-div">
                    <div className="warehouse-street">State</div>
                    <div className="warehouse-street-name" style={{ fontWeight: '700' }}>
                        {data?.states?.name ? `${data.states?.name}` : null}
                    </div>
                  </div>
                  <div className="street-div">
                    <div className="warehouse-street">City</div>
                    <div className="warehouse-street-name" style={{ fontWeight: '700' }}>
                        {data?.cities?.name ? `${data.cities?.name}` : null}
                    </div>
                  </div>
                  <div className="street-div">
                    <div className="warehouse-street">Zip Code</div>
                    <div className="warehouse-street-name" style={{ fontWeight: '700' }}>
                      {data?.shippingZipCode}
                    </div>
                  </div>
                </div>

                <div className="warehouse-vl"></div>
                <div className="warehouse--address ">
                  <div className="warehouse-contact-text warehouse-contact">
                    {' '}
                    Contact Information
                  </div>
                  <div className="contact-warehouse-left">
                    <div className="street-div">
                      <div className="warehouse-street">Name</div>
                      <div
                        className="warehouse-street-name"
                        style={{ textTransform: 'capitalize', fontWeight: '700' }}
                      >
                        {data?.contactFirstName &&
                          data?.contactLastName &&
                          `${data?.contactFirstName} ${data?.contactLastName}`}
                      </div>
                    </div>
                    <div className="street-div">
                      <div className="warehouse-street">Email Address</div>
                      <div className="warehouse-street-name" style={{ fontWeight: '700' }}>
                        {data?.contactEmail}
                      </div>
                    </div>
                    <div className="street-div">
                      <div className="warehouse-street">Phone Number</div>
                      <div className="warehouse-street-name" style={{ fontWeight: '700' }}>
                        <NumberFormat value={data?.contactPhone} displayType={'text'} format="+1 (###) ###-####"/>
                        {/* {data?.contactPhone} */}
                      </div>
                    </div>
                    <div className="street-div">
                      <div className="warehouse-street">Title</div>
                      <div
                        className="warehouse-street-name"
                        style={{ textTransform: 'capitalize', fontWeight: '700' }}
                      >
                        {data?.contactTitle}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}
export default WarehouseProfile
