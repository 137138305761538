/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Input, Label, Row, Form, Button } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { useHistory, Link, useParams } from 'react-router-dom'
// import { Editor } from 'react-draft-wysiwyg'
import Dropzone from 'react-dropzone'
import { GetData, PutData, PostData, PatchData } from 'apiHandler'
import Loader from '../../components/Loader'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { Editor } from '@tinymce/tinymce-react'

export function slugify (text) {
  return text
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}

function AddNewIndustry () {
  const history = useHistory()
  const { id } = useParams()
  const [loader, setLoader] = useState(false)
  const [editorContent, setEditorContent] = useState()
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [industryData, setIndustryData] = useState({})
  const [status, setStatus] = useState(null)
  const [imageError, setImageError] = useState({
    titleImage: '',
    bannerImage1: '',
    bannerImage2: ''
  })
  const [industryImages, setIndustryImages] = useState({
    titleImage: null,
    bannerImage1: null,
    bannerImage2: null
  })

  function handleChange (type, e) {
    e.preventDefault()
    if (e.target.name === 'title') {
      setIndustryData({
        ...industryData,
        [e.target.name]: e.target.value
      })
    } else setIndustryData({ ...industryData, [e.target.name]: e.target.value })
  }

  function resetUploadBanner (name, e) {
    e.preventDefault()
    setIndustryData({
      ...industryData,
      [name]: null
    })
  }

  function handleSubmit (e) {
    e.preventDefault()
    setLoader(true)
    const formData = {
      ...industryData,
      description: editorContent
    }
    id
      ? PutData(`admin/industryLink/${id}`, formData).then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Sent')
          history.push('/industry-links')
          setLoader(false)
        } else {
          toastr.error(response.message, 'Error')
          setLoader(false)
        }
      })
      : PostData('admin/industryLink', formData).then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Sent')
          history.push('/industry-links')
          setLoader(false)
        } else {
          toastr.error(response.message, 'Error')
          setLoader(false)
        }
      })
  }

  const getIndustryById = (id) => {
    setLoader(true)
    GetData(`admin/industryLink/${id}`).then((response) => {
      if (response.status === true) {
        setStatus(response.result.status)
        setIndustryData(response.result)
        setLoader(false)
        // const descriptionDataState = ContentState.createFromBlockArray(
        //   htmlToDraft(`${response.result.description}`)
        // )
        // setEditorState(EditorState.createWithContent(descriptionDataState))
        setEditorContent(response.result.description)
      }
    })
  }

  useEffect(() => {
    if (id) {
      getIndustryById(id)
    }
  }, [id])

  const handleUpload = async (file) => {
    setLoader(true)
    const formImage = new FormData()
    formImage.append('image', file)
    const response = await PostData('admin/uploadPostImage', formImage)
    if (response) {
      setLoader(false)
    }
    return response.status ? response.result.url : toastr.error(response.message, 'Error')
  }

  return (
        <>
        <Loader show={loader}></Loader>
        <div className="page-content">
            <Container fluid={true} className="homepage-wrap" >
                <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <Breadcrumbs
              title="UI Elements"
              breadcrumbItem={id ? 'Edit Industry Link' : 'Add New Industry Link'}
              icon={
                <i
                  className="bx bx-arrow-back me-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push('/industry-links')}
                ></i>
              }
            />
             {/* <a
              href={`${process.env.REACT_APP_WEB_URL}/service-opportunities/${serviceData.slug}`}
              target={'_blank'} rel="noreferrer"
            >
              {status === 1 && 'View on Website'}
            </a> */}
            </div>
            <Row className='mt-3'>
                <Col lg={12}>
                <Card>
                    <CardBody>
                        <div className="mb-3">
                            <Label className="form-label">
                            <b>Title</b>
                            </Label>
                            <Input
                            placeholder="Enter Title"
                            type="  "
                            id="title"
                            name="title"
                            className="input-border"
                            value={industryData.title}
                            onChange={(e) => handleChange('', e)}
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">
                            <b>Body</b>
                            </Label>
                            <Form method="post">
                            <Editor
                        value={editorContent}
                        onEditorChange={e => setEditorContent(e)}
                        apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                        init={{
                          height: 350,
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                            'table', 'image', 'link'
                          ],
                          toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist | ' +
                            'table |link | image',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          default_link_target: '_blank',
                          automatic_uploads: true,
                          file_picker_types: 'image',
                          image_uploadtab: true,
                          file_picker_callback: function (callback, value, meta) {
                            if (meta.filetype === 'image') {
                              const input = document.createElement('input')
                              input.setAttribute('type', 'file')
                              input.setAttribute('accept', 'image/*')
                              input.click()
                              input.onchange = async function () {
                                const file = input.files[0]
                                const reader = new FileReader()
                                const fileUrl = await handleUpload(file)
                                callback(fileUrl, {
                                  alt: file.name
                                })
                                reader.readAsDataURL(file)
                              }
                            }
                          }
                        }}
                      />

                        {/* <Editor
                          editorState={editorState}
                          toolbarClassName="articleToolbar"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) => {
                            setEditorState(e)
                          }}
                        /> */}
                      </Form>
                        </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="banner1Link">
                        <b>Website Link</b>
                      </Label>
                      <Input
                        id="url"
                        type="url"
                        name="url"
                        className="input-border"
                         onChange={(e) => handleChange('', e)}
                         value={industryData.url}
                      />
                    </div>
                    </CardBody>
                </Card>
                    <Link to="/industry-links">
                <Button className="btn btn-cancel mb-4 ms-0">Cancel</Button>
              </Link>
              <Button
                className="btn btn-add-new mb-4 ms-0"
                onClick={handleSubmit}
              >
                Save
              </Button>
                </Col>
            </Row>
            </Container>
        </div>
        </>
  )
}
export default AddNewIndustry
