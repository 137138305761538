import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from './actionTypes'
import { apiError, loginSuccess, logoutUserSuccess } from './actions'

// Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper'
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
  postLoginVerify
} from '../../../helpers/fakebackend_helper'
import { GetData } from '../../../apiHandler'

const fireBaseBackend = getFirebaseBackend()

function getCommonData () {
  GetData('commonData').then((response) => {
    if (response.status === true) {
      localStorage.setItem('commonData', JSON.stringify(response.result))
    }
  })
}

function * loginUser ({ payload: { user, history } }) {
  console.log('cascascasc', process.env.REACT_APP_DEFAULTAUTH)
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password
      })
      localStorage.setItem('authUser', JSON.stringify(response))
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'fake') {
      put(apiError(''))
      if (user.step === 1) {
        const response = yield call(postFakeLogin, {
          email: user.email,
          password: user.password
        })

        if (response.data?.status === true) {
          yield put(apiError(''))
          if (response.data.result.isTwoFactorEnabled) {
            user.otp = response.data.result.OTP
            localStorage.setItem('loginCreds', JSON.stringify(user))
            // localStorage.setItem("authUser", JSON.stringify(response));
            // yield put(loginSuccess(response));
            history.push({ pathname: '/verify-otp', state: { user } })
          } else {
            const responseResult = response.data.result
            responseResult.access_token = response.headers.access_token
            localStorage.setItem('authUser', JSON.stringify(responseResult))
            yield put(loginSuccess(responseResult))
            getCommonData()
            history.push('/dashboard')
          }
        } else {
          if (response.message) {
            yield put(apiError(response.message))
          } else {
            yield put(apiError('Request Timed Out. Please Try Again'))
          }
        }
      } else {
        console.log('step 2', user)
        const response = yield call(postLoginVerify, {
          email: user.userName,
          otp: user.password
        })

        if (response.data?.status === true) {
          const responseResult = response.data.result
          responseResult.access_token = response.headers.access_token
          console.log('responseResult', response.headers)
          localStorage.setItem('authUser', JSON.stringify(responseResult))
          yield put(loginSuccess(responseResult))
          getCommonData()
          history.push('/dashboard')
        } else {
          console.log('response.data', response.data)
          if (response.data.message) {
            yield put(apiError(response.data.message))
          } else {
            yield put(apiError('Request Timed Out. Please Try Again'))
          }
        }
      }
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function * logoutUser ({ payload: { history } }) {
  try {
    localStorage.removeItem('authUser')

    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push('/login')
  } catch (error) {
    yield put(apiError(error))
  }
}

function * socialLogin ({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type
      )
      localStorage.setItem('authUser', JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem('authUser', JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push('/dashboard')
  } catch (error) {
    yield put(apiError(error))
  }
}

function * authSaga () {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
