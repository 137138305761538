import React from 'react'
export const getWDStatusString = (status) => {
  let statusString = <div className="active-div">Delivered</div>
  if (status === 0) {
    statusString = <div className="disabled-div">New</div>
  }
  if (status === 1) {
    statusString = <div className="approved-div">In Progress</div>
  }
  if (status === 2) {
    statusString = <div className="approved-div">In Transit</div>
  }
  if (status === 3) {
    statusString = <div className="active-div">Delivered</div>
  }
  if (status === 4) {
    statusString = <div className="rejected-div">Rejected</div>
  }
  if (status === 5) {
    statusString = <div className="rejected-div">Cancelled</div>
  }
  if (status === 6) {
    statusString = <div className="rejected-div">Returned</div>
  }
  if (status === 7) {
    statusString = <div className="rejected-div">Enroute to Warehouse</div>
  }
  if (status === 8) {
    statusString = <div className="rejected-div">Received By Warehouse</div>
  }
  return statusString
}

export const getAdminStatusString = (status) => {
  let statusString = <div className="active-div">Delivered</div>
  if (status === 0) {
    statusString = <div className="disabled-div">New</div>
  }
  if (status === 1) {
    statusString = <div className="approved-div">In Progress</div>
  }
  if (status === 2) {
    statusString = <div className="approved-div">In Transit</div>
  }
  if (status === 3) {
    statusString = <div className="active-div">Delivered</div>
  }
  if (status === 4) {
    statusString = <div className="rejected-div">Cancelled</div>
  }
  if (status === 5) {
    statusString = <div className="rejected-div">Returned</div>
  }
  if (status === 6) {
    statusString = <div className="rejected-div">Enroute to Warehouse</div>
  }
  if (status === 7) {
    statusString = <div className="rejected-div">Received By Warehouse</div>
  }
  return statusString
}

export const getShippingStatusString = (status) => {
  let statusString = <span className="disabled-div">Pick Ticket Ready</span>
  if (status === 0) {
    statusString = <span className="disabled-div">Pick Ticket Ready</span>
  }
  if (status === 1) {
    statusString = <span className="disabled-div">Packing Slip Ready</span>
  }
  if (status === 2) {
    statusString = <span className="disabled-div">Assigned Driver</span>
  }
  if (status === 3) {
    statusString = <span className="approved-div">Packed</span>
  }
  if (status === 4) {
    statusString = <span className="approved-div">Shipped</span>
  }
  if (status === 5) {
    statusString = <span className="active-div">Delivered</span>
  }
  if (status === 6) {
    statusString = <div className="rejected-div">Enroute to Warehouse</div>
  }
  if (status === 7) {
    statusString = <div className="rejected-div">Received By Warehouse</div>
  }
  if (status === 10) {
    statusString = <span className="rejected-div">Cancelled</span>
  }
  if (status === 11) {
    statusString = <span className="disabled-div">Unfulfilled</span>
  }
  return statusString
}
export const getpaymentString = (status) => {
  let statusString = ''
  if (status === 0) {
    statusString = <span className="disabled-div"> Pending</span>
  }
  if (status === 1) {
    statusString = <span className="approved-div"> partiallyPaid</span>
  }
  if (status === 2) {
    statusString = <span className="rejected-div"> paid</span>
  }
  return statusString
}
