import React from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import styled from 'styled-components'
import delIcon from '../../assets/images/ic_delete.svg'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

const TrWrapper = styled.tr`
  cursor: default;

  .firstElement {
    display: flex;
    flex-direction: row;
  }

  &.helperContainerClass {
    width: auto;
    border: 1px solid #efefef;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;

    &:active {
      cursor: grabbing;
    }

    & > td {
      padding: 5px;
      text-align: left;
      width: 200px;
    }
  }
`

const Handle = styled.div`
  margin-right: 10px;
  padding: 0 5px;
  cursor: grab;
`

const RowHandler = SortableHandle(() => <Handle className="handle"><i className="bx bx-grid-vertical"></i></Handle>)

const TableRow = (props) => {
  const history = useHistory()

  return (
    <TrWrapper>
      {
        Object.keys(props.values).map((item, i) => {
          const checkCol = props.columns.findIndex((col) => col.key === item)
          if (checkCol === -1) {
            return null
          } else {
            if (item === props.firstCol) {
              return (<td key={i}>
                <div className="firstElement">
                  <RowHandler />
                  {props.values[item]}
                </div>
              </td>)
            } else if (item === 'actions') {
              return (
                <td key={i}>
                  <div className="d-flex align-items-center">
                                  <i
                                    className="bx bxs-edit-alt icons me-2"
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      history.push('/technical-bulletin/' + props.values.id)
                                    }}
                                  ></i>
                                  <img
                                    src={delIcon}
                                    className="del--icon"
                                    onClick={() => props.deleteAction(props.values, props.index)}
                                  />
                                  </div>
                </td>
              )
            }
            return <td key={i}>{props.values[item]}</td>
          }
        })
      }
    </TrWrapper>
  )
}

export default TableRow

TableRow.propTypes = {
  values: PropTypes.any,
  index: PropTypes.any,
  columns: PropTypes.any,
  deleteAction: PropTypes.any,
  firstCol: PropTypes.any
}
