/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import { Input, Modal } from 'reactstrap'
import { Pagination } from '@mui/material'

const SelectionModalTable = (props) => {
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    setCurrentPage(props.paging.currentPage)
  }, [props.paging.currentPage])

  function handlePage (e, value) {
    e.preventDefault()
    setCurrentPage(value)
    props.paginationHandler(value)
  }

  return (
    <Modal
    className="modal-main-body search-popup"
    isOpen={props.modalState.modalOpen}
    toggle={() => props.modalPopup(false, '')}
    centered
  >
    <div className="modal-header">
      <span className="modal-title">
        Add <span style={{ textTransform: 'capitalize' }}>{props.modalState.modalType}</span>
      </span>
      <button
        type="button"
        onClick={() => props.modalPopup(false, '')}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div className="modal-body">
      {
        props.modalState.modalType !== 'categories' && <div className="search-bar">
        <Input
          placeholder={`Search ${props.modalState.modalType.charAt(0).toUpperCase() + props.modalState.modalType.slice(1)}`}
          type="text"
          id="search-products"
          name="search-products"
          className="input-border"
          onChange={(e) => props.fetchData(1, e.target.value)}
        />
  </div>
      }
      <div className="modal-detail-div">
        <table>
          <tbody>
          <>
                {
                    (props.tempData && props?.tempData?.length > 0)
                      ? props.tempData?.map((data, key) => {
                        return (
                            <tr key={key}>
                              <td className="text-center">
                              <Input
                                type="checkbox"
                                name="select-product"
                                className="input-border  cursor-pointer"
                                onChange={(e) => props.handleSelect(e, data)}
                              />
                            </td>
                            {
                              props.modalState.modalType === 'categories' && <>
                              <td>{
                                  data?.categoryName
                                }
                                </td>
                              </>
                            }
                            {
                              props.modalState.modalType === 'products' && <>
                              <td className="text-center">{data?.imageMapper?.length > 0 ? <img src={data.imageMapper[0].imageUrl} style={{ width: '60px', height: '60px', objectFit: 'contain' }} /> : <i className="bx bx-image-alt image-icon"></i>}</td>
                              <td>{
                                    data?.partNumber
                                }
                                </td>
                              </>
                            }
                            {
                              props.modalState.modalType === 'customers' && <>
                              <td className="text-center">
                                {
                                    data?.companyName
                                }
                                </td>
                                <td className="text-center">
                                {
                                    data?.email
                                }
                                </td>
                              </>
                            }
                          </tr>
                        )
                      })
                      : props.mainData?.map((data, key) => {
                        return (
                          <tr key={key}>
                              <td className="text-center">
                              <Input
                                type="checkbox"
                                name="select-product"
                                className="input-border  cursor-pointer"
                                onChange={(e) => props.handleSelect(e, data)}
                              />
                            </td>
                            {
                              props.modalState.modalType === 'categories' && <>
                              <td>{
                                  data?.categoryName
                                }
                                </td>
                              </>
                            }
                            {
                              props.modalState.modalType === 'products' && <>
                              <td className="text-center">{data?.imageMapper?.length > 0 ? <img src={data?.imageMapper[0]?.imageUrl} style={{ width: '60px', height: '60px', objectFit: 'contain' }} /> : <i className="bx bx-image-alt image-icon"></i>}</td>
                              <td>{
                                    data?.partNumber
                                }
                                </td>
                              </>
                            }
                            {
                              props.modalState.modalType === 'customers' && <>
                              <td className="text-center">
                                {
                                    data?.companyName
                                }
                                </td>
                                <td className="text-center">
                                {
                                    data?.email
                                }
                                </td>
                              </>
                            }
                          </tr>
                        )
                      })
                }
                </>
            </tbody>
        </table>
      </div>
      {
            Math.ceil(props.paging.totalRecord / props.paging.recordPerPage) > 1 && <div className='d-flex justify-content-center mt-2'>
            <Pagination count={Math.ceil(props.paging.totalRecord / props.paging.recordPerPage)} page={currentPage} onChange={handlePage} variant="outlined" color="primary" shape="rounded" />
            </div>
}
      <div className="modal-btn-div mb-3 pr-2">
        <button
          className="btn btn-outline-secondary"
          onClick={(e) => props.handleCancel(e)}
        >
          Cancel
        </button>
        <button
          className="btn btn-secondary add-btn"
          disabled={ props?.tempSelected?.length === 0 }
          onClick={(e) => props.handleAdd(e)}
        >
          Add
        </button>
      </div>
    </div>
  </Modal>
  )
}

export default SelectionModalTable
