/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { Input, Modal, Collapse } from 'reactstrap'

const CategoryModalTable = (props) => {
  const [openCollpase, setOpenCollapse] = useState([])

  useEffect(() => {
    if (props.mainData) {
      // eslint-disable-next-line array-callback-return
      props.mainData.map((data) => {
        setOpenCollapse((prevState) => ([
          ...prevState,
          true
        ]))
      })
    }
  }, [props.mainData])

  const handleShowCollpase = (e, index) => {
    const collapseArr = [...openCollpase]
    collapseArr[index] = true
    setOpenCollapse(collapseArr)
  }

  const handleHideCollapse = (e, index) => {
    const collapseArr = [...openCollpase]
    collapseArr[index] = false
    setOpenCollapse(collapseArr)
  }

  return (
    <Modal
    className="modal-main-body search-popup"
    isOpen={props.modalState.modalOpen}
    toggle={() => props.modalPopup(false, '')}
    centered
  >
    <div className="modal-header">
      <span className="modal-title">
        Add <span style={{ textTransform: 'capitalize' }}>{props.modalState.modalType}</span>
      </span>
      <button
        type="button"
        onClick={() => props.modalPopup(false, '')}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div className="modal-body"><div className="search-bar">
        <Input
          placeholder='Search by Category Name'
          type="text"
          id="search-products"
          name="search-products"
          className="input-border"
          onChange={(e) => props.fetchData(1, e.target.value)}
        />
  </div>
      <div className="modal-detail-div">
          <>
                {props.mainData?.map((data, key) => {
                  const catIndex = props.selectedData.findIndex((item) => item.categoryId === data.categoryID)
                  return (
                            <>
                          <div className='' style={{ borderBottom: '1px solid #dcdcdc' }}>
                          <div key={key} className="d-flex pt-3 pb-3 p-2" style={{ borderBottom: '1px solid #dcdcdc' }}>
                              <div className="text-center" style={{ width: '10%' }}>
                              <Input
                                type="checkbox"
                                name="select-product"
                                className="input-border  cursor-pointer"
                                checked={catIndex > -1}
                                onClick={(e) => props.handleSelect(e, data)}
                              />
                            </div>
                              <div style={{ width: '80%' }}>{
                                  data?.categoryName
                                }
                                </div>
                                <div style={{ width: '10%' }}>
                                  {
                                    openCollpase[key]
                                      ? <i className='bx bx-message-alt-minus'
                                    onClick={(e, index = key) =>
                                      handleHideCollapse(e, index)
                                      }
                                      ></i>
                                      : <i className='bx bx-plus'
                                      onClick={(e, index = key) =>
                                        handleShowCollpase(e, index)
                                        }
                                        ></i>
                                  }
                            </div>
                          </div>
                          <Collapse isOpen={openCollpase[key]} className="pt-2 pb-2 ps-4">
                                    {
                                        data?.children.map((sub, k) => {
                                          return (
                                            <div key={k} className="d-flex p-1">
                                            <div className="text-center" style={{ width: '10%' }}>
                              <Input
                                type="checkbox"
                                name="select-product"
                                className="input-border  cursor-pointer"
                                checked={catIndex > -1 && props.selectedData[catIndex].subCategory.findIndex((item) => item.subcategoryID === sub.subcategoryID) > -1}
                                onClick={(e) => props.handleSelect(e, data, sub)}
                              />
                            </div>
                              <div style={{ width: '80%' }}>{
                                  sub.subCategoryName
                                }
                                </div>
                                            </div>
                                          )
                                        })
                                    }
                            </Collapse>
                          </div>
                          </>
                  )
                })
                }
                </>
      </div>
    </div>
  </Modal>
  )
}

export default CategoryModalTable
