/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  Dropdown,
  DropdownItem,
  Modal,
  DropdownToggle,
  DropdownMenu,
  UncontrolledTooltip
} from 'reactstrap'
import classnames from 'classnames'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import TableComponent from '../../components/Table'
// import moment from 'moment'

import { GetData, PostData, PutData, ToesterSettings } from '../../apiHandler'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { useHistory, Link } from 'react-router-dom'
import moment from 'moment'

let filterByStatus = 'all'
let searchTimer
let filterByKeyObject = { key: 'all' }
export default function Customer () {
  toastr.options = ToesterSettings
  const history = useHistory()
  const [loader, setLoader] = useState(false)
  const [tab, setTab] = useState('1')
  const [singlebtn, setSinglebtn] = useState(false)
  const [isStatusOpen, setIsStatusOpen] = useState(false)
  const [customerStatus, setCustomerStatus] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState({
    label: 'All',
    key: 'all'
  })
  const [customers, setCustomers] = useState([])
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const [searchText, setSearchText] = useState('')
  const [selectedData, setSelectedData] = useState({})
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  const [columns, setColumns] = useState([
    { key: 'companyName', label: 'Company Name', sorting: true },
    { key: 'mobile', label: 'Mobile Number', sorting: false },
    { key: 'email', label: 'Email Address', sorting: true },
    { key: 'priceLevel', label: 'Price Level', sorting: false },
    { key: 'createdAt', label: 'Application Date', html: true, sorting: true },
    { key: 'locations', label: 'Locations', html: true, sorting: false },
    { key: 'openAccount', label: 'Type', html: true },
    { key: 'status', label: 'Status', html: true, sorting: false },
    { key: 'action', label: 'View/Disable', sorting: false }
  ])
  const [filterTypes, setFilterTypes] = useState([
    { label: 'All', key: 'all' },
    { label: 'Company Name', key: 'companyName' },
    { label: 'Email', key: 'email' },
    { label: 'Location Name', key: 'locationName' },
    { label: 'Location', key: 'location' },
    { label: 'Price Level', key: 'priceLevel' },
    { label: 'Status', key: 'status' },
    { label: 'Mobile', key: 'mobile' }
  ])
  const userObj = JSON.parse(localStorage.authUser)
  // const filterTypes = [
  //   { label: 'All', key: 'all' },
  //   { label: 'Company Name', key: 'companyName' },
  //   { label: 'Email', key: 'email' },
  //   { label: 'Location Name', key: 'locationName' },
  //   { label: 'Location', key: 'location' },
  //   { label: 'Price Level', key: 'priceLevel' },
  //   { label: 'Status', key: 'status' },
  //   { label: 'Mobile', key: 'mobile' }
  // ]
  const statusTypesData =
[
  { label: 'Pending', key: '0' },
  { label: 'Active', key: '1' },
  { label: 'Approved', key: '2' },
  { label: 'Rejected', key: '3' },
  { label: 'Disabled', key: '4' }
]
  const [selectStatus, setSelectStatus] = useState('Select Status')

  useEffect(() => {
    getCustomers()
    return () => {
      setTab('1')
      filterByStatus = 'all'
      filterByKeyObject = { key: 'all' }
    }
  }, [])

  const handleDisable = (id, status) => (e) => {
    e.preventDefault()
    status == 1
      ? PutData('admin/changeCustomerStatus', {
        id,
        status: 4
      }).then((response) => {
        if (response.status == true) {
          toastr.success(response.message, 'Success')
          getCustomers()
        } else {
          toastr.error(response.message, 'Error')
        }
      })
      : PutData('admin/changeCustomerStatus', {
        id,
        status: 1
      }).then((response) => {
        if (response.status == true) {
          toastr.success(response.message, 'Success')
          getCustomers()
        } else {
          toastr.error(response.message, 'Error')
        }
      })
    setIsDisableOpen(false)
  }

  const getCustomers = (p = 1) => {
    const pageSize = 20
    let url = 'admin/getAllCustomers?page=' + p + '&pageSize=' + pageSize
    if (userObj.roleId === 3 || userObj.roleId === 4) {
      url = 'vendor/getWDCustomers?page=' + p + '&pageSize=' + pageSize
      setColumns([
        { key: 'companyName', label: 'Company Name', sorting: true },
        { key: 'location', label: 'Location', sorting: false },
        { key: 'customerName', label: 'Customer Name', sorting: true }
      ])
      setFilterTypes([
        { label: 'All', key: 'all' },
        { key: 'companyName', label: 'Company Name' },
        { key: 'location', label: 'Location' },
        { key: 'customerName', label: 'Customer Name' }
      ])
    }
    if (filterByStatus !== 'all') {
      url += '&status=' + filterByStatus
    }
    if (filterByKeyObject.string) {
      url +=
        '&filterBy=' +
        filterByKeyObject.key +
        '&search=' +
        filterByKeyObject.string
    }
    if (sortingState.sortColumn != null) {
      url +=
        '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order
    }
    setLoader(true)
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status === true) {
        if (userObj.roleId === 3 || userObj.roleId === 4) {
          if (response.result.customers.length) {
            const tableData = []
            // eslint-disable-next-line array-callback-return
            response?.result.customers?.map((product, k) => {
              const mylocation = product?.location.city + ' , ' + product?.location.state
              tableData.push({ companyName: product?.companyName, location: mylocation, customerName: product?.customerName })
            })
            setPaging({
              currentPage: p,
              recordPerPage: pageSize,
              totalRecord: response.result.total
            })
            setCustomers(tableData)
            return null
          } else {
            setPaging({
              currentPage: p,
              recordPerPage: pageSize,
              totalRecord: 0
            })
            setCustomers([])
          }
        }
        if (response?.result?.data?.length) {
          const tableData = []
          const changeRequestData = []
          const exportArr = []
          // response.result.data.map((item, key) => {
          //   exportArr.push(data.customerId)
          //   letterSpacing
          // })
          response.result.data.map((data, index) => {
            exportArr.push(data.customerId)
            let locations = ''
            data?.primaryLocation?.map((location) => {
              locations = (
                <>
                  {locations}
                  <div>
                    {location.locationName}, {location.city}
                  </div>
                  <div>
                    {location.state}
                    {data.locations.length
                      ? (
                      <>
                        <a
                          id={`UncontrolledTooltipExample${index}`}
                          className="more-locations"
                        >
                          +{data.locations.length}
                        </a>
                        <UncontrolledTooltip
                          placement="left"
                          target={`UncontrolledTooltipExample${index}`}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}
                        >
                          <ul className="tooltip-location">
                            {data.locations.map((location, i) => {
                              return (
                                <li key={i}>
                                  <div className="location-head">
                                    {location.locationName}
                                  </div>
                                  <div className="location-desc">
                                    {(location.city && location.state) ? (location.city + ',' + location.state) : location.city}
                                  </div>
                                </li>
                              )
                            })}
                          </ul>
                        </UncontrolledTooltip>
                      </>
                        )
                      : (
                          ''
                        )}
                  </div>
                </>
              )
              return null
            })
            let statusString = <div className="pending-div">Pending</div>
            if (data.status === 1) {
              statusString = <div className="active-div">Active</div>
            }
            if (data.status === 2) {
              statusString = <div className="approved-div">Approved</div>
            }
            if (data.status === 3) {
              statusString = <div className="rejected-div">Rejected</div>
            }
            if (data.status === 4) {
              statusString = <div className="disabled-div">Disabled</div>
            }

            data.tempstatus = data.status
            data.status = statusString
            data.locations = locations
            data.openAccount = data.isOpenAccount ? 'Open Account' : 'Normal Account'
            data.createdAt = moment(data.createdAt).format('MM-DD-YYYY')
            // let companyName = <span>{data.companyName}</span>;
            if (data.changeRequest) {
              data.companyName = (
                <div className="d-flex align-items-center">
                  <span className="me-1">{data.companyName}</span>{' '}
                  <i
                    className="bx bx-info-circle text-danger h5 m-0"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Change Request"
                  ></i>
                </div>
              )
            }
            const temp = ['view']
            if (data.tempstatus === 1) {
              temp.push('disable')
            }
            // else if (data.tempstatus === 4) {
            //   temp.push('enable')
            // }
            data.action = temp

            if (data.changeRequest) {
              changeRequestData.push(data)
            } else {
              tableData.push(data)
            }
            return null
          })

          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: response.result.total
          })
          setCustomers(changeRequestData.concat(tableData))
        } else {
          setCustomers([])
          setPaging({
            currentPage: 1,
            recordPerPage: pageSize,
            totalRecord: 0
          })
        }
      } else {
        setCustomers([])
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
        toastr.error(response.message, 'Error')
      }
    })
  }

  const filterData = (key, type) => {
    setTab(key)
    filterByStatus = type
    if (type === 'pending') {
      setCustomerStatus(0)
    } else if (type === 'approved') {
      setCustomerStatus(2)
    } else if (type === 'rejected') {
      setCustomerStatus(3)
    } else if (type === 'active') {
      setCustomerStatus(1)
    } else if (type === 'disabled') {
      setCustomerStatus(4)
    } else {
      setCustomerStatus(null)
    }
    getCustomers(1)
  }

  const handleActions = (key, data) => {
    if (key === 'view') {
      history.push('/customer/' + data.customerId)
    } else if (key === 'disable') {
      // PutData("admin/changeCustomerStatus",{
      //   id: data.customerId,
      //   status: 4
      // }).then((response)=>{
      //   if(response.status==true){
      //     console.log(response)
      //   }
      // })
      setSelectedData(data)
      setIsDisableOpen(true)
    }
  }

  const handleRow = (data) => {
    if (userObj.roleId === 1 || userObj.roleId === 2) {
      history.push('/customer/' + data.customerId)
    }
  }

  const paginationHandler = (page) => {
    getCustomers(page)
  }

  const filterByKey = (obj) => {
    setSelectStatus('Select Status')
    filterByKeyObject.key = obj.key
    setSelectedFilter(obj)
    if (filterByKeyObject.string) {
      getCustomers(1)
    }
  }

  const searchByText = (t) => {
    setSearchText(t)
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      filterByKeyObject.string = t
      getCustomers(1)
    }, 2000)
  }

  const selectByStatus = (obj) => {
    filterByKeyObject.string = obj.key
    getCustomers(1)
    setSelectStatus(obj.label)
  }

  // let columns = [
  //   { key: 'companyName', label: 'Company Name', sorting: true },
  //   { key: 'mobile', label: 'Mobile Number', sorting: false },
  //   { key: 'email', label: 'Email Address', sorting: true },
  //   { key: 'priceLevel', label: 'Price Level', sorting: false },
  //   { key: 'locations', label: 'Locations', html: true, sorting: false },
  //   { key: 'status', label: 'Status', html: true, sorting: false },
  //   { key: 'action', label: 'Action', sorting: false }
  // ]

  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn == column) {
      if (sortingState.order == 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }

  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getCustomers(1)
  }, [sortingState])

  const handleRemoveSearchWord = () => {
    setSearchText('')
    filterByKeyObject.string = ''
    getCustomers(1)
  }

  function handleExport (e) {
    e.preventDefault()
    PostData('admin/export/customers', { status: customerStatus }).then(
      (response) => {
        if (response.status == true) {
          const a = document.createElement('a')
          a.href = 'https://' + response.result
          a.download = 'download'
          a.click()
        }
      }
    )
  }
  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <MetaTags>
          <title>QLS Admin | Customer</title>
        </MetaTags>
        <Container fluid={true} className="customer-container">
          <div className="breadcrumb-main-nav">
            <Breadcrumbs title="UI Elements" breadcrumbItem="Customers" />
            {
              userObj.roleId === 3 || userObj.roleId === 4
                ? ''
                : <div className="imp-exp-div">
              <button
                className="bg-white export-btn me-2"
                onClick={(e) => {
                  handleExport(e)
                }}
              >
                Export
              </button>
              <Link to="/customer/add-new">
                <button className="add-new-customer-btn">
                  + Add New Customer
                </button>
              </Link>
            </div>
              }
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="warehouse-navbar">
                    {
                      (userObj.roleId === 1 || userObj.roleId === 2)
                        ? <Nav pills className="wd-bottom">
                      <NavItem className="nav-links-hover">
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '1'
                          })}
                          onClick={() => filterData('1', 'all')}
                        >
                          All
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '2'
                          })}
                          onClick={() => filterData('2', 'pending')}
                          id="pending"
                        >
                          New/Pending
                          <UncontrolledTooltip
                          placement="top"
                          target={'pending'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            <div>New: Customer application received.</div>
                            <div>Pending: QLS Admin to approve account.</div>
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '4'
                          })}
                          onClick={() => filterData('4', 'approved')}
                          id="approved"
                        >
                          Approved
                          <UncontrolledTooltip
                          placement="top"
                          target={'approved'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            Customer account has been approved. Email confirmation sent to customer to create password.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '3'
                          })}
                          onClick={() => filterData('3', 'active')}
                          id="active"
                        >
                          Active
                          <UncontrolledTooltip
                          placement="top"
                          target={'active'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            Customer has created their password and logged into their account.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '5'
                          })}
                          onClick={() => filterData('5', 'rejected')}
                          id="rejected"
                        >
                          Rejected
                          <UncontrolledTooltip
                          placement="top"
                          target={'rejected'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            Customer account not approved.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: tab === '6'
                          })}
                          onClick={() => filterData('6', 'disabled')}
                          id="disabled"
                        >
                          Disabled
                           <UncontrolledTooltip
                          placement="top"
                          target={'disabled'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            Customer account has been permanently disabled. Account cannot be reinstated.
                          </UncontrolledTooltip>
                        </NavLink>
                      </NavItem>
                    </Nav>
                        : ''
                    }
                    <div className="d-flex drop-search">
                      <Dropdown
                        isOpen={singlebtn}
                        toggle={() => setSinglebtn(!singlebtn)}
                      >
                        <DropdownToggle className="customer-select text-dark">
                          Filter By{' '}
                          <span className="font-weight-bold">
                            {selectedFilter.label}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {filterTypes.map((filterObj, key) => {
                            return (
                              <DropdownItem
                                key={key}
                                onClick={() => filterByKey(filterObj)}
                              >
                                {filterObj.label}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      {filterByKeyObject.key === 'status'
                        ? (
                          <Dropdown
                            isOpen={isStatusOpen}
                            toggle={() => setIsStatusOpen(!isStatusOpen)}
                          >
                          <DropdownToggle className="customer-select status-dropdown text-dark">
                           {selectStatus}
                            <i className=" ms-2 bx bx-caret-down" />
                          </DropdownToggle>
                          <DropdownMenu className='status-dropdown-menu'>
                            {statusTypesData.map((item) => {
                              return (
                                <DropdownItem
                                  key={item.key}
                                  onClick={() => selectByStatus(item)}
                                >
                                {item.label}
                                </DropdownItem>
                              )
                            })}
                          </DropdownMenu>
                        </Dropdown>)
                        : (<div className="customer-search-form">
                        <input
                          className="form-control input-border customer-input-border"
                          type="text"
                          placeholder="Search"
                          onChange={(e) => searchByText(e.target.value)}
                          value={searchText}
                        />
                        {searchText && (
                          <i
                            className="mdi mdi-close empty-search-icon"
                            onClick={() => handleRemoveSearchWord()}
                          ></i>
                        )}
                        <i className="bx bx-search-alt-2 search-icon"></i>
                      </div>)
                      }
                    </div>
                  </div>
                  <hr className=" mt-0 mb-4 tab-hr"></hr>
                  <TableComponent
                    columns={columns}
                    content={customers}
                    handleActions={handleActions}
                    paging={paging}
                    paginationHandler={paginationHandler}
                    onClick={handleRow}
                    sorting={handleSort}
                    arrowState={sortingState}
                    verticalCol={true}
                  />
                </CardBody>
                <Modal
                  className="modal-main-body"
                  isOpen={isDisableOpen}
                  toggle={() => setIsDisableOpen(false)}
                  centered
                >
                  <div className="modal-header">
                    <button
                      type="button"
                      onClick={() => {
                        setIsDisableOpen(false)
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p className="disable-warehouse">
                      Are you sure you want to disable this {' '}
                      {/* {selectedData.tempstatus === 4 ? ' Enable' : ' Disable'}{' '} */}
                      customer?
                    </p>
                    <div className="modal-detail-div">
                      <p className="modal-details">Details</p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading">
                          {' '}
                          Company Name:{' '}
                        </span>{' '}
                        {selectedData.companyName}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Email: </span>{' '}
                        {selectedData.email}
                      </p>
                    </div>
                    <div className="modal-btn-div">
                      <button
                        className="warehouse-btn-yes"
                        onClick={handleDisable(
                          selectedData.customerId,
                          selectedData.tempstatus
                        )}
                      >
                        Yes
                      </button>
                      <button
                        className=" warehouse-btn-no"
                        onClick={() => setIsDisableOpen(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </Modal>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
