/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Form, Label, Input } from 'reactstrap'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import { PutData } from 'apiHandler'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { INPUTTYPE } from './HomeBanner'
import Loader from '../../components/Loader'

const SupplyCustomer = (props) => {
  const [customer, setCustomer] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [imageChange, setImageChange] = useState(false)
  const [loader, setLoader] = useState(false)
  const [imageError, setImageError] = useState('')

  useEffect(() => {
    props.data && setCustomer(props.data)
  }, [props.data])

  function previewImage (e) {
    e.preventDefault()
    setSelectedFile(URL.createObjectURL(e.target.files[0]))
  }
  function handleAcceptedFiles (files) {
    if (files[0].size >= 300000) {
      setImageError('Please Upload Small Size File')
    } else {
      setImageError('')
      setCustomer({ ...customer, image: files[0] })
      setSelectedFile(URL.createObjectURL(files[0]))
      setImageChange(true)
    }
  }
  const maxSize = 5242880
  /**
   * Formats the size
   */
  // function formatBytes (bytes, decimals = 2) {
  //   if (bytes === 0) return '0 Bytes'
  //   const k = 1024
  //   const dm = decimals < 0 ? 0 : decimals
  //   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  //   const i = Math.floor(Math.log(bytes) / Math.log(k))
  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  // }

  function removeImage (e) {
    e.preventDefault()
    const newData = { ...customer }
    newData.imageUrl = null
    setCustomer(newData)
    setSelectedFile(null)
  }

  function handleChange (input, e) {
    e.preventDefault()
    if (input == INPUTTYPE.IMAGE) {
      if (e.target.files[0].size >= 300000) {
        setImageError('Please Upload Small Size File')
      } else {
        setImageError('')
        previewImage(e)
        setCustomer({ ...customer, image: e.target.files[0] })
        setImageChange(true)
      }
    } else {
      setCustomer(prevCustomer => ({ ...prevCustomer, [e.target.name]: e.target.value }))
      // setCustomer({ ...customer, [e.target.name]: e.target.value })
    }
  }

  function handleSubmit (e) {
    e.preventDefault()
    setLoader(true)
    const formData = new FormData()
    if (imageChange) {
      for (const k in customer) {
        formData.append(k, customer[k])
      }
    } else {
      for (const k in customer) {
        if (k == 'imageUrl') {
          // do something
        } else {
          formData.append(k, customer[k])
        }
      }
    }

    PutData('admin/updateHomeSection3', formData)
      .then((response) => {
        if (response.status === true) {
          toastr.success(response.message, 'Sent')
          setLoader(false)
        }
      })
      .catch((response) => {
        toastr.error(response.message, 'Error')
        setLoader(false)
      })
  }

  return (
    <React.Fragment>
      <Loader show={loader}></Loader>
      <div className="home-banner-right-wrap">
        <div className="supply-resource-wrap">
          <div className="banner-fields-box">
            <div className="sr-title-top">
              <Label htmlFor="formrow-firstname-Input">Text 1</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Text 1"
                name="text1"
                defaultValue={customer?.text1}
                onChange={(e) => {
                  handleChange(INPUTTYPE.TEXT, e)
                }}
              />
              <div className="mt-3">
              <Label htmlFor="formrow-firstname-Input">Text 2</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Text 2"
                name="text2"
                defaultValue={customer?.text2}
                onChange={(e) => {
                  handleChange(INPUTTYPE.TEXT, e)
                }}
              />
              </div>
              <div className="mt-3">
              <Label htmlFor="formrow-firstname-Input">Button Text</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter button text"
                name="buttonText"
                defaultValue={customer?.buttonText}
                onChange={(e) => {
                  handleChange(INPUTTYPE.TEXT, e)
                }}
              />
              </div>
              <div className="mt-3">
              <Label htmlFor="formrow-firstname-Input">Button Link (URL)</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter button text"
                name="buttonUrl"
                defaultValue={customer?.buttonUrl}
                onChange={(e) => {
                  handleChange(INPUTTYPE.TEXT, e)
                }}
              />
              </div>
            </div>
          </div>
        </div>
        {/* banner1 */}
        <div className="home-banner-outer supply-customer-outer">
          <div className="supply-customer-wrap">
            <h4>Banner Image</h4>
            <div className="form-outer-dropzone">
              <Form>
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles)
                  }}
                  accept="image/jpeg, image/png, image/gif, image/bmp, image/webp"
                  minSize={0}
                  maxSize={maxSize}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <input
                          name="imageUrl"
                          {...getInputProps()}
                          onChange={(e) => {
                            handleChange(INPUTTYPE.IMAGE, e)
                          }}
                        />
                        <div className="upload-icon">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h5>Drop files here or click to upload.</h5>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="preview-container">
                  {(customer?.imageUrl || selectedFile) && (
                    <div className="file-preview-container">
                      <div className="file-box">
                        <img
                          data-dz-thumbnail=""
                          className="avatar-sm"
                          src={customer.imageUrl || selectedFile}
                        />
                      </div>
                      <div
                        className="cross-btn-upload"
                        onClick={(e) => {
                          removeImage(e)
                        }}
                      >
                        X
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            </div>
            <div className="error mt-2">{imageError}</div>
            <div className="upload-img-lines">
              <ul>
                <li>Format Supported: Jpeg, JPG, PNG, GIF, Webp</li>
                <li>Preferred Dimensions: 1440x250 (pixels)</li>
                <li>Maximum Size: 300 kb </li>
              </ul>
            </div>
            <div className="banner-fields-box">
              <div>
                <Label htmlFor="formrow-firstname-Input">
                  Banner Video Link (URL)
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter banner video link"
                  name="link"
                  // defaultValue={customer?.link}
                  value={customer?.link}
                  onChange={(e) => {
                    handleChange(INPUTTYPE.TEXT, e)
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="save-btn">
          <button type="submit" onClick={(e) => handleSubmit(e)}>
            Save
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SupplyCustomer

SupplyCustomer.propTypes = {
  data: PropTypes.object
}
